import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  updateProfilePreferences,
  getProfileRequest,
  getProfileSuccess,
  getProfileError,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileError,
  submissionRequest,
  submissionSuccess,
  submissionError,
  submissionContactRequest,
  submissionContactSuccess,
  submissionContactError,
  deAuthorizationState,
  getRemotePerConfSuccess,
  getRemotePerConfRequest,
  getRemotePerScheduleSuccess,
  getRemotePerScheduleRequest,
  getRemotePerStatusSuccess,
  getRemotePerStatusRequest,
  updateScheduleRequest,
  getRemoteRequestConfig,
  getRemoteStreamShutdownRequest,
  StreamRestartRequest,
  payPalGetCodeRequest,
  payPalGetCodeHistoryRequest,
  payPalGetCodeHistorySuccess,
  payPalGetCodeHistorySuccessEarnings,
  addEmailArtistRequest,
  addEmailArtistSuccess,
  sendDbAmountRequest,
  sendDbAmountSuccess,
  getWatchListSuccess,
  getWatchListError,
  getWatchListRequest,
  getTargetArtistsSuccess,
  getTargetArtistsError,
  getTargetArtistsRequest,
  getRemoveWatchListRequest,
  getRemoveWatchListSuccess,
  getRemoveWatchListError,
  setRemoveUserWatchListSuccess,
  setRemoveUserWatchListError,
  setRemoveUserWatchListRequest,
  getAddWatchListError,
  getAddWatchListSuccess,
  getAddWatchListRequest,
  getAddArtistSuccess,
  getAddArtistError,
  getAddArtistRequest,
  setSearchWatchListSuccess,
  setSearchWatchListError,
  setSearchWatchListRequest,
  setUpdatePlayerLocationRequest,
  setUpdatePlayerLocationSuccess,
  setUpdatePlayerLocationError,
  setFeedbackSuccess,
  setFeedbackError,
  setFeedbackRequest,
  registerProducerSuccess,
  addNameArtistRequest,
  addNameArtistSuccess,
  addPasswordArtistRequest,
  addPasswordArtistSuccess,
  setRefreshTokenError,
  setRefreshTokenSuccess,
  setRefreshTokenRequest
} from '../slices/user';
import { toastConfig } from '../shared/defaultValue';
import userService from '../services/UserAPI';
import toast from 'react-hot-toast';
import { login, loginSuccess, registerSuccess } from '../slices/auth';
import jwt_decode from 'jwt-decode';

function* profileUser() {
  try {
    const result = yield call(userService.getProfileUser, {});
    if (result.success) {
      localStorage.setItem('profile_user', JSON.stringify(result.data));
      yield put(getProfileSuccess(result.data));
    } else {
      toast.error(result.message, toastConfig);
      yield put(getProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getProfileError(error.message));
  }
}

//
// function* remotePerformanceStatus({ payload }) {
//   try {
//     const result = yield call(userService.getRemotePerStatusApi, {});
//     if (result.success) {
//       yield put(getRemotePerStatusSuccess(result.data));
//       // yield put(getProfileRequest());
//       // toast.success('Data received', toastConfig);
//     } else {
//       toast.error(result.message, toastConfig);
//       yield put(updateProfileError(result.message));
//     }
//   } catch (error) {
//     toast.error(error.message, toastConfig);
//     yield put(updateProfileError(error.message));
//   }
// }
//
//
//
// function* remotePerformanceConfig({ payload }) {
//   try {
//     const result = yield call(userService.getRemotePerConfigApi, {});
//     if (result.success) {
//       yield put(getRemotePerConfSuccess(result.data));
//       // yield put(getProfileRequest());
//       // toast.success('Data received', toastConfig);
//     } else {
//       toast.error(result.message, toastConfig);
//       yield put(updateProfileError(result.message));
//     }
//   } catch (error) {
//     toast.error(error.message, toastConfig);
//     yield put(updateProfileError(error.message));
//   }
// }
//
// function* remotePerformanceSchedule({ payload }) {
//   try {
//     const result = yield call(userService.getRemotePerScheduleApi, {});
//     if (result.success) {
//       yield put(getRemotePerScheduleSuccess(result.data));
//       // yield put(getProfileRequest());
//       // toast.success('Data received', toastConfig);
//     } else {
//       toast.error(result.message, toastConfig);
//       yield put(updateProfileError(result.message));
//     }
//   } catch (error) {
//     toast.error(error.message, toastConfig);
//     yield put(updateProfileError(error.message));
//   }
// }
function* remotePerformanceData({ payload }) {
  try {
    const result = yield call(userService.getRemotePerformanceDataApi, {});
    if (result.success) {
      yield put(getRemotePerScheduleSuccess(result.data));
      // yield put(getProfileRequest());
      // toast.success('Data received', toastConfig);
    } else {
      // toast.error(result.message, toastConfig);
      // yield put(updateProfileError(result.message));
    }
  } catch (error) {
    // toast.error(error.message, toastConfig);
    // yield put(updateProfileError(error.message));
  }
}

function* streamShutdownData({ payload }) {
  try {
    const result = yield call(userService.streamShutdown, {});
    if (result.success) {
      yield put(getRemotePerScheduleSuccess(result.data));
      yield put(getRemotePerConfRequest());
      yield put(getRemotePerScheduleRequest());
      // yield put(getProfileRequest());
      // toast.success('Data received', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(updateProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* streamRestartData({ payload }) {
  try {
    const result = yield call(userService.streamRestart, payload);
    if (result.success) {
      // yield put(getRemotePerScheduleSuccess(result.data));
      yield put(getRemotePerConfRequest());
      yield put(getRemotePerScheduleRequest());
      // yield put(getProfileRequest());
      // toast.success('Data received', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(updateProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* getDbAmount({ payload }) {
  try {
    const result = yield call(userService.sendDbAmount, payload);
    if (result.success) {
      yield put(sendDbAmountSuccess(result.data));
      yield put(payPalGetCodeHistoryRequest());
      // yield put(getProfileRequest());
      toast.success('Withdrawal request was successful', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(updateProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* getPayPalData({ payload }) {
  try {
    const result = yield call(userService.setPayPalCode, payload);
    if (result.success) {
      // yield put(getRemotePerConfRequest());
      // yield put(getRemotePerScheduleRequest());
      // yield put(getProfileRequest());
      // toast.success('Data received', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(updateProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* addEmailArtist({ payload }) {
  yield put(addEmailArtistSuccess(payload));
}

function* addNameArtist({ payload }) {
  yield put(addNameArtistSuccess(payload));
}

function* addPasswordArtist({ payload }) {
  yield put(addPasswordArtistSuccess(payload));
}

function* getPayPalDataHistory({ payload }) {
  try {
    const result = yield call(userService.getPayoutsHistory, payload);
    if (result.success) {
      yield put(payPalGetCodeHistorySuccess(result.data));
      // yield put(getRemotePerConfRequest());
      // yield put(getRemotePerScheduleRequest());
      // yield put(getProfileRequest());
      // toast.success('Data received', toastConfig);
    } else {
      // toast.error(result.message, toastConfig);
      // yield put(updateProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* remotePerformanceConfigApi({ payload }) {
  try {
    const result = yield call(
      userService.submissionUserUpdRemPerConfig,
      payload
    );
    if (result.success) {
      yield put(getRemotePerConfRequest());
      // yield put(getRemotePerScheduleSuccess(result.data));
      yield put(getProfileRequest());
      // toast.success('Data received', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(updateProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* updatePerformanceSchedule(payload) {
  try {
    const result = yield call(
      userService.submissionUserUpdRemPerSchedule,
      payload.payload.customConfigurationTab
    );
    if (result.success) {
      yield put(getRemotePerScheduleSuccess(result.data));
      yield put(getRemotePerConfRequest());
      yield put(getRemotePerScheduleRequest());
      // toast.success('Data received', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(updateProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* deAuthorizationYoutubeTwitch({ payload }) {
  try {
    const result = yield call(userService.deAuthorizeAccount, payload);
    if (result.success) {
      yield put(getProfileRequest());
      toast.success('Update profile successful', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(updateProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* updateAccountPreferences({ payload }) {
  try {
    const result = yield call(userService.updateAccountPreferences, payload);
    console.log('result error', result);

    if (result.success) {
      console.log('payload', payload);
      yield put(registerSuccess(true));
      yield put(updateProfileSuccess(result.data));
      yield put(setRefreshTokenRequest());
      localStorage.removeItem('profile_user');
      // yield put(login({ email: payload?.email, password: payload?.password }));
      yield put(getProfileRequest());
      yield put(getRemotePerScheduleRequest());
      toast.success('Update profile successful', toastConfig);
      setTimeout(() => {
        let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
        const decode = jwt_decode(appState.auth.currentUser);
        console.log('decode-->', decode);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'newAccountSignUpProducer',
          'characterId': decode?.playFabId,
          'playfabId': decode?.playFabId
        });
      }, 3000);

      //add new object for tutorial state
      let tutorialState = {
        "dressing-room" : true,
        "store" : true,
        "atm" : true,
        "crowd-chat" : true
      }
      localStorage.setItem("frisson_tutorial", JSON.stringify(tutorialState))
    } else {
      console.log('result error', result);
      toast.error(result.message, toastConfig);
      yield put(updateProfileError(result.message));
    }
  } catch (error) {
    console.log('result error catch', error);
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* updateProfile({ payload }) {
  try {
    const result = yield call(userService.updateProfile, payload);
    if (result.success) {
      yield put(updateProfileSuccess(result.data));
      yield put(getProfileRequest());
      toast.success('Update profile successful', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(updateProfileError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(updateProfileError(error.message));
  }
}

function* submissionEmail({ payload }) {
  try {
    const result = yield call(userService.submissionContact, payload);
    if (result.success) {
      yield put(submissionSuccess());
      toast.success('Submit email successful', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(submissionError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(submissionError(error.message));
  }
}

function* submissionContact({ payload }) {
  try {
    const result = yield call(userService.submissionContact, payload);
    if (result.success) {
      yield put(submissionContactSuccess(true));
      toast.success('Submit form contact successful', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(submissionContactError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(submissionContactError(error.message));
  }
}


function* watchListUser({id, payload}) {
  try {
    const result = yield call(userService.getUserWatchList, payload);
    if (result.success) {
      yield put(getWatchListSuccess(result.data));
    } else {
      toast.error(result.message, toastConfig);
      yield put(getWatchListError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getWatchListError(error.message));
  }
}

function* watchTargetUser({payload}) {
  try {
    const result = yield call(userService.getTargetArtists, payload);
    if (result.success) {
      yield put(getTargetArtistsSuccess(result.data));
    } else {
      toast.error(result.message, toastConfig);
      yield put(getTargetArtistsError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getTargetArtistsError(error.message));
  }
}

function* removeWatchList({payload}) {
  try {
    const result = yield call(userService.setRemoveWatchList, payload);
    if (result.success) {
      yield put(getRemoveWatchListSuccess(result.data));
      yield put(getWatchListRequest(''));
      yield put(getTargetArtistsRequest(''));
    } else {
      toast.error(result.message, toastConfig);
      yield  put(getRemoveWatchListError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getRemoveWatchListError(error.message));
  }
}

function* removeUserWatchList({payload}) {
  try {
    const result = yield call(userService.setRemoveUserWatchList, payload);
    if (result.success) {
      yield put(setRemoveUserWatchListSuccess(result.data));
      yield put(getWatchListRequest(''));
      yield put(getTargetArtistsRequest(''));
    } else {
      toast.error(result.message, toastConfig);
      yield  put(setRemoveUserWatchListError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(setRemoveUserWatchListError(error.message));
  }
}

function* addWatchList({payload}) {
  try {
    const result = yield call(userService.setAddToWatchList, payload);
    if (result.success) {
      console.log('result.data--> ', result.data);
      yield put(getAddWatchListSuccess(result.data));
    } else {
      toast.error(result.message, toastConfig);
      yield  put(getAddWatchListError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getAddWatchListError(error.message));
  }
}

function* addArtist({payload}) {
  try {
    const result = yield call(userService.setAddArtist, payload);
    if (result.success) {
      console.log('result.data--> ', result.data);
      yield put(getAddArtistSuccess(result.data));
    } else {
      toast.error(result.message, toastConfig);
      yield  put(getAddArtistError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getAddArtistError(error.message));
  }
}


function* updatePlayerLocation({payload}) {
  try {
    const result = yield call(userService.setUpdatePlayerLocation, payload);
    if (result.success) {
      yield put(setUpdatePlayerLocationSuccess(result.data));
    } else {
      toast.error(result.message, toastConfig);
      yield  put(setUpdatePlayerLocationError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(setUpdatePlayerLocationError(error.message));
  }
}


function* searchWatchList({payload}) {
  try {
    const result = yield call(userService.setSearchWatchList, payload);
    if (result.success) {
      yield put(setSearchWatchListSuccess(result.data));
    } else {
      toast.error(result.message, toastConfig);
      yield put(setSearchWatchListError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(setSearchWatchListError(error.message));
  }
}

function* refreshToken() {
  try {
    const res = localStorage.getItem('refreshToken');
    if(!res){
      return
    }

    const result = yield call(userService.setRefreshToken, res);
    if (result.success) {
      yield put(loginSuccess(result.data.token));
      const access_token = result.data.refreshToken;
      localStorage.setItem('refreshToken', access_token);

      yield put(setRefreshTokenSuccess(result.data));
      console.log('[Auth] Token and Refresh token are updated!');
    } else {
      toast.error(result.message, toastConfig);
      yield put(setRefreshTokenError(result.message));
    }
  } catch (error) {
    console.log( 'res error', error );
    toast.error(error.message, toastConfig);
    yield put(setRefreshTokenError(error.message));
  }
}

function* feedbackMessage({payload}) {
  try {
    const result = yield call(userService.setFeedback, payload);
    console.log('result feedbackMessage');
    if (result.success) {
      yield put(setFeedbackSuccess(result.data));
      toast.success('Feedback successfully sent', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield  put(setFeedbackError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(setFeedbackError(error.message));
  }
}


export function* userSaga() {
  yield all([
    takeLatest(StreamRestartRequest.type, streamRestartData),
    takeLatest(getRemoteStreamShutdownRequest.type, streamShutdownData),
    takeLatest(getRemoteRequestConfig.type, remotePerformanceConfigApi),
    takeLatest(updateScheduleRequest.type, updatePerformanceSchedule),
    // takeLatest(getRemotePerStatusRequest.type, remotePerformanceStatus),
    // takeLatest(getRemotePerScheduleRequest.type, remotePerformanceSchedule),
    // takeLatest(getRemotePerConfRequest.type, remotePerformanceConfig),
    takeLatest(getRemotePerConfRequest.type, remotePerformanceData),
    takeLatest(deAuthorizationState.type, deAuthorizationYoutubeTwitch),
    takeLatest(getProfileRequest.type, profileUser),
    takeLatest(updateProfilePreferences.type, updateAccountPreferences),
    takeLatest(updateProfileRequest.type, updateProfile),
    takeLatest(submissionRequest.type, submissionEmail),
    takeLatest(submissionContactRequest.type, submissionContact),
    takeLatest(payPalGetCodeRequest.type, getPayPalData),
    takeLatest(payPalGetCodeHistoryRequest.type, getPayPalDataHistory),
    takeLatest(addEmailArtistRequest.type, addEmailArtist),
    takeLatest(sendDbAmountRequest.type, getDbAmount),
    takeLatest(getWatchListRequest.type, watchListUser),
    takeLatest(getTargetArtistsRequest.type, watchTargetUser),
    takeLatest(getRemoveWatchListRequest.type, removeWatchList),
    takeLatest(setRemoveUserWatchListRequest.type, removeUserWatchList),
    takeLatest(getAddWatchListRequest.type, addWatchList),
    takeLatest(getAddArtistRequest.type, addArtist),
    takeLatest(setSearchWatchListRequest.type, searchWatchList),
    takeLatest(setUpdatePlayerLocationRequest.type, updatePlayerLocation),
    takeLatest(setRefreshTokenRequest.type, refreshToken),
    takeLatest(setFeedbackRequest.type, feedbackMessage),
    takeLatest(addNameArtistRequest.type, addNameArtist),
    takeLatest(addPasswordArtistRequest.type, addPasswordArtist),
  ]);
}