import React from 'react';
import { useLocation } from 'react-router-dom';
import PageContainer from '../container/page';
import PopupSearch from '../components/popup/PopupSearch';
import PopupMobileMenu from '../components/popup/PopupMobileMenu';
// import DynamicMenu from 'components/dynamic-menu';
import DynamicMenuModernized from 'components/dynamic-menu-modernized';

const LayoutDefault = ({ children, className, revealFooter }) => {
  const LandingPage = `${process.env.PUBLIC_URL + '/artist'}`;
  const location = useLocation();
  const MainMenu =
    LandingPage === location.pathname ? (
      ''
    ) : (
      <DynamicMenuModernized position={3} direction="top" />
    );

  return (
    <>
      <PageContainer className={className} revealFooter={revealFooter}>
        {MainMenu}
        <PopupSearch />
        <PopupMobileMenu />

        {children}
      </PageContainer>
    </>
  );
};

export default LayoutDefault;
