import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';



// const scale = value => {
//   const previousMarkIndex = Math.floor(value / 25);
//   const previousMark = followersMarks[previousMarkIndex];
//   const remainder = value % 25;
//   if (remainder === 0) {
//     return previousMark.scaledValue;
//   }
//   const nextMark = followersMarks[previousMarkIndex + 1];
//   const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
//   return remainder * increment + previousMark.scaledValue;
// };

// function numFormatter(num) {
//   if (num > 999 && num < 1000000) {
//     return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
//   } else if (num >= 1000000) {
//     return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
//   } else if (num < 900) {
//     return num; // if value < 1000, nothing to do
//   }
// }

function calculateValue(value) {
  return  2 ** value;
}

let timingMarks = [
    {
      value: 0,
      scaledValue: 0,
      label: "0"
    },
    {
      value: 25,
      scaledValue: 0.125,
      label: "1/8"
    },
    {
      value: 50,
      scaledValue: 0.25,
      label: "1/4"
    }
  ]  
let maxTimes = 8

export default function VibesUI_Timing(props) {

  const [value, setValue] = React.useState(parseInt(props.timing));
  
  // function updateMarks() {
  //   props.qty <= 17 && followersMarks.push({
  //     value: 75,
  //     scaledValue: 0.5,
  //     label: "1/2"
  //   })
  //   props.qty <= 9 && followersMarks.push({
  //     value: 100,
  //     scaledValue: 1,
  //     label: "1"
  //   })
  //   props.qty <= 5 && followersMarks.push({
  //     value: 125,
  //     scaledValue: 2,
  //     label: "2"
  //   })
  //   props.qty <= 3 && followersMarks.push({
  //     value: 150,
  //     scaledValue: 4,
  //     label: "4"
  //   })
  //   props.qty <= 2 && followersMarks.push({
  //     value: 175,
  //     scaledValue: 8,
  //     label: "8"
  //   })
  // }

  const handleChange = (event, newValue) => {
    setValue(newValue)
    props.updateTiming(newValue)
    // console.log(timingOptions[newValue])
  };

  React.useMemo(() => {
    timingMarks = [
      {
        value: 0,
        scaledValue: 0,
        label: "0"
      }
    ]

    if (props.qty > 1) {
      timingMarks.push(
      {
      value: 7,
      scaledValue: 8,
      label: "8"
      },
      {
        value: 1,
        scaledValue: 0.125,
        label: "1/8"
      },
      {
        value: 2,
        scaledValue: 0.25,
        label: "1/4"
      })

      props.qty <= 17 && timingMarks.push({
        value: 3,
        scaledValue: 0.5,
        label: "1/2"
      })
      props.qty <= 9 && timingMarks.push({
        value: 4,
        scaledValue: 1,
        label: "1"
      })
      props.qty <= 5 && timingMarks.push({
        value: 5,
        scaledValue: 2,
        label: "2"
      })
      props.qty <= 3 && timingMarks.push({
        value: 6,
        scaledValue: 4,
        label: "4"
      })
      props.qty < 3 && timingMarks.push(
      {
      value: 7,
      scaledValue: 8,
      label: "8"
      })

      // for (let i = 0; i < timingMarks.length; i++) {
      //   const time = timingMarks[i];

      //   time.value = i
        
      // }

      
    }
    else {
      setValue(0)
    }

    maxTimes = timingMarks.length - 2

    console.log(timingMarks)

    //

  }, [props.qty])

  // updateMarks()
  
  

  // const scale = value => {
  //   const previousMarkIndex = Math.floor(value / 25);
  //   const previousMark = followersMarks[previousMarkIndex];
  //   const remainder = value % 25;
  //   if (remainder === 0) {
  //     return previousMark.scaledValue;
  //   }
  //   const nextMark = followersMarks[previousMarkIndex + 1];
  //   const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
  //   return remainder * increment + previousMark.scaledValue;
  // };

  
  
  // function numFormatter(num) {
  //   if (num > 999 && num < 1000000) {
  //     return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
  //   } else if (num >= 1000000) {
  //     return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
  //   } else if (num < 900) {
  //     return num; // if value < 1000, nothing to do
  //   }
  // }

  // function valuetext(number) {
  //     return `${number}°C`;
  // }
  
  return (
    <div className='vibesUI--timing_slider'>
      <Box>
        {props.qty > 1 && <Slider 
          value={value}
          min={0}
          step={null}
          max={maxTimes}
          // valueLabelFormat={numFormatter}
          marks={timingMarks}
          // scale={calculateValue}
          onChange={handleChange}
          valueLabelDisplay="off"
        />}
      </Box>
      <p><i>{props.qty > 1 ? 'All values in beats' : '(only works for 2 or more fireworks)'}</i></p>
    </div>
  )
}