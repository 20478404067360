import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../components/checkbox';
import { SpinnerOverlay } from '../../components/spinner-overlay';
import { useFormik, FormikProvider } from 'formik';
// import LableInline from '../../components/label-inline';
import SelectCustom from '../../components/SelectCustom';
import { genres, pronouns } from '../../shared/defaultValue';
import { Col, Container, Row } from 'react-bootstrap';
// import Button from '../../components/button';
import { getProfileRequest, updateProfileRequest } from '../../slices/user';
import { isLoading } from '../../selectors/user.selector';
import Select from 'react-select';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import './style.scss';
import AvatarUpload from '../../components/avatar-upload';
import AvatarUploadAccountSettings from '../../components/avatar-upload-account-settings';
import userAPI from '../../services/UserAPI';

const AccountSetting = ({ getUser, initGenres }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const [valueInput, setValueInput] = useState('');
  const [valueImage, setValueImage] = useState('');
  const [loadImage, setLoadImage] = useState(false);
  const initPronoun = useMemo(() => {
    return pronouns.filter((element) => element.value === getUser?.pronoun);
  }, [getUser]);
  const updateProfileSchema = Yup.object().shape({
    genre: Yup.array().required('Required')
  });
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: updateProfileSchema,
    initialValues: {
      pronoun: initPronoun,
      genre: initGenres,
      marketingOptIn: getUser?.marketingOptIn
      // username: '',
    },
    onSubmit: (values) => {
      const genres = values?.genre?.map((item) => item.value);
      const newform = {
        // pronoun: values?.pronoun[0].value || '',
        pronoun: values?.pronoun[0].value,
        genre: genres.toString() || '',
        marketingOptIn: values?.marketingOptIn
        // username: valueInput
      };
      dispatch(updateProfileRequest(newform));
    }
  });
  // set value into form
  useEffect(() => {
    if (isEmpty(getUser)) return;
    console.log();
    formik.setValues({
      pronoun: initPronoun,
      genre: initGenres,
      marketingOptIn: getUser?.marketingOptIn
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, getUser]);
  const getImage = (value) => {
    setValueImage(value);
  };
  // useEffect(() => {
  //   console.log(valueImage);
  // }, [valueImage]);
  const setImageButton = async () => {
    setLoadImage(true);
    // setLoading(true);
    const formData = new FormData();
    formData.append('avatar', valueImage);
    const res = await userAPI.uploadAvatar(formData);
    if (res) setLoadImage(false);
    dispatch(getProfileRequest());
  };
  return (
    <>
      <SpinnerOverlay loading={loading} />
      <div className="wrapper-form-schedule">
        <div className="form-schedule-top">
          <div className="shedule-text shedule-title">Account Settings</div>
          <div className="shedule-text shedule-description">
            Update your accounts preferences. Change anything you want on the
            following form.
          </div>
        </div>
        <FormikProvider value={formik}>
          <Container fluid="xxl">
            <Row style={{ rowGap: '10px' }}>
              <Col xs={12} md={4}>
                <AvatarUpload
                  loadImage={loadImage}
                  getUser={getUser}
                  getImage={getImage}
                />
              </Col>
              <div className="hr-form-group_hr">
                <div className="hr-form-group"></div>
              </div>
              <Col xs={12} md={5}>
                <div className="form-group">
                  <label>
                    Your username
                    <div>
                      <input
                        type="text"
                        name="userName"
                        onChange={(e) => {
                          setValueInput(e.target.value);
                        }}
                        value={formik.values.userName}
                        className="input-name"
                        placeholder={getUser?.displayName}
                      />
                    </div>
                  </label>
                  <label>
                    Your Email
                    <div>
                      <input
                        disabled
                        type="email"
                        className="input-name"
                        placeholder={getUser?.email}
                      />
                    </div>
                  </label>
                  {/*<LableInline classNameColor='special' label='PRONOUNS' />{' '}*/}
                  <label>
                    Your pronouns
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          background: '#fff',
                          borderColor:
                            formik.touched['pronoun'] &&
                            formik.errors['pronoun']
                              ? '#dc3545'
                              : '#9e9e9e',
                          color: '#696c79',
                          padding: '0 10px',
                          fontFamily: "'Futura', Arial, Helvetica, sans-serif",
                          height: 46,
                          minHeight: 46
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          margin: '0px'
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          height: '46px',
                          padding: '0 6px'
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          fontFamily: "'Futura', Arial, Helvetica, sans-serif",
                          height: 46,
                          minHeight: 46,
                          margin: '0px'
                        }),
                        option: (base) => ({
                          ...base,
                          fontSize: '14px',
                          color: '#696c79',
                          fontFamily: "'Futura', Arial, Helvetica, sans-serif"
                        }),
                        indicatorSeparator: (state) => ({
                          display: 'none'
                        }),
                        indicatorsContainer: (provided, state) => ({
                          ...provided
                        })
                      }}
                      name="pronoun"
                      onChange={(opt, e) => {
                        formik.setFieldValue('pronoun', [opt]);
                      }}
                      value={formik.values.pronoun}
                      placeholder="Pronouns"
                      options={pronouns}
                    />
                  </label>
                  {formik.touched.pronoun && formik.errors.pronoun ? (
                    <div className="error">{formik.errors.pronoun}</div>
                  ) : null}
                </div>
                <label style={{ color: ' #969696' }}>
                  Music Genre
                  <SelectCustom
                    isMulti={true}
                    data={genres || []}
                    onChange={(opt, e) => {
                      formik.setFieldValue('genre', opt);
                    }}
                    name="genre"
                    className="select-music-genres"
                    placeholder="Music Genres"
                    customIndicator=" "
                    formik={formik}
                  />
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className="form-group">
                  <div className="wrapper-control">
                    <Checkbox formik={formik} name="marketingOptIn">
                      I would like to sign up to receive email updates & news
                      from Frisson. We never share or sell your personal
                      information.
                    </Checkbox>
                  </div>
                  {/* <div className="form-group hidden">
                  <div className="form-label">Your Password</div>
                  <InputFormik
                    icon="fa-lock"
                    type="password"
                    name="password"
                    formik={formik}
                    placeholder="Password"
                  />
                  <InputFormik
                    icon="fa-lock"
                    type="password"
                    name="confirmPassword"
                    formik={formik}
                    placeholder="Confirm Password"
                  />
                  <div className="form-text">Change</div>
                </div> */}
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-login-profile-note hidden">
                  <div className="profile-note-body">
                    <div className="note-title">Delete my account</div>
                    <div className="note-body">
                      You will receive an email to confirm your decision. Please
                      note, that all the playlists, watch later shows and
                      preferences you have created will be permanently erased.
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </FormikProvider>
        <div className="form-footer">
          <button
            className="button-save"
            type="button"
            onClick={() => {
              setImageButton();
              formik.handleSubmit();
            }}
          >
            SAVE
          </button>
          {/*<Button*/}
          {/*  btnText={'SAVE'}*/}
          {/*  type='button'*/}
          {/*  btnStyle={'rounded'}*/}
          {/*  onClick={formik.handleSubmit}*/}
          {/*  size={'sd'}*/}
          {/*  color={'theme'}*/}
          {/*/>*/}
        </div>
      </div>
    </>
  );
};
export default AccountSetting;
