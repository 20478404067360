import React from "react";
import { isEmpty } from "lodash";
import ButtonVideo from "../../components/button-video";
import { redirectPage } from "../../shared/utils/helpers";
import "./styles.scss";

const CardLive = ({ item }) => {
  if (isEmpty(item)) {
    return <div />;
  }

  return (
    <div
      className="cart-live-now wow move-up"
      onClick={(e) => redirectPage(e, `jointhecrowd?id=${item?.id}`)}
    >
      <div className="box-live-now">
        <img src={item?.thumbnailUri} alt="" />
        <div className="container-live-now">
          <div className="content-live-now-top">
            <div className="live-now-left">
              {item?.genre?.split(",")?.map((item, index) => (
                <div className="now-item" key={index}>
                  {item}
                </div>
              ))}
            </div>
            <div className="live-now-right">
              <div className="number-watch">{item?.totalConnections}</div>
              <div className="number-watch people">In the crowd</div>
            </div>
          </div>
          <div className="content-live-now-body ">
            <div className="show-live__box">
              <div className="live-indicator" />
              <div className="live-text">{`${item.status} Now!`}</div>
            </div>
          </div>
          <div className="content-live-now-bottom">
            <div className="live-now-bottom-left">
              <div className="left__title">{item?.targetArtistDisplayName}</div>
              <div className="left__description">{item?.streamTitle}</div>
            </div>
            <div className="live-now-bottom-right">
              <ButtonVideo videoId={`jointhecrowd?id=${item?.id}`} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLive;
