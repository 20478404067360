import { call, put, all, takeLatest } from "redux-saga/effects";
import {
  getFriendsListRequest,
  getFriendsListSuccess,
  getFriendsListError,
  requestFriendRequest,
  requestFriendSuccess,
  requestFriendError,
  acceptFriendRequestRequest,
  acceptFriendRequestSuccess,
  acceptFriendRequestError,
  declineFriendRequestRequest,
  declineFriendRequestSuccess,
  declineFriendRequestError
} from "../slices/friendsList";
import { toastConfig } from "../shared/defaultValue";
import friendsListService from "../services/FriendsListAPI";
import toast from "react-hot-toast";

const arrayToString = (array, key) =>
  (array?.map((item) => `"${key ? item[key] : item}"`) || []).join();

function* friendsList() {
  try {
    const result = yield call(friendsListService.getProfileUser, {});
    if (result.success) {
      yield put(getFriendsListSuccess(result.data));
    } else {
      toast.error(result.message, toastConfig);
      yield put(getFriendsListError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getFriendsListError(error.message));
  }
}

function* requestFriend({ payload }) {
  try {
    const result = yield call(friendsListService.requestFriend, payload);
    if (result.success) {
      yield put(requestFriendSuccess(result.data));
      yield put(getFriendsListRequest());
      if (result.data.failureList.length > 0) {
        let errorMessage = `Send friend request to ${arrayToString(
          result.data.failureList,
          "friendId"
        )} failed`;
        toast.error(errorMessage, toastConfig);
      }
      let successMessage = `Send friend request to ${arrayToString(
        result.data.successList,
        ""
      )} successfully`;
      toast.success(successMessage, toastConfig);
    } else {
      let errorMessage = `Send friend request to ${arrayToString(
        result.details,
        "friendId"
      )} failed`;
      toast.error(errorMessage, toastConfig);
      yield put(requestFriendError(errorMessage));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(requestFriendError(error.message));
  }
}

function* acceptFriendRequest({ payload }) {
  try {
    const result = yield call(friendsListService.acceptFriendRequest, payload);
    if (result.success) {
      yield put(acceptFriendRequestSuccess(result.data));
      yield put(getFriendsListRequest());
      toast.success("Friend request accepted", toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(acceptFriendRequestError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(acceptFriendRequestError(error.message));
  }
}

function* declineFriendRequest({ payload }) {
  try {
    const result = yield call(friendsListService.declineFriendRequest, payload);
    if (result.success) {
      yield put(declineFriendRequestSuccess(result.data));
      yield put(getFriendsListRequest());
      toast.success("Friend request declined", toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(declineFriendRequestError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(declineFriendRequestError(error.message));
  }
}

export function* friendsSaga() {
  yield all([takeLatest(getFriendsListRequest.type, friendsList)]);
  yield all([takeLatest(requestFriendRequest.type, requestFriend)]);
  yield all([takeLatest(acceptFriendRequestRequest.type, acceptFriendRequest)]);
  yield all([
    takeLatest(declineFriendRequestRequest.type, declineFriendRequest)
  ]);
}
