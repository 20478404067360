import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Input from "../input";
import { useFormik } from "formik";
import * as Yup from "yup";

import { submissionRequest } from "../../slices/user";

const NewsletterFormThree = () => {
  const dispatch = useDispatch();

  const EmailSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required")
  });
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: EmailSchema,
    onSubmit: (values) => {
      const newForm = {
        type: "newsletter",
        formData: {
          email: values.email
        }
      };

      dispatch(submissionRequest(newForm));
    }
  });

  return (
    <form id="footer">
      <Row>
        <Col xs={12}>
          <div className="input-box mb--10">
            <Input
              id={"newsletter_form_4"}
              name="email"
              type={"email"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={"Your e-mail"}
            />
            <button
              type="button"
              className="newsletter"
              onClick={formik.handleSubmit}
            >
              SEND
            </button>
          </div>
          {formik.touched["email"] && formik.errors["email"] ? (
            <div className="error">{formik.errors["email"]}</div>
          ) : null}
          <p style={{ color: "white", fontSize: "12px" }}>
            By submitting your email, you accept our{" "}
            <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
              privacy policy.
            </Link>
          </p>
        </Col>
      </Row>
    </form>
  );
};

export default NewsletterFormThree;
