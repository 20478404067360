import React from 'react';
import ButtonVideo from '../../components/button-video';
import { redirectPage } from '../../shared/utils/helpers';
import './styles.scss';
import icons from '../../constants/icon';

const GalleryItem = ({ item, className = 'normal' }) => {
  const redirectUrl = () => {
  };
  return (
    <div
      className={`gallery normal ${className}`}
      onClick={(e) => redirectPage(e, `jointhecrowd?id=${item?.id}`)}
      style={{ minHeight: '300px' }}
    >
      {/*<img src={item?.thumbnailUri} alt='' />*/}
      <img src={item?.thumbnailUri ? item?.thumbnailUri : icons.defaultImage} className={!item?.thumbnailUri ? 'defaultImg' : ''} alt={''}/>
      <div className='content-top-item'>
        <div className='genres-item'>
          <div className='now-item'>{item?.genre || 'POP'}</div>
        </div>
      </div>
      <div className={'live-now-bottom-right'}>
        <ButtonVideo videoId={`jointhecrowd?id=${item?.id}`} />
      </div>
      <div className='content-bottom-show'>
        <div className='desc'>
          {item?.targetArtistDisplayName}
          <p> {item?.streamTitle}</p>
        </div>
      </div>
    </div>
  );
};
export default GalleryItem;
