import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userAPI from '../../services/UserAPI';
import { Spinner } from '../spinner';
import { isLoading } from '../../selectors/user.selector';
import defaultuser from '../../assets/img/edit-profile/defaultuser.png';
import { getProfileRequest } from '../../slices/user';
import './styles.scss';
import sprite from '../../assets/svg/sprite.svg';
import { preventDefault } from '@fullcalendar/core';

const ImageUploadStreamContent = ({
  buttonColor,
  getImageImage,
  isImage,
  loadImage,
  buttonName,
  title,
  changeTitleStreamContent
}) => {
  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageImage, setUploadImageImage] = useState('');
  const dispatch = useDispatch();
  const isloading = useSelector(isLoading);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(loadImage);
  }, [loadImage]);
  // const uploadPicture = async (e) => {
  //   console.log('3333',e.target.files[0])
  //   setLoading(true);
  //   const formData = new FormData();
  //   formData.append('avatar', e.target.files[0]);
  //   const res = await userAPI.uploadAvatar(formData);
  //   if (res) setLoading(false);
  //   // dispatch(getProfileRequest());
  // };
  const encodeImageFileAsURL = (element) => {
    let file = element.target.files[0];
    setUploadImageImage(element.target.files[0]);
    let reader = new FileReader();
    reader.onloadend = function () {
      // console.log('RESULT', reader.result)
      setUploadImage(reader.result);
    };
    reader.readAsDataURL(file);
    getImageImage(file);
  };
  return (
    <>
      <div aria-label="Change Profile Picture" className="block-avatar-upload">
        <div className="stream-grid">
          <span>
            <input
              type="text"
              style={{ opacity: '1', position: 'relative', border: 'none' }}
              onChange={(e) => {
                changeTitleStreamContent(e.target.value);
              }}
              value={title}
            />
          </span>
          {loading || isloading ? (
            <Spinner />
          ) : (
            <>
              <div
                className="image-button-upload"
                style={{ backgroundColor: buttonColor ? '#FF6E09' : '' }}
              >
                <input
                  className="input-ava"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={encodeImageFileAsURL}
                />
                {buttonName ? buttonName : 'Change avatar'}
              </div>
            </>
          )}
        </div>
      </div>
      {uploadImage || isImage ? (
        <div className="video-stream">
          <div className="video-stream-svg" style={{display:'none'}}>
            <svg fill="#fff" style={{ width: '30px', height: '30px' }}>
              <use href={sprite + '#tre'}></use>
            </svg>
          </div>
          <img
            src={uploadImage !== '' ? uploadImage : isImage}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
      ) : (
        false
      )}
    </>
  );
};
export default ImageUploadStreamContent;
