import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

// layout
import { LayoutDefault } from "../../layouts";
import { Header } from "../../components/header";
import { Footer } from "../../container/footer";

// container
import RecentlyLive from "../../container/recently-live";
import ListRecommendedShows from "../../container/list-recommended-shows";

import "./styles.scss";

// data
import frisson from "../../data/frisson.json";

const Recommendations = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{`${frisson.title}`}</title>
      </Helmet>
      <LayoutDefault className="template-color-1 template-font-2 bg_color--3">
        <Header />
        <div className="wrapper-genres-page">
          <div>
            <ListRecommendedShows />
          </div>
          <div>
            <RecentlyLive />
          </div>
        </div>
        <Footer />
      </LayoutDefault>
    </Fragment>
  );
};

export default Recommendations;
