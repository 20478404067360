import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { dataRemoteShedule } from '../../selectors/user.selector';
import { isSuccess } from '../../selectors/user.selector';
import { getUrls } from '../../selectors/tab.selector';
import TabRemotePerformance from '../../components/tab-remote-perfomance';
import OutboundStreamConnection from '../../components/outbound-stream-connection';
import SuperAdmins from '../../components/super-admins';
import RemoteStatus from '../../components/remote-status';
import CustomizationStreamContent from '../../components/customization-stream-content';
import UserAPI from '../../services/UserAPI';
import { roleUser } from '../../shared/utils/helpers';
import { Spinner } from '../../components/spinner';
import userService from '../../services/UserAPI';
import { getRemotePerConfRequest, setRefreshTokenRequest } from '../../slices/user';

const AddConnection = ({ profileUser }) => {
  const dispatch = useDispatch();
  const dataRem = useSelector(dataRemoteShedule);
  const [tabComponent, setTabComponent] = useState('2');

  const role = roleUser();

  const [isAccepted, setIsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.search?.includes('code')) {
      let str = window.location.href;
      let s = str.split('?');
      let y = s[1].slice(5).split('&');
      userService.setPayPalCode(y[0]).then(r => console.log('setPayPalCode --> ', r));
      // const str = '?code=C21AALLko4epMa0T1zjGEh-Km-r6w5-OiCj8P-rQxRFXlvRPJ3glrLpuWf-fi3TBOsJcdA1uTG8UfSaTSLFq0NFqKAhz3SpJQ&scope=openid'
      // userService.setPayPalCode(location.search.slice(6, -13)).then(r => console.log('setPayPalCode --> ', r));
    }
  }, [location]);
  const getSelectId = (value) => {
    setTabComponent(value);
  };

  const onAccept = () => {
    setLoading(true);
    UserAPI.acceptBuildAgreement()
      .then((res) => {
        if (res.success === true) {
          dispatch(setRefreshTokenRequest());
          dispatch(getRemotePerConfRequest());
          setIsAccepted(true);
          setLoading(false);
          console.log('dataRem onAccept', dataRem);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(false);
    (dataRem !== undefined && dataRem?.status !== null) ? setIsAccepted(true) : setIsAccepted(false);
    // UserAPI.getRemotePerformanceDataApi()
    //   .then((res) => {
    //     console.log('res', res);
    //     if (res.success) setIsAccepted(true);
    //   })
    //   .catch((err) => {
    //     console.log('err', err);
    //     throw err;
    //   })
    //   .finally(() => setLoading(false));
    setLoading(false);
  }, [dataRem]);
  return (
    <Container>
      <Row>
        <Col lg={12}>
          <h4 className='remote-performance-title'>Remote Performance</h4>
        </Col>
        <Col lg={8}>
          <p className='remote-performance-body'>
            Run a remote, reliable, 100% fully-automated performance using your
            custom stage, logo, video loops, and more.
          </p>
        </Col>
      </Row>
      {loading ? (
        <div className={'remote-build-agreement-spinner'}>
          <Spinner />
        </div>
      ) : isAccepted && role === 'Producer' ? (
        <>
          <TabRemotePerformance
            getSelectId={getSelectId}
            tabComponent={tabComponent}
          />
          {tabComponent === '1' && <RemoteStatus />}
          {tabComponent === '2' && <Weekly profileUser={profileUser} />}
          {tabComponent === '3' && <CustomizationStreamContent />}
        </>
      ) : (
        <div className='remote-build-agreement'>
          <p className='remote-build-agreement-title'>
            Remote Performance Build Agreement
          </p>
          <p className='remote-build-agreement-desc'>
            Remote performances run on dedicated hardware to maximize the
            stability and graphical output of your stream. It is fully
            customizable from this dashboard and works with existing data on
            your account, such as any saved stages.
          </p>
          <p className='remote-build-agreement-desc'>
            {' '}
            By clicking “I Accept” below, you agree to all the terms and
            conditions for using remote performances located at
            www.frisson.live/rpb-terms-and-conditions, including the cost of
            $0.XX per minute while dedicated hardware is running your
            performance.
          </p>
          <div className='remote-build-agreement-button'>
            <button onClick={onAccept}>I accept</button>
          </div>
        </div>
      )}
    </Container>
  );
};
export default AddConnection;
const Weekly = ({ profileUser }) => {
  return (
    <div
      style={{
        background: 'rgba(239, 239, 239, 0.1)',
        borderRadius: '10px',
        marginTop: '20px'
      }}
    >
      {/* <WeeklySchedule /> */}
      {/* <div className='remote-performance-body-hr'>
        <div className='remote-performance-hr'></div>
      </div> */}
      <Row style={{ padding: '20px 37px 20px 23px' }}>
        <Col lg={8}>
          <OutboundStreamConnection profileUser={profileUser} />
        </Col>
        <Col lg={4}>
          <SuperAdmins />
        </Col>
      </Row>
    </div>
  );
};
