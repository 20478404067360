import React, { useEffect, useState } from 'react';
import './styles.scss';
import moment from 'moment';

const SelectInputEarningBottom = ({
                                    element,
                                    isOrigin,
                                    meaning,
                                    getSelectInput,
                                    getPropsNone,
                                    vision,
                                    isEarnings,
                                    id
                                  }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValue, setIsValue] = useState('');
  const toggleOptionsBlur = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    setIsValue(isOrigin);
  }, []);
  useEffect(() => {
    !vision &&
    setIsOpen(false);
  }, [vision]);
  const toggleOptions = () => {
    setIsOpen(!isOpen);
    getPropsNone(!isOpen, id);
  };
  const selected = (val, e) => {
    e.preventDefault();
    setIsValue(val);
    getSelectInput(val, meaning);
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className='container-select-earning-bottom' style={{ position: 'absolute', bottom: '0' }}>
        <input
          name='select_value'
          type='hidden'
          id='selectedValue'
          value={isValue}
          onBlur={() => {
            toggleOptionsBlur();
          }}
        />
        <div
          className='display-value-earning-bottom'
          id='displayValue'
          onClick={toggleOptions}
        >
          <span className='value-text-earning-bottom' id='valueText'>
            {isValue}
          </span>
          <span
            className={
              isOpen === true ? 'arrow-earning-bottom active arrow-down' : 'arrow-earning-bottom arrow-down'
            }
            id='arrowControl'
          ></span>
        </div>
        <ul
          tabIndex='0'
          style={{ height: '205px', overflow: 'auto' }}
          className={
            isOpen === true ? 'select-container-earning-bottom active' : 'select-container-earning-bottom'
          }
          id='selectContainer'
        >
          {element.map((i, index) =>
            isOrigin === i ? (
              <li
                key={index}
                className='select-option-earning-bottom'
                onClick={() => {
                  selected(i);
                }}
              >
                {moment(i?.dateTime).format('M/D/YY')} - {i?.streamId}
                <span>{i?.dbAmount}dB</span>
              </li>
            ) : (
              <li
                key={index}
                className='select-option-earning-bottom'
                onClick={() => {
                  selected(i);
                }}
              >
                {moment(i?.dateTime).format('M/D/YY')} - {isEarnings ? i?.streamId : i?.payoutMethod}
                <span>{isEarnings ? i?.dbAmount + ' dB' : i?.amountUsdCents + ' usd'}</span>
              </li>
            )
          )}
        </ul>
      </div>
    </>
  );
};
export default SelectInputEarningBottom;
