import gsap from "gsap"
import { CheckTutorialStep, tutState } from "shared/audiophile/onboarding"
import { SendGTMEvent } from "shared/utils/gtmEvents"
import { user, titleId, streamId, ChatInput } from "./chatManager"

let curBundles
let overallHeight
let listenForPurchaseFocus = false
let leftForPurchase = false
let userCurrency
let frissonSplit = null

window.addEventListener("focus", function(event) 
{ 
    if (listenForPurchaseFocus && leftForPurchase) {
        //presumably returning from paypal, check user account
        CompareCurrency()
    }
}, false);

// Example of the blur event as opposed to focus
window.addEventListener("blur", function(event) { 
    if (listenForPurchaseFocus) {
        leftForPurchase = true
    }
}, false);


export async function InitVibes () {
    //get user decibels
    //display all bundles
    //grey out any unavailable

    document.getElementById('vibesToggle').classList.toggle('selected')
    document.getElementById('vibesMenu').classList.toggle('hidden')

    if (document.getElementById('vibesToggle').classList.contains('selected')) {
        // userCurrency = await GetCurrency()
        // const bundles = await GetBundles(userCurrency)

        // console.log(bundles)

        // const height = parseFloat(document.getElementById('message-init').style.transform.split('translateY(')[1].split('px')[0])

        // const sortedBundles = SortBundles(bundles)
        // //save for later
        // curBundles = sortedBundles

        // DisplayBundles(sortedBundles, userCurrency,  height)

        CheckTutorialStep("OpenedVibes")
    }
    else {
        //loop through and reset heights and delete
        // HideBundles()
        
    }

    
    
    
}

function SelectVibeBundle (bundle) {
    console.log(bundle)

    if (document.querySelector('.' + bundle.id).classList.contains('selected')) {
        //deselect and hide from box
        document.querySelector('.' + bundle.id).classList.remove('selected')
        document.getElementById('vibeContainer').classList.add('hidden')
        document.getElementById('message-init').classList.remove('vibeActive')

        document.getElementById('messageInput').removeAttribute('data-vibe')
        document.getElementById('messageInput').removeAttribute('data-vibeName', bundle.name)
    }
    else {
        //select and add to box
        //reset all bundles 
        const bundleEls = document.querySelectorAll('.vibe')
        bundleEls.forEach(el => {
            el.classList.remove('selected')
        });

        document.querySelector('.' + bundle.id).classList.add('selected')

        document.getElementById('vibeType').textContent = bundle.name
        document.getElementById('vibeContainer').classList.remove('hidden')
        document.getElementById('message-init').classList.add('vibeActive')

        document.getElementById('messageInput').setAttribute('data-vibe', bundle.id)
        document.getElementById('messageInput').setAttribute('data-vibeName', bundle.name)
        document.getElementById('vibesMenu').classList.add('hidden')
    }

    HideBundles()
}

export async function SendVibes (vibe) {

    let selectedVibe = curBundles.filter(obj => {
        return obj.id === vibe
      })
    selectedVibe = selectedVibe[0]
    const sentVibes = await fetch(
        'https://'+ titleId + '.playfabapi.com/Client/PurchaseItem',
        {
            method: 'POST',
            headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization': user.playFabSessionToken
            },
            body: '{"ItemId":"'+selectedVibe.id+'","Price":"'+selectedVibe.price+'","VirtualCurrency":"'+selectedVibe.currencyType+'","CatalogVersion": "Vibes Items", "CustomTags":{"streamId":"'+streamId+'"}}'
        }
    )
    .then(function (response) {
        if (response.status >= 400 && response.status < 600) {
            // failure in getting totals
        } else if (response.status === 200) {
            return response.json()
        } else {
            // failure in getting totals
            return null
        }
    })
    .then(async (data) => {
        if (data) {
            // send vibes gtm event
            let purchases = data.data.Items

            if (!frissonSplit) frissonSplit = await GetSplit()

            for (let i = 0; i < purchases.length; i++) {
                const purchase = purchases[i];
                SendGTMEvent({   
                    'event': 'purchase_vibes',
                    'transaction_id': purchase.ItemInstanceId,
                    'affiliation': streamId,
                    'currency': 'USD',
                    'value': purchase.UnitPrice, //((purchase.UnitPrice * 0.01) * frissonSplit).toFixed(2), // GA4 requires the value in cents, which I think this is
                    'items': [{
                        'item_name': purchase.DisplayName,
                        'item_id': purchase.ItemId,
                        'price': purchase.UnitPrice,//(purchase.UnitPrice * 0.01).toFixed(2),
                        'item_brand': 'frisson',
                        'item_category': 'Vibes',
                        'quantity': 1
                    }]
                })
            }
            

            CheckTutorialStep("SentVibes")


            return true
        }
    })
    .catch(function (err) {
        //no existing session with this id
        console.error(err.toString())
        return null
    })

    return sentVibes
}

function SortBundles (bundles) {

    let n = bundles.length;
        
    for(let i = 0; i < n; i++) {
        // Finding the smallest number in the subarray
        let min = i;
        for(let j = i+1; j < n; j++){
            if(bundles[j].price < bundles[min].price) {
                min=j; 
            }
         }
         if (min != i) {
             // Swapping the elements
             let tmp = bundles[i]; 
             bundles[i] = bundles[min];
             bundles[min] = tmp;      
        }
    }
    return bundles
}

export function HideBundles (close) {

    document.getElementById('vibesToggle').classList.remove('selected')

    const height = parseFloat(document.getElementById('message-init').style.transform.split('translateY(')[1].split('px')[0])

    gsap.to('.vibe', {y: height, duration: 0.4} )
    gsap.to('#vibesLow', {y: height, duration: 0.4} )
    gsap.to('#vibesHeader', {y: height, duration: 0.4, onComplete: () => {
        RemovePackages()
        RemoveBundles(close)
        
    }} )
}

export function RemoveBundles (close) {
    document.querySelectorAll('#messageContainer .vibe').forEach(e => e.remove())
    document.getElementById('vibesHeader').classList.add('hidden')
    document.getElementById('vibesLow').classList.add('hidden')
    if (close) ChatInput()
}

function DisplayBundles (bundles, userCurrency, height) {

    //check for existing selection
    let selectedVibe = document.getElementById('messageInput').getAttribute('data-vibe') 

    overallHeight = (height - ((bundles.length + 1) * 55) - 130)

    //move footer
    document.getElementById('vibesLow').classList.remove('hidden')
    document.getElementById('vibesLow').innerHTML = ''
    document.getElementById('vibesLow').insertAdjacentHTML('afterbegin', '<div class="trigger">Low on vibes? ➡️</div>')
    gsap.fromTo('#vibesLow', {y: (height)}, {y: (height - 105), duration: 0.4} )

    for (let i = 0; i < bundles.length; i++) {
        const bundle = bundles[i];
        const selectedClass = bundles[i].id == selectedVibe ? 'selected' : ''
        const canAfford = bundle.canAfford? '' : 'locked'
        const bundleEl = '<div class="vibe point '+ canAfford + ' ' + bundle.id + ' ' + selectedClass + '" style="display: block; transform: translateY('+ height +');" data-price="'+ bundle.price +'"><div class="vibe-item">' + bundle.name + ' - ' + bundle.price +'</div></div>'
        document.getElementById('messageContainer').insertAdjacentHTML('afterbegin', bundleEl)
        //const id = '.' + bundle.id
        //add onclick
        if (bundle.canAfford) {
            document.querySelector('.' + bundle.id).addEventListener('click', function (event) {
                event.stopPropagation();
                SelectVibeBundle(bundle)
            })
        }
        
        gsap.fromTo('.' + bundle.id, {y: (height)}, {y: height - ((i+2) * 55) - 50, duration: 0.4} )
    }

    //move header
    document.getElementById('vibesHeader').classList.remove('hidden')
    document.getElementById('vibesHeader').innerHTML = ''
    document.getElementById('vibesHeader').insertAdjacentHTML('afterbegin', '<div>SEND    V I B E S</div><div>'+userCurrency[bundles[0].currencyType]+ bundles[0].currencyType + '</div>')
    gsap.fromTo('#vibesHeader', {y: (height)}, {y: overallHeight, duration: 0.4} )


}

export async function DisplayPackages () {

    document.getElementById('vibesLow').classList.toggle('selected')

    if (document.getElementById('vibesLow').classList.contains('selected')) {
        const packages = await GetPackages()

        for (let i = 0; i < packages.length; i++) {
            const RMBundle = packages[i];
            
            const bundleEl = '<div class="rm-package ' + RMBundle.id + '" style="display: block;" data-price="'+ RMBundle.price +'"><div class="package-item"><div class="value">' + RMBundle.value +'</div><div class="price">' + RMBundle.displayPrice + '</div></div>'
            document.getElementById('vibesLow').insertAdjacentHTML('afterbegin', bundleEl)
            //const id = '.' + bundle.id
            //add onclick
            document.querySelector('.' + RMBundle.id).addEventListener('click', function (event) {
                event.stopPropagation();
                SelectRMBundle(RMBundle)
            })
            
            
            // gsap.fromTo('.' + RMBundle.id, {y: (height)}, {y: (height - ((i+1) * 60) - 60), duration: 0.4} )
        }
        const height = parseFloat(document.getElementById('message-init').style.transform.split('translateY(')[1].split('px')[0])
        const startingHeight = (height - 80)
        const newHeight = startingHeight - overallHeight + 15 
        gsap.to('#vibesLow', {height: newHeight, duration: 0.5})
        gsap.to('#vibesLow', {y: overallHeight, duration: 0.4})

        document.querySelector('#vibesLow .trigger').textContent = "⬅️ Return to Vibes"
    }
    else {
        RemovePackages()
        document.querySelector('#vibesLow .trigger').textContent = "Low on vibes? ➡️"
    }
    
}

export function RemovePackages () {
    document.querySelectorAll('#vibesLow .rm-package').forEach(e => e.remove())
    const height = parseFloat(document.getElementById('message-init').style.transform.split('translateY(')[1].split('px')[0])
    const startingHeight = (height - 105)
    gsap.to('#vibesLow', {height: 40, duration: 0.5})
    gsap.to('#vibesLow', {y: startingHeight, duration: 0.4})
}


let curOrder = {}
function SelectRMBundle(bundle) {

    bundle.rawValue = bundle.value.split('DB')[0]
    curOrder.bundle = bundle

    //open blank window and save it to have url set once we get it back
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=0,height=0,left=-1000,top=-1000`;

    var theWindow = window.open('','purchase', params)

    fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
        method: "POST",
        headers: {
            "Accept" : "text/plain",
            "X-EntityToken" : user.entityToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({

            "FunctionName" : "PlayPal_StartPurchase",
            "PlayFabId" : user.playFabId,
            "FunctionParameter" : {
                "itemId" : bundle.id,
                "ticket" : user.playFabSessionToken
            }


        })
    })
    .then(response => response.json())
    .then(data => {

        curOrder.order = data.data.FunctionResult

        // SendGTMEvent({
        //     'ecommerce': {
        //       'purchase': {
        //         'actionField': {
        //           'id': curOrder.order.orderId,                         // Transaction ID. Required for purchases and refunds.
        //           'affiliation': streamId,
        //           'revenue': curOrder.order.price - curOrder.bundle.rawValue,                     // Total transaction value (incl. tax and shipping)
        //         },
        //         'products': [{                            // List of productFieldObjects.
        //           'name': curOrder.bundle.name,     // Name or ID is required.
        //           'id': curOrder.bundle.id,
        //           'price': (curOrder.bundle.price * 0.01).toFixed(2),
        //           'brand': 'frisson',
        //           'category': 'vibes',
        //           'quantity': 1,                         // Optional fields may be omitted or set to empty string.
        //          }]
        //       }
        //     }
        //   })

        fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
            method: "POST",
            headers: {
                "Accept" : "text/plain",
                "X-EntityToken" : user.entityToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                "FunctionName" : "PlayPal_PayForPurchase",
                "PlayFabId" : user.playFabId,
                "FunctionParameter" : {
                    "orderId" : curOrder.order.orderId,
                    "ticket" : user.playFabSessionToken
                }


            })
        })
        .then(response => response.json())
        .then(data => {

            redirectToPurchase(theWindow, data.data.FunctionResult.confirmationURL)

        });

    });
}

function redirectToPurchase (thewindow, url) {
    listenForPurchaseFocus = true
    thewindow.location = url
}



function confirmPurchase() {
    fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
        method: "POST",
        headers: {
            "Accept" : "text/plain",
            "X-EntityToken" : user.entityToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({

            "FunctionName" : "PlayPal_ConfirmPurchase",
            "PlayFabId" : user.playFabId,
            "FunctionParameter" : {
                "orderId" : BigInt(orderId).toString('16').toString().toUpperCase(),// eslint-disable-line
                "ticket" : user.playFabSessionToken
            }


        })
    })
    .then(response => response.json())
    .then(data => {

        if (data.status === 200) {
            alert("Successful purchase!")
        }

    });
}

async function CompareCurrency() {
    let curCurrency = await GetCurrency()
    if (curCurrency["DB"] > userCurrency["DB"]) {
        //we now have more money than before
        listenForPurchaseFocus = false
        leftForPurchase = false

        document.getElementById('vibesToggle').classList.toggle('selected')

        RemovePackages()
        RemoveBundles()
        InitVibes()

        //send GTM event
        SendGTMEvent({
            'event': 'purchase_vc',
            'transaction_id': curOrder.order.orderId,
            'affiliation': streamId, // Can comment this out on atm.js
            'currency': 'USD',
            'value': curOrder.bundle.VirtualCurrencyPrices.RM , // GA4 requires the value in cents, which I think this is
            'items': [{
                'item_name': curOrder.bundle.DisplayName,
                'item_id': curOrder.bundle.ItemId,
                'price': curOrder.bundle.VirtualCurrencyPrices.RM ,
                'item_brand': 'frisson',
                'item_category': 'Virtual Currency', // I think in the current implementation, we have this falsely labeled as 'Vibes'?
                'quantity': 1
            }]
        })

        
    }
}

async function GetCurrency() {
    const currency = await fetch(
        'https://'+ titleId + '.playfabapi.com/Client/GetUserInventory',
        {
            method: 'POST',
            headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization': user.playFabSessionToken
            }
        }
    )
    .then(function (response) {
        if (response.status >= 400 && response.status < 600) {
            // failure in getting totals
        } else if (response.status === 200) {
            return response.json()
        } else {
            // failure in getting totals
            return null
        }
    })
    .then((data) => {
        if (data) {
            // display the new total somewhere
            return data.data.VirtualCurrency
        }
    })
    .catch(function (err) {
        //no existing session with this id
        console.error(err.toString())
        return null
    })
    
    return currency
}

async function GetPackages() {
    const allPackages = await fetch(
        'https://'+ titleId + '.playfabapi.com/Client/GetCatalogItems',
        {
            method: 'POST',
            headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization': user.playFabSessionToken
            },
            body: '{"CatalogVersion": "RM Items"}'

        }
    )
    .then(function (response) {
        if (response.status >= 400 && response.status < 600) {
            // failure in getting totals
        } else if (response.status === 200) {
            return response.json()
        } else {
            // failure in getting totals
            return null
        }
    })
    .then((data) => {
        if (data) {
            // display the new total somewhere
            return data.data.Catalog
        }
    })
    .catch(function (err) {
        //no existing session with this id
        console.error(err.toString())
        return null
    })
    
    return EvalPackages(allPackages)
}

function EvalPackages(allPackages) {
    
    const RMBundles = []

    allPackages.forEach(bundle => {
        const newBundle = {
            'id' : bundle.ItemId,
            'name' : bundle.DisplayName,
            'price' : Object.values(bundle.VirtualCurrencyPrices)[0],
            'displayPrice' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((Object.values(bundle.VirtualCurrencyPrices)[0] * 0.01)),
            'value' : `${Object.values(bundle.Bundle.BundledVirtualCurrencies)[0]}${Object.keys(bundle.Bundle.BundledVirtualCurrencies)[0]}`
        }
        
        RMBundles.push(newBundle)
        
    });

    return RMBundles
}

async function GetBundles(userCurrency) {
    const allBundles = await fetch(
        'https://'+ titleId + '.playfabapi.com/Client/GetCatalogItems',
        {
            method: 'POST',
            headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization': user.playFabSessionToken
            },
            body: '{"CatalogVersion": "Vibes Items"}'

        }
    )
    .then(function (response) {
        if (response.status >= 400 && response.status < 600) {
            // failure in getting totals
        } else if (response.status === 200) {
            return response.json()
        } else {
            // failure in getting totals
            return null
        }
    })
    .then((data) => {
        if (data) {
            // display the new total somewhere
            return data.data.Catalog
        }
    })
    .catch(function (err) {
        //no existing session with this id
        console.error(err.toString())
        return null
    })


    
    return EvalBundles(allBundles, userCurrency)
}

function EvalBundles(allBundles, userCurrency) {
    
    const bundles = []

    //add in the free vibe bundle for tutorial only
    if (tutState == 1) {
        const freeBundle = {
            'id' : 'vibes-0',
            'name' : 'Free Vibes',
            'price' : 0,
            'canAfford' : true, 
            'displayPrice' : '0DB',
            'currencyType' : 'DB'
        }
        
        bundles.push(freeBundle)
    }

    allBundles.forEach(bundle => {
        const currencyType = Object.keys(bundle.VirtualCurrencyPrices)[0]
        const price = Object.values(bundle.VirtualCurrencyPrices)[0]

        const newBundle = {
            'id' : bundle.ItemId,
            'name' : bundle.DisplayName,
            'price' : price,
            'canAfford' : userCurrency[currencyType] >= price ? true : false, 
            'displayPrice' : price.toString() + currencyType.toString(),
            'currencyType' : currencyType
        }
        
        bundles.push(newBundle)
        
    });

    return bundles
}

async function GetSplit() {
    return await fetch(
        'https://'+ titleId + '.playfabapi.com/Client/GetTitleData',
        {
            method: 'POST',
            headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization': user.playFabSessionToken
            },
            body: '{"Keys": ["frissonSplit"]}'
        }
    )
    .then(function (response) {
        if (response.status >= 400 && response.status < 600) {
            // failure in getting totals
        } else if (response.status === 200) {
            return response.json()
        } else {
            // failure in getting totals
            return null
        }
    })
    .then((data) => {
        if (data) {
            // send vibes gtm event
            return data.data.Data.frissonSplit
        }
    })
    .catch(function (err) {
        //no existing session with this id
        console.error(err.toString())
        return null
    })
    
    
}