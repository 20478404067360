import React, { useCallback, useState } from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import FriendsListItem from '../../components/friends-list/FriendsListItem';
import WaitingResponseListItem from '../../components/friends-list/WaitingResponseListItem';
import RequestedListItem from '../../components/friends-list/RequestedListItem';
import AccordionItem from '../../components/accordion/AccordionItem';
import './styles.scss';
import AddNewFriendModal from '../../components/friends-list/AddNewFriendModal';
import ButtonIcon from '../../components/button-icon';
import ButtonFriends from '../../components/button-friends';
import { SpinnerOverlay } from '../../components/spinner-overlay';
import { useSelector } from 'react-redux';
import { isLoading } from '../../selectors/friends.selector';

const FriendsListDetails = ({ data }) => {
  const loading = useSelector(isLoading);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = useCallback(() => setOpenModal(false), []);
  return (
    <div className='wrapper-friends-list'>
      <SpinnerOverlay loading={loading} />
      <AddNewFriendModal
        openModal={openModal}
        onCloseModal={handleCloseModal}
      />

      <div className='grid-add-friends'>
        <h2>Friends</h2>
        <ButtonFriends
          btnText='+ ADD FRIEND'
          type='button'
          btnStyle='rounded'
          size='sd'
          color='tarkis'
          onClick={handleOpenModal}
        />
      </div>
      <div className='container-friend'>
        {data?.requested?.length > 0 &&
          <Container>
            <Row>
              <Col xs={12} sm={12}>
                <h5 className='title-friends'>Request <span>{data?.requested?.length}</span></h5>
              </Col>
            </Row>
          </Container>}
        {data?.requested?.length > 0 && (
          data?.requested?.map((item) => (
            <RequestedListItem key={item.friendId} data={item} />
          ))
        )}
        {data?.waitingResponse?.length > 0 &&
          <Container style={{ marginTop: '62px' }}>
            <Row>
              <Col xs={12} sm={12}>
                <h5 className='title-friends'>Waiting Response <span>{data?.waitingResponse?.length}</span></h5>
              </Col>
            </Row>
          </Container>}
        {data?.waitingResponse?.length > 0 && (
          data?.waitingResponse?.map((item) => (
            <WaitingResponseListItem key={item.friendId} data={item} />
          ))
        )}
        {(data?.friends || []).length > 0 &&
          <Container style={{ marginTop: '62px' }}>
            <Row>
              <Col xs={12} sm={12}>
                <h5 className='title-friends'>Friends <span>{data?.friends?.length}</span></h5>
              </Col>
            </Row>
          </Container>}
        {
          (data?.friends || []).length > 0
            ? data?.friends?.map((item) => (
              <FriendsListItem key={item.friendId} data={item} />
            ))
            : 'No friend'
        }
      </div>
      {/*{data?.requested?.length > 0 && (*/}
      {/*  <Accordion*/}
      {/*    defaultActiveKey="0"*/}
      {/*    className={"fr-accordion friends-list-accordion"}*/}
      {/*  >*/}
      {/*    <AccordionItem*/}
      {/*      eventKey="0"*/}
      {/*      title={*/}
      {/*        <div>*/}
      {/*          Requested{" "}*/}
      {/*          <span className="badge badge-light">*/}
      {/*            {data?.requested?.length}*/}
      {/*          </span>*/}
      {/*        </div>*/}
      {/*      }*/}
      {/*      content={data?.requested?.map((item) => (*/}
      {/*        <RequestedListItem key={item.friendId} data={item} />*/}
      {/*      ))}*/}
      {/*    />*/}
      {/*  </Accordion>*/}
      {/*)}*/}
      {/*{data?.waitingResponse?.length > 0 && (*/}
      {/*  <Accordion*/}
      {/*    defaultActiveKey="0"*/}
      {/*    className={"fr-accordion friends-list-accordion"}*/}
      {/*  >*/}
      {/*    <AccordionItem*/}
      {/*      eventKey="0"*/}
      {/*      title={*/}
      {/*        <div>*/}
      {/*          Waiting Response{" "}*/}
      {/*          <span className="badge badge-light">*/}
      {/*            {data?.waitingResponse?.length}*/}
      {/*          </span>*/}
      {/*        </div>*/}
      {/*      }*/}
      {/*      content={data?.waitingResponse?.map((item) => (*/}
      {/*        <WaitingResponseListItem key={item.friendId} data={item} />*/}
      {/*      ))}*/}
      {/*    />*/}
      {/*  </Accordion>*/}
      {/*)}*/}
      {/*<Accordion*/}
      {/*  defaultActiveKey="0"*/}
      {/*  className={"fr-accordion friends-list-accordion"}*/}
      {/*>*/}
      {/*  <AccordionItem*/}
      {/*    eventKey="0"*/}
      {/*    title={*/}
      {/*      <div>*/}
      {/*        Friends{" "}*/}
      {/*        <span className="badge badge-light">*/}
      {/*          {data?.friends?.length || 0}*/}
      {/*        </span>*/}
      {/*      </div>*/}
      {/*    }*/}
      {/*    content={*/}
      {/*      (data?.friends || []).length > 0*/}
      {/*        ? data?.friends?.map((item) => (*/}
      {/*            <FriendsListItem key={item.friendId} data={item} />*/}
      {/*          ))*/}
      {/*        : "No friend"*/}
      {/*    }*/}
      {/*  />*/}
      {/*</Accordion>*/}
    </div>
  );
};
export default FriendsListDetails;
