import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const VibesUI_Quantity = (props) => {

    const [count, setCountState] = useState(parseInt(props.qty))

    function add() {
        const value = count < 20 ? count + 1 : count
        setCountState(value)
        props.updateQuantity(value)
    }
    function subtract() {
        const value = count > 1 ? count - 1 : count
        setCountState(value)
        props.updateQuantity(value)
    }

    return (
        <div className={`vibesUI--quantity_selector`}>
            <button onClick={subtract}>-</button>
            <div className='text'>{count}</div>
            <button onClick={add}>+</button>
        </div>
    );
};

export default VibesUI_Quantity;