import { all } from "redux-saga/effects";
import { authSaga } from "./auth";
import { userSaga } from "./user";
import { searchGameSaga } from "./searchGameSession";
import { schedulePerformance } from "./schedulePerformance";
import { genresSaga } from "./genres";
import { friendsSaga } from "./friendsList";
import { tabsSaga } from "./tabs";

// If any of these functions are dispatched, invoke the appropriate saga
function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    searchGameSaga(),
    schedulePerformance(),
    genresSaga(),
    friendsSaga(),
    tabsSaga(),
  ]);
}

export default rootSaga;
