import React from "react";
import { useDispatch } from "react-redux";

import { Container, Row, Col, Image, Button } from "react-bootstrap";
import DefaultAvatar from "./DefaultAvatar";

// redux-action
import {
  declineFriendRequestRequest,
  acceptFriendRequestRequest
} from "../../slices/friendsList";

import "./styles.scss";
import close from '../../assets/svg/sprite.svg';

const WaitingResponseListItem = ({ data }) => {
  const dispatch = useDispatch();

  const handleDeclineRequest = () => {
    dispatch(
      declineFriendRequestRequest({
        friendId: data?.friendId,
        displayName: data?.displayName,
        isAccept: false
      })
    );
  };

  const handleAcceptRequest = () => {
    dispatch(
      acceptFriendRequestRequest({
        friendId: data?.friendId,
        displayName: data?.displayName,
        isAccept: true
      })
    );
  };

  return (
    <Container>
      <Row className="wrapper-friends-list-item">
        <Col xs={4} sm={2}>
          {data?.avatarUrl ? (
            <Image src={data.avatarUrl} roundedCircle />
          ) : (
            <DefaultAvatar name={data?.displayName} />
          )}
        </Col>
        <Col xs={4} sm={7}>
          {data?.displayName}
        </Col>
        <Col xs={4} sm={3} className="actions">
          <Button
            className="frisson-btn fr-btn-theme"
            onClick={handleDeclineRequest}
          >

            <svg style={{width: "13px", height: "13px"}}>
              <use href={close + '#close_white'} />
            </svg>
            {/*<i className="fa fa-times"></i>*/}
          </Button>
          <Button
            className="frisson-btn fr-btn-theme-4"
            onClick={handleAcceptRequest}
          >

            <svg style={{width: "15px", height: "15px"}}>
              <use href={close + '#check'} />
            </svg>
            {/*<i className="fa fa-check"></i>*/}
          </Button>

        </Col>
      </Row>
    </Container>
  );
};

export default WaitingResponseListItem;
