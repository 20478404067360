import React from 'react';
import { Container } from 'react-bootstrap';
import './styles.scss';
import Statistics from './components/statistics';
import icons from '../../assets/img/avatar-user-tab';
import UserTabButton from './components/button';
import CharacterViewer from 'components/character-viewer';

const AvatarUserTab = ({ user }) => {
  return (
    <Container>
      <div className={'avatarUserTab-container'}>
        <div className={'character-wrapper'}>
          <CharacterViewer />
        </div>
        <div className={'content-wrapper'}>
          <div className={'statistics'}>
            <Statistics number={'000'} icon={icons.HZ} />
            <Statistics number={user?.virtualCurrency?.DB} icon={icons.DB} />
          </div>
          <p className={'name'}>Hi, {user?.displayName}!</p>
          <div className={'buttons'}>
            <UserTabButton icon={icons.DressingRoom} text={'Dressing room'} link={'/dressing-room'} />
            <UserTabButton icon={icons.Store} text={'Store'} link={'/store'} />
            <UserTabButton icon={icons.ATM} text={'Atm'} link={'/atm'} />
          </div>
        </div>
      </div>
    </Container>
  );
};
export default AvatarUserTab;
