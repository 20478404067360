import React from 'react';
import Logo from "../header/elements/logo";
import Input from "../input";
import logoBlack from '../../assets/img/logo/frisson-black.png';
import GroupButtonOption from "../../container/group-button-option";

const data = [
    {
        id: "type",
        text: "LIVE",
        className: "--live"
    },
    {
        id: "time",
        text: "TODAY",
        className: "--date"
    },
    {
        id: "genres",
        text: "EDM",
        className: "--genres"
    }
];

const colors = [
    "#4286F4",
    "#1B9EA8",
    "#10D583",
    "#3FE53E",
    "#CEDF17",
    "#FCF168",
    "#FFAF09",
    "#FF1C09",
    "#DB0050"
];
const PopupSearch = () => {

    const closeSearchPopup = () => {
        const body = document.querySelector('body');
        const html = document.querySelector('html');
        body.classList.remove('page-search-popup-opened');
        html.style.overflow = "auto"
    };

    return (
        <div className="frisson-search-popup">
            <div className="overlay" onClick={() => {
                closeSearchPopup();
            }}/>
            <div className="inner">
                <div className="search-header">
                    <Logo logo={logoBlack}/>
                    <button className="search-close" onClick={() => {
                        closeSearchPopup();
                    }}/>
                </div>
                <GroupButtonOption data={data} colors={colors} />
                <div className="search-content">
                    <form action="/" method="post">
                        <label>
                            <Input type="search" placeholder="Search you’re favorite live show" required={true}/>
                        </label>
                        <button className="search-submit"><i className="fa fa-search"/></button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PopupSearch;
