import React, { useState } from 'react';
import ButtonVideo from '../button-video';
import TitleCommon from '../title-common';
import { useDispatch, useSelector } from 'react-redux';
import LiveNow from '../live-now';
import { FILTER_STATUS, FORMAT_DATE_TIME } from '../../shared/utils/constants';
import { redirectPage } from '../../shared/utils/helpers';
import UpComing from '../up-coming';
import ButtonWatchList from '../button-watch-list';
import sprite from '../../assets/svg/sprite.svg';
import moment from 'moment/moment';
import ok from '../../assets/img/ok2.png';
import warning from '../../assets/img/warning.png';
import { getAuth } from '../../selectors/auth.selector';
import { addToPlayListSchedulePerfomancesRequest } from '../../slices/schedulePerformance';
import icons from '../../constants/icon';
import { getAddArtistRequest, getAddWatchListRequest } from '../../slices/user';

const SheduledItem = ({ featureShow, item, classItem }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(getAuth);
  const [visibleButton, setVisibleButton] = useState(false);
  const [addToWatchList, setAddToWatchList] = useState(false);
  const [openShowAdded, setOpenShowAdded] = useState(false);
  const [animateClass, setAnimateClass] = useState(false);
  const addToWatch = (va) => {
    setAddToWatchList(va);
  };

  function timeConvert(time) {
    if (Number(time.slice(0, 2)) > 12) {
      return time.slice(0, 2) - 12 + ':' + time.slice(3, 5) + 'PM';
    } else if (Number(time.slice(0, 2)) === 12) {
      return time.slice(0, 2) + ':' + time.slice(3, 5) + 'PM';
    } else {
      return time.slice(0, 2) + ':' + time.slice(3, 5) + 'AM';
    }
  }

  const getDay = (data) => {
    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    let d = new Date(data);
    let n = d.getDay();
    return days[n];
  };
  const month = new Date(item.startDatetime).getMonth();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const timeZone = () => {
    return new Date().getTimezoneOffset();
  };
  const itemTime = () => {
    let y = item.startDatetime.slice(11, 19).split(':');
    return moment
      .utc(item.startDatetime)
      .utcOffset(
        timeZone() >= 0
          ? -`${Math.abs(timeZone())}`
          : +`${Math.abs(timeZone())}`
      )
      .format('HH:mm:ss');
  };
  const itemDate = () => {
    return (
      monthNames[month] +
      ' ' +
      new Date(item.startDatetime).getDate() +
      ', ' +
      new Date(item.startDatetime).getFullYear()
    );
  };
  const showAdd = () => {
    dispatch(getAddArtistRequest(item?.scheduledPerformanceId))
    setAddToWatchList(false);
    setOpenShowAdded(true);
    setTimeout(() => {
      setOpenShowAdded(false);
    }, 2000);
  };

  const showAddShedulePerfomances = () => {
    // dispatch(
    //   addToPlayListSchedulePerfomancesRequest({
    //     sessionId: 'abaeaca1-d027-4442-adbd-f025b80436a9'
    //   })
    // );

    dispatch(getAddWatchListRequest(item?.scheduledPerformanceId));
    setAddToWatchList(false);
    setOpenShowAdded(true);
    setTimeout(() => {

      setOpenShowAdded(false);
    }, 2000);
  };

  let featureShowTrue;
  if (featureShow) {
    featureShowTrue = <TitleCommon title={featureShow} />;
  }
  let className = 'add-watch-list';
  if (!addToWatchList) {
    className += ' add-watch-list_no-active';
  } else {
    className += ' add-watch-list_active';
  }
  return (
    <div
      onMouseEnter={(e) => {
        e.preventDefault();
        setVisibleButton(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        setVisibleButton(false);
      }}
      className={`gallery ${classItem ? classItem : 'item-1'}`}
      // onClick={(e) => redirectPage(e, `jointhecrowd?id=yt-${item?.id}`)}
      style={{ minHeight: '300px', position: 'relative' }}
    >
      <div className={className}>
        <div className={'add-watch-list__top'}>
          <div>Add To Watchlist?</div>
          <div onClick={() => setAddToWatchList(false)}>
            <svg
              style={{
                width: '20px',
                height: '20px',
                position: 'relative',
                zIndex: '5',
                marginTop: '0px',
                marginBottom: '5px'
              }}
            >
              <use href={sprite + '#icon_close_grey'}></use>
            </svg>
          </div>
        </div>
        <div className={'add-watch-list__middle'}>
          <div onClick={showAdd}>+ Add Artist</div>
          <div onClick={showAddShedulePerfomances}>+ Add Show</div>
        </div>
        <div className={'add-watch-list__bottom'}>
          <span>
            {' '}
            By clicking the “+ Add Show” or “+ Add Artist” buttons above, you
            agree to receive an email when the selected show goes live or when
            the artist goes live for any of their future shows, respectively.
          </span>
        </div>
      </div>
      {isAuthenticated
        ? openShowAdded && (
            <div className={'show-added'}>
              <img
                style={{ width: '26px', height: '26px' }}
                src={ok}
                alt="img"
              />
              <div>Show added to watchlist</div>
            </div>
          )
        : openShowAdded && (
            <div className={'show-added'}>
              <img
                style={{ width: '26px', height: '26px' }}
                src={warning}
                alt="img"
              />
              <div>Please login to add a show</div>
            </div>
          )}

      <img src={item?.thumbnailUrl ? item?.thumbnailUrl : icons.defaultImage} alt={''} className={!item?.thumbnailUrl ? 'defaultImg' : ''} />
      {featureShowTrue ? featureShowTrue : ''}
      {!addToWatchList ? (
        <div
          className={
            !visibleButton ? 'content-top-item-2' : 'content-top-watch-1'
          }
        >
          <div className="genres-item-1">
            <div className="now-item">
              {!visibleButton ? (
                item?.genre
              ) : (
                <div className="now-item__head">{item?.targetArtistDisplayName}</div>
              )}
            </div>
            {visibleButton && (
              <div className="now-item__text" style={{ width: '100%' }}>
                {item.description}{' '}
              </div>
            )}
          </div>
          <div className="people-item-1">
            {/*<div className="now-item">{item?.totalConnections}</div>*/}
            <div className="now-item add-watch">
              {' '}
              {timeConvert(itemTime())}, {getDay(item.startDatetime)}
            </div>
            <div className="watcher add-watch-sub"> {itemDate()}</div>
          </div>
        </div>
      ) : (
        ''
      )}
      {!visibleButton
        ? item?.status === FILTER_STATUS.LIVE && <UpComing />
        : ''}
      <div className="content-bottom-item-1">
        <div className="desc item-1-desc">
          {!visibleButton ? (
            <div
              className="now-item__head"
              style={{
                color: '#fff',
                fontSize: '16px',
                lineHeight: '140%',
                fontWeight: '900'
              }}
            >
              {!visibleButton ? item?.targetArtistDisplayName : ''}<br/>
              {item?.performanceName}
            </div>
          ) : (
            ''
          )}
          {/*<div className="left__title">*/}
          {/*  {!visibleButton ? item?.targetArtistDisplayName : ''}*/}
          {/*</div>*/}
          <div className="left__description">
            {!visibleButton ? item?.streamTitle : ''}
          </div>
        </div>
        <div className="live-now-bottom-right">
          {openShowAdded ? (
            <svg
              style={{
                width: '20px',
                height: '20px'
              }}
            >
              <use href={sprite + '#icon_icon'}></use>
            </svg>
          ) : (
            <ButtonWatchList
              animateClass={animateClass}
              setAnimateClass={setAnimateClass}
              openShowAdded={openShowAdded}
              addToWatch={addToWatch}
            />
          )}
        </div>
      </div>
      <div className={addToWatchList ? 'conter animate' : 'conter'}></div>
    </div>
  );
};
export default SheduledItem;