import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik, FormikProvider } from 'formik';
// layout
import ImageUploadScheduledPerfomance from '../../components/image-upload-scheduled-perfomance';
import { Col, Container, Row, Modal, Form } from 'react-bootstrap';
import ScheduleEvent from '../../components/schedule';
import { SpinnerOverlay } from '../../components/spinner-overlay';
import FormSchedulePerformance from '../../components/form-schedule-performance';
import ButtonIcon from '../../components/button-icon';
import LableInline from '../../components/label-inline';
import CalendarInput from '../../components/datepicker-custom';
import FormGroupInput from '../../components/form-group-input';
import FormSectionSuperAdmins from '../../components/form-section-super-admin';
import {
  shedules,
  isLoading,
  isUpdated
} from '../../selectors/schedule.selector';
import Button from '../../components/button';
import SelectCustom from '../../components/SelectCustom';
import { genres } from '../../shared/defaultValue';
import {
  ValidationRemoveArtistSchedulePerformanceSchema,
  ValidationSchedulePerformanceSchema
} from '../../shared/validationSchema/schedulePerformanceValidate';
import {
  updateFormPerformanceRequest,
  updateFormPerformanceSuccess,
  getSchedulePerformanceRequest,
  deleteFormPerformanceRequest,
  getRemoveTargetArtistRequest
} from '../../slices/schedulePerformance';
import moment from 'moment';
import { isEmpty } from 'lodash';
import './styles.scss';
import { getProfileUser } from '../../selectors/user.selector';
import { getFriendsListRequest } from '../../slices/friendsList';
import { getFriendsList } from '../../selectors/friends.selector';

const SchedulePerformance = () => {
  const [loadImage, setLoadImage] = useState(false);
  const [reRendingSchedules, setReRendingSchedules] = useState(0);
  const [valueImage, setValueImage] = useState('');
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [authorPublication, setAuthorPublication] = useState('');
  const [isForm, setForm] = useState(true);
  const [objectEvent, setEvent] = useState({});
  const [objectDate, setDate] = useState({ EndDate: '', StartDate: '' });
  const loading = useSelector(isLoading);
  const updated = useSelector(isUpdated);
  const data = useSelector(shedules);
  const [formValue, setFormValue] = useState(false);
  const [picture, setPicture] = useState();
  const [modalRemoveArtist, setModalRemoveArtist] = useState(false);
  const [modalConfirmRemoveSP, setModalConfirmRemoveSP] = useState(false);
  const profileUser = useSelector(getProfileUser);
  const getUser = useMemo(() => {
    return profileUser || JSON.parse(localStorage.getItem('profile_user'));
  }, [profileUser]);

  const friendsList = useSelector(getFriendsList);
  const listUsers = [{
    label: getUser?.displayName,
    value: getUser?.playFabId
  }];

  useEffect(() => {
    dispatch(getFriendsListRequest());
  }, [dispatch]);


  friendsList?.friends.forEach((item) => {
    const { displayName, friendId } = item;
    const newObj = { label: displayName, value: friendId };
    listUsers.push(newObj);
  });

  let userAdminEmail = JSON.parse(localStorage.getItem('profile_user'));
  useEffect(() => {
    if (
      Object.keys(objectEvent).length !== 0 &&
      objectEvent?.superAdminsIds.includes(getUser?.playFabId) ||
      objectEvent?.schedulingAccountId === getUser?.playFabId ||
      objectEvent?.targetArtistAccountId === getUser?.playFabId
    ) {
      setFormValue(false);
    } else {
      setFormValue(true);
    }
  }, [objectEvent]);

  const handleToggleForm = (value) => {
    setForm(value);
  };
  const handleToggleModal = (visible) => {
    setVisible(visible);
    if (!visible) {
      setEvent({});
    }
  };

  const handleEventClick = ({ event }) => {
    const { groupId } = event;
    setAuthorPublication(event.extendedProps.schedulingAccountId);
    setVisible(true);
    const item = data.find((value) => value.scheduledPerformanceId === groupId);
    setEvent(item);
  };
  const isDateBefore = useMemo(() => {
    if (isEmpty(objectEvent)) return;
    const pastDate = moment(objectEvent?.start).format('YYYY-MM-DD');
    const todayDate = moment().format('YYYY-MM-DD');
    return moment(pastDate).isBefore(todayDate);
  }, [objectEvent]);
  const initGenres = useMemo(() => {
    if (isEmpty(objectEvent)) return [];
    return genres.filter((n) => {
      return objectEvent?.genre.split(',').indexOf(n.value) !== -1;
    });
  }, [objectEvent]);

  const initTargetArtistAccount = useMemo(() => {
    if (isEmpty(objectEvent)) return [];
    return listUsers.filter((n) => {
      return objectEvent?.targetArtistAccountId?.indexOf(n.value) !== -1;
    });
  }, [objectEvent]);

  const initSuperAdmins = useMemo(() => {
    if (isEmpty(objectEvent)) return [];
    return listUsers.filter((n) => {
      return objectEvent?.superAdminsIds.indexOf(n.value) !== -1;
    });
  }, [objectEvent]);

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: ValidationSchedulePerformanceSchema,
    initialValues: {
      performanceName: '',
      startDatetime: '',
      runtime: Number(objectEvent?.runtime),
      targetArtistAccountId: [],
      superAdminsIds: [],
      description: '',
      updateThumbnail: '',
    },
    onSubmit: (values) => {
      const superAdminsIdsArr = []
      const genres = values?.genre?.map((item) => item.value);
      values?.superAdminsIds?.forEach((item) => {
        superAdminsIdsArr.push(item.value);
      });

      const newForm = {
        genre: genres.toString(),
        startDatetime:
          Date.parse(formik.values.startDatetime) > Date.parse(new Date())
            ? moment(formik.values.startDatetime)
            : moment(new Date()),
        performanceName: values?.performanceName,
        scheduledPerformanceId: objectEvent?.scheduledPerformanceId,
        superAdminsIds: superAdminsIdsArr,
        runtime: Number(values?.runtime),
        targetArtistAccountId: values?.targetArtistAccountId.value,
        description: values?.description,
        updateThumbnail: picture,
      };
      console.log('newForm', newForm);
      dispatch(updateFormPerformanceRequest(newForm));
    }
  });

  const formikRemoveMyself = useFormik({
    enableReinitialize: true,
    validationSchema: ValidationRemoveArtistSchedulePerformanceSchema,
    initialValues: {
      performanceName: '',
      startDatetime: '',
      runtime: '',
      targetArtistAccountId: [],
      superAdminsIds: [],
      description: '',
      updateThumbnail: '',
    },
    onSubmit: (values) => {
      const superAdminsIdsArr = []
      const genres = values?.genre?.map((item) => item.value);
      values?.superAdminsIds?.forEach((item) => {
        superAdminsIdsArr.push(item.value);
      });

      const newForm = {
        genre: genres.toString(),
        startDatetime:
          Date.parse(formik.values.startDatetime) > Date.parse(new Date())
            ? moment(formik.values.startDatetime)
            : moment(new Date()),
        performanceName: values?.performanceName,
        schedulingAccountId: getUser?.playFabId,
        superAdmins: superAdminsIdsArr,
        runtime: Number(objectEvent?.runtime),
        targetArtistAccountId: [],
        description: values?.description,
        updateThumbnail: picture,
      };
      console.log('formikRemoveMyself', newForm);
      dispatch(updateFormPerformanceRequest(newForm));
    }
  });
  const deleteSchedule = () => {
    dispatch(deleteFormPerformanceRequest(objectEvent?.scheduledPerformanceId));
    setModalConfirmRemoveSP(false)
  };
  const handleMonthChange = (payload) => {
    const { startStr, endStr } = payload;
    setDate((prevState) => {
      return {
        ...prevState,
        StartDate: moment(startStr).format('YYYY-MM-DDTHH:mm Z'),
        EndDate: moment(endStr).format('YYYY-MM-DDTHH:mm Z')
      };
    });
  };
  // set value into form

  useEffect(() => {
    if (isEmpty(objectEvent)) return;

    formik.setValues({
      performanceName: objectEvent?.performanceName || '',
      genre: initGenres,
      startDatetime: objectEvent.start ? new Date(objectEvent?.start) : '',
      runtime: Number(objectEvent.runtime),
      thumbnailUrl: objectEvent?.thumbnailUrl,
      id: objectEvent?.scheduledPerformanceId,
      superAdminsIds: initSuperAdmins,
      targetArtistAccountId: initTargetArtistAccount,
      description: objectEvent?.description
    });
    formikRemoveMyself.setValues({
      performanceName: objectEvent?.performanceName || '',
      genre: initGenres,
      startDatetime: objectEvent.start ? new Date(objectEvent?.start) : '',
      runtime: Number(objectEvent.runtime),
      thumbnailUrl: objectEvent?.thumbnailUrl,
      id: objectEvent?.scheduledPerformanceId,
      superAdminsIds: initSuperAdmins,
      targetArtistAccountId: initTargetArtistAccount,
      description: objectEvent?.description
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    objectEvent.genre,
    objectEvent.performanceName,
    objectEvent.startDatetime,
    objectEvent.superAdminsIds,
    objectEvent.targetArtistDisplayName,
    reRendingSchedules
  ]);

  // only get schedul when submit form sucess
  useEffect(() => {
    if (!updated) return;
    dispatch(updateFormPerformanceSuccess(false));
    setVisible(false);
    dispatch(
      getSchedulePerformanceRequest({
        pageSize: 500,
        pageIndex: 1,
        filter: [
          {
            key: 'startDatetime',
            operator: 'between',
            value: [objectDate?.StartDate, objectDate?.EndDate]
          }
        ],
        schedulingAccountId: getUser?.playFabId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, updated]);
  // get schedule
  useEffect(() => {
    const { StartDate, EndDate } = objectDate;
    if (isEmpty(StartDate) || isEmpty(EndDate) || !isForm) return;
    dispatch(
      getSchedulePerformanceRequest({
        pageSize: 500,
        pageIndex: 1,
        filter: [
          {
            key: 'startDatetime',
            operator: 'between',
            value: [objectDate?.StartDate, objectDate?.EndDate]
          }
        ],
        schedulingAccountId: getUser?.playFabId,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, objectDate.EndDate, objectDate.StartDate, isForm]);
  const getImage = (value) => {
    setValueImage(value);
  };

  const removeMyself = () => {
    setModalRemoveArtist(true)
  }

  const confirmDeleteSP = () => {
    setModalConfirmRemoveSP(true)
  }

  const removeTargetArtist = () => {
    dispatch(getRemoveTargetArtistRequest(objectEvent?.scheduledPerformanceId));
    setModalConfirmRemoveSP(false);
    setModalRemoveArtist(false);
    setVisible(false);
  }

  return (
    <Fragment>
      {visible && (
        <Modal
          backdrop="static"
          keyboard={false}
          size="lg"
          show={visible}
          onHide={() => handleToggleModal(false)}
          className={`modal-sp ${modalRemoveArtist || modalConfirmRemoveSP ? 'openRemove' : ''}`}
        >
          <Modal.Header closeButton>
            <Modal.Title>Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormikProvider value={formik}>
              <Container>
                <Row>
                  <Col xs={6}>
                    <FormGroupInput
                      disabled={isDateBefore || formValue}
                      classNameColor="active color-gray"
                      label={'PERFORMANCE NAME'}
                      placeholder={'Max 60 charactor'}
                      icon="fa-envelope"
                      name="performanceName"
                      type="text"
                      formik={formik}
                    />
                  </Col>
                  <Col xs={6}>
                    <div className="form-group">
                      <LableInline classNameColor="special color-gray" label="ARTIST OR EVENT COMPANY ACCOUNT" />
                      <SelectCustom
                        isDisabled={isDateBefore || formValue}
                        isMulti={false}
                        data={listUsers || []}
                        onChange={(opt, e) => {
                          formik.setFieldValue('targetArtistAccountId', opt);
                        }}
                        name="targetArtistAccountId"
                        placeholder="Select artist"
                        customIndicator=" "
                        formik={formik}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="form-group">
                      <LableInline
                        classNameColor="active color-gray"
                        label="START DATE & TIME"
                      />
                      <CalendarInput
                        showTimeSelect
                        isDisabled={formValue}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        disablePast
                        timeCaption="time"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        name="startDatetime"
                        formik={formik}
                        handleChange={(opt, e) => {
                          formik.setFieldValue('startDatetime', opt);
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <FormGroupInput
                      disabled={isDateBefore || formValue}
                      classNameColor="special color-gray"
                      label={'RUNTIME 1'}
                      placeholder={'Runtime in minutes'}
                      icon="fa-envelope"
                      type="text"
                      name="runtime"
                      formik={formik}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <div className="form-group">
                      <LableInline
                        classNameColor="active color-gray"
                        label="MUSIC GENRE"
                      />
                      <SelectCustom
                        isMulti={true}
                        data={genres || []}
                        onChange={(opt, e) => {
                          formik.setFieldValue('genre', opt);
                        }}
                        isDisabled={isDateBefore || formValue}
                        name="genre"
                        className="select-music-genres"
                        placeholder="Music Genres"
                        customIndicator=" "
                        formik={formik}
                      />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className="form-group">
                      <LableInline
                        classNameColor="special color-gray"
                        label="PERFORMANCE DESCRIPTION"
                      />
                      <Form.Control
                        disabled={formValue}
                        value={formik.values['description'] || ''}
                        onChange={formik.handleChange}
                        as="textarea"
                        name="description"
                        onBlur={formik.handleBlur}
                        className={`input-custom-control ${
                          formik.touched['description'] &&
                          formik.errors['description']
                            ? 'is-invalid'
                            : null
                        }`}
                        // disabled={isDateBefore}
                        rows={2}
                      />
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div className="error">{formik.errors.description}</div>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <FormSectionSuperAdmins
                      list={listUsers}
                      authorPublication={authorPublication}
                      formik={formik}
                      objectEvent={objectEvent}
                      disabled={isDateBefore || formValue}
                      classLabel="active color-gray"
                    />
                  </Col>
                  <Col lg={6}>
                    <ImageUploadScheduledPerfomance
                      setReRendingSchedules={setReRendingSchedules}
                      buttonColor="red"
                      loadImage={loadImage}
                      getUser={formik}
                      getImage={getImage}
                      onPictyreUpload = {(e) => setPicture(e)}
                      classLabel="form-label special color-gray"
                    />
                  </Col>
                </Row>
              </Container>
            </FormikProvider>
          </Modal.Body>
          <Modal.Footer>
            <div className="form-footer-action">
              {
                objectEvent?.targetArtistAccountId === getUser?.playFabId &&
                objectEvent?.schedulingAccountId !== getUser?.playFabId ?(
                  <>
                    <Button
                      btnText={'Remove myself'}
                      type="button"
                      btnStyle={'rounded'}
                      size={'sd'}
                      // disabled={isDateBefore}
                      color={'yellow'}
                      onClick={removeMyself}
                    />
                    <Button
                      btnText={'Delete'}
                      type="button"
                      btnStyle={'rounded'}
                      size={'sd'}
                      // disabled={isDateBefore}
                      color={'yellow'}
                      // onClick={deleteSchedule}
                      onClick={confirmDeleteSP}
                    />
                    <Button
                      btnText={'Save'}
                      type="button"
                      btnStyle={'rounded'}
                      size={'sd'}
                      // disabled={isDateBefore}
                      color={'tarkis'}
                      onClick={formik.handleSubmit}
                    />
                  </>
                ) : (
                  <>
                    <Button
                    btnText={'Delete'}
                    type="button"
                    btnStyle={'rounded'}
                    size={'sd'}
                    // disabled={isDateBefore}
                    color={'yellow'}
                    // onClick={deleteSchedule}
                    onClick={confirmDeleteSP}
                    />
                    <Button
                    btnText={'Save'}
                    type="button"
                    btnStyle={'rounded'}
                    size={'sd'}
                    // disabled={isDateBefore}
                    color={'tarkis'}
                    onClick={formik.handleSubmit}
                    />
                  </>
                )
              }


              <Button
                btnText={'Close'}
                type="button"
                btnStyle={'rounded'}
                size={'sd'}
                color={'theme'}
                onClick={() => handleToggleModal(false)}
              />
            </div>
          </Modal.Footer>
        </Modal>
      )}
      <SpinnerOverlay loading={loading} />
      {isForm ? (
        <>
          <div className="form-schedule-action" style={{ margin: 'auto' }}>
            <ButtonIcon
              btnText={'Schedule Show'}
              type="button"
              btnStyle={'rounded'}
              size={'sd'}
              color={'tarkis'}
              onClick={() => handleToggleForm(false)}
            />
          </div>
          <ScheduleEvent
            handleMonthChange={handleMonthChange}
            handleEventClick={handleEventClick}
            allDaySlot={false}
            events={data}
          />
        </>
      ) : (
        <FormSchedulePerformance onToggle={handleToggleForm} />
      )}

          <Modal
            backdrop="static"
            keyboard={false}
            size="sm"
            show={modalRemoveArtist}
            onHide={() => setModalRemoveArtist(false)}
            className="modal-remove-myself"
          >
            <Modal.Header closeButton>
              <Modal.Title>Are you sure you want to remove yourself from this scheduled performance?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormikProvider value={formikRemoveMyself}>
                <Container  style={{ display: 'none' }}>
                  <Row>
                    <Col xs={6}>
                      <FormGroupInput
                        disabled={isDateBefore || formValue}
                        classNameColor="active color-gray"
                        label={'PERFORMANCE NAME'}
                        placeholder={'Max 60 charactor'}
                        icon="fa-envelope"
                        name="performanceName"
                        type="text"
                        formik={formikRemoveMyself}
                      />
                    </Col>
                    <Col xs={6}>
                      <FormGroupInput
                        disabled={isDateBefore || formValue}
                        classNameColor="special color-gray"
                        label={'ARTIST OR EVENT COMPANY ACCOUNT'}
                        placeholder={'Enter Existing Account Email'}
                        icon="fa fa-user"
                        name="targetArtistAccountId"
                        formik={formikRemoveMyself}
                        type="text"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="form-group">
                        <LableInline
                          classNameColor="active color-gray"
                          label="START DATE & TIME"
                        />
                        <CalendarInput
                          showTimeSelect
                          isDisabled={formValue}
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          disablePast
                          timeCaption="time"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          name="startDatetime"
                          formik={formikRemoveMyself}
                          handleChange={(opt, e) => {
                            formikRemoveMyself.setFieldValue('startDatetime', opt);
                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <FormGroupInput
                        disabled={isDateBefore || formValue}
                        classNameColor="special color-gray"
                        label={'RUNTIME'}
                        placeholder={'Runtime in minutes'}
                        icon="fa-envelope"
                        type="text"
                        name="runtime"
                        formik={formikRemoveMyself}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="form-group">
                        <LableInline
                          classNameColor="active color-gray"
                          label="MUSIC GENRE"
                        />
                        <SelectCustom
                          isMulti={true}
                          data={genres || []}
                          onChange={(opt, e) => {
                            formikRemoveMyself.setFieldValue('genre', opt);
                          }}
                          isDisabled={isDateBefore || formValue}
                          name="genre"
                          className="select-music-genres"
                          placeholder="Music Genres"
                          customIndicator=" "
                          formik={formikRemoveMyself}
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <LableInline
                          classNameColor="special color-gray"
                          label="PERFORMANCE DESCRIPTION"
                        />
                        <Form.Control
                          disabled={formValue}
                          value={formikRemoveMyself.values['description'] || ''}
                          onChange={formikRemoveMyself.handleChange}
                          as="textarea"
                          name="description"
                          onBlur={formikRemoveMyself.handleBlur}
                          className={`input-custom-control ${
                            formikRemoveMyself.touched['description'] &&
                            formikRemoveMyself.errors['description']
                              ? 'is-invalid'
                              : null
                          }`}
                          // disabled={isDateBefore}
                          rows={2}
                        />
                        {formikRemoveMyself.touched.description &&
                        formikRemoveMyself.errors.description ? (
                          <div className="error">{formikRemoveMyself.errors.description}</div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6}>
                      <FormSectionSuperAdmins
                        list={listUsers || []}
                        authorPublication={authorPublication}
                        formik={formikRemoveMyself}
                        objectEvent={objectEvent}
                        disabled={isDateBefore || formValue}
                        classLabel="active color-gray"
                      />
                    </Col>
                    <Col lg={6}>
                      <ImageUploadScheduledPerfomance
                        setReRendingSchedules={setReRendingSchedules}
                        buttonColor="red"
                        loadImage={loadImage}
                        getUser={formikRemoveMyself}
                        getImage={getImage}
                        onPictyreUpload = {(e) => setPicture(e)}
                        classLabel="form-label special color-gray"
                      />
                    </Col>
                  </Row>
                </Container>
              </FormikProvider>
            </Modal.Body>
            <Modal.Footer>

                <div className="form-footer-action">
                  <Button
                    btnText={'Yes'}
                    type="button"
                    btnStyle={'rounded'}
                    size={'sd'}
                    // disabled={isDateBefore}
                    color={'tarkis'}
                    onClick={removeTargetArtist}
                  />
                  <Button
                    btnText={'No'}
                    type="button"
                    btnStyle={'rounded'}
                    size={'sd'}
                    color={'theme'}
                    onClick={() => setModalRemoveArtist(false)}
                  />
                </div>

            </Modal.Footer>
          </Modal>


        <Modal
          backdrop="static"
          keyboard={false}
          size="sm"
          show={modalConfirmRemoveSP}
          onHide={() => setModalConfirmRemoveSP(false)}
          className="modal-remove-myself"
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to delete this scheduled performance?</Modal.Title>
          </Modal.Header>

          <Modal.Footer>

            <div className="form-footer-action">
              <Button
                btnText={'Yes'}
                type="button"
                btnStyle={'rounded'}
                size={'sd'}
                color={'tarkis'}
                onClick={deleteSchedule}
              />
              <Button
                btnText={'No'}
                type="button"
                btnStyle={'rounded'}
                size={'sd'}
                color={'theme'}
                onClick={() => setModalConfirmRemoveSP(false)}
              />
            </div>

          </Modal.Footer>
        </Modal>
    </Fragment>
  );
};
export default SchedulePerformance;