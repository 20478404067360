import React, { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import './style.scss';
import { useFormik } from 'formik';
import { ValidationForgotPassword } from '../../shared/validationSchema/registerValidate';
import InputFormik from '../../components/input-formik';
import toast from 'react-hot-toast';
import { toastConfig } from '../../shared/defaultValue';


const ForgotPassword = () => {
  const [successHeadText, setSuccessHeadText] = useState(false);
  const [successBtn, setSuccessBtn] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: ValidationForgotPassword,
    onSubmit: (values) => {
      // let token = localStorage.getItem('refreshToken');
      let token = new URLSearchParams(window.location.search).get('token');
      ResetPassword(values.password, token);
    }
  });

  async function ResetPassword(values, token) {

    let key = '';

    await fetch('https://www.frisson.live/CrowdChatV1/js/config.json').then(response => {
      if (!response.ok) {
        throw new Error('HTTP error ' + response.status);
      }
      return response.json();
    })
      .then(json => {
        key = json.apiKey;
      })
      .catch(function() {
        this.dataError = true;
      });
    fetch('https://10B75.playfabapi.com/Admin/ResetPassword', {
      method: 'POST',
      headers: {
        'X-SecretKey': key.toString(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'TitleId': '10B75',
        'Password': values,
        'Token': token
      })
    })
      .then(function(response) {
        if (response.status >= 400 && response.status < 600) {
          response.json().then(function(object) {
            console.log('object.errorMessage--->', object.errorMessage);
            alert(object.errorMessage);
          });
        } else if (response.status === 200) {
          console.log('passReset');
          setSuccessHeadText(true);
          setSuccessBtn(true);
          return response.json();

          toast.success('Your password was reset!', toastConfig);

          setTimeout(() => {
            window.location.href = 'https://www.frisson.live';
          }, 2000);
        }

      }).catch(function(err) {
      //no existing session with this id
      return console.log((err.toString()));
    });
  }


  return (
    <div className='wrap'>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='forgot-password-wrap'>
          <h3>{successHeadText ? 'Your password was reset!' : 'Reset your password'}</h3>
          {!successBtn &&
            <>
              <div className='wrapper-control'>
                <InputFormik
                  className='input-custom-control'
                  formik={formik}
                  placeholder='New Password'
                  name='password'
                  type='password'
                  autoComplete='off'
                  icon='fa-lock'
                />
                <InputFormik
                  className='input-custom-control'
                  placeholder='Repeat Password'
                  name='confirmPassword'
                  formik={formik}
                  type='password'
                  autoComplete='off'
                  icon='fa-lock'
                />
              </div>
              <Button
                variant='primary'
                type='submit'
                className='btn-submit'
                style={{ width: '100%' }}
              >
                Return to Frisson
              </Button>
            </>
          }
        </div>
      </form>
      <p className='copyright'>© Frisson {(new Date().getFullYear())}</p>
    </div>
  );
};

export default ForgotPassword;
