import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import frisson from '../../data/frisson.json';

// layout
import { LayoutDefault } from '../../layouts';
import { Footer } from '../../container/footer';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import HeaderContainer from '../../container/header/HeaderContainerOne';
import Logo from '../../components/header/elements/logo';
import logo from '../../assets/img/logo/logo.png';
import { SpinnerOverlay } from '../../components/spinner-overlay';
import LableInline from '../../components/label-inline';
import CalendarInput from '../../components/datepicker-custom';
import FormGroupInput from '../../components/form-group-input';
import {
  isLoading,
  shedulePerformance
} from '../../selectors/schedule.selector';
import Button from '../../components/button';
import { ValidationUpdateSchedulePerformanceSchema } from '../../shared/validationSchema/schedulePerformanceValidate';
import {
  updateFormPerformanceRequest,
  getScheduleByIdRequest
} from '../../slices/schedulePerformance';
import { isEmpty } from 'lodash';
import moment from 'moment';

import './styles.scss';

const SchedulePerformance = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isConfirm, setConfirm] = useState(false);
  const { scheduleId } = useParams();
  const loading = useSelector(isLoading);
  const { shedulesDetails } = useSelector(shedulePerformance);
  const handleToggleModal = (visible) => {
    setVisible(visible);
  };
  const handleToggleConfirm = (visible) => {
    setConfirm(visible);
  };

  const formik = useFormik({
    validationSchema: visible
      ? ValidationUpdateSchedulePerformanceSchema
      : null,
    initialValues: {
      startDatetime: new Date(),
      runtime: 0
    },
    onSubmit: (values) => {
      dispatch(
        updateFormPerformanceRequest(
          !visible
            ? { status: 'abandoned', scheduledPerformanceId: scheduleId }
            : {
                scheduledPerformanceId: scheduleId,
                startDatetime: moment(values.startDatetime).format(
                  'YYYY/MM/DD HH:mm Z'
                ),
                runtime: Number(values?.runtime)
              }
        )
      );
      setVisible(false);
      setConfirm(false);
    }
  });

  const formikInit = useFormik({
    initialValues: {
      startTime: new Date(),
      performanceName: ''
    }
  });

  const openConfirm = useMemo(() => {
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        show={isConfirm}
        onHide={() => handleToggleConfirm(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <p>Are you sure to cancel this show?</p>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-footer-action">
            <Button
              btnText={'Ok'}
              type="button"
              btnStyle={'rounded'}
              size={'sd'}
              color={'tarkis'}
              onClick={formik.handleSubmit}
            />
            <Button
              btnText={'Close'}
              type="button"
              btnStyle={'rounded'}
              size={'sd'}
              color={'theme'}
              onClick={() => handleToggleConfirm(false)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }, [formik.handleSubmit, isConfirm]);

  // set value into form
  useEffect(() => {
    if (isEmpty(shedulesDetails)) return;

    formikInit.setValues({
      performanceName: shedulesDetails?.performanceName || '',
      startTime: shedulesDetails.startDatetime
        ? new Date(shedulesDetails?.startDatetime)
        : ''
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shedulesDetails]);

  useEffect(() => {
    dispatch(getScheduleByIdRequest(scheduleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log('shedulesDetails', shedulesDetails);

  const openModal = useMemo(() => {
    return (
      <Modal
        backdrop="static"
        keyboard={false}
        size="lg"
        show={visible}
        onHide={() => handleToggleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={6}>
                <div className="form-group">
                  <LableInline
                    classNameColor="active color-gray"
                    label="START DATE & TIME"
                  />
                  <CalendarInput
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    disablePast
                    timeCaption="time"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    name="startDatetime"
                    formik={formik}
                    handleChange={(opt, e) => {
                      formik.setFieldValue('startDatetime', opt);
                    }}
                  />
                </div>
              </Col>
              <Col xs={6}>
                <FormGroupInput
                  classNameColor="special color-gray"
                  label={'RUNTIME'}
                  placeholder={'Runtime'}
                  icon="fa-envelope"
                  type="text"
                  name="runtime"
                  formik={formik}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-footer-action">
            <Button
              btnText={'Save'}
              type="button"
              btnStyle={'rounded'}
              size={'sd'}
              color={'tarkis'}
              onClick={formik.handleSubmit}
            />
            <Button
              btnText={'Close'}
              type="button"
              btnStyle={'rounded'}
              size={'sd'}
              color={'theme'}
              onClick={() => handleToggleModal(false)}
            />
          </div>
        </Modal.Footer>
      </Modal>
    );
  }, [formik, visible]);
  return (
    <Fragment>
      <Helmet>
        <title>{`${frisson.title}`}</title>
      </Helmet>
      <SpinnerOverlay loading={loading} />
      {visible && openModal}
      {isConfirm && openConfirm}
      <LayoutDefault className="template-color-1 template-font-2 bg_color--3">
        <HeaderContainer logoVersion={'light'} className="mb--130">
          <div className="header-left flex-20">
            <Logo logo={logo} />
          </div>
          <div className="header-flex-right flex-80"> </div>
        </HeaderContainer>

        <div className="mt--50">
          <div className="content-edit-profile-right">
            <div className="profile-right-header">
              <div className="tab-account active">Update schedule</div>
            </div>
            <div className="horizontal"> </div>
          </div>
        </div>
        <Container className="content-form-schedule">
          <div className="schedule-right-body">
            <Row>
              <Col xs={12} sm={12} md={6}>
                <FormGroupInput
                  label={'PERFORMANCE TITLE'}
                  placeholder={'Max 60 charactor'}
                  icon="fa-video-camera"
                  disabled
                  name="performanceName"
                  formik={formikInit}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="form-group">
                  <LableInline
                    classNameColor="special"
                    label="START DATE & TIME"
                  />
                  <CalendarInput
                    showTimeSelect
                    disablePast={true}
                    isDisabled
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    name="startTime"
                    formik={formikInit}
                    handleChange={(opt, e) => {
                      formikInit.setFieldValue('startTime', opt);
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="action-button">
              <Col xs={12} sm={12} md={6}>
                <Button
                  btnText={'Update schedule'}
                  type="button"
                  btnStyle={'rounded'}
                  size={'sd'}
                  color={'tarkis'}
                  onClick={() => handleToggleModal(true)}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <Button
                  btnText={'Cancel'}
                  type="button"
                  btnStyle={'rounded'}
                  size={'sd'}
                  color={'theme'}
                  onClick={() => handleToggleConfirm(true)}
                />
              </Col>
            </Row>
          </div>
        </Container>

        <Footer />
      </LayoutDefault>
    </Fragment>
  );
};

export default SchedulePerformance;
