import React, { useEffect, useState } from 'react';
import './styles.scss';

const SelectInputEarning = ({ element, isOrigin, meaning, getSelectInput, setDateObj, itemSelect, item, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValue, setIsValue] = useState('');
  const toggleOptionsBlur = () => {
    setIsOpen(false);
  };
  const daysMonth = () => {
    let d = new Date();
    return d.getDate();
  };
  const setDataForSelect = (val) => {
    let d = new Date();
    let t = d.setDate(d.getDate() + val);
    let day = new Date(t);
    let sumD = day.getMonth() + 1;
    return `${String(sumD).length === 1 ? '0' + String(sumD) : String(sumD)}-${String(day.getDate())}-${String(day.getFullYear())}`;
  };
  const getWeekDay = (date) => {
    let days = [0, 1, 2, 3, 4, 5, 6];
    return days[date.getDay()];
  };
  useEffect(() => {
    setIsValue(isOrigin);
  }, []);
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };
  const selected = (value) => {
    if (id === 'pa_1') {
      switch (value) {
        case 'Today':
          setDateObj({
            startDayUtc: setDataForSelect(0), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'Yesterday':
          setDateObj({
            startDayUtc: setDataForSelect(1), endDayUtc: setDataForSelect(1)
          });
          break;
        case 'Last 7 days':
          setDateObj({
            startDayUtc: setDataForSelect(-7), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'This week':
          setDateObj({
            startDayUtc: setDataForSelect(-getWeekDay(new Date())), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'Last week':
          setDateObj({
            startDayUtc: setDataForSelect(-(getWeekDay(new Date()) + 7)),
            endDayUtc: setDataForSelect(-getWeekDay(new Date()))
          });
          break;
        case 'Last 30 days':
          setDateObj({
            startDayUtc: setDataForSelect(-30), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'This month':
          setDateObj({
            startDayUtc: setDataForSelect(-daysMonth()), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'Last month':
          setDateObj({
            startDayUtc: setDataForSelect(-(daysMonth() + 30)), endDayUtc: setDataForSelect(-daysMonth())
          });
          break;
        case 'All time':
          setDateObj({
            startDayUtc: setDataForSelect(-18670), endDayUtc: setDataForSelect(0)
          });
          break;
        default:
          break;
      }
    }
    if (id === 'ea_1') {
      switch (value) {
        case 'Today':
          setDateObj({
            startDayUtc: setDataForSelect(0), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'Yesterday':
          setDateObj({
            startDayUtc: setDataForSelect(1), endDayUtc: setDataForSelect(1)
          });
          break;
        case 'Last 7 days':
          setDateObj({
            startDayUtc: setDataForSelect(-7), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'This week':
          setDateObj({
            startDayUtc: setDataForSelect(-getWeekDay(new Date())), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'Last week':
          setDateObj({
            startDayUtc: setDataForSelect(-(getWeekDay(new Date()) + 7)),
            endDayUtc: setDataForSelect(-getWeekDay(new Date()))
          });
          break;
        case 'Last 30 days':
          setDateObj({
            startDayUtc: setDataForSelect(-30), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'This month':
          setDateObj({
            startDayUtc: setDataForSelect(-daysMonth()), endDayUtc: setDataForSelect(0)
          });
          break;
        case 'Last month':
          setDateObj({
            startDayUtc: setDataForSelect(-(daysMonth() + 30)), endDayUtc: setDataForSelect(-daysMonth())
          });
          break;
        case 'All time':
          setDateObj({
            startDayUtc: setDataForSelect(-18670), endDayUtc: setDataForSelect(0)
          });
          break;
        default:
          break;
      }
    }
    itemSelect(value);
    setIsOpen(false);
    setIsValue(value);
    getSelectInput(value, meaning);
  };
  return (<>
    <div className='container-select-earning'>
      <input
        name='select_value'
        type='hidden'
        id='selectedValue'
        value={isValue}
        onBlur={() => {
          toggleOptionsBlur();
        }}
      />
      <div
        className='display-value-earning'
        id='displayValue'
        onClick={toggleOptions}
      >
          <span className='value-text-earning' id='valueText'>
           {item ? item : isValue}
          </span>
        <span
          className={isOpen === true ? 'arrow-earning active arrow-down' : 'arrow-earning arrow-down'}
          id='arrowControl'
        ></span>
      </div>
      <ul
        tabIndex='0'
        style={{ height: '200px', overflow: 'auto' }}
        className={isOpen === true ? 'select-container-earning active' : 'select-container-earning'}
        id='selectContainer'
      >
        {element.map((i, index) => isOrigin === i ? (<li
          key={index}
          className='select-option-earning'
          onClick={() => {
            selected(i);
          }}
        >
          {i}
        </li>) : (<li
          key={index}
          className='select-option-earning'
          onClick={() => {
            selected(i);
          }}
        >
          {i}
        </li>))}
      </ul>
    </div>
  </>);
};
export default SelectInputEarning;
