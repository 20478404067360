import React, { useEffect, useMemo, useState, useRef } from 'react';
import './style.scss';
import SelectInputEarning from '../select-input-earning';
import SelectInputEarningBottom from '../select-input-earning-bottom';
import SelectInputEarningBottomReplacement from '../select-input-earning-bottom-replacement';
import { Modal } from 'react-bootstrap';
import userService from '../../services/UserAPI';
import moment from 'moment';
import {
  getProfileRequest,
  payPalGetCodeHistoryRequest,
  payPalGetCodeRequest,
  sendDbAmountRequest
} from '../../slices/user';
import { useDispatch, useSelector } from 'react-redux';
import User from '../../shared/audiophile/models/user';
import jwt_decode from 'jwt-decode';
import GetUser from '../../../src/utilities/getUserLocalStorage';

const EarningContainer = () => {
  const dispatch = useDispatch();
  const [errorForm, setErrorForm] = useState({ error: '' });
  const [selectItemLiEarnings, setSelectItemLiEarnings] = useState('');
  const [selectItemLi, setSelectItemLi] = useState('');
  const [inputPayPalForValidate, setInputPayPalForValidate] = useState('');
  const [inputPayPal, setInputPayPal] = useState('');
  const [inputPayPalData, setInputPayPalData] = useState('');
  const [inputPayPalError, setInputPayPalError] = useState('');
  const [openModalStatusPayPal, setOpenModalStatusPayPal] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [isOrigin, setIsOrigin] = useState('All time');
  const [isOriginEarning, setIsOriginEarning] = useState('All time');
  const [isOriginEarningBottom, setIsOriginEarningBottom] = useState('See line items');
  const [visionEarnings, setVisionEarnings] = useState(false);
  const [visionPayout, setVisionPayout] = useState(false);
  const [date, setDate] = useState({
    startDayUtc: '01-01-2020',
    endDayUtc: '09-01-2022'
  });
  const [dateForPayout, setDatePayout] = useState({
    startDayUtc: '01-01-2020',
    endDayUtc: '09-01-2022'
  });
  const onchangeInput = (e) => {
    setInputPayPalForValidate(e.target.value);
    setInputPayPal(e.target.value.replace(/[^\d]/g, '').replace(/[\s.,%]/g, ''));
  };
  const totalEarningsDb = () => {
    // return restApiCustomPayoutsHistory?.totalEarningsDb - (restApiCustomPayoutsHistory?.totalSuccessfulUsdCents + restApiCustomPayoutsHistory?.pendingUsdCents) * 100;
    return restApiCustomPayoutsHistory?.totalEarningsDb;
  };
  const sendingDb = () => {
    if (Number(inputPayPalForValidate) > totalEarningsDb()) {
      setErrorForm({ ...errorForm, error: 'You don\'t have enough dB' });
    } else if (Number(inputPayPalForValidate) === 0 || Number(inputPayPalForValidate) === NaN) {
      setErrorForm({ ...errorForm, error: 'Please entered a valid amount of dB' });
    } else if (Number(inputPayPalForValidate) < 1000) {
      setErrorForm({ ...errorForm, error: 'You cannot withdraw less than 1000' });
    } else {
      setErrorForm({ ...errorForm, error: '' });
      dispatch(sendDbAmountRequest(Number(inputPayPalForValidate)));
      setInputPayPal('');
      setOpenModalStatusPayPal(false);
      // if (restApiSuccess?.code === 200) {
      //   setOpenModalStatusPayPal(false);
      // }
    }
  };
  const restApiCustomPayoutsHistory = useSelector((state) => state?.user?.payouts);
  const restApiSuccess = useSelector((state) => state?.user?.apiDb);
  const profUser = useSelector((state) => state?.user?.profileUser);
  const filterArrPayout = (arr, { start, end }) => {
    let s = Date.parse(start),
      e = Date.parse(end);
    if (start === end) {
      return arr?.filter((b) => {
        let d = new Date(b.dateTime);
        return (e === Date.parse((String(d.getMonth() + 1).length === 1 ? '0' + String(d.getMonth() + 1) : d.getMonth() + 1) + '-' + String(d.getDate()) + '-' + d.getFullYear()));
      });
    }
    return arr?.filter((a) => {
      return !((s && s >= a?.timestamp) || (e && e <= a?.timestamp));
    });
  };
  const sumDb = (arr = []) => {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i].dbAmount;
    }
    return sum;
  };
  const sumDbUsd = (arr = []) => {
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      sum += arr[i].amountUsdCents;
    }
    return sum;
  };
  const getPropsNone = (val) => {
    setVisionEarnings(val);
  };
  const getPropsNonePayout = (val) => {
    setVisionPayout(val);
  };
  const getVision = (val) => {
    setVisionEarnings(val);
  };
  const getVisionPayout = (val) => {
    setVisionPayout(val);
  };
  const itemSelectEarnings = (val) => {
    setSelectItemLiEarnings(val);
  };
  const itemSelect = (val) => {
    setSelectItemLi(val);
  };
  const handleToggleModal = (value) => {
    setOpenModalStatus(value);
  };
  const handleToggleModalPayPal = (value) => {
    setOpenModalStatusPayPal(value);
  };

  useEffect(() => {

    if (localStorage.getItem('havePaypal') === 'yes' && localStorage.getItem('urlLink') !== null) {
      getResolvePaypal();
    }
  }, []);
  const payPalPopPup = () => {
    window.paypal?.use(['login'], function(login) {
      login.render({
        'appid': process.env.REACT_APP_PAYPAL_ID,
        'authend': process.env.REACT_APP_PAYPAL_AUTHEND,
        'scopes': 'profile https://uri.paypal.com/services/paypalattributes',
        'containerid': 'payPalButton',
        'responseType': 'code',
        'locale': 'en-us',
        'buttonType': 'CWP',
        'buttonShape': 'rectangle',
        'buttonSize': 'lg',
        'fullPage': 'true',
        'text': 'Connect to PayPal',
        'returnurl': process.env.REACT_APP_PAYPAL_RETURN_URL
      });
    });


  };


  const getResolvePaypal = () => {
    let str = localStorage.getItem('urlLink');
    let s = str.split('?');
    let y = s[1].slice(5).split('&');
    localStorage.setItem('payPalCode', y[0]);
    dispatch(payPalGetCodeRequest(y[0]));
    localStorage.removeItem('havePaypal');
    localStorage.removeItem('payPalCode');
    localStorage.removeItem('urlLink');


  };
  const clickItemLocalStorage = () => {
    localStorage.setItem('havePaypal', 'yes');
  };
  const openModal = () => {
    return (
      <Modal show={openModalStatus} onHide={() => handleToggleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>How to withdraw earnings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: 'center', marginBottom: '10px' }}>
            1. Connect your PayPal account
          </p><p style={{ textAlign: 'center', marginBottom: '10px' }}>
          2. Request an amount to withdraw (1dB = $0.01 USD). It must be equal to or less than the total dB in your
          account, and greater than 1,000 dB
        </p><p style={{ textAlign: 'center', marginBottom: '10px' }}>
          3. Accept the terms (view Earnings Withdraw Terms & Conditions), which include PayPal's required 2%
          transaction fee.
        </p><p style={{ textAlign: 'center', marginBottom: '10px' }}>
          4. The amount withdrawn will arrive in $USD in your connected PayPal account within 5 business days
        </p>
          <div
            style={{
              textAlign: 'center',
              display: 'grid',
              justifyContent: 'center',
              gap: '15px'
            }}
          >
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}></div>
        </Modal.Body>
      </Modal>
    );
  };
  useEffect(() => {
    setInputPayPalData(inputPayPal?.replace(/\B(?=(\d{3})+(?!\d))/g, ','));
  }, [inputPayPal]);
  const openModalPayPal = () => {
    return (
      <Modal show={openModalStatusPayPal} onHide={() => handleToggleModalPayPal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw Earnings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-paypal'>
            <div
              className='modal-paypal-header'>Max: {profUser?.virtualCurrency?.DB.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
            <div className='modal-paypal-body'>
              <div>Withdraw:</div>
              <input type='text' placeholder='Enter Amount in dB...' value={inputPayPalData} onChange={onchangeInput} />
              <div>as ${inputPayPalData ? inputPayPal / 100 : 'X.XX'} USD</div>
            </div>
            {inputPayPalError !== '' &&
              <div style={{ color: 'red', fontSize: '14px', marginTop: '-15px' }}><sup>*</sup> {inputPayPalError}</div>}
            <div className='modal-paypal-footer'>Min: 1,000</div>
            <div style={{ color: 'red', fontSize: '12px' }}>{errorForm?.error}</div>
          </div>
          <div className='modal-paypal-button'>
            <button className='modal-paypal-button-first' onClick={() => handleToggleModalPayPal(false)}>Close</button>
            <button onClick={sendingDb} className='modal-paypal-button-two'>Withdraw
              ${inputPayPalData ? inputPayPal / 100 : 'x,xx'} usd
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <div className='earning-container'>
      <h2>Earnings</h2>
      {openModal()}
      <div className='earning-container-text'>
        See how much you have earned from producer & creator activity on
        frisson. Connect to your PayPal account to withdraw your earnings in dB
        as $USD. 1db = $0.01 USD
      </div>
      <div className='earning-container-content'>
        <h3>In Account: {profUser?.virtualCurrency?.DB} dB</h3>
        <div className='earning-container-block-grid'>
          <div className='block-grid-color ' style={{ position: 'relative' }}>
            {visionEarnings && <div className='grid-color-header-text'>Earnings - TODAY: 14,957 dB</div>}
            {visionEarnings ? '' : <div className='block-center'>
              <div className='block-center-header one'>Earnings</div>
              <div
                className='block-center-center'>{sumDb(filterArrPayout(restApiCustomPayoutsHistory?.payoutItems.map((e) => {
                return { ...e, timestamp: Date.parse(e.dateTime) };
              }), { start: date.startDayUtc, end: date.endDayUtc }))} dB
              </div>
              <div className='block-center-bottom'>
                <SelectInputEarning id='pa_1' isOrigin={isOrigin} setDateObj={setDate} itemSelect={itemSelectEarnings}
                                    item={selectItemLiEarnings}
                                    element={['Today', 'Yesterday', 'Last 7 days', 'This week', 'Last week', 'Last 30 days', 'This month', 'Last month', 'All time']} />
              </div>
            </div>}
            {
              !visionEarnings ? '' :
                <SelectInputEarningBottomReplacement id='pa_2' isOrigin={isOrigin} getVision={getVision}
                                                     setDateObj={setDate}
                                                     item={selectItemLiEarnings} itemSelect={itemSelectEarnings}
                                                     element={['Today', 'Yesterday', 'Last 7 days', 'This week', 'Last week', 'Last 30 days', 'This month', 'Last month', 'All time']} />
            }
            <SelectInputEarningBottom id='pa_3'
                                      element={filterArrPayout(restApiCustomPayoutsHistory?.payoutItems.map((e) => {
                                        return { ...e, timestamp: Date.parse(e.dateTime) };
                                      }), {
                                        start: date.startDayUtc,
                                        end: date.endDayUtc
                                      }) ? filterArrPayout(restApiCustomPayoutsHistory?.payoutItems.map((e) => {
                                        return { ...e, timestamp: Date.parse(e.dateTime) };
                                      }), { start: date.startDayUtc, end: date.endDayUtc }) : []}
                                      getPropsNone={getPropsNone} isOrigin={isOriginEarningBottom}
                                      vision={visionEarnings} isEarnings={true} />
          </div>
          <div className='block-grid-color ' style={{ position: 'relative' }}>
            <div className='block-center'>
              <div className='block-center-header two'>Pending Payout</div>
              <div
                className='block-center-center'>${restApiCustomPayoutsHistory === undefined ? 0 : restApiCustomPayoutsHistory?.pendingUsdCents / 100} USD
              </div>
              <div className='block-center-bottom'>
                <div style={{ height: '52px' }}></div>
              </div>
            </div>
            {/*<SelectInputEarningBottom element={['See line items', 2, 3, 4, 5, 1, 2, 3, 4, 5]}*/}
            {/*                          getPropsNone={getPropsNone} isOrigin={isOriginEarningBottom}*/}
            {/*                          vision={vision} />*/}
          </div>
          <div className='block-grid-color ' style={{ position: 'relative' }}>
            {visionPayout && <div className='grid-color-header-text'>Earnings - TODAY: 14,957 dB</div>}
            {visionPayout ? '' : <div className='block-center'>
              <div className='block-center-header three'>Payout Completed</div>
              <div
                className='block-center-center'>${sumDbUsd(filterArrPayout(restApiCustomPayoutsHistory?.successfulPayouts.map((e) => {
                return { ...e, timestamp: Date.parse(e.dateTime) };
              }), { start: dateForPayout.startDayUtc, end: dateForPayout.endDayUtc })) / 100} USD
              </div>
              <div className='block-center-bottom'>
                <SelectInputEarning id='ea_1' isOrigin={isOriginEarning} setDateObj={setDatePayout}
                                    itemSelect={itemSelect}
                                    item={selectItemLi}
                                    element={['Today', 'Yesterday', 'Last 7 days', 'This week', 'Last week', 'Last 30 days', 'This month', 'Last month', 'All time']} />
              </div>
            </div>}
            {
              !visionPayout ? '' :
                <SelectInputEarningBottomReplacement id='ea_2' isOrigin={isOrigin} getVision={getVisionPayout}
                                                     setDateObj={setDatePayout} itemSelect={itemSelect}
                                                     item={selectItemLi}
                                                     element={['Today', 'Yesterday', 'Last 7 days', 'This week', 'Last week', 'Last 30 days', 'This month', 'Last month', 'All time']} />
            }
            <SelectInputEarningBottom id='ea_3'
                                      element={filterArrPayout(restApiCustomPayoutsHistory?.successfulPayouts.map((e) => {
                                        return { ...e, timestamp: Date.parse(e.dateTime) };
                                      }), {
                                        start: dateForPayout.startDayUtc,
                                        end: dateForPayout.endDayUtc
                                      }) ? filterArrPayout(restApiCustomPayoutsHistory?.successfulPayouts.map((e) => {
                                        return { ...e, timestamp: Date.parse(e.dateTime) };
                                      }), { start: dateForPayout.startDayUtc, end: dateForPayout.endDayUtc }) : []}
                                      getPropsNone={getPropsNonePayout} isOrigin={isOriginEarningBottom}
                                      vision={visionPayout} isEarnings={false} />
          </div>
        </div>
        <div className='earning-container-block'>
          <div className='earning-container-block-title' onClick={() => {
            setOpenModalStatus(true);
          }}>How to withdraw earnings
          </div>
          {profUser?.paypal_name ? <button className='earning-container-block-button ' onClick={() => {
              handleToggleModalPayPal(true);
            }}>Withdraw Earnings </button> :
            <div id='payPalButton' className='paypal_b2' onClick={clickItemLocalStorage}></div>
          }
          {openModalPayPal()}
          {payPalPopPup()}
          <div className='earning-container-block-flex'>
            <div className='earning-container-block-flex-block1'>Authenticated
              as {profUser?.paypal_name ? profUser?.paypal_name : 'PAYPAL_NAME'}</div>
            {profUser?.paypal_name ? <div className='earning-container-block-flex-block2'>
              <div id='payPalButton' className='paypal_b' onClick={clickItemLocalStorage}>
                <span>CHANGE</span>
              </div>
            </div> : ''}
          </div>
          <div className='earning-container-block-bottom'>*All data & reporting is delayed up to 1 hour</div>
        </div>
      </div>
    </div>
  );
};
export default EarningContainer;
