import jwt_decode from 'jwt-decode';
import { countBy } from 'lodash';
import { FORMAT_DATE_TIME, DATE } from '../../shared/utils/constants';
import moment from 'moment';
/**
 * Persisted state from storage
 * @returns {any}
 */
export const loadAppState = () => {
  try {
    const serializedState = localStorage.getItem('frisson_state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

/**
 * Save App state into browser storage
 * @param state
 */
export const saveAppState = (state) => {
  const serializedState = JSON.stringify(state);
  localStorage.setItem('frisson_state', serializedState);
};

export const getUserByToken = (token) => {
  const jwtUser = token && jwt_decode(token);
  return jwtUser;
};

export const roleUser = () => {
  const user = loadAppState();
  return getUserByToken(user?.auth?.currentUser)?.role;
};

export const groupByGenres = (data) => {
  let filterGenres = [];
  data.forEach((x) => {
    filterGenres = filterGenres.concat(x.genre.toLowerCase().split(','));
  });

  return countBy(filterGenres);
};

// convert time
export const convertTime = (type) => {
  switch (type) {
    case DATE.TODATE: {
      return moment().format(FORMAT_DATE_TIME.YYYY_MM_DD);
    }
    case DATE.YES_TER_DATE: {
      return moment().subtract(1, 'days').format(FORMAT_DATE_TIME.YYYY_MM_DD);
    }
    case DATE.LAST_WEEK: {
      return moment().subtract(7, 'days').format(FORMAT_DATE_TIME.YYYY_MM_DD);
    }
    case DATE.LAST_MONTH: {
      return moment().subtract(30, 'days').format(FORMAT_DATE_TIME.YYYY_MM_DD);
    }
    default:
      return;
  }
};

export const generateGreetings = () => {
  const currentHour = moment().format('HH');

  if (currentHour >= 3 && currentHour < 12) {
    return 'Morning';
  } else if (currentHour >= 12 && currentHour < 15) {
    return 'Afternoon';
  } else if (currentHour >= 15 && currentHour < 20) {
    return 'Evening';
  } else if (currentHour >= 20 && currentHour < 3) {
    return 'Night';
  } else {
    return 'Night';
  }
};

export const redirectPage = (e, videoId) => {
  e.stopPropagation();
  return window.open(videoId, '_self');
};
