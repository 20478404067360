import { tutState } from "shared/audiophile/onboarding";
import Twitch from "./twitch";
import { mediaQueryState } from "./mediaQueries";

const ytEmbed =
    '<iframe id="stream" width="100%" height="100%" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""  class="embed"></iframe>';
let twEmbed =
    '<iframe id="stream" class="twEmbed" src="https://player.twitch.tv/?|||&html5&parent=???" frameborder="0" height="100%" width="100%" allowfullscreen> </iframe>';

export let streamProvider = undefined;
let vidId = undefined;

export let player;
let twitchEmbed
let initLoad = false

// YOUTUBE API EVENTS
function onYouTubeIframeAPIReady() {
    console.log("YT api ready")
    player = new window.YT.Player('stream', {
    height: '390',
    width: '640',
    videoId: vidId,
    playerVars: {
        'playsinline': 1,
        'controls' : 0,
        'disablekb' : 1,
        'fs' : 0,
        'modestbranding' : 1,
        'playsinline' : 1,
        'rel' : 0,
        'autoplay' : 1,
    },
    events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange,
        'onError': onError
    }
    });
}

function onError(event) {
    console.log(`yt error ${event}`)
}

function onPlayerReady(event) {
    //event.target.playVideo();
    // document.getElementById('joinCrowdButton').style.opacity = 1;
    // //hide loading
    // document.getElementsByClassName('loading')[0].style.display = 'none'
    
    // console.log("yt ready")
    if (tutState == 1 && new URLSearchParams(window.location.search).get('id').toLowerCase() == 'tutorialshow' )
    {
        skipAhead(0)
    }

    event.target.mute()
    event.target.playVideo()
}

function onPlayerStateChange(event) {
    if (event.data == window.YT.PlayerState.PLAYING && !initLoad) {
        initLoad = true;

        document.getElementById('joinCrowdButton').style.opacity = 1;
        //hide loading
        document.getElementsByClassName('loading')[0].style.display = 'none'
        //unhide the tutorial arrow if tut is active
        if (document.getElementById('tutArrow-1') && document.getElementById('tutArrow-1').classList.contains('static')) {
            document.getElementById('tutArrow-1').classList.remove('hidden')
          }
      }
}

export function skipAhead(time) {
    player.seekTo(time)
}

//TWITCH API EVENTS
function TwitchPlayerReady() {
    console.log("TWITCH api ready")
    player = twitchEmbed.getPlayer();
    player.setMuted(true)
    player.play();

    //mobile may not autoplay so turn the loading off after a delay if it hasn't already
    // if (mediaQueryState != 'desktop') {
        setTimeout(() => {
            initLoad = true;

            document.getElementById('joinCrowdButton').style.opacity = 1;
            //hide loading
            document.getElementsByClassName('loading')[0].style.display = 'none'
            //unhide the tutorial arrow if tut is active
          if (document.getElementById('tutArrow-1').classList.contains('static')) {
            document.getElementById('tutArrow-1').classList.remove('hidden')
          }
        }, 2000);
    // }
}
function TwitchPlayerPlaying() {
    console.log("TWITCH api playing")
    if (!initLoad) {
        initLoad = true;

        document.getElementById('joinCrowdButton').style.opacity = 1;
        //hide loading
        document.getElementsByClassName('loading')[0].style.display = 'none'
    }
}


export function initVid (streamId, curApi) {
    console.log("Stream ID: " + streamId)
    
    if (streamId) {

        //replacement for tutorial show
        if (streamId.toLowerCase() == 'tutorialshow') {
            streamId = 'yt-Xdoo2VAp3bM'
        }

        streamProvider = streamId.substring(0, 2);
            
        if (streamProvider === 'yt') {

            
            createEmbedScript('https://www.youtube.com/iframe_api')
            window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady

            
            vidId = streamId.substring(3);
            // document.getElementById('streamContainer').insertAdjacentHTML('beforeend', ytEmbed);
            // document.getElementById('stream').src = 'https://www.youtube.com/embed/' + vidId + '?autoplay=1&controls=0&disablekb=1&fs=0&modestbranding=1&playsinline=1&rel=0'           

        }
        else if (streamProvider === 'tw') {
            vidId = streamId.split('-')[1]
            
            // let tag = createEmbedScript('https://player.twitch.tv/js/embed/v1.js')

            // tag.addEventListener('load', () => {
                // console.log(Twitch)
                // twitchEmbed = new window.Twitch.Embed("stream", {
                //     width: 854,
                //     height: 480,
                //     channel: vidId,
                //     layout: "video",
                //     autoplay: true
                //   });
            
                // twitchEmbed.addEventListener(window.Twitch.Embed.VIDEO_READY, TwitchPlayerReady);
                // twitchEmbed.addEventListener(window.Twitch.Embed.VIDEO_PLAY, TwitchPlayerPlaying);
            // })

            // let url = window.location.hostname

            

            // document.addEventListener("DOMContentLoaded", (event) => {
            //     console.log(Twitch);
            //   });

            twitchEmbed = new Twitch.Embed("stream", {
                width: 854,
                height: 480,
                channel: vidId,
                layout: "video",
                autoplay: true
              });
        
            twitchEmbed.addEventListener(Twitch.Embed.VIDEO_READY, TwitchPlayerReady);
            twitchEmbed.addEventListener(Twitch.Embed.VIDEO_PLAY, TwitchPlayerPlaying);
            
            // twEmbed = twEmbed.replace('???', url)

            // if (url != 'localhost') {
            //     fetch(curApi + 'GameSessions/GetGameSession?streamUri=' + streamId, {
            //         method: 'GET',
            //         headers: {
            //             Accept: 'text/plain',
            //         }
            //     })
            //     .then(function (response) {
            //         if (response.status >= 400 && response.status < 600) {
            //             response.json().then(function (object) {
            //                 alert(object.errorMessage);
            //             });
            //         } else if (response.status === 200) {
            //             return response.json();
            //         } else {
            //             alert('Cannot join chat, session has expired');
            //         }
            //     })
            //     .then((data) => {
            //         if (data) {

            //             if (data.status == 'Pending' || data.status == 'Live') {
            //                 twEmbed = twEmbed.replace('|||', 'channel=' + vidId)
            //             }
            //             else {
            //                 twEmbed = twEmbed.replace('|||', 'video=' + data.twitchVodId)
            //             }

            //             document.getElementById('streamContainer').insertAdjacentHTML('beforeend', twEmbed)

                        
        
            //         }
            //     })         
            // }
            // else {
            //     twEmbed = twEmbed.replace('|||', 'channel=' + vidId)
            //     document.getElementById('streamContainer').insertAdjacentHTML('beforeend', twEmbed)
            // }

            // setTimeout(() => {  
            //     document.getElementById('joinCrowdButton').style.opacity = 1;
            //     //hide loading
            //     document.getElementsByClassName('loading')[0].style.display = 'none'
            // }, 2000);

            
                   
        }
    }
}

function createEmbedScript (url) {
    
    var element = document.getElementsByTagName('script'), index;

    for (index = element.length - 1; index >= 0; index--) {
        if (element[index].src == url) {
            element[index].parentNode.removeChild(element[index]);
        }
        
    }
    
    var tag = document.createElement('script');
    tag.src = url;
    
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    return tag
}

export function setSectionSelection (selecting) {
    let vidContainer = document.getElementById("videoContainer")
    if (selecting != 0) vidContainer.classList.add("selection")
    else vidContainer.classList.remove("selection")
}