import React from "react";

import "./styles.scss";
const Checkbox = ({ children, formik, name }) => {
  return (
    <>
      <label className="content-checkbox">
        <input
          checked={formik.values[name]}
          onChange={() =>
            formik.setFieldValue(`${name}`, !formik.values.marketingOptIn)
          }
          className="input-checkbox"
          type="checkbox"
        />
        {children}
      </label>
    </>
  );
};
export default Checkbox;
