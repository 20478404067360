import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import './styles.scss';

const ScheduleEvent = ({ events, handleEventClick, handleMonthChange }) => {
  return (
    <>
      <div className='wrapper-schedule-event'>
        <FullCalendar
          headerToolbar={{
            left: 'prev,next,today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          datesSet={(args) => handleMonthChange(args)}
          allDaySlot={false}
          // select={onDateSelect}
          editable={true}
          eventClick={handleEventClick}
          selectable={true}
          initialView='dayGridMonth'
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          eventTextColor='#ffffff'
          eventResizeStart={() => this.updateSize()}
        />
      </div>
      <div className='wrapper-schedule-event-mobile'>
        <FullCalendar
          headerToolbar={{
            left: 'prev,next,today,title,dayGridMonth,timeGridWeek,timeGridDay',
            right: ''
          }}
          datesSet={(args) => handleMonthChange(args)}
          allDaySlot={false}
          // select={onDateSelect}
          editable={true}
          eventClick={handleEventClick}
          selectable={true}
          initialView='dayGridMonth'
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          eventTextColor='#ffffff'
        />
      </div>
    </>
  );
};
export default ScheduleEvent;
