/* eslint-disable no-unused-expressions */
import ThreeMeshUI from 'three-mesh-ui'
import * as THREE from 'three'
// import FontJSON from "../../crowd-chat/assets/nunito.json"
// import FontImage from "../../crowd-chat/assets/nunito.png"
// import FontJSON from "../assets/Roboto-msdf.json"
// import FontImage from "../assets/Roboto-msdf.png";
import FontJSON from "../assets/nunito-bold-msdf.json"
import FontImage from "../assets/nunitobold.png"
import { Scene } from 'three';
import HZ from '../assets/hZ-white.png'
import DB from '../assets/dB-white.png'
import { addRemoveButton } from 'shared/ui-builder';
import { clearRemoveButtons } from 'shared/ui-builder';

export class Cart {

    constructor(removeItemCallback) {
        this.items = []
        this.formattedItems = []
        this.price
        this.disabled
        this.mixer
        this.cartTotal
        this.cartTotalCurrency
        this.cartContainer
        this.cartPriceContainer
        this.cartPriceCurrencyContainer
        this.cartRemoveContainer
        this.currencyContainer
        this.currencyIconContainer
        this.insufficientFundsContainer
        this.emptyText
        this.checkoutButton
        this.checkoutButtonColor = '#059900'
        this.userDB
        this.userHZ
        this.removeCallback = removeItemCallback
        this.insfShowing = false
    }

    containsCategory(category) {
        let matchedCat
        this.items.forEach(item => {
            if (item.type == category) {
                matchedCat = item
            }
        })

        return matchedCat
    }

    contains(item) {
        if (this.items.includes(item)) {
            return true
        }
        else {
            return false
        }
    }

    add(addedItem) {
        if (!this.items.includes(addedItem)) {
            this.items.push(addedItem)

            this.formattedItems.push(addedItem.id + '|' + addedItem.type)

            //add to html
            // let el = document.createElement('p')
            // el.setAttribute('id', addedItem.id)
            // el.setAttribute('class', 'cartItem')
            // let textnode = document.createTextNode(addedItem.name + ' - ' + addedItem.price + 'DB')
            
            // el.appendChild(textnode)
            // // el.appendChild(document.createTextNode('\u2718'));
            // // el.onclick = function() { loadCatalogItem(element) }

            // document.getElementById('cartContainer').appendChild(el)

            //add to cart text

            this.#updateCartContainer()

            this.emptyText.visible = false

            this.#getPrice()        

        }
    }

    remove(removedItem) {

        if (removedItem) {
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                
                if (removedItem.id === item.id) {
                    this.items.splice(i, 1)
                    this.formattedItems.splice(i, 1)

                    // document.getElementById(removedItem.id).remove()
                }

            } 

            this.#updateCartContainer()

            this.#getPrice()   
            
        }
    }

    clear() {
        this.items = []
        this.formattedItems = []
        this.#updateCartContainer()
        this.#getPrice()

        this.emptyText.visible = true
        this.checkoutButton.disabled = true
        this.checkoutButton.material.color.set('#aaa')
    }

    #getPrice() {
        let price = 0

        this.items.forEach(item => {
            price += item.price
        });

        this.price = price

        this.cartTotal.set( { content: price.toString() } )

        if (this.items.length == 0)
        {
            this.emptyText.visible = true
            this.checkoutButton.disabled = true
            this.checkoutButton.colorLocked = true
            this.disabled = true
            this.checkoutButton.material.color.set('#aaa')
            this.cartTotalCurrency.set( { backgroundColor: new THREE.Color( 0xffffff ) } )
            this.cartTotal.set( { fontColor: new THREE.Color( 0xffffff ) } )
        }
        else if (this.price <= this.userDB)
        {
            this.checkoutButton.disabled = false
            this.checkoutButton.colorLocked = false
            this.disabled = false
            this.checkoutButton.material.color.set(this.checkoutButtonColor)
            this.cartTotalCurrency.set( { backgroundColor: new THREE.Color( 0xffffff ) } )
            this.cartTotal.set( { fontColor: new THREE.Color( 0xffffff ) } )
        }
        else if (this.price >= this.userDB) {
            this.checkoutButton.disabled = false
            this.checkoutButton.colorLocked = true
            this.disabled = true
            this.checkoutButton.material.color.set('#ff0000')
            this.cartTotalCurrency.set( { backgroundColor: new THREE.Color( 0xff0000 ) } )
            this.cartTotal.set( { fontColor: new THREE.Color( 0xff0000 ) } )
        }

        // ThreeMeshUI.update()

        // document.getElementById('cartTotal').innerHTML = 'Total: ' + price.toString() + 'DB'
        // document.getElementById('cartContainer').appendChild(document.getElementById('cartTotal'))
        // document.getElementById('cartContainer').appendChild(document.getElementById('buyButton'))
    }

    createTextContainer (mixer, currency) {
        this.userDB = currency.DB
        this.userHZ = currency.HZ

        this.mixer = mixer
        this.checkoutButton = mixer.children.filter(child => child.name == 'save|select')[0]
        this.checkoutButton.disabled = true

        let cartContainer = new ThreeMeshUI.Block( {
            width: 0.42,
            height: 0.15,
            padding: 0.009,
            justifyContent: 'start',
            textAlign: 'left',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSupersampling: true,
            backgroundOpacity: 0,
        } )
    
        cartContainer.position.set(-0.01,0.05,0.025)
        cartContainer.rotation.set(-1.57,0,0)

        cartContainer.name = "CartContainer"

        this.cartContainer = cartContainer
        this.mixer.add(cartContainer)

        let cartPriceContainer = new ThreeMeshUI.Block( {
            width: 0.42,
            height: 0.15,
            padding: 0.009,
            justifyContent: 'start',
            textAlign: 'left',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSupersampling: true,
            backgroundOpacity: 0,
        } )
    
        cartPriceContainer.position.set(-0.01,0.05,0.025)
        cartPriceContainer.rotation.set(-1.57,0,0)

        cartPriceContainer.name = "CartPriceContainer"

        this.cartPriceContainer = cartPriceContainer
        this.mixer.add(cartPriceContainer)

        let cartPriceCurrencyContainer = new ThreeMeshUI.Block( {
            width: 0.42,
            height: 0.15,
            padding: 0.009,
            justifyContent: 'start',
            textAlign: 'left',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSupersampling: true,
            backgroundOpacity: 0,
        } )
    
        cartPriceCurrencyContainer.position.set(0.054,0.05,0.025)
        cartPriceCurrencyContainer.rotation.set(-1.57,0,0)

        cartPriceCurrencyContainer.name = "CartPriceCurrencyContainer"

        this.cartPriceCurrencyContainer = cartPriceCurrencyContainer
        this.mixer.add(cartPriceCurrencyContainer)

        let cartRemoveContainer = new ThreeMeshUI.Block( {
            width: 0.42,
            height: 0.15,
            padding: 0.009,
            justifyContent: 'start',
            textAlign: 'left',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSupersampling: true,
            backgroundOpacity: 0,
        } )
    
        cartRemoveContainer.position.set(0.1,0.05,0.014)
        cartRemoveContainer.rotation.set(-1.57,0,0)

        cartRemoveContainer.name = "CartRemoveContainer"

        this.cartRemoveContainer = cartRemoveContainer
        this.mixer.add(cartRemoveContainer)
    
        let totalText = new ThreeMeshUI.Block({
            height: 0.05,
            width: 0.4,
            margin: 0,
            padding: 0.002,
            fontSize: 0.03,
            bestFit: 'auto',
            textAlign: 'left',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            // justifyContent: "center",
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x440000 )
        })
        totalText.add(
            new ThreeMeshUI.Text({
                content: 'Total:',
            })
        )

        totalText.position.set(-0.01,0.04,0.117)
        totalText.rotation.set(-1.57,0,0)

        totalText.name = "TotalText"
        this.mixer.add(totalText)

        let totalPrice = new ThreeMeshUI.Block({
            height: 0.05,
            width: 0.15,
            margin: 0,
            padding: 0.002,
            fontSize: 0.03,
            bestFit: 'auto',
            textAlign: 'right',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            // justifyContent: "center",
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x440000 )
        })
        totalPrice.add(
            new ThreeMeshUI.Text({
                content: '0',
            })
        )

        totalPrice.position.set(-0.017,0.05,0.115)
        totalPrice.rotation.set(-1.73,0,0)

        totalPrice.name = "TotalPrice"
        this.cartTotal = totalPrice.childrenTexts[0]
        this.mixer.add(totalPrice)

        let cartDbIcon = new ThreeMeshUI.Block( {
            justifyContent: 'center',
            contentDirection: 'column',
            // fontFamily: FontJSON,
            // fontTexture: FontImage,
            fontSize: 0.05,
            padding: 0.0001,
            height: 0.025,
            width: 0.025,
            padding: 0.01,
            borderRadius: 0.0001,
            backgroundOpacity: 1,
            backgroundSize: 'contain',
            backgroundColor: new THREE.Color( 0xffffff ),
            name: "dbIcon"
        } )

        new THREE.TextureLoader().load(DB, (texture) => {
            cartDbIcon.set({
              backgroundTexture: texture,
            });
          });
          cartDbIcon.position.set(0.065,0.06,0.115)
          cartDbIcon.rotation.set(-1.57,0,0)
          this.cartTotalCurrency = cartDbIcon
          this.mixer.add(cartDbIcon)

        let cartEmpty = new ThreeMeshUI.Block({
            height: 0.02,
            width: 0.2,
            margin: 0.001,
            padding: 0,
            // bestFit: 'auto',
            fontSize: 0.014,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            // justifyContent: "center",
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x440000 )
        })
        cartEmpty.add(
            new ThreeMeshUI.Text({
                content: 'Select a category and an item to add it to your cart.',
            })
        )

        cartEmpty.name = "CartEmpty"
        cartEmpty.position.set(-0.08,0.05,-0.02)
        cartEmpty.rotation.set(-1.57,0,0)

        this.emptyText = cartEmpty
        this.mixer.add(cartEmpty)

        let currencyHeaderContainer = new ThreeMeshUI.Block({
            margin: 0.0,
            fontColor: new THREE.Color(0xffffff),
            backgroundOpacity: 0,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            offset: 0.0001,
            // backgroundSize: 'cover',
            backgroundColor: new THREE.Color( 0xff0000 ),
        })

        let currencyHeader = new ThreeMeshUI.Block({
            height: 0.03,
            width: 0.09,
            padding: 0.001,
            offset: 0.0001,
            textAlign: 'center', 
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x00ff00 ),
        })
        currencyHeader.add(
            new ThreeMeshUI.Text({
              content: "Inventory:",
              fontSize: 0.015,
                // bestFit: 'shrink'
            })
          );
        currencyHeaderContainer.add(currencyHeader)

        let currencyContainer = new ThreeMeshUI.Block({
            margin: 0.0,
            fontColor: new THREE.Color(0xffffff),
            backgroundOpacity: 0,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            offset: 0.0001,
            width: 0.065,
            justifyContent: 'end',
			alignItems: 'end',
            // backgroundSize: 'cover',
            backgroundColor: new THREE.Color( 0xff0000 ),
        })
        let currencyProps = {
            height: 0.027,
            width: 0.065,
            padding: 0.001,
            offset: 0.0001,
	        // justifyContent: 'end',
            textAlign: 'right', 
            // bestFit: 'shrink',
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x00ff00 ),
        }

        let dBHeader = new ThreeMeshUI.Block(currencyProps)
        dBHeader.add(
            new ThreeMeshUI.Text({
              content: currency.DB?.toString() ?? '0',
              fontSize: 0.025,
                // bestFit: 'shrink'
            })
          );
        currencyContainer.add(dBHeader)
        let hZHeader = new ThreeMeshUI.Block(currencyProps)

        hZHeader.add(
            new ThreeMeshUI.Text({
              content: currency.HZ?.toString() ?? '0',
              fontSize: 0.025,
                // bestFit: 'shrink'
            })
          );
          currencyContainer.add(hZHeader)

        currencyHeaderContainer.name = "CurrencyHeaderUI"
        currencyContainer.currencyTexts = [dBHeader.childrenTexts[0], hZHeader.childrenTexts[0]]

        currencyHeaderContainer.position.set(0.28,0.047,-0.045)
        currencyHeaderContainer.rotation.set(-1.57,0,0)
        currencyContainer.name = "CurrencyUI"
        currencyContainer.position.set(0.271,0.047,-0.008)
        currencyContainer.rotation.set(-1.57,0,0)
        
        this.currencyContainer = currencyContainer

        let dbIcon = new ThreeMeshUI.Block( {
            justifyContent: 'center',
            contentDirection: 'column',
            // fontFamily: FontJSON,
            // fontTexture: FontImage,
            fontSize: 0.05,
            padding: 0.0001,
            height: 0.025,
            width: 0.025,
            padding: 0.01,
            borderRadius: 0.0001,
            backgroundOpacity: 1,
            backgroundSize: 'contain',
            backgroundColor: new THREE.Color( 0xffffff ),
        } )

        new THREE.TextureLoader().load(DB, (texture) => {
            dbIcon.set({
              backgroundTexture: texture,
            });
          });
        //   dbIcon.position.set(0.305,0.05,-0.016)
        //   dbIcon.rotation.set(-1.57,0,0)

          let hzIcon = new ThreeMeshUI.Block( {
            justifyContent: 'center',
            contentDirection: 'column',
            // fontFamily: FontJSON,
            // fontTexture: FontImage,
            fontSize: 0.05,
            padding: 0.0001,
            height: 0.025,
            width: 0.025,
            padding: 0.01,
            borderRadius: 0.0001,
            backgroundOpacity: 1,
            backgroundSize: 'contain',
            backgroundColor: new THREE.Color( 0xffffff ),
        } )

        new THREE.TextureLoader().load(HZ, (texture) => {
            hzIcon.set({
              backgroundTexture: texture,
            });
          });
        //   hzIcon.position.set(0.305,0.05,0.015)
        //   hzIcon.rotation.set(-1.57,0,0)
        let currencyIconContainer = new ThreeMeshUI.Block({
            margin: 0.0,
            fontColor: new THREE.Color(0xffffff),
            backgroundOpacity: 0,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            offset: 0.0001,
            // backgroundSize: 'cover',
            backgroundColor: new THREE.Color( 0xff0000 ),
        })
        currencyIconContainer.name = "CurrencyIconContainer"
        currencyIconContainer.position.set(0.327,0.027,0.005)
        currencyIconContainer.rotation.set(-1.57,0,0)
        this.currencyIconContainer = currencyIconContainer
          this.currencyIconContainer.add(dbIcon, hzIcon)
          this.mixer.add(currencyHeaderContainer, currencyContainer, currencyIconContainer )

          let insufficientFundsContainer = new ThreeMeshUI.Block({
            margin: 0.0,
            height: 0.06,
            width: 0.1,
            padding: 0.001,
            fontColor: new THREE.Color(0xffffff),
            backgroundOpacity: 0,
            fontFamily: FontJSON,
            fontTexture: FontImage,
            offset: 0.0001,
            justifyContent: "center",
            // backgroundSize: 'cover',
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x000000 ),
        })
        insufficientFundsContainer.name = "InsufficientFundsContainer"
        let iFTopBar = new ThreeMeshUI.Block({
            height: 0.025,
            width: 0.1,
            padding: 0,
            offset: 0.0001,
            textAlign: 'center', 
            fontColor: new THREE.Color(0xff0000),
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x000000 ),
        })
        iFTopBar.add(
            new ThreeMeshUI.Text({
              content: "_____________",
              fontSize: 0.013,
                bestFit: 'shrink'
            })
          );
        let iFHeader = new ThreeMeshUI.Block({
            height: 0.015,
            width: 0.1,
            padding: 0,
            offset: 0.0001,
            textAlign: 'center', 
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x000000 ),
        })
        iFHeader.add(
            new ThreeMeshUI.Text({
              content: "**Insufficient**",
              fontSize: 0.013,
                bestFit: 'shrink'
            })
          );
          let iFHeader2 = new ThreeMeshUI.Block({
            height: 0.015,
            width: 0.1,
            padding: 0,
            offset: 0.0001,
            textAlign: 'center', 
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x000000 ),
        })
        iFHeader2.add(
            new ThreeMeshUI.Text({
                content: "**Funds!**",
                fontSize: 0.013,
                bestFit: 'shrink'
            })
        );
        let iFBottomBar = new ThreeMeshUI.Block({
            height: 0.005,
            width: 0.1,
            padding: 0,
            offset: 0.0001,
            textAlign: 'center', 
            fontColor: new THREE.Color(0xff0000),
            backgroundOpacity: 0,
            backgroundColor: new THREE.Color( 0x000000 ),
        })
        iFBottomBar.add(
            new ThreeMeshUI.Text({
              content: "_____________",
              fontSize: 0.013,
                bestFit: 'shrink'
            })
          );
        insufficientFundsContainer.add(iFTopBar)
          insufficientFundsContainer.add(iFHeader)
          insufficientFundsContainer.add(iFHeader2)
          insufficientFundsContainer.add(iFBottomBar)
          insufficientFundsContainer.position.set(0.281,0.067,-0.008)
          insufficientFundsContainer.rotation.set(-1.57,0,0)
          this.insufficientFundsContainer = insufficientFundsContainer
          this.insufficientFundsContainer.visible = false
          this.mixer.add(insufficientFundsContainer)

        // let currencyContainer = new ThreeMeshUI.Block( {
        //     justifyContent: 'center',
        //     contentDirection: 'column',
        //     fontFamily: FontJSON,
        //     fontTexture: FontImage,
        //     // fontColor: new THREE.Color(0x000000),
        //     fontSize: 0.01,
        //     padding: 0.2,
        //     height: 0.1,
        //     width: 0.1,
        //     borderRadius: 0,
        //     backgroundOpacity: 1,
        //     // backgroundSize: 'cover',
        //     backgroundColor: new THREE.Color( 0xff0000 ),
        //     fontSupersampling: true
        // } )
        // const textOptions = {
        //     width: 0.1,
        //     height: 0.04,
        //     textAlign: 'right',
        //     // justifyContent: 'right',
        //     // bestFit: "auto",
        //     // padding: 0,
        //     offset: 0.0001,
        //     // margin: 0.01,
        //     fontSize: 0.015,
        //     borderRadius: 0,
        //     backgroundOpacity: 0,
        //     backgroundColor: new THREE.Color( 0x440000 ),
        // }

        // let currencyHeader = new ThreeMeshUI.Block(textOptions)
        // currencyHeader.add(
        //     new ThreeMeshUI.Text({
        //         content: 'You have:',
        //         textAlign: 'center'
        //     })
        // )
        // currencyHeader.position.set(-0.02, 0.02, 0)
        // currencyHeader.name = "CurrencyHeader"
        // this.mixer.add(currencyHeader)
    
        // let currencyText = new ThreeMeshUI.Block(textOptions)
        // currencyText.add(
        //     new ThreeMeshUI.Text({
        //         content: '\n' + currency.DB?.toString() ?? '0',
        //         textAlign: 'left'
        //     }),
    
        //     new ThreeMeshUI.Text({
        //         content: '\n' + (currency.HZ?.toString() ?? '0'),
        //         textAlign: 'left'
        //     })
        // )
        // currencyText.position.set(-0.02, 0.01, 0)
        // currencyContainer.add(currencyText)
        // currencyContainer.currencyTexts = [currencyText.childrenTexts[1], currencyText.childrenTexts[2]]

        // currencyContainer.name = "CurrencyUI"
        // currencyContainer.position.set(0.294,0.037,-0.015)
        // currencyContainer.rotation.set(-1.57,0,0)
        // this.mixer.add(currencyContainer)

    }

    #updateCartContainer () {

        console.log("updating cart")

        this.mixer.remove(this.cartContainer)
        this.cartContainer.clear()
        // clearRemoveButtons(this.mixer)

        this.cartContainer = new ThreeMeshUI.Block( {
            width: 0.22,
            height: 0.15,
            padding: 0.013,
            // contentDirection: 'column',
            // justifyContent: 'start',
            textAlign: 'left',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSupersampling: true,
            backgroundOpacity: 0,
        } )

        this.cartContainer.position.set(-0.11,0.05,0.025)
        this.cartContainer.rotation.set(-1.57,0,0)

        this.cartContainer.name = "CartContainer"

        this.mixer.remove(this.cartPriceContainer)
        this.cartPriceContainer.clear()

        this.cartPriceContainer = new ThreeMeshUI.Block( {
            width: 0.22,
            height: 0.15,
            padding: 0.013,
            // contentDirection: 'column',
            // justifyContent: 'end',
            textAlign: 'right',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSupersampling: true,
            backgroundOpacity: 0,
        } )

        this.cartPriceContainer.position.set(0.01,0.05,0.025)
        this.cartPriceContainer.rotation.set(-1.57,0,0)

        this.cartPriceContainer.name = "CartPriceContainer"

        this.mixer.remove(this.cartPriceCurrencyContainer)
        this.cartPriceCurrencyContainer.clear()

        this.cartPriceCurrencyContainer = new ThreeMeshUI.Block( {
            width: 0.22,
            height: 0.15,
            padding: 0.013,
            // contentDirection: 'column',
            // justifyContent: 'end',
            textAlign: 'right',
            fontFamily: FontJSON,
            fontTexture: FontImage,
            fontSupersampling: true,
            backgroundOpacity: 0,
        } )

        this.cartPriceCurrencyContainer.position.set(0.054,0.05,0.025)
        this.cartPriceCurrencyContainer.rotation.set(-1.57,0,0)

        this.cartPriceCurrencyContainer.name = "CartPriceCurrencyContainer"

        

        // this.mixer.remove(this.cartRemoveContainer)
        // this.cartRemoveContainer.clear()

        // this.cartRemoveContainer = new ThreeMeshUI.Block( {
        //     width: 0.22,
        //     height: 0.15,
        //     padding: 0.013,
        //     // contentDirection: 'column',
        //     // justifyContent: 'end',
        //     textAlign: 'right',
        //     fontFamily: FontJSON,
        //     fontTexture: FontImage,
        //     fontSupersampling: true,
        //     backgroundOpacity: 0,
        // } )

        // this.cartRemoveContainer.position.set(0.1,0.05,0.014)
        // this.cartRemoveContainer.rotation.set(-1.57,0,0)

        // this.cartRemoveContainer.name = "CartRemoveContainer"

        this.items.forEach(item => {
            //add to cart text
            let cartItem = new ThreeMeshUI.Block({
                height: 0.02,
                width: 0.2,
                margin: 0.001,
                padding: 0,
                fontSize: 0.014,
                // bestFit: 'auto',
                justifyContent: "start",
                backgroundOpacity: 0,
                backgroundColor: new THREE.Color( 0x440000 )
            })
            cartItem.add(
                new ThreeMeshUI.Text({
                    content: item.name,
                })
            )
            
    
            cartItem.name = item.id
            this.cartContainer.add(cartItem)

            //prices//

            //add to cart text
            let cartPriceItem = new ThreeMeshUI.Block({
                height: 0.02,
                width: 0.07,
                margin: 0.001,
                padding: 0,
                fontSize: 0.014,
                // bestFit: 'auto',
                justifyContent: "end",
                backgroundOpacity: 0,
                backgroundColor: new THREE.Color( 0x440000 )
            })
            cartPriceItem.add(
                new ThreeMeshUI.Text({
                    content:item.price.toString(),
                })
            )
            
    
            cartPriceItem.name = item.id + ' price'
            this.cartPriceContainer.add(cartPriceItem)

            let dbIcon = new ThreeMeshUI.Block( {
                justifyContent: 'end',
                contentDirection: 'column',
                // fontFamily: FontJSON,
                // fontTexture: FontImage,
                fontSize: 0.05,
                padding: 0.0001,
                height: 0.022,
                width: 0.018,
                padding: 0.01,
                borderRadius: 0.0001,
                backgroundOpacity: 1,
                backgroundSize: 'contain',
                backgroundColor: new THREE.Color( 0xffffff )
            } )
    
            new THREE.TextureLoader().load(DB, (texture) => {
                dbIcon.set({
                  backgroundTexture: texture,
                });
              });
            //   dbIcon.position.set(0.305,0.05,-0.016)
            //   dbIcon.rotation.set(-1.57,0,0)
            this.cartPriceCurrencyContainer.add(dbIcon)


            // let callbacks = [
            //     () => {
            //         this.removeCallbacks[1](item.shelf, item.bone)
            //     },
            //     () => {
            //         this.removeCallbacks[0](item)
            //     }
            // ]
            // addRemoveButton(
            //     () => {
            //         this.removeCallback(item)
            //     }
            // )

            //remove button
            // const buttonOptions = {
            //     width: 0.025,
            //     height: 0.025,
            //     justifyContent: 'center',
            //     // bestFit: "auto",
            //     fontSize: 0.014,
            //     margin: 0.0001,
            //     padding: 0.02,
            //     borderRadius: 0.01,
            //     backgroundColor: new THREE.Color( 0xff0000 )
            // }
            // const selectedAttributes = {
            //     // offset: 0.02,
            //     backgroundColor: new THREE.Color( 0x777777 ),
            //     fontColor: new THREE.Color( 0x222222 )
            // }
            // const textOptions = {
            //     fontSize: 0.014,
            //     // width: 0.3,
            //     // height: 0.1,
            //     justifyContent: 'center',
            //     bestFit: "auto",
            //     offset: 0.0001,
            //     // margin: 0.001,
            //     borderRadius: 0.075,
            //     backgroundOpacity: 0,
            //     content: 'X'
            // }

            //add new remove button for this item

            // let removeButton = new ThreeMeshUI.Block(buttonOptions)
	
            // removeButton.add (
            //     new ThreeMeshUI.Text( textOptions )
            // )

            // removeButton.setupState( {
            //     state: 'idle',
            //     attributes: {
            //         backgroundColor: new THREE.Color( 0xff0000 ),
            //         fontColor: new THREE.Color(0xffffff),
            //         offset: 0.001,
            //     }
            // } )
            // removeButton.setupState( {
            //     state: 'hovered',
            //     attributes: {
            //         backgroundColor: new THREE.Color( 0x777777 ),
            //         fontColor: new THREE.Color( 0xffffff ),
            //         offset: 0.004,
            //     },
            //     onSet: () => {
            //         //console.log('set to hover')
            //     }
            // } )
            // removeButton.setupState({
            //     state: 'selected',
            //     attributes: selectedAttributes,
            //     onSet: () => {
            //         //callback()
            //     }
            // })

            // removeButton.setState('idle')

            // this.cartRemoveContainer.add(removeButton)
        });

        this.mixer.add(this.cartContainer)
        this.mixer.add(this.cartPriceContainer)
        this.mixer.add(this.cartPriceCurrencyContainer)
        // this.mixer.add(this.cartRemoveContainer)


    }

    cantAfford (show) {
        if (!this.insfShowing) {
            if (show) {
                this.insfShowing = true
                this.insufficientFundsContainer.visible = true
                this.currencyContainer.visible = false
                this.currencyIconContainer.visible = false

                setTimeout(() => {
                    this.insfShowing = false
                    this.cantAfford(false)
                }, 1600)
                
            }
            else {
                
                this.insufficientFundsContainer.visible = false
                this.currencyContainer.visible = true
                this.currencyIconContainer.visible = true
            }
        }
        
        
    }

    updateStoreCurrency (currencyVals) {

        this.userDB = currencyVals['DB']
        this.userHZ = currencyVals['HZ']

        this.currencyContainer.currencyTexts[0].set( { content: (currencyVals['DB'] ?? 0).toString() } )
        this.currencyContainer.currencyTexts[1].set( { content: (currencyVals['HZ'] ?? 0).toString() } )
        // console.log('here')
    
    }
}