import React from 'react';
import ButtonVideo from '../button-video';
import TitleCommon from '../title-common';
import LiveNow from '../live-now';
import { FILTER_STATUS } from '../../shared/utils/constants';
import { redirectPage } from '../../shared/utils/helpers';
import icons from '../../constants/icon';

const RecentlyItem = ({ featureShow, item, classItem }) => {
  let featureShowTrue;
  if (featureShow) {
    featureShowTrue = <TitleCommon title={featureShow} />;
  }
  return (
    <div
      className={`gallery ${classItem ? classItem : 'item-1'}`}
      onClick={(e) => redirectPage(e, `jointhecrowd?id=${item?.id}`)}
      style={{ minHeight: '300px' }}
    >
      {/*<img src={item?.thumbnailUri} alt={''} />*/}
      <img src={item?.thumbnailUri ? item?.thumbnailUri : icons.defaultImage} alt={''}  className={!item?.thumbnailUri ? 'defaultImg smallImg' : ''}/>
      {featureShowTrue ? featureShowTrue : ''}
      <div className='content-top-item-1'>
        <div className='genres-item-1'>
          <div className='now-item'>{item?.genre || 'POP'}</div>
        </div>
        <div className='people-item-1'>
          <div className='now-item'>{item?.totalConnections}</div>
          <div className='watcher'> In the crowd</div>
        </div>
      </div>
      {item?.status === FILTER_STATUS.LIVE && <LiveNow />}
      <div className='content-bottom-item-1'>
        <div className='desc item-1-desc'>
          <div className='left__title'>
            {item?.targetArtistDisplayName}
          </div>
          <div className='left__description'>
            {item?.streamTitle}
          </div>
        </div>
        <div className='live-now-bottom-right'>
          <ButtonVideo videoId={`jointhecrowd?id=${item?.id}`} />
        </div>
      </div>
    </div>
  );
};
export default RecentlyItem;