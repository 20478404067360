import React from 'react';
import './styles.scss';
import sprite from '../../assets/svg/sprite.svg';
import { useHistory } from 'react-router-dom';
import { resetState } from '../../slices/friendsList';
import { logout } from '../../slices/auth';
import { useDispatch } from 'react-redux';

const IconMenu = ({ isOpen }) => {
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(resetState());
    dispatch(logout());
  };
  const history = useHistory();
  const navigateProfile = () => {
    history.push('/');
  };
  const navigateProfileDiscover = () => {
    history.push('/discover');
  };
  const navigateProfileRecommendations = () => {
    history.push('/recommendations');
  };
  return (
    <div
      className='label-1 advance-search-menu-icon'
      style={{ display: isOpen ? 'flex' : 'none' }}
    >
      <div className='grid-icon-menu'>
        <div onClick={() => navigateProfile()} style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          cursor: 'pointer'
        }}>
          <svg style={{ width: '30px', height: '30px' }}>
            <use href={sprite + '#home'}></use>
          </svg>
        </div>
        <div onClick={onLogout} style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'
        }}>
          <svg style={{ width: '30px', height: '30px' }}>
            <use href={sprite + '#search_t'}></use>
          </svg>
        </div>
        <div onClick={()=>{navigateProfileRecommendations()}} style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'
        }}>
          <svg style={{ width: '30px', height: '30px' }}>
            <use href={sprite + '#molnia'}></use>
          </svg>
        </div>
        <div onClick={()=>{navigateProfileDiscover()}} style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center'
        }}>
          <svg style={{ width: '30px', height: '30px' }}>
            <use href={sprite + '#planets'}></use>
          </svg>
        </div>
      </div>
    </div>
  );
};
export default IconMenu;
