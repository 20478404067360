import React from "react";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch } from 'react-redux';
import { login } from "../../slices/auth";

import * as Yup from "yup";
import "./styles.scss";

import 'playfab-web-sdk/src/PlayFab/PlayFabClientApi.js';
import toast from 'react-hot-toast';
import { toastConfig } from '../../shared/defaultValue';
import { PLAYFAB_TITLE_ID } from '../../shared/utils/constants';

const Login = () => {

  const dispatch = useDispatch();
  window.PlayFab.settings.titleId = PLAYFAB_TITLE_ID.titleId;

  const switchForgotLogin = (active) => {
    const login = document.querySelector(".login-section");
    const loginTitle = document.querySelector(".--login");
    const signUpTitle = document.querySelector(".sign-up");
    const forgotPassword = document.querySelector(".forgot-password-section");
    const forgotPasswordTitle = document.querySelector(".--forgot-password");

    if (active === true) {
      login.style.display = "none";
      loginTitle.style.display = "none";
      signUpTitle.style.display = "none";
      forgotPassword.setAttribute("style", "display:contents !important");
      forgotPasswordTitle.setAttribute("style", "display:flex !important");
    } else if (active === false) {
      login.style.display = "contents";
      loginTitle.style.display = "flex";
      signUpTitle.style.display = "flex";
      forgotPassword.style.display = "none";
      forgotPasswordTitle.style.display = "none";
    }
  };
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("Required")
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema,
    onSubmit: async (values) => {
      const loginRequest = {
        Email: values.email,
        Password: values.password,
      };

      try {
        window.PlayFab.ClientApi.LoginWithEmailAddress(loginRequest, (response, error) => {
          if (error) {
            handleLoginError(error);
          } else {
            const loginValue = {
              email: values?.email,
              sessionTicket: response?.data.SessionTicket
            };
            dispatch(login(loginValue));
          }
        });
      } catch (error) {
        toast.error(error?.errorMessage, toastConfig);
      }
    },
  });


  function handleLoginError(error) {
    toast.error(error?.errorMessage, toastConfig);
  }

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <div className="login-section">
        <div className="wrapper-control">
          <div className="input-content-control">
            <i className={`fa fa-envelope-o`} aria-hidden="true" />
            <input
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`input-custom-control ${
                formik.touched.email && formik.errors.email
                  ? "is-invalid"
                  : null
              }`}
              placeholder="Email address"
              name="email"
              autoComplete="off"
            />
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="wrapper-control">
          <div className="input-content-control">
            <i className="fa fa-lock" aria-hidden="true" />
            <input
              value={formik.values.password}
              onChange={formik.handleChange}
              className="input-custom-control"
              type="password"
              placeholder="Password"
              name="password"
              autoComplete="off"
            />
          </div>
        </div>
        <Button
          variant="primary"
          type="submit"
          className="btn-submit"
          style={{ width: "100%" }}
        >
          Login
        </Button>
        <div
          className="forgot-password"
          onClick={() => switchForgotLogin(true)}
        >
          Forgot your password ?
        </div>
      </div>
    </form>
  );
};

export default Login;
