import React from 'react';

const MenuButton = ({
  styleButton,
  setAddclass,
  setAddClassShadow,
  activeShadow,
  isAuthenticated,
  openMenuClick
}) => {
  return (
    <div
      className={styleButton}
      onMouseEnter={() => {
        setAddclass((val) => (val = true));
        setAddClassShadow((val) => (val = true));
      }}
      onMouseLeave={() => {
        setAddclass((val) => (val = false));
        setAddClassShadow((val) => (val = false));
      }}
      onClick={()=>{!isAuthenticated ? activeShadow() : openMenuClick(true)}}
    >
      {!isAuthenticated ? (
        <p>
          Log In / <br /> Sign Up
        </p>
      ) : (
        <div className="color-icon">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
    </div>
  );
};
export default MenuButton;
