import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DropDownItem from '../../components/dropdown-item';
import showData from '../../data/dropdown-menu/shows.json';
import byTimeData from '../../data/dropdown-menu/bytime.json';
import { genres as genresData } from '../../shared/defaultValue';
import { typeGenre } from '../../selectors/genres.selector';
import { selectedGenre, selectedShow, selectedTime } from '../../slices/genres';
import './styles.scss';
import { useCallback } from 'react';

const DropDownMenu = ({ className }) => {
  const dispatch = useDispatch();
  const { genre, show, time } = useSelector(typeGenre);
  const [isReset, setReset] = useState(false);
  const [valueGenres, setGenres] = useState(genre);
  const [valueShow, setShow] = useState(show);
  const [valueTime, setTime] = useState(time);
  const handleClick = useCallback(() => {
    setReset(true);
    dispatch(selectedGenre({ label: 'Genres', value: 'genres' }));
  }, [dispatch]);
  useEffect(() => {
    setGenres(genre);
  }, [genre]);
  useEffect(() => {
    setShow(show);
  }, [show]);
  useEffect(() => {
    setTime(time);
  }, [time]);
  useEffect(() => {
    dispatch(selectedShow(valueShow));
  }, [dispatch, valueShow]);
  useEffect(() => {
    dispatch(selectedGenre(valueGenres));
  }, [dispatch, valueGenres]);
  useEffect(() => {
    dispatch(selectedTime(valueTime));
  }, [dispatch, valueTime]);
  return (
    <div className={`wrapper-dropdown-menu ${className}`}>
      <div className='nav-area'>
        <ul className='content-menu'>
          <DropDownItem
            data={showData}
            className={className}
            defaulValue={{ value: 'show', label: 'Shows' }}
            key={'wrapper-dropdown-menu-2'}
            isReset={isReset}
            value={valueShow}
            setReset={setReset}
            setValue={setShow}
          />
          <DropDownItem
            data={byTimeData}
            defaulValue={{ value: 'bytime', label: 'By Time' }}
            className={className}
            key={'wrapper-dropdown-menu-2233423423'}
            isReset={isReset}
            setReset={setReset}
            value={valueTime}
            setValue={setTime}
          />
          <DropDownItem
            data={genresData}
            defaulValue={{ label: 'Genres', value: 'genres' }}
            className={className}
            key={'wrapper-dropdown-menu-245645645645'}
            isReset={isReset}
            setReset={setReset}
            setValue={setGenres}
            value={valueGenres}
          />
        </ul>
      </div>
      {(genre.value !== 'genres' ||
        show.value !== 'show' ||
        time.value !== 'bytime') && (
        <div className='btn-reset-filter' onClick={handleClick}>
          <div className='btn-icon'>
            <i className='fa fa-undo' aria-hidden='true' />
          </div>
        </div>
      )}
    </div>
  );
};
export default DropDownMenu;
