import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  profileUser: undefined,
  error: '',
  loading: false,
  success: false,
  dataRemotePerApi: undefined,
  payouts: undefined,
  emailForFinishSetup: undefined,
  emailForArtist: undefined,
  apiDb: undefined,
  showWatchlist: undefined,
  showTargetArtist: undefined,
  nameForArtist: undefined,
  passwordForArtist: undefined
};
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeObjectScheduleStartTime: (state, payload) => {
      return {
        ...state,
        dataRemotePerApi: {
          ...state.dataRemotePerApi,
          schedule: {
            ...state.dataRemotePerApi.schedule,
            schedule: [
              ...state.dataRemotePerApi.schedule.schedule.map((post, index) =>
                Number(payload.payload.id) === index
                  ? { ...post, startTime: payload.payload.startTime }
                  : post
              )
            ]
          }
        }
      };
    },
    changeObjectScheduleEndTime: (state, payload) => {
      return {
        ...state,
        dataRemotePerApi: {
          ...state.dataRemotePerApi,
          schedule: {
            ...state.dataRemotePerApi.schedule,
            schedule: [
              ...state.dataRemotePerApi.schedule.schedule.map((post, index) =>
                Number(payload.payload.id) === index
                  ? { ...post, endTime: payload.payload.endTime }
                  : post
              )
            ]
          }
        }
      };
    },
    changeObjectScheduleInputCheckbox: (state, payload) => {
      return {
        ...state,
        dataRemotePerApi: {
          ...state.dataRemotePerApi,
          schedule: {
            ...state.dataRemotePerApi.schedule,
            schedule: [
              ...state.dataRemotePerApi.schedule.schedule.map((post, index) => {
                if (
                  Number(payload.payload.id) === index &&
                  payload.payload.checked === true
                ) {
                  return { ...post, endTime: '', startTime: '' };
                } else if (
                  Number(payload.payload.id) === index &&
                  payload.payload.checked === false
                ) {
                  return {
                    ...post,
                    startTime: '12:15AM',
                    endTime: '12:45AM'
                  };
                } else return post;
              })
            ]
          }
        }
      };
    },
    updateTimeZone: (state, payload) => {
      return {
        ...state,
        dataRemotePerApi: {
          ...state.dataRemotePerApi,
          schedule: {
            ...state.dataRemotePerApi.schedule,
            timezone: payload.payload
          }
        }
      };
    },
    updateScheduleRequest: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    getProfileRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    updateProfileRequest: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    payPalGetCodeRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    payPalGetCodeHistoryRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    payPalGetCodeHistorySuccess: (state, { payload }) => {
      return {
        ...state,
        payouts: payload,
        loading: false,
        success: true
      };
    },
    addEmailArtistRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    addEmailArtistSuccess: (state, { payload }) => {
      // debugger
      return {
        ...state,
        emailForArtist: payload,
        loading: false,
        success: true
      };
    },
    addNameArtistRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    addNameArtistSuccess: (state, { payload }) => {
      // debugger
      return {
        ...state,
        nameForArtist: payload,
        loading: false,
        success: true
      };
    },
    addPasswordArtistRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    addPasswordArtistSuccess: (state, { payload }) => {
      // debugger
      return {
        ...state,
        passwordForArtist: payload,
        loading: false,
        success: true
      };
    },
    deAuthorizationState: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    getRemoteStreamShutdownRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getRemoteStreamShutdownSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true
      };
    },
    StreamRestartRequest: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    getRemotePerConfRequest: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    getRemotePerConfSuccess: (state, { payload }) => {
      return {
        ...state,
        dataRemotePerConf: payload,
        loading: false,
        success: true
      };
    },
    getRemotePerScheduleRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getRemotePerScheduleSuccess: (state, { payload }) => {
      return {
        ...state,
        dataRemotePerApi: payload,
        loading: false,
        success: true
      };
    },
    slicesRemotePerScheduleRequest: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    slicesRemotePerScheduleSuccess: (state, { payload }) => {
      return {
        ...state,
        dataRemotePerSchedule: payload,
        loading: false,
        success: true
      };
    },
    sendDbAmountRequest: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    sendDbAmountSuccess: (state, { payload }) => {

      return {
        ...state,
        apiDb:payload,
        loading: false,
        success: true
      };
    },
    getRemotePerStatusRequest: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    getRemoteRequestConfig: (state) => {
      return {
        ...state,
        loading: false
      };
    },
    getRemotePerStatusSuccess: (state, { payload }) => {
      return {
        ...state,
        dataRemotePerStatus: payload,
        loading: false,
        success: true
      };
    },
    getProfileSuccess: (state, { payload }) => {
      return {
        ...state,
        profileUser: payload,
        loading: false,
        success: true
      };
    },
    getProfileError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },
    updateProfilePreferences: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    updateProfileSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true
      };
    },
    registerProducerSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        isSignUp: payload,
        success: true
      };
    },
    updateProfileError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },
    submissionRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    submissionSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true
      };
    },
    submissionError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },
    submissionContactRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    submissionContactSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: payload
      };
    },
    submissionContactError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },
    resetState: () => {
      return { ...initialState };
    },

    getWatchListRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getWatchListSuccess: (state, { payload }) => {
      return{
        ...state,
        showWatchlist: payload,
        loading: false,
        success: true
      }
    },
    getWatchListError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    getRemoveWatchListRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getRemoveWatchListSuccess: (state, { payload }) => {
      return{
        ...state,
        scheduledPerformanceId: payload,
        loading: false,
        success: true
      }
    },
    getRemoveWatchListError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    getTargetArtistsRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getTargetArtistsSuccess: (state, { payload }) => {
      return{
        ...state,
        showTargetArtist: payload,
        loading: false,
        success: true
      }
    },
    getTargetArtistsError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    setRemoveUserWatchListRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    setRemoveUserWatchListSuccess: (state, { payload }) => {
      return{
        ...state,
        scheduledPerformanceId: payload,
        loading: false,
        success: true
      }
    },
    setRemoveUserWatchListError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    getAddWatchListRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getAddWatchListSuccess: (state, { payload }) => {
      return{
        ...state,
        scheduledPerformanceId: payload,
        loading: false,
        success: true
      }
    },
    getAddWatchListError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    getAddArtistRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getAddArtistSuccess: (state, { payload }) => {
      return{
        ...state,
        scheduledPerformanceId: payload,
        loading: false,
        success: true
      }
    },
    getAddArtistError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    setSearchWatchListRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    setSearchWatchListSuccess: (state, { payload }) => {
      return{
        ...state,
        showWatchlist: payload,
        loading: false,
        success: true
      }
    },
    setSearchWatchListError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },



    setUpdatePlayerLocationRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    setUpdatePlayerLocationSuccess: (state, { id, url }) => {
      return{
        ...state,
        showId:  id,
        url:  url,
        loading: false,
        success: true
      }
    },
    setUpdatePlayerLocationError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    setRefreshTokenRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    setRefreshTokenSuccess: (state, { payload }) => {
      return{
        ...state,
        refreshToken: payload,
        loading: false,
        success: true
      }
    },
    setRefreshTokenError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    setFeedbackRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    setFeedbackSuccess: (state, { payload }) => {
      return{
        ...state,
        payload,
        loading: false,
        success: true
      }
    },
    setFeedbackError:(state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },



  }
});
export const {
  sendDbAmountSuccess,
  sendDbAmountRequest,
  updateSelectTwitch,
  changeObjectScheduleInputCheckbox,
  StreamRestartRequest,
  getRemoteStreamShutdownRequest,
  updateTimeZone,
  getRemoteRequestConfig,
  updateScheduleRequest,
  changeObjectScheduleEndTime,
  changeObjectScheduleStartTime,
  getRemotePerStatusRequest,
  getRemotePerStatusSuccess,
  getRemotePerScheduleSuccess,
  getRemotePerScheduleRequest,
  getRemotePerConfSuccess,
  getRemotePerConfRequest,
  deAuthorizationState,
  updateProfilePreferences,
  getProfileRequest,
  getProfileSuccess,
  getProfileError,
  updateProfileRequest,
  updateProfileSuccess,
  updateProfileError,
  submissionRequest,
  submissionSuccess,
  submissionError,
  submissionContactRequest,
  submissionContactSuccess,
  submissionContactError,
  payPalGetCodeRequest,
  payPalGetCodeHistoryRequest,
  payPalGetCodeHistorySuccess,
  addEmailArtistRequest,
  addEmailArtistSuccess,
  getWatchListRequest,
  getWatchListSuccess,
  getWatchListError,
  getTargetArtistsRequest,
  getTargetArtistsSuccess,
  getTargetArtistsError,
  getRemoveWatchListRequest,
  getRemoveWatchListSuccess,
  getRemoveWatchListError,
  setRemoveUserWatchListSuccess,
  setRemoveUserWatchListError,
  setRemoveUserWatchListRequest,
  getAddWatchListRequest,
  getAddWatchListSuccess,
  getAddWatchListError,
  getAddArtistRequest,
  getAddArtistSuccess,
  getAddArtistError,
  setSearchWatchListRequest,
  setSearchWatchListSuccess,
  setSearchWatchListError,
  setUpdatePlayerLocationRequest,
  setUpdatePlayerLocationSuccess,
  setUpdatePlayerLocationError,
  setRefreshTokenRequest,
  setRefreshTokenSuccess,
  setRefreshTokenError,
  setFeedbackRequest,
  setFeedbackSuccess,
  setFeedbackError,
  registerProducerSuccess,
  addNameArtistRequest,
  addNameArtistSuccess,
  addPasswordArtistRequest,
  addPasswordArtistSuccess,
} = userSlice.actions;
export default userSlice.reducer;
