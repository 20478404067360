import WOW from 'wowjs';
import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Router, Route, Switch } from 'react-router-dom';
// Include This Home
import Home from './pages/homes/Home';
// Include This Extra Pages
import Error from './pages/others/Error';
//Crowd Chat Pages
// import CrowdChat from "./pages/crowd-chat/CrowdChat";
import FinishProducerSetup from './pages/finish-producer-setup/FinishProducerSetup';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import DownloadPage from './pages/download-page/DownloadPage';
import PaymentSuccessful from './pages/payment_successful/PaymentSuccessful';
//ThreeJS Pages
import CrowdChat from './pages/crowd-chat/CrowdChat';
import DressingRoom from 'pages/dressing-room/DressingRoom';
import Store from 'pages/store/Store';
import ATM from './pages/atm/atm';
//Main Pages
import Discover from './pages/discover/Discover';
import Artist from './pages/artist/Artist';
// import WatchLater from "./pages/watch-later/WatchLater";
import Recommendations from './pages/recommendations/Recommendations';
import EditProfile from './pages/edit-profile/EditProfile';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import ReSchedule from './pages/re-schedule';
import NonDisclosureAgreement from './pages/non-disclosure-agreement/NonDisclosureAgreement';
import { PrivateRoute } from './components/Route/PrivateRoute';
import history from './shared/utils/history';
import userService from './services/UserAPI';
import { getURLSearchParam } from './helper/getURLSearchParams';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from './selectors/auth.selector';
import { loadAppState } from './shared/utils/helpers';
import finishProducerSetup from './pages/finish-producer-setup';
import TermsOfSale from './pages/terms-of-sale/TermsOfSale';
import TermsOfService from './pages/terms-of-service/TermsOfService';
import CommunityGuidelines from './pages/community-guidelines/CommunityGuidelines';
import User from './shared/audiophile/models/user';
import GetUser from '../src/utilities/getUserLocalStorage';
import SignUp from './pages/sign-up/SignUp';
import { setUpdatePlayerLocationRequest } from './slices/user';
import store from './store';
import { pick } from 'lodash';
import { tryGetValidToken } from 'utilities/tryGetValidToken';
import jwt_decode from 'jwt-decode';
import JoinTheCrowd from './pages/crowd-chat/JoinTheCrowd';

const App = () => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const url = window.location.href;
  const [pageId, setPageId] = useState('');
  const streamId = urlParams.get('id');
  const authState = (pick(loadAppState(), ['auth']) || {}).auth;

  if (window.location.href.includes('profile?code')) {
    localStorage.setItem('urlLink', window.location.href);
    window.open('about:blank', '_self');
    window.close();
  }
  // const { isAuthenticated } = useSelector(getAuth);
  const [getUrl, setGetUrl] = useState('');
  useEffect(() => {
    window.location.href.includes('localhost')
      ? setGetUrl('http://localhost:3000/')
      : setGetUrl('https://frisson-live-development.azureedge.net/');
  }, [window.location.href]);


  useEffect(() => {
    GetUser();
  }, []);

  // useEffect(() => {
  //   let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
  //   if (appState?.auth.isAuthenticated) {
  //     return new User(jwt_decode(appState.auth.currentUser));
  //   } else {
  //     return null;
  //   }
  // }, []);
  // useEffect(() => {
  //   history.listen((location, action) => {
  //     if (
  //       loadAppState()?.auth?.isAuthenticated &&
  //       location.pathname !== '/jointhecrowd'
  //     ) {
  //       userService.updateUserShowConnection({
  //         showId: '0',
  //         disconnect: true
  //       });
  //     }
  //   });
  // }, []);
  // const updateUserShowConnection = () => {
  //   if (!isAuthenticated) return;
  //   const location = window.document.location;
  //   let showId = '-1';
  //   if (location.pathname === '/jointhecrowd') {
  //     showId = getURLSearchParam(location.search, 'id');
  //   }
  //   userService.updateUserShowConnection({
  //     showId,
  //     disconnect: true
  //   });
  // };
  // useEffect(() => {
  //   window.addEventListener('beforeunload', updateUserShowConnection);
  //   new WOW.WOW({
  //     live: false
  //   }).init();
  //   return () =>
  //     window.removeEventListener('beforeunload', updateUserShowConnection);
  // });

  useEffect(() => {
    function handleLocationChange(pathname) {
      // console.log('pathname0--->', pathname);
      if (pathname === '/') {
        setPageId(-1);
      } else if (pathname === '/dressing-room') {
        setPageId(-2);
      } else if (pathname === '/store') {
        setPageId(-3);
      } else if (pathname === '/atm') {
        setPageId(-4);
      } else if (
        pathname === '/profile' ||
        pathname === '/discover' ||
        pathname === '/privacy-policy' ||
        pathname === '/terms-and-conditions' ||
        pathname === '/update-schedule' ||
        pathname === '/artist' ||
        pathname === '/recommendations' ||
        pathname === '/terms-of-sale' ||
        pathname === '/terms-of-service' ||
        pathname === '/community-guidelines' ||
        pathname === '/nda' ||
        pathname === '/dl'
      ) {
        setPageId(-5);
      }
    }

    handleLocationChange(window.location.pathname);
    return history.listen((location) =>
      handleLocationChange(location.pathname)
    );
  });

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     if (pageId !== '' && window.location.pathname !== '/jointhecrowd') {
  //       // console.log('test /watch');
  //       dispatch(setUpdatePlayerLocationRequest({ pageId, url }));
  //       const intervalId = setInterval(() => {
  //         dispatch(setUpdatePlayerLocationRequest({ pageId, url }));
  //       }, 5 * 60 * 1000);
  //       return () => clearInterval(intervalId);
  //     }
  //   }
  // }, [isAuthenticated, pageId, dispatch]);

  return (
    <Router history={history}>
      <Toaster />
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/'}`}
          component={Home}
        />
        <PrivateRoute
          exact
          path={`${process.env.PUBLIC_URL + '/profile'}`}
          component={EditProfile}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/update-schedule'}/:scheduleId`}
          component={ReSchedule}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/artist'}`}
          component={Artist}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/discover'}`}
          component={Discover}
        />
        {/*<Route*/}
        {/*  exact*/}
        {/*  path={`${process.env.PUBLIC_URL + "/watch-later"}`}*/}
        {/*  component={WatchLater}*/}
        {/*/>*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/sign-up'}`}
          component={SignUp}
        />
        {/* <Route
          exact
          path={`${process.env.PUBLIC_URL + '/watch'}`}
          component={CrowdChat}
        /> */}
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/jointhecrowd'}`}
          component={JoinTheCrowd}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/finish-producer-setup'}`}
          component={finishProducerSetup}
        />
        {/*<Route path='/twitch' component={() => {*/}
        {/*  debugger*/}
        {/*  window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=ao6rtebu0y8d898lzxxvjqaskvnpil&grant_type=authorization_code&redirect_uri=${getUrl}profile&response_type=code&scope=viewing_activity_read`;*/}
        {/*  return null;*/}
        {/*}} />*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/forgot-password'}`}
          component={ForgotPassword}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/dl'}`}
          component={DownloadPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/recommendations'}`}
          component={Recommendations}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/terms-and-conditions'}`}
          component={TermsAndConditions}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/terms-of-sale'}`}
          component={TermsOfSale}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/community-guidelines'}`}
          component={CommunityGuidelines}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/terms-of-service'}`}
          component={TermsOfService}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/privacy-policy'}`}
          component={PrivacyPolicy}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/nda'}`}
          component={NonDisclosureAgreement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/dressing-room'}`}
          component={DressingRoom}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/store'}`}
          component={Store}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/atm'}`}
          component={ATM}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/payment_successful'}`}
          component={PaymentSuccessful}
        />
        <Route exact component={Error} />
      </Switch>
    </Router>
  );
};
export default App;