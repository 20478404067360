import HZ from './hZ.svg'
import DB from './dB.svg'
import DressingRoom from './dressing_room.svg'
import Store from './store.svg'
import ATM from './atm.svg'


const icons = {
  HZ,
  DB,
  DressingRoom,
  Store,
  ATM
};
export default icons;
