import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userAPI from '../../services/UserAPI';
import { Spinner } from '../spinner';
import { isLoading } from '../../selectors/user.selector';
import defaultuser from '../../assets/img/edit-profile/defaultuser.png';
import { getProfileRequest } from '../../slices/user';
import './styles.scss';

const AvatarUpload = ({ getUser, buttonColor }) => {
  const dispatch = useDispatch();
  const isloading = useSelector(isLoading);
  const [loading, setLoading] = useState(false);
  const uploadPicture = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('avatar', e.target.files[0]);
    const res = await userAPI.uploadAvatar(formData);
    if (res) setLoading(false);
    dispatch(getProfileRequest());
  };
  return (
    <div aria-label="Change Profile Picture" className="block-avatar-upload">
      {/*<div*/}
      {/*  className='avatar-img'*/}
      {/*  style={{*/}
      {/*    backgroundImage: `url(${getUser?.avatarUrl || defaultuser})`*/}
      {/*  }}*/}
      {/*>*/}
      {/*</div>*/}
      <div className="avatar-img">
        <div className="avatar-img-photo">
          <img
            className="avatar-img-personPhoto"
            src={getUser?.avatarUrl || defaultuser}
          />
        </div>
      </div>
      {loading || isloading ? (
        <Spinner />
      ) : (
        <>
          <div
            className="avatar-button-upload"
            style={{ backgroundColor: buttonColor ? '#FF6E09' : '' }}
          >
            <input
              className="input-ava"
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={uploadPicture}
            />
            Change avatar
          </div>
        </>
      )}
    </div>
  );
};
export default AvatarUpload;
