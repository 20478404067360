import React, { useEffect } from "react"
import { ccEventData, emojiTypes } from "./vibes_datas";
import VibesUI_Header from "./VibesUI_Header"
import VibesUI_Body from "./VibesUI_Body"
import VibesUI_Order from "./VibesUI_Order"
import "./styles.scss";
import GetUser from "utilities/getUserLocalStorage";
import { userConnection, streamId } from "pages/crowd-chat/chatManager"
import { CheckTutorialStep } from "shared/audiophile/onboarding";
import { SendGTMEvent } from "shared/utils/gtmEvents"
import { displayMessage } from "pages/crowd-chat/trackedMessageController";
import { skipAhead } from "pages/crowd-chat/vidManager";

export default function VibesMenu(props) {
    const [selectedCCEvent, setCcEvent] = React.useState("")
    const [eventData, setEventData] = React.useState(Object.assign({}, ccEventData))
    const [dbInventory, setInventoryDB] = React.useState(0)
    const [vbCatalog, setVBCatalog] = React.useState()
    const [catalogObject, setCatalogObject] = React.useState()
    const [visibleClass, setVisibleClass] = React.useState('hidden')
    const user = GetUser()
    const displayName = user?.displayName.displayName ?? 'Your Username'
    const urlParams = new URLSearchParams(window.location.search);
    const streamId = urlParams.get('id');
    const isTutorial = streamId == 'tutorialShow' ? true : false
    
    const titleId = '10B75';
    let frissonSplit = null

    function selectVibeEvent(eventName) {
        // TODO: Reset ccEventData if we want them to have a fresh start from the last events settings they set
        setCcEvent(eventName)

        UpdateCatalogObject()
    }

    React.useEffect(() => {
        //get db
        if (user) {
 
            //get user's db total
            const fetchDBData = async () => {
                const data = await fetch(
                    'https://'+ titleId + '.playfabapi.com/Client/GetUserInventory',
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'text/plain',
                            'Content-Type': 'application/json',
                            'X-Authorization': user.playFabSessionToken
                        }
                    }
                )
                .then(function (response) {
                    if (response.status >= 400 && response.status < 600) {
                        // failure in getting totals
                    } else if (response.status === 200) {
                        return response.json()
                    } else {
                        // failure in getting totals
                        return null
                    }
                })
                .then((data) => {
                    if (data) {
                        // display the new total somewhere
                        setInventoryDB(data.data.VirtualCurrency.DB)
                    }
                })
            }

            
            fetchDBData()
            .catch(console.error);

            //get vibes catalog
            const fetchVibesCatalog = async () => {
                const data = await fetch(
                    'https://'+ titleId + '.playfabapi.com/Client/GetCatalogItems',
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'text/plain',
                            'Content-Type': 'application/json',
                            'X-Authorization': user.playFabSessionToken
                        },
                        body: JSON.stringify({
                            "CatalogVersion": "Vibes Items 2.0"
                        })
                    }
                )
                .then(function (response) {
                    if (response.status >= 400 && response.status < 600) {
                        // failure in getting totals
                    } else if (response.status === 200) {
                        return response.json()
                    } else {
                        // failure in getting totals
                        return null
                    }
                })
                .then((data) => {
                    if (data) {
                        // display the new total somewhere
                        setVBCatalog(data.data.Catalog)
                    }
                })
            }

            
            fetchVibesCatalog()
            .catch(console.error);

            
            
            // console.log('user = ' + user.playFabSessionToken)
        }
    }, [selectedCCEvent])

    //when we've fetched a catalog, rebuild the catalog object 
    useEffect(() => {
        if (vbCatalog) UpdateCatalogObject()
    }, [vbCatalog])
        
    async function SendVibes() {

        if (isTutorial) {
            HideMenu()

            CheckTutorialStep("SentVibes")

            //display the message to the user but don't actually send it
            if (selectedCCEvent == 'event_crowdemoji') {
                let newMessage = `&vibe& |||Sent ${eventData[selectedCCEvent].emoji} vibes!`
                displayMessage(user.playFabId, newMessage)
                
                //skip ahead to vibe in video only on tutorialShow
                if (new URLSearchParams(window.location.search).get('id').toLowerCase() == 'tutorialshow' ) {
                    skipAhead(2037)
                }
                
            }
            else if (selectedCCEvent == 'event_firework') {
                let newMessage = `&vibe& |||Sent 🎆 vibes!`
                displayMessage(user.playFabId, newMessage)
                
                //skip ahead to vibe in video only on tutorialShow
                if (new URLSearchParams(window.location.search).get('id').toLowerCase() == 'tutorialshow' ) {
                    skipAhead(2045)
                }
                
            }
            
        }
        else {
            console.log('send')

            eventData[selectedCCEvent]['streamId'] = streamId
            eventData[selectedCCEvent]['roomId'] = userConnection.room

            //set emoji timing to section
            if (selectedCCEvent == 'event_crowdemoji') eventData[selectedCCEvent]['timing'] = userConnection.section
            eventData[selectedCCEvent]['stagingToken'] = "a5687043-2e7b-4bde-aa60-8c228f0f6586"

            //convert firework timing
            //cache current timing
            const curTiming = eventData[selectedCCEvent]['timing']
            const timingOptions = [0,0.125,0.25,0.5,1,2,4,8]

            if (selectedCCEvent == 'event_firework') eventData[selectedCCEvent]['timing'] = timingOptions[eventData[selectedCCEvent]['timing']].toString()
            
            //build proper string for event id
            const size = selectedCCEvent === "event_firework" ? 'medium' : eventData[selectedCCEvent].typeText
            const id = "vibe-" + selectedCCEvent.split("event_")[1] + "-" + size + "-" + eventData[selectedCCEvent].quantity

            

            const sendVibes = async () => {
                const vibe = await fetch(
                'https://'+ titleId + '.playfabapi.com/Client/PurchaseItem',
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'text/plain',
                            'Content-Type': 'application/json',
                            'X-Authorization': user.playFabSessionToken
                        },
                        body: JSON.stringify({
                            "ItemId": id,
                            "Price": catalogObject.VirtualCurrencyPrices["DB"].toString(),
                            "VirtualCurrency": "DB",
                            "CatalogVersion": "Vibes Items 2.0",
                            "CustomTags": eventData[selectedCCEvent]
                        })
                    }
                )
                .then(function (response) {
                    if (response.status >= 400 && response.status < 600) {
                        // failure in getting totals
                    } else if (response.status === 200) {
                        return response.json()
                    } else {
                        // failure in getting totals
                        return null
                    }
                })
                .then(async (data) => {
                    let purchases = data.data.Items;
                    
                    if (!frissonSplit) frissonSplit = await GetSplit()

                    for (let i = 0; i < purchases.length; i++) {
                        const purchase = purchases[i];
                        
                        //only report purchases of the bundle with 'vibe-' prefix
                        if (purchase.ItemId.includes('vibe-')) {
                            SendGTMEvent({   
                                'event': 'purchase_vibes',
                                'transaction_id': purchase.ItemInstanceId,
                                'affiliation': streamId,
                                'currency': 'USD',
                                'value': ((purchase.UnitPrice * 0.01) * frissonSplit).toFixed(2), // GA4 requires the value in cents, which I think this is
                                'items': [{
                                    'item_name': purchase.DisplayName,
                                    'item_id': purchase.ItemId,
                                    'price': (purchase.UnitPrice * 0.01).toFixed(2),
                                    'item_brand': 'frisson',
                                    'item_category': 'Vibes',
                                    'quantity': 1
                                }]
                            })
                        }
                    }
                    

                    //reset selected event
                    selectVibeEvent('')

                    //reset timing val
                    eventData[selectedCCEvent]['timing'] = curTiming

                    // setVisibleClass('hidden')
                    //clean up all visibility
                    HideMenu()

                    CheckTutorialStep("SentVibes")
                })
            }
        
            sendVibes()
            .catch(console.error);
        }
        
    }

    function HideMenu () {
        document.getElementById('send-icons').classList.remove("visible")
        document.getElementById('messageInput').classList.remove("visible")
        document.getElementById('message-init').classList.remove("active","vibeActive")
        document.getElementById('send-icons').classList.remove("visible")
        document.getElementById('vibeContainer').classList.add("hidden")
        document.getElementById('vibesToggle').classList.remove("selected")
        document.getElementById('vibesLow').classList.remove('selected')
        document.getElementById('vibesMenu').classList.add('hidden')
    }

    function UpdateData(newData) {
        
        setEventData(newData)

        UpdateCatalogObject()
        
        console.log('updating data: ', eventData)
    }

    function UpdateCatalogObject () {
        //build proper string for event id
        const size = selectedCCEvent === "event_firework" ? 'medium' : eventData[selectedCCEvent]?.typeText
        const id = "vibe-" + selectedCCEvent.split("event_")[1] + "-" + size + "-" + eventData[selectedCCEvent]?.quantity
        setCatalogObject(vbCatalog.find(item => item.ItemId === id))
    }

    async function GetSplit() {
        return await fetch(
            'https://'+ titleId + '.playfabapi.com/Client/GetTitleData',
            {
                method: 'POST',
                headers: {
                    Accept: 'text/plain',
                    'Content-Type': 'application/json',
                    'X-Authorization': user.playFabSessionToken
                },
                body: '{"Keys": ["frissonSplit"]}'
            }
        )
        .then(function (response) {
            if (response.status >= 400 && response.status < 600) {
                // failure in getting totals
            } else if (response.status === 200) {
                return response.json()
            } else {
                // failure in getting totals
                return null
            }
        })
        .then((data) => {
            if (data) {
                // send vibes gtm event
                return data.data.Data.frissonSplit
            }
        })
        .catch(function (err) {
            //no existing session with this id
            console.error(err.toString())
            return null
        })
        
        
    }

    return (
        <div id="vibesMenu" className={`${visibleClass} vibesMenu`}>
            <VibesUI_Header setCcEvent={setCcEvent}/>
            {!selectedCCEvent && <div className="vibesUI--event_selection_container">
                <button onClick={() => {selectVibeEvent("event_firework")}}>🎆 Fireworks</button>
                <button onClick={() => {selectVibeEvent("event_crowdemoji")}}>🙌 Crowd Emoji</button>
                {/* <button onClick={() => {selectVibeEvent("event_dance_vibes")}}>💃 Dance Vibes</button> */}
            </div>}
            {selectedCCEvent && <VibesUI_Body displayName={displayName}
                selectedEvent={selectedCCEvent}
                setCcEvent={setCcEvent} ccData={eventData} setCCData={UpdateData}
            />}
            {selectedCCEvent && <VibesUI_Order sendVibes={SendVibes} inventory={dbInventory} selectedEvent={selectedCCEvent} catalogObject={catalogObject} isTutorial={isTutorial}
            />}
        </div>
    )
}