import React from 'react';
import './styles.scss';

const Statistics = ({ icon, number }) => {
  return (
    <div className={'statistics-container'}>
      <img src={icon} alt={'image'}/>
      <p className={'description'}>{number}</p>
    </div>
  );
};

export default Statistics;
