import React from "react";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

import "./styles.scss";
const animatedComponents = makeAnimated();

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
);

const SelectCustom = ({
  placeholder,
  data,
  isMulti,
  customIndicator,
  onChange,
  formik,
  name,
  isDisabled
}) => {
  const indicator = customIndicator && {
    DropdownIndicator: (props) => (
      <components.DropdownIndicator
        {...props}
        className="select-custom-indicator"
      >
        {customIndicator}
      </components.DropdownIndicator>
    )
  };
  return (
    <div className={`wrapper-control ${isDisabled ? "control-disable" : ""}`}>
      <Select
        className="basic-single"
        classNamePrefix="select"
        closeMenuOnSelect={true}
        placeholder={placeholder}
        isDisabled={isDisabled}
        styles={{
          control: (provided, state) => ({
            ...provided,
            background: "#fff",
            border: "none",
            outline: "0",
            outlineOffset: "0",
            borderColor:
              formik.touched[name] && formik.errors[name]
                ? "#dc3545"
                : "#9e9e9e",
            padding: "0 10px",
            fontFamily: "'Futura', Arial, Helvetica, sans-serif",
          }),

          valueContainer: (provided, state) => ({
            ...provided,
            padding: "0",

          }),

          indicatorSeparator: (state) => ({
            display: "none"
          }),
          indicatorsContainer: (provided, state) => ({
            ...provided
          }),

          option: (base) => ({
            ...base,
            color: "#696c79",
            fontFamily: "'Futura', Arial, Helvetica, sans-serif",

          }),
          multiValue: (styles, { data }) => {
            return {
              ...styles,
              backgroundColor: "#0069ff"
            };
          },
          multiValueLabel: (base) => ({
            ...base,
            backgroundColor: "#0069ff",
            color: "white"
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "white",
            ":hover": {
              backgroundColor: "#00aff0",
              color: "white",
              opacity: 0.6
            }
          })
        }}
        components={{
          SingleValue,
          animatedComponents,
          Menu: (props) => <components.Menu {...props} className="menu" />,
          ...indicator
        }}
        value={formik.values[name]}
        options={data}
        onChange={onChange}
        isMulti={isMulti}
      />
      {formik.touched[name] && formik.errors[name] ? (
        <div className="error">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default SelectCustom;
