import * as THREE from 'three'
import gsap from 'gsap'
import { CheckTutorialStep } from 'shared/audiophile/onboarding'

export const mouse = new THREE.Vector2()

let mouseDown = false
let camera = null
let dragging = false
let currentHover = null
let currentSelected = null
let prevHover = null
let prevSelected = null
let clickXStartPos = 0
let clickYStartPos = 0
const maxSlide = 0.125
const minSlide = -0.052
const maxRotate = 3.7
const minRotate = -0.27

const selectDefaultColor = '#aaa'
const slideRotDefaultColor = '#aaa'
const selectHovorColor = '#fff'
const slideRotHovorColor = '#aaa'
const selectSelectColor = '#aaa'
const slideRotSelectColor = '#aaa'

const MouseStates = {
    None : 'none',
    Hovering : 'hovering',
    Dragging : 'dragging'
}

let mouseState = MouseStates.None

export let interactables = []
export let draggableShelves = []
const raycaster = new THREE.Raycaster()
let pointersEnabled = false

let canTick = true

let shelfSlideDist = 0

export function toggleCanTick (state) {
    canTick = state
}

export function togglePointer (state) {
    pointersEnabled = state
}

export function prepControlObjs (obj, selectCallback) {
    //get obj and check for how it's dragged, 
    //set tag and min + max

    obj.charProp = obj.name.split('|')[0]
    const type = obj.name.split('|')[1]

    // console.log(obj.charProp)

    if (type?.includes('slide')) {
        obj.tag = 'slide'
        obj.startingPos = new THREE.Vector3(maxSlide, obj.position.y, obj.position.z)
        obj.material = obj.material.clone()
        obj.material.color.set(slideRotDefaultColor)
        interactables.push(obj)
    }
    else if (type?.includes('rot')) {
        obj.tag = 'rotate'
        obj.material = obj.material.clone()
        obj.material.color.set(slideRotDefaultColor)
        interactables.push(obj)
    }
    else if (type?.includes('select')) {
        obj.tag = 'select'
        obj.callback = [() => selectCallback(obj.charProp)]
        obj.material = obj.material.clone()
        obj.material.color.set(selectDefaultColor)
        
        //assign highlight colors
        switch (obj.charProp) {
            case "head_topper":
                obj.highlightColor = '#0068ff'
                obj.pressColor = '#1057bd'
                break
            case "head_acc":
                obj.highlightColor = '#00fed5'
                obj.pressColor = '#0b7f6c'
                break
            case "eyes":
                obj.highlightColor = '#0cf640'
                obj.pressColor = '#079c2e'
                break
            case "face_acc":
                obj.highlightColor = '#c5ff00'
                obj.pressColor = '#688504'
                break
            case "clothes":
                obj.highlightColor = '#ffea0b'
                obj.pressColor = '#a2920c'
                break
            case "body_acc":
                obj.highlightColor = '#ff7100'
                obj.pressColor = '#944506'
                break
            case "hands_acc":
                obj.highlightColor = '#ff0039'
                obj.pressColor = '#9c0d2d'
                break
            case "feet":
                obj.highlightColor = '#ff00ae'
                obj.pressColor = '#9c0d7c'
                break
            case "animations":
                obj.highlightColor = '#9000ff'
                obj.pressColor = '#610d9c'
                break
            case "customize":
                obj.highlightColor = '#fff'
                obj.pressColor = '#333'
                break
            case "customizeReturn":
                obj.highlightColor = '#fff'
                obj.pressColor = '#333'
                break
            default:
                obj.highlightColor = '#fff'
                obj.pressColor = '#333'
                break;
        }

        interactables.push(obj)
    }

    
}

var deltMouseX = 0,
    deltMouseY = 0

export function dragControls(canvas,theCamera,callback) {  
    camera = theCamera

    
        
    console.log('mouseevents',canvas)
    
    canvas.addEventListener('mousemove', function (evt) {
        pointerDrag(evt, false, callback)
    }, false);
        
    canvas.addEventListener('mousedown', function (evt) {
        pointerDown(evt, false)
    }, false);
    
    canvas.addEventListener('mouseup', function (evt) {
        pointerUp(evt)
    }, false);

    canvas.addEventListener('mouseout', function (evt) {
        pointerUp(evt)
    }, false);

    canvas.addEventListener('touchmove', function (evt) {
        pointerDrag(evt, true, callback)
    }, false);
        
    canvas.addEventListener('touchstart', function (evt) {
        pointerDown(evt, true)
    }, false);
    
    canvas.addEventListener('touchend', function (evt) {
        pointerUp(evt)
    }, false);
}

function pointerDown (evt, isTouch) {
    //back out if pointers is disabled
    if (!pointersEnabled) return

    evt.preventDefault();
    mouseDown = true;
    let clientX = evt.clientX
    let clientY = evt.clientY

    if (isTouch) {
        clientX = evt.touches[0].clientX;
        clientY = evt.touches[0].clientY;
    }

    deltMouseX = clientX;
    deltMouseY = clientY; 
    
    mouse.x = ( clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( clientY / window.innerHeight ) * 2 + 1;

    //run the raycaster immediately if touch since we didn't have a frame to check for hover
    if (isTouch) tick()

    clickXStartPos = mouse.x
    clickYStartPos = mouse.y

    // if (currentHover) {
    //     currentSelected = currentHover
    //     currentSelected.object.material.color.set('#0000ff')
    // }

    //initiate a selection
    mouseState = MouseStates.Dragging

    currentSelected = currentHover
    prevHover = currentHover
    currentHover = null

    if (currentSelected?.object.tag == "select") {
        var curY = currentSelected.object.position.y

        gsap.to(currentSelected.object.position, {duration: 0.03, y: curY - 0.007})
    }

    SetMaterial()
}

function pointerDrag (evt, isTouch, callback) {
    //back out if pointers is disabled
    if (!pointersEnabled) return

    let clientX = evt.clientX
    let clientY = evt.clientY

    if (isTouch) {
        clientX = evt.touches[0].clientX;
        clientY = evt.touches[0].clientY;
    }

    var deltaX = clientX - deltMouseX,
        deltaY = clientY - deltMouseY;
            
        deltMouseX = clientX;
        deltMouseY = clientY; 
        
        
        mouse.x = ( clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( clientY / window.innerHeight ) * 2 + 1;
    
    if (!mouseDown) {return}
    
    // console.log('dragging ' + currentSelected?.object.name)
    evt.preventDefault();
    dragging = true
    dragAction(deltaX, deltaY, callback);
}

function pointerUp (evt) {
    //back out if pointers is disabled
    if (!pointersEnabled) return

    evt.preventDefault();
    mouseDown = false;

    let dragDiffX = Math.abs(clickXStartPos - mouse.x)
    let dragDiffY = Math.abs(clickYStartPos - mouse.y)
    
    if (currentSelected) {

        //fix any slider positions - the update loop seems out of sync with the mouse drag actions
        if (currentSelected.object.tag == "slide") {
            sliderBounds()
        }
        if (currentSelected.object.tag == "spinner") {
            currentSelected.object.callbacks[1]()
        }
        //only run callback if we straight up selected it and not dragged with it
        else if (currentSelected.object.tag == "select") {
            if (dragDiffX < 0.01 && dragDiffY < 0.01  && currentSelected.object != prevSelected?.object) {
                if (currentSelected.object.callback) {
                    currentSelected.object.callback.forEach(cb => {
                        cb()
                    });
                }

                var curY = currentSelected.object.position.y
                gsap.to(currentSelected.object.position, {duration: 0.03, y: curY + 0.007})
            }
            else {
                var curY = currentSelected.object.position.y
                gsap.to(currentSelected.object.position, {duration: 0.03, y: curY + 0.007})

                currentSelected = null
                SetMaterial()
            }

            

        }

        if (currentSelected?.object != prevSelected?.object) {
            prevSelected = currentSelected
            currentSelected = null
    
            SetMaterial()
        }
    }

    //check if we dragged the item or just clicked on it
    if (dragging) {
        dragging = false
        
        console.log(Math.abs(shelfSlideDist))
        
        if (Math.abs(shelfSlideDist) > 0.1) {
            CheckTutorialStep("DragShelf")
        }
        shelfSlideDist = 0
        
    }
}

export function removeShelves (controls, callbacks) {
    interactables = []
    controls.forEach(control => {
        control.children.forEach(el => {
            if (el.name.includes('select')) {
                switch (el.name.split('|')[0]) {
                    case "headMorphPlus":
                        prepControlObjs(el, callbacks[0])
                        break;
                    case "headMorphMinus":
                        prepControlObjs(el, callbacks[1])
                        break;
                    case "bodyMorphPlus":
                        prepControlObjs(el, callbacks[2])
                        break;
                    case "bodyMorphMinus":
                        prepControlObjs(el, callbacks[3])
                        break;
                    case "save":
                        prepControlObjs(el, callbacks[4])
                        break;
                    case "customize":
                        prepControlObjs(el, callbacks[5])
                        break;
                    case "customizeReturn":
                        prepControlObjs(el, callbacks[6])
                        break;
                    default:
                        prepControlObjs(el, callbacks[7])
                        break;
                }
                
            }
            else{
                prepControlObjs(el)
            }
        })
    })   
}



function dragAction(deltaX, deltaY, callback) {

    //figure out if rotate or slide
    //if rotate, standard min max rotation val, but need min max of value it's controlling and need to remap
    //if slide, need to know direction and starting location to determing min max distance


    

    if (currentSelected) {
        let totalDelta

        if (currentSelected.object.tag == "rotate") {
            totalDelta = (deltaX - deltaY) * 0.01

            if ((currentSelected.object.rotation.y - totalDelta) >= minRotate && (currentSelected.object.rotation.y - totalDelta) <= maxRotate) {
                currentSelected.object.rotation.y -= totalDelta
                // console.log(currentSelected.object.rotation.y)
                currentSelected.object.val = Math.abs(getRatio(currentSelected.object.rotation.y, minRotate, maxRotate) - 1)

                if (callback) callback(currentSelected)
            }
        } else if (currentSelected.object.tag == "slide") {
            totalDelta = deltaY * 0.0008
            // console.log(currentSelected.object.position.x - totalDelta - 0.1)
            if (currentSelected.object.position.x + totalDelta <= maxSlide && currentSelected.object.position.x - totalDelta >= minSlide) {
                currentSelected.object.translateZ(totalDelta)

                currentSelected.object.val = getRatio(currentSelected.object.position.distanceTo(currentSelected.object.startingPos), minSlide, maxSlide)
                // console.log(currentSelected.object.position.x)
                if (callback) callback(currentSelected)
            }
            
        } else if (currentSelected.object.tag == "spinner") {
            if (callback) currentSelected.object.callbacks[0](deltaX)
        }
        //for shelf dragging and button select
        else if (currentSelected.object.tag == "select" || typeof currentSelected.object.tag == 'undefined') {
            let topParent = currentSelected.object
            let foundParent = false

            while (topParent.parent && foundParent == false) {
                topParent = topParent.parent

                if (topParent.tag == 'drag') {
                    foundParent = true
                }
            }

            //if the top most parent of the 
            if (topParent.tag == 'drag') {
                totalDelta = (deltaX - deltaY) * 0.006

                draggableShelves.forEach(element => {
                    element.translateX(totalDelta)
                });

                shelfSlideDist += totalDelta
                
            }
            
            
            
        }
    }
}

export function setInitialVal (props) {
    // console.log("props:")
        Object.keys(props).forEach(val => {
            
            //the triple loop is probably not the most efficient, but it's fine
            interactables.forEach(element => {
                if (element.charProp === val) {
                    console.log("Val for " + element.charProp + " is " + props[val])

                    if (element.tag === 'slide') {
                        //honestly I'm not really sure why I need to pass the min and max in flipped when going the other way, but it works!
                        element.position.x = getVal(props[val], maxSlide, minSlide)
                    }
                    else if (element.tag === 'rotate') {
                        //honestly I'm not really sure why I need to pass the min and max in flipped when going the other way, but it works!
                        element.rotation.y = getVal(props[val], maxRotate, minRotate)
                    }
                }
            })
        })
}

function SetMaterial (state, obj) {
    if (prevHover && typeof prevHover != 'undefined') {
        switch (prevHover?.object.tag) {
            case 'select':
                prevHover.object.material.color.set(selectDefaultColor)
                break;
            case 'rotate':
            case 'slide':
                prevHover.object.material.color.set(slideRotDefaultColor)
                break;
        
            default:
                break;
        }
    }
    if (prevSelected && typeof prevSelected != 'undefined') {
        switch (prevSelected?.object.tag) {
            case 'select':
                prevSelected.object.material.color.set(prevSelected.object.highlightColor)
                break;
            case 'rotate':
            case 'slide':
                prevSelected.object.material.color.set(slideRotDefaultColor)
                break;
        
            default:
                break;
        }
    }
    if (currentHover && typeof currentHover != 'undefined') {
        switch (currentHover?.object.tag) {
            case 'select':
                currentHover.object.material.color.set(currentHover.object.highlightColor)
                break;
            case 'rotate':
            case 'slide':
                currentHover.object.material.color.set(slideRotHovorColor)
                break;
        
            default:
                break;
        }
    }
    if (currentSelected && typeof currentSelected != 'undefined') {
        switch (currentSelected?.object.tag) {
            case 'select':
                currentSelected.object.material.color.set(currentSelected.object.pressColor)
                break;
            case 'rotate':
            case 'slide':
                currentSelected.object.material.color.set(slideRotSelectColor)
                break;
        
            default:
                break;
        }
    }
    
    // prevHover?.object.material.color.set('#000000')
    // prevSelected?.object.material.color.set('#000000')
    // currentSelected?.object.material.color.set('#770000')
    // currentHover?.object.material.color.set('#0000ff')

}

export function getRatio (val, min, max) {
    return (val - min) / (max - min)
}

export function getVal (ratio, min, max) {
    return ((max - min) * ratio) + min
}

function sliderBounds () {
    
    if (currentSelected.object.position.x <= minSlide) {
        currentSelected.object.position.x = minSlide + 0.008

        console.log("fixing min bounds")
    }
    else if (currentSelected.object.position.x >= maxSlide) {
        currentSelected.object.position.x = maxSlide - 0.008

        console.log("fixing max bounds")
    }   
}

export const tick = () =>
{

    if (camera && pointersEnabled) {
        raycaster.setFromCamera(mouse, camera)

        // const objectsToTest = [cube]
        const intersects = raycaster.intersectObjects(interactables, true)

        if (intersects.length) {
            switch (mouseState) {
                case MouseStates.None:
                    //reset all vars, mouse not interacting with anything
                    mouseState = MouseStates.Hovering

                    //set new hover
                    currentHover = intersects[0]

                    if (mouseDown) {
                        //initiate a selection
                        mouseState = MouseStates.Dragging

                        currentSelected = currentHover
                    }

                    SetMaterial()

                    break;
                case MouseStates.Hovering:
                    //indicate hovering over one obj

                    //new hover obj
                    if (!currentHover || intersects[0].object.uuid != currentHover.object.uuid) {

                        //this means it's a new obj we're hovering over, set it as prev
                        if (currentHover) {
                            prevHover = currentHover
                        }
                        //set new hover
                        currentHover = intersects[0]
                        // console.log(currentHover.object.name)
                        SetMaterial()
                    }

                    

                    // if (mouseDown) {
                    //     //initiate a selection
                    //     mouseState = MouseStates.Dragging

                    //     currentSelected = currentHover
                    //     prevHover = currentHover
                    //     currentHover = null

                    //     SetMaterial()
                    // }

                    break;
                case MouseStates.Dragging:
                    //indicate dragging one obj
                    
                    if (!mouseDown) {
                        mouseState = MouseStates.Hovering
                    }

                    break;
            
                default:
                    break;
            }
        }
        else {
            //not hovering over anything

            if (mouseState != MouseStates.Dragging) {
                if (currentHover) {
                    prevHover = currentHover
                    currentHover = null

                    SetMaterial()
                }
            }
            else {
                if (!mouseDown) {
                    mouseState = MouseStates.None
                }
            }
        }

        //fix any slider positions - the update loop seems out of sync with the mouse drag actions
        if (currentSelected?.object.tag == "slide") {
            sliderBounds()
        }
    }
    

    if (canTick)
    {
        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }
    
}

// tick()

export function dispose () {
    canTick = false
    // canvas.removeEventListener('mousemove', function (evt) {
    //     pointerDrag(evt, false, callback)
    // }, false);
        
    // canvas.removeEventListener('mousedown', function (evt) {
    //     pointerDown(evt, false)
    // }, false);
    
    // canvas.removeEventListener('mouseup', function (evt) {
    //     pointerUp(evt)
    // }, false);

    // canvas.removeEventListener('touchmove', function (evt) {
    //     pointerDrag(evt, true, callback)
    // }, false);
        
    // canvas.removeEventListener('touchstart', function (evt) {
    //     pointerDown(evt, true)
    // }, false);
    
    // canvas.removeEventListener('touchend', function (evt) {
    //     pointerUp(evt)
    // }, false);
}


export default {dragControls:dragControls, dragAction:dragAction, mouse:mouse, interactables:interactables}