import React from "react";

const UpComing = () => {
  return (
    <div className="content-up-coming">
      <div className="show-live__box">
        {/*<div className="live-indicator-one"> </div>*/}
        {/*<div className="live-indicator-two"> </div>*/}
        {/*<div className="live-indicator-tree"> </div>*/}
        <div className="live-text">Upcoming</div>
      </div>
    </div>
  );
};

export default UpComing;
