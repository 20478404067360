import React from "react";

const LiveNow = () => {
  return (
    <div className="content-live-now">
      <div className="show-live__box">
        <div className="live-indicator"> </div>
        <div className="live-text">Live Now!</div>
      </div>
    </div>
  );
};

export default LiveNow;
