import React, { useState } from 'react';
import { redirectPage } from '../../shared/utils/helpers';
import './styles.scss';
import sprite from '../../assets/svg/sprite.svg';

const ButtonWatchList = ({ size, videoId, addToWatch, openShowAdded, setAnimateClass, animateClass }) => {
  const [visibleClass, setVisibleClass] = useState(false);
  return (
    <div className={`watch-list__custom`}>
      <button className={'play__btn'} onMouseEnter={() => {
        setVisibleClass(true);
      }} onMouseLeave={() => {
        setVisibleClass(false);
      }} onClick={(e) => {
        e.preventDefault();
        addToWatch(true);
        setAnimateClass(!animateClass);
        redirectPage(e, videoId);
      }}>
        <div className={visibleClass ? `icon-gradient ${videoId} well` : `icon-gradient ${videoId}`}>
          <span>
              {!openShowAdded && <svg style={{
                width: '24px',
                height: '24px',
                position: 'absolute',
                zIndex: '5',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
                <use href={sprite + '#icon_plus_wenght'}></use>
              </svg>}
            {!openShowAdded ? <div className={'text-div '}>
            </div> : <div className={'text-div'} style={{ width: '116px' }}> On
              Watchlist</div>}

        </span>
        </div>
      </button>
    </div>
  );
};
export default ButtonWatchList;
