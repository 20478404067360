import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

const UserTabButton = ({ icon, text, link }) => {
  return (
    <Link className={'userTabButton'} to={process.env.PUBLIC_URL + link}>
      <img src={icon} alt={'image'} />
      <p className={'userTabButton-text'}>{text}</p>
    </Link>
  );
};

export default UserTabButton;
