import React from "react";
import { Link } from "react-router-dom";
import GroupButtonOption from "../../container/group-button-option";
import AdvanceFilterData from "../../data/advance-filter";
import "./styles.scss";

const colors = [
  "#4286F4",
  "#1B9EA8",
  "#10D583",
  "#3FE53E",
  "#CEDF17",
  "#FCF168",
  "#FFAF09",
  "#FF1C09",
  "#DB0050"
];

const AdvanceFilter = ({ isOpen }) => {
  return (
    <div
      className="label-1 advance-search-menu"
      style={{ display: isOpen ? "flex" : "none" }}
    >
      <div className="wrapper-advance-filter">
        <div id="advance_search">
          <div className="wrapper-advance-filter__content">
            {/* <input placeholder="Search you’re favorite live show" /> */}
            <GroupButtonOption data={AdvanceFilterData} colors={colors} />
          </div>
        </div>
        <Link
          className="btn-search"
          to={`${process.env.PUBLIC_URL + "/discover"}`}
        >
          <i className="fa fa-search" />
        </Link>
      </div>
    </div>
  );
};

export default AdvanceFilter;
