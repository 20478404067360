import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getFriendsListRequest } from '../../slices/friendsList';
import { getFriendsTotalCount } from '../../selectors/friends.selector';
import AdvanceFilter from '../../container/advance-filter';
import { getAuth } from '../../selectors/auth.selector';
// import { logout } from '../../slices/auth';
import { getProfileUser } from '../../selectors/user.selector';
import defaultuser from '../../assets/img/edit-profile/defaultuser.png';
import PopUpFriends from '../../container/popup-friends';
import sprite from '../../assets/svg/sprite.svg';
import './styles.scss';
import IconMenu from '../../container/icon-menu';
import IconMenuTop from '../../container/icon-menu-top';
import IconMenuTopModerno from 'container/icon-menu-top-moderno';
import MenuButton from 'components/menu-button';
import history from '../../shared/utils/history';
import { loadAppState } from '../../shared/utils/helpers';
import userService from '../../services/UserAPI';
import LoginForgotPasswordRegister from '../../container/login-register';
import * as PropTypes from 'prop-types';
import PopupFeedback from '../../container/popup-feedback';

const DynamicMenuModernized = ({ position, direction }) => {
  const { isAuthenticated } = useSelector(getAuth);
  const profileUser = useSelector(getProfileUser);
  const totalFriends = useSelector(getFriendsTotalCount);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openMenuUser, setOpenMenuUser] = useState(false);
  const [addClass, setAddclass] = useState(false);
  const [addClassShadow, setAddClassShadow] = useState(false);
  const [display, setDislay] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [sizeWindows, setSizeWindows] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuTop, setOpenMenuTop] = useState(false);
  const [openSlideUp, setOpenSlideUp] = useState(false);
  const [addClassShadowClick, setAddClassShadowClick] = useState(false);
  const [modalLogin ,setModalLogin] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);

  const buttonPosition = () => {
    if (position === 3) {
      return `bottom: 0, right: 0`
    }
  };




  const styleButton = !addClass
    ? 'avatar-right login-join-moderno'
    : 'avatar-right login-join-moderno moderno';

  const styleButtonShadow = !addClassShadow ? '' : 'shadowBox';

  const styleButtonShadowClick = !addClassShadowClick
    ? ''
    : 'moderno shadowBox';

  const getUser = useMemo(() => {
    return profileUser || JSON.parse(localStorage.getItem('profile_user'));
  }, [profileUser]);
  useEffect(() => {
    if (!totalFriends && isAuthenticated) {
      dispatch(getFriendsListRequest());
    }
  }, [dispatch, totalFriends, isAuthenticated]);
  const handleNavigation = useCallback((e) => {
    const footer = document?.querySelector('#footer');
    const target = e?.currentTarget;
    if (
      target?.innerHeight + target?.scrollY >=
      document?.body?.offsetHeight - footer?.scrollHeight
    ) {
      // setDislay(true);
    } else if (target.scrollY > -1) {
      setDislay(false);
      setOpenSearch(false);
      setOpenMenu(false);
      setOpenMenuTop(false);
    } else {
      // setDislay(true);
    }
  }, []);
  window.addEventListener(`resize`, (event) => {
    if (event.target.innerWidth <= 424) {
      setSizeWindows(true);
    } else {
      setSizeWindows(false);
    }
  });
  useEffect(() => {
    if (window.innerWidth <= 424) {
      setSizeWindows(true);
    } else {
      setSizeWindows(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener('scroll', handleNavigation);
    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);
  const login_section = () => {
    const login_section = document.getElementById('login_section');
    login_section
      ? login_section.scrollIntoView({
          behavior: 'smooth'
        })
      : document.body.scrollIntoView({
          behavior: 'smooth'
        });
  };
  const scrollLogin = () => {
    if (history.location.pathname === '/') {
      login_section();
    } else {
      HomeLogin();
      login_section();
    }
  };
  const HomeLogin = () => {
    history.push('/');
  };
  const navigateProfile = () => {
    history.push('/profile');
  };
  const handleOpenPopUp = (e) => {
    e.stopPropagation();
    setOpenSlideUp(!openSlideUp);
    setOpen((val) => (val = false));
  };

  const handleOpenFeedback = (e) => {
    e.stopPropagation();
    setOpenFeedback(!openFeedback);
  }

  const handleClosePopUp = useCallback(() => {
    setOpenSlideUp(false);
  }, []);
  // const onLogout = () => {
  //   dispatch(resetState());
  //   dispatch(logout());
  //   handleClosePopUp();
  // };
  const handleClosePopupFeedback = useCallback(() => {
    setOpenFeedback(false);
  },[]);

  const closeMenu = (em) => {
    setOpenMenuUser(em);
    setOpen(em);
  };
  const openMenuClick = (value) => {
    setOpen((val) => (val = value));
    setOpenMenuUser((val) => (val = value));
    setAddClassShadow((val) => (val = false));
  };
  const activeShadow = () => {
    setAddClassShadow((val) => (val = true));
    setAddClassShadowClick((val) => (val = true));
    scrollLogin();
  };

  const changeModal = () => {
    setModalLogin(!modalLogin);
  }

  return (
    <Fragment>
      {window.location.pathname.includes('/jointhecrowd') && !isAuthenticated ?
        (
        <div id="loginModal" className={modalLogin && 'open'}>
          <LoginForgotPasswordRegister close={changeModal}/>
        </div>
        ) : ('')
      }
      {isAuthenticated && openSlideUp && (
        <PopUpFriends
          data={totalFriends}
          onClose={handleClosePopUp}
          centered="true"
        />
      )}
      {isAuthenticated && openFeedback && (
        <PopupFeedback
          onClose={handleClosePopupFeedback}
          email={profileUser?.email}
        />
      )}
      <div
        className={`wrapper-notify-avatar-modern position-${position}`}
        style={{ display: display ? 'none' : 'flex', 
        right: (position===3 || position===4) && '0', 
        bottom: (position===2 || position===3) && '0', 
        top: !sizeWindows ? (position===1 || position===4) && '40px'  : (position===1 || position===4) && '20px', 
        left: !sizeWindows ? (position===2 || position===1) && '40px' : (position===2 || position===1) && '20px'}}
     
      >
        {isAuthenticated ? (
          <div className="content-notify-avatar-left">
            <AdvanceFilter isOpen={openSearch} />
            <IconMenu isOpen={openMenu} />
          </div>
        ) : (
          ''
        )}
        {window.location.pathname.includes('/jointhecrowd') && !isAuthenticated ?
          (

            <div className="content-notify-avatar-right">
              <div className={'avatar-right login-join-moderno watch-page'}>
                <button className={'btnOpenModal'} onClick={() => changeModal()}> Log In / <br /> Sign Up</button>
              </div>
            </div>

          ) : (
            <div className="content-notify-avatar-right">
              <div>
                <IconMenuTopModerno
                  closeMenu={closeMenu}
                  openT={open}
                  // isOpen={openMenuUser}
                  user={getUser?.avatarUrl}
                  isAuthenticated={isAuthenticated}
                  handleOpenPopUp={handleOpenPopUp}
                  direction={direction}
                  handleOpenFeedback={handleOpenFeedback}
                />
                <MenuButton
                  styleButton={styleButton}
                  setAddclass={setAddclass}
                  setAddClassShadow={setAddClassShadow}
                  activeShadow={activeShadow}
                  isAuthenticated={isAuthenticated}
                  openMenuClick={openMenuClick}
                />
              </div>
            </div>
          )}

      </div>
    </Fragment>
  );
};
export default DynamicMenuModernized;
