export const getProfileUser = (state) => state.user.profileUser;
export const isLoading = (state) => state.user.loading;
export const isSuccess = (state) => state.user.success;
export const isSuccessAuth = (state) => state.auth.success;
export const getUser = (state) => state.user;
export const dataRemoteShedule = (state) => state.user.dataRemotePerApi;
export const profUser = (state) => state?.user?.profileUser;
export const getWatchList = (state) => state?.user?.showWatchlist?.items;
export const getTargetArtist = (state) => state?.user?.showTargetArtist?.items;

