import { AppConfig } from "configs";
import API from "./api/api";

class FriendsListAPI {
  getProfileUser() {
    return API.get(`${AppConfig.apiURL}/users/friends-list`);
  }

  requestFriend(payload) {
    return API.post(`${AppConfig.apiURL}/users/friend-request`, payload);
  }

  acceptFriendRequest(payload) {
    return API.post(
      `${AppConfig.apiURL}/users/response-friend-request`,
      payload
    );
  }

  declineFriendRequest(payload) {
    return API.post(
      `${AppConfig.apiURL}/users/response-friend-request`,
      payload
    );
  }
}

export default new FriendsListAPI();
