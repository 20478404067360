import React from "react";
import "./styles.scss";
const TitleCommon = ({ title, className = "" }) => {
  return (
    <div className={`title-suggest-section fluid ${className} `}>
      <span className="devide-vertical"> </span>
      <p>{title}</p>
    </div>
  );
};

export default TitleCommon;
