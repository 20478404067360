import React, { Fragment, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// redux-action
import { getFriendsListRequest } from "../../slices/friendsList";
import { getFriendsList, isLoading } from "../../selectors/friends.selector";

// layout
import { SpinnerOverlay } from "../../components/spinner-overlay";
import ButtonIcon from "../../components/button-icon";
import FriendsListDetails from "./FriendsListDetails";
import AddNewFriendModal from "../../components/friends-list/AddNewFriendModal";

import "./styles.scss";

const FriendsList = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const friendsList = useSelector(getFriendsList);
  const loading = useSelector(isLoading);

  useEffect(() => {
    dispatch(getFriendsListRequest());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      history.replace({
        search: ""
      });
    };
  }, [history]);

  const handleCloseModal = useCallback(() => setOpenModal(false), []);
  const handleOpenModal = () => setOpenModal(true);

  return (
    <Fragment>
      {/*<AddNewFriendModal*/}
      {/*  openModal={openModal}*/}
      {/*  onCloseModal={handleCloseModal}*/}
      {/*/>*/}

      {/*<SpinnerOverlay loading={loading} />*/}

      {/*<div className="form-schedule-action" style={{ margin: "auto" }}>*/}
      {/*  <ButtonIcon*/}
      {/*    btnText="Add Friend"*/}
      {/*    type="button"*/}
      {/*    btnStyle="rounded"*/}
      {/*    size="sd"*/}
      {/*    color="tarkis"*/}
      {/*    onClick={handleOpenModal}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className="profile-right-body">
        <FriendsListDetails data={friendsList} />
      </div>
    </Fragment>
  );
};

export default FriendsList;
