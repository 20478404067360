import React from 'react';

const Widget = ({children, wrapClasses, title, description, frisson}) => {
    return (
        <div className={`footer-widget ${wrapClasses ? wrapClasses : ''}`}>
            {title && <h2 className="widget-title">{title}</h2>}
            {description && <p className="widget-title" style={{color: 'white', fontSize:'14px'}}>{description} <b style={{color: '#DF0346'}}>{frisson}</b></p>}
            {children}
        </div>
    );
};

export default Widget;
