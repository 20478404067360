export default class User {
    constructor(userProps) {
        this.email = userProps.email
        this.gameChatToken = userProps.gameChatToken
        this.playFabId = userProps.playFabId
        this.playFabSessionToken = userProps.playFabSessionToken
        this.role = userProps.role
        this.entityToken = userProps.entityToken
        this.displayName = userProps.displayName
    }
}