import React, { useEffect, useState } from 'react';
import './styles.scss';
import DatePicker from 'sassy-datepicker';
import sprite from '../../assets/svg/sprite.svg';
import moment from 'moment';

const SelectInputEarningBottomReplacement = ({
                                               element,
                                               isOrigin,
                                               meaning,
                                               getSelectInput,
                                               getPropsNone,
                                               getVision,
                                               setDateObj,
                                               item,
                                               itemSelect, id
                                             }) => {
  const [visibleDate, setVisibleDate] = useState(false);
  const [date, setDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [isValue, setIsValue] = useState('');
  const toggleOptionsBlur = () => {
    setIsOpen(false);
  };
  const handleDateSelect = (newDate) => {
    setDate(newDate);
    setVisibleDate(false);
    setIsOpen(false);
    setIsValue(moment(newDate).format('MM-DD-YYYY'));
    itemSelect(moment(newDate).format('MM-DD-YYYY'));
    setDateObj({
      startDayUtc: moment(newDate).format('MM-DD-YYYY'),
      endDayUtc: moment(newDate).format('MM-DD-YYYY')
    });
  };
  useEffect(() => {
    setIsValue(isOrigin);
  }, []);
  const toggleOptions = () => {
    setVisibleDate(!visibleDate);
    setIsOpen(!isOpen);
    getPropsNone(!isOpen);
  };
  const setDataForSelect = (val) => {
    let d = new Date();
    let t = d.setDate(d.getDate() + val);
    let day = new Date(t);
    let sumD = day.getMonth() + 1;
    return `${String(sumD).length === 1 ? '0' + String(sumD) : String(sumD)}-${String(day.getDate())}-${String(day.getFullYear())}`;
  };
  const getWeekDay = (date) => {
    let days = [0, 1, 2, 3, 4, 5, 6];
    return days[date.getDay()];
  };
  const daysMonth = () => {
    let d = new Date();
    return d.getDate();
  };
  const selected = (val) => {
    switch (val) {
      case 'Today':
        setDateObj({
          startDayUtc: setDataForSelect(0),
          endDayUtc: setDataForSelect(0)
        });
        break;
      case 'Yesterday':
        setDateObj({
          startDayUtc: setDataForSelect(1),
          endDayUtc: setDataForSelect(1)
        });
        break;
      case 'Last 7 days':
        setDateObj({
          startDayUtc: setDataForSelect(-7),
          endDayUtc: setDataForSelect(0)
        });
        break;
      case 'This week':
        setDateObj({
          startDayUtc: setDataForSelect(-getWeekDay(new Date())),
          endDayUtc: setDataForSelect(0)
        });
        break;
      case 'Last week':
        setDateObj({
          startDayUtc: setDataForSelect(-(getWeekDay(new Date()) + 7)),
          endDayUtc: setDataForSelect(-getWeekDay(new Date()))
        });
        break;
      case 'Last 30 days':
        setDateObj({
          startDayUtc: setDataForSelect(-30),
          endDayUtc: setDataForSelect(0)
        });
        break;
      case 'This month':
        setDateObj({
          startDayUtc: setDataForSelect(-daysMonth()),
          endDayUtc: setDataForSelect(0)
        });
        break;
      case 'Last month':
        setDateObj({
          startDayUtc: setDataForSelect(-(daysMonth() + 30)),
          endDayUtc: setDataForSelect(-daysMonth())
        });
        break;
      case 'All time':
        setDateObj({
          startDayUtc: setDataForSelect(-18670),
          endDayUtc: setDataForSelect(0)
        });
        break;
      default:
        setDateObj({
          startDayUtc: moment(date).format('MM-DD-YYYY'),
          endDayUtc: moment(date).format('MM-DD-YYYY')
        });
        break;
    }
    itemSelect(val);
    setIsValue(val);
    getSelectInput(val, meaning);
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className='arrow-earning-bottom-replacement-date'>
        {visibleDate && (
          <DatePicker
            selected={date}
            onChange={handleDateSelect}
            minDate={new Date(2021, 10, 16)}
            // className="absolute mt-2"
          />
        )}
        <ul
          tabIndex='0'
          style={{ height: '268px', overflow: 'auto' }}
          className={
            isOpen === true ? 'select-container-earning-bottom-replacement active' : 'select-container-earning-bottom-replacement'
          }
          id='selectContainer'
        >
          {element.map((i, index) =>
            isOrigin === i ? (
              <li
                key={index}
                className='select-option-earning-bottom-replacement'
                onClick={() => {
                  selected(i);
                }}
              >
                {i}
              </li>
            ) : (
              <li
                key={index}
                className='select-option-earning-bottom-replacement'
                onClick={() => {
                  selected(i);
                }}
              >
                {i}
              </li>
            )
          )}
        </ul>
      </div>
      <div className='block-grid-color-select'>
        <div className='container-select-earning-bottom-replacement'>
          <input
            name='select_value'
            type='hidden'
            id='selectedValue'
            value={isValue}
            onBlur={() => {
              toggleOptionsBlur();
            }}
          />
          <div
            className='display-value-earning-bottom-replacement'
            id='displayValue'
            onClick={() => {
              toggleOptions();
            }}
          >
          <span className='value-text-earning-bottom-replacement' id='valueText'>
            {item ? item : isValue}
          </span>
            <span
              className={
                isOpen === true ? 'arrow-earning-bottom-replacement active arrow-down' : 'arrow-earning-bottom-replacement arrow-down'
              }
              id='arrowControl'
            ></span>
          </div>
        </div>
        <div className='block-grid-color-select-close' onClick={() => {
          getVision(false);
          // setDateObj({
          //   startDayUtc: '01-01-2020',
          //   endDayUtc: '09-01-2022'
          // });
        }}><span>CLOSE</span>
          <span>
          <svg width='8' height='8'>
            <use href={sprite + '#close_h'}></use>
          </svg>
        </span>
        </div>
      </div>
    </>
  );
};
export default SelectInputEarningBottomReplacement;
