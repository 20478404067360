import React, { useEffect, useMemo, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useFormik, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import FormGroupInput from '../form-group-input';
import ButtonCustom from '../button';
import LableInline from '../label-inline';
import CalendarInput from '../../components/datepicker-custom';
import { ValidationSchedulePerformanceSchema } from '../../shared/validationSchema/schedulePerformanceValidate';
import {
  createFormPerformacneRequest,
  createFormPerformacneSuccess
} from '../../slices/schedulePerformance';

import FormSectionSuperAdmins from '../form-section-super-admin';
import { getProfileUser } from '../../selectors/user.selector';
import { isLoading, isCreated } from '../../selectors/schedule.selector';
import SelectCustom from '../../components/SelectCustom';
import { genres } from '../../shared/defaultValue';
import { FORMAT_DATE_TIME } from '../../shared/utils/constants';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import moment from 'moment';
import TextArea from '../textarea';
import ImageUploadScheduledPerfomance from '../image-upload-scheduled-perfomance';
import { getFriendsList } from '../../selectors/friends.selector';
import { getFriendsListRequest } from '../../slices/friendsList';
import FormSectionsuperAdminsIds from '../form-section-super-admin';

const FormSchedulePerformance = ({ onToggle }) => {
  const dispatch = useDispatch();

  const [loadImage, setLoadImage] = useState(false);
  const profileUser = useSelector(getProfileUser);
  const loading = useSelector(isLoading);
  const created = useSelector(isCreated);
  const getUser = useMemo(() => {
    return profileUser || JSON.parse(localStorage.getItem('profile_user'));
  }, [profileUser]);
  const [reRendingSchedules, setReRendingSchedules] = useState(0);
  const [valueImage, setValueImage] = useState('');
  const [picture, setPicture] = useState();


  const friendsList = useSelector(getFriendsList);

  const listUsers = [{
    label: getUser?.displayName,
    value: getUser?.playFabId
  }];

  let appState = JSON.parse(window.localStorage.getItem('frisson_state'));

  useEffect(() => {
    dispatch(getFriendsListRequest());
  }, [dispatch]);


  friendsList?.friends.forEach((item) => {
    const { displayName, friendId } = item;
    const newObj = { label: displayName, value: friendId };
    listUsers.push(newObj);
  });

  const formik = useFormik({
    initialValues: {
      performanceName: '',
      description: '',
      schedulingAccountId: getUser?.playFabId,
      genre: [],
      startDatetime: new Date(),
      runtime: 0,
      targetArtistAccountId: [],
      superAdminsIds: [],
      updateThumbnail: '',
    },
    validationSchema: ValidationSchedulePerformanceSchema,
    onSubmit: (values) => {
      const superAdminsIdsArr = []
      const genres = values?.genre?.map((item) => item.value);
      values?.superAdminsIds?.forEach((item) => {
        superAdminsIdsArr.push(item.value);
      });

      const newForm = {
        ...values,
        genre: genres.toString(),
        targetArtistAccountId: values?.targetArtistAccountId.value,
        startDatetime: moment(values.startDatetime).format(
          FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM
        ),
        runtime: Number(values.runtime),
        superAdminsIds: superAdminsIdsArr,
        updateThumbnail: picture,
      };
      console.log('newForm-->', newForm);
      dispatch(createFormPerformacneRequest(newForm));
    }
  });
  useEffect(() => {
    if (!created) return;
    onToggle(true);
    dispatch(createFormPerformacneSuccess(false));
  }, [created, dispatch, onToggle]);

  const getImage = (value) => {
    setValueImage(value);
  };

  return (
    <div className="wrapper-form-schedule">
      <div className="form-schedule-top">
        <div className="shedule-text shedule-title">
          New Scheduled Performance
        </div>
        <div className="shedule-text shedule-description">
          Scheduled performances are intended to be completed by the responsible
          parties. Repeated abuse of scheduled performances may result in
          Frisson access limitations for future performances.
        </div>
      </div>
      <FormikProvider value={formik}>
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              <FormGroupInput
                label={'PERFORMANCE TITLE'}
                placeholder={'Max 50 charactor'}
                icon="fa-video-camera"
                name="performanceName"
                formik={formik}
              />
            </Col>
            <Col xs={12} lg={6}>
              <div className="form-group">
                <LableInline classNameColor="special" label="ARTIST OR EVENT COMPANY ACCOUNT" />
                <SelectCustom
                  isMulti={false}
                  data={listUsers || []}
                  onChange={(opt, e) => {
                    formik.setFieldValue('targetArtistAccountId', opt);
                  }}
                  name="targetArtistAccountId"
                  placeholder="Select artist"
                  customIndicator=" "
                  formik={formik}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <div className="form-group">
                <LableInline
                  classNameColor="active"
                  label="START DATE & TIME"
                />
                <CalendarInput
                  styley={{ width: '100% !important' }}
                  showTimeSelect
                  disablePast={true}
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  name="startDatetime"
                  formik={formik}
                  handleChange={(opt, e) => {
                    formik.setFieldValue('startDatetime', opt);
                  }}
                />
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="form-group">
                <FormGroupInput
                  classNameColor="special"
                  label={'RUNTIME IN MINUTES'}
                  placeholder="Runtime in minutes"
                  icon="fa-clock-o"
                  name="runtime"
                  formik={formik}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} lg={6}>
              <div className="form-group">
                <LableInline classNameColor="active" label="MUSIC GENRE" />
                <SelectCustom
                  isMulti={true}
                  data={genres || []}
                  onChange={(opt, e) => {
                    formik.setFieldValue('genre', opt);
                  }}
                  name="genre"
                  className="select-music-genres"
                  placeholder="Music Genres"
                  customIndicator=" "
                  formik={formik}
                />
              </div>
              <div className="form-group">
                <LableInline
                  classNameColor="active"
                  label="PERFORMANCE DESCRIPTION"
                />
                <TextArea
                  class="bg_color--1 performance_description"
                  name="description"
                  rows="3"
                  placeholder={'Description of your show'}
                />
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <FormSectionsuperAdminsIds classLabel="special" list={listUsers || []} formik={formik} />
              <ImageUploadScheduledPerfomance
                setReRendingSchedules={setReRendingSchedules}
                buttonColor="red"
                loadImage={loadImage}
                getUser={formik}
                getImage={picture}
                onPictyreUpload = {(e) => setPicture(e)}
                classLabel="form-label special"
              />
            </Col>
          </Row>
        </Container>
      </FormikProvider>
      <div className="form-footer">
        <ButtonCustom
          btnText={'Schedule Show'}
          type="button"
          btnStyle={'rounded'}
          size={'sd'}
          color={'tarkis'}
          loading={loading}
          onClick={formik.handleSubmit}
        />
        <ButtonCustom
          customClass="cancel-btn"
          btnText={'Cancel'}
          type="button"
          btnStyle={'rounded'}
          size={'sd'}
          color={'theme'}
          onClick={() => onToggle(true)}
        />
      </div>
    </div>
  );
};
export default FormSchedulePerformance;