import { AppConfig } from 'configs';
import API from './api/api';

class ScheduledPerformancesAPI {
  constructor() {
    this.requestUrl = `${AppConfig.apiURL}/schedules`;
  }

  getScheduledPerformances = async (payload) => {
    return API.post(`${this.requestUrl}/search`, payload, {
      withoutAuth: true
    });
  };

  updateThumbnail(payload, id) {
    return API.post(`${this.requestUrl}/update-thumbnail/${id}`, payload, {
      isMultipart: true
    });
  }
}

export default new ScheduledPerformancesAPI();
