import { isEmpty } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './styles.scss';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { TABS } from '../../data/profile-edit/ProfileTab';
import classNames from 'classnames/bind';
import { selectTab } from '../../slices/tabs';
import { Link, NavLink, useHistory } from 'react-router-dom';
import sprite from '../../assets/svg/sprite.svg';
import { selectedGenre } from '../../slices/genres';
import ListItems from '../../container/list-items';

const DropDownSearchMenu = ({ isShowSearchMenu, openAndCloseMenu, isOpenMenuSubSearch }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isActive = () => {
    openAndCloseMenu(!isShowSearchMenu);
  };
  const [tabIndexEm, setTabIndexEm] = useState(0);
  const [inShowAddItems, setInShowAddItems] = useState(false);
  const [addGenres, setAddGenres] = useState('');
  const [types, setTypes] = useState('');
  const [addTimes, setAddTimes] = useState('');
  const [addShows, setAddShows] = useState('');
  const replace = () => {
    let str = addGenres.toLowerCase();
    if (str.indexOf('-')) {
      let re = /-/g;
      let newstr = str.replace(re, ' ');
      return newstr;
    } else if (addGenres.toLowerCase() === 'country music') {
      return 'country';
    } else return addGenres.toLowerCase();
  };
  const searchButtonReady = () => {
    dispatch(selectedGenre({ label: replace(), value: replace() }));
    history.push('/discover');
  };
  const getItemsSelect = (value) => {
    if (value.addType === 'shows') {
      setAddShows(value.item);
    }
    if (value.addType === 'time') {
      setAddTimes(value.item);
    }
    if (value.addType === 'genres') {
      setAddGenres(value.item);
    }
  };
  const isActiveItems = () => {
    setInShowAddItems(false);
  };
  const handlClick = (e, tabIdx) => {
    setTabIndexEm(tabIdx);
    dispatch(selectTab(e.target.tabIndex));
  };
  useEffect(() => {
    setInShowAddItems(false);
  }, [isOpenMenuSubSearch]);
  return (
    <>
      <ListItems inShowAddItems={inShowAddItems} isActiveItems={isActiveItems} getItemsSelect={getItemsSelect}
                 types={types} />
      <div style={{ display: isShowSearchMenu ? 'block' : 'none' }} className='block-top-menu-search'>
        <div style={{ display: 'grid', justifyItems: 'center', width: 'inherit' }}>
          <ul className='block-top-menu-menu'>
            <li onClick={() => {
              setInShowAddItems(true);
              setTypes('shows');
            }}>
              <span>Shows</span>
              <svg fill='#fff' style={{ width: '5px', height: '10px' }}>
                <use href={sprite + '#angle_right'}></use>
              </svg>
              <div className='genres-select'>{addShows}</div>
            </li>
            <li onClick={() => {
              setInShowAddItems(true);
              setTypes('time');
            }}>
              <span>Time</span>
              <svg fill='#fff' style={{ width: '5px', height: '10px' }}>
                <use href={sprite + '#angle_right'}></use>
              </svg>
              <div className='genres-select'>{addTimes}</div>
            </li>
            <li onClick={() => {
              setInShowAddItems(true);
              setTypes('genres');
            }}>
              <span>Genres</span>
              <svg fill='#fff' style={{ width: '5px', height: '10px' }}>
                <use href={sprite + '#angle_right'}></use>
              </svg>
              <div className='genres-select'>{addGenres}</div>
            </li>
          </ul>
        </div>
        <div className='block-top-menu-menu'>
          <button className='block-top-menu-search-button' onClick={searchButtonReady}>Search</button>
        </div>
      </div>
    </>
  );
};
export default DropDownSearchMenu;
