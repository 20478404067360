import jwt_decode from 'jwt-decode';
import store from '../store';
import { logout, loginSuccess } from 'slices/auth';
import userService from '../services/UserAPI';

export const tryGetValidToken = createHandleFunc(async () => {
  console.log('[Auth] Trying get valid token...');
  tokensInfo();
  const token = getTakenIfValid();
  if (token) return token;

  const refreshToken = getRefreshTokenIfValid();
  if (!refreshToken) {
    store.dispatch(logout());
    return false;
  }

  const result = await userService.setRefreshToken(refreshToken);
  if (result.success) {
    store.dispatch(loginSuccess(result.data.token));
    const access_token = result.data.refreshToken;
    localStorage.setItem('refreshToken', access_token);
    return result.data.token;
  } else {
    store.dispatch(logout());
    console.error('[Auth] Unauthorized! Unable to refresh token!');
    return false;
  }
});

function getTakenIfValid() {
  const frissonState = JSON.parse(window.localStorage.getItem('frisson_state'));
  if (!frissonState?.auth?.currentUser) {
    console.warn('[Auth] No frisson_state found in localStorage!');
    return false;
  }
  const token = jwt_decode(frissonState.auth.currentUser);

  if (token.exp * 1000 < Date.now()) {
    console.warn(`[Auth] Token was expired!`);
    return false;
  }
  console.log(`[Auth] Token is valid!`);
  return frissonState.auth.currentUser;
}

function getRefreshTokenIfValid() {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    console.warn('[Auth] No refreshToken found in localStore!');
    return false;
  }

  if (jwt_decode(refreshToken).exp * 1000 < Date.now()) {
    console.warn('[Auth] Refresh token is expired!');
    return false;
  }
  console.log('[Auth] Refresh token is valid!');
  return refreshToken;
}

function createHandleFunc(func) {
  let execution = false;
  let waiter = undefined;

  return async function (...args) {
    console.log('[createHandleFunc] REFRESH TOKEN CALLED');
    if (execution) {
      return waiter;
    }

    execution = true;
    waiter = new Promise(async (resolve) => {
      console.log('[createHandleFunc] REFRESH TOKEN PROCESS');
      const result = await func(...args);
      execution = false;
      console.log('[createHandleFunc] REFRESH TOKEN FINISHED');

      resolve(result);
    });

    return waiter;
  };
}

function tokensInfo() {
  const frissonState = JSON.parse(window.localStorage.getItem('frisson_state'));
  const tokenExp =
    frissonState?.auth?.currentUser &&
    jwt_decode(frissonState.auth.currentUser).exp;

  const refreshToken = localStorage.getItem('refreshToken');
  const refreshTokenExp = refreshToken && jwt_decode(refreshToken).exp;

  console.log(`Token will be expired at ${new Date(tokenExp * 1000)}`);
  console.log(
    `Refresh Token will be expired at ${new Date(refreshTokenExp * 1000)}`
  );
}
