import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FieldArray } from 'formik';
import './styles.scss';
import LabelInline from '../label-inline';
import SelectCustom from '../SelectCustom';

function FormSectionsuperAdminsIds({ formik, list, classLabel, disabled, readOnly, authorPublication }, objectEvent) {
  const [formValue, setFormValue] = useState(false);
  const [emailUser, setEmailUser] = useState('');
  let userAdminEmail = JSON.parse(localStorage.getItem('profile_user'));
  useEffect(() => {
    if (Object.keys(objectEvent).length !== 0 && objectEvent?.superAdminsIds.includes(userAdminEmail.email)) {
      setFormValue(true);
    }
  }, [objectEvent]);
  useEffect(() => {
    setEmailUser(() => userAdminEmail.email);
  }, []);

  return (
    <div className='form-group'>
      <LabelInline
        classNameColor={classLabel}
        label='SUPER ADMINS'
      />
      <SelectCustom
        isDisabled={disabled}
        isMulti={true}
        readOnly={readOnly}
        data={list || []}
        onChange={(opt, e) => {
          formik.setFieldValue('superAdminsIds', opt);
        }}
        onBlur={formik.handleBlur}
        name={`superAdminsIds`}
        placeholder="Select super admin"
        customIndicator=" "
        formik={formik}
      />
    </div>
  );
}

export default FormSectionsuperAdminsIds;
