import * as yup from "yup";
import { FORMAT_DATE_TIME } from '../utils/constants';

export const ValidationRegisterSchema = yup.object().shape({
  userName: yup
    .string()
    .min(5, "Must be 6 characters")
    .max(50, "Must be 50 characters or less")
    .matches(
      "^[a-zA-Z0-9]*$",
      "Must contain characters or numbers"
    )
    .required("Required"),
  genre: yup.array().required("Required"),
  birthday: yup.string().required("Required"),
  pronoun: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  password: yup
    .string()
    .required("Required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%#?&.-])[A-Za-z\d@$!%*#?&.-]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Both password need to be the same")
    })
});

export const ValidationForgotPassword = yup.object().shape({
  password: yup
    .string()
    .required("Required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Both password need to be the same")
    })
})

export const ValidationRegisterSchemaProducer = yup.object().shape({
  username: yup
    .string()
    .min(5, "Must be 6 characters")
    .max(50, "Must be 50 characters or less")
    .matches(
      "^[a-zA-Z0-9]*$",
      "Must contain characters or numbers"
    )
    .required("Required"),
  music_genres: yup.array().required("Required"),
  birthdate: yup.string()
    .max(new Date(), "max date")
    .required("Required"),
  preferred_pronoun: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  password: yup
    .string()
    .required("Required")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%#?&.-])[A-Za-z\d@$!%*#?&.-]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Both password need to be the same")
    })
});
