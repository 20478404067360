import React, { useCallback, useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import "./styles.scss";

const ButtonSearch = ({ value, styleColor, handleClick }) => {
  const [object, setObject] = useState({});
  const emdashBefore = useRef(0);
  const emdashAfter = useRef(0);
  const background = useRef(0);

  useEffect(() => {
    if (isEmpty(object)) {
      emdashBefore.current.style.backgroundColor = `${styleColor}`;
      emdashAfter.current.style.backgroundColor = `${styleColor}`;
      background.current.style.background = "#ffffff";
      background.current.style.color = "#333333";
      return;
    }
    emdashBefore.current.style.background = "#ffffff";
    emdashAfter.current.style.background = "#ffffff";
    background.current.style.background = `${styleColor}`;
    background.current.style.color = "#ffffff";
  }, [object, styleColor]);

  const handleActiveButon = useCallback(
    (color, value) => {
      if (color === styleColor && isEmpty(object)) {
        setObject(value);
      } else {
        setObject({});
      }
      handleClick(value);
    },
    [handleClick, object, styleColor]
  );
  return (
    <div
      type="button"
      ref={background}
      className="btn-filter"
      onClick={() => handleActiveButon(styleColor, value)}
    >
      <div className="btn-content">
        <span ref={emdashBefore} className={`btn-content__empdash `}>
          {" "}
        </span>
        <p className="btn-content__text">{value.text || value}</p>
        <span ref={emdashAfter} className={`btn-content__empdash`}>
          {" "}
        </span>
      </div>
    </div>
  );
};

export default ButtonSearch;
