import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const FooterFinishProducerSetup = () => {

  let date = new Date();
  let year = date.getFullYear()
  return (
<Container  fluid style={{ position: 'absolute', bottom: '0'}}>
      <Row>
        <Col md={4}></Col>
        <Col md={4} style={{textAlign: 'center', fontSize: '16px', fontWeight: '400', lineHeight: '24px', color:'#fff'}}>
          <p>&copy; Frisson {year}</p>
        </Col>
        <Col md={4}></Col>
      </Row>
</Container>
  );
};
export default FooterFinishProducerSetup;