import friendStatus from "../data/friends-list/friend-status.json";

export const getFriendsList = (state) => state.friends.friendsList;

export const isLoading = (state) => state.friends.loading;

export const isSuccess = (state) => state.friends.success;

export const getFriends = (state) => state.friends;

export const getFriendsTotalCount = (state) => {
  return state.friends?.friendsList?.friends?.filter(
    (item) => item?.status !== friendStatus.offline
  );
};
