import React from 'react';
import { ReactComponent as LoadingSVG } from './frisson_logo_lines_loading.svg'
import './styles.scss';

const Loading = () => {
  return (
    <div class="loading">
      <div class="loadingAnim">
        <LoadingSVG /> 
      </div>
    </div>
  );
};
export default Loading;