import * as THREE from 'three'
import gsap from 'gsap'
import { ChatInput } from './chatManager'
import { relativeCenter } from './JoinTheCrowd'

export const mouse = new THREE.Vector2()

let mouseDown = false
let camera = null
let dragging = false
let canDrag = false
export function setCanDrag (state) {
    canDrag = state
}

const MouseStates = {
    None : 'none',
    Hovering : 'hovering',
    Dragging : 'dragging'
}

let mouseState = MouseStates.None

const raycaster = new THREE.Raycaster()

var deltMouseX = 0,
    deltMouseY = 0

export function dragControls(canvas,theCamera,callback) {  
    camera = theCamera

    
        
    // console.log('mouseevents',canvas)
    
    canvas.addEventListener('mousemove', function (evt) {
        pointerDrag(evt, false, callback)
    }, false);
        
    canvas.addEventListener('mousedown', function (evt) {
        pointerDown(evt, false)
    }, false);

    canvas.addEventListener('mouseout', function (evt) {
        pointerUp(evt)
    }, false);
    
    canvas.addEventListener('mouseup', function (evt) {
        pointerUp(evt)
    }, false);

    canvas.addEventListener('touchmove', function (evt) {
        pointerDrag(evt, true, callback)
    }, false);
        
    canvas.addEventListener('touchstart', function (evt) {
        pointerDown(evt, true)
    }, false);
    
    canvas.addEventListener('touchend', function (evt) {
        pointerUp(evt)
    }, false);
}

function pointerDown (evt, isTouch) {
    evt.preventDefault();
    mouseDown = true;
    let clientX = evt.clientX
    let clientY = evt.clientY

    if (isTouch) {
        clientX = evt.touches[0].clientX;
        clientY = evt.touches[0].clientY;
    }

    deltMouseX = clientX;
    deltMouseY = clientY; 
    
    mouse.x = ( clientX / window.innerWidth ) * 2 - 1;
    mouse.y = - ( clientY / window.innerHeight ) * 2 + 1;

    // if (currentHover) {
    //     currentSelected = currentHover
    //     currentSelected.object.material.color.set('#0000ff')
    // }

    //run the raycaster immediately if touch since we didn't have a frame to check for hover
    // if (isTouch) tick()

    //initiate a selection
    mouseState = MouseStates.Dragging

    // currentSelected = currentHover
    // prevHover = currentHover
    // currentHover = null

}

function pointerDrag (evt, isTouch, callback) {
    let clientX = evt.clientX
    let clientY = evt.clientY

    if (isTouch) {
        clientX = evt.touches[0].clientX;
        clientY = evt.touches[0].clientY;
    }

    var deltaX = clientX - deltMouseX,
        deltaY = clientY - deltMouseY;
            
        deltMouseX = clientX;
        deltMouseY = clientY; 
        
        
        mouse.x = ( clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( clientY / window.innerHeight ) * 2 + 1;
    
    if (!mouseDown) {return}
    
    // console.log('dragging ' + currentSelected?.object.name)
    evt.preventDefault();
    dragging = true
    dragAction(deltaX, deltaY, callback);
}

function pointerUp (evt) {
    evt.preventDefault();
    mouseDown = false;

    //check if we dragged the item or just clicked on it
    if (dragging) {
        dragging = false

    }
}

function dragAction(deltaX, deltaY, callback) {

    //figure out if rotate or slide
    //if rotate, standard min max rotation val, but need min max of value it's controlling and need to remap
    //if slide, need to know direction and starting location to determing min max distance


    

    if (canDrag) {
        let totalDelta
        totalDelta = (deltaX) * 0.008
        if (camera.position.x <= relativeCenter + 5 && camera.position.x >= relativeCenter -5 ) {
        camera.translateX(-totalDelta) 
        }

        if (camera.position.x >= relativeCenter + 5) camera.position.set(relativeCenter + 4.98, camera.position.y, camera.position.z)

        if (camera.position.x <= relativeCenter -5) camera.position.set(relativeCenter - 4.98, camera.position.y, camera.position.z)
        

        //if we're dragging, close the input window
        if (document.getElementById('message-init').classList.contains('active')) {
            ChatInput()
        }
    }
    

    // if (currentSelected.object.tag == "rotate") {
    //     totalDelta = (deltaX - deltaY) * 0.01

    //     if ((currentSelected.object.rotation.y - totalDelta) >= minRotate && (currentSelected.object.rotation.y - totalDelta) <= maxRotate) {
    //         currentSelected.object.rotation.y -= totalDelta
    //         // console.log(currentSelected.object.rotation.y)
    //         currentSelected.object.val = Math.abs(getRatio(currentSelected.object.rotation.y, minRotate, maxRotate) - 1)

    //         if (callback) callback(currentSelected)
    //     }
    // } else if (currentSelected.object.tag == "slide") {
    //     totalDelta = deltaY * 0.001
    //     // console.log(currentSelected.object.position.x - totalDelta - 0.1)
    //     if (currentSelected.object.position.z + totalDelta <= maxSlide && currentSelected.object.position.z - totalDelta >= minSlide) {
    //         currentSelected.object.translateZ(totalDelta)

    //         currentSelected.object.val = getRatio(currentSelected.object.position.distanceTo(currentSelected.object.startingPos), minSlide, maxSlide)
    //         // console.log(currentSelected.object.position.x)
    //         if (callback) callback(currentSelected)
    //     }
        
    // } else if (currentSelected.object.tag == "spinner") {
    //     if (callback) currentSelected.object.callbacks[0](deltaX)
    // }
    // //for shelf dragging and button select
    // else if (currentSelected.object.tag == "select" || typeof currentSelected.object.tag == 'undefined') {
    //     let topParent = currentSelected.object
    //     let foundParent = false

    //     while (topParent.parent && foundParent == false) {
    //         topParent = topParent.parent

    //         if (topParent.tag == 'drag') {
    //             foundParent = true
    //         }
    //     }

    //     //if the top most parent of the 
    //     if (topParent.tag == 'drag') {
    //         totalDelta = (deltaX - deltaY) * 0.003

    //         draggableShelves.forEach(element => {
    //             element.translateX(totalDelta)
    //         });
            
    //     }
        
        
        
    // }
    
}


export function getRatio (val, min, max) {
    return (val - min) / (max - min)
}

export function getVal (ratio, min, max) {
    return ((max - min) * ratio) + min
}

// const tick = () =>
// {

//     if (camera) {
//         raycaster.setFromCamera(mouse, camera)

//         // const objectsToTest = [cube]
//         const intersects = raycaster.intersectObjects(interactables, true)

//         if (intersects.length) {
//             switch (mouseState) {
//                 case MouseStates.None:
//                     //reset all vars, mouse not interacting with anything
//                     mouseState = MouseStates.Hovering

//                     //set new hover
//                     currentHover = intersects[0]

//                     if (mouseDown) {
//                         //initiate a selection
//                         mouseState = MouseStates.Dragging

//                         currentSelected = currentHover
//                     }

//                     SetMaterial()

//                     break;
//                 case MouseStates.Hovering:
//                     //indicate hovering over one obj

//                     //new hover obj
//                     if (!currentHover || intersects[0].object.uuid != currentHover.object.uuid) {

//                         //this means it's a new obj we're hovering over, set it as prev
//                         if (currentHover) {
//                             prevHover = currentHover
//                         }
//                         //set new hover
//                         currentHover = intersects[0]
//                         console.log(currentHover.object.name)
//                         SetMaterial()
//                     }

                    

//                     // if (mouseDown) {
//                     //     //initiate a selection
//                     //     mouseState = MouseStates.Dragging

//                     //     currentSelected = currentHover
//                     //     prevHover = currentHover
//                     //     currentHover = null

//                     //     SetMaterial()
//                     // }

//                     break;
//                 case MouseStates.Dragging:
//                     //indicate dragging one obj
                    
//                     if (!mouseDown) {
//                         mouseState = MouseStates.Hovering
//                     }

//                     break;
            
//                 default:
//                     break;
//             }
//         }
//         else {
//             //not hovering over anything

//             if (mouseState != MouseStates.Dragging) {
//                 if (currentHover) {
//                     prevHover = currentHover
//                     currentHover = null

//                     SetMaterial()
//                 }
//             }
//             else {
//                 if (!mouseDown) {
//                     mouseState = MouseStates.None
//                 }
//             }
//         }

//         //fix any slider positions - the update loop seems out of sync with the mouse drag actions
//         if (currentSelected?.object.tag == "slide") {
//             sliderBounds()
//         }
//     }
    

//     // Call tick again on the next frame
//     window.requestAnimationFrame(tick)
// }

// tick()
// console.log("This is running")

export default {dragControls:dragControls, dragAction:dragAction, mouse:mouse}