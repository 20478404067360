import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ButtonVideo from '../button-video';
import { redirectPage } from '../../shared/utils/helpers';
import './styles.scss';
import close from '../../../src/assets/svg/sprite.svg';
import icons from '../../constants/icon';
import moment from 'moment';

const WatchListItem = ({ data, onRemoveItem }) => {
  const handleRedirectToChat = (e) =>
    redirectPage(e, `jointhecrowd?id=${data?.streamId}`);

  function timeConvert(time) {
    if (Number(time.slice(0, 2)) > 12) {
      return time.slice(0, 2) - 12 + ':' + time.slice(3, 5) + 'PM';
    } else if (Number(time.slice(0, 2)) === 12) {
      return time.slice(0, 2) + ':' + time.slice(3, 5) + 'PM';
    } else {
      return time.slice(0, 2) + ':' + time.slice(3, 5) + 'AM';
    }
  }

  const getDay = (data) => {
    let days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ];
    let d = new Date(data);
    let n = d.getDay();
    return days[n];
  };
  const month = new Date(data.startDatetime).getMonth();
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const timeZone = () => {
    return new Date().getTimezoneOffset();
  };
  const itemTime = () => {
    let y = data.startDatetime.slice(11, 19).split(':');
    return moment
      .utc(data.startDatetime)
      .utcOffset(
        timeZone() >= 0
          ? -`${Math.abs(timeZone())}`
          : +`${Math.abs(timeZone())}`
      )
      .format('HH:mm:ss');
  };
  const itemDate = () => {
    return (
      monthNames[month] +
      ' ' +
      new Date(data.startDatetime).getDate() +
      ', ' +
      new Date(data.startDatetime).getFullYear()
    );
  };

  return (
    <Row className='wrapper-watch-list-item'>
      <Col xs={12} md={5} className='thumbnail' onClick={data?.streamId ? handleRedirectToChat : ''}>
        <img src={data?.thumbnailUrl ? data?.thumbnailUrl : icons.defaultImage} alt={data?.performanceName} />
        <div className='play-button'>
          {
            data?.streamId ?
              <ButtonVideo videoId={`jointhecrowd?id=${data?.streamId}`} />
            :
              <span className='not-live'>Not Live</span>
          }
        </div>
      </Col>
      <Col xs={12} md={6} className='details'>
        <span className='title'  onClick={data?.streamId ? handleRedirectToChat : ''}>{data?.performanceName}</span>
        <span className='text name-author'>{data?.targetArtistDisplayName}</span>
        <span className='text'>{timeConvert(itemTime())}, {getDay(data.startDatetime)}</span>
        <span className='text'>{itemDate()}</span>
        <div className='color-button'>{
          data?.genre?.split(' / ')?.map((n) => {
            return <span className={'style-music-' + n.split(' ')} key={n}>{n}</span>;
          })
        }
        </div>
      </Col>
      <Col className='action'>
        <svg onClick={() => onRemoveItem(data?.scheduledPerformanceId)}>
          <use href={close + '#close'} />
        </svg>
        {/*<i className='fa fa-times' onClick={() => onRemoveItem(data?.id)}></i>*/}
      </Col>
      <Col xs={12} className='details-mobile'>
        <div className='color-button'>{
          data?.genre?.split(' / ')?.map((n) => {
            return <span className={'style-music-' + n.split(' ')} key={n}>{n}</span>;
          })
        }
        </div>
      </Col>
    </Row>
  );
};
export default WatchListItem;
