import React, { useState, useRef, useEffect } from 'react';
import './styles.scss';
import sprite from '../../assets/svg/sprite.svg';
import { Link } from 'react-router-dom';
import defaultuser from '../../assets/img/edit-profile/defaultuser.png';
import { CSSTransition } from 'react-transition-group';

const IconMenuTopModerno = ({
  isOpen,
  user,
  closeMenu,
  openT,
  isAuthenticated,
  handleOpenPopUp,
  handleOpenFeedback,
  direction
}) => {

  const [showMessage, setShowMessage] = useState(false);
  const [showChild, setShowChild] = useState(false);

  const nodeRef = useRef(null);
  useEffect(()=>{
    openT && setShowMessage(true)
  }, [openT])
  return (
    <CSSTransition
        in={showMessage}
        nodeRef={nodeRef}
        timeout={300}
        classNames="alert"
        unmountOnExit      
      >
    <div ref={nodeRef}
      className={
        `label-1 advance-icon-top-menu-moderno_active ${direction}`
      }
      // style={{ display: isOpen ? 'grid' : 'none' }}
    >
      <Link to={process.env.PUBLIC_URL + '/'} className="top-menu-item-modern a">
        <svg style={{ width: '30px', height: '30px' }}>
          <use href={sprite + '#evenodd'}></use>
        </svg>
      </Link>
      <div className="line_a"></div>
      <Link
        to={process.env.PUBLIC_URL + '/dressing-room'}
        className="top-menu-item-modern b"
      >
        <svg style={{ width: '50px', height: '50px' }}>
          <use href={sprite + '#yel'}></use>
        </svg>
      </Link>
      <div className="line_b"></div>
      <Link
        to={process.env.PUBLIC_URL + '/store'}
        className="top-menu-item-modern c"
      >
        <svg style={{ width: '50px', height: '50px' }}>
          <use href={sprite + '#cart_men'}></use>
        </svg>
      </Link>
      <div className="line_c"></div>
      <Link
        to={process.env.PUBLIC_URL + '/atm'}
        className="top-menu-item-modern d"
      >
        <svg style={{ width: '30px', height: '30px' }}>
          <use href={sprite + '#atm_r'}></use>
        </svg>
      </Link>
      <div className="line_d"></div>
      <div
        className="top-menu-item-modern e"
        onMouseDown={(e) => (isAuthenticated ? handleOpenPopUp(e) : {})}
      >
        <svg style={{ width: '30px', height: '30px' }}>
          <use href={sprite + '#users_r'}></use>
        </svg>
      </div>
      <div className="line_f"></div>
      <div
        className={`top-menu-item-modern f parent-menu-item ${showChild ? 'open' : ''}`}
        onClick={() => {setShowChild((prevActive) => !prevActive)}}
      >
        <CSSTransition
          in={showChild}
          nodeRef={nodeRef}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <div  ref={nodeRef} className={`children-menu-item`}>
            <ul>
              <li>
                <div
                  onMouseDown={(e) => (isAuthenticated ? handleOpenFeedback(e) : {})}
                >
                  <svg style={{ width: '16px', height: '16px' }}>
                    <use href={sprite + '#shareFeedback_ico'}></use>
                  </svg>
                  Give Feedback
                </div>
              </li>
              <li>
                <Link
                  to={process.env.PUBLIC_URL}
                >
                  <svg style={{ width: '16px', height: '16px' }}>
                    <use href={sprite + '#tutorial_ico'}></use>
                  </svg>
                  Tutorial
                </Link>
              </li>
            </ul>
          </div>
        </CSSTransition>
        <svg style={{ width: '30px', height: '30px' }}>
          <use href={sprite + '#question_ico'}></use>
        </svg>
      </div>
      <div className="line_g"></div>
      <Link
        to={process.env.PUBLIC_URL + '/profile'}
        className="top-menu-item-modern g"
      >
        <div className="circle">
          <img src={user || defaultuser} alt="" />
        </div>
      </Link>

      <div className="div_close k" onClick={() => {closeMenu(false);setShowMessage(false);setShowChild(false) }}>
        <svg style={{ width: '30px', height: '30px' }}>
          <use href={sprite + '#close_red'}></use>
        </svg>
      </div>
    </div>
    
    </CSSTransition>
  );
};
export default IconMenuTopModerno;
