import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuth } from "../../selectors/auth.selector";
import { roleUser } from "../../shared/utils/helpers";

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { isAuthenticated } = useSelector(getAuth);
  const role = roleUser();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }

        // check if route is restricted by role
        if (roles && roles.indexOf(role) === -1) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: "/" }} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};
