import { AppConfig } from "configs";
import API from "./api/api";

class SearchGameAPI {
  constructor() {
    this.requestUrl = `${AppConfig.apiURL}/game-sessions`;
  }

  searchGameSession = async (payload) => {
    return API.post(`${this.requestUrl}/search`, payload, {
      withoutAuth: true
    });
  };

  searchGameRandom = async (params) => {
    return API.get(`${this.requestUrl}/random-search`, {
      params,
      withoutAuth: true
    });
  };
}

export default new SearchGameAPI();
