import User from '../shared/audiophile/models/user';
import jwt_decode from 'jwt-decode';

function GetUser() {
  let appState = JSON.parse(window.localStorage.getItem('frisson_state'));

  if (appState?.auth.isAuthenticated) {
    let props = jwt_decode(appState.auth.currentUser);
    const displayName = window.localStorage.getItem('profile_user');

    try {
      if (displayName) {
        props.displayName = JSON.parse(displayName);
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error parsing JSON for displayName:', error);
      return null;
    }
    return new User(props);
  } else {
    return null;
  }
}

export default GetUser;