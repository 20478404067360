export const ccEventData = {
    event_firework: {
        type: "bees",
        typeText: "bees",
        primaryColor: "#FF0000",
        secondaryColor: "#FFFFFF",
        timing: "0",
        quantity: "1",
        // fireworkBeatsBetweenLaunches: '0'
    },
    event_crowdemoji: {
        text: "1f642", //must be all lowercase with no prefix
        emoji: "🙂",
        primaryColor: "#FF0000",
        typeText: "small",
        type: "1", // Size as an integer: 1-small, 2-medium, 3-large
        timing: "0", // section ID
        quantity: "1"
    },
    event_dance_vibes: {
        animation: ""
    }
}

export const fireworkTypes = [
    "bees",
    "pion",
    "comet",
    "crossover",
    "georgine",
    "gold",
    "honey",
    "lilac",
    "mine",
    "minion",
    "osier",
    "palm",
    "scarlet",
    "violet",
    "flares"
]

export const emojiTypes = [
    "small",
    "medium",
    "large"
]