import React from 'react';

const CallContentVertical = ({heading, headingColor, btnText, btnLink, btnClasses, description, onClick}) => {
    return (
        <div className="call-content vertical-call-to-action text-left">
            <h4 className="heading heading-h4" style={{color: headingColor}}>{heading}</h4>
            {/*<div className="spacing"/>*/}
            <p className="pt--10" style={{color: headingColor, fontSize:12}}>{description}</p>
            <div className="call-btn text-left">
                <a onClick={onClick} className={`frisson-btn ${btnClasses}`}
                   href={btnLink}
                >
                    {btnText}
                </a>
            </div>
        </div>
    );
};

export default CallContentVertical;
