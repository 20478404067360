import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Modal, Button, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
// component
import CheckMark from '../check-mark';

// api

import { useDispatch, useSelector } from 'react-redux';
// helper
import { ValidationProducer } from '../../utilities/validationSchemaSheet';
import './styles.scss';
import {
  addEmailArtistRequest,
  addNameArtistRequest,
  addPasswordArtistRequest,
  updateProfilePreferences
} from '../../slices/user';
import LoginForgotPasswordRegister from '../../container/login-register';
import { getAuth } from '../../selectors/auth.selector';
import { FORMAT_DATE_TIME, ROLE_SCHEDULE } from '../../shared/utils/constants';
import { navigateSchedule, registerRequest } from '../../slices/auth';
import { roleUser } from '../../shared/utils/helpers';
import moment from 'moment/moment';
import { ValidationRegisterSchema } from '../../shared/validationSchema/registerValidate';
import Select from 'react-select';
import { genres, pronouns } from '../../shared/defaultValue';
import InputFormik from '../input-formik';
import SelectCustom from '../SelectCustom';
import CalendarComponent from '../calendar';
import GetUser from '../../utilities/getUserLocalStorage';
import { getProfileUser, isLoading } from '../../selectors/user.selector';
import { Spinner } from '../spinner';
import { SpinnerOverlay } from '../spinner-overlay';

function SignUpModal(props) {
  const { size } = props;
  return (
    <Modal
      {...props}
      size={size}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
    </Modal>
  );
}

const LandingForms = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [modalArtistShow, setArtistModalShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [modalLogin ,setModalLogin] = useState(false);
  const [modalLoginProducer ,setModalLoginProducer] = useState(false);
  const { isAuthenticated, isSignUp, isRole } = useSelector(getAuth);
  const role = roleUser();
  const [isExpandable, setExpandable] = useState(false);
  const wrapperRef = useRef(null);
  const profileUser = useSelector(getProfileUser);
  const getUser = useMemo(() => {
    return profileUser || JSON.parse(localStorage.getItem('profile_user'));
  }, [profileUser]);

  useEffect(() => {
    GetUser();
  }, []);

  const handleHideModal = () => {
    setArtistModalShow(false);
    setSuccess(false);
  };
  const changeModalArtist = () => {
    setModalLogin(!modalLogin);
  }

  const changeModalProducer = () => {
    setModalLoginProducer(!modalLoginProducer);
  }

  const handleInput = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(
      'birthdate',
      moment(value).format(FORMAT_DATE_TIME.MM_DD_YYYY)
    );
  };

  useEffect(() => {
    if (role === 'Producer') {
      history.push('/profile');
      dispatch(navigateSchedule());
    }
  }, [dispatch, history, isRole, role]);

  useEffect(() => {
    if (isSignUp) {
      setModalLoginProducer(false);
    }
  }, [isSignUp])

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setExpandable(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);


  const genreValues = getUser ? getUser?.genre?.split(',').map(value => value.trim()) : '';

  const selectedGenres = genres?.filter(genre => genreValues?.includes(genre?.value));

  const birthdayString = getUser ? getUser?.birthday : '';
  const dateComponents = birthdayString.split('/');

  const day = dateComponents[0];
  const month = dateComponents[1];
  const year = dateComponents[2];

  const formattedDate = `${year}-${day}-${month}`;

  const formik = useFormik({
    initialValues: {
      username: getUser?.displayName,
      music_genres: selectedGenres,
      email: `${getUser?.email}`,
      preferred_pronoun: getUser?.pronoun || '',
      birthdate: formattedDate
    },
    validationSchema: ValidationProducer,
    onSubmit: (values) => {
      setLoading(true)
      const genres = values?.music_genres?.map((item) => item.value);
      const birthdateDate = moment(values?.birthdate).format(FORMAT_DATE_TIME.MM_DD_YYYY).replaceAll("/","-");
      setTimeout(() => {
        const newForm = {
          ...values,
          music_genres: genres.toString(),
          birthdate: birthdateDate
        };
        delete newForm.username;

        dispatch(updateProfilePreferences(newForm));
        }, 1000);
    }
  });

  const defaultValue = (options, value) => {
    return options ? options.find(option => option.value === value) : "";
  };

  const openArtistModal = () => {
    setArtistModalShow(true);
  }

  const thisDay = moment(new Date()).format('YYYY-MM-DD').replaceAll("/","-");

  return (
    <div className='frisson-brand-area bg-shape ptb--150 ptb-md--80 ptb-sm--60'>
      <SpinnerOverlay loading={loading} />
      <Container fluid>
        <Col
          className='mt_sm--30 mt_md--30'
          style={{ margin: 'auto' }}
          xs={12}
          md={12}
          lg={6}
        >
          <div className='contact-form bg_color--1 contact-form--2 move-left wow'>
            {(role !== 'Producer' && isAuthenticated) ? (
              <>
                <Button
                  variant='primary'
                  className='frisson-btn artist_button'
                  onClick={openArtistModal}
                >
                  Become a producer
                </Button>
              </>
            ) : (
              <>
                <h4 className='heading heading-h4'>If You're An Artist</h4>
                <div className='fr-separator--15' />
                <p>
                  Perform on your own virtual stage
                  {/* <span style={{ color: "#DF0346" }}>September 2021</span> */}
                </p>
                <Button
                  variant='primary'
                  className='frisson-btn artist_button'
                  onClick={() => changeModalProducer()}
                >
                  Sign Up Now
                </Button>
                <p style={{marginTop: 25, display: 'flex', alignItems: 'center', gap: 10, justifyContent: 'center'}}>
                  Not an artist? Sign up here instead  <button className={'btnOpenModal'} onClick={() => changeModalArtist()}> Log In / Sign Up</button>
                </p>
              </>
            )}

            <div id="loginModal" className={modalLogin && 'open'}>
              <LoginForgotPasswordRegister close={changeModalArtist} activeTabe={true}/>
            </div>

            <div id="loginModalProducer" className={modalLoginProducer && 'open'}>
              <LoginForgotPasswordRegister close={changeModalProducer} activeTabe={false}/>
            </div>

            {(role !== 'Producer' && isAuthenticated) && (
              <SignUpModal
                id="producerModal"
                show={modalArtistShow}
                onHide={() => handleHideModal()}
                size={success ? 'xs' : 'lg'}
                title={
                  !success ? (
                    <h4 className='heading heading-h4 text-center theme-color'>
                      Producer <br /> Sign Up Form
                    </h4>
                  ) : null
                }
                content={
                  success ? (
                    <CheckMark />
                  ) : (
                    <Fragment>
                      <div className='contact-form bg_color--1 '>

                        <form onSubmit={formik.handleSubmit}>
                          <Col xs={12} className='text-left'>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  background: '#fff',
                                  borderColor:
                                    formik.touched['preferred_pronoun'] && formik.errors['preferred_pronoun']
                                      ? '#dc3545'
                                      : '#9e9e9e',
                                  color: '#696c79',
                                  padding: '0 10px',
                                  fontFamily: "'Futura', Arial, Helvetica, sans-serif",
                                  height: 46,
                                  minHeight: 46
                                }),
                                input: (provided, state) => ({
                                  ...provided,
                                  margin: '0px'
                                }),
                                valueContainer: (provided, state) => ({
                                  ...provided,
                                  height: '46px',
                                  padding: '0 6px'
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  display: 'flex',
                                  alignItems: 'center',
                                  fontSize: '14px',
                                  fontFamily: "'Futura', Arial, Helvetica, sans-serif",
                                  height: 46,
                                  minHeight: 46,
                                  margin: '0px'
                                }),
                                option: (base) => ({
                                  ...base,
                                  fontSize: '14px',
                                  fontFamily: "'Futura', Arial, Helvetica, sans-serif"
                                }),
                                indicatorSeparator: (state) => ({
                                  display: 'none'
                                }),
                                indicatorsContainer: (provided, state) => ({
                                  ...provided
                                })
                              }}
                              name="preferred_pronoun"
                              onChange={(opt, e) => {
                                formik.setFieldValue('preferred_pronoun', opt.value);
                              }}
                              placeholder="Pronouns"
                              options={pronouns}
                              value={defaultValue(pronouns, getUser?.pronoun)}
                            />
                            {formik.touched.preferred_pronoun && formik.errors.preferred_pronoun ? (
                              <div className="error">{formik.errors.pronoun}</div>
                            ) : null}
                          </Col>

                          <Col xs={12} className='text-left'>
                            <InputFormik
                              icon="fa-user"
                              name="username"
                              type="text"
                              formik={formik}
                              placeholder="Username"
                              readOnly={true}
                              disabled={true}
                            />
                          </Col>
                          <Col xs={12} className='mt--15 text-left'>
                            <InputFormik
                              icon="fa-envelope-o"
                              name="email"
                              formik={formik}
                              placeholder="Email address"
                            />
                          </Col>
                          <Col xs={12} className='mt--15 text-left'>
                            <SelectCustom
                              isMulti={true}
                              data={genres || []}
                              value={selectedGenres}
                              onChange={(opt, e) => {
                                formik.setFieldValue('music_genres', opt);
                              }}
                              name="music_genres"
                              className="select-music-genres"
                              placeholder="Music Genres"
                              customIndicator=" "
                              formik={formik}
                            />
                          </Col>
                          <Col xs={12} className='mt--15 text-left'>
                            <div className="wrapper-control">
                              <InputFormik
                                className='input-style-txt'
                                type='date'
                                name='birthdate'
                                id='birthdate'
                                formik={formik}
                                onChange={handleInput}
                                placeholder="Confirm password"
                                icon="fa-calendar"
                                value={formattedDate}
                                max={thisDay}
                              />
                            </div>
                          </Col>
                          <Row className='pt--50'>
                            <Col lg={4} sm={4} xs={2} md={4}></Col>
                            <Col
                              lg={4}
                              sm={4}
                              xs={8}
                              md={4}
                              style={{ justifyContent: 'center', display: 'flex' }}
                            >
                              <button
                                id='registerButton'
                                className='frisson-btn fr-btn-theme btn-sd-size btn-rounded space-between'
                                type='submit'
                              >
                                Submit
                              </button>
                            </Col>
                            <Col lg={4} sm={4} xs={2} md={4}></Col>
                          </Row>
                        </form>
                      </div>
                    </Fragment>
                  )
                }
              />
            )}
          </div>
        </Col>
      </Container>
    </div>
  );
};
export default LandingForms;