import React from 'react';
import './styles.scss';
import close from '../../assets/svg/sprite.svg';
import defaultuser from '../../assets/img/edit-profile/defaultuser.png';


const WatchListAuthor = ({data, onRemoveItem, onChange, activeClass}) => {

  return(
    <div className={`wrap ${activeClass ? 'active' : ''}`}>
      <div className='item' onClick={() => onChange(data?.PlayFabId)}>
        <img className="photo-author" src={data?.AvatarUrl ? data?.AvatarUrl : defaultuser} alt={data?.DispalyName} />
        <p className="name-author">{data?.DispalyName}</p>
      </div>
      <div className="remove-button">
        <svg onClick={() => onRemoveItem(data?.PlayFabId)}>
          <use href={close + '#close'} />
        </svg>
      </div>
    </div>
  )
}

export default WatchListAuthor;