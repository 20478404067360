import React, { useEffect, useState } from 'react';
import './styles.scss';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { deAuthorizationState, getProfileRequest } from '../../slices/user';
import { useHistory } from 'react-router-dom';
import { getUrls } from '../../selectors/tab.selector';
import sprite from '../../assets/svg/sprite.svg';
import { getProfileUser, profUser } from '../../selectors/user.selector';

const OutboundStreamConnection = ({ profileUser }) => {
  // const restApiCustom = useSelector(getProfileUser);
  let user = JSON.parse(localStorage.profile_user);
  const [idBlock, setIdBlock] = useState(user.selectedStream);
  useEffect(() => {
    setIdBlock(user.selectedStream);
  }, [user.selectedStream]);
  const dispatch = useDispatch();
  let history = useHistory();
  const [isLoad, setIsLoad] = useState(false);
  const [count, setCount] = useState(0);
  const [getUrl, setGetUrl] = useState('');
  // const [authCode, setAuthCode] = useState({});
  // const [entityToken, setEntityToken] = useState({});
  const [objectAuth, setObjectAuth] = useState({});
  const getUrlsMore = useSelector(getUrls);
  const getTokenLocalStorage = () => {
    let token = JSON.parse(localStorage.getItem('frisson_state')).auth
      .currentUser;
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      atob(base64)
        ?.split('')
        ?.map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  };


  const getApiToken = (entity, playfab, streamingplatform, code, url) => {
    return fetch('https://10B75.playfabapi.com/CloudScript/ExecuteFunction', {
      method: 'POST',
      headers: {
        Accept: 'text/plain',
        'X-EntityToken': entity,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        FunctionName: 'ProducerStreamOut_Auth',
        PlayFabId: playfab,
        FunctionParameter: {
          streamingPlatform: streamingplatform,
          authorization_code: code,
          isWebApp: true,
          redirectUri: url
        }
      })
    });
  };
  // ----Twitch Start----
  useEffect(() => {
    if (localStorage.getItem('permissionTwitchUrl') === 'true' && true) {
      if (window.location.href.includes('localhost')) {
        getResolve('http://localhost:3000/profile');
      } else if (window.location.href.includes('development')) {
        getResolve('https://frisson-live-development.azureedge.net/profile');
      } else getResolve('https://frisson.live/profile');
    }
    // window.addEventListener('load', () => {
    //   if (window.location.search !== '') {
    //     window.location.href.includes('localhost') ? getResolve('http://localhost:3000/profile') : getResolve('https://frisson-live-development.azureedge.net/profile');
    //   }
    // });
    // history.push('/profile');
  }, []);
  const getResolve = (url) => {
    let promise = new Promise(function(resolve, reject) {
      // let str = window.location.search;
      let str = localStorage.getItem('urlLink');
      let s = str.slice(6);
      let k = s.split('&')[0];
      let h = k.indexOf('=');
      let p = k.slice(h + 1);
      resolve(p);
    });
    promise
      .then((data) => {
        // setAuthCode(data);
        // let token = JSON.parse(localStorage.getItem('frisson_state')).auth.currentUser;
        return {
          authCode: data,
          entityTok: getTokenLocalStorage(),
          streamingPlatform: 'twitch',
          url: url
        };
      })
      .then((data) => {
        return fetch(`${process.env.REACT_APP_EXECUTE_FUNCTION}`, {
          method: 'POST',
          headers: {
            Accept: 'text/plain',
            'X-EntityToken': data?.entityTok?.entityToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            FunctionName: 'ProducerStreamOut_Auth',
            PlayFabId: data?.entityTok?.playFabId,
            FunctionParameter: {
              streamingPlatform: data?.streamingPlatform,
              authorization_code: data?.authCode,
              isWebApp: true,
              redirectUri: data?.url
            }
          })
        });
      })
      .then(async (data) => {
        let object = await data.json();
        setTimeout(() => {
        }, 3000);
        setObjectAuth(object);
        setIsLoad(false);
        localStorage.setItem('permissionTwitchUrl', false);
        dispatch(getProfileRequest());
      });
  };
  // ----Twitch End----
  // ----Google Start----
  const responseGoogleError = (e) => {
    console.log(e.error);
  };
  const responseGoogle = (res) => {
    setIsLoad(true);
    let p = new Promise(function(resolve, reject) {
      resolve(res);
    });
    p.then((data) => {
      // setAuthCode(data);
      return {
        authCode: data,
        entityTok: getTokenLocalStorage(),
        streamingPlatform: 'youtube',
        url: 'postmessage'
      };
    }).then((data) => {
      getApiToken(
        data?.entityTok?.entityToken,
        data?.entityTok?.playFabId,
        data?.streamingPlatform,
        data?.authCode?.code,
        data?.url
      ).then(async (data) => {
        let object = await data.json();
        setObjectAuth(object);
        setIsLoad(false);
        dispatch(getProfileRequest());
      });
    });
  };
  const detachmentYoutubeTwitch = (streamingPlatform) => {
    dispatch(deAuthorizationState(streamingPlatform));
  };
  const updateKey = async (res) => {
    let t = await fetch(`${process.env.REACT_APP_UPDATE_USER_DATA}`, {
      method: 'POST',
      headers: {
        Accept: 'text/plain',
        'X-Authorization': getTokenLocalStorage().playFabSessionToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Data: {
          selectedStream: res
        },
        Permission: 'Public'
      })
    });
    dispatch(getProfileRequest());
  };
  const getId = async (e) => {
    setIdBlock(e.target.id);
    await updateKey(e.target.attributes[2].nodeValue);
  };
  return (
    <>
      <div className='outbound-stream-connection'>
        <div className='outbound-stream-connection-info'>
          <h4>Outbound Stream Connection</h4>
          <div>
            <svg fill='#fff' style={{ width: '20px', height: '20px' }}>
              <use href={sprite + '#info'}></use>
            </svg>
          </div>
        </div>
        <div className='stream-youtube-twitch'>
          <div
            className={
              idBlock === 'youtube' ? 'stream-block active' : 'stream-block'
            }
          >
            <div className='stream-block-img'>
              <h4>Youtube</h4>
              {profileUser.youtube_authorized === true ? (
                <p>Authenticated as:</p>
              ) : (
                <p>Not Authenticated</p>
              )}
              {profileUser.youtube_authorized === true ? (
                <div className='block-image'>
                  <img
                    src={
                      profileUser.authorizedServiceAccounts.youtube.pictureUrl
                    }
                  />
                  <div>
                    {profileUser.authorizedServiceAccounts.youtube.username}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            {profileUser.youtube_authorized !== true ? (
              <GoogleLogin
                clientId='413139574132-4ojvbh9864ns3v2cirphkquakffskb11.apps.googleusercontent.com'
                render={(renderProps) => (
                  <button
                    className='stream-block-btn red'
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    Connect Account
                  </button>
                )}
                buttonText='Login'
                responseType='code'
                onSuccess={responseGoogle}
                onFailure={responseGoogleError}
                cookiePolicy={'single_host_origin'}
                redirectUri='postmessage'
                scope='https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/userinfo.profile'
                accessType='offline'
              />
            ) : (
              <GoogleLogin
                clientId='413139574132-4ojvbh9864ns3v2cirphkquakffskb11.apps.googleusercontent.com'
                render={(renderProps) => (
                  <button
                    className='stream-block-btn red'
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    Change Account
                  </button>
                )}
                buttonText='Login'
                responseType='code'
                onSuccess={responseGoogle}
                onFailure={responseGoogleError}
                cookiePolicy={'single_host_origin'}
                redirectUri='postmessage'
                scope='https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/userinfo.profile'
                accessType='offline'
              />
            )}
            {profileUser.youtube_authorized === true ? (
              <button
                className='stream-block-btn-border red'
                onClick={() => {
                  detachmentYoutubeTwitch({ streamingPlatform: 'youtube' });
                }}
              >
                Disconnect Account
              </button>
            ) : (
              ''
            )}
            <button
              className={
                idBlock === 'youtube'
                  ? 'stream-block-btn-border-none'
                  : 'stream-block-btn-border-none no-selected'
              }
              id='youtube'
              data-response='youtube'
              onClick={getId}
            >
              {idBlock === 'youtube'
                ? 'Selected Stream Platform'
                : 'Select Stream Platform'}
            </button>
          </div>
          <div
            className={
              idBlock === 'twitch' ? 'stream-block active' : 'stream-block'
            }
          >
            <div className='stream-block-img'>
              <h4>Twitch</h4>
              {profileUser.twitch_authorized === true ? (
                <p>Authenticated as:</p>
              ) : (
                <p>Not Authenticated</p>
              )}
              {profileUser.twitch_authorized === true ? (
                <div className='block-image'>
                  <img
                    src={
                      profileUser.authorizedServiceAccounts.twitch.pictureUrl
                    }
                  />
                  <div>
                    {profileUser.authorizedServiceAccounts.twitch.username}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            {profileUser.twitch_authorized !== true ? (
              <button
                onClick={() => {
                  localStorage.setItem('permissionTwitchUrl', true);
                  window.location.replace(
                    `${process.env.REACT_APP_URL_TWITCH}${
                      window.location.href.includes('localhost')
                        ? 'http://localhost:3000/'
                        : 'https://frisson-live-development.azureedge.net/'
                    }profile&response_type=code&scope=channel:manage:broadcast channel:read:stream_key clips:edit`
                  );
                }}
                className='stream-block-btn violet'
              >
                Connect Account
              </button>
            ) : (
              <button
                onClick={() => {
                  window.location.replace(
                    `${process.env.REACT_APP_URL_TWITCH}${
                      window.location.href.includes('localhost')
                        ? 'http://localhost:3000/'
                        : 'https://frisson-live-development.azureedge.net/'
                    }profile&response_type=code&scope=channel:manage:broadcast channel:read:stream_key clips:edit`
                  );
                }}
                className='stream-block-btn violet'
              >
                Change Account{' '}
              </button>
            )}
            {profileUser.twitch_authorized === true ? (
              <button
                className='stream-block-btn-border violet'
                onClick={() => {
                  detachmentYoutubeTwitch({ streamingPlatform: 'twitch' });
                }}
              >
                Disconnect Account
              </button>
            ) : (
              ''
            )}
            <button
              className={
                idBlock === 'twitch'
                  ? 'stream-block-btn-border-none'
                  : 'stream-block-btn-border-none no-selected'
              }
              id='twitch'
              data-response='twitch'
              onClick={getId}
            >
              {idBlock === 'twitch'
                ? 'Selected Stream Platform'
                : 'Select Stream Platform'}
            </button>
          </div>
          <div
            className={
              idBlock === 'ud_3' ? 'stream-block active' : 'stream-block'
            }
          >
            <div className='stream-block-img'>
              <h4>Facebook</h4>
              <p>Not Authenticated</p>
              {/*<div className='block-image'>*/}
              {/*  <img src={ggg} />*/}
              {/*  <div>Frisson Live</div>*/}
              {/*</div>*/}
            </div>
            <button className='stream-block-btn blue'>Connect Account</button>
          </div>
        </div>
      </div>
    </>
  );
};
export default OutboundStreamConnection;
