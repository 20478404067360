import React, { useState } from 'react';
import './styles.scss';

const tabs = [
  { id: '1', name: 'Status' },
  { id: '2', name: 'Configuration' },
  { id: '3', name: 'Customization' }
];
const TabRemotePerformance = ({ getSelectId, tabComponent }) => {
  const setTab = (e) => {
    getSelectId(e.target.id);
  };
  return (
    <div className='block-tab-remote-performance'>
      {
        tabs?.map(i => {
          return <span key={i.id} className={tabComponent === i.id ? 'active' : ''} id={i.id}
                       onClick={setTab}>{i.name}</span>;
        })
      }
    </div>
  );
};
export default TabRemotePerformance;
