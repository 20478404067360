import * as yup from "yup";

export const ValidationGoogelSheerSchema = yup.object().shape({
  username: yup
    .string()
    .max(50, "Must be 50 characters or less")
    .required("Required"),

  email: yup
    .string()
    .email("Email is invalid")
    .required("Required"),
  teamIsFrisson: yup
    .boolean()
    .required("Required")
    .oneOf([true], "You must accept the terms and conditions."),

  addRoles: yup.array().of(
    yup.object().shape({
      addRoleEmail: yup
        .string()
        .email("Email is invalid")
        .required("Email is required"),
    })
  ),
  password: yup
    .string()
      .required("Required")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  confirmPassword: yup
    .string()
      .required("Required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("password")], "Both password need to be the same")
    }),
});

export const ValidationProducer = yup.object().shape({
  email: yup
    .string()
    .email("Email is invalid")
    .required("Required"),
  birthdate: yup.string()
    .max(new Date(), "max date")
    .required("Required"),
  music_genres: yup.array().required("Required"),
  preferred_pronoun: yup.string().required("Required"),
});
