import React, { useEffect, useMemo, useRef, useCallback } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { BlockLoading } from "react-loadingg";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// // component
import PopupVideo from "../../components/popup-video";
// redux-action
import { homeSliderRequest } from "../../slices/searchGameSession";
import { isLoading, getHomeSlider } from "../../selectors/searchGame.selector";

// helper
import { FILTER_STATUS } from "../../shared/utils/constants";
import { isEmpty } from "lodash";
import "./styles.scss";
const SliderLiveShows = () => {
  const swiperRef = useRef(null);
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const { data } = useSelector(getHomeSlider);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false
  };
  const goNext = useCallback(() => {
    swiperRef.current.slickNext();
  }, []);
  const goPrev = useCallback(() => {
    swiperRef.current.slickPrev();
  }, []);
  const renderSlider = useMemo(() => {
    return (
        <Slider {...settings} ref={swiperRef}>
          {!isEmpty(data) &&
          data?.map((slide, index) => (
              <div className="swiper-slide move-up h100dvh" key={index}>
                <img src={slide.thumbnailUri} alt="" />
                <div className="slide-inner h100dvh">
                  <Container fluid className="h-100">
                    <Row className="h-100 align-items-center">
                      <Col xs={12}>
                        <div className="hero-banner-content">
                          <PopupVideo
                              className="text-center"
                              videoId={`jointhecrowd?id=${slide?.id}`}
                              gradient={false}
                              gradientClass="color-pink"
                          />
                        </div>
                      </Col>
                      <Col
                          lg={{ span: 5, offset: 1 }}
                          xl={{ span: 3, offset: 1 }}
                      >
                        <div className="slide-content">
                          <div className="slide-title">
                          <span className="watchers">
                            <div>{slide?.totalConnections}</div>
                            <div> In the crowd </div>
                          </span>
                            <div className="profile-stream">
                              <h2>{slide.targetArtistDisplayName}</h2>
                              <p>{slide.genre}</p>
                            </div>

                            {slide.status === FILTER_STATUS.LIVE ? (
                                <div className="content-live-now-body">
                                  <div className="show-live__box">
                                    <div className="live-indicator"> </div>
                                    <div className="live-text">
                                      {slide?.status} !
                                    </div>
                                  </div>
                                </div>
                            ) : null}
                          </div>

                          <div className="slider-control">
                            <div
                                className="swiper-button-prev"
                                aria-label="Previous slide"
                                role="button"
                                onClick={goPrev}
                            />
                            <div
                                className="swiper-button-next"
                                onClick={goNext}
                                aria-label="Next slide"
                                role="button"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
          ))}
        </Slider>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (!isEmpty(data)) return;
    dispatch(
        homeSliderRequest({
          status: FILTER_STATUS.LIVE,
          limit: 500
        })
    );
  }, [data, dispatch]);

  return (
      <div className="hero-slider hero-slider--architecture">
        {loading ? <BlockLoading color="#df1a46" /> : renderSlider}
      </div>
  );
};

export default SliderLiveShows;
