import ThreeMeshUI from 'three-mesh-ui'
import * as THREE from 'three'
import logo from "../pages/crowd-chat/assets/frisson_totem_default.png";
import FontJSON from "../pages/crowd-chat/assets/nunito.json";
import FontImage from "../pages/crowd-chat/assets/nunito.png";

import { buildCanvas } from 'shared/canvasBuilder';
import { Callbacks, contains } from 'jquery';
import { ProposeNewTotem, VoteForTotem } from '../pages/crowd-chat/totemManager';

const objsToTest = []

let tempUIElements = []

const raycaster = new THREE.Raycaster();

const mouse = new THREE.Vector2();
mouse.x = mouse.y = null;

let selectState = false;

let scene
let camera
let renderer
let bounds
let canvas
let canvasContainer
let videoContainer
export let totems = {}


export function updateBounds (theRenderer) {
  bounds = theRenderer.domElement.getBoundingClientRect();
}


///////////////////
// UI contruction
///////////////////

function PointerMove (event) {

	mouse.x = ( ( event.clientX - bounds.left ) / ( bounds.width - bounds.left ) ) * 2 - 1;
	mouse.y = - ( ( event.clientY - bounds.top ) / ( bounds.bottom - bounds.top) ) * 2 + 1;

}
function TouchStart (event) {
	if (event.target == canvas) {
		selectState = true;
	mouse.x = ( ( event.touches[ 0 ].clientX - bounds.left ) / ( bounds.width - bounds.left ) ) * 2 - 1;
	mouse.y = - ( ( event.touches[ 0 ].clientY - bounds.top ) / ( bounds.bottom - bounds.top) ) * 2 + 1;	
	PointerDown(event, true)
	updateButtons()
	}
	
}
function PointerDown (evt, isTouch) {
	//console.log(bounds)
	if (evt.target == canvas) selectState = true;
	
}

function PointerUp () {
	selectState = false;
}

function TouchEnd () {
	selectState = false;
    mouse.x = null;
    mouse.y = null;
}

export function setupUI(theScene, theCamera, theRenderer, theCanvas) {

  renderer = theRenderer
  scene = theScene
  camera = theCamera
  canvas = theCanvas
  

  updateBounds(renderer)

  window.addEventListener( 'pointermove', PointerMove, false);

  window.addEventListener( 'touchstart', function (evt) {
	TouchStart(evt)
}, false);
  
  window.addEventListener( 'pointerdown', PointerDown, false);
  
  window.addEventListener( 'pointerup', PointerUp, false);
  
  window.addEventListener( 'touchend', TouchEnd, false);

  canvasContainer = document.getElementById('canvasContainer2')
  videoContainer = document.getElementById('videoContainer2')

  console.log('ui loaded')
}

export function buildSectionsPanel (sectionTotals, isRoom, posX, posY, posZ, btnCallback, camCallback) {
	// Container block, in which we put the two buttons.
	// We don't define width and height, it will be set automatically from the children's dimensions
	// Note that we set contentDirection: "row-reverse", in order to orient the buttons horizontally

	  //temp button array
	//   let sections = [
	//     "Top-Left",
	//     "Top-Middle",
	//     "Top-Left2",
	//     "Top-Middle2",
	//     "Top-Right",
	//     "Middle-Left",
	//     "Center",
	//     "Middle-Right",
	// 	"Middle-Left",
	//     "Center",
	//     "Middle-Right",
	//     "Bottom-Left",
	//     "Bottom-Middle",
	//     "Bottom-Right",
	//   ]

	//   sectionTotals = sections

	//remove any existing ui panels
	tempUIElements.forEach(element => {
		scene.remove(element)
	});
	tempUIElements.length = 0
	objsToTest.length = 0


	let grid = getGrid(sectionTotals.length)

	// BUTTONS

	// We start by creating objects containing options that we will use with the two buttons,
	// in order to write less code.
	let buttonOptions
	let container
	if (isRoom) {
		buttonOptions = {
			width: 1,
			height: 0.5,
			justifyContent: 'center',
			offset: 0.5,
			margin: 0.05,
			// borderRadius: 0.75,
			backgroundOpacity: 0
		};

		container = new ThreeMeshUI.Block( {
			justifyContent: 'center',
			contentDirection: 'column',
			// fontFamily: FontJSON,
			// fontTexture: FontImage,
			fontSize: 0.05,
			padding: 0.2,
			// borderRadius: 1.1,
			backgroundOpacity: 0,
			backgroundColor: new THREE.Color( 0xff0000 )
		} );
	}
	else {
		buttonOptions = {
			width: 7,
			height: 4,
			justifyContent: 'center',
			offset: 0.5,
			margin: 0.05,
			// borderRadius: 0.75,
			backgroundOpacity: 0
		};
		container = new ThreeMeshUI.Block( {
			justifyContent: 'center',
			contentDirection: 'column',
			// fontFamily: FontJSON,
			// fontTexture: FontImage,
			fontSize: 0.05,
			padding: 0.2,
			// borderRadius: 1.1,
			backgroundOpacity: 0
		} );
	}

	container.position.set( posX, posY, posZ );
	container.rotation.x = -1.75;
	scene.add( container );
	tempUIElements.push(container)

	// Options for component.setupState().
	// It must contain a 'state' parameter, which you will refer to with component.setState( 'name-of-the-state' ).

	const hoveredStateAttributes = {
		state: 'hovered',
		attributes: {
			offset: 0.35,
			backgroundColor: new THREE.Color( 0x999999 ),
			backgroundOpacity: 1,
			fontColor: new THREE.Color( 0xffffff )
		},
	};

	const idleStateAttributes = {
		state: 'idle',
		attributes: {
			offset: 0.35,
			backgroundColor: new THREE.Color( 0x666666 ),
			backgroundOpacity: 0.3,
			fontColor: new THREE.Color( 0xffffff )
		},
	};
  
  	// Create states for the buttons.
	// In the loop, we will call component.setState( 'state-name' ) when mouse hover or click

	const selectedAttributes = {
		offset: 0.2,
		backgroundColor: new THREE.Color( 0x777777 ),
		fontColor: new THREE.Color( 0x222222 )
	};

  	let curInner
	let columnTracker = -1
	let rowTracker = 0

	console.log('building grid')
	// Buttons creation, with the options objects passed in parameters.
	for (let i = 0; i < sectionTotals.length; i++) {
		const sectionName = sectionTotals[i].uid
		const connections = sectionTotals[i].connections
		//check for inner container
		if (rowTracker == grid.rowCounts[columnTracker] || i == 0) {
			curInner = new ThreeMeshUI.Block( {
				justifyContent: 'center',
				contentDirection: 'row',
				// fontFamily: FontJSON,
				// fontTexture: FontImage,
				fontSize: 0.7,
				padding: 0.05,
				// borderRadius: 1.1,
				backgroundOpacity: 0
			} );
		
			container.add(curInner)

			columnTracker++ 
			rowTracker = 0
		}
		
		createButton(sectionName, connections, i, buttonOptions, selectedAttributes, hoveredStateAttributes, idleStateAttributes, curInner, btnCallback, camCallback)

		rowTracker++
	}
	
	container.add(curInner)

	//add totem back into raycaster array
	Object.values(totems).forEach(totem => {
		objsToTest.push( totem.totemContainer )
		objsToTest.push( totem.button )
	});
	
	

}

function createButton (name, connections, intName, options, selAttr, hovAttr, idleAttr, container, callback, camCallback) {
  
	const btn = new ThreeMeshUI.Block( options );
	//add the uid of the room or section to the btn for access later
	btn.uid = name
	btn.intName = intName

	// btn.add(
	// 		new ThreeMeshUI.Text( { content: name } )
	// 	);
	let innerOptions = {...options}
	innerOptions.width -= innerOptions.width * 0.03
	innerOptions.height -= innerOptions.width * 0.03
	innerOptions.offset = 0.05
	const cvs = new ThreeMeshUI.InlineBlock( innerOptions)
	const canvas = buildCanvas(options.width * 200, options.height * 200, connections)
	const texture = new THREE.CanvasTexture(canvas)
	let sectionMat = new THREE.MeshBasicMaterial({
	    map: texture
	});

	cvs.children[0].material = sectionMat
	btn.add(cvs)
	container.add( btn );

	btn.setupState( {
			state: 'selected',
			attributes: selAttr,
			onSet: () => {

				// currentMesh = ( currentMesh + 1 ) % 3;
				// showMesh( currentMesh );
				btn.worldPos = new THREE.Vector3(btn.position.x, 0, -container.position.y)
				callback(btn, btn.worldPos)
				// camCallback(btn.worldPos)
			}
		} );
	btn.setupState( hovAttr );
	btn.setupState( idleAttr );


	objsToTest.push( btn );
}

//Totem UI
let totemContainer
let voteInfoContainer
let initTotemVote

export function buildTotemPanel (id, callbackParam, rotateCallback, BtnCallback, retainImg) {

	let totem = totems[id] 

	if (!retainImg) {
		totem.totemContainer = new ThreeMeshUI.Block( {
			justifyContent: 'center',
			contentDirection: 'column',
			// fontFamily: FontJSON,
			// fontTexture: FontImage,
			fontSize: 0.05,
			padding: 0.2,
			height: 3,
			width: 3,
			padding: 0.5,
			// borderRadius: 1.1,
			backgroundOpacity: 1,
			backgroundSize: 'cover',
			backgroundColor: new THREE.Color( 0xffffff )
		} )
	
		
		totem.totemContainer.tag = 'totem'
	
		totem.totemContainer.setupState( {
			state: 'idle',
			attributes: {}
		} );
		totem.totemContainer.setupState( {
			state: 'hovered',
			attributes: {},
			onSet: () => {
				if (!totem.flipped) {
					rotateCallback(totem.id, true)
				}
			}
		} );
		totem.totemContainer.setupState( {
			state: 'selected',
			attributes: {}
		} );
	
		totem.totemContainer.position.set(0,6,0.25)
		objsToTest.push( totem.totemContainer );

		setTotemImage(totem.id, logo)

		totem.totem.add(totem.totemContainer)

	}
	

	const buttonOptions = {
		width: 2.8,
		height: retainImg ? 1.5 : 2.8,
		justifyContent: 'center',
		bestFit: "auto",
		margin: 0.1,
		borderRadius: 0.075,
		backgroundColor: new THREE.Color( 0x330000 ),
		backgroundOpacity: 0.8
	}
	const selectedAttributes = {
		offset: 0.02,
		backgroundColor: new THREE.Color( 0x777777 ),
		fontColor: new THREE.Color( 0x222222 )
	}
	const textOptions = {
		width: 2.8,
		height: 1.2,
		justifyContent: 'center',
		bestFit: "auto",
		offset: 0.05,
		margin: 0.1,
		borderRadius: 0.075,
		backgroundOpacity: 0,
		fontSize: 0.2,
	}

	voteInfoContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'column',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		fontColor: new THREE.Color(0xffffff),
		fontSize: retainImg ? 0.25 : 0.4,
		padding: 0.02,
		height: 3,
		width: 3,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		// backgroundSize: 'cover',
		backgroundColor: new THREE.Color( 0xffffff ),
		fontSupersampling: true
	} )

	totem.voteInfoContainer = voteInfoContainer

	voteInfoContainer.setupState( {
		state: 'idle',
		attributes: {}
	} );
	voteInfoContainer.setupState( {
		state: 'hovered',
		attributes: {},
		onSet: () => {
			// rotateCallback(false)
		}
	} );
	voteInfoContainer.setupState( {
		state: 'selected',
		attributes: {}
	} );

	//if this is the room totem, add the text at the top
	if (totem.id == 'room') {
		totem.text = new ThreeMeshUI.Block(textOptions)
		totem.text.add(
			new ThreeMeshUI.Text({
				content: "Totem voting \n not ready yet!",
				color: new THREE.Color(0xff0000)
			})
		)

		voteInfoContainer.add(totem.text)
	}
	

	totem.button = new ThreeMeshUI.Block(buttonOptions)
	totem.button.name = 'totemButton'
	totem.button.text = new ThreeMeshUI.Text( { content: totem.id == 'room' ? 'Propose \n new totem' : 'VOTE' } )
	
	totem.button.text.setupState( {
		state: 'available',
		onSet: () => {
			if (callbackParam == 'crowd') {
				totem.button.text.set({ content: 'JOIN'})
			}
			else if (callbackParam == 'full') 
			{
				totem.button.text.set({ content: 'FULL', fontColor: new THREE.Color( 0xff0000 )})
			}
			else {
				totem.id == 'room' ? totem.button.text.set({ content: 'Propose \n new totem!'})  : totem.button.text.set({ content: 'VOTE'})
			}
		}
	})

	totem.button.text.setupState( {
		state: 'unavailable',
		onSet: () => {
			totem.id == 'room' ? totem.button.text.set({ content: 'Propose \n new totem!'})  : totem.button.text.set({ content: 'VOTED'})
		}
	})
	totem.button.text.setState('available')
	
	totem.button.add (totem.button.text)
	 
	totem.button.setupState( {
		state: 'disabled',
		attributes: {
			backgroundColor: new THREE.Color( 0x111111 ),
			//fontColor: new THREE.Color( 0x555555 )
		}
	} )
	totem.button.setupState( {
		state: 'idle',
		attributes: {
			backgroundColor: new THREE.Color( 0x333333 ),
			fontColor: new THREE.Color(0xffffff),
			offset: 0.05,
		},
		onSet: () => {
			// console.log('set to idle')
		}
	} )
	totem.button.setupState( {
		state: 'hovered',
		attributes: {
			backgroundColor: new THREE.Color( 0x777777 ),
			fontColor: new THREE.Color( 0xffffff ),
			offset: 0.25,
		},
		onSet: () => {
			// rotateCallback(false)
		}
	} )
	totem.button.setupState({
		state: 'selected',
		attributes: selectedAttributes,
		onSet: () => {
			BtnCallback(callbackParam, id)
		}
	})

	if (callbackParam == 'full') {
		totem.button.setState('disabled')
	}
	
	objsToTest.push( totem.button )

	voteInfoContainer.add(totem.button)

	voteInfoContainer.position.set(0,6,-0.16)
	voteInfoContainer.rotation.set(0,Math.PI,0)

	totem.totem.add(voteInfoContainer)
}

export function setProposeActive (voteState) {
	console.log('setting propose')
	if (totems['room'])
	{
		console.log('setting propose' + voteState)
		switch (voteState) {
			case 'available':
				totems['room'].button.setState('idle')
				totems['room'].button.visible = true
				totems['room'].text.children[1].set( { content: "Submission available!" } )
				break;
			case 'active':
				totems['room'].button.setState('idle')
				totems['room'].button.visible = true
				totems['room'].text.children[1].set( { content: "Voting \n in progress!" } )
				break;
			case 'unavailable':
				totems['room'].button.setState('disabled')
				totems['room'].button.visible = false
				totems['room'].text.children[1].set( { content: "Voting \n not ready yet!" } )
				break;
		
			default:
				break;
		}

		// if (flag) {
		// 	totems['room'].button.setState('idle')
		// 	totems['room'].text.children[1].set( { content: "Totem submission enabled!" } )
		// }
		// else {
		// 	totems['room'].button.setState('disabled')
		// 	totems['room'].text.children[1].set( { content: "Totem voting \n not ready yet!" } )
		// }
	} 
}

export function setTotemImage (id, img) {
	
	if (totems[id]) {
		if (!img) {
			img = logo
		}
		if (!id) {
			id = 'room'
		}

		new THREE.TextureLoader().load(img, (texture) => {
			totems[id].totemContainer.set({
			backgroundTexture: texture,
			});
		});
	}
	
}

let atmContainer
export function BuildATMUI (bundles, selectCallback, tutCallback) {

	const buttonOptions = {
		width: 0.25,
		height: 0.12,
		justifyContent: 'center',
		// bestFit: "auto",
		margin: 0.01,
		padding: 0.001,
		borderRadius: 0.0075,
		backgroundColor: new THREE.Color( 0x333333 )
	}
	const selectedAttributes = {
		offset: 0.02,
		backgroundColor: new THREE.Color( 0x777777 ),
		fontColor: new THREE.Color( 0x222222 )
	}
	const textOptions = {
		width: 0.3,
		height: 0.1,
		justifyContent: 'center',
		bestFit: "auto",
		offset: 0.005,
		margin: 0.001,
		borderRadius: 0.075,
		backgroundOpacity: 0
	}


	atmContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'column',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		// fontSize: 0.2,
		fontColor: new THREE.Color( 0xffffff ),
		padding: 0.2,
		height: 0.25,
		width: 0.65,
		padding: 0.5,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		backgroundSize: 'cover',
		backgroundColor: new THREE.Color( 0xffffff )
	} )


	

	let columns = 2
	let cCounter = 0
	let curContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'row',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		// fontSize: 0.2,
		fontColor: new THREE.Color( 0xffffff ),
		padding: 0.2,
		height: 0.15,
		width: 0.65,
		padding: 0.05,
		margin: 0.001,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		backgroundSize: 'cover',
		backgroundColor: new THREE.Color( 0xffffff )
	} )
	atmContainer.add(curContainer)
	//bundles
	for (let i = bundles.length - 1; i >= 0; i--) {
		const el = bundles[i];

		const id = el.ItemId
		const displayName = el.DisplayName
		const price = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format((el.VirtualCurrencyPrices.RM * 0.01))

		let bundleButton = new ThreeMeshUI.Block(buttonOptions)
		
		bundleButton.add (
			new ThreeMeshUI.Text( { fontSize: 0.02, content: displayName + '\n' } )
		)
		bundleButton.add (
			new ThreeMeshUI.Text( {content: price } )
		)
		// bundleButton.setupState( {
		// 	state: 'disabled',
		// 	attributes: {
		// 		backgroundColor: new THREE.Color( 0x111111 ),
		// 		fontColor: new THREE.Color( 0x555555 )
		// 	}
		// } )
		bundleButton.setupState( {
			state: 'idle',
			attributes: {
				backgroundColor: new THREE.Color( 0x333333 ),
				fontColor: new THREE.Color(0xffffff),
				offset: 0.0001,
			}
		} )
		bundleButton.setupState( {
			state: 'hovered',
			attributes: {
				backgroundColor: new THREE.Color( 0x777777 ),
				fontColor: new THREE.Color( 0xffffff ),
				offset: 0.0004,
			},
			onSet: () => {
				console.log('set to hover')
			}
		} )
		bundleButton.setupState({
			state: 'selected',
			attributes: selectedAttributes,
			onSet: () => {
				selectCallback(el)
			}
		})

		bundleButton.setState('idle')

		if (cCounter < columns) {
			curContainer.add(bundleButton)
		}
		else {
			curContainer = new ThreeMeshUI.Block( {
				justifyContent: 'center',
				contentDirection: 'row',
				fontFamily: FontJSON,
				fontTexture: FontImage,
				// fontSize: 0.2,
				fontColor: new THREE.Color( 0xffffff ),
				padding: 0.2,
				height: 0.15,
				width: 0.65,
				padding: 0.5,
				// borderRadius: 1.1,
				backgroundOpacity: 0,
				backgroundSize: 'cover',
				backgroundColor: new THREE.Color( 0xffffff )
			} )
			curContainer.add(bundleButton)
			atmContainer.add(curContainer)
			cCounter = 0
		}

		cCounter++

		// atmContainer.add(bundleButton)
		objsToTest.push(bundleButton)

	}

	let bundleText = new ThreeMeshUI.Block(textOptions)
	bundleText.add(
		new ThreeMeshUI.Text({
			content: "Get Decibels",
		})
	)

	atmContainer.add(bundleText)


	atmContainer.position.set(0.925,1.87,-0.35)
	atmContainer.rotation.set(-0.32,0,0)
	scene.add(atmContainer)
	atmContainer.setupState( {
		state: 'idle',
		attributes: {}
	} )
	// objsToTest.push( atmContainer );

	///? ON ATM ////
	const TutButtonOptions = {
		width: 0.035,
		height: 0.035,
		justifyContent: 'center',
		// bestFit: "auto",
		fontSize: 0.7,
		margin: 0.01,
		padding: 0.0001,
		borderRadius: 0.02,
		backgroundColor: new THREE.Color( 0x000000 )
	}
	const TutselectedAttributes = {
		offset: 0.02,
		backgroundColor: new THREE.Color( 0x777777 ),
		fontColor: new THREE.Color( 0x222222 )
	}
	const TuttextOptions = {
		fontSize: 0.03,
		// width: 0.3,
		// height: 0.1,
		justifyContent: 'center',
		bestFit: "auto",
		offset: 0.0005,
		// margin: 0.001,
		borderRadius: 0.075,
		backgroundOpacity: 0,
		content: '?'
	}


	let ATMTutContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'column',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		// fontSize: 0.4,
		fontColor: new THREE.Color( 0xffffff ),
		padding: 0.2,
		height: 0.15,
		width: 0.25,
		padding: 0.05,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		backgroundSize: 'cover',
		backgroundColor: new THREE.Color( 0x000000 )
	} )


	ATMTutContainer.add(ATMTutContainer)
	

	let ATMQuestionMark = new ThreeMeshUI.Block(TutButtonOptions)
	
	ATMQuestionMark.add (
		new ThreeMeshUI.Text( TuttextOptions )
	)

	ATMQuestionMark.setupState( {
		state: 'idle',
		attributes: {
			backgroundColor: new THREE.Color( 0x333333 ),
			fontColor: new THREE.Color(0xffffff),
			offset: 0.0001,
		}
	} )
	ATMQuestionMark.setupState( {
		state: 'hovered',
		attributes: {
			backgroundColor: new THREE.Color( 0x777777 ),
			fontColor: new THREE.Color( 0xffffff ),
			offset: 0.0004,
		},
		onSet: () => {
			//console.log('set to hover')
		}
	} )
	ATMQuestionMark.setupState({
		state: 'selected',
		attributes: TutselectedAttributes,
		onSet: () => {
			tutCallback()
		}
	})

	ATMQuestionMark.setState('idle')

	ATMTutContainer.add(ATMQuestionMark)


	objsToTest.push(ATMQuestionMark)
	

	// let bundleText = new ThreeMeshUI.Block(textOptions)
	// bundleText.add(
	// 	new ThreeMeshUI.Text({
	// 		content: "Get Decibels",
	// 	})
	// )

	// storeMixerContainer.add(bundleText)


	ATMTutContainer.position.set(1.165,2.01,-0.387)
	ATMTutContainer.rotation.set(-0.21,0,0)
	ATMTutContainer.name = "customizeMixerUIContainer"
	scene.add(ATMTutContainer)
	ATMTutContainer.setupState( {
		state: 'idle',
		attributes: {}
	} )
}

//REPLACE ME
function rmtodollar (rm) {
	let total = '$' + rm * 0.01
	// if(total.length < 5) total += '0'
    return total
}

let customizeMixerContainer
let bodyMixerContainer
export function BuildDressingRoomUI (callback, uiParent1, uiParent2) {

	const buttonOptions = {
		width: 0.04,
		height: 0.04,
		justifyContent: 'center',
		// bestFit: "auto",
		fontSize: 0.7,
		margin: 0.01,
		padding: 0.0001,
		borderRadius: 0.02,
		backgroundColor: new THREE.Color( 0x000000 )
	}
	const selectedAttributes = {
		// offset: 0.02,
		backgroundColor: new THREE.Color( 0x777777 ),
		fontColor: new THREE.Color( 0x222222 )
	}
	const textOptions = {
		width: 0.3,
		height: 0.1,
		justifyContent: 'center',
		bestFit: "auto",
		offset: 0.0005,
		margin: 0.001,
		borderRadius: 0.075,
		backgroundOpacity: 0
	}


	customizeMixerContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'column',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		// fontSize: 0.4,
		fontColor: new THREE.Color( 0xffffff ),
		padding: 0.2,
		height: 0.15,
		width: 0.25,
		padding: 0.05,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		backgroundSize: 'cover',
		backgroundColor: new THREE.Color( 0x000000 )
	} )

	bodyMixerContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'column',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		// fontSize: 0.4,
		fontColor: new THREE.Color( 0xffffff ),
		padding: 0.2,
		height: 0.15,
		width: 0.25,
		padding: 0.05,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		backgroundSize: 'cover',
		backgroundColor: new THREE.Color( 0x000000 )
	} )


	

	let columns = 2
	let cCounter = 0
	let customizeContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'row',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		fontSize: 0.4,
		fontColor: new THREE.Color( 0xffffff ),
		padding: 0.2,
		height: 0.05,
		width: 0.35,
		padding: 0.05,
		margin: 0.001,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		backgroundSize: 'cover',
		backgroundColor: new THREE.Color( 0xffffff )
	} )
	let bodyContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'row',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		fontSize: 0.4,
		fontColor: new THREE.Color( 0xffffff ),
		padding: 0.2,
		height: 0.05,
		width: 0.35,
		padding: 0.05,
		margin: 0.001,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		backgroundSize: 'cover',
		backgroundColor: new THREE.Color( 0xffffff )
	} )

	customizeMixerContainer.add(customizeContainer)
	bodyMixerContainer.add(bodyContainer)
	//bundles
	
	let bodyQuestionMark = new ThreeMeshUI.Block(buttonOptions)
	
	bodyQuestionMark.add (
		new ThreeMeshUI.Text( { fontSize: 0.02, content: '?' } )
	)

	bodyQuestionMark.setupState( {
		state: 'idle',
		attributes: {
			backgroundColor: new THREE.Color( 0x333333 ),
			fontColor: new THREE.Color(0xffffff),
			offset: 0.0001,
		}
	} )
	bodyQuestionMark.setupState( {
		state: 'hovered',
		attributes: {
			backgroundColor: new THREE.Color( 0x777777 ),
			fontColor: new THREE.Color( 0xffffff ),
			offset: 0.0004,
		},
		onSet: () => {
			//console.log('set to hover')
		}
	} )
	bodyQuestionMark.setupState({
		state: 'selected',
		attributes: selectedAttributes,
		onSet: () => {
			callback()
		}
	})

	bodyQuestionMark.setState('idle')

	let customizeQuestionMark = new ThreeMeshUI.Block(buttonOptions)
	
	customizeQuestionMark.add (
		new ThreeMeshUI.Text( { fontSize: 0.02, content: '?' } )
	)

	customizeQuestionMark.setupState( {
		state: 'idle',
		attributes: {
			backgroundColor: new THREE.Color( 0x333333 ),
			fontColor: new THREE.Color(0xffffff),
			offset: 0.0001,
		}
	} )
	customizeQuestionMark.setupState( {
		state: 'hovered',
		attributes: {
			backgroundColor: new THREE.Color( 0x777777 ),
			fontColor: new THREE.Color( 0xffffff ),
			offset: 0.0004,
		},
		onSet: () => {
			//console.log('set to hover')
		}
	} )
	customizeQuestionMark.setupState({
		state: 'selected',
		attributes: selectedAttributes,
		onSet: () => {
			console.log('set to selected!')
			callback()
		}
	})

	customizeQuestionMark.setState('idle')

	customizeContainer.add(customizeQuestionMark)
	bodyContainer.add(bodyQuestionMark)

	// atmContainer.add(bundleButton)
	objsToTest.push(bodyQuestionMark)
	objsToTest.push(customizeQuestionMark)
	

	// let bundleText = new ThreeMeshUI.Block(textOptions)
	// bundleText.add(
	// 	new ThreeMeshUI.Text({
	// 		content: "Get Decibels",
	// 	})
	// )

	// customizeMixerContainer.add(bundleText)


	customizeMixerContainer.position.set(0.03,0.04,0.16)
	customizeMixerContainer.rotation.set(-1.75,0,0)
	customizeMixerContainer.name = "customizeMixerUIContainer"
	uiParent1.add(customizeMixerContainer)
	customizeMixerContainer.setupState( {
		state: 'idle',
		attributes: {}
	} )

	bodyMixerContainer.position.set(0.02,0.03,0.195)
	bodyMixerContainer.rotation.set(4.65,0.03,1.56)
	bodyMixerContainer.name = "bodyMixerUIContainer"
	uiParent2.add(bodyMixerContainer)
	bodyMixerContainer.setupState( {
		state: 'idle',
		attributes: {}
	} )
	// objsToTest.push( atmContainer );
}

let storeMixerContainer
let cartRemoveContainer
export function BuildStoreRoomUI (callback, uiParent) {

	const buttonOptions = {
		width: 0.035,
		height: 0.035,
		justifyContent: 'center',
		// bestFit: "auto",
		fontSize: 0.7,
		margin: 0.01,
		padding: 0.0001,
		borderRadius: 0.02,
		backgroundColor: new THREE.Color( 0x000000 ),
		backgroundOpacity: 1
	}
	const selectedAttributes = {
		// offset: 0.02,
		backgroundColor: new THREE.Color( 0x777777 ),
		fontColor: new THREE.Color( 0x222222 )
	}
	const textOptions = {
		fontSize: 0.03,
		// width: 0.3,
		// height: 0.1,
		justifyContent: 'center',
		bestFit: "auto",
		offset: 0.0005,
		// margin: 0.001,
		borderRadius: 0.075,
		backgroundOpacity: 0,
		content: '?'
	}


	storeMixerContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'column',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		// fontSize: 0.4,
		fontColor: new THREE.Color( 0xffffff ),
		padding: 0.2,
		height: 0.15,
		width: 0.25,
		padding: 0.05,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		backgroundSize: 'cover',
		backgroundColor: new THREE.Color( 0x000000 )
	} )	

	let storeQuestionMark = new ThreeMeshUI.Block(buttonOptions)
	
	storeQuestionMark.add (
		new ThreeMeshUI.Text( textOptions )
	)

	storeQuestionMark.setupState( {
		state: 'idle',
		attributes: {
			backgroundColor: new THREE.Color( 0x333333 ),
			fontColor: new THREE.Color(0xffffff),
			offset: 0.0001,
		}
	} )
	storeQuestionMark.setupState( {
		state: 'hovered',
		attributes: {
			backgroundColor: new THREE.Color( 0x777777 ),
			fontColor: new THREE.Color( 0xffffff ),
			offset: 0.0004,
		},
		onSet: () => {
			//console.log('set to hover')
		}
	} )
	storeQuestionMark.setupState({
		state: 'selected',
		attributes: selectedAttributes,
		onSet: () => {
			callback()
		}
	})

	storeQuestionMark.setState('idle')

	storeMixerContainer.add(storeQuestionMark)


	objsToTest.push(storeQuestionMark)
	

	// let bundleText = new ThreeMeshUI.Block(textOptions)
	// bundleText.add(
	// 	new ThreeMeshUI.Text({
	// 		content: "Get Decibels",
	// 	})
	// )

	// storeMixerContainer.add(bundleText)


	storeMixerContainer.position.set(0.15,0.05,0.117)
	storeMixerContainer.rotation.set(-1.71,0,0)
	storeMixerContainer.name = "customizeMixerUIContainer"
	uiParent.add(storeMixerContainer)
	storeMixerContainer.setupState( {
		state: 'idle',
		attributes: {}
	} )

	//store remove container
	cartRemoveContainer = new ThreeMeshUI.Block( {
		width: 0.42,
		height: 0.15,
		padding: 0.009,
		justifyContent: 'center',
		textAlign: 'center',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		fontSupersampling: true,
		backgroundColor: new THREE.Color( 0x440000 ),
		backgroundOpacity: 0,
	} )

	cartRemoveContainer.position.set(0.1,0.05,0.014)
	cartRemoveContainer.rotation.set(-1.57,0,0)
	cartRemoveContainer.name = "CartRemoveContainer"

	uiParent.add(cartRemoveContainer)

}

export function addRemoveButton (callback) {
	const buttonOptions = {
	    width: 0.025,
	    height: 0.025,
	    justifyContent: 'center',
		textAlign: 'center',
	    // bestFit: "auto",
	    fontSize: 0.014,
	    margin: 0.0001,
	    padding: 0.002,
	    borderRadius: 0.01,
	    backgroundColor: new THREE.Color( 0xff0000 )
	}
	const selectedAttributes = {
	    // offset: 0.02,
	    backgroundColor: new THREE.Color( 0x777777 ),
	    fontColor: new THREE.Color( 0x222222 )
	}
	const textOptions = {
	    fontSize: 0.014,
	    // width: 0.3,
	    // height: 0.1,
	    justifyContent: 'center',
	    bestFit: "auto",
	    offset: 0.0001,
	    // margin: 0.001,
	    borderRadius: 0.075,
	    backgroundOpacity: 0,
	    content: 'X'
	}

	// add new remove button for this item

	let removeButton = new ThreeMeshUI.Block(buttonOptions)
	removeButton.name = 'RemoveButton'
	removeButton.add (
	    new ThreeMeshUI.Text( textOptions )
	)

	removeButton.setupState( {
	    state: 'idle',
	    attributes: {
	        backgroundColor: new THREE.Color( 0x7d0000 ),
	        fontColor: new THREE.Color(0xffffff),
	        offset: 0.001,
	    }
	} )
	removeButton.setupState( {
	    state: 'hovered',
	    attributes: {
	        backgroundColor: new THREE.Color( 0xff0000 ),
	        fontColor: new THREE.Color( 0xffffff ),
	        offset: 0.003,
	    },
	    onSet: () => {
	        //console.log('set to hover')
	    }
	} )
	removeButton.setupState({
	    state: 'selected',
	    attributes: selectedAttributes,
	    onSet: () => {
	        callback()
	    }
	})

	removeButton.setState('idle')

	cartRemoveContainer.add(removeButton)
	objsToTest.push(removeButton)
}

export function clearRemoveButtons (uiParent) {
	uiParent.remove(cartRemoveContainer)
	cartRemoveContainer.clear()

	for (let i = 0; i < objsToTest.length; i++) {
		const obj = objsToTest[i];
		if (obj.name == 'RemoveButton')
		{
			objsToTest.splice(i, 1)
		}
	}

	cartRemoveContainer = new ThreeMeshUI.Block( {
		width: 0.22,
		height: 0.15,
		padding: 0.013,
		// contentDirection: 'column',
		// justifyContent: 'end',
		textAlign: 'right',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		fontSupersampling: true,
		backgroundOpacity: 0,
	} )

	cartRemoveContainer.position.set(0.1,0.05,0.014)
	cartRemoveContainer.rotation.set(-1.57,0,0)

	cartRemoveContainer.name = "CartRemoveContainer"
	uiParent.add(cartRemoveContainer)
}

function updateButtons() {

	// Find closest intersecting object

  if (!camera || !scene) 
    return

	let intersect;

	if ( mouse.x !== null && mouse.y !== null ) {

		raycaster.setFromCamera( mouse, camera );

		intersect = raycast();

	}

	// Update targeted button state (if any)

	if ( intersect && intersect.object.isUI && intersect.object.currentState != 'disabled' ) {

		if ( selectState && intersect.object.tag != 'totem' ) {

			// Component.setState internally call component.set with the options you defined in component.setupState
			intersect.object.setState( 'selected' );

		} else {

			// Component.setState internally call component.set with the options you defined in component.setupState
			intersect.object.setState( 'hovered' );
		}

	}
	else {
		selectState = false
	}

	// Update non-targeted buttons state

	objsToTest.forEach( ( obj ) => {

		if ( ( !intersect || obj !== intersect.object ) && obj.isUI && obj.currentState != 'disabled' ) {

			// Component.setState internally call component.set with the options you defined in component.setupState
			obj.setState( 'idle' );

		}

	} );

}

function raycast() {

	return objsToTest.reduce( ( closestIntersection, obj ) => {

		const intersection = raycaster.intersectObject( obj, true );

		if ( !intersection[ 0 ] ) return closestIntersection;

		if ( !closestIntersection || intersection[ 0 ].distance < closestIntersection.distance ) {

			intersection[ 0 ].object = obj;

			return intersection[ 0 ];

		}

		return closestIntersection;

	}, null );

}

function getGrid (total) {
    let rows = Math.floor(Math.sqrt(total))
    let columns = Math.floor(total / rows)
    let remainder = total - (rows * columns)
	let rowCounts = []

	//figure out when to add a remainder to the row
	//try to distribute them middle out
	//floor row/2 
	for (let i = 0; i < rows; i++) {
		
		rowCounts[i] = columns

		if (i < remainder)
		{
			rowCounts[i]++
		}


		
	}

    const grid = {
        "rows" : rows,
        "columns" : columns,
        "remainder" : remainder,
		"rowCounts" : rowCounts		
    }

    return grid
}


export function buildNameplate (name) {
	let nameContainer = new ThreeMeshUI.Block( {
		justifyContent: 'center',
		contentDirection: 'column',
		fontFamily: FontJSON,
		fontTexture: FontImage,
		fontColor: new THREE.Color(0xffffff),
		fontSize: 0.1,
		padding: 0.02,
		height: 0.35,
		width: 1.5,
		// borderRadius: 1.1,
		backgroundOpacity: 0,
		// backgroundSize: 'cover',
		// backgroundColor: new THREE.Color( 0x000000 ),
		fontSupersampling: true
	} )
	nameContainer.name = 'nameContainer'
	const textOptions = {
		width: 1.5,
		height: 0.35,
		justifyContent: 'center',
		bestFit: "auto",
		offset: 0.05,
		margin: 0.1,
		borderRadius: 0.075,
		backgroundOpacity: 0,
		// backgroundColor: new THREE.Color( 0x440000 ),
	}

	nameContainer.setupState( {
		state: 'idle',
		attributes: {}
	} );
	nameContainer.setupState( {
		state: 'hovered',
		attributes: {}
	} );
	nameContainer.setupState( {
		state: 'selected',
		attributes: {}
	} );

	let nameText = new ThreeMeshUI.Block(textOptions)
	nameText.add(
		new ThreeMeshUI.Text({
			content: name,
		})
	)
	nameText.name = 'nameText'

	//hide text frames that block other text
	nameContainer.children[0].visible = false
	nameText.children[0].visible = false

	nameContainer.add(nameText)

	return nameContainer
}

const loop = () => {

	// Don't forget, ThreeMeshUI must be updated manually.
	// This has been introduced in version 3.0.0 in order
	// to improve performance
	ThreeMeshUI.update();

	updateButtons();

	if (totems['room']) {
		let size = new THREE.Vector2(0, 0);
    	renderer.getSize(size);
		let elementOffset = (videoContainer.offsetHeight - (window.innerHeight / 2) + (canvasContainer.offsetHeight / 2))

		let totemPos = new THREE.Vector3();
		totems['room'].totem.children.find((obj) => obj.name = 'totemButton').getWorldPosition(totemPos);
		totemPos.setY(2.2)
		// headPos.y -= Object.values(this.characters)[0].headOffset - Object.values(this.characters)[0]

		const screenPosition = totemPos.clone();
		// const screenPosition = this.spinner.position.clone()
		screenPosition.project(camera);

		const translateX = screenPosition.x * size.x * 0.5 - 45;
		//53px is the overall size of the bubble element
		const translateY = elementOffset - document.getElementById('totemMobileButton').offsetHeight - screenPosition.y * size.y * 0.5;

		if (document.getElementById('totemMobileButton')){
			document.getElementById('totemMobileButton').style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
		} 
		
	} 

  // Call tick again on the next frame
  window.requestAnimationFrame(loop)
}

loop()

export function DisposeEvents () {
	window.removeEventListener( 'pointermove', PointerMove, false);

	window.removeEventListener( 'touchstart', TouchStart, false);
	
	window.removeEventListener( 'pointerdown', PointerDown, false);
	
	window.removeEventListener( 'pointerup', PointerUp, false);
	
	window.removeEventListener( 'touchend', TouchEnd, false);
}