// import React, { Fragment } from "react";
// import { Helmet } from "react-helmet";

// // layout
// import { LayoutDefault } from "../../layouts";
// import { Header } from "../../components/header";
// import { Footer } from "../../container/footer";

// // container
// import GenresList from "../../container/genres-list";
// import DropDownMenu from "../../container/dropdown-menu";
// import ListCardLive from "../../container/list-live-now";
// import ListRecommendedShows from "../../container/list-recommended-shows";
// import SuggestedShows from "../../container/suggested-shows";

// // data
// import frisson from "../../data/frisson.json";
// import { typeGenre } from "../../selectors/genres.selector";
// import { useSelector } from "react-redux";

// import "./styles.scss";

// const DressingRoom = () => {
//   const { genre } = useSelector(typeGenre);

//   return (
//     <Fragment>
//       <Helmet>
//         <title>{`${frisson.title}`}</title>
//       </Helmet>
//       <p>Hello World</p>
//     </Fragment>
//   );
// };

// export default DressingRoom;

import React, { useEffect } from 'react';
// import ReactDOM from "react-dom";
// import { Redirect, Route } from "react-router-dom";
import './style.css';
import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import jwt_decode from 'jwt-decode';
import { setCanDrag, dragControls, getRatio, getVal } from './drag'
import User from 'shared/audiophile/models/user';
import { screenSizeListener, screenSizeStates, mediaQueryState, sizes, getInitialSize, MQDIsposeEvents
} from './mediaQueries';
import logo from '../../assets/img/logo/logo.png';
import { Character, sharedAnims, CharacterClassInit
} from 'shared/audiophile/character';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { initChat, DisplaySections, ChatInput, SendMessage, DisconnectUser, startVid, AddMessageToHistory, updateLocationInterval, StartChat, targetArtist, userConnection
} from './chatManager';
import { bubbles, expandInit } from './trackedMessageController';
import { buildCanvas } from 'shared/canvasBuilder';
import { dynoCanvases } from 'shared/canvasBuilder';
import { buildTotemPanel, setupUI, tempUIElements, DisposeEvents, totems, setTotemImage
} from '../../shared/ui-builder';
import { render } from '@testing-library/react';
import { initCanvasBuilder } from 'shared/canvasBuilder';
import { messageEvent, messageEventInit } from './messageEvents';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { Vector3 } from 'three';
import { DisplayPackages, InitVibes, RemoveBundles } from './vibesManager';
import GetUser from '../../../src/utilities/getUserLocalStorage';
import LoginForgotPasswordRegister from 'container/login-register';
import DynamicMenu from 'components/dynamic-menu';
import { InitTut, CheckTutorialStep, arrowData
} from 'shared/audiophile/onboarding';
import { EmojiButton } from '@joeattardi/emoji-button';
import { createPicker } from 'picmo';
import VibesMenu from 'components/vibes-menu';
import { ActivateTut } from 'shared/audiophile/onboarding';
import { TutDisplay } from 'shared/audiophile/onboarding';
import DynamicMenuModernized from 'components/dynamic-menu-modernized';
import Loading from 'components/loading';
import { spawn } from 'redux-saga/effects';
import exitSign from '../../assets/img/icons/exit-sign-hang.png'
import { ProposeNewTotem } from './totemManager';
import { clone } from 'lodash';
import { player } from './vidManager';
import CCModal from './ccModal';
import { SpinnerOverlay } from '../../components/spinner-overlay';
import { useSelector } from 'react-redux';
import { isLoading } from '../../selectors/auth.selector';
import { initted } from 'shared/audiophile/onboarding';
import { createPopup } from '@picmo/popup-picker';


function Loader() {
  const loading = useSelector(isLoading);
  return <SpinnerOverlay loading={loading} />;
}

class CrowdChat extends React.Component {
  constructor(props) {
    super(props);

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.animate = this.animate.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
    this.promptUnload = this.promptUnload.bind(this);
  }


  promptUnload(e) {
    console.log('deciding to close, disconnect');

    if (this.user.character) {
      this.sectionSelection();

      var message = 'o/';

      (e || window.event).returnValue = message; //Gecko + IE
      return message;
    }

    
  }

  handleUnload() {
    console.log('decided to close, disconnect');
    DisconnectUser();
  }
  componentWillReceiveProps(props) {
    console.log(props);
  }

  componentDidMount() {
    //try to launch vid player as soon as possible
    startVid();

    //display loading
    document.getElementsByClassName('loading')[0].style.display = 'block'

    //add styles to body
    // document.body.style.height = '100%'
    // document.body.style.position = 'fixed'
    // document.body.style.overflowY = 'scroll'
    // document.documentElement.style.overflow = 'hidden'
    // document.documentElement.style.width = '100%'


    let canExpand = true

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(10, width / height, 0.1, 1000);
    this.camera = camera;

    let defaultCamPos = new THREE.Vector3(0, 4.55, 20);
    let defaultCamRot = new THREE.Vector3(-0.15, 0, 0);
    let sectionSelectCamPos = new THREE.Vector3(0, 90, 0);
    let sectionSelectCamRot = new THREE.Vector3(-1.57, 0, 0);
    let roomTotemPosition = new THREE.Vector3(5.8, 0, -3.4);

    let messageHistoryPOS = 0
    

    const roomSelectCamPosHeightOffset = 25;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer = renderer;
    const canvas = renderer.domElement;

    renderer.setClearColor(0x000000, 0);
    renderer.setSize(width, height);

    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;

    function Dispose() {
      DisposeEvents();
      MQDIsposeEvents();
    }
    this.eventsDispose = Dispose;

    /**
     * Constants
     */

    const titleId = '10B75';

    /**
     * Lights
     */
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.set(1024, 1024);
    directionalLight.shadow.camera.far = 15;
    directionalLight.shadow.camera.left = -7;
    directionalLight.shadow.camera.top = 7;
    directionalLight.shadow.camera.right = 7;
    directionalLight.shadow.camera.bottom = -7;
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    const rimLight = new THREE.PointLight(0xc73afc, 0.5);
    rimLight.position.set(-1.5, 0, 0.75);
    rimLight.name = 'RimLight';
    scene.add(rimLight);

    const spotLight = new THREE.SpotLight(0xff4444);
    spotLight.position.set(-2, 3, 0);

    spotLight.castShadow = true;

    spotLight.shadow.mapSize.width = 1024;
    spotLight.shadow.mapSize.height = 1024;

    spotLight.shadow.camera.near = 500;
    spotLight.shadow.camera.far = 4000;
    spotLight.shadow.camera.fov = 30;
    spotLight.penumbra = 1;
    spotLight.angle = 1;
    spotLight.target.position.set(3, 3, 0.5);
    spotLight.intensity = 1;
    scene.add(spotLight);
    scene.add(spotLight.target);

    //init canvas builder
    initCanvasBuilder(document);

    let initChatBtn;

    //Ground Section canvas
    // var sectionCanvas = document.createElement('canvas'),
    // ctx = sectionCanvas.getContext('2d');

    // sectionCanvas.width = 256;
    // sectionCanvas.height = 256;

    // let sectionCanvas = buildCanvas(document, 1024, 1024, "sectionCanvas")
    // let ctx = sectionCanvas.ctx

    // // ctx.fillStyle = '#333';
    // // ctx.fillRect(0, 0, sectionCanvas.canvas.width, sectionCanvas.canvas.height);
    // // ctx.strokeStyle = '#eee';
    // // ctx.lineWidth = 10
    // // ctx.strokeRect(0, 0, sectionCanvas.canvas.width, sectionCanvas.canvas.height);

    // // var canvasTexture = new THREE.CanvasTexture(sectionCanvas.canvas);

    // var sectionMat = new THREE.MeshBasicMaterial({
    //     map: sectionCanvas.texture
    // });

    // const sectionPlaneGeo = new THREE.PlaneGeometry( 25,15,20 );
    // const atmMat = new THREE.MeshBasicMaterial( {color: 0xdddcc5} );
    // const sectionPlaneMesh = new THREE.Mesh( sectionPlaneGeo, atmMat );
    // sectionPlaneMesh.position.set(0,1.2,0)
    // sectionPlaneMesh.rotation.set(-1.57,0,0)
    // scene.add( sectionPlaneMesh );

    // const cylGeo = new THREE.BoxGeometry(1, 3, 1);
    // const cylMat = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    // const characterSpinner = new THREE.Mesh(cylGeo, cylMat);
    // characterSpinner.position.set(0.09, 1.5, 0);
    // // characterSpinner.name = 'Char Spinner Target';
    // // characterSpinner.tag = 'spinner';
    // // characterSpinner.callbacks = [spinChar, resetCharSpin];
    // // characterSpinner.visible = false;
    // // interactables.push(characterSpinner);
    // scene.add(characterSpinner);
    // this.spinner = characterSpinner

    /**
     * Models
     */
    let anims = {};
    const modelPath = 'https://audiophileitems.blob.core.windows.net/items/';
    const dracoLoader = new DRACOLoader();
    const gltfLoader = new GLTFLoader();
    dracoLoader.setDecoderPath('/draco/');

    gltfLoader.setDRACOLoader(dracoLoader);

    const textureLoader = new THREE.TextureLoader();
    // let texture1 = textureLoader.load(modelPath + 'empty.png')
    // let texture2 = textureLoader.load(modelPath + 'hoody_base.png')
    // let texture3 = textureLoader.load(modelPath + 'blush.png')
    // let texture4 = textureLoader.load(modelPath + 'face.png')

    // texture1.flipY = false
    // texture2.flipY = false
    // texture3.flipY = false
    // texture4.flipY = false

    //container for onboarding arrow targets
    this.arrowTargets = {};
    this.arrowData = arrowData;
    this.arrowTargets['bubble'] = 'bubble';
    this.arrowTargets['vibe'] = 'vibe';
    this.arrowTargets['join'] = 'join';

    const geometry2 = new THREE.BoxGeometry(1, 1, 1);
    const material2 = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const sectionArrowTarget = new THREE.Object3D();
    sectionArrowTarget.name = 'section test';
    sectionArrowTarget.position.set(-3.37, 8.1, -3);
    scene.add(sectionArrowTarget);

    this.arrowTargets['sectionTarget'] = sectionArrowTarget;

    // const roomArrowTarget = new THREE.Object3D();
    const roomArrowTarget = new THREE.Mesh(geometry2, material2);
    roomArrowTarget.name = 'room test';
    roomArrowTarget.position.set(-0.7, 8.5, -4.18);
    roomArrowTarget.visible = false
    scene.add(roomArrowTarget);

    this.arrowTargets['roomTarget'] = roomArrowTarget;

    const totemArrowTarget = new THREE.Mesh(geometry2, material2);
    totemArrowTarget.visible = false
    totemArrowTarget.name = 'totem target';
    totemArrowTarget.position.set(5.5, -2.76, -3);
    scene.add(totemArrowTarget);

    this.arrowTargets['roomTotemTarget'] = totemArrowTarget;

    const selfTotemArrowTarget = new THREE.Mesh(geometry2, material2)
    selfTotemArrowTarget.visible = false
    selfTotemArrowTarget.name = 'self totem target';
    selfTotemArrowTarget.position.set(0.5, -1.9, 0.5);
    scene.add(selfTotemArrowTarget);

    this.arrowTargets['selfTotemTarget'] = selfTotemArrowTarget;

    var texture = new THREE.TextureLoader().load(
      modelPath + 'Test_Sprite_Sheet2.png'
    );
    var geometry = new THREE.PlaneGeometry(5, 5, 5);
    var material = new THREE.MeshBasicMaterial({
      map: texture,
      transparent: true
    });
    var annie = new TextureAnimator(texture, 10, 6, 60, 50); // texture, #horiz, #vert, #total, duration.
    var cube = new THREE.Mesh(geometry, material);
    cube.position.set(-15, -1.2, -22);
    // scene.add( cube );
    this.annie = annie;
    function TextureAnimator(
      texture,
      tilesHoriz,
      tilesVert,
      numTiles,
      tileDispDuration
    ) {
      // note: texture passed by reference, will be updated by the update function.

      this.tilesHorizontal = tilesHoriz;
      this.tilesVertical = tilesVert;
      // how many images does this spritesheet contain?
      //  usually equals tilesHoriz * tilesVert, but not necessarily,
      //  if there at blank tiles at the bottom of the spritesheet.
      this.numberOfTiles = numTiles;
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(1 / this.tilesHorizontal, 1 / this.tilesVertical);

      // how long should each image be displayed?
      this.tileDisplayDuration = tileDispDuration;

      // how long has the current image been displayed?
      this.currentDisplayTime = 0;

      // which image is currently being displayed?
      this.currentTile = 0;

      this.update = function (milliSec) {
        this.currentDisplayTime += milliSec;
        while (this.currentDisplayTime > this.tileDisplayDuration) {
          this.currentDisplayTime -= this.tileDisplayDuration;
          this.currentTile++;
          if (this.currentTile == this.numberOfTiles) this.currentTile = 0;
          var currentColumn = this.currentTile % this.tilesHorizontal;
          texture.offset.x = currentColumn / this.tilesHorizontal;
          var currentRow = Math.floor(this.currentTile / this.tilesHorizontal);
          texture.offset.y = currentRow / this.tilesVertical;
        }
      };
    }

    const charPositions = [
      {
        'pos' : new THREE.Vector3(-1, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(1, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-2, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(2, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-3, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(3, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-4, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(4, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-5, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(5, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-6, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(6, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-7, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(7, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-8, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(8, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-9, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(9, 0, -1.8), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-10, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(10, 0, 0), 
        'occupied' : false
      },
    ];

    // const charPositions = [
    //   {
    //     'pos' : new THREE.Vector3(-1, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(1, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(-2, 0, 0), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(2, 0, 0), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(-3, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(3, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(-4, 0, 0), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(4, 0, 0), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(-5, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(5, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(-6, 0, 0), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(6, 0, 0), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(-7, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(7, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(-8, 0, 0), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(8, 0, 0), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(-9, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(9, 0, -1.8), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(-10, 0, 0), 
    //     'occupied' : false
    //   },
    //   {
    //     'pos' : new THREE.Vector3(10, 0, 0), 
    //     'occupied' : false
    //   },
    // ];
    

    //===========================
    //
    //     User Properties
    //
    //===========================

    // function GetUser () {
    //     let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
    //     if (appState?.auth.isAuthenticated) {
    //         return new User(jwt_decode(appState.auth.currentUser))
    //     }
    //     else {
    //         // console.log("Cannot get user, we should return to login")
    //         // window.location.href = '/'
    //         return null
    //     }
    // }
    let user = {}
    
    user = GetUser()
    if (user) {
      InitTut('crowd-chat', user, [DisconnectUser]);
      document.getElementById('tutorialButton').className = ''
    }
    else {
      user = {}
    }
    this.user = user
    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === "attributes") {
          //after beginning to observe, class changes indicate that the user has attempted to log in
          //hide the modal immediately and show loading
          //if the modal has already been hidden and the class changes it's time to monitor storage for user data
          // if(!mutation.target.getAttribute(mutation.attributeName).includes('animate') && mutation.target.style.display != 'none') {
          //   document.getElementById('loginModal').style.display = 'none'
          //   document.getElementsByClassName('loading')[0].style.display = 'block'
          // }
          // else {
          //   CheckForUser()
          // }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    let observingModal = false

    //this is hacky, but the alternative would be re-writing the whole structure of this component
    function CheckForUser() {

      //display loading
      if (document.getElementsByClassName('loading')[0]) {
        document.getElementsByClassName('loading')[0].style.display = 'block'
      }

      //extra work to get user available to animate fn
      let userContainer = GetUser();
      

      if (userContainer && window.localStorage.getItem('profile_user')) {

        observer.disconnect()
        
        for (let i = 0; i < Object.keys(userContainer).length; i++) {
          const prop = Object.keys(userContainer)[i];
          user[prop] = Object.values(userContainer)[i]
        }
        // this.user = user
      
        //check if tutorial hasn't been completed
        // if(JSON.parse(window.localStorage.getItem('frisson_tutorial'))['crowd-chat'])
        // {
        //   // //check if we've just registered
        //   // if(JSON.parse(window.localStorage.getItem('frisson_state'))['auth'].isSignUp )
        //   // {
        //   //   //show profile modal here
        //   //   document.getElementById('CCModal').style.display = 'block';
        //   // }
        //   // else {
        //   //   ActivateTut();
        //   //   TutDisplay(true);
        //   // }
          
        // }
        // else {
          //tutorial has been completed, so we can init chat
          if (!initted) {
            InitTut('crowd-chat', user, [DisconnectUser])
            document.getElementById('tutorialButton').className = ''
          }
          // InitTut('crowd-chat', user, [DisconnectUser]);
          initChat(
              user,
              CharacterInit,
              GetGuestChar,
              LoadUsersInGroup,
              RemoveGuestChar,
              SectionSelectCameraMove,
              RoomSelectCameraMove,
              InRoomCameraMove,
              BuildTotem,
              CleanUpTotems
            );
          

          
          if(!JSON.parse(window.localStorage.getItem('frisson_state'))['auth'].isSignUp )   {
            
            StartChat()
            
            
            CheckTutorialStep('JoinedCrowdChat');
          }
          else {
            document.getElementsByClassName('loading')[0].style.display = 'none'
            document.getElementById('joinCrowdButton').style.display = 'block'
            document.getElementById('joinCrowdButton').classList.remove('disabled')
          }
          
          //document.getElementById('loginModal').style.display = 'none';

          
        // }
        
                
      } else {
        if (document.getElementById('loginModal')) {
          document.getElementById('loginModal').style.display = 'block';
        }
        
        document.getElementsByClassName('loading')[0].style.display = 'none' 
        // if (!observingModal) {
        //   //hide loading
        //   document.getElementsByClassName('loading')[0].style.display = 'none' 
        //   

        //   //no user was found to be logged in, so we show login modal and observe it for changes
        //   // Select the node that will be observed for mutations
        //   const targetNode = document.getElementById("login_section");

        //   // Options for the observer (which mutations to observe)
        //   const config = { attributes: true };

        //   // Start observing the target node for configured mutations
        //   observer.observe(targetNode, config);
        //   observingModal = true
        // }
        


        setTimeout(() => {
          CheckForUser();
        }, 500);
      }
    }

    // function GetUser () {
    //     let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
    //     if (appState?.auth.isAuthenticated) {
    //         user = new User(jwt_decode(appState.auth.currentUser))
    //         initChat(user, CharacterInit, GetGuestChar, LoadUsersInGroup, RemoveGuestChar, SectionSelectCameraMove, RoomSelectCameraMove, InRoomCameraMove, BuildTotem, CleanUpTotems)
    //         document.getElementById('loginModal').style.display = 'none'
    //     }
    //     else {
    //         // console.log("Cannot get user, we should return to login")
    //         // window.location.href = '/'
    //         user = null
    //         document.getElementById('loginModal').style.display = 'block'
    //     }
    // }

    //GetUser()
    // if (user != null) {
    //     CharacterInit(user)
    // }

    //===========================
    //
    //     Init
    //
    //===========================

    // setTimeout(() => {
    //     initChat(user, CharacterInit, GetGuestChar)
    //     displayMessage('4C20886BAECEFE43', 'This is a test message!')
    // }, 1000)

    CharacterClassInit(OneShotManager);

    function CharacterInit(user) {
      user = user;
      // GetUserCharacter();

      sectionSelection(true);
    }

    messageEventInit(ChangeAnimation, ChangeExpression);
    const defaultAnims = ['laughing', 'house', 'robot_dance', 'samba_dance'];
    //load deafult animations
    for (let i = 0; i < defaultAnims.length; i++) {
      const anim = defaultAnims[i];

      //only load the animation if we haven't already
      if (!sharedAnims.anim) {
        gltfLoader.load(modelPath + 'anim_' + anim + '.glb', (model) => {
          //add this anim to the library
          sharedAnims[anim] = model.animations[0];
        });
      }
    }

    const characters = {};
    this.characters = characters;

    // function replaceColor() {
    //     let obj = characters[GetMainCharacter()]?.bodyparts.face.children[0]

    //     obj.material.userData.shader.uniforms.tOne.value = texture3
    // }

    //set up the camera and section avatars for section and room selection
    const sectionSelection = (init) => {

      setCanDrag(false)

      //make sure mobile totem clicker is hidden 
      document.getElementById('totemMobileButton').style.display = 'none'
      document.getElementById('totemMobileButton').style.pointerEvents = 'none'

      if (!init) {
        ChatInput('sectionSelection', RecenterCamera);
        DisconnectUser();
        intervalManager('room', false);
      }

      document.getElementById('sectionSelectButton').style.display = 'none';
      document.getElementById('dsktpExpandButton').style.display = 'none';
      document.getElementById('message-init').style.display = 'none';
      
      DisplaySections(roomSelection);
      //move camera to top-down position

      //SectionSelectCameraMove();
    };

    //for disconnect
    this.sectionSelection = sectionSelection;

    window.addEventListener('beforeunload', this.promptUnload);
    window.addEventListener('unload', this.handleUnload);
    window.addEventListener('pagehide', this.promptUnload);
    window.addEventListener('pagehide', this.promptUnload);

    //move camera closer to selected section and populate new canvas with room details
    function roomSelection(sectionTile) {
      console.log('move the cam from room select now');
      console.log(sectionTile);

      RoomSelectCameraMove();
      sectionTile.visible = false;
    }

    function SectionSelectCameraMove() {
      document.getElementById('message-init').style.opacity = 0
      gsap.to(camera.position, {
        duration: 2.5,
        x: sectionSelectCamPos.x,
        y: sectionSelectCamPos.y,
        z: sectionSelectCamPos.z,
        ease: 'power1.out',
        onComplete: () => {
          if (user.character)  {
            user.character.body.visible = false
          }
        }
      });
      gsap.to(camera.rotation, {
        duration: 3,
        x: sectionSelectCamRot.x,
        y: sectionSelectCamRot.y,
        z: sectionSelectCamRot.z,
        ease: 'circ.out'
      });
    }

    function RoomSelectCameraMove(tilePos) {
      console.log(mediaQueryState)
      switch (mediaQueryState) {
        case 'mobilePortrait':
          //desktop needs to be offset for some reason
          roomArrowTarget.position.set(tilePos.x - 0.5, 10, tilePos.z);
          break;
        
        default:
          roomArrowTarget.position.set(tilePos.x - 0.5, 10, tilePos.z + 0.81);
          break;
      }

      
      gsap.to(camera.position, {
        duration: 2,
        x: tilePos.x,
        y: 32,
        z: tilePos.z,
        ease: 'power1.out'
      });
    }

    function InRoomCameraMove() {
      gsap.to(camera.position, {
        duration: 2.5,
        x: defaultCamPos.x,
        y: defaultCamPos.y,
        z: defaultCamPos.z,
        ease: 'power1.out', 
        onComplete: () => {
          if (!user.character)  {
            GetUserCharacter()
          }
          else {
            user.character.body.visible = true
            document.getElementById('message-init').style.opacity = 1
          }

          setCanDrag(true)

          
        }
      });
      gsap.to(camera.rotation, {
        duration: 2.5,
        x: defaultCamRot.x,
        y: defaultCamRot.y,
        z: defaultCamRot.z,
        ease: 'power1.out'
      });

      //reenable the section select button
      document.getElementById('sectionSelectButton').style.display = 'block';
      document.getElementById('dsktpExpandButton').style.display = 'block';

      BuildTotem('room', null, null, roomTotemPosition);
      // BuildTotem('center', new THREE.Vector3(0, 0, -3.4))
      // BuildTotem('left', new THREE.Vector3(-5.8, 0, -3.4))
    }

    function RecenterCamera() {
      gsap.to(camera.position, {
        duration: 0.3,
        x: 0,
        ease: 'power1.out'
      });
    }

    //===========================
    //
    //     External Functions
    //
    //===========================

    function GetUserCharacter() {
      //get user main character
      fetch(
        'https://' + titleId + '.playfabapi.com/Client/GetUserReadOnlyData',
        {
          method: 'POST',
          headers: {
            Accept: 'text/plain',
            'Content-Type': 'application/json',
            'X-Authorization': user.playFabSessionToken
          },
          body: JSON.stringify({
            Keys: ['mainCharacter']
          })
        }
      )
        .then(function (response) {
          if (response.status >= 400 && response.status < 600) {
            response.json().then(function (object) {
              alert(object.errorMessage);
            });
          } else if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          user.mainCharacter = data.data.Data.mainCharacter.Value;

          //we have to get all the user's characters first
          fetch(
            'https://' +
              titleId +
              '.playfabapi.com/Client/GetAllUsersCharacters',
            {
              method: 'POST',
              headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization': user.playFabSessionToken
              },
              body: JSON.stringify({
                PlayFabId: user.playFabId
              })
            }
          )
            .then(function (response) {
              if (response.status >= 400 && response.status < 600) {
                response.json().then(function (object) {
                  alert(object.errorMessage);
                });
              } else if (response.status === 200) {
                return response.json();
              }
            })
            .then((data) => {
              let userChars = [];
              if (Object.keys(data.data.Characters).length != 0) {
                for (let i = 0; i < data.data.Characters.length; i++) {
                  const char = data.data.Characters[i];

                  if (char.CharacterType === 'audiophile-1') {
                    userChars.push(char);
                  }
                }

                if (userChars.length) {
                  user.characters = userChars;

                  //get the data now that we have the character id
                  fetch(
                    'https://' +
                      titleId +
                      '.playfabapi.com/Client/GetCharacterReadOnlyData',
                    {
                      method: 'POST',
                      headers: {
                        Accept: 'text/plain',
                        'Content-Type': 'application/json',
                        'X-Authorization': user.playFabSessionToken
                      },
                      body: JSON.stringify({
                        PlayFabId: user.playFabId,
                        CharacterId: user.mainCharacter,
                        Keys: ['items', 'customizations', 'animations']
                      })
                    }
                  )
                    .then(function (response) {
                      if (response.status >= 400 && response.status < 600) {
                        response.json().then(function (object) {
                          alert(object.errorMessage);
                        });
                      } else if (response.status === 200) {
                        return response.json();
                      }
                    })
                    .then((data) => {
                      if (Object.keys(data.data.Data).length != 0) {
                        let props = data.data.Data;

                        console.log('successful data fetch');
                        let charProps = {
                          items: JSON.parse(props.items.Value),
                          customizations: JSON.parse(
                            props.customizations.Value
                          ),
                          displayName: user.displayName,
                          animations: JSON.parse(props.animations.Value)
                        };
                        console.log(charProps);
                        let newChar = new Character(charProps);
                        user.character = newChar;
                        characters[user.playFabId] = user.character;
                        //get new spawn pos
                        let spawnPos = new THREE.Vector3(0, 0, 0)

                        newChar.spawn(
                          user.playFabId,
                          user.mainCharacter,
                          spawnPos.x,
                          spawnPos.z,
                          scene
                        );

                        document.getElementById('message-init').style.opacity = 1

                        //get existing inventory
                        // GetOwnedItems()

                        // PrepInitialControlPops(charProps)
                      } else {
                        console.log(
                          'no character item data, drop in the defaults'
                        );
                      }
                    })
                    .catch(function (err) {
                      //no existing session with this id
                      return console.log(err);
                    });
                } else {
                  return console.log('User has no character added to account');
                }
              } else {
                console.log('User has no characters somehow...');
              }
            })
            .catch(function (err) {
              //no existing session with this id
              return console.log(err);
            });
        });
    }

    async function LoadUsersInGroup(users) {
      for (let i = 0; i < users.length; i++) {
        const id = users[i];
        await GetGuestChar(id);
      }
    }

    let userClones = []

    async function GetGuestChar(id, clone) {
      //make sure we're ignoring any join events from the main user or anyone else already spawned in the room
      if (clone || (id != user.playFabId && !Object.keys(characters).includes(id))) {
        await fetch(
          'https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction',
          {
            method: 'POST',
            headers: {
              Accept: 'text/plain',
              'X-EntityToken': user.entityToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              FunctionName: 'CC_LoadCharacter',
              PlayFabId: user.playFabId,
              FunctionParameter: {
                playfabID: id
              }
            })
          }
        )
          .then((response) => response.json())
          .then((data) => {
            //only spawn if we found player data
            if (data.data) {
              const playerData = data.data.FunctionResult;

              
              if (playerData) {
                playerData.animations = ['idle'];

                //weird fix accommodating for user data change
                let displayName = playerData.displayName
                playerData.displayName = {}
                playerData.displayName.displayName = displayName
                
                //get new spawn pos
                let spawnPos = GetSpawnPos();
                let newChar
                if (clone) {
                  let displayName = typeof playerData.displayName === 'object' ? playerData.displayName.displayName : playerData.displayName
                  displayName += "-clone:" +(userClones.length+1)
                  newChar = new Character(playerData);
                  userClones.push(newChar)
                  characters[id+'-clone:'+userClones.length] = newChar
                  //get index of spawn pos for cleanup later
                  characters[id+'-clone:'+userClones.length].spawnVal = spawnPos[0]
                }
                else {
                  newChar = new Character(playerData);
                  characters[id] = newChar;
                  //get index of spawn pos for cleanup later
                  characters[id].spawnVal = spawnPos[0]
                }
                
                newChar.spawn(id, '', spawnPos[1].x, spawnPos[1].z, scene);
              }
            }
            
            
          })
          .catch(function (err) {
            console.log('no existing character found by that id, just move past it')
            return console.log(err);
          })
      }
    }

    function RemoveGuestChar(id, cloneNum) {
      if (id == 'disconnect') {
        let ids = Object.keys(characters)
        for (let i = 0; i < ids.length; i++) {
          const id = ids[i];
          if (id != user.playFabId && characters[id]) {
            characters[id].body.traverse((child) => {
              // Test if it's a mesh
              if (child instanceof THREE.Mesh) {
                child.geometry.dispose();

                // Loop through the material properties
                for (const key in child.material) {
                  const value = child.material[key];

                  // Test if there is a dispose function
                  if (value && typeof value.dispose === 'function') {
                    value.dispose();
                  }
                }
              }
            });

            scene.remove(characters[id].body);
            scene.remove(characters[id].bubbleTarget)
            //clear out occupied spawn pos          
            delete charPos[characters[id].spawnVal]
            delete characters[id];
          }
        }

        charPos.length = 0

        //remove room totem
        DestroyTotem('room');
      }
      //if other id is passed, remove just that one
      else if (id) {
        if (id != user.playFabId || cloneNum) {
          let charId = cloneNum ? id+'-clone:'+cloneNum : id 
          if (cloneNum && userClones[cloneNum-1]) {
            delete userClones[cloneNum-1]
            userClones.flat()
          } 


          characters[charId].body.traverse((child) => {
            // Test if it's a mesh
            if (child instanceof THREE.Mesh) {
              child.geometry.dispose();

              // Loop through the material properties
              for (const key in child.material) {
                const value = child.material[key];

                // Test if there is a dispose function
                if (value && typeof value.dispose === 'function') {
                  value.dispose();
                }
              }
            }
          });

          scene.remove(characters[charId].body);
          //clear out occupied spawn pos          
          charPos[characters[charId].spawnVal] = null
          delete characters[charId]
        }
      }
      //if no id then remove everyone except user (like when leaving a room)
      
    }

    const charPos = []
    function GetSpawnPos () {
      if (charPos.length > 0) {
        for (let i = 0; i < charPos.length; i++) {

          if (!charPos[i])
          {
            //alternate for odd/even
            // let xVal = i % 2 == 0 ? -1 : 1
            // let zVal = i % 2 == 0 ? -1.5 : 0.5 //TODO: Fix alternating z pos
            // charPos[i] = new THREE.Vector3(1.25 * (Math.max(1,Math.floor(i/2))) * xVal, 0, zVal)
            charPos[i] = charPositions[i].pos
            return [i, charPos[i]]
          }
          
        }
        //didn't find an open spot in existing array, add new one
        //alternate for odd/even
        // let xVal = charPos.length % 2 == 0 ? -1 : 1
        // let zVal = zTracker == 2 ? -1.5 : 0.5
        // if (zTracker == 2) {
        //   zTracker = 0
        // }
        // else {
        //   zTracker++
        // }
        // charPos.push(new THREE.Vector3(1.25 * (Math.max(1,Math.floor(charPos.length/2)+1)) * xVal, 0, zVal))
        charPos.push(charPositions[charPos.length])
        return [charPos.length - 1, charPos[charPos.length-1].pos]
      }
      else {
        charPos[0] = charPositions[0]
        return [0, charPos[0].pos]
      }
      
    }

    function CleanUpTotems() {
      //cache number of totems
      let numTotems = Object.keys(totems).length
      for (let i = 0; i < numTotems; i++) {
        const id = Object.keys(totems)[i];
        console.log('cleaning up totem: ', id, "totems before: ", totems)
        
        intervalManager(id, false)
        if (id != 'room') DestroyTotem(id);
        
      }

      for (let key in totems) {
        if (totems.hasOwnProperty(key) && key !== 'room') {
          delete totems[key];
        }
      }
      
      console.log('totems after: ', totems)

    }

    //not running for all totems in array
    function DestroyTotem(id) {
      if (totems[id]) {
        clearInterval(totems[id].interval);

        totems[id].totem.traverse((child) => {
          // Test if it's a mesh
          if (child instanceof THREE.Mesh) {
            child.geometry.dispose();

            // Loop through the material properties
            for (const key in child.material) {
              const value = child.material[key];

              // Test if there is a dispose function
              if (value && typeof value.dispose === 'function') {
                value.dispose();
              }
            }
          }
        });
        scene.remove(totems[id].totem);

        //delete totems[id];
      }
    }

    function GetMainCharacter() {
      return user.characters?.[0].CharacterId;
    }

    function ChangeAnimation(userid, animation, oneshot) {
      characters[userid].startAnimation(animation, oneshot);
    }

    function ChangeExpression(userid, expression) {
      characters[userid].changeExpression(expression);

      if (userid == user.playFabId) {
        CheckTutorialStep('UpdatedExpression');
      }
    }

    function OneShotManager(userid, _listener) {
      // characters[userid].startAnimation();
      // characters[userid].mixer.removeEventListener('finished', _listener, true);
      characters[userid].currentlyAnimating = false
    }

    //===========================
    //
    //     Totems
    //
    //===========================

    //totem obj
    // const totems = {}

    function BuildTotem(id, imgUrl, voteId, position) {
      console.log('building totem ' + id)
      if (id == 'room' || characters[id]) {
        let scale = 0.45;

        if (!position) {
          position = new THREE.Vector3(
            characters[id].body.position.x + 0.5,
            characters[id].body.position.y + 1.5,
            characters[id].body.position.z
          );

          scale = 0.3;
        }

        gltfLoader.load(modelPath + 'totem.glb', (model) => {
          let totem = model.scene;
          totem.name = id + ' totem';
          totem.position.set(position.x, position.y - 10.5, position.z + 0.5);
          totem.scale.set(scale, scale, scale);
          scene.add(totem);
          // totem.visible = false

          totems[id] = {
            id: id,
            totem: totem,
            interval: null,
            rotationTimer: 0
          };

          buildTotemPanel(id, voteId, RotateTotem);

          //if we already have the img url, add it on immediately
          if (imgUrl) setTotemImage(id, imgUrl);

          AnimateVoteTotem(id, true);
        });
      }
    }

    function RotateTotem(id, forward) {
      if (totems[id] && totems[id].totem.flipped != forward) {
        
        totems[id].totem.flipped = forward
        
        gsap.to(totems[id].totem.rotation, {
          y: forward ? 3 : 0,
          duration: 0.5,
          ease: 'power1.out'
        });

        intervalManager(id, forward);
      } 
      // else {
      //   intervalManager(id, false);
      // }

      if (forward && (mediaQueryState == 'mobilePortrait' || mediaQueryState == 'mobileLandscape')) {
        document.getElementById('totemMobileButton').style.display = 'block'
        document.getElementById('totemMobileButton').style.pointerEvents = 'all'
      }
      else {
        document.getElementById('totemMobileButton').style.display = 'none'
        document.getElementById('totemMobileButton').style.pointerEvents = 'none'
      }
    }

    function AnimateVoteTotem(id, forward) {
      gsap.to(totems[id].totem.position, {
        y: forward ? -1 : -10,
        duration: 2,
        ease: CustomEase.create(
          'custom',
          'M0,0 C0,0 0.025,0.193 0.05,0.32 0.072,0.436 0.078,0.474 0.104,0.581 0.124,0.664 0.14,0.706 0.168,0.78 0.191,0.841 0.202,0.862 0.238,0.918 0.262,0.956 0.29,0.985 0.328,1.01 0.362,1.032 0.372,1.047 0.418,1.048 0.506,1.048 0.524,0.964 0.682,0.964 0.758,0.964 0.851,0.97 0.884,0.984 0.92,1 1,1 1,1 '
        )
      });
    }

    function intervalManager(id, flag) {
      if (typeof flag != 'undefined' && totems[id]) {
        //reset timer
        totems[id].rotationTimer = 5;
        if (!totems[id].interval) {
          totems[id].interval = setInterval(function () {
            //make sure id still exists first!!
            if (!totems[id]) {
              clearInterval(totems[id].interval);
              return
            }
            
            if (totems[id].rotationTimer > 0) {
              totems[id].rotationTimer -= 1;
            } else {
              clearInterval(totems[id].interval);
              totems[id].interval = null;
              if (totems[id].totem.rotation.y > 0) {
                RotateTotem(id, false);
              }
            }
          }, 1000);
        }
        
      } else {
        if (totems[id]) {
          clearInterval(totems[id].interval);
          totems[id].interval = null;
        }
      }
    }

    // function PrepInitialControlPops (charProps) {

    //     let props = {}

    //     Object.values(charProps.customizations).forEach(part => {

    //         Object.keys(part).forEach(val => {
    //             console.log(val)
    //             switch (val) {
    //                 case "headSize":
    //                     props[val] = getRatio(part[val], headSizeProp.min, headSizeProp.max)
    //                     break;
    //                 case "neckLength":
    //                     props[val] = getRatio(part[val], neckLengthProp.min, neckLengthProp.max)
    //                     break;
    //                 case "bodySize":
    //                     props[val] = getRatio(part[val], bodySizeProp.min, bodySizeProp.max)
    //                     break;
    //                 case "bodyColor":
    //                     props["hue"] = getRatio(part[val].h, bodyColorProp.hMin, bodyColorProp.hMax)
    //                     props["sat"] = getRatio(part[val].s, bodyColorProp.sMin, bodyColorProp.sMax)
    //                     props["value"] = getRatio(part[val].v, bodyColorProp.vMin, bodyColorProp.vMax)
    //                     break;
    //                 case "handSize":
    //                     props[val] = getRatio(part[val], handSizeProp.min, handSizeProp.max)
    //                     break;
    //                 case "legLength":
    //                     props[val] = getRatio(part[val], legLengthProp.min, legLengthProp.max)
    //                     break;
    //                 case "feetSize":
    //                     props[val] = getRatio(part[val], feetSizeProp.min, feetSizeProp.max)
    //                     break;
    //                 default:
    //                     break;
    //             }

    //         })

    //     })

    //     setInitialVal(props)
    // }

    // function GetOwnedItems () {
    //     fetch('https://' + titleId + '.playfabapi.com/Client/GetUserInventory', {
    //         method: 'POST',
    //         headers: {
    //             'Accept' : 'text/plain',
    //             'Content-Type': 'application/json',
    //             'X-Authorization' : user.playFabSessionToken
    //         },
    //         // body:  JSON.stringify({
    //         //     "CharacterId": user.characters[0].CharacterId
    //         // })
    //     })
    //     .then(function(response){
    //         if (response.status >= 400 && response.status < 600) {
    //             response.json().then(function(object) {
    //                 alert(object.errorMessage);
    //             })
    //         } else if (response.status === 200) {
    //             return response.json()
    //         }

    //     })
    //     .then(data => {

    //         if (Object.keys(data.data.Inventory).length != 0) {

    //             let inventory = [];
    //             let items = data.data.Inventory

    //             for (let i = 0; i < items.length; i++) {

    //                 //check if it's an item, it will have it's id delineated with a bar
    //                 if (items[i].ItemId.includes('|')) {
    //                     const item = parseCatalogItem(items[i])

    //                     if (item.type !== 'head') {
    //                         inventory.push(item)
    //                     }
    //                 }

    //             }

    //             user.inventory = new Catalog()

    //             inventory.forEach(item => {
    //                 // let name = {}
    //                 // name[item.name] = () => {characters[user.characters[0].CharacterId].replacePart(item.type, item.id)}
    //                 // gui.add(name, item.name)

    //                 user.inventory[item.type].push(item)

    //             });

    //             // Object.keys(user.inventory).forEach(element => {
    //             //     let el = document.createElement('a')
    //             //     // let price = element.price == 0 ? "Free" : element.price + 'DB';
    //             //     let textnode = document.createTextNode(element + ' - ' + user.inventory[element].length)

    //             //     el.appendChild(textnode)
    //             //     // el.appendChild(document.createTextNode('\u2718'));
    //             //     el.onclick = function() {
    //             //         CategoryStartSpawn(element, user.inventory)
    //             //     }

    //             //     document.getElementById('inventoryContainer').appendChild(el)
    //             //     let linebreak = document.createElement("br");
    //             //     document.getElementById('inventoryContainer').appendChild(linebreak)
    //             // });

    //             // let save = {}
    //             // save["Save Character!"] = () => { SaveCharacter() }
    //             // gui.add(save, "Save Character!")

    //             // itemPool = user.inventory

    //             //now that we have the user's items, get catalog and filter out owned items
    //             // GetCatalogItems()

    //         }
    //         else {
    //             console.log("no character item data, drop in the defaults")
    //         }

    //     }).catch(function (err) {

    //     //no existing session with this id
    //         return console.log(err);
    //     });
    // }

    // function modelLoader(url) {
    //     return new Promise((resolve, reject) => {
    //         gltfLoader.load(url, data=> resolve(data), null, reject);
    //     });
    // }

    // function parseCatalogItem(item) {

    //     //couldn't do this in a single line :(
    //     let price = 0
    //     if (item.VirtualCurrencyPrices && item.VirtualCurrencyPrices['DB']) {
    //         price = item.VirtualCurrencyPrices['DB']
    //     }

    //     let processedItem = {
    //         id : item.ItemId.split('|')[0],
    //         type: item.ItemId.split('|')[1],
    //         name : item.DisplayName,
    //         price : price
    //     }

    //     return processedItem
    // }

    /**
     * Sizes
     */
    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    };
    this.sizes = sizes;

    window.addEventListener('resize', () => {
      // Update sizes
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;

      // Update camera
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();

      // Update renderer
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    // let testX = -4;
    // document.addEventListener("keydown", onSpawnKey, false);
    // function onSpawnKey(event) {
    //     var keyCode = event.which;
    //     if (keyCode == 83) {
    //         // GetUserCharacter(user)
    //         // let testChar = new Character(charProps)
    //         // characters.push(testChar)
    //         // console.log(characters)
    //         // testChar.spawn("testID",testX,0,0)

    //         // testX++

    //         replaceColor()
    //     }
    //     if (keyCode == 68) {
    //         // let testChar2 = new Character(charProps2)
    //         // characters.push(testChar2)
    //         // // console.log(characters)
    //         // testChar2.spawn(testX,0,0)

    //         // testX++

    //         characters[user.characters[0].CharacterId].replacePart("body_acc", "body_jazz")
    //     }
    //     if (keyCode == 49) {
    //         // console.log(JSON.stringify(characters[0].customizations))
    //         characters[user.characters[0].CharacterId].startAnimation('robot')
    //     }
    // };
    /**
     * Camera
     */
    // Base camera
    // const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    // camera.position.set(0, 2.75, 3.5)
    // camera.rotation.set(-0.25, 0, 0)
    scene.add(camera);

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    getInitialSize(camera, renderer);
    // renderer.setSize(initSize.initWidth, initSize.initHeight)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    //screen resizer + change callback
    screenSizeListener(mediaQueryChange);

    
    function mediaQueryChange(state) {
      console.log('Changed screen to ' + state);
      // AddMessageToHistory(state + ': ' + window.innerWidth + ' x ' + window.innerHeight, 'admin')

      if (totems['room']) {
        totems['room'].totem.visible = true
      }

      switch (state) {
        case 'desktop':
          defaultCamPos = new THREE.Vector3(0, 4.22, 19);
          sectionSelectCamPos = new THREE.Vector3(0, 90, 0);
          
          //totem
          roomTotemPosition = new THREE.Vector3(5.8, 0, -3.4);
          totemArrowTarget.position.set(5.5, -1.6, -2.9);
          if (totems['room']) {
            gsap.to(totems['room'].position, {
              x: roomTotemPosition.x,
              z: roomTotemPosition.z,
              duration: 0.5
            });
          }
          break;

        case 'tabletLandscape':
          defaultCamPos = new THREE.Vector3(0, 4.5, 19);
          sectionSelectCamPos = new THREE.Vector3(0, 115, 0);
          //totem
          roomTotemPosition = new THREE.Vector3(1.23, -1, -7.75);
          totemArrowTarget.position.set(1.23, 0.4, -7.35);
          if (totems['room']) {
            gsap.to(totems['room'].position, {
              x: roomTotemPosition.x,
              y: roomTotemPosition.y,
              z: roomTotemPosition.z,
              duration: 0.5
            });
          }

          // camera.rotation.set(-0.15, 0, 0)

          break;

        case 'mobileLandscape':
          defaultCamPos = new THREE.Vector3(0, 9.1, 39);
          sectionSelectCamPos = new THREE.Vector3(0, 90, 0);
          // camera.rotation.set(-0.15, 0, 0)

          //totem
          roomTotemPosition = new THREE.Vector3(1.23, -1, -7.75);
          totemArrowTarget.position.set(1.23, -1, -7.35);
          if (totems['room']) {
            // gsap.to(totems['room'].position, {
            //   x: 1.23,
            //   y: -1,
            //   z: -7.75,
            //   duration: 0.5
            // });
            console.log(totems['room'])
            // totems['room'].totem.visible = false
          }

          break;

        case 'tabletPortrait':
          defaultCamPos = new THREE.Vector3(0, 5.8, 25.5);
          sectionSelectCamPos = new THREE.Vector3(0, 115, 0);
          //totem
          roomTotemPosition = new THREE.Vector3(1, -1, -10);
          totemArrowTarget.position.set(1, 0.4, -9.6);
          if (totems['room']) {
            gsap.to(totems['room'].position, {
              x: roomTotemPosition.x,
              y: roomTotemPosition.y,
              z: roomTotemPosition.z,
              duration: 0.5
            });
          }
          // camera.rotation.set(-0.15, 0, 0)

          break;
        case 'mobilePortrait':

          //set default pos of history
          if (!document.getElementById('messagesList').classList.contains('expanded')) {
            messageHistoryPOS = document.getElementById('videoContainer').offsetHeight
            document.getElementById('messagesList').style.top = messageHistoryPOS + 'px' 
          }

          defaultCamPos = new THREE.Vector3(0, 8, 35);
          sectionSelectCamPos = new THREE.Vector3(0, 215, 0);
          // camera.rotation.set(-0.15, 0, 0)

          //totem
          roomTotemPosition = new THREE.Vector3(1.6, -1, -10);
          totemArrowTarget.position.set(1.5, 1.3, -9.6);
          selfTotemArrowTarget.position.set(0.5, 0.7, 0.5)
          if (totems['room']) {
            gsap.to(totems['room'].position, {
              x: roomTotemPosition.x,
              y: roomTotemPosition.y,
              z: roomTotemPosition.z,
              duration: 0.5
            });
          }
          // gsap.to(camera.position, {x: 1.8, y: 1.7, z: 3.2, duration: 0.5})

          break;
        default:
          break;
      }
    }

    //proto html declaration
    setTimeout(() => {
      //move message container inside canvas container at the end. This isn't a great way to do this
      document
        .getElementById('canvasContainer')
        .appendChild(document.getElementById('messageContainer'));
      document
        .getElementById('canvasContainer')
        .appendChild(document.getElementById('sectionSelectButton'));
        document
        .getElementById('canvasContainer')
        .appendChild(document.getElementById('dsktpExpandButton'));
      
      document
        .getElementById('joinCrowdButton')
        .addEventListener('click', function (event) {
          document
        .getElementById('joinCrowdButton').classList.add('disabled')

          CheckForUser();
          if (player) player.unMute();
        });
      //moved this to after video is loaded
      // document.getElementById('joinCrowdButton').style.opacity = 1;

      document
        .getElementById('tutorialButton')
        .addEventListener('click', function (event) {
          ActivateTut();
          TutDisplay(true);
        });

      document
        .getElementById('sectionSelectButton')
        .addEventListener('click', function (event) {
          sectionSelection();
        });
      document
        .getElementById('totemMobileButton')
        .addEventListener('click', function (event) {
          ProposeNewTotem()
        });
        this.totemMobileButton = document
        .getElementById('totemMobileButton')
      document
        .getElementById('messageContainer')
        .insertAdjacentHTML(
          'beforeend',
          '<div id="vibesHeader" class="point hidden"></div><div id="vibesLow" class="point hidden"></div><div class="point" id="message-init"><div id="shareBubble" class="point">Show link copied to clipboard!</div><div class="speak"><div id="inputContainer"><input id="messageInput" type="text" autocomplete="off"></input></div><div id="msgInput"><span id="msgInputPlaceholder" class="visible">...</span><span id="send-icons"><span id="showShare" class="icon">🛸</span><span id="emojiToggle" class="icon">🙂</span><span id="vibesToggle" class="icon">💓</span><i id="messageSend" class="icon fa fa-send"></i></span></div></div><div id="vibeContainer" class="hidden"><span>Sending<span><p id="vibeType"></p></div></div>'
        );
      initChatBtn = document.getElementById('message-init');
      document
        .getElementById('message-init')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          ChatInput(event.target.id, RecenterCamera);
          
        });
      document
        .getElementById('msgInputPlaceholder')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          ChatInput(event.target.id, RecenterCamera);
        });
      document
        .getElementById('messageSend')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          ChatInput(event.target.id, () => {/*don't move the cam on send, only open*/});
        });
      document
        .getElementById('vibesToggle')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          InitVibes(event.target.id);
        });
      document
        .getElementById('vibesLow')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          DisplayPackages(true);
        });
        document
        .getElementById('showShare')
        .addEventListener('click', function (event) {
          event.stopPropagation();

          if (mediaQueryState == 'desktop' || !navigator.share) {
            // Copy the text inside the text field
            navigator.clipboard.writeText(window.location.href);

            // Alert the copied text
            document.getElementById('shareBubble').style.display = 'block'
            
            setTimeout(() => {
              document.getElementById('shareBubble').style.top = '-30px'
            }, 100);
            setTimeout(() => {
              document.getElementById('shareBubble').style.top = '30px'
            }, 2500)
             setTimeout(() => {
              document.getElementById('shareBubble').style.display = 'none'
            }, 3000)
          }
          else {
            const shareData = {
              title: `${targetArtist} - LIVE on frisson.live. Join the crowd!`,
              text: `Join me in the crowd at this LIVE virtual music festival!`,
              url: window.location.href,
            };
            navigator.share(shareData)
          }
           
        });
        // document.getElementById('historyExpand').addEventListener('click', function (event) {
        //   event.stopPropagation();
        //   console.log('detecting close tap')
        //   CollapseHistory();
        // });
        document.getElementById('expandButton').addEventListener('click', function (event) {
          event.stopPropagation()
          if (document.getElementById('messagesList').classList.contains('dsktpExpanded'))
          {
            document.getElementById('messagesList').classList.remove('dsktpExpanded')
          }
          else {
            if (document.getElementById('expandButton').className != 'dis') {
              if (document.getElementById('messagesList').className == 'expanded') {
                HistoryContract()
              }
              else {
                HistoryExpand()
              }
            }
          }
          
          

        })
        document.getElementById('dsktpExpandButton').addEventListener('click', function (event) {
          event.stopPropagation()
          // if (document.getElementById('expandButton').className != 'dis') {
          //   if (document.getElementById('messagesList').className == 'expanded') {
          //     document.getElementById('expandButton').style.opacity = 0
          //     HistoryContract()
          //   }
          //   else {
          //     document.getElementById('expandButton').style.opacity = 1
          //     HistoryExpand()
          //   }
          // }

          if (document.getElementById('messagesList').className == 'dsktpExpanded') {
            document.getElementById('messagesList').classList.remove('dsktpExpanded')
          }
          else {
            document.getElementById('messagesList').classList.add('dsktpExpanded')
          }
          

        })

      // document.getElementById('ccModalButton').addEventListener('click', function (event) {
      //   //clicked join on modal, start tutorial
      //   ActivateTut();
      //   TutDisplay(true);
      //   //hide modal
      //   document.getElementById('ccModal').classList.add('hidden')
      // })

      // document.querySelector('#ccModal .close').addEventListener('click', function (event) {
      //   //clicked close on modal, don't start tutorial
      //   document.getElementById('ccModal').classList.add('hidden')
      //   initChat(
      //       user,
      //       CharacterInit,
      //       GetGuestChar,
      //       LoadUsersInGroup,
      //       RemoveGuestChar,
      //       SectionSelectCameraMove,
      //       RoomSelectCameraMove,
      //       InRoomCameraMove,
      //       BuildTotem,
      //       CleanUpTotems
      //     );
      //     //document.getElementById('loginModal').style.display = 'none';

      //     InitTut('crowd-chat', GetUser(), [DisconnectUser]);
      //     CheckTutorialStep('JoinedCrowdChat');
      // })
      // Execute a function when the user presses a key on the keyboard
      document.addEventListener('keydown', function (event) {
        KeyDown(event)
      });

      let scrollPos = 0
      let scrollUpAmt = 0

      let scrollStart
      let historyScroll = false
      let scrollEnd
      let lastPos = 0
      let scrollDir = 0
      let scrollDiff = 0
      let topExpand
      let heightExpand
      let topContract
      let heightContract

      let scrollingUp = false
      let scrollUpInfluence = 0
      let scrollDownInfluence = 0
      
      document.getElementById('messagesList').addEventListener('scroll', (event) => {
          // MessageListScrollChecker(event)
      });
      document.getElementById('messagesList').addEventListener('touchstart', (event) => { 
        lastPos = event.touches[0].clientY
        scrollStart = event.touches[0].clientY
      })
      document.getElementById('messagesList').addEventListener('touchmove', (event) => {
        if (userConnection.room && !document.getElementById('messagesList').classList.contains('expanded')) {
          historyTouchMove(event)
        }
      });
      // document.getElementById('historyDragBar').addEventListener('touchmove', (event) => {
      //     historyTouchMove(event)
      // });
      
      function historyTouchMove (event) {
        // if (false && !historyScroll && !document.getElementById('messagesList').classList.contains('expanded')) {
        //   historyScroll = true
        //   scrollStart = event.touches[0].clientY
        //   lastPos = scrollStart
        // }
        // else {
        //   scrollEnd = event.touches[0].clientY

          if (scrollEnd - scrollStart > 20 )
          {
            console.log('scrolling up')
            scrollUpInfluence ++

            if (scrollUpInfluence > 15)
            {
              scrollingUp = true
              scrollUpInfluence = 0
              document.getElementById('expandButton').style.opacity = 1
            }
            
            
            // document.getElementById('messagesList').className = 'expanded'
            // document.getElementById('historyClose').style.display = 'block'
            // canExpand = false
          }
          else {
            console.log('scrolling down')
            scrollDownInfluence ++ 
            if (scrollDownInfluence > 15)
            {
              scrollingUp = false
              scrollDownInfluence = 0
              document.getElementById('expandButton').style.opacity = 0
            }
          }

        //   scrollDiff = (scrollEnd - lastPos)
        //   let mlTop = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))
        //   let mlHeight = document.getElementById('messagesList').offsetHeight 

        //   if (mlTop >= 0 && mlTop <= messageHistoryPOS) {
            
        //     let offset = mlTop - scrollDiff > 0 ? mlTop - scrollDiff : 0
        //     if (offset == 0) {
        //       // historyScroll = false
        //       document.getElementById('messagesList').classList.remove('expanded')
        //     }
        //     if (offset > messageHistoryPOS) {
        //       offset = messageHistoryPOS
        //       // historyScroll = false
        //       document.getElementById('messagesList').classList.remove('expanded')
        //     }
        //     document.getElementById('messagesList').style.top = offset + 'px'
        //   }
        //   if (mlHeight <= window.innerHeight && mlHeight >= 150 ) {
        //     let newHeight = mlHeight + (scrollDiff * 2) 
        //     if (newHeight > window.innerHeight) {
        //       newHeight = window.innerHeight
        //       // historyScroll = false
        //       document.getElementById('messagesList').classList.remove('expanded')
        //     }
        //     if (newHeight < 150) {
        //       newHeight = 150
        //       // historyScroll = false
        //       document.getElementById('messagesList').classList.remove('expanded')
        //       document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0)'
        //     }
        //     document.getElementById('messagesList').style.height = newHeight + 'px'
        //     let alpha = newHeight / window.innerHeight
        //     document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
        //   }

        //   lastPos = scrollEnd
        //   console.log(scrollDiff)
        // }

        scrollEnd = event.touches[0].clientY
        // MessageListScrollChecker(event)
      }

      function HistoryExpand() {
        //temp disable while expanding
        document.getElementById('expandButton').className = 'dis'

        document.querySelector('#expandButton i').style.transform = 'rotate(180deg)'
        topExpand = setInterval(function () {
          clearInterval(topContract)
          let top = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))

          if(top <= 0) {
            clearInterval(topExpand)
            document.getElementById('messagesList').className = 'expanded'
            // historyScroll = false
          }
          else {
            let newVal = top - 10 > 0 ? top - 10 : 0
            document.getElementById('messagesList').style.top = newVal + 'px'
          }
        }, 1)
        heightExpand = setInterval(function () {
          clearInterval(heightContract)
          let height = document.getElementById('messagesList').offsetHeight

          if(height >= window.innerHeight - 50) {
            clearInterval(heightExpand)
            document.getElementById('messagesList').className = 'expanded'
            // historyScroll = false
            document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0.9)'
            //reenable
            document.getElementById('expandButton').className = ''
          }
          else {
            document.getElementById('messagesList').style.height = height + 10 + 'px'
            let alpha = height / window.innerHeight
            document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
          }
        }, 1)
      }
      function HistoryContract() {
        document.querySelector('#expandButton i').style.transform = 'rotate(0deg)'
        document.getElementById('expandButton').style.opacity = 0
        //temp disable while expanding
        document.getElementById('expandButton').className = 'dis'

        let topContract = setInterval(function () {
          clearInterval(topExpand)
          let top = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))

          if(top >= messageHistoryPOS) {
            clearInterval(topContract)
            document.getElementById('messagesList').classList.remove('expanded')
          }
          else {
            let offset = top + 10 > messageHistoryPOS ? messageHistoryPOS : top + 10
            document.getElementById('messagesList').style.top = offset + 'px'
          }
        }, 1)
        let heightContract = setInterval(function () {
          clearInterval(heightExpand)
          let height = document.getElementById('messagesList').offsetHeight

          if(height <= 150) {
            clearInterval(heightContract)
            document.getElementById('messagesList').classList.remove('expanded')
            document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0)'
            document.getElementById('messagesList').scrollTo({
              top: document.getElementById('messagesList').scrollHeight,
              behavior: "smooth"
            })
            //reenable
            document.getElementById('expandButton').className = ''
          }
          else {
            let offset = height - 10 < 150 ? 150 : height - 10
            document.getElementById('messagesList').style.height = offset + 'px'
            let alpha = offset / window.innerHeight
            document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
          }
      }, 1)
      }
      //send over to tracked messages controller so truncated msgs can be expanded
      expandInit(HistoryExpand)

      document.addEventListener('touchend', (event) => {
        // if (false && historyScroll || document.getElementById('messagesList').classList.contains('expanded')) {
        //   if (!document.getElementById('messagesList').classList.contains('expanded')) {
        //     // historyScroll = false
  
        //     //scrolling down
        //     if (scrollDiff > 0) {
        //       topExpand = setInterval(function () {
        //         clearInterval(topContract)
        //         let top = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))
    
        //         if(top <= 0) {
        //           clearInterval(topExpand)
        //           document.getElementById('messagesList').className = 'expanded'
        //           // historyScroll = false
        //         }
        //         else {
        //           let newVal = top - 10 > 0 ? top - 10 : 0
        //           document.getElementById('messagesList').style.top = newVal + 'px'
        //         }
        //       }, 1)
        //       heightExpand = setInterval(function () {
        //         clearInterval(heightContract)
        //         let height = document.getElementById('messagesList').offsetHeight
    
        //         if(height >= window.innerHeight - 50) {
        //           clearInterval(heightExpand)
        //           document.getElementById('messagesList').className = 'expanded'
        //           // historyScroll = false
        //           document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0.9)'
        //         }
        //         else {
        //           document.getElementById('messagesList').style.height = height + 10 + 'px'
        //           let alpha = height / window.innerHeight
        //           document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
        //         }
        //       }, 1)
        //     }
        //     //scrolling up
        //     else if (scrollDiff < 0) {
  
        //     }
        //     //even
        //     else {
  
        //     }
            
        //   }
        //   else if (document.getElementById('messagesList').classList.contains('expanded')) {
  
        //     //scrolling down
        //     if (scrollDiff > 0) {
              
        //     }
        //     //scrolling up
        //     else if (scrollDiff < 0) {
        //       let topContract = setInterval(function () {
        //         clearInterval(topExpand)
        //         let top = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))
    
        //         if(top >= messageHistoryPOS) {
        //           clearInterval(topContract)
        //           document.getElementById('messagesList').classList.remove('expanded')
        //         }
        //         else {
        //           let offset = top + 10 > messageHistoryPOS ? messageHistoryPOS : top + 10
        //           document.getElementById('messagesList').style.top = offset + 'px'
        //         }
        //       }, 1)
        //       let heightContract = setInterval(function () {
        //         clearInterval(heightExpand)
        //         let height = document.getElementById('messagesList').offsetHeight
    
        //         if(height <= 150) {
        //           clearInterval(heightContract)
        //           document.getElementById('messagesList').classList.remove('expanded')
        //           document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0)'
        //           document.getElementById('messagesList').scrollTo({
        //             top: document.getElementById('messagesList').scrollHeight,
        //             behavior: "smooth"
        //           })
        //         }
        //         else {
        //           let offset = height - 10 < 150 ? 150 : height - 10
        //           document.getElementById('messagesList').style.height = offset + 'px'
        //           let alpha = offset / window.innerHeight
        //           document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
        //         }
        //       }, 1)
              
        //     }
        //     //even
        //     else {
  
        //     }
        //   }
        //   historyScroll = false
        // }
        // lastPos = 0
      })

      function MessageListScrollChecker (event) {
        let prevScroll = scrollPos
          
        if (event.target.scrollTopMax != 0)
        {
          scrollPos = event.target.scrollTopMax - event.target.scrollTop
        }
        
        console.log('scroll prev: ' + prevScroll)
        console.log('scroll pos: ' + scrollPos)
        if (prevScroll < scrollPos) {
          console.log("scrolling up")
          scrollUpAmt++
        } 
        else if (prevScroll > scrollPos){
          console.log("scrolling down")
          scrollUpAmt = 0
        }
        console.log('scrolled ' + scrollUpAmt)
        if (scrollUpAmt > 2)
        {
          // if (!document.getElementById('messagesList').classList.contains('expanded') && canExpand)
          // {
          //   console.log('trying to expand')
          //   document.getElementById('messagesList').className = 'expanded'
          //   document.getElementById('historyClose').style.display = 'block'
          // }
        }
          
          
      }

      function KeyDown (event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === 'Enter') {
          // Cancel the default action, if needed
          event.preventDefault();
          if (
            document
              .getElementById('message-init')
              .classList.contains('active') &&
            document.getElementById('messageInput').value != ''
          ) {
            // Trigger the button element with a click
            SendMessage();
          } else if (
            !document
              .getElementById('message-init')
              .classList.contains('active')
          ) {
            event.stopPropagation();
            ChatInput(event.target.id, () => {/*don't move the cam on send, only open*/});
          }
        } else if (
          event.key === 'Escape' &&
          document.getElementById('message-init').classList.contains('active')
        ) {
          ChatInput();
        }
      }

      this.keyDown = KeyDown

      const trigger = document.querySelector('#emojiToggle');

      //init emoji keyboard
      const picker = new createPopup({
        theme: 'picmo__dark',
        showVariants: false,
        position: 'bottom',
        showPreview: false,
        visibleRows: 5,
        emojiSize: '1.8rem'
      },
      {
        referenceElement: trigger,
        onPositionLost: 'close'
      }
      );

      

      picker.addEventListener('emoji:select', (selection) => {
        document.querySelector('#messageInput').value += selection.emoji;
      });

      trigger.addEventListener('click', () => {
        picker.toggle();
        trigger.classList.toggle('selected');
      });

      picker.addEventListener('picker:close', selection => {
        trigger.classList.remove('selected');
    }); 

      // window.addEventListener('click', (event)=> {
      //   CheckOutsideTap(event)
      // }) 

      // window.addEventListener('touchstart', (event)=> {
      //   CheckOutsideTap(event)
      // })

      function CheckOutsideTap (event) {
        if(document.getElementById('message-init').style.display != 'none' && document.getElementById('messageInput').classList.contains('visible') && event.target != document.getElementById('messageInput') && !event.target.classList.contains('emoji-picker__wrapper') && !event.target.classList.contains('emoji-picker__emoji')) {
          ChatInput()
        }
      }

      initChatBtn.style.display = 'none';
      this.initChatBtn = initChatBtn;

      setupUI(scene, camera, renderer, canvas);

      // InitTut('crowd-chat', GetUser(), [DisconnectUser]);

      
    }, 2000);

    //bandaid for fixing yt api and react
    //please fix me some day
    // setTimeout(() => {
    //   if (document.getElementById('streamContainer')?.tagName == "DIV")
    //   {
    //     window.location.reload()
    //   }
    // }, 1900)

    //collapse message history
    function CollapseHistory () {
      console.log('should close')
      canExpand = false
      document.getElementById('messagesList').className = ''
      document.getElementById('historyClose').style.display = 'none'
      
      setTimeout(() => {
        document.getElementById('messagesList').scrollTo({
        top: document.getElementById('messagesList').scrollHeight,
        behavior: "smooth"
      });
      canExpand = true
      }, 2000);

    }

    //Drag Controls
    dragControls(canvas, camera, null);

    this.mount.appendChild(this.renderer.domElement);
    this.start();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.promptUnload);
    window.removeEventListener('unload', this.handleUnload);
    window.removeEventListener('pagehide', this.promptUnload);
    window.removeEventListener('pagehide', this.handleUnload);
    
    clearInterval(updateLocationInterval)

    this.stop();
    this.mount.removeChild(this.renderer.domElement);

    // window.removeEventListener('storage', this.checkStorage);

    document.removeEventListener('keydown', function (event) {
      this.keyDown(event)
    });

    // Traverse the whole scene
    this.scene.traverse((child) => {
      // Test if it's a mesh
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();

        // Loop through the material properties
        for (const key in child.material) {
          const value = child.material[key];

          // Test if there is a dispose function
          if (value && typeof value.dispose === 'function') {
            value.dispose();
          }
        }
      }
    });
    // this.renderer.instance.dispose()
    this.eventsDispose();

    DisconnectUser();
  }

  start() {
    const clock = new THREE.Clock();
    let previousTime = 0;

    if (!this.frameId) {
      this.frameId = requestAnimationFrame(() => {
        this.animate(clock, previousTime);
      });
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  animate(clock, previousTime) {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;
    //update all character animation mixers
    for (const [id, char] of Object.entries(this.characters)) {
      if (char.currentAnim) char.mixer.update(deltaTime);
    }
    this.annie.update(deltaTime * 1000);

    //update positions for all visible bubbles

    let size = new THREE.Vector2(0, 0);
    this.renderer.getSize(size);

    for (const bubble of Object.entries(bubbles)) {
      //returns array, first is id, second is bubble obj
      if (bubble[1].element && this.characters[bubble[0]]?.body && this.characters[bubble[0]].bubbleTarget) {
        //get head pos of char
        // const headPos = new Vector3(this.characters[bubble[0]].body.position.x, this.characters[bubble[0]].body.position.y - this.characters[bubble[0]].headOffset, this.characters[bubble[0]].body.position.z)

        let headPos = new THREE.Vector3();
        Object.values(
          this.characters[bubble[0]].bubbleTarget.getWorldPosition(headPos)
        );
        const screenPosition = headPos.clone();
        screenPosition.project(this.camera);
        const translateX = screenPosition.x * size.x * 0.5 + 20;
        //55px is the overall size of the bubble element + the chat element
        const translateY = -screenPosition.y * size.y * 0.5 - 100;

        bubble[1].element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
      }
    }

    //update position of init chat button
    if (this.initChatBtn && this.characters[this.user?.playFabId]?.bubbleTarget) {
      //get head pos of char

      // const headPos = new Vector3(Object.values(this.characters)[0].body.position.x, Object.values(this.characters)[0].body.position.y - Object.values(this.characters)[0].headOffset, Object.values(this.characters)[0].body.position.z)

      let headPos = new THREE.Vector3();
      this.characters[this.user?.playFabId].bubbleTarget.getWorldPosition(headPos);
      // headPos.y -= Object.values(this.characters)[0].headOffset - Object.values(this.characters)[0]

      const screenPosition = headPos.clone();
      // const screenPosition = this.spinner.position.clone()
      screenPosition.project(this.camera);

      const translateX = screenPosition.x * size.x * 0.5;
      //53px is the overall size of the bubble element
      const translateY = -screenPosition.y * size.y * 0.5 - 33;

      // console.log(translateY)
      if (translateX > -window.innerWidth/2 && translateX < window.innerWidth/2 - this.initChatBtn.offsetWidth )
      {
        this.initChatBtn.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
      }
      
    }

    //update position 
    // if (this.roomTotem) {
    //   let totemPos = new THREE.Vector3()
    //   console.log(this.roomTotem)
    //   this.roomTotem.getWorldPosition(totemPos)

    //   const screenPosition = totemPos.clone();
    //   // const screenPosition = this.spinner.position.clone()
    //   screenPosition.project(this.camera);

    //   const translateX = screenPosition.x * size.x * 0.5;
    //   //53px is the overall size of the bubble element
    //   const translateY = -screenPosition.y * size.y * 0.5 - 33;

    //   this.totemMobileButton.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
    // }


    let objPos = new THREE.Vector3();
    if (
      this.arrowData.arrow1.obj &&
      this.arrowTargets[this.arrowData.arrow1.obj]
    ) {
      let translateX;
      let translateY;

      if (
        this.arrowData.arrow1.obj != 'bubble' &&
        this.arrowData.arrow1.obj != 'vibe' &&
        this.arrowData.arrow1.obj != 'join'
      ) {
        Object.values(this.arrowTargets[this.arrowData.arrow1.obj].getWorldPosition(objPos))
          const screenPosition = objPos.clone()
          screenPosition.project(this.camera)
          translateX = screenPosition.x * size.x * 0.5
          //55px is the overall size of the bubble element + the chat element
          translateY = - screenPosition.y * size.y * 0.5
      } else {
        if (this.arrowData.arrow1.obj == 'bubble' && document
        .getElementById('message-init')) {
          translateX = document
            .getElementById('message-init')
            .getBoundingClientRect().x;
          translateY =
            document.getElementById('message-init').getBoundingClientRect().y -
            45 -
            document.getElementById('message-init').getBoundingClientRect()
              .height /
              2;
        } else if (
          this.arrowData.arrow1.obj == 'vibe' &&
          document.getElementsByClassName('vibesMenu ')[0]
        ) {
          translateX =
            document
              .getElementsByClassName('vibesMenu ')[0]
              .getBoundingClientRect().x +
            document
              .getElementsByClassName('vibesMenu ')[0]
              .getBoundingClientRect().width - 60;
          translateY =
            document
              .getElementsByClassName('vibesUI--header ')[0]
              .getBoundingClientRect().y +
            document
              .getElementsByClassName('vibesUI--header ')[0]
              .getBoundingClientRect().height /
              2;
        } else if (this.arrowData.arrow1.obj == 'join') {
          translateX = document
            .getElementById('joinCrowdButton')
            .getBoundingClientRect().x;
          translateY =
            document.getElementById('joinCrowdButton').getBoundingClientRect()
              .y -
            45 -
            document.getElementById('joinCrowdButton').getBoundingClientRect()
              .height /
              2;
        }
        // translateX = -30
        // translateY = 185
      }

      let arrow = document.getElementById('tutArrow-1')
      // arrow.className = "bounce " + arrowData.arrow1.arrow1Pos

      if (arrow) {
          arrow.style.transform = `translateX(${translateX}px) translateY(${translateY}px)` 
      }
    }
    if (
      this.arrowData.arrow2.obj &&
      this.arrowTargets[this.arrowData.arrow2.obj]
    ) {
      Object.values(
        this.arrowTargets[this.arrowData.arrow2.obj].getWorldPosition(objPos)
      );
      const screenPosition = objPos.clone();
      screenPosition.project(this.camera);
      const translateX = screenPosition.x * size.x * 0.5;
      //55px is the overall size of the bubble element + the chat element
      const translateY = -screenPosition.y * size.y * 0.5;

      let arrow = document.getElementById('tutArrow-2');
      // arrow.className = "bounce " + arrowData.arrow1.arrow1Pos

      if (arrow) {
        arrow.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
      }
    }

    //message history messages positioning
    //get all visible messages
    var visMessages = document.querySelectorAll('.historyMessage.visible p'), i

    //define min max movement of message
    //min = 0 | max = window total width - width of el
    //min -15 | max 15
    

    for (let i = 0; i < visMessages.length; i++) {
      const mes = visMessages[i];
      //find id of user that said it
      let id = mes.dataset.id

      if (this.characters[id]) {
        
        
        //find user char in char array and get x pos
        let xPos = this.characters[id].body.position.x
        let distDif = xPos - this.camera.position.x

        let rat = getRatio(distDif, -15, 15)
        let mesPos = getVal(rat, 0, document.getElementById('messagesList').offsetWidth - mes.offsetWidth)
        mes.style.transform = 'translateX('+mesPos+'px)'
      }
    }

    ThreeMeshUI.update();

    

    this.renderScene();
    this.frameId = window.requestAnimationFrame(() => {
      this.animate(clock, previousTime);
    });
  }

  renderScene() {
    this.renderer.render(this.scene, this.camera);
  }


  render() {

    return (
      <div>
        <Loading />
        <CCModal />
        <Loader />
        <div id="ccContainer">
          <div id="joinCrowdOverlay">
              <input
              id="joinCrowdButton"
              class="ui"
              type="button"
              value="Join the Crowd!"
            ></input>
          </div>
          <div id="videoContainer">
            <div class="streamBanner">
              <img class="bannerLogo" src={logo}></img>
            </div>
            <div id="streamContainer"></div>
            <div class="streamBanner">
              <img class="bannerLogo" src={logo}></img>
            </div>
          </div>

          <div
            id="canvasContainer"
            ref={(mount) => {
              this.mount = mount;
            }}
          >
            
          </div>
          <img id="sectionSelectButton" src={exitSign}></img>
          <div id="dsktpExpandButton"><i class="fa fa-history" aria-hidden="true"></i></div>
          <div id="totemMobileButton" class="point"></div>
          
          <div id="messagesList">
            <div id="expandButton"><i class="fa fa-external-link-square" aria-hidden="true"></i></div>
          </div>
          <div id="messageContainer">
          </div>
          
          
          <input class="hidden" id="tutorialButton" type="button" value="?"></input>

          <input id="totemInput" type="file" style={{ display: 'none' }}></input>
          {/* <div id="loginModal">
            <LoginForgotPasswordRegister />
          </div> */}
          <DynamicMenuModernized position={3} direction="top" />
          <VibesMenu />
        </div>
      </div>
      
    );
  }
}

// const rootElement = document.getElementById("root")
// ReactDOM.render(<DressingRoom />, rootElement);
export default CrowdChat;
