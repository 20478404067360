import React, { useEffect, useState } from 'react';
import './styles.scss';
import {Col, Container, Row} from 'react-bootstrap';
import FooterFinishProducerSetup from '../../components/footer-finish-producer-setup';
import SelectCustom from '../../components/SelectCustom';
import { genres, pronounsProducer } from '../../shared/defaultValue';
import { useDispatch, useSelector } from 'react-redux';
import {updateProfilePreferences} from '../../slices/user';
import {isSuccessAuth} from '../../selectors/user.selector';
import { useHistory } from 'react-router-dom';
import {isLoading} from '../../selectors/user.selector';
import { SpinnerOverlay } from '../../components/spinner-overlay';
import { useFormik } from 'formik';

// validate
import {ValidationRegisterSchemaProducer} from '../../shared/validationSchema/registerValidate';
import moment from 'moment/moment';
import { FORMAT_DATE_TIME } from '../../shared/utils/constants';
import InputFormik from '../../components/input-formik';
import Select from 'react-select';

const FinishProducerSetup = () => {
  const getEmailArtistStore    = useSelector((state) => state?.user?.emailForArtist);
  const getNameArtistStore     = useSelector((state) => state?.user?.nameForArtist);
  const getPasswordArtistStore = useSelector((state) => state?.user?.passwordForArtist);
  const [urlFinishSetup, setUrlFinishSetup] = useState('');
  const [nameArtist, setNameArtist] = useState('');
  const [passwordArtist, setPasswordArtist] = useState('');
  const loading = useSelector(isLoading);


  useEffect(() => {
    if (getEmailArtistStore !== undefined) {
      setUrlFinishSetup(getEmailArtistStore);
    }
    if (getNameArtistStore !== undefined) {
      setNameArtist(getNameArtistStore)
    }
    if (getPasswordArtistStore !== undefined) {
      setPasswordArtist(getPasswordArtistStore)
    }

    if (window.location.search !== '') {
      fetch(process.env.REACT_APP_SERVER_URL + '/users/getEmail/' + window.location.search.slice(6))
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setUrlFinishSetup(data.data.response);
        });
    }
  }, []);

  const [getEmailArtist, setGetEmailArtist] = useState('');
  let history = useHistory();
  const getIsSuccessAuth = useSelector(isSuccessAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    setGetEmailArtist(localStorage.getItem('email'));
  }, []);

  useEffect(() => {
    formik.setFieldValue(
      'email',
      `${urlFinishSetup}`
    );
  }, [urlFinishSetup]);

  useEffect(() => {
    formik.setFieldValue(
      'username',
      `${nameArtist}`
    );
  }, [nameArtist]);

  useEffect(() => {
    formik.setFieldValue(
      'password',
      `${passwordArtist}`
    );
    formik.setFieldValue(
      'confirmPassword',
      `${passwordArtist}`
    );
  }, [passwordArtist]);

  useEffect(() => {
    getIsSuccessAuth && history.push('/profile');
  }, [getIsSuccessAuth]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(
      'birthdate',
      moment(value).format(FORMAT_DATE_TIME.MM_DD_YYYY)
    );
  };
  const formik = useFormik({
    initialValues: {
      email: urlFinishSetup ? `${urlFinishSetup}` : '',
      password: passwordArtist ? `${passwordArtist}` : '',
      confirmPassword: '',
      username: nameArtist ? `${nameArtist}` : '',
      preferred_pronoun: '',
      music_genres: [],
      birthdate: ''
    },
    validationSchema: ValidationRegisterSchemaProducer,
    onSubmit: (values) => {
      const genres = values?.music_genres?.map((item) => item.value);
      const birthdateDate = moment(values?.birthdate).format(FORMAT_DATE_TIME.MM_DD_YYYY).replaceAll("/","-");
      setTimeout(() => {
        let newForm = {
          ...values,
          music_genres: genres.toString(),
          birthdate: birthdateDate.toString(),
        };
        delete newForm.confirmPassword;
        console.log('newForm--->', newForm);
        dispatch(updateProfilePreferences(newForm));
      }, 1000)


    }
  });
  const thisDay = moment(new Date()).format('YYYY-MM-DD').replaceAll("/","-");
  console.log('formik', formik);
  return (
    <>
      <SpinnerOverlay loading={loading} />
      <form onSubmit={formik.handleSubmit}>
        <Container
          style={{
            height: '100vh',
            display: 'grid',
            alignContent: 'space-evenly'
          }}
          className='ml--20 mr--20 container-class content-finish-producer-setup'
          fluid='xll'
        >
          <Row>
            <Col xs={12} md={12}>
              <h3 style={{ color: '#fff', textAlign: 'center' }}>
                Update your accounts preferences
              </h3>
            </Col>
          </Row>
          <Row>
            <Col lg={6} sm={6} xs={12} md={6} className='pl--50 pr--50'>
              <div>
                <label style={{ width: '100%' }}>
                  Email on file:
                  {urlFinishSetup ? <InputFormik
                    className='input-style-txt'
                    type='email'
                    name='email'
                    id='email'
                    readOnly
                    icon="fa-envelope-o"
                    formik={formik}
                    value={formik?.values?.email}
                  /> : <InputFormik
                    icon="fa-envelope-o"
                    className='input-style-txt'
                    name='email'
                    id='email'
                    formik={formik}
                    placeholder="Email address"
                  />}
                </label>
              </div>
              <div>
                <label style={{ width: '100%' }}>
                  Password:
                  {passwordArtist ? <InputFormik
                    icon="fa-lock"
                    className='input-style-txt'
                    type='password'
                    name='password'
                    id='password'
                    formik={formik}
                    placeholder="Password"
                    value={formik?.values?.password}
                  /> : <InputFormik
                      icon="fa-lock"
                      className='input-style-txt'
                      type='password'
                      name='password'
                      id='password'
                      formik={formik}
                      placeholder="Password"
                  />}
                </label>
              </div>
              <div>
                <label style={{ width: '100%' }}>
                  Preferred Pronoun
                  <Select
                    classNamePrefix="select"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        background: '#fff',
                        borderColor:
                          formik.touched['preferred_pronoun'] && formik.errors['preferred_pronoun']
                            ? '#dc3545'
                            : '#9e9e9e',
                        color: '#696c79',
                        padding: '0 10px',
                        fontFamily: "'Futura', Arial, Helvetica, sans-serif",
                        height: 46,
                        minHeight: 46
                      }),
                      input: (provided, state) => ({
                        ...provided,
                        margin: '0px',
                      }),
                      valueContainer: (provided, state) => ({
                        ...provided,
                        height: '46px',
                        padding: '0 6px'
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontFamily: "'Futura', Arial, Helvetica, sans-serif",
                        height: 46,
                        minHeight: 46,
                        margin: '0px'
                      }),
                      option: (base) => ({
                        ...base,
                        fontSize: '14px',
                        fontFamily: "'Futura', Arial, Helvetica, sans-serif",
                        color: '#000',
                      }),
                      indicatorSeparator: (state) => ({
                        display: 'none'
                      }),
                      indicatorsContainer: (provided, state) => ({
                        ...provided
                      })
                    }}
                    name="preferred_pronoun"
                    onChange={(opt, e) => {
                      formik.setFieldValue('preferred_pronoun', opt.value);
                    }}
                    placeholder="Pronouns"
                    options={pronounsProducer}
                  />
                  {formik.touched.preferred_pronoun && formik.errors.preferred_pronoun ? (
                    <div className="error">{formik.errors.preferred_pronoun}</div>
                  ) : null}
                </label>
              </div>
              <div>
                <label style={{width: '100%' }}>
                  Select music genres:
                  <SelectCustom
                    isMulti={true}
                    data={genres || []}
                    onChange={(opt, e) => {
                      formik.setFieldValue('music_genres', opt);
                    }}
                    name='genre'
                    className='select-music-genres'
                    placeholder='Music Genres'
                    customIndicator=" "
                    formik={formik}
                  />
                </label>
              </div>
            </Col>
            <Col lg={6} sm={6} xs={12} md={6} className='pl--50 pr--50'>
              <div>
                <label style={{ width: '100%' }}>
                  user Name:
                  {nameArtist? <InputFormik
                      icon="fa-user"
                      className='input-style-txt'
                      type='text'
                      name='username'
                      id='username'
                      formik={formik}
                      placeholder="user Name"
                      value={formik?.values?.username}
                    /> : <InputFormik
                      icon="fa-user"
                      className='input-style-txt'
                      type='text'
                      name='username'
                      id='username'
                      formik={formik}
                      placeholder="user Name"
                    />

                  }

                </label>
              </div>
              <div>
                <label style={{ width: '100%' }}>
                  Confirm Password:
                  {passwordArtist ? <InputFormik
                    className='input-style-txt'
                    type='password'
                    name='confirmPassword'
                    id='confirmPassword'
                    formik={formik}
                    placeholder="Confirm password"
                    icon="fa-lock"
                    value={formik?.values?.confirmPassword}
                  /> : <InputFormik
                    className='input-style-txt'
                    type='password'
                    name='confirmPassword'
                    id='confirmPassword'
                    formik={formik}
                    placeholder="Confirm password"
                    icon="fa-lock"
                  />}
                </label>
              </div>
              <div>
                <label style={{ width: '100%' }}>
                  Birthdate
                  <InputFormik
                    className='input-style-txt'
                    type='date'
                    name='birthdate'
                    id='birthdate'
                    formik={formik}
                    onChange={handleInput}
                    placeholder="Confirm password"
                    icon="fa-calendar"
                    max={thisDay}
                  />
                </label>
              </div>
            </Col>
          </Row>
          <Row className='ptb--50'>
            <Col lg={4} sm={4} xs={2} md={4}></Col>
            <Col
              lg={4}
              sm={4}
              xs={8}
              md={4}
              style={{ justifyContent: 'center', display: 'flex' }}
            >
              <button
                id='registerButton'
                className='frisson-btn fr-btn-theme btn-sd-size btn-rounded space-between'
                type='submit'
              >
                Submit
              </button>
            </Col>
            <Col lg={4} sm={4} xs={2} md={4}></Col>
          </Row>
          <FooterFinishProducerSetup />
        </Container>
      </form>
    </>
  );
};
export default FinishProducerSetup;
