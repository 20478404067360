/*******************
 * 
 * TOTEMS
 * 
 *******************/

import { curApi, userConnection, user, streamId, BuildTutorialTotem, CleanUpTutorialTotems } from "./chatManager"
import { setProposeActive, setTotemImage, totems } from "../../shared/ui-builder"
import moment from "moment"
import { CheckTutorialStep, tutState } from "shared/audiophile/onboarding"
import { skipAhead } from "./vidManager"
import loadImage from "blueimp-load-image"

let localTutImage
export let activeVote = null

 export async function GetTotem() {
  console.log(`user: ${user.playFabId}`)
  if (tutState == 1) {
    setTotemImage()
    setProposeActive('available')
    console.log("Tutorial is active so totem should be available")
  }
  else {
    //first set the last winning totem
    const winningTotemResponse = await fetch(
      curApi + 'Votes/GetWinningVotedImagesForRoom?roomId=' + userConnection.room + '&count=1',
      {
          method: 'GET',
          headers: {
              Accept: 'text/plain',
              Authorization: 'Bearer ' + user.gameChatToken
          }
      }
    )
    .then(function (response) {
      if (response.status >= 400 && response.status < 600) {
          // failure in getting totals
      } else if (response.status === 200) {
          return response.json()
      } else {
          // failure in getting totals
      }
    }).catch((error) => {
      console.error('Error:', error);
    })

    let currentTotem
    
    if (winningTotemResponse) {
      currentTotem = winningTotemResponse[0]
    }
    else {
      currentTotem = null
    }
    
    
    //set the current totem to the last winning one right away, regardless of active vote
    if (currentTotem) {
        //display the image
        setTotemImage('room', winningTotemResponse[0].imageUri)

    } else {
        //no image yet, display default
        setTotemImage()
    }

    activeVote = await GetActiveTotemVote()
    console.log("Active vote: ")
    console.log(activeVote)

    if (activeVote?.isVoteActive) {
      // console.log(`user: ${user.playFabId}`)
      //vote is currently active, so we set the timer accordingly

      //make sure we haven't already submitted something
      let iStarted = activeVote.userStarted?.playfabId == user.playFabId ? true : false
      let iVoted = false
      for (let i = 0; i < activeVote.images.length; i++) {
        const vote = activeVote.images[i];

        if (vote.userPosted?.playfabId == user.playFabId) iVoted = true
      }

      if(!iStarted && !iVoted) {
        //activate submission button
        setProposeActive('active')
      }
      else {
        //vote is actuivve but we either started it or already voted
        setProposeActive('unavailable')
      }

      console.log(timeSinceDate(new Date(activeVote.voteStarted)))
      
    } else {
      //vote isn't currently active, so let's try to get the most recent completed vote
      let lastVote = new Date(await GetLastVoteForRoom())
      console.log("Last vote: ")
      console.log(lastVote)
      // let lastEnd = new moment.utc(lastVote)
      // let now = new moment()
      // let futureStart = new moment(moment(lastEnd).add(15, 'minutes'))
      // let timeSince = now.diff(futureStart, 'seconds')
      // let lastEnd = convertDateToUTC(lastVote)
      let futureStart = addMinutes(lastVote, 15)
      let timeSince = timeSinceDate(futureStart)

      // enough time has passed and we can enable voting
      if (!isNaN(timeSince) && timeSince > 0) {
        //activate submission button
        setProposeActive('available')
      }
      else {
        console.log("Not enough time has passed since last vote")
        setProposeActive('unavailable')
      }        
        
    }
  }
    

}

export async function GetActiveTotemVote () {
    //next figure out what to do with the timer and the submission button
    const activeVoteResponse = await fetch(
      curApi + 'Votes/GetActiveVoteForRoom?roomId=' + userConnection.room,
      {
          method: 'GET',
          headers: {
              Accept: 'text/plain',
              Authorization: 'Bearer ' + user.gameChatToken
          }
      }
    ).then((response) => {
      if (response.status === 200) {
        return response.json()
      } else {
        return null
      }
    })
    .then((data) => {
      //is vote really active??
      if (data) {
        return data
      } else {
        return null
      }

    })

    return activeVoteResponse
}

async function GetLastVoteForRoom () {
  //next figure out what to do with the timer and the submission button
  const lastVoteResponse = await fetch(
      curApi + 'Votes/GetLastVoteHappenedForRoom?roomId=' + userConnection.room,
      {
          method: 'GET',
          headers: {
              Accept: 'text/plain',
              Authorization: 'Bearer ' + user.gameChatToken
          }
      }
  ).then((response) => {
    if (response.status === 200) {
      return response.json()
    } else {
      return null
    }
  })
  .then((data) => {
    //is vote really active??
    if (data) {
      return data
    } else {
      return null
    }

  })


  return lastVoteResponse
}

export function ProposeNewTotem () {
    
    let input = document.getElementById('totemInput')
    input.onchange = e => { 
        

        if (tutState == 1) {
          //if tut is active we're going to ignore the submited image and force a stored one
          fetch('https://audiophileitems.blob.core.windows.net/items/tutorialTotem.png')
          .then(response => response.blob())
          .then(blob => {
            const file = new File([blob], 'tutorialTotem.png', { type: blob.type });
            SubmitTotem(file)
          })
          .catch(error => {
            console.error('Error fetching the image:', error);
          });
          
        }
        else {
          let file = e.target.files[0]

          //fix for ios images being rotated
          // Use the package to reset the orientation data
          loadImage(
            file,
            function (img, data) {
              if (data.imageHead && data.exif) {
                // Reset Exif Orientation data:
                loadImage.writeExifData(data.imageHead, data, "Orientation", 1);
                img.toBlob(function (blob) {
                  loadImage.replaceHead(blob, data.imageHead, function (newBlob) {
                    // do something with newBlob
                    file = newBlob;

                    let formData = new FormData();
                    formData.append('file', file);
                    SubmitTotem(formData);

                  });
                }, "image/jpeg");
              }
              else {
                let formData = new FormData();
                formData.append('file', file);
                SubmitTotem(formData);
              }
            },
            { meta: true, orientation: true, canvas: true, maxWidth: 800 }
          );


          
        }

        
    }

    input.click();
}

export function VoteForTotem (voteId, totemId) {

  if (tutState == 1 && voteId == "tutorialVote") {
    //bypass all serverside stuff and just throw the uploaded image right into a votable totem
    setTotemImage('room', localTutImage)
    CleanUpTutorialTotems()

    CheckTutorialStep("VotedTotem")
    //skip to totem submission point only on tutorial show
    if (new URLSearchParams(window.location.search).get('id').toLowerCase() == 'tutorialshow' ) {
      skipAhead(944)
  }
    return null
  }

  fetch(curApi + 'Votes/GiveVote?imageId=' + voteId, {
    method: 'POST',
    headers: {
      Accept: 'text/plain',
      Authorization: 'Bearer ' + user.gameChatToken
    }
    //body: totemImage,
  })
    .then((data) => {
      console.log('Success:', data);
      UpdateTotemUI(totemId)
    })
    .catch((error) => {
      console.error('Error:', error);
    });
    
    
}

function UpdateTotemUI (id) {
  console.log(totems[id])
  for (const [id, totem] of Object.entries(totems)) {
    if (id != 'room') {
      totem.button.text.setState('available')
      totem.button.setState('idle')
    }
  }  
  totems[id].button.text.setState('unavailable')
  totems[id].button.setState('disabled')
}

function SubmitTotem(totemImage) {

    if (tutState == 1) {
      //bypass all serverside stuff and just throw the uploaded image right into a votable totem
      let reader = new FileReader();

      reader.onload = function (e) {
        //ignore submitted and upload our own

        
        localTutImage = e.target.result //cache this for later
        BuildTutorialTotem(e.target.result, 'tutorialVote')
        CheckTutorialStep("ProposeTotem")
      };

      reader.readAsDataURL(totemImage)

      return null
    }

    fetch(curApi + 'Votes/GetActiveVoteForRoom?roomId=' + userConnection.room, {
        method: 'GET',
        headers: {
          Accept: 'text/plain',
          Authorization: 'Bearer ' + user.gameChatToken
        }
      })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((data) => {
        //is vote really active??
        if (data) {
          if (data.isVoteActive) {
            //propose new image, but don't create a vote
            ProposeTotem(totemImage, data.id);
          } else {
            //not active, create new vote
            CreateVote(totemImage)

          }
        } else {
          //create new vote
          CreateVote(totemImage)
        }

      })
      .catch((error) => {
        alert("Totem submission failed, please try again : " + error)
      });
}

function ProposeTotem(totemImage, id) {
    fetch(curApi + 'Votes/ProposeImage?voteId=' + id, {
      method: 'POST',
      headers: {
        Accept: 'text/plain',
        Authorization: 'Bearer ' + user.gameChatToken
      },
      body: totemImage
    })
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        alert("Totem proposition failed, please try again : " + error)
      });
  }

  function CreateVote(totemImage) {
    fetch(
      curApi + 'Votes/CreateVote?roomUid=' + userConnection.room + '&sessionId=' + streamId,
      {
        method: 'POST',
        headers: {
          Accept: 'text/plain',
          Authorization: 'Bearer ' + user.gameChatToken
        },
        body: totemImage
      }
    )
      .then((data) => {
        if (data.status >= 400 && data.status < 600) {
          //there must be something wrong (like a vote was created at the exact same time)
          //try to just run propose again and it should switch to propose instead
        //   setTimeout(() => {
        //     SubmitTotem(totemImage);
        //   }, 500);
          // data.then(function(object) {
          //     alert(object.errorMessage);
          //   })
        } else if (data.status === 200) {
          console.log('Success:', data);
        }
        // VoteCountdown(Date.now(), 5, false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function timeSinceDate(date) {

    var nowUTC = convertDateToUTC(new Date())
    var seconds = Math.floor((nowUTC - date) / 1000);
  
    var interval = seconds / 31536000;
    interval = seconds / 60;
    
    return Math.floor(interval);
  }
  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
  }
  function convertDateToUTC(date) { return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
  }