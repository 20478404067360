import React from "react"

export default function VibesUI_CustomTab(props) {
    const nameCapped = props.name.charAt(0).toUpperCase() + props.name.slice(1)
    let [selectionCapped, setSelectionCapped] = React.useState(props.eventData)

    selectionCapped = DisplaySelection(selectionCapped)
    function DisplaySelection(eventData) {
        switch (props.name) {
            case "emoji":
                return eventData['emoji']
            case "type":
                return eventData['typeText']
            case "color":
                return eventData['primaryColor']
            case "quantity":
                return eventData['quantity']
            case "timing":
                let timingConvert = ['0', '1/8', '1/4', '1/2', '1', '2', '4', '8']

                return timingConvert[eventData['timing']]
        }
    }

    return (
        // TODO: change image based on class vibesUI--category_is_ in CSS
        <button className={`vibesUI--custom_tab vibesUI--category_is_${props.name}`} onClick={props.selectCategory}>
            <span className={`${props.name == 'color' ? 'colorSwatch': ''}`} style={nameCapped == 'Color' ? {backgroundColor: selectionCapped}: {backgroundColor: 'revert'}}>{selectionCapped}</span>
            <i class="">{nameCapped}</i>
        </button>
    )
}