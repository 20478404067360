import React, { useState } from "react";
import ButtonSearch from "../../components/button-search";
import { indexOf } from "lodash";
import "./styles.scss";
import { Dropdown } from "react-bootstrap";
const GroupButtonOption = ({ data, colors }) => {
  const [, setValue] = useState("");

  const handleSelect = (e) => {
    setValue(e);
  };

  const genNumbers = [];

  // get random color not duplicate
  const getRandom = (array) => {
    let rand = array[Math.floor(Math.random() * array.length)];
    if (indexOf(genNumbers, rand) < 0) {
      genNumbers.push(rand);
      return rand;
    }
    return getRandom(array);
  };

  const handleClick = (value) => {};

  return (
    <div className="btn-wrapper-filter">
      {data?.map((item, index) => (
        <Dropdown drop={"up"} key={index}>
          <Dropdown.Toggle
            item={item.text}
            header={item.text}
            itemtext={item.text}
          >
            <ButtonSearch
              key={index}
              styleColor={getRandom(colors)}
              handleClick={handleClick}
              value={item}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {item &&
              item.options &&
              item.options?.map((option, index) => (
                <Dropdown.Item
                  eventKey={option}
                  onSelect={handleSelect}
                  key={index}
                >
                  {option}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      ))}
    </div>
  );
};

export default GroupButtonOption;
