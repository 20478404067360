import { loadAppState } from "../../shared/utils/helpers";
import store from "../../store";
import { logout, loadGuestSession } from "../../slices/auth";
import { pick } from "lodash";
import { tryGetValidToken } from 'utilities/tryGetValidToken';

class ApiService {
  buildApiPath(path, options) {
    let url = new URL(`${process.env.API_URL || ''}${path}`);
    if (options && options.params) {
      url.search = new URLSearchParams(options.params);
    }
    return url.toString();
  }

  async buildApiHeader(options, path) {
    console.log('[Auth] Build api header on path:', path);
    const token = await tryGetValidToken();

    return {
      ...(token && { Authorization: token }),
      ...(!options.isMultipart && { 'Content-Type': 'application/json' })
    };
  }

  /**
   * Handle Success Response
   * @param {*} response APi Response
   * @returns
   */
  handleSuccess(response) {
    return Promise.resolve(response);
  }

  /**
   * Parsing error req/res
   * @param {*} error api err
   * @returns
   */
  handleErrors(error) {
    return Promise.reject(error);
  }

  /**
   * Validate Response Status Code
   * @param {*} response API Response
   * @returns
   */
  status(response) {
    const authState = (pick(loadAppState(), ['auth']) || {}).auth;

    if (response.status === 401) {
      if (!authState?.isAuthenticated) {
        store.dispatch(loadGuestSession());
      } else {
        tryGetValidToken();
      }
    }

    if (response.ok || response.status === 400) {
      return response;
    } else {
      const err = new Error(response.statusText);
      return Promise.reject(err);
    }
  }

  /**
   * Parse Fetch API response
   * @param {*} response API Response
   * @returns
   */
  json(response) {
    return response.json();
  }

  async get(path, options = {}) {
    return fetch(this.buildApiPath(path, options), {
      headers: options.withoutAuth
        ? undefined
        : await this.buildApiHeader(options, path)
    })
      .then(this.status)
      .then(this.json)
      .then(this.handleSuccess)
      .catch(this.handleErrors);
  }

  async post(path, payload, options = {}) {
    return fetch(this.buildApiPath(path, options), {
      method: 'POST',
      headers: options.withoutAuth
        ? undefined
        : await this.buildApiHeader(options, path),
      body: !options.isMultipart ? JSON.stringify(payload) : payload,
      signal: options?.signal
    })
      .then(this.status)
      .then(this.json)
      .then(this.handleSuccess)
      .catch(this.handleErrors);
  }

  async put(path, payload, options = {}) {
    return fetch(this.buildApiPath(path, options), {
      method: 'PUT',
      headers: options.withoutAuth
        ? undefined
        : await this.buildApiHeader(options, path),
      body: !options.isMultipart ? JSON.stringify(payload) : payload
    })
      .then(this.status)
      .then(this.json)
      .then(this.handleSuccess)
      .catch(this.handleErrors);
  }

  async delete(path, payload = {}, options = {}) {
    return fetch(this.buildApiPath(path, options), {
      method: 'DELETE',
      headers: options.withoutAuth
        ? undefined
        : await this.buildApiHeader(options, path),
      body: !options.isMultipart ? JSON.stringify(payload) : payload
    })
      .then(this.status)
      .then(this.json)
      .then(this.handleSuccess)
      .catch(this.handleErrors);
  }
}

export default new ApiService();
