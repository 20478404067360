import React, { useState } from 'react';
import { HexColorPicker } from "react-colorful";

const VibesUI_Color = (props) => {

    const [color, setColor] = useState(props.color);

    function updateColor (curColor) {
        setColor(curColor)
        props.updateColor(curColor)
    }
    console.log(props)
    return (
        <div className={`vibesUI--color_selector`}>

            
            {props.selectedEvent ==  "event_crowdemoji" ? 
            <div style={{backgroundColor: '#eaeaea'}} className={`container ${props.selectedEvent}`}>
                <div className='emojiContainer'>
                    <span className={props.itemType}>{props.emoji}</span>
                </div>
                <p style={{color: color}}>{props.displayName}</p>
                
                
            </div>
            :
            <div style={{backgroundColor: '#eaeaea'}} className={`container ${props.selectedEvent}`}>
                
                <div style={{backgroundColor: color, backgroundImage: 'url(' + require('../../assets/img/fireworks/' + props.itemType + '.png')}} />
                <p>{props.itemType}</p>
                        
            </div>
            }
            <section className="responsive">
                <HexColorPicker color={color} onChange={updateColor}/>
            </section>
        </div>
    );
};

export default VibesUI_Color;