import * as THREE from "three"


export let dynoCanvases = {}

let document

export function initCanvasBuilder (theDocument) {
    document = theDocument
}

export function buildCanvas (width, height, connections) {
        const canvas = document.createElement('canvas')
        // const ctx = canvas.getContext('2d')
        // const texture = new THREE.CanvasTexture(canvas)
        
        canvas.width = width
        canvas.height = height

        // dynoCanvases[id] = {
        //     'id' : id,
        //     'canvas' : canvas,
        //     'ctx' : ctx,
        //     "texture" : texture,
        //     "elements" : {}
        // }

        // return dynoCanvases[id]

        return drawAttendees(canvas, connections)
}

function drawAttendees (canvas, connections) {
    const canvasCTX = canvas.getContext('2d')
    const canvasWidth = canvas.width
    const canvasHeight = canvas.height

    canvasCTX.fillStyle = '#323239';
    canvasCTX.fillRect(0, 0, canvasWidth, canvasHeight);

    //draw a dot somewhere in the canvas
    //find bounds
    // const minWidth = widthOffset
    // const maxWidth = widthOffset + width
    // const minHeight = heightOffset
    // const maxHeight = heightOffset + height

    for (let k = 0; k < connections; k++) {
        canvasCTX.fillStyle = '#111';
        canvasCTX.beginPath();

        const x = Math.random() * canvasWidth
        const y = Math.random() * canvasHeight
        
        canvasCTX.arc(x, y, 10, 0, Math.PI * 2);
        canvasCTX.fill();
    }

    return canvas
}

export function drawAttendeesOld (canvas, sections) {

    //TODO: Make tiles max size of tiles in rows with most number of tiles
    //evently distribute the remainder amongst the rows, middle out

    console.log("Drawing!")
    const canvasCTX = canvas.ctx
    const canvasWidth = canvas.canvas.width
    const canvasHeight = canvas.canvas.height

    const numberOfSections = sections.length
    const grid = getGrid(numberOfSections)

    let curTile = 0

    canvasCTX.fillStyle = '#323239';
    canvasCTX.fillRect(0, 0, canvasWidth, canvasHeight);

    //interate through both rows and columns
    for (let i = 0; i < grid.rows; i++) {
        
        //determine num of tiles in row (different if last row)
        let tilesInRow = grid.columns
        if (i == grid.rows - 1) {
            tilesInRow += grid.remainder
        }
        
            //iterate through all colums in each row
        for (let j = 0; j < tilesInRow; j++) {
            //draw grid square
            //Y offset = i
            //X offset = j


            const width = canvasWidth / tilesInRow
            const height = canvasHeight / grid.rows

            const widthOffset = width * j
            const heightOffset = height * i

            let tile = new Path2D()
            canvas.elements[sections[curTile].placement] = tile 
            
            //build outline
            canvasCTX.strokeStyle = '#eee'
            canvasCTX.lineWidth = 5
            tile.rect(widthOffset, heightOffset, width, height)
            canvasCTX.stroke(tile)
            
            //draw a dot somewhere in the canvas
            //find bounds
            const minWidth = widthOffset
            const maxWidth = widthOffset + width
            const minHeight = heightOffset
            const maxHeight = heightOffset + height

            for (let k = 0; k < sections[curTile].connections; k++) {
                canvasCTX.fillStyle = '#111';
                canvasCTX.beginPath();

                const x = Math.random() * (maxWidth - minWidth) + minWidth
                const y = Math.random() * (maxHeight - minHeight) + minHeight
                
                canvasCTX.arc(x, y, 5, 0, Math.PI * 2);
                canvasCTX.fill();
            }

            //iterate tile counter
            curTile ++

        }

    }

    canvas.texture.needsUpdate = true

    //add event handler
    // canvas.canvas.addEventListener('mousemove', function(event) {
    //     event = event || window.event;
        
    //     for (var i = canvas.elements - 1; i >= 0; i--){  
        
    //         if (canvas.elements[i] && canvasCTX.isPointInPath(canvas.elements[i], event.offsetX, event.offsetY)) {
    //             console.log("Clicked " + canvas.elements[i].placement)
    //         }
    //         else {
            
    //         }
    //     }  
        
    // });

    return canvas

}

function getGrid (total) {
    let rows = Math.floor(Math.sqrt(total))
    let columns = Math.floor(total / rows)
    let remainder = total - (rows * columns)

    const grid = {
        "rows" : rows,
        "columns" : columns,
        "remainder" : remainder
    }

    return grid
}