import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import Select from 'react-select';
//component
import CalendarComponent from '../../components/calendar';
import SelectCustom from '../../components/SelectCustom';
import InputFormik from '../../components/input-formik';
import { genres, pronouns } from '../../shared/defaultValue';
import Checkbox from '../../components/checkbox';
// selectors
import { registerRequest } from '../../slices/auth';

// validate
import { ValidationRegisterSchema } from '../../shared/validationSchema/registerValidate';

// helpers
import moment from 'moment';
import { FORMAT_DATE_TIME } from '../../shared/utils/constants';

import './styles.scss';

const Register = () => {
  const dispatch = useDispatch();
  const [isExpandable, setExpandable] = useState(false);
  const closedCalendar = () => {
    setExpandable(false);
  };
  const wrapperRef = useRef(null);
  const handleFocus = () => {
    setExpandable(!isExpandable);
  };

  const handleInput = (event) => {
    const { value } = event.target;
    formik.setFieldValue(
      'birthday',
      moment(value).format(FORMAT_DATE_TIME.MM_DD_YYYY)
    );
  };

  const handleExpandableCalendar = (value) => {
    formik.setFieldValue(
      'birthday',
      moment(value).format(FORMAT_DATE_TIME.MM_DD_YYYY)
    );
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setExpandable(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      userName: '',
      displayName: '',
      genre: [],
      email: '',
      password: '',
      confirmPassword: '',
      pronoun: '',
      customTags: {
        account_type: 'player'
      },
      marketingOptIn: false,
      birthday: moment(new Date()).format(FORMAT_DATE_TIME.MM_DD_YYYY) || null
    },
    validationSchema: ValidationRegisterSchema,
    onSubmit: (values) => {
      const genres = values?.genre?.map((item) => item.value);
      const newForm = {
        ...values,
        genre: genres.toString(),
        displayName: formik.values?.userName
      };
      dispatch(registerRequest(newForm));
    }
  });
  // useEffect(()=>{
  //   let g = localStorage.getItem('frisson_state')
  //   let parse = JSON.parse(g)
  //
  //   alert(parse.auth.currentUser)
  // },[localStorage.getItem('frisson_state')])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit} className="form-active-register">
        <div className="wrapper-control">
          <Select
            className="basic-single"
            classNamePrefix="select"
            styles={{
              control: (provided, state) => ({
                ...provided,
                background: '#fff',
                borderColor:
                  formik.touched['pronoun'] && formik.errors['pronoun']
                    ? '#dc3545'
                    : '#9e9e9e',
                color: '#696c79',
                padding: '0 10px',
                fontFamily: "'Futura', Arial, Helvetica, sans-serif",
                height: 46,
                minHeight: 46
              }),
              input: (provided, state) => ({
                ...provided,
                margin: '0px'
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                height: '46px',
                padding: '0 6px'
              }),
              singleValue: (provided) => ({
                ...provided,
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                fontFamily: "'Futura', Arial, Helvetica, sans-serif",
                height: 46,
                minHeight: 46,
                margin: '0px'
              }),
              option: (base) => ({
                ...base,
                fontSize: '14px',
                fontFamily: "'Futura', Arial, Helvetica, sans-serif"
              }),
              indicatorSeparator: (state) => ({
                display: 'none'
              }),
              indicatorsContainer: (provided, state) => ({
                ...provided
              })
            }}
            name="pronoun"
            onChange={(opt, e) => {
              formik.setFieldValue('pronoun', opt.value);
            }}
            placeholder="Pronouns"
            options={pronouns}
          />
          {formik.touched.pronoun && formik.errors.pronoun ? (
            <div className="error">{formik.errors.pronoun}</div>
          ) : null}
        </div>

        <InputFormik
          icon="fa-user"
          name="userName"
          type="text"
          formik={formik}
          placeholder="Username"
        />
        <SelectCustom
          isMulti={true}
          data={genres || []}
          onChange={(opt, e) => {
            formik.setFieldValue('genre', opt);
          }}
          name="genre"
          className="select-music-genres"
          placeholder="Music Genres"
          customIndicator=" "
          formik={formik}
        />
        <div ref={wrapperRef}>
          <div className="wrapper-control">
            <div ref={wrapperRef} className="input-content-control --calendar">
              <span className="title" onClick={handleFocus}>
                Birthday
              </span>
              <input
                onFocus={handleFocus}
                value={formik.values.birthday}
                name="birthday"
                onChange={handleInput}
                className="input-custom-control __input-calendar"
                placeholder="Calendar"
              />
              <i
                className={`custom-icon-select fa fa-angle-${
                  isExpandable ? 'up' : 'down'
                }`}
                aria-hidden="true"
                onClick={handleFocus}
              />
            </div>
            <div className={`expandable ${isExpandable ? 'open' : 'closed'}`}>
              <CalendarComponent
                handleOnChange={handleExpandableCalendar}
                closedCalendar={closedCalendar}
              />
            </div>
          </div>
        </div>

        <InputFormik
          icon="fa-envelope-o"
          name="email"
          formik={formik}
          placeholder="Email address"
        />
        <InputFormik
          icon="fa-lock"
          name="password"
          type="password"
          formik={formik}
          placeholder="Password"
        />
        <InputFormik
          icon="fa-lock"
          name="confirmPassword"
          type="password"
          formik={formik}
          placeholder="Confirm password"
        />
        <div className="wrapper-control">
          <Checkbox formik={formik} name="marketingOptIn">
            I would like to sign up to receive email updates & news from
            Frisson. We never share or sell your personal information.
          </Checkbox>
        </div>

        <Button
          variant="primary"
          type="submit"
          className="btn-submit"
          style={{ width: '100%' }}
        >
          Sign Up
        </Button>
      </form>
    </Fragment>
  );
};

export default Register;
