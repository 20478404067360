import { render } from "@testing-library/react"

/**
 * Sizes
 */
export const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

let camera = null
let renderer = null
let resizeCallback = null

function _resizeListener() {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    //save current to see if it changes at this size change
    const prevState = mediaQueryState

    if (sizes.width < 500) {
        mediaQueryState = screenSizeStates.MobilePortrait
    }
    else if (sizes.width < 1000 && sizes.height < 600) {
        mediaQueryState = screenSizeStates.MobileLandscape
    }
    else if (sizes.width < 1200 && sizes.height > 1000) {
        mediaQueryState = screenSizeStates.TabletPortrait
    }
    else if (sizes.width < 1200 && sizes.height < 1000) {
        mediaQueryState = screenSizeStates.TabletLandscape
    }
    else if (sizes.width > 1200) {
        mediaQueryState = screenSizeStates.Desktop
    }

    if (prevState != mediaQueryState) {
        if (resizeCallback) resizeCallback(mediaQueryState)
    }

    // // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}

export function screenSizeListener (theCamera, theRenderer, theCallback) {

    console.log("initting screensize")

    camera = theCamera
    renderer = theRenderer
    resizeCallback = theCallback
    mediaQueryState = null

    window.addEventListener('resize', _resizeListener, false)

    _resizeListener()

}

export const screenSizeStates = {
    Desktop : 'desktop',
    TabletLandscape : 'tabletLandscape',
    TabletPortrait : 'tabletPortrait',
    MobileLandscape : 'mobileLandscape',
    MobilePortrait : 'mobilePortrait'
}

export let mediaQueryState = screenSizeStates.Desktop

export function MQDIsposeEvents () {
    window.removeEventListener( 'resize', _resizeListener, false);

}