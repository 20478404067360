import React, { useEffect, useMemo, useRef } from "react";
import Slick from "react-slick";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import TitleCommon from "../../components/title-common";
import CardLive from "../../components/card-live-now";
import { FORMAT_DATE_TIME } from "../../shared/utils/constants";
import { isLoading } from "../../selectors/searchGame.selector";
import { SpinnerOverlay } from "../../components/spinner-overlay";
// redux-action
import { getListLiveRequest } from "../../slices/genres";
import { FILTER_STATUS } from "../../shared/utils/constants";
import { typeGenre, getLives } from "../../selectors/genres.selector";
import { convertTime } from "../../shared/utils/helpers";
import moment from "moment";
import "./styles.scss";

const ListCardLive = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(getLives);
  const { genre, show, time } = useSelector(typeGenre);
  const loading = useSelector(isLoading);
  const ref = useRef({});
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const next = () => {
    ref.current.slickNext();
  };
  const previous = () => {
    ref.current.slickPrev();
  };

  const SlickSlider = ({ children, settings, classes }) => {
    return (
      <Slick {...settings} ref={ref} className={classes}>
        {children}
      </Slick>
    );
  };

  const filterData = useMemo(() => {
    if (isEmpty(data)) return;
    return data.filter((item) => {
      const listGenres = item?.genre
        ?.split(",")
        ?.map((item) => item.toLowerCase());
      if (
        time.value !== "bytime" &&
        genre.value === "genres" &&
        show.value === "show"
      ) {
        const timeStarted = moment(item?.timeStarted).format(
          FORMAT_DATE_TIME.YYYY_MM_DD
        );
        if (time.value === "today" || time.value === "yesterday") {
          return moment(timeStarted).isSame(convertTime(time.value));
        }
        if (time.value === "lastWeek") {
          return moment(timeStarted).isSameOrAfter(convertTime(time.value));
        }
        if (time.value === "lastMonth") {
          return moment(timeStarted).isSameOrAfter(convertTime(time.value));
        }
      }

      if (
        time.value !== "bytime" &&
        genre.value === "genres" &&
        show.value !== "show"
      ) {
        const timeStarted = moment(item?.timeStarted).format(
          FORMAT_DATE_TIME.YYYY_MM_DD
        );
        if (time.value === "today" || time.value === "yesterday") {
          return (
            item.status === show.value &&
            moment(timeStarted).isSame(convertTime(time.value))
          );
        }
        if (time.value === "lastWeek") {
          return (
            item.status === show.value &&
            moment(timeStarted).isSameOrAfter(convertTime(time.value))
          );
        }
        if (time.value === "lastMonth") {
          return (
            item.status === show.value &&
            moment(timeStarted).isSameOrAfter(convertTime(time.value))
          );
        }
      }
      if (
        time.value !== "bytime" &&
        genre.value !== "genres" &&
        show.value === "show"
      ) {
        const timeStarted = moment(item?.timeStarted).format(
          FORMAT_DATE_TIME.YYYY_MM_DD
        );
        if (time.value === "today" || time.value === "yesterday") {
          return (
            listGenres.includes(genre.value) &&
            moment(timeStarted).isSame(convertTime(time.value))
          );
        }
        if (time.value === "lastWeek") {
          return (
            listGenres.includes(genre.value) &&
            moment(timeStarted).isSameOrAfter(convertTime(time.value))
          );
        }
        if (time.value === "lastMonth") {
          return (
            listGenres.includes(genre.value) &&
            moment(timeStarted).isSameOrAfter(convertTime(time.value))
          );
        }
      }

      if (
        genre.value !== "genres" &&
        show.value === "show" &&
        time.value === "bytime"
      ) {
        return listGenres.includes(genre.value);
      }
      if (
        genre.value !== "genres" &&
        show.value !== "show" &&
        time.value !== "bytime"
      ) {
        const timeStarted = moment(item?.timeStarted).format(
          FORMAT_DATE_TIME.YYYY_MM_DD
        );
        if (time.value === "today" || time.value === "yesterday") {
          return (
            item.status === show.value &&
            listGenres.includes(genre.value) &&
            moment(timeStarted).isSame(convertTime(time.value))
          );
        }
        if (time.value === "lastWeek") {
          return (
            item.status === show.value &&
            listGenres.includes(genre.value) &&
            moment(timeStarted).isSameOrAfter(convertTime(time.value))
          );
        }
        if (time.value === "lastMonth") {
          return (
            item.status === show.value &&
            listGenres.includes(genre.value) &&
            moment(timeStarted).isSameOrAfter(convertTime(time.value))
          );
        }
      }
      if (
        genre.value === "genres" &&
        show.value !== "show" &&
        time.value === "bytime"
      ) {
        return item.status === show.value;
      }
      return [...data];
    });
  }, [data, genre.value, show.value, time.value]);

  useEffect(() => {
    if (!isEmpty(data)) return;
    dispatch(
      getListLiveRequest({
        status: FILTER_STATUS.LIVE,
        startedFrom: moment()
          .subtract(1, "year")
          .format(FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM),
        startedTo: moment(new Date()).format(FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM),
        limit: 100
      })
    );
  }, [data, dispatch]);

  return (
    <>
      <SpinnerOverlay loading={loading} />
      {!isEmpty(filterData) ? (
        <div className="wrapper-recently-slider slider-container">
            <TitleCommon title="Live Shows" />
            <SlickSlider settings={settings}>
              {!isEmpty(filterData) &&
                filterData?.concat([{}, {}])?.map((item, index) => (
                  <div key={index}>
                    <CardLive item={item} />
                  </div>
                ))}
            </SlickSlider>
            {filterData.length === 1 ? null : (
              <div className="live-now-pagination">
                <div className="box-pagination">
                  <div className="show-arrow" onClick={previous}>
                    <i className="fa fa-angle-left" aria-hidden="true" />
                  </div>
                  <div className="show-arrow active" onClick={next}>
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </div>
                </div>
              </div>
            )}
        </div>
      ) : null}
    </>
  );
};
export default ListCardLive;
