import React from 'react';
import { useHistory } from "react-router-dom";
import Thumbnail from "../about-us/thumbnail/AboutThumbOne";

const AdsBanner = ({className,thumb, heading, adsLink, ctaText}) => {
    let history = useHistory();

    const handleClick = (e) => {
        e.preventDefault();
        history.push(adsLink);
    }
    
    return (
        <div className={`inner ${className} wow move-up ads-announcements`}>
            {
                thumb?
                <Thumbnail thumb={thumb} adsLink={adsLink}/>
                :
                <div className="content">
                    <h4 className="heading heading-h4 text-white text-uppercase">{heading}</h4>
                    <div className="banner-btn mt--25 text-center">
                        <a className="frisson-btn fr-btn-black btn-sd-size space-between"
                           // target="_blank"
                           rel="noopener noreferrer"
                           href={adsLink}
                           onClick={e => handleClick(e)}>
                            {ctaText}
                        </a>
                    </div>
                </div>
            }
        </div>
    );
};

export default AdsBanner;
