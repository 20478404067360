// import React, { Fragment } from "react";
// import { Helmet } from "react-helmet";

// // layout
// import { LayoutDefault } from "../../layouts";
// import { Header } from "../../components/header";
// import { Footer } from "../../container/footer";

// // container
// import GenresList from "../../container/genres-list";
// import DropDownMenu from "../../container/dropdown-menu";
// import ListCardLive from "../../container/list-live-now";
// import ListRecommendedShows from "../../container/list-recommended-shows";
// import SuggestedShows from "../../container/suggested-shows";

// // data
// import frisson from "../../data/frisson.json";
// import { typeGenre } from "../../selectors/genres.selector";
// import { useSelector } from "react-redux";

// import "./styles.scss";

// const DressingRoom = () => {
//   const { genre } = useSelector(typeGenre);

//   return (
//     <Fragment>
//       <Helmet>
//         <title>{`${frisson.title}`}</title>
//       </Helmet>
//       <p>Hello World</p>
//     </Fragment>
//   );
// };

// export default DressingRoom;




import React from "react";
// import ReactDOM from "react-dom";
// import { Redirect, Route } from "react-router-dom";
// import './style.css'
import User from 'shared/audiophile/models/user'
import jwt_decode from 'jwt-decode' 

class PaymentSuccessful extends React.Component {
    constructor(props) {
        super(props)

      }
    
    componentDidMount() {
        /**
         * Constants
         */

        const titleId = '10B75'

        const urlParams = new URLSearchParams(window.location.search);
        let orderId = urlParams.get('orderId');

        //===========================
        //
        //     User Properties
        // 
        //===========================
        function GetUser () {
            let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
            if (appState?.auth.isAuthenticated) {
                return new User(jwt_decode(appState.auth.currentUser))
            }
            else {
                console.log("Cannot get user, we should return to login")
                window.location.href = '/'
                return null
            }
        }

        const user = GetUser()

        if (orderId) {
            confirmPurchase()
        }

        function confirmPurchase() {
            fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
                method: "POST",
                headers: {
                    "Accept" : "text/plain",
                    "X-EntityToken" : user.entityToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
        
                    "FunctionName" : "PlayPal_ConfirmPurchase",
                    "PlayFabId" : user.playFabId,
                    "FunctionParameter" : {
                        "orderId" : BigInt(orderId).toString('16').toString().toUpperCase(),// eslint-disable-line
                        "ticket" : user.playFabSessionToken
                    }
        
        
                })
            })
            .then(response => response.json())
            .then(data => {
        
                if (data.code === 200) {
                    window.close()
                }
        
            });
        }
        
    }

    
      render() {
        return (
            <div></div>
            
        )
      }
}

export default PaymentSuccessful;