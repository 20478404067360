import React from 'react';

const AboutThumbOne = ({className, thumb, adsLink}) => {
    return (
        <div className={`thumb text-left wow move-up ${className && className}`}>
            <a className="frisson-btn fr-btn-black btn-sd-size space-between"
               target="_blank"
               rel="noopener noreferrer"
               href={adsLink}>
                <img src={thumb} alt="ctaText"/>
            </a>
        </div>
    );
};

export default AboutThumbOne;
