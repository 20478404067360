import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

// layout
import { LayoutDefault } from "../../layouts";
import { Header } from "../../components/header";
import { Footer } from "../../container/footer";

// container
import GenresList from "../../container/genres-list";
import DropDownMenu from "../../container/dropdown-menu";
import ListCardLive from "../../container/list-live-now";
import ListRecommendedShows from "../../container/list-recommended-shows";
import SuggestedShows from "../../container/suggested-shows";

// data
import frisson from "../../data/frisson.json";
import { typeGenre } from "../../selectors/genres.selector";
import { useSelector } from "react-redux";

import "./styles.scss";

const Discover = () => {
  const { genre,genres } = useSelector(typeGenre);

  console.log('hhhhh',genres)

  return (
    <Fragment>
      <Helmet>
        <title>{`${frisson.title}`}</title>
      </Helmet>
      <LayoutDefault className="template-color-1 template-font-2 bg_color--3">
        <Header />
        <div className="wrapper-genres-page">
          <DropDownMenu className="position-filter" />
          {genre.value !== "genres" ? <SuggestedShows /> : null}
          <ListCardLive />
          <ListRecommendedShows />
          <GenresList />
        </div>
        <Footer />
      </LayoutDefault>
    </Fragment>
  );
};

export default Discover;
