import React, { useState, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import GenresBox from "../../components/genrer-box";
import TitleCommon from "../../components/title-common";
import { useSelector, useDispatch } from "react-redux";

import dataFake from "../../data/genres";
import { getGenresRequest } from "../../slices/genres";
import { getGenres } from "../../selectors/genres.selector";
import { groupByGenres } from "../../shared/utils/helpers";
import { isEmpty } from "lodash";
import { FORMAT_DATE_TIME } from "../../shared/utils/constants";
import { typeGenre } from "../../selectors/genres.selector";

import moment from "moment";
import "./styles.scss";
const GenresList = () => {
  const [hover, setHover] = useState({});
  const dispatch = useDispatch();
  const { genre } = useSelector(typeGenre);

  const { data } = useSelector(getGenres);

  const groupData = useMemo(() => {
    if (isEmpty(data)) return [];
    return groupByGenres(data);
  }, [data]);

  const modifyData = useMemo(() => {
    if (isEmpty(dataFake)) return [];
    const keys = Object.keys(groupData);
    return dataFake?.map((item, index) => {
      if (keys.includes(item.value.toLowerCase())) {
        return {
          ...item,
          total: groupData[item.value]
        };
      }
      return { ...item };
    });
  }, [groupData]);

  useEffect(() => {
    if (!isEmpty(data)) return;
    dispatch(
      getGenresRequest({
        startedFrom: moment()
          .subtract(1, "year")
          .format(FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM),
        startedTo: moment(new Date()).format(FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM),
        status: "Past,Live",
        limit: 100
      })
    );
  }, [data, dispatch]);

  return (
    <div className="wrapper-discover wow move-up">
      <TitleCommon title={"Genres"} />
      <div className="container-list-genres fluid">
        <Row>
          {modifyData?.map((item, index) => (
            <Col sm={6} lg={3} className="mt--30" key={index}>
              <GenresBox
                key={index}
                item={item}
                isActive={item ? item?.value === genre.value : false}
                isHover={item ? item?.value === hover.value : false}
                setHover={setHover}
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default GenresList;
