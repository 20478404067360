import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  tabs
} from '../slices/tabs';
import {
  recentlyLiveRequest,
  popularShowRequest,
  homeSliderRequest
} from '../slices/searchGameSession';
import { getProfileRequest } from '../slices/user';
import {
  FORMAT_DATE_TIME,
  PAGINATION,
  FILTER_STATUS
} from '../shared/utils/constants';
import { toastConfig } from '../shared/defaultValue';
import accountService from '../services/Account';
import userService from '../services/UserAPI';
import moment from 'moment';
import toast from 'react-hot-toast';



function* selectedTab() {
alert('dfdf')
}



export function* tabsSaga() {
  yield all([

     takeLatest('SET_TAB', selectedTab),

  ]);
}
