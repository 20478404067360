import { isEmpty } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './styles.scss';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { TABS } from '../../data/profile-edit/ProfileTab';
import classNames from 'classnames/bind';
import { selectTab } from '../../slices/tabs';
import { NavLink } from 'react-router-dom';
import { logout, resetState } from '../../slices/auth';

const DropDownButton = ({ isShow, openAndCloseMenu }) => {
  const isActive = () => {
    openAndCloseMenu(!isShow);
  };
  const [tabIndexEm, setTabIndexEm] = useState(0);
  const [openSlideUp, setOpenSlideUp] = useState(false);
  const dispatch = useDispatch();
  const handlClick = (e, tabIdx) => {
    setTabIndexEm(tabIdx);
    dispatch(selectTab(e.target.tabIndex));
  };
  const handleClosePopUp = useCallback(() => {
    setOpenSlideUp(false);
  }, []);
  const onLogout = () => {
    dispatch(resetState());
    dispatch(logout());
    handleClosePopUp();
    // window.localStorage.removeItem('profile_user')
  };

  let arrayTab = [
    { tabInd: 6, tab: TABS.userName, title: 'All', icon: 'fa fa-cog tab-account-icon' },
    { tabInd: 1, tab: TABS.schedulePerformance, title: 'Schedule Performance', icon: 'fa fa-archive tab-account-icon' },
    { tabInd: 0, tab: TABS.accountSettings, title: 'Account Settings', icon: 'fa fa-cog tab-account-icon' },
    { tabInd: 3, tab: TABS.watchList, title: 'Watch List', icon: 'fa fa-list tab-account-icon' },
    { tabInd: 2, tab: TABS.friends, title: 'Friends', icon: 'fa fa-users tab-account-icon' },
    { tabInd: 5, tab: TABS.youtubeTwitch, title: 'Add Connection', icon: 'fa fa-plug tab-account-icon' }
  ];
  return (
    <div style={{ display: isShow ? 'block' : 'none' }} className='block-top-menu'>
      <ul className='block-top-menu-menu'>
        <li><NavLink to='/'>Home</NavLink></li>
        <li><NavLink to='/discover'>Discover</NavLink></li>
        <li><NavLink to='/recommendations'>Recommendations</NavLink></li>
      </ul>
      <ul>
        {
          arrayTab?.map((k) => {
            return <li key={k.tabInd} as='button' onClick={(e) => {
              handlClick(e, k.tabInd);
              isActive();
            }} tabIndex={k.tab} className={classNames('', {
              inactive: tabIndexEm !== k.tab,
              active: tabIndexEm === k.tab
            })}><i className={k.icon} style={{ marginRight: '10px' }}></i>{k.title}</li>;
          })
        }
        <li>
          <div
            className='logout-link'
            onClick={() => {
              onLogout();
            }}
          >
            Logout
          </div>
        </li>
      </ul>
    </div>
  );
};
export default DropDownButton;
