import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Logo from "../../components/header/elements/logo";
import Widget from "../../components/widget";
import Copyright from "../../components/footer/Copyright";
import logo from "../../assets/img/logo/footer/footer-logo.png";
import socials from "../../data/socials";
import NewsletterFormThree from "../../components/forms/NewsletterFormThree";
import CallToActionVerticalThree from "../call-to-action/elements/CallToActionVerticalThree";
import { SpinnerOverlay } from "../../components/spinner-overlay";
import { useSelector } from "react-redux";
import { getUser } from "../../selectors/user.selector";

const Footer = () => {
  const user = useSelector(getUser);

  return (
    <Fragment>
      <SpinnerOverlay loading={user?.loading} />

      <footer className="wow move-up page-footer bg-particles bg_color--3 pr--30 pr_lp--30 pr_lg--30 pr_md--30 pr_sm--30">
        <div className="fr-footer-inner pt--100 pb--30 pt_md--90 pt_sm--90 pb_md--30 pb_sm--20">
          <Row>
            <Col xs={12} sm={12} lg={5} xl={5} className="pl--50 pr--20 pb--20">
              <Widget wrapClasses="text-var--2" title={null}>
                <div className="footer-logo-section">
                  <Row>
                    <Col xs={12} sm={7} lg={7} xl={7}>
                      <Logo logo={logo} />
                      <div className="footer-address">
                        <div className="fr-hover">
                          Frisson Studios Inc.
                          <p className="text-black">
                            340 S Lemon Ave #4257 <br /> Walnut, CA 91789, USA
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={5} lg={5} xl={5}>
                      <div className="footer-address">
                        <Copyright />
                        <div className="fr-hover pt--60">
                          <p className="text-black">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="mailto:hello@frisson.live"
                            >
                              hello@frisson.live
                            </a>
                          </p>
                          {/*<p className="text-black">*/}
                          {/*  <a href="tel:+68-120-034-509">(+68) 120034509</a>*/}
                          {/*</p>*/}
                        </div>
                        <div className="social-share social--transparent text-black">
                          <h6 style={{ fontWeight: "bold" }}>Follow Us</h6>
                          {socials?.map((social) => (
                            <a
                              key={social.id}
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://${social.media}.com/${social.username}`}
                            >
                              <i className={`fa fa-${social.media}`} />
                            </a>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Widget>
            </Col>

            <Col xs={12} sm={12} lg={7} xl={7}>
              <Row>
                <Col xs={12} sm={12} lg={5} xl={5} className="pl--50 pr--20">
                  <CallToActionVerticalThree
                    bgColor={"rgb(49, 49, 49, 0.3)"}
                    overlayColor={"rgb(49, 49, 49, 0.3)"}
                    overlayOpacity={0.4}
                  />
                </Col>

                <Col xs={12} sm={12} lg={7} xl={7} className="pl--50 pr--20">
                  <Widget
                    wrapClasses="menu--about"
                    title="Newsletter"
                    description="Join our mailing list and keep up with"
                    frisson="all things frisson"
                  >
                    <div className="contact-form mt--20 contact-form--4">
                      <NewsletterFormThree />
                    </div>
                  </Widget>
                </Col>
                <Col xs={12} sm={12} lg={12} xl={12} className="stay-bottom">
                  <div className="copyright">
                    <Row className="align-items-center">
                      <Col xs={12} md={8}>
                        <div className="copyright-left text-md-left text-center">
                          <ul className="pl--40 fr-copyright-menu d-flex fr-hover justify-content-center justify-content-md-start flex-wrap flex-sm-nowrap">
                            <li>
                              <Link
                                to={
                                  process.env.PUBLIC_URL +
                                  "/terms-and-conditions"
                                }
                              >
                                Terms And Conditions
                              </Link>
                            </li>
                            <li>
                              <Link
                                to={process.env.PUBLIC_URL + "/privacy-policy"}
                              >
                                Privacy
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Col>

                      <Col xs={12} md={4}>
                        <div className="copyright-right text-md-right text-center">
                          <p>
                            MADE WITH LOVE BY{" "}
                            <b style={{ color: "#DF0346" }}>Frisson</b>{" "}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
