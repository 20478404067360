import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// layout
import { LayoutDefault } from '../../layouts';
import { Header } from '../../components/header';
import { Footer } from '../../container/footer';
// data
import frisson from '../../data/frisson.json';
import './styles.scss';

const TermsAndConditions = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{`${frisson.title}`}</title>
      </Helmet>
      <LayoutDefault className='bg-light template-font-1 bg_color--3'>
        <Header />
        <Container className='pl_md--60 pl_sm--0'>
          <Row>
            <div className='terms-conditions'>
              <h2>Frisson Terms & Conditions</h2>
              <blockquote>
                <h4>Introduction</h4>
                <p>We appreciate your interest in the Frisson platform, products, features, and services which we make
                  available for your use as part of the platform (collectively the, “Service”).</p>
                <i>Last updated: April 25, 2021</i>
              </blockquote>
              <br />
              <h4>1. Our Service</h4>
              <p>The Service enables you to build and stream virtual environments and live and recorded performances
                using Platform Content (as described below) and your own User Content (as described below) which you may
                choose to insert, upload or download to the Service under your own accord and then provide the combined
                output to various video and audio capture programs and sites via the Stream (as described below). The
                resulting Streams allow other users of the Service to find, watch, share, and engage with the Streams,
                whether live or recorded, acting as a distribution channel for advertisers and original content creators
                of all sizes.</p>
              <p>Crowd Chat is a feature that extends from the Stream. When a Stream is created using the Service, it
                creates a chat session on the Frisson platform through which users can chat with one another and engage
                with the artist. This is where users can vote on images to be passed back into the Stream to be
                displayed to the audience if a Crowd Chat admin (established by the user Streaming) approves the
                image.</p>
              <br />
              <h4>2. Applicable Terms</h4>
              <p>Your use of the Service is subject to these terms, the Frisson <a target='_blank'
                                                                                   href='/community-guidelines'>Community
                Guidelines</a> and the Privacy and Copyright Policies which may from time to time be updated (together,
                this "Agreement"). Your Agreement with us will also include the Advertising on Frisson Policies if your
                Stream includes advertising or sponsorships to the Service or if your Stream incorporates paid
                promotions in your content. Any other links or references provided in these terms are for informational
                use only and are not part of the Agreement.</p>
              <br />
              <h4>3. Your Service Provider</h4>
              <p>The Service is provided by Frisson Studios, Inc., a Delaware corporation, located at 340 S Lemon Ave
                #4257 Walnut, CA, 91789 (referred to as “Frisson”, “we”, “us”, or “our”). References to Frisson’s
                “Affiliates” in the Agreement means the other companies or entities partnered with Frisson, now or in
                the future, to deliver the Service.</p>
              <br />
              <blockquote>
                Please read this Agreement carefully and make sure you understand it. If you do not accept any part of
                the Agreement, or do not understand it, then you may not use the Service.
              </blockquote>
              <br />
              <h4>4. Who may use the Service?</h4>
              <p><b>Age Requirements.</b> You must be at least 18 years old to use the Service.</p>
              <p><b>Permission by Parent or Guardian.</b> If you are under 18, you represent that you have your parent
                or guardian’s permission to use the Service. Please have them read this Agreement with you.</p>
              <p>If you are a parent or legal guardian of a user under the age of 18, by allowing your child to use the
                Service, you are subject to the terms of this Agreement and responsible for your child’s activity on the
                Service.</p>
              <p><b>Businesses.</b> If you are using the Service on behalf of a company or organization, you represent
                that you have authority to act on behalf of that entity, and that such entity accepts this Agreement.
              </p>
              <br />
              <h4>5. Your Use of the Service.</h4>
              <p><b>Platform Content on the Service.</b> Platform Content on the Service includes all software, 2d & 3d
                models, videos, audio, images, text, Frisson branding (including trade names, trademarks, service marks,
                or logos), interactive features such as Crowd Chat, metrics,
                and other materials which are provided directly by Frisson. For clarity, no original content nor User
                Content is provided by Frisson other than the content directly owned by Frisson.
              </p>
              <p>
                <b>User Content on the Service.</b> User content on the Service includes videos, audio (including, but
                not limited to, music and other sounds), images, text (including, but not limited to, comments and
                scripts), branding (including trade names, trademarks, service marks, or logos), interactive features,
                software, metrics, and other materials which are be provided by you or a third-party (collectively the,
                "User Content”). For clarity, User Content is any content which is not provided directly by Frisson
                software in providing the Service.
              </p>
              <p>
                User Content is the responsibility of the person or entity that provides it to the Service, including
                the consequences of posting, publishing, or streaming it. Frisson is under no obligation to host or
                serve User Content.
                You represent and warrant that: (1) you are the content creator or control or own all the rights in and
                to use or upload the User Content or have sufficient rights and authority to otherwise grant the rights
                provided herein;
                (2) your User Content will not and does not: (a) misappropriate, infringe, or violate any third-party
                rights, including any copyright, trademark, trade secret, moral right, privacy right, right of
                publicity, or any other
                intellectual property or proprietary right, or (b) defame any other person.
              </p>
              <p>
                <i>
                  If you see any User Content you believe does not comply with this Agreement, including by violating
                  the <a target='_blank' href='/community-guidelines'>Community Guidelines</a> or the law, you can
                  report it to us at
                  <a target='_blank' rel='noopener noreferrer'
                     href='mailto:compliance@frisson.live?Subject='> compliance@frisson.live</a>
                </i>
              </p>
              <br />
              <h4>6. Streaming on the Service.</h4>
              <p>
                The Service allows you to stream live or recorded captures of Platform Content and User Content
                (collectively the, “Content”) through partner platforms and websites
                (collectively the, “Stream,”). Frisson is excited to stream live and recorded content with some of our
                most valued partners, but our partners must be held accountable.
                Below you’ll find our best practices surrounding content distribution via live or recorded video
                streaming through the Service on our site as well as partner sites.
                Your use of the Service must at all times comply with these guidelines:
              </p>
              <p>1. The advertiser, promoter, or production company is responsible for securing and making sure they
                have all necessary rights to legally broadcast the live stream on Frisson and our Streaming Partners.
                These rights include but are not limited to:</p>
              <p>a.Public performance rights</p>
              <p>b. Music licensing</p>
              <p>c. Video licensing</p>
              <p>d. Other publicity rights, including use of any branded content or logos</p>
              <p>2. Content in the live stream must adhere to our <a target='_blank' href='/community-guidelines'>Community
                Guidelines</a>.</p>
              <p>3. The promoter or producer must monitor the Stream to ensure compliance with our <a target='_blank'
                                                                                                      href='/community-guidelines'>Community
                Guidelines</a>, including the Stream’s coupled Crowd Chat located at
                https://frisson.live/jointhecrowd during the entire duration of any live stream.</p>
              <p>4. Promoters and producers must agree separately while using the Service to all Terms and Conditions
                for any Streaming Partners which they utilize:</p>
              <p>a. Youtube: <a target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/t/terms'>YouTube
                Terms</a></p>
              <p>b. Facebook: <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/legal/terms'>Facebook
                Terms</a></p>
              <p>c. Twitch: <a target='_blank' rel='noopener noreferrer'
                               href='https://www.twitch.tv/p/legal/terms-of-service/'>Twitch Terms</a></p>
              <p><i>We reserve all rights to conduct a policy review on your stream, with or without notice, and, I our
                sole discretion determine whether or not to approve it for use with the Service.</i></p>
              <br />
              <h4>7. Frisson Accounts and Additional Features.</h4>
              <p>You can use parts of the Service without having a Frisson account, such as browsing and searching for
                Content. However, you need a Frisson account to access some features. With a Frisson account, you may be
                able to interact with each stream’s Crowd Chat, allocate stats to unlock rewards, subscribe to artist
                streams, distribute Content through the Service, and more. You can create a Frisson account by following
                these instructions.</p>
              <p>Keeping your password confidential is necessary to protect your Frisson account. You should never reuse
                your Frisson account password on third-party applications and accounts.</p>
              <br />
              <h4>8. Your Information.</h4>
              <p> Our <a target='_blank' href='/privacy-policy'>Privacy Policy</a> explains how we treat your personal
                data and protect your privacy when you use the Service.</p>
              <p>How we protect your privacy and treat your personal data while you use the service is explained in
                Our <Link to={process.env.PUBLIC_URL + '/privacy-policy'}>Privacy Policy.</Link></p>
              <p>Any audio or audiovisual content uploaded by you to the Service may be edited, modified or processed by
                us in in our
                sole discretion as we may determine to be consistent with the Service.
              </p>
              <br />
              <h4>9. Permissions and Restrictions.</h4>
              <p>If you fully comply with this Agreement and applicable law, then you may access and use the Service as
                made available to you. You may view or listen to Content
                or interact with non-admin Crowd Chat features (including uploading media content) for your personal,
                non-commercial use.</p>
              <p>While you are using the Service, the following restrictions apply. You are not allowed to:</p>
              <p> 1. access, broadcast, transmit, stream, display, reproduce, download, sell, license, translate, alter,
                adapt, modify, create derivative works of or
                otherwise use any part of the Service or any Content except: (a) as expressly authorized by the Service;
                or (b) with prior written
                permission from Frisson and, if applicable, the respective rights holders;
              </p>
              <p>2. run contests on or through the Service without the prior written permission of Frisson;</p>
              <p>3. delete, remove, circumvent, disable, damage, or otherwise interfere with any part of the Service (or
                attempt to do any of these things),
                including security-related features or features that (a) prevent or restrict the copying or other use of
                Content or (b) limit the use of the Service or Content;</p>
              <p>4. harvest, scrape, or gather any personal information that might identify a person (for example,
                emails, usernames), unless permitted by that person or allowed under part (3) above;</p>
              <p>5. access the Service via automated means (such as bots, botnets or scrapers) except (a), in accordance
                with Frisson’s applicable policies in the case of public search engines;
                or (b) with the prior written permission of Frisson;</p>
              <p>6. encourage or cause any inaccurate measurements of legitimate user engagement with the Service,
                including via paying people or services or providing people or services with incentives
                to increase a video’s views, active chat users, chat engagements, likes or other engagements, or to
                increase, damage, or improve Frisson account metrics, or otherwise manipulate metrics in any manner;</p>
              <p>7. misuse any reporting processes such as reporting, appeals processes, disputes, complaints, or
                flagging, including by making baseless, intentionally misleading, or frivolous submissions;</p>
              <p>8. use the Service to distribute or communicate unsolicited promotional or commercial content or other
                undesirable or mass solicitations;</p>
              <p>9. use the Stream to view or listen to Content other than for personal, non-commercial use (for
                example, you may not publicly screen videos or stream music from the Service); or</p>
              <p>10. use the Service to (a) sell any promotional material, advertising, or sponsorships, placed on,
                around, or within the Service, the Stream, or Content, other than those allowed in the
                applicable Frisson policies (such as compliant product placements offered directly through the Service)
                or those allowed on Streaming Partner sites, subject to Streaming Partners’
                own Terms of Service listed above; or (b) sell promotional material, advertising, or sponsorships on any
                page of any application or website that only contains Content from the Stream or Service,
                or where Content from the Stream or Service is the primary basis for such sales.</p>
              <p><b>Reservation. </b> You do not gain any ownership of or rights to any aspect of the Service while
                using the Service, including emails, usernames, or any other Content posted by Frisson or others.</p>
              <p><b>Changes to the Service.</b> Frisson may edit, amend, modify or change any of the terms of this
                Agreement by posting the amended terms. Your continued use of the Service after the effective date of
                the revised Agreement constitutes your acceptance of the revised Agreement. You understand and agree
                that there will be times when Frisson makes such edits, amendments, modification and changes
                without notice, including, without limitation at such times as we determine the need to take action to
                comply with legal requirements, prevent abuse, or to improve the security and
                operability of our Service.
              </p>
              <br />
              <h4>10. Your Content and Conduct.</h4>
              <p><b>Uploading Content. </b> You may be able to Stream from the Service if you have a Frisson account
                approved to use the Service. You may use your Stream to promote your business or artistic enterprise.
                If you choose to upload User Content for use in your Stream, you must not submit to the Service any
                Content that does not comply with this Agreement (including the Frisson <a target='_blank'
                                                                                           href='/community-guidelines'>Community
                  Guidelines</a>) or applicable law.
                For example, the Content you submit must not include third-party intellectual property (such as
                copyrighted material) unless you have permission from that party or are otherwise legally entitled to do
                so.
                You are legally responsible for the Content you submit to and Stream through the Service, including any
                licenses, fees, commissions, or payouts required by law for usage of said Content. We may use automated
                systems that analyze your Content to help detect infringement and abuse, such as spam, malware, and
                illegal content.
              </p>
              <p><b>Rights you Grant. </b>While you retain ownership rights in your Content, we do require that you
                grant certain rights to Frisson and other users of the Service, which is described below.
              </p>
              <p>
                <b>License to Frisson. </b>
                Unless otherwise agreed to in a written agreement between you and Frisson that was signed by an
                authorized representative of Frisson, if you submit, transmit, display, perform, post, or store Content
                using the Frisson Service
                (via the Stream or otherwise), you grant Frisson, its successors and Affiliates, to the furthest extent
                and for the maximum duration permitted by applicable law (including in perpetuity if permitted under
                applicable law),
                an unrestricted, worldwide, non-exclusive, royalty-free, sublicensable and transferable license right
                to: (a) use, reproduce, distribute, prepare derivative works, display and perform it such Content
                (including without limitation)
                for promoting and redistributing part or all of the Frisson Service in any, media, media channels, form,
                or format now known or later developed or discovered; and (b) use the name, identity, likeness, and
                voice
                (or other biographical information) that you submit in connection with such Content. Should such Content
                contain the name, identity, likeness, and voice (or other biographical information) of third parties,
                you represent
                and warrant that you have obtained the appropriate licenses and/or consents for your use of such Content
                and that Frisson and its sub-licensees are allowed to use them to the extent indicated in these Terms
                and Conditions.
              </p>
              <p>
                <b>License to Other Users. </b>
                You also grant every and each other user of the Service a worldwide, non-exclusive, royalty-free license
                to access your Content through the Service, and to use that Content, including to reproduce, distribute,
                prepare derivative works,
                display, and perform it, only as enabled by a feature of the Service (such as video playback, Crowd
                Chat, or embeds). For clarity, this license does not grant any permissions or rights for any other user
                to make use of your Content
                independent of the Service.
              </p>
              <p>
                <b>Duration of License. </b>
                With respect to the Stream, the rights granted by you hereunder terminate once you delete such Content
                from the Frisson Service, or generally by closing your account, except:(a) to the extent you shared it
                with others as part of
                the Frisson Service and others copied or stored portions of the Content; (b) Frisson used it for
                promotional purposes; and (c) for the reasonable time it takes to remove from backup and other systems.
                You understand and agree,
                however, that Frisson may retain, but not display, distribute, or perform, server copies of your videos
                that have been removed or deleted.
              </p>
              <p>
                <b>Right to Monetize. </b>
                By using the Service, you grant to Frisson the right to monetize your Content on the Service and
                otherwise. Such monetization methods and placements may vary, but they may include displaying
                advertisements within or around
                Platform Content, User Content, the Stream, the website, or any other Content created from or using the
                Service and it’s applications or software. This Agreement does not entitle you to any payments, though
                any payments you
                may be entitled to receive from Frisson under any other agreement between you and Frisson (including for
                example payments under the Frisson Partner Program (as may be made available from time to time), Stream
                tickets or access
                fees, chat or game content) will be treated as royalties. Frisson may withhold taxes from such payments
                to the extent required by law.
              </p>
              <p>
                <b>Removing Your Content. </b>
                You may remove your Content from the Service at any time. Additionally, you have the option to make a
                copy of your Content before removing it. You must remove your Content if you no longer have the rights
                or licenses
                required by these terms.
              </p>
              <p>
                <i>If you remove Content or Streams from the Service, it does not remove any content from 3rd party
                  platforms utilized while streaming or uploading the Content. You are fully responsible for managing
                  your Content
                  on these 3rd party platforms.
                </i>
              </p>
              <p>
                <b><i>Removal of Content By Frisson. </i></b>
                If we, in our sole discretion, determine that any Content is in breach of this Agreement or may cause
                harm to Frisson, our users, or third parties, we may at our discretion edit, modify, delete, remove, or
                take down that Content.
                We will use reasonable efforts to provide you the reason for our action unless we reasonably believe
                that in doing so: (a) would break any law or order from a legal enforcement authority or would otherwise
                put Frisson or our
                Affiliates at risk of legal liability; (b) would jeopardize an investigation or the integrity or
                operation of the Service; or (c) would cause harm to Frisson, our Affiliates, any user, or other third
                party. More information about
                reporting and enforcement (including how to appeal any removal of Content) can be obtained by contacting
                compliance@frisson.live.
              </p>
              <p>
                <b><i>Copyright Protection. </i></b>
                We provide tools and information to help copyright holders manage their intellectual property. If you
                believe your copyright has been infringed on the Service, please go to notify us at <a target='_blank'
                                                                                                       rel='noopener noreferrer'
                                                                                                       href='mailto:compliance@frisson.live?Subject='> compliance@frisson.live</a>.
              </p>
              <p>
                We outline our process of responding to and resolving notices of alleged copyright infringement in our
                Frisson Copyright Center. Should a user repeatedly infringe on the rights of copyright holders, our
                policies provide for the termination of that user’s account.
              </p>
              <br />
              <h4>11. Account Suspension & Termination.</h4>
              <p>
                <b>Terminations by You. </b>
                Follow these instructions to delete the Service from your Frisson Account at any time, which in turn
                closes your Frisson channel and removes your data. Prior to removal, you also have the option to
                download a copy of your data.
              </p>
              <p>
                <b>Terminations and Suspensions by Frisson for Cause. </b>
                To the fullest extent permitted by applicable law, Frisson reserves the right, without notice and in our
                sole discretion, to terminate your license to use the Service (including to post Content and Streams)
                and to block or
                prevent your future access to and use of the Service, including, without limitation, where we determine
                that: (a) your use of the Service violates the Agreement or applicable law; (b) we are required to do so
                to comply with
                a court order or legal requirement; or (c) you fraudulently use or misuse the Service.
              </p>
              <p>
                <b>Terminations by Frisson for Service Changes. </b>
                Frisson may terminate your access, or your Frisson account’s access to all or part of the Service if we
                believe we are unable to continue providing the Service to you due to technical or legitimate business
                reasons.
              </p>
              <p>
                <b>Notice for Termination or Suspension. </b>
                We will use commercially reasonable efforts to notify you with the reason for termination or suspension
                by Frisson unless we determine that to do so: (a) would violate applicable law, regulations, or orders
                from regulatory authorities,
                (b) compromise an ongoing investigation conducted by a regulatory authority, or (c) that any notice may
                cause harm to you, third parties, Frisson, and/or its affiliates.
                If Frisson is terminating your access for Service changes, you will be provided where reasonably
                possible with sufficient time to export your Content from the Service.
              </p>
              <p>
                <b>Effect of Account Suspension or Termination. </b>
                Should your Frisson account be terminated or your Frisson account’s access to the Service becomes
                restricted, this Agreement will continue to apply, and you may continue using certain aspects of the
                Service (such as viewing only) without an account.
                If you believe your Frisson account has been terminated in error, please contact us at <a
                target='_blank' rel='noopener noreferrer'
                href='mailto:support@frisson.live?Subject='>support@frisson.live</a>.
              </p>
              <br />
              <h4>12. About Software in the Service </h4>
              <p>
                <b>Downloadable Software.</b>
                The Service may require or include downloadable software (such as the Frisson application). Frisson
                gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the
                software provided to you by Frisson
                as part of the Service, unless that software is governed by additional terms which provide a license.
                This license serves only to provide you access to the Service provided by Frisson, and is bound by this
                Agreement.
                You are not allowed to reproduce, sell, license, translate, alter, adapt, modify, reverse-engineer,
                create derivative works of, or attempt to extract the source code of that software without Frisson’s
                written permission.
                When installing that software, you give permission for that software to automatically update when a new
                feature or version is available.
              </p>
              <p>
                <b>Open Source.</b>
                Frisson may use or provide to you software as a part of the Service that may be offered under an open
                source license. Please be sure to read those license terms, as they may have expressly
                stated provisions which override some of these terms.
              </p>
              <br />
              <h4>13. Other Legal Terms</h4>
              <p>
                <b>Warranty Disclaimer. </b>
                OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW, THE SERVICE IS PROVIDED “AS IS”
                AND FRISSON DOES NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE,
                WE DON’T MAKE ANY WARRANTIES ABOUT: (A) THE CONTENT PROVIDED THROUGH THE SERVICE; (B) THE SPECIFIC
                FEATURES OF THE SERVICE, OR ITS ACCURACY, RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS;
                OR (C) THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE SERVICE.
              </p>
              <p>
                <b>Limitation of Liability. </b>
                EXCEPT AS REQUIRED BY APPLICABLE LAW, FRISSON, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS
                WILL NOT BE RESPONSIBLE FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL,
                OR ANTICIPATED SAVINGS; LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES,
                INCLUDING WHETHER OR NOT CAUSED BY:
              </p>
              <br />
              <center>
                <p> ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;</p>
                <p> PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE;</p>
                <p> ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICE;</p>
                <p> ANY INTERRUPTION OR CESSATION OF THE SERVICE;</p>
                <p> ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY;</p>
                <p> ANY CONTENT WHETHER SUBMITTED BY A USER OR YOUTUBE, INCLUDING YOUR USE OF CONTENT; AND/OR</p>
                <p> THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.</p>
                <p> THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON WARRANTY,
                  CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.</p>
                <p> FRISSON AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE
                  IS LIMITED TO THE GREATER OF: (A) THE AMOUNT OF REVENUE THAT FRISSON HAS PAID TO YOU FROM YOUR USE OF
                  THE SERVICE IN THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE, IN WRITING TO YOUTUBE, OF THE CLAIM; AND
                  (B) USD $500.</p>
              </center>
              <br />
              <p>
                <b>Indemnity. </b>
                To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless Frisson, its
                Affiliates, officers, directors, employees and agents, from and against any and all claims, damages,
                obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's
                fees) arising
                from: (i) your use of and access to the Service; (ii) your violation of any term of this Agreement;
                (iii) your violation of any third party right, including without limitation any copyright, property, or
                privacy right;
                or (iv) any claim that your Content caused damage to a third party. This defense and indemnification
                obligation will survive this Agreement and your use of the Service.
              </p>
              <p>
                <b>Third-Party Links.</b>
                The Service may contain links to third-party websites and online services that are not owned or
                controlled by Frisson. Frisson has no control over, and assumes no responsibility for, such websites and
                online services. Be aware when you leave the Service; we suggest you read the terms and <a
                target='_blank' href='/privacy-policy'>Privacy Policy</a> of each third-party
                website and online service that you visit.
              </p>
              <br />
              <h4>14. About this Agreement</h4>
              <p>
                <b>Modifying this Agreement. </b>
                We may modify this Agreement, for example, to reflect changes to our Service or for legal, regulatory,
                or security reasons. Frisson will use commercially reasonable efforts to provide reasonable advance
                notice of any material modifications to this Agreement and the opportunity to review them, except that
                modifications addressing
                newly available features of the Service or modifications made for legal reasons may be effective
                immediately without notice. Modifications to this Agreement will only apply going forward. If you do not
                agree to the modified terms, you must promptly, and in any event within 72 hours, remove any Content you
                have
                uploaded and discontinue your use of the Service.
              </p>
              <p>
                <b>Continuation of this Agreement. </b>
                If your use of the Service ends, the following terms of this Agreement will continue to apply to you:
                “Other Legal Terms”, “About This Agreement”, and the licenses granted by you will continue as described
                under “Duration of License”.
              </p>
              <p>
                <b>Severance.</b>
                If it turns out that a particular term of this Agreement is not enforceable for any reason, this will
                not affect any other terms.
              </p>
              <p>
                <b>No Waiver.</b>
                If you fail to comply with this Agreement and we do not take immediate action, this does not mean that
                we are giving up any rights that we may have (such as the right to take action in the future).
              </p>
              <p>
                <b>Interpretation. </b>
                In these terms, “include” or “including” means “including but not limited to,” and any examples we give
                are for illustrative purposes.
              </p>
              <p>
                <b>Governing Law. </b>
                All claims arising out of or relating to these terms or the Service will be governed by California law,
                except California’s conflict of laws rules, and will be litigated exclusively in the federal or state
                courts of Los Angeles County, California, USA. You and Frisson consent to personal jurisdiction in those
                courts.
              </p>
              <p>
                <b>Limitation on Legal Action. </b>
                YOU AND FRISSON AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE
                WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
                BARRED.
              </p>
            </div>
          </Row>
        </Container>
        <Footer />
      </LayoutDefault>
    </Fragment>
  );
};
export default TermsAndConditions;
