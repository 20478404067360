import React from "react";

import "./styles.scss";
const InputFormik = ({
  type = "text",
  name,
  icon,
  placeholder,
  className,
  formik,
  readOnly = false,
  max,
  disabled = false
}) => {
  return (
    <div className="wrapper-control">
      <div className={`input-content-control ${readOnly ? "not-allow" : ""}`}>
        <i className={`fa ${icon}`} aria-hidden="true" />
        <input
          readOnly={readOnly}
          disabled={disabled}
          type={type}
          value={formik.values[name]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`input-custom-control ${
            formik.touched[name] && formik.errors[name] ? "is-invalid" : null
          }`}
          placeholder={placeholder}
          name={name}
          autoComplete="off"
          max={max}
        />
      </div>
      {formik.touched[name] && formik.errors[name] ? (
        <div className="error">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default InputFormik;
