import React, { useEffect, useState } from 'react';
import './styles.scss';
import sprite from '../../assets/svg/sprite.svg';
import { Modal, ProgressBar, Spinner } from 'react-bootstrap';
import UserAPI from '../../services/UserAPI';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRemoteStreamShutdownRequest,
  StreamRestartRequest
} from '../../slices/user';
import AvatarUpload from '../avatar-upload';
import { getAuth } from '../../selectors/auth.selector';
import { registerSuccess } from '../../slices/auth';
import { dataRemoteShedule } from '../../selectors/user.selector';
import { NavLink } from 'react-router-dom';

const RemoteStatus = () => {
  const restApiStatus = useSelector(dataRemoteShedule);
  const loading = useSelector((state) => state.user.loading);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const dispatch = useDispatch();
  const [customPerStatus, setCustomPerStatus] = useState(undefined);
  const customConfiguration = useSelector(
    (state) => state?.user?.dataRemotePerApi?.schedule
  );
  const restApiCustom = useSelector((state) => state?.user?.dataRemotePerApi);
  const [timeStart, setTimeStart] = useState('');
  const [timeEnd, setTimeEnd] = useState('');
  let arrayDate = [
    { day: 'Monday' },
    { day: 'Tuesday' },
    { day: 'Wednesday' },
    { day: 'Thursday' },
    { day: 'Friday' },
    { day: 'Saturday' },
    { day: 'Sunday' }
  ];
  console.log('restApiCustom-->', restApiCustom);
  let d = new Date();
  let n = d.getDay();
  const shutDownButton = () => {
    dispatch(getRemoteStreamShutdownRequest());
  };

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ampm;
    return strTime;
  }

  useEffect(() => {
    setTimeStart(restApiStatus?.rpbTimes?.startTime);
    setTimeEnd(restApiStatus?.rpbTimes?.endTime);
    setCustomPerStatus(restApiStatus?.status);
    // UserAPI.getRemotePerformanceDataApi().then((res) => {
    //   setTimeStart(res?.data?.rpbTimes?.startTime);
    //   setTimeEnd(res?.data?.rpbTimes?.endTime);
    //   setCustomPerStatus(res?.data?.status);
    // });
  }, []);
  const returnDataBar = () => {
    if (customPerStatus?.origin?.setupStage === 0) {
      return 0;
    }
    if (customPerStatus?.origin?.setupStage === 1) {
      return 12.5;
    }
    if (customPerStatus?.origin?.setupStage === 2) {
      return 25;
    }
    if (customPerStatus?.origin?.setupStage === 3) {
      return 37.5;
    }
    if (customPerStatus?.origin?.setupStage === 4) {
      return 50;
    }
    if (customPerStatus?.origin?.setupStage === 5) {
      return 62.5;
    }
    if (customPerStatus?.origin?.setupStage === 6) {
      return 75;
    }
    if (customPerStatus?.origin?.setupStage === 7) {
      return 87.5;
    }
    if (customPerStatus?.origin?.setupStage === 8) {
      return 100;
    }
  };
  const getDateStatus = () => {
    let ar = customPerStatus?.status?.setupStage?.split(' - ');
    let bug = ar[1].split(' ');
    return bug;
  };
  const dataCorrect = () => {
    let i = getDateStatus()[0].split('/');
    if (i[0] === '1') {
      return `January ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '2') {
      return `February ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '3') {
      return `March ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '4') {
      return `April ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '5') {
      return `May ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '6') {
      return `June ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '7') {
      return `July ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '8') {
      return `August ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '9') {
      return `September ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '10') {
      return `October ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '11') {
      return `November ${i[1]}, ${i[2]}`;
    }
    if (i[0] === '12') {
      return `December ${i[1]}, ${i[2]}`;
    }
    return false;
  };
  const timeCorrect = () => {
    let i = getDateStatus()[1];
    return `${getDateStatus()[1]} ${getDateStatus()[2]}`;
  };
  const handleToggleModal = (value) => {
    setOpenModalStatus(value);
  };
  const openModal = () => {
    return (
      <Modal show={openModalStatus} onHide={() => handleToggleModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reconnect on restart?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: 'center', marginBottom: '40px' }}>
            Would you like to reconnect to the same stream link/id as the
            current performance? If you don't reconnect, a new stream link will
            be created once the remote performance restarts.
          </p>
          <div
            style={{
              textAlign: 'center',
              display: 'grid',
              justifyContent: 'center',
              gap: '15px'
            }}
          >
            <button
              className='button-reconnect'
              onClick={() => {
                dispatch(StreamRestartRequest({ reconnect: false }));
                setOpenModalStatus(false);
              }}
            >
              Don't Reconnect
            </button>
            <button
              className='button-stream'
              onClick={() => {
                dispatch(StreamRestartRequest({ reconnect: true }));
                setOpenModalStatus(false);
              }}
            >
              Reconnect To Existing Stream
            </button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}></div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      {openModal()}
      {customPerStatus === undefined ? (
        <Spinner
          animation='border'
          variant='primary'
          style={{ margin: '10% 50%' }}
        />
      ) : (
        <div className='remote-status-grid'>
          <div className='remote-status-left'>
            <h4>Dashboard</h4>
            <h5 style={{ marginTop: '25px' }}>Stream links:</h5>
            { restApiCustom?.config?.rtmpLink ?
                restApiCustom?.config?.rtmpLink.includes('https://') ?
                <NavLink target='__blank'
                to={{ pathname: restApiCustom?.config?.rtmpLink }}>{restApiCustom?.config?.rtmpLink}</NavLink> :
                <NavLink target='__blank'
                to={{ pathname: 'https://' + restApiCustom?.config?.rtmpLink }}>{restApiCustom?.config?.rtmpLink}</NavLink>
              : <p>---</p>
            }
            {/*<a href="www.frisson.live/?v=yt-id8ySD-S8f_K">*/}
            {/*  www.frisson.live/?v=yt-id8ySD-S8f_K*/}
            {/*</a>*/}
            <h5 style={{ marginTop: '30px' }}>Time since start:</h5>
            <p>
              {customPerStatus?.status?.status !== 'Offline'
                ? timeStart.length > 0
                  ? timeStart
                  : 'Not set'
                : '---'}
            </p>
            <h5 style={{ marginTop: '28px' }}>Time since end:</h5>
            <p>
              {customPerStatus?.status?.status !== 'Offline'
                ? timeEnd.length > 0
                  ? timeEnd
                  : 'Not set'
                : '---'}
            </p>
            {/*<p>{timeEnd?.length ? timeEnd.replace(/^(\d+):(\d+)(\w+)$/, `$1h $2m $3`)  : 'Not set'}</p>*/}
            <div className='remote-status-left-button'>
              {/*<button className='button-green'>*/}
              {/*  <svg fill='#fff' style={{ width: '20px', height: '20px' }}>*/}
              {/*    <use href={sprite + '#clock'}></use>*/}
              {/*  </svg>*/}
              {/*  Extend End Time*/}
              {/*</button>*/}
              <button
                className='button-orange'
                onClick={() => {
                  setOpenModalStatus(true);
                }}
              >
                <svg fill='#fff' style={{ width: '20px', height: '20px' }}>
                  <use href={sprite + '#refresh'}></use>
                </svg>
                Restart
              </button>
              <button className='button-red' onClick={shutDownButton}>
                <svg fill='#fff' style={{ width: '20px', height: '20px' }}>
                  <use href={sprite + '#circle_close'}></use>
                </svg>
                Shut Down
              </button>
            </div>
          </div>
          <div className='remote-status-right'>
            <h4>Status</h4>
            <div className='remote-status-warning'>
              <svg fill='#fff' style={{ width: '20px', height: '20px' }}>
                <use href={sprite + '#ep_warning'}></use>
              </svg>
              {customPerStatus?.status?.status}
            </div>
            <div className='remote-status-progress'>
              <div>Connecting Stream</div>
              <ProgressBar
                animated
                now={returnDataBar()}
                style={{ height: '10px' }}
              />
            </div>
            <div className='no-music-status'>
              <p>{customPerStatus?.origin?.statusMessage}</p>
              <div className='no-music-status-calendar'>
                <svg fill='#fff' style={{ width: '20px', height: '20px' }}>
                  <use href={sprite + '#calendar'}></use>
                </svg>
                {dataCorrect()}
              </div>
              <div className='no-music-status-clock'>
                <svg fill='#fff' style={{ width: '20px', height: '20px' }}>
                  <use href={sprite + '#clock'}></use>
                </svg>
                <span>{timeCorrect().toLowerCase()}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default RemoteStatus;
