import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// layout
import { LayoutDefault } from '../../layouts';
import { Header } from '../../components/header';
import { Footer } from '../../container/footer';
// data
import frisson from '../../data/frisson.json';
import './styles.scss';

const TermsOfService = () => {
  return (<Fragment>
    <Helmet>
      <title>{`${frisson.title}`}</title>
    </Helmet>
    <LayoutDefault className='bg-light template-font-1 bg_color--3'>
      <Header />
      <Container className='pl_md--60 pl_sm--0'>
        <Row>
          <div className='terms-service'>
            <h2>Frisson Terms & Conditions</h2>
            <blockquote>
              <h4>Introduction</h4>
              <p>We appreciate your interest in the Frisson platform, products, features, and services which we make
                available for your use as part of the platform (collectively the, “Service”).</p>
            </blockquote>
            <br />
            <h4>1. Our Service</h4>
            <p>The Frisson Services enables you to build and stream virtual environments and live and recorded
              performances using Platform Content (as described below) and your own User Content (as described below)
              which you may choose to insert, upload or download to the Frisson Services under your own accord and then
              provide the combined output to various video and audio capture programs and sites via the Stream (as
              described below). The resulting Streams allow other users of the Frisson Services to find, watch, share,
              and engage with the Streams, whether live or recorded, acting as a distribution channel for advertisers
              and original content creators of all sizes.</p>
            <p>Crowd Chat is a feature that extends from the Stream. When a Stream is created using the Frisson
              Services, it creates a chat session on the Frisson platform through which users can chat with one another
              and engage with the artist. This is where users can vote on images to be passed back into the Stream to be
              displayed to the audience if a Crowd Chat admin (established by the user Streaming) approves the
              image.</p>
            <br />
            <h4>2. Applicable Terms</h4>
            <p>Your use of the Frisson Services is subject to these terms, the Terms of Sale, the <a target='_blank'
                                                                                                     href='/community-guidelines'>Community
              Guidelines</a>
              and the Privacy and Copyright Policies which may from time to time be updated (together, this
              "Agreement"). Your agreement with us will also include the Advertising on Frisson Policies if your Stream
              includes advertising or sponsorships to the Frisson Services or if your Stream incorporates paid
              promotions in your content. Any other links or references provided in these terms are for informational
              use only and are not part of the Agreement.</p>
            <br />
            <h4>3. Your Service Provider</h4>
            <p>The Frisson Services is provided by Frisson Studios, Inc., a Delaware corporation, located at 340 S Lemon
              Ave #4257 Walnut, CA 91789 (referred to as “Frisson”, “we”, “us”, or “our”). References to Frisson’s
              “Affiliates” in the Agreement means the other companies or entities partnered with Frisson, now or in the
              future, to deliver the Frisson Service</p>
            <p>Please read this Agreement carefully and make sure you understand it. If you do not understand or do not
              accept any part of the Agreement, then you may not use the Frisson Services. If you use the Frisson
              Services, you agree to comply with this Agreement at all times.</p>
            <br />
            <h4>4. Limited License</h4>
            <p>Subject to the terms and conditions of this Agreement, Frisson grants you a non-exclusive,
              non-transferable license, to access and use the Services for your personal use only. Such license is
              subject to this Agreement and does not include: (a) any commercial use of the Services or the Content
              therein; (b) the distribution, public performance, public display, making publicly available, or other
              unauthorized exploitation of any Content; (c) modifying or otherwise making any derivative uses of the
              Services or the Content, or any portion thereof; (d) downloading (other than the page caching) of any
              portion of the Services, the Content or any information contained therein, except as expressly permitted
              on the Services; or (f) any use of the Services or the Content other than for their intended purposes. Any
              use of the Services or the Content other than as speciﬁcally authorized herein, without the prior written
              permission of Frisson, is strictly prohibited and will terminate the license granted herein. Such
              unauthorized use may also violate applicable laws, including without limitation copyright and trademark
              laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in
              this Agreement shall be construed as conferring any license to intellectual property rights, whether by
              estoppel, implication or otherwise. Frisson may revoke this license at any time.</p>
            <br />
            <h3>Who may use the Frisson Services?</h3>
            <br />
            <h4>5. Age Requirements</h4>
            <p>You must be at least 18 years old to use the Frisson Services.
            </p>
            <br />
            <h4>6. Permission by Parent or Guardian</h4>
            <p>If you are under 18, you represent that you have your parent or guardian’s permission to use the Frisson
              Services. Please have them read this Agreement with you.
            </p>
            <br />
            <h4>7. Permission by Parent or Guardian</h4>
            <p>If you are under 18, you represent that you have your parent or guardian’s permission to use the Frisson
              Services. Please have them read this Agreement with you.
            </p>
            <p>If you are a parent or legal guardian of a user under the age of 18, by allowing your child to use the
              Frisson Services, you are subject to the terms of this Agreement and responsible for your child’s activity
              on the Frisson Services.</p>
            <br />
            <h4>8. Businesses</h4>
            <p>If you are using the Frisson Services on behalf of a company or organization, you represent that you have
              authority to act on behalf of that entity, and that such entity accepts this Agreement.</p>
            <br />
            <h3>Your Use of the Frisson Services</h3>
            <br />
            <h4>8. Platform Content on the Frisson Services</h4>
            <p>Platform Content on the Frisson Services includes all software, 2d & 3d models, videos, audio, images,
              text, Frisson branding (including trade names, trademarks, service marks, or logos), interactive features
              such as Crowd Chat, metrics, and other materials which are provided directly by Frisson. For clarity, no
              original content nor User Content is provided by Frisson other than the content directly owned by
              Frisson.</p>
            <br />
            <h4>9. User Content on the Frisson Services</h4>
            <p>User content on the Frisson Services includes videos, audio (including, but not limited to, music and
              other sounds), images, text (including, but not limited to, comments and scripts), branding (including
              trade names, trademarks, service marks, or logos), interactive features, software, metrics, and other
              materials which are be provided by you or a third-party (collectively the, "User Content”). For clarity,
              User Content is any content which is not provided directly by Frisson in providing the Frisson
              Services.</p>
            <p>User Content is the responsibility of the person or entity that provides it to the Frisson Services,
              including the consequences of posting, publishing, or streaming it. Frisson is under no obligation to host
              or serve User Content. You represent and warrant that: (1) you are the content creator or control or own
              all the rights in and to use or upload the User Content or have sufficient rights and authority to
              otherwise grant the rights provided herein; (2) your User Content will not and does not: (a)
              misappropriate, infringe, or violate any third-party rights, including any copyright, trademark, trade
              secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary
              right, or (b) defame any other person.</p>
            If you see any User Content you believe does not comply with this Agreement, including by violating the
            <a target='_blank' href='/community-guidelines'>Community Guidelines</a> or the law, you can report it to us
            at <a target='_blank' rel='noopener noreferrer'
                  href='mailto:compliance@frisson.live?Subject='> compliance@frisson.live</a>.
            <br />
            <h4>10. Streaming on the Frisson Services</h4>
            <p>The Frisson Services allows you to stream live or recorded captures of Platform Content and User Content
              (collectively the, “Content”) through partner platforms and websites (collectively the, “Stream,”).
              Frisson is excited to stream live and recorded Content with some of our most valued partners, but our
              partners must be held accountable. Below you’ll find our best practices surrounding content distribution
              via live or recorded video streaming through the Frisson Services on our site as well as partner sites.
              Your use of the Frisson Services must at all times comply with these guidelines:</p>
            <ol>
              <li> The advertiser, promoter, or production company is responsible for securing and making sure they have
                all necessary rights to legally broadcast the live stream on Frisson and our Streaming Partners. These
                rights include but are not limited to:
                <ol className='litera'>
                  <li> Public performance rights</li>
                  <li> Music licensing</li>
                  <li> Video licensing</li>
                  <li> Other publicity rights, including use of any branded content or logos</li>
                </ol>
                <li> Content in the live stream must adhere to our <a target='_blank' href='/community-guidelines'>Community
                  Guidelines</a>.
                </li>
                <li> The promoter or producer must monitor the Stream to ensure compliance with our Community
                  Guidelines, including the Stream’s coupled Crowd Chat located at <a
                    href='https://frisson.live/jointhecrowd'> https://frisson.live/jointhecrowd</a> during
                  the entire duration of any live stream.
                  <ol className='litera'>
                    <li> Youtube: <a href='https://www.youtube.com/t/terms'>https://www.youtube.com/t/terms</a></li>
                    <li> Facebook: <a
                      href='https://www.facebook.com/legal/terms'>https://www.facebook.com/legal/terms</a></li>
                    <li> Twitch: <a
                      href='https://www.twitch.tv/p/legal/terms-of-service/'>https://www.twitch.tv/p/legal/terms-of-service/</a>
                    </li>
                  </ol>
                </li>
                <li> Promotors and producers must agree separately while using the Frisson Services to all applicable
                  terms and conditions for any Streaming Partners which they utilize:
                </li>
                <li> Monetization features on Streams may allow users to tip, buy stream actions, purchase tickets for
                  access, or other transactions from time to time.
                </li>
                <li> Producers of Streams that are monetized may be eligible to earn a cut of the revenue generated on
                  each producer’s monetized Stream.
                </li>
              </li>
            </ol>
            <p>We reserve all rights to conduct policy reviews on your stream from time to time, with or without notice,
              and, in our sole discretion determine whether or not to approve it for use with the Frisson Services.</p>
            <br />
            <h4>11. Frisson Accounts and Additional Features</h4>
            <p>You can use parts of the Frisson Services without having a Frisson account, such as browsing and
              searching for Content. However, you need a Frisson account to access some features. With a Frisson
              account, you may be able to interact with each Stream’s Crowd Chat, allocate stats to unlock rewards,
              subscribe to artist streams, distribute Content through the Frisson Services, and more. You can create a
              Frisson account by following these instructions.</p>
            <p>Keeping your password confidential is necessary to protect your Frisson account. You should never reuse
              your Frisson account password on third-party applications and accounts.</p>
            <br />
            <h4>12. Your Information</h4>
            <p>Our <a target='_blank' href='/privacy-policy'>Privacy Policy</a> explains how we treat your personal data and protect your privacy when you use the
              Frisson Services.</p>
            <p>How we protect your privacy and treat your personal data while you use the service is explained in Our
              <a target='_blank' href='/privacy-policy'>Privacy Policy</a>.</p>
            <p>Any audio or audiovisual content uploaded by you to the Frisson Services may be edited, modified or
              processed by us in in our sole discretion as we may determine to be consistent with the Frisson
              Services.</p>
            <p>Users of the Frisson Services may elect to have a user representative character that can be customized by
              a user with both earned and purchased digital content items in accordance with the Terms of Sale. </p>
            <br />
            <h4>13. Permissions and Restrictions</h4>
            <p>If you fully comply with this Agreement and applicable law, then you may access and use the Frisson
              Services as made available to you. You may view or listen to Content or interact with non-admin Crowd Chat
              features (including uploading media content) for your personal, non-commercial use.</p>
            <p>While you are using the Frisson Services, the following restrictions apply. You are not allowed to:</p>
            <ol>
              <li>access, broadcast, transmit, stream, display, reproduce, download, sell, license, translate, alter,
                adapt, modify, create derivative works of or otherwise use any part of the Frisson Services or any
                Content except: (a) as expressly authorized by the Frisson Services; or (b) with prior written
                permission from Frisson and, if applicable, the respective rights holders;
              </li>
              <li>run contests on or through the Frisson Services without the prior written permission of Frisson;</li>
              <li>delete, remove, circumvent, disable, damage, or otherwise interfere with any part of the Frisson
                Services (or attempt to do any of these things), including security-related features or features that
                (a) prevent or restrict the copying or other use of Content or (b) limit the use of the Frisson Services
                or Content;
              </li>
              <li>harvest, scrape, or gather any personal information that might identify a person (for example, emails,
                usernames), unless permitted by that person or allowed under part (3) above;
              </li>
              <li>access the Frisson Services via automated means (such as bots, botnets or scrapers) except (a), in
                accordance with Frisson’s applicable policies in the case of public search engines; or (b) with the
                prior written permission of Frisson;
              </li>
              <li>encourage or cause any inaccurate measurements of legitimate user engagement with the Frisson
                Services, including via paying people or services or providing people or services with incentives to
                increase a video’s views, active chat users, chat engagements, likes or other engagements, or to
                increase, damage, or improve Frisson account metrics, or otherwise manipulate metrics in any manner;
              </li>
              <li>misuse any reporting processes such as reporting, appeals processes, disputes, complaints, or
                flagging, including by making baseless, intentionally misleading, or frivolous submissions;
              </li>
              <li>use the Frisson Services to distribute or communicate unsolicited promotional or commercial content or
                other undesirable or mass solicitations;
              </li>
              <li>use the Stream to view or listen to Content other than for personal, non-commercial use (for example,
                you may not publicly screen videos or stream music from the Frisson Services); or
              </li>
              <li>use the Frisson Services to (a) sell any promotional material, advertising, or sponsorships, placed
                on, around, or within the Frisson Services, the Stream, or Content, other than those allowed in the
                applicable Frisson policies (such as compliant product placements offered directly through the Frisson
                Services) or those allowed on Streaming Partner sites, subject to Streaming Partners’ own Terms of
                Service listed above; or (b) sell promotional material, advertising, or sponsorships on any page of any
                application or website that only contains Content from the Stream or Service, or where Content from the
                Stream or Service is the primary basis for such sales.
              </li>
            </ol>
            <br />
            <h4>14. Reservation</h4>
            <p>You do not gain any ownership of or rights to any aspect of the Frisson Services while using the Frisson
              Services, including emails, usernames, or any other Content posted by Frisson or others</p>
            <br />
            <h4>15. Changes to the Frisson Services</h4>
            <p>Frisson may edit, amend, modify or change any of the terms of this Agreement by posting the amended
              terms. Your continued use of the Frisson Services after the effective date of the revised Agreement
              constitutes your acceptance of the revised Agreement. You understand and agree that there will be times
              when Frisson makes such edits, amendments, modification and changes without notice, including, without
              limitation at such times as we determine the need to take action to comply with legal requirements,
              prevent abuse, or to improve the security and operability of our Service.</p>
            <br />
            <h3>Your Content and Conduct</h3>
            <br />
            <h4>16. Uploading Content</h4>
            <p>You may be able to Stream from the Frisson Services if you have a Frisson account approved to use the
              Frisson Services. You may use your Stream to promote your business or artistic enterprise. If you choose
              to upload User Content for use in your Stream, you must not submit to the Frisson Services any Content
              that does not comply with this Agreement (including the Frisson <a target='_blank'
                                                                                 href='/community-guidelines'>Community
                Guidelines</a>) or applicable law.
              For example, the Content you submit must not include third-party intellectual property (such as
              copyrighted material) unless you have permission from that party or are otherwise legally entitled to do
              so. You are legally responsible for the Content you submit to and Stream through the Frisson Services,
              including any licenses, fees, commissions, or payouts required by law for usage of said Content. We may
              use automated systems that analyze your Content to help detect infringement and abuse, such as spam,
              malware, and illegal content.</p>
            <br />
            <h4>17. Rights you Grant</h4>
            <p>While you retain ownership rights in your Content, we do require that you grant certain rights to Frisson
              and other users of the Frisson Services, which is described below.</p>
            <br />
            <h4>18. License to Frisson</h4>
            <p>Unless otherwise agreed to in a written agreement between you and Frisson that was signed by an
              authorized representative of Frisson, if you submit, transmit, display, perform, post, or store Content
              using the Frisson Service (via the Stream or otherwise), you grant Frisson, its successors and Affiliates,
              to the furthest extent and for the maximum duration permitted by applicable law (including in perpetuity
              if permitted under applicable law), an unrestricted, irrevocable, perpetual, worldwide, non-exclusive,
              fully paid up, royalty-free, sublicensable and transferable unlimited license right to: (a) use,
              reproduce, distribute, exploit, prepare derivative works, adapt, copy, fix, translate, reformat, add to
              and delete from, rearrange and transpose, manufacture, publish, distribute, sell, license, sublicense,
              transfer, rent, lease, transmit, publicly display, make publicly available, publicly perform, provide
              access to, broadcast, practice, display and perform such Content (including without limitation) for
              promoting and redistributing part or all of the Frisson Service in any, media, media channels, form, or
              format now known or later developed or discovered; and (b) use the name, identity, likeness, and voice (or
              other biographical information) that you submit in connection with such Content. Should such Content
              contain the name, identity, likeness, and voice (or other biographical information) of third parties, you
              represent and warrant that you have obtained the appropriate licenses and/or consents for your use of such
              Content and that Frisson and its sub-licensees are allowed to use them to the extent indicated in this
              Agreement.</p>
            <p>In the event you upload or otherwise transmit to Frisson any concepts, ideas, or feedback relating to
              Frisson’s products or services, you shall not be entitled to any compensation for any such submission,
              unless expressly agreed between you and Frisson, and Frisson may freely use any such submission in any
              manner it deems appropriate. Any such submission by you shall not create any contractual relationship
              between you and Frisson. Except to the extent that any such waiver is prohibited by law, you hereby waive
              the beneﬁt of any provision of law known as "moral rights" or "droit moral" or any similar law in any
              country of the world. You represent and warrant that the User Content does not infringe upon the
              copyright, trademark, patent, trade secret or other intellectual property rights of any third party. You
              further represent and warrant that you will not use or contribute User Content that is unlawful, tortious,
              defamatory, obscene, invasive of the privacy of another person, threatening, harassing, abusive, hateful,
              racist or otherwise objectionable or inappropriate. Frisson may remove any User Content and any related
              content or elements from the Platform at its sole discretion.</p>
            <br />
            <h4>19. License to Other Users</h4>
            <p>You also grant every and each other user of the Frisson Services a worldwide, non-exclusive, royalty-free
              license to access your Content through the Frisson Services, and to use that Content, including to
              reproduce, distribute, prepare derivative works, display, and perform it, as enabled by a feature of the
              Frisson Services (such as video playback, Crowd Chat, or embeds). For clarity, this license does not grant
              any permissions or rights for any other user to make use of your Content independent of the Frisson
              Services.</p>
            <br />
            <h4>20. Duration of License</h4>
            <p>With respect to the Stream, the rights granted by you hereunder terminate once you delete such Content
              from the Frisson Service, or generally by closing your account, except:(a) to the extent you shared it
              with others as part of the Frisson Service and others copied or stored portions of the Content; (b)
              Frisson used it for promotional purposes; and (c) for the reasonable time it takes to remove from backup
              and other systems. You understand and agree, however, that Frisson may retain, but not display,
              distribute, or perform, server copies of your videos that have been removed or deleted.</p>
            <br />
            <h4>21. Right to Monetize</h4>
            <p>By using the Frisson Services, you grant to Frisson the right to monetize your Content on the Frisson
              Services and otherwise. Such monetization methods and placements may vary, but they may include displaying
              advertisements within or around Platform Content, User Content, the Stream, the website, or any other
              Content created from or using the Frisson Services and it’s applications or software. This Agreement does
              not entitle you to any payments, though any payments you may be entitled to receive from Frisson under any
              other agreement between you and Frisson (including for example payments under the Frisson Partner Program
              (as may be made available from time to time), Stream tickets or access fees, chat or game content) will be
              treated as royalties. Frisson may withhold taxes from such payments to the extent required by law.</p>
            <br />
            <h4>22. Removing Your Content</h4>
            <p>You may remove your Content from the Frisson Services at any time. Additionally, you have the option to
              make a copy of your Content before removing it. You must remove your Content if you no longer have the
              rights or licenses required by these terms.</p>
            <p>If you remove Content or Streams from the Frisson Services, it does not remove any content from 3rd party
              platforms utilized while streaming or uploading the Content. You are fully responsible for managing your
              Content on these 3rd party platforms.</p>
            <br />
            <h4>22. Removal of Content By Frisson</h4>
            <p>If we, in our sole discretion, determine that any Content is in breach of this Agreement or may cause
              harm to Frisson, our users, or third parties, we may at our discretion edit, modify, delete, remove, or
              take down that Content. We will use reasonable efforts to provide you the reason for our action unless we
              reasonably believe that in doing so: (a) would break any law or order from a legal enforcement authority
              or would otherwise put Frisson or our Affiliates at risk of legal liability; (b) would jeopardize an
              investigation or the integrity or operation of the Frisson Services; or (c) would cause harm to Frisson,
              our Affiliates, any user, or other third party. More information about reporting and enforcement
              (including how to appeal any removal of Content) can be obtained by contacting <a target='_blank'
                                                                                                rel='noopener noreferrer'
                                                                                                href='mailto:compliance@frisson.live?Subject='> compliance@frisson.live</a>
            </p>
            <br />
            <h4>23. Copyright Protection</h4>
            <p>We provide tools and information to help copyright holders manage their intellectual property. If you
              believe your copyright has been infringed on the Frisson Services, please go to notify us at
              <a target='_blank'
                 rel='noopener noreferrer'
                 href='mailto:compliance@frisson.live?Subject='> compliance@frisson.live</a>.</p>
            <p>We outline our process of responding to and resolving notices of alleged copyright infringement in our
              Frisson Copyright Center. Should a user repeatedly infringe on the rights of copyright holders, our
              policies provide for the termination of that user’s account.</p>
            <br />
            <h3> Account Suspension & Termination</h3>
            <br />
            <h4>24. Terminations by You</h4>
            <p>Go to <a href='https://www.frisson.live/profile'> https://www.frisson.live/profile</a> and follow the
              instructions to delete the Frisson Services from your Frisson Account at any time, which in turn closes
              your Frisson channel and removes your data. Prior to removal, you also have the option to download a copy
              of your data.</p>
            <br />
            <h4>25. Terminations and Suspensions by Frisson for Cause</h4>
            <p>To the fullest extent permitted by applicable law, Frisson reserves the right, without notice and in our
              sole discretion, to terminate your license to use the Frisson Services (including to post Content and
              Streams) and to block or prevent your future access to and use of the Frisson Services, including, without
              limitation, where we determine that: (a) your use of the Frisson Services violates the Agreement or
              applicable law; (b) we are required to do so to comply with a court order or legal requirement; or (c) you
              fraudulently use or misuse the Frisson Services.</p>
            <br />
            <h4>26. Terminations by Frisson for Service Changes</h4>
            <p>Frisson may terminate your access, or your Frisson account’s access to all or part of the Frisson
              Services if we believe we are unable to continue providing the Frisson Services to you due to technical or
              legitimate business reasons.</p>
            <br />
            <h4>27. Notice for Termination or Suspension</h4>
            <p>We will use commercially reasonable efforts to notify you with the reason for termination or suspension
              by Frisson unless we determine that to do so: (a) would violate applicable law, regulations, or orders
              from regulatory authorities, (b) compromise an ongoing investigation conducted by a regulatory authority,
              or (c) that any notice may cause harm to you, third parties, Frisson, and/or its affiliates. If Frisson is
              terminating your access for Service changes, you will be provided where reasonably possible with
              sufficient time to export your Content from the Frisson Services.</p>
            <br />
            <h4>28. Effect of Account Suspension or Termination</h4>
            <p>Should your Frisson account be terminated or your Frisson account’s access to the Frisson Services
              becomes restricted, this Agreement will continue to apply, and you may continue using certain aspects of
              the Frisson Services (such as viewing only) without an account. If you believe your Frisson account has
              been terminated in error, please contact us at <a target='_blank' rel='noopener noreferrer'
                                                                href='mailto:support@frisson.live?Subject='> support@frisson.live</a>.
            </p>
            <br />
            <h3>About Software in the Frisson Services</h3>
            <br />
            <h4>28. Downloadable Software</h4>
            <p>The Frisson Services may require or include downloadable software (such as the Frisson application).
              Frisson gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the
              software provided to you by Frisson as part of the Frisson Services, unless that software is governed by
              additional terms which provide a license. This license serves only to provide you access to the Frisson
              Services provided by Frisson, and is bound by this Agreement. You are not allowed to reproduce, sell,
              license, translate, alter, adapt, modify, reverse-engineer, create derivative works of, or attempt to
              extract the source code of that software without Frisson’s written permission. When installing that
              software, you give permission for that software to automatically update when a new feature or version is
              available.</p>
            <br />
            <h4>29. Open Source</h4>
            <p>Frisson may use or provide to you software as a part of the Frisson Services that may be offered under an
              open source license. Please be sure to read those license terms, as they may have expressly stated
              provisions which override some of these terms.</p>
            <br />
            <h3>Other Legal Terms</h3>
            <br />
            <h4>30. Warranty Disclaimer</h4>
            <p>OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW, THE SERVICE IS PROVIDED “AS IS”
              AND FRISSON DOES NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T
              MAKE ANY WARRANTIES ABOUT: (A) THE CONTENT PROVIDED THROUGH THE SERVICE; (B) THE SPECIFIC FEATURES OF THE
              SERVICE, OR ITS ACCURACY, RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR (C) THAT ANY
              CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE SERVICE. </p>
            <br />
            <h4>31. Limitation of Liability</h4>
            <p>EXCEPT AS REQUIRED BY APPLICABLE LAW, FRISSON, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS
              WILL NOT BE RESPONSIBLE FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR
              ANTICIPATED SAVINGS; LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES,
              INCLUDING WHETHER OR NOT CAUSED BY:</p>
            <p>ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;
              PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE;
              ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICE;
              ANY INTERRUPTION OR CESSATION OF THE SERVICE;
              ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY;
              ANY CONTENT WHETHER SUBMITTED BY A USER OR YOUTUBE, INCLUDING YOUR USE OF CONTENT; AND/OR
              THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.
              THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON WARRANTY,
              CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.</p>
            <p>FRISSON AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE IS
              LIMITED TO THE GREATER OF: (A) THE AMOUNT OF REVENUE THAT FRISSON HAS PAID TO YOU FROM YOUR USE OF THE
              SERVICE IN THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE, IN WRITING TO YOUTUBE, OF THE CLAIM; AND (B) USD
              $500.</p>
            <br />
            <h4>32. Indemnity</h4>
            <p>To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless Frisson, its
              Affiliates, officers, directors, employees and agents, from and against any and all claims, damages,
              obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's
              fees) arising from: (i) your use of and access to the Frisson Services; (ii) your violation of any term of
              this Agreement; (iii) your violation of any third party right, including without limitation any copyright,
              property, or privacy right; or (iv) any claim that your Content caused damage to a third party. This
              defense and indemnification obligation will survive this Agreement and your use of the Frisson
              Services.</p>
            <br />
            <h4>33. Third-Party Links</h4>
            <p>The Frisson Services may contain links to third-party websites and online services that are not owned or
              controlled by Frisson. Frisson has no control over, and assumes no responsibility for, such websites and
              online services. Be aware when you leave the Frisson Services; we suggest you read the terms and privacy
              policy of each third-party website and online service that you visit.</p>
            <br />
            <h3>About this Agreement</h3>
            <br />
            <h4>34. Modifying this Agreement</h4>
            <p>We may modify this Agreement, for example, to reflect changes to our Service or for legal, regulatory, or
              security reasons. Frisson will use commercially reasonable efforts to provide reasonable advance notice of
              any material modifications to this Agreement and the opportunity to review them, except that modifications
              addressing newly available features of the Frisson Services or modifications made for legal reasons may be
              effective immediately without notice. Modifications to this Agreement will only apply going forward. If
              you do not agree to the modified terms, you must promptly, and in any event within 72 hours, remove any
              Content you have uploaded and discontinue your use of the Frisson Services.</p>
            <br />
            <h4>35. Continuation of this Agreement</h4>
            <p>If your use of the Frisson Services ends, the following terms of this Agreement will continue to apply to
              you: “Other Legal Terms”, “About This Agreement”, and the licenses granted by you will continue as
              described under “Duration of License”.</p>
            <br />
            <h4>36. Severance</h4>
            <p>If it turns out that a particular term of this Agreement is not enforceable for any reason, this will not
              affect any other terms.</p>
            <br />
            <h4>37. No Waiver</h4>
            <p>If you fail to comply with this Agreement and we do not take immediate action, this does not mean that we
              are giving up any rights that we may have (such as the right to take action in the future).</p>
            <br />
            <h4>38. Interpretation</h4>
            <p>In these terms, “include” or “including” means “including but not limited to,” and any examples we give
              are for illustrative purposes.</p>
            <br />
            <h4>39. Governing Law</h4>
            <p>All claims arising out of or relating to these terms or the Frisson Services will be governed by
              California law, except California’s conflict of laws rules, and will be litigated exclusively in the
              federal or state courts of Los Angeles County, California, USA. You and Frisson consent to personal
              jurisdiction in those courts.</p>
            <br />
            <h4>40. Limitation on Legal Action</h4>
            <p>YOU AND FRISSON AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE
              WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
              BARRED.</p>
          </div>
        </Row>
      </Container>
      <Footer />
    </LayoutDefault>
  </Fragment>);
};
export default TermsOfService;
