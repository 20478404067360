/* eslint-disable no-unused-expressions */

export default class Catalog {
    constructor() {
        this.head_topper = [],
        this.head_acc = [],
        this.eyes = [],
        this.face_acc = [],
        this.clothes = [],
        this.body_acc = [],
        this.hands_acc = [],
        this.feet = [],
        this.animations = []
    }
}