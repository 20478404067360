import React, { useEffect, useState } from 'react';
import './styles.scss';
import AdvanceFilterData from '../../data/advance-filter';

const ListItems = ({ inShowAddItems, isActiveItems, getItemsSelect, types }) => {
  const [addType, setAddType] = useState('');
  useEffect(() => {
    setAddType(types);
  }, [types]);
  const setTypes = () => {
    if (types === 'genres') {
      return AdvanceFilterData[2]?.options;
    } else if (types === 'shows') {
      return AdvanceFilterData[0]?.options;
    } else if (types === 'time') {
      return AdvanceFilterData[1]?.options;
    } else return [];
  };
  return (
    <div className='add-genres' style={{ display: inShowAddItems ? 'block' : 'none' }}>
      <div className='add-genres-back' onClick={() => {
        isActiveItems(false);
      }}>Back
      </div>
      <ul className='add-genres-ul'>
        {setTypes()?.map((item, index) => {
            return <li key={index} onClick={() => {
              getItemsSelect({ item: item, addType: addType });
              isActiveItems(false);
            }}>{item}</li>;
          }
        )}
      </ul>
    </div>
  );
};
export default ListItems;
