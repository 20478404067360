import React, { useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";

import "./styles.scss";

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
  space: 32
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma, KeyCodes.space];
const formatData = (array) => array?.map((item) => item?.text || "") || [];

const TagInput = ({ name, formik, ...props }) => {
  const [tags, setTags] = useState([]);

  const handleDelete = (i) => {
    let newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    handleChangeFormikValue(formatData(newTags));
  };

  const handleAddition = (tag) => {
    let newTags = [...tags, tag];
    setTags(newTags);
    handleChangeFormikValue(formatData(newTags));
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
    handleChangeFormikValue(formatData(newTags));
  };

  const handleInputBlur = (value) => {
    if (!value) return;
    handleAddition({ id: value, text: value });
  };

  const handleChangeFormikValue = (value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div>
      <ReactTags
        tags={tags}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        handleInputBlur={handleInputBlur}
        delimiters={delimiters}
        maxLength={50}
        {...props}
      />
      {formik.touched[name] && formik.errors[name] ? (
        <div className="error">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default TagInput;
