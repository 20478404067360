import React from "react"

export default function VibesUI_Order(props) {
    const [eventData, setEventData] = React.useState(props.eventData)
    
    const price = props.catalogObject ? ': '+ props.catalogObject.VirtualCurrencyPrices['DB'] + 'dB' : ''
    const cost = props.catalogObject ? props.catalogObject.VirtualCurrencyPrices['DB'] : 0
    // console.log('updated')



    return (
        <div className="vibesUI--order">
            <div className="inventory">
                <p>Inventory:{"\n"}</p>
                <p><b>{props.inventory}dB</b></p>
            </div>
            <button onClick={() => {props.sendVibes()}}  className={ cost <= props.inventory ? 'vibesUI--purchase_btn ui' : ' cantafford vibesUI--purchase_btn ui'}>{cost <= props.inventory ? 'Send Vibes' : 'Get More Vibes'} <span className={ cost <= props.inventory ? '' : 'cantafford'}>{props.isTutorial ? ' : FREE' : price}</span> </button>
        </div>
    )
}