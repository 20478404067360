import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import searchGame from "./searchGameSession";
import schedulePerformance from "./schedulePerformance";
import genres from "./genres";
import friends from "./friendsList";
import tabs from "./tabs";

const rootReducer = combineReducers({
  auth,
  user,
  searchGame,
  schedulePerformance,
  genres,
  friends,
  tabs
});

export default rootReducer;
