import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  tabs: 6,
  url: ''

};

const selectTabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    selectTab: (state,  payload ) => {
      return {
        ...state,
        tabs: payload.payload
      };
    },
    selectUrls: (state,  payload ) => {
      return {
        ...state,
        url: payload.payload
      };
    },



  }
});

export const {
  selectTab,
  selectUrls
} = selectTabsSlice.actions;

export default selectTabsSlice.reducer;
