import React from 'react';
import './styles.scss';

const ButtonFriends = ({
                      type,
                      btnText,
                      onClick
                    }) => {
  return (
    <button className='button-friends' onClick={onClick} type={type}>
      {btnText}
    </button>
  );
};
export default ButtonFriends;
