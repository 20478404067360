import React, { useEffect, useState } from 'react';
import './styles.scss';

const SelectInput = ({ element, isOrigin, meaning, getSelectInput }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isValue, setIsValue] = useState('');
  const toggleOptionsBlur = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    setIsValue(isOrigin);
  }, []);
  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };
  const selected = (val) => {
    setIsValue(val);
    getSelectInput(val, meaning);
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="container-select">
        <input
          name="select_value"
          type="hidden"
          id="selectedValue"
          value={isValue}
          onBlur={() => {
            toggleOptionsBlur();
          }}
        />
        <div
          className="display-value"
          id="displayValue"
          onClick={toggleOptions}
        >
          <span className="value-text" id="valueText">
            {isValue}
          </span>
          <span
            className={
              isOpen === true ? 'arrow active arrow-down' : 'arrow arrow-down'
            }
            id="arrowControl"
          ></span>
        </div>
        <ul
          tabIndex="0"
          style={{ height: '200px', overflow: 'auto' }}
          className={
            isOpen === true ? 'select-container active' : 'select-container'
          }
          id="selectContainer"
        >
          {element.map((i, index) =>
            isOrigin === i ? (
              <li
                key={index}
                className="select-option"
                onClick={() => {
                  selected(i);
                }}
              >
                {i}
              </li>
            ) : (
              <li
                key={index}
                className="select-option"
                onClick={() => {
                  selected(i);
                }}
              >
                {i}
              </li>
            )
          )}
        </ul>
      </div>
    </>
  );
};
export default SelectInput;
