export const TABS = {
  accountSettings: 0,
  schedulePerformance: 1,
  friends: 2,
  watchList: 3,
  favorites: 4,
  youtubeTwitch: 5,
  userName: 6,
  earningTab: 7
};
