import { isEmpty } from "lodash";
import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import "./styles.scss";

const DropDownItem = ({
  data,
  defaulValue,
  isReset,
  setReset,
  setValue,
  value
}) => {
  const [option, setOption] = useState(defaulValue);
  const dispatch = useDispatch();
  const [isShow, setShow] = useState(false);
  const selectedItem = useCallback(
    (value) => {
      setShow(false);
      setOption(value);
      setValue(value);
    },
    [setValue]
  );

  const handleMouseEnter = () => {
    setShow(true);
  };
  const handleMouseLeave = () => {
    setShow(false);
  };

  useEffect(() => {
    if (isEmpty(value) || value?.value === defaulValue.value) return;
    setOption(value);
  }, [defaulValue.value, dispatch, value]);

  useEffect(() => {
    if (!isReset) {
      setValue(defaulValue);

      return;
    }
    setOption(defaulValue);
    setReset(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isReset]);

  return (
    <>
      <li
        className={`dropdown-item-custom`}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        <span style={{ marginRight: "10px" }}> {option.label}</span>
        <i className="fa fa-angle-down" />
        <ul className={`dropdown-custom ${isShow ? "dropdown-show" : ""}`}>
          {data?.map((item, index) => (
            <li
              className={`sub-item ${
                item.value === option.value ? "active" : ""
              }`}
              onClick={() => selectedItem(item)}
              key={index}
            >
              {item.label ? item.label : item.label}
            </li>
          ))}
        </ul>
      </li>
      <div className="line" />
    </>
  );
};

export default DropDownItem;
