import { call, put, all, takeLatest } from "redux-saga/effects";
import {
  getGenresRequest,
  getGenresSuccess,
  getGenresError,
  getListLiveRequest,
  getListLiveSuccess,
  getListLiveError,
  getRecommendedShowsRequest,
  getRecommendedShowsSuccess,
  getRecommendedShowsError
} from "../slices/genres";
import { toastConfig } from "../shared/defaultValue";
import searchGameService from "../services/SearchGame";

import toast from "react-hot-toast";
function* genresSlider({ payload }) {
  try {
    const result = yield call(searchGameService.searchGameRandom, payload);
    if (result.success) {
      yield put(getGenresSuccess(result));
    } else {
      toast.error(result.message, toastConfig);
      yield put(getGenresError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getGenresError(error.message));
  }
}

function* recommendedShows({ payload }) {
  try {
    const result = yield call(searchGameService.searchGameRandom, payload);
    if (result.success) {
      yield put(getRecommendedShowsSuccess(result));
    } else {
      toast.error(result.message, toastConfig);
      yield put(getRecommendedShowsError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getRecommendedShowsError(error.message));
  }
}

function* listLiveDiscover({ payload }) {
  try {
    const result = yield call(searchGameService.searchGameRandom, payload);
    if (result.success) {
      yield put(getListLiveSuccess(result));
    } else {
      toast.error(result.message, toastConfig);
      yield put(getListLiveError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(getListLiveError(error.message));
  }
}

export function* genresSaga() {
  yield all([
    takeLatest(getGenresRequest.type, genresSlider),
    takeLatest(getListLiveRequest.type, listLiveDiscover),
    takeLatest(getRecommendedShowsRequest.type, recommendedShows)
  ]);
}
