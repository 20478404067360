import { initVid } from "./vidManager"
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"
import { displayMessage } from "./trackedMessageController";
import { screenSizeListener, update } from "./mediaQueries";
import { drawAttendees } from "shared/canvasBuilder";
import { dynoCanvases } from "shared/canvasBuilder";
import { buildSectionsPanel, setProposeActive, totems } from "../../shared/ui-builder";
import { messageEvent } from "./messageEvents";
import { GetActiveTotemVote, GetTotem, VoteForTotem } from "./totemManager";
import { HideBundles, SendVibes, RemovePackages } from "./vibesManager";
import { CheckTutorialStep } from "shared/audiophile/onboarding";
import { clone } from "lodash";
import { set } from "lodash";

const url = window.location.hostname;
const urlParams = new URLSearchParams(window.location.search);
const api = 'https://gamechat.azurewebsites.net/api/';
const devApi = 'https://gamechat-dev.azurewebsites.net/api/';
const chatApi = 'https://gamechat.azurewebsites.net/chatHub/';
const chatDevApi = 'https://gamechat-dev.azurewebsites.net/chatHub/';
const userApi = 'https://sls-eus-dev-frisson-live-api.azurewebsites.net/api/users/';
export let streamId = urlParams.get('id');
const useDev = urlParams.get('dev');
export const titleId = '10B75';

let sessionTicket = '';
export let updateLocationInterval

export const curApi = url.includes('localhost') || url.includes('frisson-live-development') || url.includes('frisson-staging')  || url.includes('ngrok') ? devApi : api;
const curChatApi = url.includes('localhost') || url.includes('frisson-live-development') || url.includes('frisson-staging')  || url.includes('ngrok') ? chatDevApi : chatApi;
export let user
//useful stuff for user id
export let userConnection = {
    username : undefined,
    section: undefined,
    room : undefined,
    roomStatuses : undefined
}

let connection;
let adminHub

let encodedUser

let gameSession;

export let targetArtist;

let voteActive = false;

var timerIntervalID;

export let selectionStep = 0

let sectionViewCam
let sectionSelectCam
let roomSelectCam

let removeCharacterCallback

let canSendUsersInGroup = true

let createTotemCallback

let cleanUpTotemsCallback
let destroySingleTotem

let userJoinCallback
let usersInGroupCallback
let loginCallBack

//full flow:
//  establish chat connection
//  get game session for most up to date section info
//  init section select and display section info

//init vid right away
export function startVid () {
    if (!streamId) streamId = new URLSearchParams(window.location.search).get('id')
    initVid(streamId, curApi)
}

export function joinTest() {
    console.log('test worked')
}

export async function initChat (loggedInUser, theLoginCallBack, theUserJoinCallback, theUsersInGroupCallback, userLeaveCallback, sectionViewCamCallback, sectionSelectCamCallback, roomSelectCamCallback, totemCallback, cleanTotemsCallback, destroySingleTotemCallback) {

    // initVid(streamId, curApi)
    
    sectionViewCam = sectionViewCamCallback
    sectionSelectCam = sectionSelectCamCallback
    roomSelectCam = roomSelectCamCallback
    removeCharacterCallback = userLeaveCallback
    createTotemCallback = totemCallback
    cleanUpTotemsCallback = cleanTotemsCallback
    userJoinCallback = theUserJoinCallback
    usersInGroupCallback = theUsersInGroupCallback
    loginCallBack = theLoginCallBack
    destroySingleTotem = destroySingleTotemCallback

    if (! document.getElementById('joinCrowdButton')) return
    
    document.getElementById('joinCrowdButton').style.display = 'none'
    
    
    //if user is null, the user needs to be prompted with login form
    if (loggedInUser != null) {
        user = loggedInUser

        
    //    await GetGameSession()

       //init chat
    //    connection = new HubConnectionBuilder()
    //         .withUrl(curChatApi, {
    //             accessTokenFactory: async () => {
                    
    //                 // let userName = "gavin@frisson.live"
    //                 // let userPass = "password"

    //                 // var res = await fetch("https://gamechat-dev.azurewebsites.net/api/Auth/LoginAndReturnSessionTicket",
    //                 // {

                        

    //                 //     method: 'POST',
    //                 //     body: "{\r\n  \"login\": \"" + userName + "\",\r\n  \"password\": \"" + userPass + "\"\r\n}",
    //                 //     headers: {
    //                 //         'Content-Type': 'application/json-patch+json'
    //                 //         // 'Content-Type': 'application/x-www-form-urlencoded',
    //                 //     }
    //                 // });
    //                 // var tkn = await  res.json();
    //                 // return tkn.token;


    //                 return user.gameChatToken;
    //             }
    //         })
    //         .configureLogging(LogLevel.Information)
    //         .build();

    //     connection.serverTimeoutInMilliseconds = 1000 * 60 * 20;

    //     connection
    //         .start()
    //         .then(async function () {
    //             //hide loading
    //             // document.getElementById('userEmail').disabled = false;
    //             // document.getElementById('userPassword').disabled = false;
    //             // document.getElementById('authButton').disabled = false;
    //             //$('#login-loader, #authButton').removeClass('loading');

    //             // SuccessfulLogin();
    //             await GetGameSession()
                
    //             let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
    //             if (appState?.auth.isAuthenticated) {
    //                 encodedUser = appState.auth.currentUser
    //             }
    //             UpdateLocation()

    //             // setTimeout(() => {
    //             //     JoinRoom('Top-Left')
    //             // }, 1000);

    //             // setTimeout(() => {
    //             //     connection.invoke('SendMessage', user.gameChatToken, "TEST MESSAGE", userConnection.room).catch(function (err) {
    //             //         return console.error(err.toString());
    //             //     });
    //             // }, 10000);
    //         })
    //         .catch(function (err) {
    //             //hide loading
    //             // document.getElementById('userEmail').disabled = false;
    //             // document.getElementById('userPassword').disabled = false;
    //             // document.getElementById('authButton').disabled = false;
    //             // $('#login-input .button, #login-loader, #authButton').removeClass(
    //             //     'loading'
    //             // );

    //             //hide loading
    //             document.getElementsByClassName('loading')[0].style.display = 'none'

    //             alert('Connection could not be made, the show may be over.');
    //             return console.error(err.toString());
    //         });

    //     //THIS IS FOR NORMAL MESSAGES
    //     connection.on('ReceiveMessage', function (username, message, userid) {
    //         var msg = message
    //             .replace(/&/g, '&amp;')
    //             .replace(/</g, '&lt;')
    //             .replace(/>/g, '&gt;');
    //         var encodedMsg = username + ': ' + msg;
            
    //         displayMessage(userid, message)
    //         messageEvent(userid, message)

    //         console.log(encodedMsg);
    //         // // console.log("stored used = " + uuid);

    //         AddMessageToHistory(encodedMsg, userid)

    //         //this is just for testing
    //         if (/\bClone me please\b/g.test(encodedMsg) && userid && userid == user.playFabId) {
    //             userJoinCallback(userid, true)
    //         }
    //         if (/\bRemove clone:\b/g.test(encodedMsg) && userid && userid == user.playFabId) {
    //             let cloneNum = encodedMsg.split(':')[2]
    //             removeCharacterCallback(userid, cloneNum)
    //         }
            
    //     });

    //     //THIS IS FOR EVENTS CONNECTIONS, VOTES ETC
    //     connection.on('Send', function (message, userid) {
    //         var msg = message
    //             .replace(/&/g, '&amp;')
    //             .replace(/</g, '&lt;')
    //             .replace(/>/g, '&gt;');
    //         var encodedMsg = msg;
    //         // console.log(encodedMsg);
    //         //parse message to display proper events
    //         if (/\bcreated a poll\b/g.test(encodedMsg)) {

    //             //if it's a history msg there's no user so just ignore it
    //             if (userid) {
    //                 var imgURL = message.match('image (.*), id')[1];
                
    //                 let bothIds = message.match('/imagepoll-(.*),')[1];
    //                 let voteId = bothIds.split('/')[1];


    //                 createTotemCallback(userid, imgURL, voteId)

    //                 // $('#voteCountdownHeadline').text('Voting ends in:');
    //                 // voteActive = true;
    //                 // VoteCountdown(Date.now(), 5, false);
    //                 console.log("Spawn totem in front of user" + userid)

    //                 if (userid == user.playFabId) {
    //                     setProposeActive('unavailable')
    //                 }
    //             }
                
                
    //         } else if (/\bproposed image\b/g.test(encodedMsg)) {
    //             var imgURL = message.match('image for voting (.*), id')[1];
                
    //             let bothIds = message.match('/imagepoll-(.*),')[1];
    //             let voteId = bothIds.split('/')[1];


    //             createTotemCallback(userid, imgURL, voteId)
    //             console.log("Spawn totem in front of user" + userid)
    //         } else if (/\bhas joined the group\b/g.test(encodedMsg)) {

    //             userJoinCallback(userid)
    //             //console.log("Joined message");

    //             // JoinDisplay(encodedMsg);

    //             //this is where we were get the user's id and display their character!

    //         } else if (/\bhas left the group\b/g.test(encodedMsg)) {
    //             //console.log("Joined message");

    //             removeCharacterCallback(userid)

    //             // LeaveDisplay(encodedMsg);
    //         } else if (/\bVote closed\b/g.test(encodedMsg)) {

    //             cleanUpTotemsCallback()
    //             GetTotem()

    //             // var msg = message.split(': ')[1];
    //             // var encodedMsg = 'The vote is over! The winning totem is:';
    //             // // var li = document.createElement("li");
    //             // // li.textContent = encodedMsg;
    //             // // document.getElementById("messagesList").appendChild(li);

    //             // var li = document.createElement('li');
    //             // li.innerHTML =
    //             //     '<li><div class="totemMessage">' +
    //             //     encodedMsg +
    //             //     '<img src="' +
    //             //     msg +
    //             //     '">';
    //             // document.getElementById('messagesList').appendChild(li);
    //         } else {
    //             //These are history messages and should be added like normal messages

    //             //parse out the user from the message since that isn't happening for us automatically here
    //             // var user = msg.split(': ')[0];
    //             // //var encodedMsg = msg.split(": ")[1];

    //             // var div = document.createElement('div');
    //             // // console.log("the user = " + user);
    //             // // console.log("stored used = " + uuid);
    //             // if (username == user) {
    //             //     div.classList.add('sent');
    //             // } else {
    //             //     div.classList.add('received');
    //             // }

    //             // div.textContent = encodedMsg;
    //             // document.getElementById('messagesList').appendChild(div);
    //             //temp filter of this msg until it starts coming back with a userid
    //             //split out message and id (though is could easily be broken by typing these in the correct sequence in a normal message...)
    //             console.log(`encoded: ${encodedMsg}`)
    //             let usrName = encodedMsg.split(' - ')[0]
    //             if (!userid) userid = encodedMsg.split(' - ')[1].split(': ')[0]
    //             let msg = `${usrName}: ${encodedMsg.split(' - ')[1].split(': ')[1]}`

    //             if (!/\bcreated a poll\b/g.test(encodedMsg)) {
    //                 AddMessageToHistory(msg, userid)
    //             }
                
    //         }

    //         //THIS IS FOR GETTING EXISTING USERS IN ROOM
    //         connection.on('UsersInGroup', function (usersInRoom) {

    //             if (canSendUsersInGroup) {
    //                 console.log(usersInRoom);
            
    //                 //SPAWN ALL OTHER USERS IN THE ROOMS THAT AREN'T YOU
    //                 usersInGroupCallback(usersInRoom)

    //                 canSendUsersInGroup = false
    //             }
                

    //         });

    //         console.log(encodedMsg);
    //     });

    //     connection.on('VoteClosed', function (message) {
    //         // var msg = message
    //         //     .replace(/&/g, '&amp;')
    //         //     .replace(/</g, '&lt;')
    //         //     .replace(/>/g, '&gt;');
    //         // var encodedMsg = 'The vote is over! The winning totem is:';

    //         cleanUpTotemsCallback()
    //         GetTotem()

    //         // var li = document.createElement("li");
    //         // li.textContent = encodedMsg;
    //         // document.getElementById("messagesList").appendChild(li);

    //         // var li = document.createElement('li');
    //         // li.innerHTML =
    //         //     '<li><div class="totemMessage">' +
    //         //     encodedMsg +
    //         //     '<img src="' +
    //         //     msg +
    //         //     '">';
    //         // document.getElementById('messagesList').appendChild(li);

    //         // setTimeout(() => {
    //         //     GetTotem();
    //         // }, 2000);

    //         // $('#messagesList')
    //         //     .stop()
    //         //     .animate({ scrollTop: $('#messagesList')[0].scrollHeight }, 1000);
    //     });

    //     connection.on('SendAdminMessage', function (message) {
    //         var msg = message
    //             .replace(/&/g, '&amp;')
    //             .replace(/</g, '&lt;')
    //             .replace(/>/g, '&gt;');
    //         msg = msg.split(': ')[1];

    //         // var div = document.createElement('div');
    //         // // console.log("the user = " + user);
    //         // // console.log("stored used = " + uuid);

    //         // div.classList.add('announcement');

    //         // $(div).append('<span>-- Announcement --</span><p>' + msg + '</p>');
    //         // document.getElementById('messagesList').appendChild(div);

    //         // $('#messagesList')
    //         //     .stop()
    //         //     .animate({ scrollTop: $('#messagesList')[0].scrollHeight }, 1000);
    //     });

    //     connection.on('Vibes_CrowdEmoji', function (message) {
    //         let event = JSON.parse(message)
    //         let parsedEmoji = String.fromCodePoint(parseInt (event.emojiUnicode, 16))
    //         let newMessage = `&vibe& |||Sent ${parsedEmoji} vibes!`

    //         displayMessage(event.playfabId, newMessage)
    //     });

    //     connection.on('Vibes_Fireworks', function (message) {
    //         let event = JSON.parse(message)
    //         let newMessage = `&vibe& |||Sent 🎆 vibes!`

    //         displayMessage(event.playfabId, newMessage)

    //     });

        //TEST DEBUG ADMIN HUB
        // adminHub = new HubConnectionBuilder()
        //     .withUrl(curChatApi, {
        //         accessTokenFactory: async () => {
        //            return user.gameChatToken;
        //         }
        //     })
        //     .configureLogging(LogLevel.Information)
        //     .build();

        //     adminHub.serverTimeoutInMilliseconds = 1000 * 60 * 20;

        //     adminHub
        //     .start()
        //     .then(async function () {

        //         await GetGameSession()

        //         adminHub
        //         .invoke('ConnectAdminToGamesessionRoom', streamId)
        //         .then(function (room) {

        //             console.log(room)

        //         })
        //         .catch(function (err) {
        //             alert('Connection could not be made to admin hub.');
        //             // CloseChat();
        //             return console.error(err.toString());
        //         })

        //     })
        //     .catch(function (err) {
                
        //         alert('Connection could not be made, the show may be over.');
        //         return console.error(err.toString());
        //     });

        //     adminHub.on('VoteClosedAdmin', function (message) {
        //         console.log("ADMIN HUB MESSAGE!:" + message)
        //     });

        //     adminHub.on('SuperCustomEvent', function (message) {
        //         console.log("ADMIN HUB MESSAGE!: Super custom message" + message)
        //     });
    
        //     adminHub.on('SecretCustomEvent', function (message) {
        //         console.log("ADMIN HUB MESSAGE!: Super secret custom message" + message)
        //     });



            


        
    }
    else {
        //show login form
    }

    

    
}

export function StartChat () {
    connection = new HubConnectionBuilder()
        .withUrl(curChatApi, {
            accessTokenFactory: async () => {
                
                // let userName = "gavin@frisson.live"
                // let userPass = "password"

                // var res = await fetch("https://gamechat-dev.azurewebsites.net/api/Auth/LoginAndReturnSessionTicket",
                // {

                    

                //     method: 'POST',
                //     body: "{\r\n  \"login\": \"" + userName + "\",\r\n  \"password\": \"" + userPass + "\"\r\n}",
                //     headers: {
                //         'Content-Type': 'application/json-patch+json'
                //         // 'Content-Type': 'application/x-www-form-urlencoded',
                //     }
                // });
                // var tkn = await  res.json();
                // return tkn.token;


                return user.gameChatToken;
            }
        })
        .configureLogging(LogLevel.Information)
        .build();

    connection.serverTimeoutInMilliseconds = 1000 * 60 * 20;

    connection
        .start()
        .then(async function () {
            //hide loading
            // document.getElementById('userEmail').disabled = false;
            // document.getElementById('userPassword').disabled = false;
            // document.getElementById('authButton').disabled = false;
            //$('#login-loader, #authButton').removeClass('loading');

            // SuccessfulLogin();
            let gs = await GetGameSession()
            if (!gs) {
                //alert('This show could not be found, check the url and try again.')
                return
            }

            //get user role in this show
            await GetUserRole()
            
            let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
            if (appState?.auth.isAuthenticated) {
                encodedUser = appState.auth.currentUser
            }
            UpdateLocation()

            // setTimeout(() => {
            //     JoinRoom('Top-Left')
            // }, 1000);

            // setTimeout(() => {
            //     connection.invoke('SendMessage', user.gameChatToken, "TEST MESSAGE", userConnection.room).catch(function (err) {
            //         return console.error(err.toString());
            //     });
            // }, 10000);
        })
        .catch(function (err) {
            //hide loading
            // document.getElementById('userEmail').disabled = false;
            // document.getElementById('userPassword').disabled = false;
            // document.getElementById('authButton').disabled = false;
            // $('#login-input .button, #login-loader, #authButton').removeClass(
            //     'loading'
            // );

            //hide loading
            document.getElementsByClassName('loading')[0].style.display = 'none'

            // alert('Connection could not be made, the show may be over.');
            return console.error(err.toString());
        });

    //THIS IS FOR NORMAL MESSAGES
    connection.on('ReceiveMessage', function (username, message, userid) {
        var msg = message
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
        var encodedMsg = username + ': ' + msg;
        
        displayMessage(userid, message)
        messageEvent(userid, message)

        console.log(encodedMsg);
        // // console.log("stored used = " + uuid);

        AddMessageToHistory(encodedMsg, userid)

        //this is just for testing
        if (/\bClone me please\b/g.test(encodedMsg) && userid && userid == user.playFabId) {
            userJoinCallback(userid, true)
        }
        if (/\bRemove clone:\b/g.test(encodedMsg) && userid && userid == user.playFabId) {
            let cloneNum = encodedMsg.split(':')[2]
            removeCharacterCallback(userid, cloneNum)
        }
        
    });

    //THIS IS FOR EVENTS CONNECTIONS, VOTES ETC
    connection.on('Send', function (message, userid) {
        var msg = message
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
        var encodedMsg = msg;
        // console.log(encodedMsg);
        //parse message to display proper events
        if (/\bcreated a poll\b/g.test(encodedMsg)) {

            //if it's a history msg there's no user so just ignore it
            if (userid) {
                var imgURL = message.match('image (.*), id')[1];
            
                let bothIds = message.match('/imagepoll-(.*),')[1];
                let voteId = bothIds.split('/')[1];


                createTotemCallback(userid, imgURL, voteId, null, VoteForTotem)

                // $('#voteCountdownHeadline').text('Voting ends in:');
                // voteActive = true;
                // VoteCountdown(Date.now(), 5, false);
                console.log("Spawn totem in front of user" + userid)

                if (userid == user.playFabId) {
                    console.log("I created this poll, I can't vote on it")
                    setProposeActive('unavailable')
                }
            }
            
            
        } else if (/\bproposed image\b/g.test(encodedMsg)) {
            var imgURL = message.match('image for voting (.*), id')[1];
            
            let bothIds = message.match('/imagepoll-(.*),')[1];
            let voteId = bothIds.split('/')[1];


            createTotemCallback(userid, imgURL, voteId, null, VoteForTotem)
            console.log("Spawn totem in front of user" + userid)
        } else if (/\bhas joined the group\b/g.test(encodedMsg)) {

            //userJoinCallback(userid)
            //console.log("Joined message");

            // JoinDisplay(encodedMsg);

            //this is where we were get the user's id and display their character!

        } else if (/\bhas left the group\b/g.test(encodedMsg)) {
            //console.log("Joined message");

            removeCharacterCallback(userid)

            // LeaveDisplay(encodedMsg);
        } else if (/\bVote closed\b/g.test(encodedMsg)) {

            // cleanUpTotemsCallback()
            // GetTotem()

            // var msg = message.split(': ')[1];
            // var encodedMsg = 'The vote is over! The winning totem is:';
            // // var li = document.createElement("li");
            // // li.textContent = encodedMsg;
            // // document.getElementById("messagesList").appendChild(li);

            // var li = document.createElement('li');
            // li.innerHTML =
            //     '<li><div class="totemMessage">' +
            //     encodedMsg +
            //     '<img src="' +
            //     msg +
            //     '">';
            // document.getElementById('messagesList').appendChild(li);
        } else {
            //These are history messages and should be added like normal messages

            //parse out the user from the message since that isn't happening for us automatically here
            // var user = msg.split(': ')[0];
            // //var encodedMsg = msg.split(": ")[1];

            // var div = document.createElement('div');
            // // console.log("the user = " + user);
            // // console.log("stored used = " + uuid);
            // if (username == user) {
            //     div.classList.add('sent');
            // } else {
            //     div.classList.add('received');
            // }

            // div.textContent = encodedMsg;
            // document.getElementById('messagesList').appendChild(div);
            //temp filter of this msg until it starts coming back with a userid
            //split out message and id (though is could easily be broken by typing these in the correct sequence in a normal message...)
            // console.log(`encoded: ${encodedMsg}`)
            // let usrName = encodedMsg.split(' - ')[0]
            // if (!userid) userid = encodedMsg.split(' - ')[1].split(': ')[0]
            // let msg = `${usrName}: ${encodedMsg.split(' - ')[1].split(': ')[1]}`

            // if (!/\bcreated a poll\b/g.test(encodedMsg)) {
            //     AddMessageToHistory(msg, userid)
            // }
            
        }

        //THIS IS FOR GETTING EXISTING USERS IN ROOM
        connection.on('UsersInGroup', function (usersInRoom) {

            if (canSendUsersInGroup) {
                console.log(usersInRoom);
        
                //SPAWN ALL OTHER USERS IN THE ROOMS THAT AREN'T YOU
                usersInGroupCallback(usersInRoom)

                canSendUsersInGroup = false
            }
            

        });

        console.log(encodedMsg);
    });

    connection.on('SendHistory', function (message, userid) {
        var msg = message
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
        var encodedMsg = msg;

        if (!/\bhas joined the group\b/g.test(encodedMsg) && !/\bhas left the group\b/g.test(encodedMsg) && !/\bcreated a poll\b/g.test(encodedMsg) && !/\bproposed image\b/g.test(encodedMsg) && !/\bVote closed\b/g.test(encodedMsg)) {
           AddMessageToHistory(msg, userid)
        }
        
    })

    connection.on('VoteClosed', function (message) {
        // var msg = message
        //     .replace(/&/g, '&amp;')
        //     .replace(/</g, '&lt;')
        //     .replace(/>/g, '&gt;');
        // var encodedMsg = 'The vote is over! The winning totem is:';

        // cleanUpTotemsCallback()
        GetTotem()

        // var li = document.createElement("li");
        // li.textContent = encodedMsg;
        // document.getElementById("messagesList").appendChild(li);

        // var li = document.createElement('li');
        // li.innerHTML =
        //     '<li><div class="totemMessage">' +
        //     encodedMsg +
        //     '<img src="' +
        //     msg +
        //     '">';
        // document.getElementById('messagesList').appendChild(li);

        // setTimeout(() => {
        //     GetTotem();
        // }, 2000);

        // $('#messagesList')
        //     .stop()
        //     .animate({ scrollTop: $('#messagesList')[0].scrollHeight }, 1000);
    });

    connection.on('UserJoined', function (userid, playfabId) {
        userJoinCallback(playfabId)
    })

    connection.on('SendAdminMessage', function (message) {
        var msg = message
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
        msg = msg.split(': ')[1];

        DisplayAdminMessage(msg)

        // var div = document.createElement('div');
        // // console.log("the user = " + user);
        // // console.log("stored used = " + uuid);

        // div.classList.add('announcement');

        // $(div).append('<span>-- Announcement --</span><p>' + msg + '</p>');
        // document.getElementById('messagesList').appendChild(div);

        // $('#messagesList')
        //     .stop()
        //     .animate({ scrollTop: $('#messagesList')[0].scrollHeight }, 1000);
    });

    connection.on('Vibes_CrowdEmoji', function (message) {
        let event = JSON.parse(message)
        let parsedEmoji = String.fromCodePoint(parseInt (event.emojiUnicode, 16))
        let newMessage = `&vibe& |||Sent ${parsedEmoji} vibes!`

        displayMessage(event.playfabId, newMessage)
        let historyMsg = `${event.username} sent ${parsedEmoji} vibes!`
        AddMessageToHistory(historyMsg, user.playFabId)
    });

    connection.on('Vibes_Fireworks', function (message) {
        let event = JSON.parse(message)
        let newMessage = `&vibe& |||Sent 🎆 vibes!`

        displayMessage(event.playfabId, newMessage)
        let historyMsg = `${event.username} sent 🎆 vibes!`    
        AddMessageToHistory(historyMsg, user.playFabId)

    });

    connection.on('onclose', function (message) {
        console.log("Connection closed " + message)
    })
        
    loginCallBack()
}

export function AddMessageToHistory (msg, userid) {
    var div = document.createElement('div');
    div.className = 'historyMessage'


    if (user && userid == user.playFabId) {
        div.classList.add('sent');
    } else {
        div.classList.add('received');
    }
    var p = document.createElement('p')
    p.dataset.id = userid
    p.className = 'message'
    p.textContent = msg;
    div.appendChild(p)
    
    document.getElementById('messagesList').appendChild(div);

    document.getElementById('messagesList').scrollTo({
        top: document.getElementById('messagesList').scrollHeight,
        behavior: "smooth"
    });
    document.getElementById('expandButton').style.opacity = 0
    // Use the observer to observe an element
    observer.observe( div )
}

export async function SendMessage () {
    let message = document.getElementById('messageInput').value
    //check for sending vibe
    const vibe = document.getElementById('messageInput').getAttribute('data-vibe')
    const vibeName = document.getElementById('messageInput').getAttribute('data-vibeName')
    const isAnnouncement = document.getElementById('announcementToggle').classList.contains('selected')

    if (vibe) {
        
        const sentVibe = vibe == "vibes-0" ? true : await SendVibes(vibe)
        if (sentVibe) {
            //fix this with something more secure later
            message = '&vibe&Sent with ' + vibeName + '|||' + message
            //clear vibe data
            document.getElementById('messageInput').removeAttribute('data-vibe')
            document.getElementById('messageInput').removeAttribute('data-vibeName')

            CheckTutorialStep("SentVibes")
        }
    }

    if (isAnnouncement) {
        SendAdminMessage(message)
    }
    else {
        connection.invoke('SendMessage', user.gameChatToken, message, userConnection.room)
        .then(function () {
            ChatInput(null, () => {})
            document.getElementById('messageInput').value = ""

            CheckTutorialStep('SentMessage')
        })
        .catch(function (err) {
            return console.error(err.toString());
        });
    }

    
}

let connecting = false
export async function JoinRoom(roomId, sectionPos, sectionId) {

    if (!connecting && !userConnection.room) {
        connecting = true

        //get room id
        // let roomId = roomBtn.uid

        await DisconnectUser();
            

        //empty chat list
        // $('#messagesList').empty();
        
        console.log('user should be disconnected first');
        connection
            .invoke('ConnectUserDirectAsync', streamId, roomId)
            .then(function (roomObj) {

                if (!roomObj) {
                    //room is full or invalid
                    alert("This room is full, please select another one.")
                    connecting = false
                    return
                }

                let roomUID
                let roomStatuses

                //fallback in case returned val is just uid string and doesn't iunclude statuses
                if (typeof roomObj == 'object')
                {
                    roomUID = roomObj.uid
                    roomStatuses = roomObj.chatRoomStatuses
                }
                else {
                    roomUID = roomObj
                    roomStatuses = null
                }

                if (!roomUID) {
                    //this means that the room is full, so make them chose something else and we mark this room as full

                    //CHANGE THIS TO ROOM SELECTION
                    selectionStep = 1
                    
                    //update the screen layout for desktop
                    update()

                    sectionViewCam()

                    DisplaySections()

                    connecting = false
                }
                else {
                    //hide section selection and move into room
                    selectionStep = 0
                    
                    //update the screen layout for desktop
                    update()

                    console.log('the totems', totems)

                    roomSelectCam(roomId, sectionPos)

                    userConnection.room = roomUID
                    userConnection.section = sectionId
                    userConnection.roomStatuses = roomStatuses

                    //update room #
                    GetSectionTotals(userConnection.room)

                    //get totem info for new room
                    GetTotem()

                    //enable chat ui in case they were disabled
                    // document.getElementById('message-init').style.display = 'block'
                    //doing this when character appears instead

                    CheckTutorialStep('SelectedRoom')

                    connecting = false
                }

            })
            .catch(function (err) {
                // alert('Connection could not be made, the show may be over.');
                // CloseChat();
                connecting = false
                if (err.toString().includes("not in the 'Connected' State")) {
                    alert("You are no longer connected, please refresh the page and try again.")
                }
                return console.error(err.toString());
            })
    }
    
    // event.preventDefault();
}

export async function DisconnectUser() {
    if (userConnection.room) {
        connection.invoke('Disconnect', userConnection.room)
        .then(function (){
            console.log('user disconnected');
            //re-allow usersingroup to be sent
            canSendUsersInGroup = true
            
            userConnection.room = undefined
            
            document.getElementById('expandButton').style.opacity = 0
            

            //deactivate submission button
            // setProposeActive(false)
        })
        .catch(function (err) {

            return console.error(err.toString())
        
        })
    // event.preventDefault()

        //DELETE OTHER USER's CHARACTERS HERE
        removeCharacterCallback('disconnect')
        cleanUpTotemsCallback()
    }
    else {
        console.log("We're not in a room, good to go")
    }

    //clear out message history
    // document.getElementById('messagesList').replaceChildren() 
    let messages = document.querySelectorAll('#messagesList .historyMessage')
    messages.forEach(element => {
        element.remove()
    });
    
}

/*
 * Get total connections for either room id passed in or all if null 
*/
async function GetSectionTotals(roomId) {
    if (roomId) {
        fetch(
            curApi + 'GameSessions/GetTotalConnectionsForRoom?roomId=' + roomId,
            {
                method: 'GET',
                headers: {
                    Accept: 'text/plain',
                    Authorization: 'Bearer ' + user.gameChatToken
                }
            }
        )
        .then(function (response) {
            if (response.status >= 400 && response.status < 600) {
                // failure in getting totals
            } else if (response.status === 200) {
                return response.json()
            } else {
                // failure in getting totals
            }
        })
        .then((data) => {
            if (data) {
                // display the new total somewhere
            }
        })
        .catch(function (err) {
            //no existing session with this id
            return console.error(err.toString())
        });
    } else {
        fetch(curApi + 'GameSessions/GetTotalConnections?streamUri=' + streamId, {
            method: 'GET',
            headers: {
                Accept: 'text/plain',
                Authorization: 'Bearer ' + user.gameChatToken
            }
        })
        .then(function (response) {
            if (response.status >= 400 && response.status < 600) {
                // failure in getting totals
            } else if (response.status === 200) {
                return response.json()
            } else {
                // failure in getting totals
            }
        })
        .then((data) => {
            if (data) {
                // for (let i = 0; i < Object.keys(data).length; i++) {
                //     let sectionName = getKeyByValue(
                //         sectionIds,
                //         parseInt(Object.keys(data)[i])
                //     );

                //     // display the new totals somewhere
                // }
            }
        })
        .catch(function (err) {
            //no existing session with this id
            return console.error(err.toString());
        })
    }
}

/*******************
 * 
 * Section Selection
 * 
 *******************/
export async function DisplaySections (init) {

    //get up to date game session so we can display close to accurate totals on section selector
    let gs = await GetGameSession()
    if (!gs) {
        alert('This show could not be found, check the url and try again.')
        return
    }

    //hide loading
    document.getElementsByClassName('loading')[0].style.display = 'none'
    //start section css anim
    document.getElementById('canvasContainer2').className = 'selection'


    //use that info to draw the current users on the selection plane
    let sectionAmounts = []
    for (let i = 0; i < gameSession.chatSections.length; i++) {
        const section = gameSession.chatSections[i];
        
        sectionAmounts.push(
            { 
                "uid" : section.gridPlacement,
                "connections" : section.totalActiveConnections
            }
        )
    }
 
    // buildSectionsPanel(sectionAmounts, false, 0, 8, 0, DisplayRooms)

    // drawAttendees(dynoCanvases["sectionCanvas"], sectionAmounts)

    console.log(sectionAmounts)

    //handle leaving room if we're in a room
    //tell CC that we want to move the camera and show selection UI
    selectionStep = 1
    
    //update the screen layout for desktop
    update()

    sectionViewCam(init)
}

export async function DisplayRooms (btn, pos) {

    //save selected section int
    userConnection.section = btn.intName

    //get up to date game session so we can display close to accurate totals on section selector
    await GetGameSession()

    //use that info to draw the current users on the selection plane
    let sectionAmounts = []
    let section = gameSession.chatSections.filter(obj => {
        return obj.gridPlacement === btn.uid
      })

    section[0].chatRooms.forEach(element => {
        sectionAmounts.push(
            { 
                //TODO: Fix this when we can join individual rooms!
                // "uid" : element.uid,
                'uid' : section[0].gridPlacement,
                "connections" : element.totalActiveConnections
            }
        )
    });
 
    buildSectionsPanel(sectionAmounts, true, btn.worldPos.x, 9, btn.worldPos.z, JoinRoom)

    // drawAttendees(dynoCanvases["sectionCanvas"], sectionAmounts)

    console.log(sectionAmounts)

    sectionSelectCam(pos)

    //handle leaving room if we're in a room
    //tell CC that we want to move the camera and show selection UI
    selectionStep = 2
    
    //update the screen layout for desktop
    update()

    CheckTutorialStep('SelectedSection')
}

/*******************
 * 
 * Chatting
 * 
 *******************/
export function ChatInput (caller, recenterFn) {

    console.log('Page height', document.querySelector('body').clientHeight)
    console.log('Page width', document.querySelector('body').clientWidth)

    
    if (caller == 'messageSend') {
        SendMessage()
        return
    }

    if (!document.getElementById('message-init').classList.contains('active') && caller != 'sectionSelection') {
        //the input is closed so either can open it
        document.getElementById('send-icons').classList.add("visible")
        document.getElementById('messageInput').blur()
        document.getElementById('messageInput').classList.add("visible")
        document.getElementById('message-init').classList.add("active")
        document.getElementById('messageInput').inputMode = 'text'
        document.getElementById('messageInput').focus()

        CheckTutorialStep('OpenedInput')

        recenterFn()

        //scroll to bottom of message history
        document.getElementById('messagesList').scrollTo({
            top: document.getElementById('messagesList').scrollHeight,
            behavior: "smooth"
        });
        document.getElementById('expandButton').style.opacity = 0
    }
    else {
        //input is open, only close when clicking the '...'

        if (caller != 'messageInput' && caller != 'msgInput') {
            //first check if there are vibes visible, remove them first
            if (document.querySelectorAll('#messageContainer .vibe').length > 0) {
                //once Hide is completed it will call this again to close everything
                if (document.getElementById('vibesLow').classList.contains('selected')) {
                    RemovePackages()
                }
                HideBundles(true)
            }
            else {
                if (caller == 'msgInputPlaceholder'|| caller == 'sectionSelection' || !caller) {
                    document.getElementById('send-icons').classList.remove("visible")
                    document.getElementById('messageInput').classList.remove("visible")
                    document.getElementById('message-init').classList.remove("active","vibeActive")
                    document.getElementById('send-icons').classList.remove("visible")
                    document.getElementById('vibeContainer').classList.add("hidden")
                    document.getElementById('vibesToggle').classList.remove("selected")
                    document.getElementById('vibesLow').classList.remove('selected')
                    document.getElementById('vibesMenu').classList.add('hidden')
                    document.getElementById('shareBubble').style.display = 'none'
                    document.getElementById('announcementToggle').classList.remove('selected')

                    //deactivate soft keyboard for mobile
                    document.getElementById('messageInput').inputMode = 'none'
                    window.scrollTo(0, 0)
                }
            }
        }
        
        
    }
    
}

function DisplayAdminMessage (msg) {
    //get inner text 
    let innerText = document.querySelector('#announcementContainer .announcement span')
    //get parent 
    let parent = document.querySelector('#announcementContainer .parent')
    //insert announcement text 
    innerText.textContent = msg
    //get width of new msg + buffer
    let width = innerText.offsetWidth + 20
    //offet negative width to make invisible
    innerText.style.marginLeft = `-${width}px`
    //animate parent in
    parent.style.transform = 'translateY(0)'
    //animate inner text in
    //set interval to increase margin left until it's 0
    setTimeout(() => {
        let interval = setInterval(() => {
            if (parseInt(innerText.style.marginLeft) < 0) {
                innerText.style.marginLeft = `${parseInt(innerText.style.marginLeft) + 1}px`
            }
            else {
                clearInterval(interval)
            }
        }, 5);
    }, 800);
    
    
    //set timers for reset
    setTimeout(() => {
        parent.style.transform = 'translateY(75px)'
        setTimeout(() => {
            innerText.textContent = ''
        }, 1000);
    }, Math.min((msg.length / 1.1), 17) * 1000);
}

// async function GetGameSession() {
//     await fetch(curApi + 'GameSessions/GetGameSession?streamUri=' + streamId, {
//         method: 'GET',
//         headers: {
//             Accept: 'text/plain',
//             Authorization: 'Bearer ' + user.gameChatToken
//         }
//     })
//     .then(function (response) {
//         if (response.status >= 400 && response.status < 600) {
//             response.json().then(function (object) {
//                 if (object){
//                     alert(object.errorMessage);
//                 } 
//                 else {
//                     alert('Cannot join chat, session has expired');
//                 }
//             });
//         } else if (response.status === 200) {
//             return response.json();
//         } else {
            

//         }
//     })
//     .then((data) => {
//         if (data) {

//             gameSession = data

//             targetArtist = gameSession.targetArtist.displayName

//             console.log(data)

//             // let adminContent = data.adminAnnouncement;
//             // let announcement = adminContent.split('|||')[0];
//             // let ctaText = adminContent.split('|||')[1];
//             // let ctaUrl = adminContent.split('|||')[2];

//             // //build section ids obj
//             // for (let i = 0; i < data.chatSections.length; i++) {
//             //     let sectionName = data.chatSections[i].gridPlacement;
//             //     let sectionConnections =
//             //         data.chatSections[i].totalActiveConnections;

//             //     sectionIds[sectionName] = data.chatSections[i].id;

//             //     //$($('#section-select .section-tile').get(i)).text("<div><span>" + sectionName + "</span><p style='margin:0;'>"+ sectionConnections +" people</p></div>")
//             // }

//             // $('#stream-tagline h2').text(announcement);
//             // $('#stream-tagline .cta .button').text(ctaText);
//             // $('#stream-tagline a').attr('href', ctaUrl);

//             // SuccessfulLogin();

//             // //get user role
//             // GetRole(data.id);
//             return data
//         }
//     })
//     .catch(function (err) {
//         //no existing session with this id
//         return console.error(err.toString());
//     });
// }

async function GetGameSession() {
    try {
      const response = await fetch(curApi + 'GameSessions/GetGameSession?streamUri=' + streamId, {
        method: 'GET',
        headers: {
          Accept: 'text/plain',
          Authorization: 'Bearer ' + user.gameChatToken
        }
      });
  
      if (response.status >= 400 && response.status < 600) {
        const errorObject = await response.json();
        if (errorObject) {
          alert(errorObject.errorMessage);
        } else {
          alert('Cannot join chat, session has expired');
        }
      } else if (response.status === 200) {
        const data = await response.json();
        gameSession = data;
        targetArtist = gameSession.targetArtist.displayName;
        console.log(data);
        return data;
      } else {
        // Handle other response statuses if necessary
      }
    } catch (error) {
      console.error(error.toString());
      // Handle errors here
      throw error; // Re-throw the error to propagate it up the call stack if needed
    }
  }

  
async function GetUserRole() {
    await fetch(curApi + 'AdminsForSessions/GetRoleForSession/' + streamId, {
        method: 'GET',
        headers: {
            Accept: 'text/plain',
            Authorization: 'Bearer ' + user.gameChatToken
        }
    })
    .then(function (response) {
        if (response.status >= 400 && response.status < 600) {
            response.json().then(function (object) {
                if (object){
                    alert(object.errorMessage);
                } 
                else {
                    console.log('cant fetch user role');
                }
            });
        } else if (response.status === 200) {
            return response.json();
        } else {
            

        }
    })
    .then((data) => {
        if (data) {
            if (data != 'User') {
                document.getElementById('announcementToggle').classList.remove('hidden')
            }
        }
    })
    .catch(function (err) {
        //no existing session with this id
        return console.error(err.toString());
    });
}

async function SendAdminMessage(message) {
    let msg = encodeURIComponent(message)

    await fetch(curApi + 'Admin/SendAdminMessage?messageText=' +
    msg +
    '&streamUri=' +
    streamId, 
    {
        method: 'POST',
        headers: {
            Accept: 'text/plain',
            Authorization: 'Bearer ' + user.gameChatToken
        }
    })
    .then(function (response) {
        if (response.status >= 400 && response.status < 600) {
            response.json().then(function (object) {
                if (object){
                    console.log(object.errorMessage);
                } 
                else {
                    console.log('cant send admin message');
                }
            });
        } else if (response.status === 200) {
            ChatInput(null, () => {})
            document.getElementById('messageInput').value = ""
        }
    })
    .catch(function (err) {
        //no existing session with this id
        return console.error(err.toString());
    });
}

async function UpdateLocation () {

    // try {
    //     window.PlayFab.ClientApi.UpdatePlayerLocation(loginRequest, (response, error) => {
    //       if (error) {
    //         handleLoginError(error);
    //       } else {
    //         const loginValue = {
    //           email: values?.email,
    //           sessionTicket: response?.data.SessionTicket
    //         };
    //         dispatch(login(loginValue));
    //       }
    //     });
    //   } catch (error) {
    //     toast.error(error?.errorMessage, toastConfig);
    //   }

    await fetch(userApi + 'update-player-location', {
        method: 'POST',
        headers: {
            Accept: 'text/plain',
            Authorization: encodedUser
        },
        body : JSON.stringify({
            "showId": gameSession.altId,
            "url": window.location.href.split('&')[0]
        })
    })
    .then(function (response) {
        if (response.status >= 400 && response.status < 600) {
            response.json().then(function (object) {
                console.log("failed to update location")
            });
        } else if (response.status === 200) {
            return response.json();
        } else {
            

        }
    })
    .then(() => {
        if (updateLocationInterval) clearInterval(updateLocationInterval)
        
        updateLocationInterval = setInterval(() => {
            UpdateLocation()
        }, 300000);
    })
    .catch(function (err) {
        //no existing session with this id
        return console.error(err.toString());
    });
}

export function BuildTutorialTotem (imgURL, voteId) {
    createTotemCallback(user.playFabId, imgURL, voteId, null,VoteForTotem)
}

export function CleanUpTutorialTotems () {
    destroySingleTotem(user.playFabId)
}

// define an observer instance
var observer = new IntersectionObserver(onIntersection, {
    root: null,   // default is the viewport
    threshold: .5 // percentage of target's visible area. Triggers "onIntersection"
  })
  
  // callback is called on intersection change
  function onIntersection(entries, opts){
    entries.forEach(entry =>  
      entry.target.classList.toggle('visible', entry.isIntersecting)
    )
  }

//UTILS
function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}