// import React, { Fragment } from "react";
// import { Helmet } from "react-helmet";

// // layout
// import { LayoutDefault } from "../../layouts";
// import { Header } from "../../components/header";
// import { Footer } from "../../container/footer";

// // container
// import GenresList from "../../container/genres-list";
// import DropDownMenu from "../../container/dropdown-menu";
// import ListCardLive from "../../container/list-live-now";
// import ListRecommendedShows from "../../container/list-recommended-shows";
// import SuggestedShows from "../../container/suggested-shows";

// // data
// import frisson from "../../data/frisson.json";
// import { typeGenre } from "../../selectors/genres.selector";
// import { useSelector } from "react-redux";

// import "./styles.scss";

// const DressingRoom = () => {
//   const { genre } = useSelector(typeGenre);

//   return (
//     <Fragment>
//       <Helmet>
//         <title>{`${frisson.title}`}</title>
//       </Helmet>
//       <p>Hello World</p>
//     </Fragment>
//   );
// };

// export default DressingRoom;

import React, { useEffect, useRef } from 'react';
// import ReactDOM from "react-dom";
// import { Redirect, Route } from "react-router-dom";
// import './style.css';
import './styles.scss';
import * as THREE from 'three';
import ThreeMeshUI from 'three-mesh-ui';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import jwt_decode from 'jwt-decode';
import { setCanDrag, dragControls, getRatio, getVal } from './drag'
import User from 'shared/audiophile/models/user';
import { screenSizeListener, screenSizeStates, mediaQueryState, sizes, getInitialSize, MQDIsposeEvents
} from './mediaQueries';
import logo from '../../assets/img/logo/logo.png';
import { Character, sharedAnims, CharacterClassInit
} from 'shared/audiophile/character';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { initChat, DisplaySections, ChatInput, SendMessage, DisconnectUser, startVid, AddMessageToHistory, updateLocationInterval, StartChat, targetArtist, userConnection, curApi, streamId, JoinRoom
} from './chatManager';
import { bubbles, clearAllBubbles, expandInit } from './trackedMessageController';
import { buildCanvas } from 'shared/canvasBuilder';
import { dynoCanvases } from 'shared/canvasBuilder';
import { buildTotemPanel, setupUI, tempUIElements, DisposeEvents, totems, setTotemImage, updateBounds
} from '../../shared/ui-builder';
import { render } from '@testing-library/react';
import { initCanvasBuilder } from 'shared/canvasBuilder';
import { messageEvent, messageEventInit } from './messageEvents';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { Vector3 } from 'three';
import { DisplayPackages, InitVibes, RemoveBundles } from './vibesManager';
import GetUser from '../../utilities/getUserLocalStorage';
import LoginForgotPasswordRegister from 'container/login-register';
import DynamicMenu from 'components/dynamic-menu';
import { InitTut, CheckTutorialStep, arrowData
} from 'shared/audiophile/onboarding';
import { EmojiButton } from '@joeattardi/emoji-button';
import { createPicker } from 'picmo';
import VibesMenu from 'components/vibes-menu';
import { ActivateTut } from 'shared/audiophile/onboarding';
import { TutDisplay } from 'shared/audiophile/onboarding';
import DynamicMenuModernized from 'components/dynamic-menu-modernized';
import Loading from 'components/loading';
import { spawn } from 'redux-saga/effects';
import exitSign from '../../assets/img/icons/exit-sign-hang.png'
import { ProposeNewTotem, VoteForTotem, activeVote } from './totemManager';
import { clone, delay } from 'lodash';
import { player, streamProvider } from './vidManager';
import CCModal from './ccModal';
import { SpinnerOverlay } from '../../components/spinner-overlay';
import { useSelector } from 'react-redux';
import { isLoading } from '../../selectors/auth.selector';
import { initted } from 'shared/audiophile/onboarding';
import { createPopup } from '@picmo/popup-picker';
import useSound from 'use-sound';
import { forEach } from 'lodash';
import Stats from 'three/examples/jsm/libs/stats.module.js';

function Loader() {
  const loading = useSelector(isLoading);
  return <SpinnerOverlay loading={loading} />;
}

export let relativeCenter = 0

const JoinTheCrowd = () => {
  /**
   * Constants
  */

  

  const titleId = '10B75'
  const modelPath = 'https://audiophileitems.blob.core.windows.net/items/'
  const mountRef = useRef(null);
  const [play, exposedData] = useSound(modelPath + 'crowd-background-loop.mp3', {
      volume: 1,
      loop: true
    })



  useEffect(() => {
    THREE.Cache.enabled = true;

    const script = document.createElement('script');

    script.src = "https://player.twitch.tv/js/embed/v1.js";
    script.async = true;

    document.head.prepend(script);
    
    let canObserveForCam = true
    let canAnimateCrowd = true
    let playerRoomPos = new THREE.Vector3(0, 0, 0)
    let isInRoom = false

    //===========================
    //try to launch vid player as soon as possible
    startVid();

    //display loading
    document.getElementsByClassName('loading')[0].style.display = 'block'

    //get the public initial crowd data for building the scene
    //section positions:
    const sectionPositions = {
      topLeft: new THREE.Vector3(-5.75, 0, -68.4),
      topMiddle: new THREE.Vector3(0, 0, -68.4),
      topRight: new THREE.Vector3(5.75, 0, -68.4),
      middleLeft: new THREE.Vector3(-5.75, 0, -45),
      center: new THREE.Vector3(0, 0, -45),
      middleRight: new THREE.Vector3(5.75, 0, -45),
      bottomLeft: new THREE.Vector3(-5.75, 0, -21.6),
      bottomMiddle: new THREE.Vector3(0, 0, -21.6),
      bottomRight: new THREE.Vector3(5.75, 0, -21.6)
    }
    let crowdParticlesContainer = {
      topLeft: {
        real: [],
        background: [],
      },
      topMiddle: {
        real: [],
        background: [],
      },
      topRight: {
        real: [],
        background: [],
      },
      middleLeft: {
        real: [],
        background: [],
      },
      center: {
        real: [],
        background: [],
      },
      middleRight: {
        real: [],
        background: [],
      },
      bottomLeft: {
        real: [],
        background: [],
      },
      bottomMiddle: {
        real: [],
        background: [],
      },
      bottomRight: {
        real: [],
        background: [],
      }
    }

    let currentSection

    //test section particles
    // particleTiming += 0.008
    // CreateSimpleCrowd(null, 15, true, frontLeftCenterPos)

    let initCrowdData
    let totemLocations = []

    async function GetCrowd() {
      await fetch(curApi + 'Votes/GetWinningVotedImagesForSessionBySections?sessionId=' + streamId, {
          method: 'GET',
          headers: {
              Accept: 'text/plain'
          }
      })
      .then(function (response) {
          if (response.status >= 400 && response.status < 600) {
              response.json().then(function (object) {
                  if (object){
                      alert(object.errorMessage);
                  } 
                  else {
                      alert('Cannot get crowd');
                  }
              });
          } else if (response.status === 200) {
              return response.json();
          } else {
              
  
          }
      })
      .then((data) => {
          if (data) {
              initCrowdData = data

              //iterate through sections and spawn audience members
              for (let i = 0; i < data.length; i++) {

                let sectionPos

                switch (data[i].gridPlacement) {
                  case 'TopLeft': sectionPos = sectionPositions.topLeft; 
                    break;
                  case 'TopMiddle': sectionPos = sectionPositions.topMiddle;; 
                    break;
                  case 'TopRight': sectionPos = sectionPositions.topRight; 
                    break;
                  case 'MiddleLeft': sectionPos = sectionPositions.middleLeft; 
                    break;
                  case 'Center': sectionPos = sectionPositions.center; 
                    break;
                  case 'MiddleRight': sectionPos = sectionPositions.middleRight; 
                    break;
                  case 'BottomLeft': sectionPos = sectionPositions.bottomLeft; 
                    break;
                  case 'BottomMiddle': sectionPos = sectionPositions.bottomMiddle; 
                    break;
                  case 'BottomRight': sectionPos = sectionPositions.bottomRight; 
                    break;
                  default: sectionPos = sectionPositions.center;
                }

                //reset animation timing
                particleTiming = 0.2
                //loop for each room
                // for (let j = 0; j < data[i].rooms.length; j++) {
                //   let room = data[i].rooms[j]
                //   let particle = CreateSimpleCrowd(null, Math.abs(room.roomTotalConnections), true, sectionPos, peopleParticlesMaterial,  true, `Crowd particle-${data[i].gridPlacement}-real`)

                //   switch (data[i].gridPlacement.charAt(0).toLowerCase() + data[i].gridPlacement.slice(1)) {
                //     case 'topLeft':
                //       crowdParticlesContainer.topLeft.real.push(particle)
                //       break;
                //     case 'topMiddle':
                //       crowdParticlesContainer.topMiddle.real.push(particle)
                //       break;
                //     case 'topRight':
                //       crowdParticlesContainer.topRight.real.push(particle)
                //       break;
                //     case 'middleLeft':
                //       crowdParticlesContainer.middleLeft.real.push(particle)
                //       break;
                //     case 'center':
                //       crowdParticlesContainer.center.real.push(particle)
                //       break;
                //     case 'middleRight':
                //       crowdParticlesContainer.middleRight.real.push(particle)
                //       break;
                //     case 'bottomLeft':
                //       crowdParticlesContainer.bottomLeft.real.push(particle)
                //       break;
                //     case 'bottomMiddle':
                //       crowdParticlesContainer.bottomMiddle.real.push(particle)
                //       break;
                //     case 'bottomRight':
                //       crowdParticlesContainer.bottomRight.real.push(particle)
                //       break;
                //     default:
                //       break;
                //   }


                //   //totem position adjustments
                //   // sectionPos.x += (Math.random() - 0.5) * 5
                //   // sectionPos.y = -4
                //   // sectionPos.z += (Math.random() - 0.5) * 5 - 10
                  
                //   // BuildTotem(room.room, room.image, null, sectionPos)
                // }

                  
              }
              // FadeParticles(realCrowdParticles, 0.6, 3.52)
          }
      })
      .catch(function (err) {
          //no existing session with this id
          return console.error(err.toString());
      });
    }
    
    //GetCrowd()

    //pop up room selection totems after animation
    async function ShowRoomTotems() {
      await GetCrowd()

      //iterate through sections and spawn audience members
      for (let i = 0; i < initCrowdData.length; i++) {

        let sectionPos

        switch (initCrowdData[i].gridPlacement) {
          case 'TopLeft': sectionPos = {...sectionPositions.topLeft}; 
            break;
          case 'TopMiddle': sectionPos = {...sectionPositions.topMiddle};; 
            break;
          case 'TopRight': sectionPos = {...sectionPositions.topRight}; 
            break;
          case 'MiddleLeft': sectionPos = {...sectionPositions.middleLeft}; 
            break;
          case 'Center': sectionPos = {...sectionPositions.center}; 
            break;
          case 'MiddleRight': sectionPos = {...sectionPositions.middleRight}; 
            break;
          case 'BottomLeft': sectionPos = {...sectionPositions.bottomLeft}; 
            break;
          case 'BottomMiddle': sectionPos = {...sectionPositions.bottomMiddle}; 
            break;
          case 'BottomRight': sectionPos = {...sectionPositions.bottomRight}; 
            break;
          default: sectionPos = {...sectionPositions.center};
        }

        //loop for each room
        for (let j = 0; j < initCrowdData[i].rooms.length; j++) {
          let room = initCrowdData[i].rooms[j]
          let full = room.roomTotalConnections == 20 ? true : false
          // let tempRoomId = room.split(',')[0].split('{ room = ')[1]
          // let tempImage = room.split(',')[3].split('image = ')[1].split(' }')[0]

          //totem position adjustments
          let totemPos = new THREE.Vector3()
          if (!totemLocations.some(obj => obj.roomId === room.room)) {

            let counter = 0;
            const maxAttempts = 1000;

            //generate a new position that isn't too close to another totem
            do {
              totemPos.x = sectionPos.x + (Math.random() - 0.5) * 5.5;
              totemPos.y = sectionPos.y - 4;
              totemPos.z = sectionPos.z + (((Math.random() - 0.5) * 15) -10);
              
              counter++;
          
              if(counter > maxAttempts) {
                  console.error("Max attempts reached");
                  break;
              }
          } while (totemLocations.some(obj => 
              Math.abs(totemPos.x - obj.pos.x) < 2 && Math.abs(totemPos.z - obj.pos.z) < 8));
          
          totemLocations.push({roomId: room.room, pos: totemPos});
            // for (let i = 0; i < totemLocations.length; i++) {
            //   const element = totemLocations[i];
              
            // }

            //get the first totem in the top middle to place the tut target
            if (initCrowdData[i].gridPlacement == 'Center' & j == 0) {
              roomArrowTargetId = room.room
              console.log(room.room)
            }
          }
          else {
            totemPos = totemLocations.find(obj => obj.roomId === room.room).pos 
          }

          BuildTotem(room.room, room.image, full ? 'full' : 'crowd', totemPos, () => {
              //InRoomCameraMove(sectionPos, room.room)
              JoinRoom(room.room, totemPos, i)
            }, true)

            let particle = CreateSimpleCrowd(null, Math.abs(room.roomTotalConnections), true, totemPos, peopleParticlesMaterial,  true, `Crowd particle-${room.room}-real`)

            switch (initCrowdData[i].gridPlacement) {
              case 'TopLeft':
                crowdParticlesContainer.topLeft.real.push(particle)
                break;
              case 'TopMiddle':
                crowdParticlesContainer.topMiddle.real.push(particle)
                break;
              case 'TopRight':
                crowdParticlesContainer.topRight.real.push(particle)
                break;
              case 'MiddleLeft':
                crowdParticlesContainer.middleLeft.real.push(particle)
                break;
              case 'Center':
                crowdParticlesContainer.center.real.push(particle)
                break;
              case 'MiddleRight':
                crowdParticlesContainer.middleRight.real.push(particle)
                break;
              case 'BottomLeft':
                crowdParticlesContainer.bottomLeft.real.push(particle)
                break;
              case 'BottomMiddle':
                crowdParticlesContainer.bottomMiddle.real.push(particle)
                break;
              case 'BottomRight':
                crowdParticlesContainer.bottomRight.real.push(particle)
                break;
              default:
                break;
            }
        }


        await new Promise(r => setTimeout(r, 100));
      }

      FadeParticles(realCrowdParticles, 0.6, 3.52)
      console.log("totems built", totems)
    }
    

    //get canvas container
    const canvasContainer = document.getElementById('canvasContainer2')
    const videoContainer = document.getElementById('videoContainer2')
    //get user speech bubble
    let initChatBtn

    //display loading
    document.getElementsByClassName('loading')[0].style.display = 'block'

    const stats = new Stats();
    stats.domElement.style.position = 'absolute';
    stats.domElement.style.top = '0px';
    canvasContainer.appendChild( stats.domElement );

    const width = window.innerWidth
    const height = window.innerHeight

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      0.1, width / height, 0.1, 1000
    )

    let defaultCamPos = new THREE.Vector3(0, 2.98, 20);
    let defaultCamRot = new THREE.Vector3(-0.04, 0, 0);
    let sectionSelectCamPos = new THREE.Vector3(0, 17, 52);
    let sectionSelectCamRot = new THREE.Vector3(-0.17, 0, 0);
    let roomCamOffset = new THREE.Vector3(0, 2.98, 0);
    let roomTotemPosition = new THREE.Vector3(6.5, 0, 0);

    let messageHistoryPOS = 0
    
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true})
    const canvas = renderer.domElement
    
    renderer.setClearColor(0x000000, 0);
    renderer.setSize(width, height)     
    mountRef.current.appendChild( renderer.domElement ); 



    function Dispose () {
      DisposeEvents();
      MQDIsposeEvents();
    }
    //this.eventsDispose = Dispose

    
    /**
     * Lights
     */
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
    scene.add(ambientLight)

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
    directionalLight.castShadow = true
    directionalLight.shadow.mapSize.set(1024, 1024)
    directionalLight.shadow.camera.far = 15
    directionalLight.shadow.camera.left = - 7
    directionalLight.shadow.camera.top = 7
    directionalLight.shadow.camera.right = 7
    directionalLight.shadow.camera.bottom = - 7
    directionalLight.position.set(5, 5, 5)
    // scene.add(directionalLight)

    const rimLight = new THREE.PointLight(0xC73AFC, 0.5)
    rimLight.position.set(-1.5, 0, 0.75)
    rimLight.name = "RimLight"
    scene.add(rimLight)

    const spotLight = new THREE.SpotLight( 0xffffff );
    spotLight.position.set( 0, 10, -75 );
    // spotLight.rotation.set(- Math.PI / 2, 0, 0)
    spotLight.castShadow = true;
    

    spotLight.shadow.mapSize.width = 1024;
    spotLight.shadow.mapSize.height = 1024;

    spotLight.shadow.camera.near = 0.5;
    spotLight.shadow.camera.far = 500;
    spotLight.shadow.camera.fov = 20;
    spotLight.shadow.focus = 1;
    spotLight.penumbra = 1
    spotLight.angle = 0.1
    spotLight.target.position.set(0,0,-75)
    spotLight.intensity = 0
    spotLight.power = 0
    scene.add( spotLight );
    scene.add( spotLight.target )
    // const helper = new THREE.CameraHelper( spotLight.shadow.camera );
    // scene.add( helper );

    //init canvas builder
    initCanvasBuilder(document);
   

    /**
     * Models
     */
    let anims = {}
    
    const dracoLoader = new DRACOLoader()
    const gltfLoader = new GLTFLoader()
    dracoLoader.setDecoderPath('/draco/')

    gltfLoader.setDRACOLoader(dracoLoader)

    const textureLoader = new THREE.TextureLoader( );

    //gound plane
    const groundPlane = new THREE.PlaneGeometry( 80, 150);
    const groundMat = new THREE.MeshStandardMaterial( {color: 0xffffff, side: THREE.FrontSide} );
    groundMat.color = new THREE.Color('#121212')
    // const groundTex = textureLoader.load(modelPath + 'grid.png')
    // groundTex.wrapS = groundTex.wrapT = THREE.RepeatWrapping;
    // groundTex.repeat.set( 200, 200 );
    // groundTex.anisotropy = 16;
    // groundTex.encoding = THREE.sRGBEncoding;
    // groundTex.flipY = false
    // groundMat.map = groundTex
    const groundMesh = new THREE.Mesh( groundPlane, groundMat );
    groundMesh.position.set(0, 0.03, -40)
    groundMesh.rotation.x = - Math.PI / 2;
    groundMesh.receiveShadow = true
    groundMesh.visible = false
    scene.add(groundMesh)


    //container for onboarding arrow targets
    let arrowTargets = {};
    arrowTargets['bubble'] = 'bubble';
    arrowTargets['vibe'] = 'vibe';
    arrowTargets['join'] = 'join';

    const arrowTargetGeo = new THREE.BoxGeometry(1, 1, 1);
    const arrowTargetMat = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const sectionArrowTarget = new THREE.Object3D();
    sectionArrowTarget.name = 'section test';
    sectionArrowTarget.position.set(-3.37, 8.1, -3);
    scene.add(sectionArrowTarget);

    arrowTargets['sectionTarget'] = sectionArrowTarget;

    // const roomArrowTarget = new THREE.Object3D();
    const roomArrowTarget = new THREE.Mesh(arrowTargetGeo, arrowTargetMat);
    roomArrowTarget.name = 'room target';
    // roomArrowTarget.position.set(-2, 0, -68.4);
    roomArrowTarget.visible = false
    // scene.add(roomArrowTarget);

    // arrowTargets['roomTarget'] = roomArrowTarget;
    let roomArrowTargetId

    const totemArrowTarget = new THREE.Mesh(arrowTargetGeo, arrowTargetMat);
    totemArrowTarget.visible = false
    totemArrowTarget.name = 'totem target';
    totemArrowTarget.position.set(5.5, -2.76, -3);
    scene.add(totemArrowTarget);

    arrowTargets['roomTotemTarget'] = totemArrowTarget;

    const selfTotemArrowTarget = new THREE.Mesh(arrowTargetGeo, arrowTargetMat)
    selfTotemArrowTarget.visible = false
    selfTotemArrowTarget.name = 'self totem target';
    selfTotemArrowTarget.position.set(0.5, -1.9, 0.5);
    scene.add(selfTotemArrowTarget);

    arrowTargets['selfTotemTarget'] = selfTotemArrowTarget;

    //TEST sprite animator
    // let xOffset = 0
    // for (let i = 0; i < 40; i++) {
    //   var texture = new THREE.TextureLoader().load(
    //     modelPath + 'Test_Sprite_Sheet2_dark.png'
    //   );

    //   var geometry = new THREE.PlaneGeometry(5, 5, 5);
    //   var material = new THREE.MeshBasicMaterial({
    //     map: texture,
    //     transparent: true
    //   });

    //   var annie = new TextureAnimator(texture, 10, 6, 60, 60, i + (i *2)); // texture, #horiz, #vert, #total, duration.
    //   annie.start()

    //   var cube = new THREE.Mesh(geometry, material);
    //   cube.name = 'test sprite'
    //   cube.position.set(3.5 + xOffset, 5, ((Math.random() - 0.5) * 3) -70);
    //   cube.scale.set(0.25, 0.25, 0.25)
    //   scene.add( cube );
    //   xOffset += 0.25
    // }
    // var texture = new THREE.TextureLoader().load(
    //   modelPath + 'Test_Sprite_Sheet2_dark.png'
    // );
    // var texture2 = new THREE.TextureLoader().load(
    //   modelPath + 'Test_Sprite_Sheet2_dark.png'
    // );
    // var geometry = new THREE.PlaneGeometry(5, 5, 5);
    // var material = new THREE.MeshBasicMaterial({
    //   map: texture,
    //   transparent: true
    // });
    // var geometry2 = new THREE.PlaneGeometry(5, 5, 5);
    // var material2 = new THREE.MeshBasicMaterial({
    //   map: texture2,
    //   transparent: true
    // });
    // var annie1 = new TextureAnimator(texture, 10, 7, 60, 69, 0); // texture, #horiz, #vert, #total, duration.
    // annie1.start()

    // var annie2 = new TextureAnimator(texture2, 10, 6, 60, 60, 10); // texture, #horiz, #vert, #total, duration.
    // annie2.start()

    // var cube = new THREE.Mesh(geometry, material);
    // cube.name = 'test sprite'
    // cube.position.set(6.7, 4, -70);
    // cube.scale.set(0.25, 0.25, 0.25)
    // scene.add( cube );

    // var cube2 = new THREE.Mesh(geometry2, material2);
    // cube2.name = 'test sprite 2'
    // cube2.position.set(6.7, 4, -70);
    // cube2.scale.set(0.25, 0.25, 0.25)
    // scene.add( cube2 );
    // this.annie = annie;
    // function TextureAnimator(texture, tilesHoriz, tilesVert, tileDispDuration, startingTile) 
    // { 
    //   let obj = {};

    //   obj.texture = texture;
    //   obj.tilesHorizontal = tilesHoriz;
    //   obj.tilesVertical = tilesVert;
    //   obj.tileDisplayDuration = tileDispDuration;

    //   obj.numberOfTiles = tilesHoriz * tilesVert;

    //   obj.texture.wrapS = THREE.RepeatWrapping;   
    //   obj.texture.wrapT = THREE.RepeatWrapping;   
    //   obj.texture.repeat.set( 1/tilesHoriz, 1/tilesVert );
    //   obj.currentTile = startingTile;

    //   obj.nextFrame = function()
    //   {
    //     obj.currentTile++;
    //     if (obj.currentTile == obj.numberOfTiles)
    //       obj.currentTile = 0;

    //     let currentColumn = obj.currentTile % obj.tilesHorizontal;
    //     obj.texture.offset.x = currentColumn / obj.tilesHorizontal;

    //     let currentRow = Math.floor( obj.currentTile / obj.tilesHorizontal );
    //     obj.texture.offset.y = obj.tilesVertical - currentRow / obj.tilesVertical;
    //   }

    //   obj.start = function()
    //   { obj.intervalID = setInterval(obj.nextFrame, obj.tileDisplayDuration); }

    //   obj.stop = function()
    //   { clearInterval(obj.intervalID); }

    //   obj.start();
    //   return obj;
    // }   

    let charPositions = [
      {
        'pos' : new THREE.Vector3(-1.2, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(1.2, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-2.4, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(2.4, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-3.6, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(3.6, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-4.8, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(4.8, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-6, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(6, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-7.2, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(7.2, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-8.4, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(8.4, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-9.6, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(9.6, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-10.8, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(10.8, 0, -1.2), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(-12, 0, 0), 
        'occupied' : false
      },
      {
        'pos' : new THREE.Vector3(12, 0, 0), 
        'occupied' : false
      },
    ];
    

    camera.position.set(0,4,-73)
    camera.rotation.set(-0.17,0,0)
    scene.add(camera);

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    //===========================
    //
    //     User Properties
    // 
    //===========================

    let user = {}
    
    user = GetUser()
    if (user) {
      InitTut('crowd-chat', user, [DisconnectUser]);
      document.getElementById('tutorialButton').className = ''
    }
    else {
      user = {}
    }

    function CheckForUser() {

      //display loading
      if (document.getElementsByClassName('loading')[0]) {
        document.getElementsByClassName('loading')[0].style.display = 'block'
      }

      //extra work to get user available to animate fn
      let userContainer = GetUser();
      

      if (userContainer && window.localStorage.getItem('profile_user')) {
        
        for (let i = 0; i < Object.keys(userContainer).length; i++) {
          const prop = Object.keys(userContainer)[i];
          user[prop] = Object.values(userContainer)[i]
        }
        
        //tutorial has been completed, so we can init chat
        if (!initted) {
          InitTut('crowd-chat', user, [DisconnectUser])
          document.getElementById('tutorialButton').className = ''
        }
        // InitTut('crowd-chat', user, [DisconnectUser]);
        initChat(
            user,
            CharacterInit,
            GetGuestChar,
            LoadUsersInGroup,
            RemoveGuestChar,
            SectionSelectCameraMove,
            RoomSelectCameraMove,
            InRoomCameraMove,
            BuildTotem,
            CleanUpTotems,
            DestroyTotem
          );
        
          // canvasContainer.className = 'selection'
          document.getElementById('joinCrowdOverlay').className = 'hide'
        
        if(!JSON.parse(window.localStorage.getItem('frisson_state'))['auth'].isSignUp )   {
          
          StartChat()
          
          
          CheckTutorialStep('JoinedCrowdChat');
        }
        else {
          document.getElementsByClassName('loading')[0].style.display = 'none'
          document.getElementById('joinCrowdButton').style.display = 'block'
          document.getElementById('joinCrowdButton').classList.remove('disabled')
        }
                
      } else {
        if (document.getElementById('loginModal')) {
          document.getElementById('loginModal').style.display = 'block';
        }
        
        document.getElementsByClassName('loading')[0].style.display = 'none' 
        

        setTimeout(() => {
          CheckForUser();
        }, 500);
      }
    }

    //===========================
    //
    //     Init
    //
    //===========================

    

    //===========================
    //
    //     Character Functions
    // 
    //===========================

    const characters = {};

    CharacterClassInit(OneShotManager);

    function CharacterInit(user) {
      user = user;
      // GetUserCharacter();

      sectionSelection(true);
    }

    messageEventInit(ChangeAnimation, ChangeExpression);
    const defaultAnims = ['laughing', 'house', 'robot_dance', 'samba_dance'];

    //load deafult animations
    for (let i = 0; i < defaultAnims.length; i++) {
      const anim = defaultAnims[i];

      //only load the animation if we haven't already
      if (!sharedAnims.anim) {
        gltfLoader.load(modelPath + 'anim_' + anim + '.glb', (model) => {
          //add this anim to the library
          sharedAnims[anim] = model.animations[0];
        });
      }
    }
    //===========================
    //
    //     Camera Functions
    // 
    //===========================

    //set up the camera and section avatars for section and room selection
    const sectionSelection = (init) => {

      setCanDrag(false)
      
      
      isInRoom = false

      //make sure mobile totem clicker is hidden 
      document.getElementById('totemMobileButton').style.display = 'none'
      document.getElementById('totemMobileButton').style.pointerEvents = 'none'

      if (!init) {
        ChatInput('sectionSelection', RecenterCamera);
        DisconnectUser();
        clearAllBubbles()
        intervalManager('room', false);
      }

      document.getElementById('sectionSelectButton').style.display = 'none';
      document.getElementById('dsktpExpandButton').style.display = 'none';
      document.getElementById('message-init').style.display = 'none';
      document.getElementById('messagesList').classList.remove('room')

      // canvasContainer.className = 'selection'
      
      DisplaySections(init);
      //move camera to top-down position

      //SectionSelectCameraMove();
    };

    //move camera closer to selected section and populate new canvas with room details
    function roomSelection(sectionTile) {
      console.log('move the cam from room select now');
      console.log(sectionTile);

      RoomSelectCameraMove();
      sectionTile.visible = false;
    }

    function SectionSelectCameraMove(init) {
      groundMesh.visible = true

      if (!init) {
        //fade in simple crowd
        FadeParticles(crowdParticles, 0.6, 3.52)
        //fade out room crowd
        FadeParticles(roomCrowdParticles, 0, 2)
        //fade out spotlight
        gsap.to(spotLight, {
          duration: 1,
          intensity: 0,
          ease: 'power1.out'
        })
        //hide user
        //user.character.body.visible = false
        relativeCenter = 0

      }
      
      document.getElementById('message-init').style.opacity = 0
      gsap.to(camera.position, {
        duration: init ? 6.25 : 4.9,
        // x: sectionSelectCamPos.x,
        // y: sectionSelectCamPos.y,
        z: sectionSelectCamPos.z,
        ease: CustomEase.create("custom", "M0,0,C0.298,0.11,0.612,0.78,1,1"),
        onComplete: () => {
          if (user.character)  {
            user.character.body.visible = false
          }

          ShowRoomTotems()

          setTimeout(() => {
            canObserveForCam = true
          }, 500);

          if (mediaQueryState == 'mobilePortrait')
          {
            setCanDrag(true)
          }
          
        }
      });
      
      gsap.to(camera.rotation, {
        duration: 6.25,
        // x: sectionSelectCamRot.x,
        y: sectionSelectCamRot.y,
        z: sectionSelectCamRot.z,
        ease: CustomEase.create("custom", "M0,0,C0.378,0.332,0.516,0.902,1,1")
      })


      if (init) {
        //fov animation
        gsap.to(camera, {
          duration: 6.25,
          fov: 10,
          ease: "power4.out"
        });

        gsap.to(camera.position, {
          duration: 6.25,
          x: sectionSelectCamPos.x,
          y: sectionSelectCamPos.y,
          ease: CustomEase.create("custom", "M0,0,C0.298,0.11,0.612,0.78,1,1")
        });

        gsap.to(camera.rotation, {
          duration: 6.25,
          x: sectionSelectCamRot.x,
          ease: CustomEase.create("custom", "M0,0,C0.378,0.332,0.516,0.902,1,1")
        })
      }
      else {
        gsap.to(camera.position, {
          duration: 4.9,
          y: sectionSelectCamPos.y,
          ease: CustomEase.create("custom", "M0,0 C0.562,0 0.958,1 1,1")
        });

        gsap.to(camera.position, {
          duration: 2.5,
          x: sectionSelectCamPos.x,
          ease: "power3.out"
        });

        gsap.to(camera.rotation, {
          duration: 4.9,
          x: sectionSelectCamRot.x,
          ease: CustomEase.create("custom", "M0,0,C0.466,0.168,0.958,1,1,1")
        })
      }
      
      
    }

    function RoomSelectCameraMove(tilePos) {
      console.log(mediaQueryState)
      // switch (mediaQueryState) {
      //   case 'mobilePortrait':
      //     //desktop needs to be offset for some reason
      //     roomArrowTarget.position.set(tilePos.x - 0.5, 10, tilePos.z);
      //     break;
        
      //   default:
      //     roomArrowTarget.position.set(tilePos.x - 0.5, 10, tilePos.z + 0.81);
      //     break;
      // }

      
      gsap.to(camera.position, {
        duration: 4.9,
        x: tilePos.x,
        y: 2.5,
        z: tilePos.z,
        ease: 'power1.out'
      });
      gsap.to(camera.rotation, {
        duration: 4.9,
        x: 0,
        y: defaultCamRot.y,
        z: defaultCamRot.z,
        ease: 'power1.out'
      });
    }

    function InRoomCameraMove(selectedTotemId, roomPos) {

      console.log('room pos ', roomPos)
      console.log('totems', totems)

      relativeCenter = roomPos.x

      canvasContainer.className = 'room'

      playerRoomPos = new THREE.Vector3(roomPos.x, roomPos.y, roomPos.z - 18.5)
      let cameraPos = new THREE.Vector3(roomPos.x, roomCamOffset.y, roomPos.z + roomCamOffset.z)

      //move room particles to middle of selected room
      roomParticleContainer.position.set(roomPos.x, 1.25, roomPos.z - 9)
      // canAnimateCrowd = false

      //convert the selected totem to the room one
      

      console.log(totems[selectedTotemId])
      console.log('converting to room')
      //clean up the totem before deleting it
      
      // if (totems[selectedTotemId]) {
        console.log('selected totem', totems[selectedTotemId])
        RotateTotem(selectedTotemId, false)
        if (totems[selectedTotemId].interval) {
          clearInterval(totems[selectedTotemId].interval)
        }
        totems[selectedTotemId].interval = null;

        totems[selectedTotemId].id = 'room'
        console.log('setting as room')
        totems['room'] = totems[selectedTotemId]
        // delete totems[selectedTotemId]
      // }
      
      
      totems['room'].noRotate = true
      
      //clear out old join button and create vote button
      totems['room'].totem.children.forEach(child => {
        if (child.uuid == totems['room'].voteInfoContainer.uuid) {
          totems['room'].totem.remove(child)
          scene.remove(child)
        }
      });
      scene.remove(totems['room'].voteInfoContainer)
      delete totems['room'].voteInfoContainer
      //console.log('totem', totems['room'])
      buildTotemPanel('room', selectedTotemId, RotateTotem, () => { console.log("propose from totem");
        if (isInRoom) ProposeNewTotem()
      } , true);

      

      forEach(totems, (obj) => {
        // console.log(obj.id)
        // if (obj.id == selectedTotemId) {
        //   obj.id = 'room'
        // }

        //console.log(obj)

        //hide and destroy all other totems
        if (obj.id != 'room') {
          gsap.to(obj.totem.position, {
            duration: 0.75,
            y: -5,
            ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 ")
          });
        }
        else {
          gsap.to(obj.totem.position, {
            duration: 0.75,
            y: -1,
            z: playerRoomPos.z,
            ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 ")
          });
          gsap.to(obj.totem.scale, {
            duration: 0.75,
            x: 0.3,
            y: 0.3,
            z: 0.3,
            ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 ")
          });
          gsap.to(obj.totem.scale, {
            duration: 0.75,
            delay: 1,
            x: 0.4,
            y: 0.4,
            z: 0.4,
            ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 ")
          });
          gsap.to(obj.totem.position, {
            duration: 0.75,
            delay: 1,
            y: 0,
            ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 ")
          });
          gsap.to(obj.totem.position, {
            delay: 2.5,
            duration: 0.5,
            z: playerRoomPos.z + roomTotemPosition.z,
            x: playerRoomPos.x + roomTotemPosition.x,
            ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 "),
            onComplete: () => {
              totems['room'].noRotate = false
            }
          });
        }

        //set room totem arrow target
        // totemArrowTarget.position.set(playerRoomPos.x + roomTotemPosition.x - 1, -0.6, playerRoomPos.z);


      })
      setTimeout(() => {
        //CleanUpTotems()
      }, 1000);

      
      


      gsap.to(camera.position, {
        duration: 1,
        y: cameraPos.y,
        ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 "),
        onComplete: () => {
          //once the camera is lowered all the way, hide all other crowd particle systems
          // forEach(crowdParticles, (obj) => {
          //   let pos = obj.particles.position.z + 8.5
          //   // gsap.to(obj.particles.position, {
          //   //   duration: 2,
          //   //   z: pos,
          //   //   ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 ")
          //   // });
            
          //   colorTo(obj.particles, new THREE.Color('#333333'))
    
          // })

          // setTimeout(() => {
          //   forEach(Object.keys(crowdParticlesContainer), (key) => {
          //     //console.log(key)
          //     if (key !== gridPlacement) {
          //       crowdParticlesContainer[key].background.forEach(el => {
          //         el.visible = false
          //       });
          //       crowdParticlesContainer[key].real.forEach(el => {
          //         el.visible = false
          //       });
          //     }
          //   })
          // }, 200);

          
        }
      });
      gsap.to(camera.position, {
        duration: 2.5,
        x: cameraPos.x,
        z: cameraPos.z + 9,
        ease: 'power1.out', 
        onComplete: () => {
          if (!user.character)  {
            GetUserCharacter()
          }
          else {
            user.character.body.visible = true
            initChatBtn.style.opacity = 1
          }

          //disable all real crowd particles
          realCrowdParticles.forEach(element => {
            scene.remove(element.particles)
          });
          realCrowdParticles = []

          setCanDrag(true)
          isInRoom = true

          //enable history once in room
          document.getElementById('messagesList').classList.add('room')
        } 
      });
      gsap.to(camera.rotation, {
        duration: 1,
        // delay: 1,
        x: defaultCamRot.x,
        y: defaultCamRot.y,
        z: defaultCamRot.z,
        ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 ")
      });

      //fade out simple crowd
      FadeParticles(crowdParticles, 0, 2.25)
      FadeParticles(realCrowdParticles, 0, 2.25)
      //fade in room crowd
      FadeParticles(roomCrowdParticles, 0.6, 2)
      // forEach(crowdParticles, (obj) => {
      //   gsap.to(obj.particles.material, {
      //     duration: 3.25,
      //     opacity: 0,
      //     ease: 'none'
      //   })
      // })
      // //fade in room crowd
      // forEach(roomCrowdParticles, (obj) => {
      //   gsap.to(obj.particles.material, {
      //     duration: 2,
      //     opacity: 0.6,
      //     ease: 'none'
      //   })
      // })

      //reenable the section select button
      document.getElementById('sectionSelectButton').style.display = 'block';
      document.getElementById('dsktpExpandButton').style.display = 'block';

      // let totemRoomPos = new THREE.Vector3(roomPos.x + 6, roomPos.y, roomPos.z -10)
      let totemRoomPos = new THREE.Vector3(roomPos.x + 6, roomPos.y, roomPos.z - 10)

      // BuildTotem('room', null, null, totemRoomPos, ProposeNewTotem);
      // gsap.to(totems['room'].totem.position, {
      //   x: 6,
      //   duration: 1,
      //   ease: 'power1.out',
      //   delay: 1
      // })
      // BuildTotem('center', new THREE.Vector3(0, 0, -3.4))
      // BuildTotem('left', new THREE.Vector3(-5.8, 0, -3.4))

      //move spotlight
      spotLight.position.set(roomPos.x, 20, roomPos.z)
      spotLight.target.position.set(playerRoomPos.x, 0, playerRoomPos.z)
      gsap.to(spotLight, {
        delay: 1,
        duration: 1,
        intensity: 1,
        ease: 'power1.out'
      })
    }

    function RecenterCamera() {
      gsap.to(camera.position, {
        duration: 0.3,
        x: relativeCenter,
        ease: 'power1.out'
      });
    }

    //===========================
    //
    //     Crowd Particles
    // 
    //===========================

    // Material
    // Background crowd Mat
    const particleTexture = new THREE.TextureLoader().load(modelPath + 'crowd-silhouette2.png')
    const particlesMaterial = new THREE.PointsMaterial()
    particlesMaterial.size = 5
    particlesMaterial.sizeAttenuation = true
    // particlesMaterial.depthTest = false
    particlesMaterial.map = particleTexture
    particlesMaterial.transparent = true
    particlesMaterial.alphaMap = particleTexture
    // particlesMaterial.alphaTest = 0.001
    particlesMaterial.depthWrite = false

    //Real crowd mat
    const peopleParticleTexture = new THREE.TextureLoader().load(modelPath + 'crowd-silhouette-bright2.png')
    const peopleParticlesMaterial = new THREE.PointsMaterial()
    peopleParticlesMaterial.size = 5
    peopleParticlesMaterial.sizeAttenuation = true
    // particlesMaterial.depthTest = false
    peopleParticlesMaterial.map = peopleParticleTexture
    peopleParticlesMaterial.transparent = true
    peopleParticlesMaterial.alphaMap = peopleParticleTexture
    // peopleParticlesMaterial.alphaTest = 0.00001
    peopleParticlesMaterial.depthWrite = false
    // peopleParticlesMaterial.blending = THREE.AdditiveBlending

    //In-room crowd mats
    const roomParticleTexture1 = new THREE.TextureLoader().load(modelPath + 'crowdSilhouette-full-inRoom1.png')
    const roomParticleTexture2 = new THREE.TextureLoader().load(modelPath + 'crowdSilhouette-full-inRoom2.png')
    const roomParticleTexture3 = new THREE.TextureLoader().load(modelPath + 'crowdSilhouette-full-inRoom3.png')
    const roomParticleTexture4 = new THREE.TextureLoader().load(modelPath + 'crowdSilhouette-full-inRoom4.png')
    const roomParticlesMaterial1 = new THREE.PointsMaterial()
    roomParticlesMaterial1.size = 14
    roomParticlesMaterial1.sizeAttenuation = true
    roomParticlesMaterial1.map = roomParticleTexture1
    roomParticlesMaterial1.transparent = true
    roomParticlesMaterial1.alphaMap = new THREE.TextureLoader().load(modelPath + 'crowdSilhouette-full-inRoom1-alpha.png')
    roomParticlesMaterial1.depthWrite = false
    roomParticlesMaterial1.opacity = 0.6
    const roomParticlesMaterial2 = new THREE.PointsMaterial()
    roomParticlesMaterial2.size = 14
    roomParticlesMaterial2.sizeAttenuation = true
    roomParticlesMaterial2.map = roomParticleTexture2
    roomParticlesMaterial2.transparent = true
    roomParticlesMaterial2.alphaMap = new THREE.TextureLoader().load(modelPath + 'crowdSilhouette-full-inRoom2-alpha.png')
    roomParticlesMaterial2.depthWrite = false
    roomParticlesMaterial2.opacity = 0.6
    const roomParticlesMaterial3 = new THREE.PointsMaterial()
    roomParticlesMaterial3.size = 14
    roomParticlesMaterial3.sizeAttenuation = true
    roomParticlesMaterial3.map = roomParticleTexture3
    roomParticlesMaterial3.transparent = true
    roomParticlesMaterial3.alphaMap = new THREE.TextureLoader().load(modelPath + 'crowdSilhouette-full-inRoom3-alpha.png')
    roomParticlesMaterial3.depthWrite = false
    roomParticlesMaterial3.opacity = 0.6
    const roomParticlesMaterial4 = new THREE.PointsMaterial()
    roomParticlesMaterial4.size = 14
    roomParticlesMaterial4.sizeAttenuation = true
    roomParticlesMaterial4.map = roomParticleTexture4
    roomParticlesMaterial4.transparent = true
    roomParticlesMaterial4.alphaMap = new THREE.TextureLoader().load(modelPath + 'crowdSilhouette-full-inRoom4-alpha.png')
    roomParticlesMaterial4.depthWrite = false
    roomParticlesMaterial4.opacity = 0.6

    let roomParticleMats = [roomParticlesMaterial1, roomParticlesMaterial2, roomParticlesMaterial3, roomParticlesMaterial4]

    
  
    

    //create low LOD crowd particles
    let crowdParticleColors = ['#656599', '#9291b9', '#626da6', '#61688c', '#6d7799', '#656599', '#9291b9', '#626da6', '#61688c', '#6d7799', '#656599', '#9291b9', '#626da6', '#61688c', '#6d7799', '#656599', '#9291b9', '#626da6', '#61688c', '#6d7799']
    let crowdParticles = []
    let realCrowdParticles = []
    let roomCrowdParticles = []
    let roomParticleContainer = new THREE.Object3D()
    roomParticleContainer.rotation.set(0.03, 0, 0)
    let particleTiming = 0.1
    let crowdPerType = 600
    // for (let i = 0; i < crowdParticleColors.length; i++) {
    //   CreateSimpleCrowd(crowdParticleColors[i], 100, false)
    // }
    
    for (let i = 0; i < roomParticleMats.length; i++) {
      const mat = roomParticleMats[i];

      // CreateSimpleCrowd('#656599', 50, true, new THREE.Vector3(0, 0, -45), el, false, `In-room-crowd-${i}`)

      let xRange = 60
      let zRange = 90

      // Geometry
      const particlesGeometry = new THREE.BufferGeometry()


      const colors = new Float32Array(crowdPerType * 3)
      const positions = new Float32Array(crowdPerType * 3) // Multiply by 3 because each position is composed of 3 values (x, y, z)

      let xVal = 0
      let yVal = 1
      for(let i = 0; i < crowdPerType * 3; i++) // Multiply by 3 for same reason
      {

        //every 2 loops clamp color so that it can't be a grey
        if (i % 2 == 0) {
          colors[i] = Math.min(Math.random(), 0.8)
        }
        else {
          colors[i] = Math.random()
        }
        

        if (i == xVal) {
          positions[i] = (Math.random() - 0.5) * xRange
          // do {
          //   positions[i] = Math.floor(Math.random() * (xRange)) - (xRange / 2); // Generates a number between -30 and 30
          // } while (Math.abs(positions[i]) < 5)
          
          xVal += 3
        }
        else if (i == yVal) {
          positions[i] = Math.abs(Math.random()) + 1.9
          yVal += 3
        }
        else {
          // if (i % 2 == 0) {
          //   //run z twice and take lower to get a more dense crowd up front
          //   let z1 = (Math.random() - 0.5) * zRange
          //   let z2 = (Math.random() - 0.5) * zRange
          //   if (z1 < z2) { 
          //     positions[i] = z1
          //   }
          //   else {
          //     positions[i] = z2
          //   }
            
          // }
          // else {
          //   positions[i] = (Math.random() - 0.5) * zRange // Math.random() - 0.5 to have a random value between -0.5 and +0.5
          // }

          if (Math.abs(positions[i - 2]) < 8) {
            do {
              positions[i] =(Math.random() - 0.5) * zRange
            } while (Math.abs(positions[i]) < 13)
          }
          else {
            positions[i] = (Math.random() - 0.5) * zRange
          }
        }
        
      }
      let particles

      particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3)) // Create the Three.js BufferAttribute and specify that each information is composed of 3 values

      
      mat.color = new THREE.Color('#adadad')
      mat.opacity = 0
      particles = new THREE.Points(particlesGeometry, mat)
      
      
      // particlesMaterial.color = new THREE.Color('#ffffff')
      

      // Points
      particles.name = `In-room-crowd-${i}`
     
      //set the real people just a tiny bit closer to the camera to fix z fighting
      // particles.position.set(0, 0, -75 + (0.01 * i))
      particles.position.set(0, 0, (0.01 * i))

      // scene.add(particles)
      roomParticleContainer.add(particles)
      
      //for animating
      let particleObj = {}
      particleObj['particles'] = particles
      particleObj['timing'] = particleTiming
      roomCrowdParticles.push(particleObj)
      particleTiming += 0.01
      
    }
    scene.add(roomParticleContainer)

    particleTiming = 0.002

    //loop through each of the sections twice and spawn particles for each
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < Object.keys(sectionPositions).length; j++) {
        let particle = CreateSimpleCrowd(crowdParticleColors[j], 100, true, Object.values(sectionPositions)[j], particlesMaterial, false, `Crowd particle-${Object.keys(sectionPositions)[j]}`)
        
        switch (Object.keys(sectionPositions)[j]) {
          case 'topLeft':
            crowdParticlesContainer.topLeft.background.push(particle)
            break;
          case 'topMiddle':
            crowdParticlesContainer.topMiddle.background.push(particle)
            break;
          case 'topRight':
            crowdParticlesContainer.topRight.background.push(particle)
            break;
          case 'middleLeft':
            crowdParticlesContainer.middleLeft.background.push(particle)
            break;
          case 'center':
            crowdParticlesContainer.center.background.push(particle)
            break;
          case 'middleRight':
            crowdParticlesContainer.middleRight.background.push(particle)
            break;
          case 'bottomLeft':
            crowdParticlesContainer.bottomLeft.background.push(particle)
            break;
          case 'bottomMiddle':
            crowdParticlesContainer.bottomMiddle.background.push(particle)
            break;
          case 'bottomRight':
            crowdParticlesContainer.bottomRight.background.push(particle)
            break;
          default:
            break;
        }
      }
    }

    function CreateSimpleCrowd(color, total, section, sectionPos, material, real, name) {

      let xRange
      let zRange

      if (!real) {
        xRange = 8.3
        zRange = -53.3
      }
      else {
        console.log('real: ', total)
        if (total <= 10) {
            xRange = 1
            zRange = 1
        }
        else if (total > 10 && total <= 20) {
            xRange = 2
            zRange = 2
        }
        else if (total > 20 && total < 30) {
            xRange = 3
            zRange = 3
        }
         else {
            xRange = 5
            zRange = 5
        }
      }

      // Geometry
      const particlesGeometry = new THREE.BufferGeometry()


      const colors = new Float32Array(total * 3)
      const positions = new Float32Array(total * 3) // Multiply by 3 because each position is composed of 3 values (x, y, z)

      let xVal = 0
      let yVal = 1
      for(let i = 0; i < total * 3; i++) // Multiply by 3 for same reason
      {

        //every 2 loops clamp color so that it can't be a grey
        if (i % 2 == 0) {
          colors[i] = Math.min(Math.random(), 0.9)
        }
        else {
          colors[i] = Math.random()
        }
        

        if (i == xVal) {
          if (i % 3 == 0) {
            let x1 = (Math.random() - 0.5) * xRange
            let x2 = (Math.random() - 0.5) * xRange
            if ( Math.abs(x1) < Math.abs(x2)) {
              positions[i] = x1
            }
            else {
              positions[i] = x2
            }
          }
          else {
            positions[i] = (Math.random() - 0.5) * xRange
          }
          
          xVal += 3
        }
        else if (i == yVal) {
          positions[i] = (Math.random()) * 1.5 + 1.5
          yVal += 3
        }
        else {
          if (i % 2 == 0) {
            if (!real) {
              //run z twice and take lower to get a more dense crowd up front
              let z1 = (Math.random() - 0.5) * zRange
              let z2 = (Math.random() - 0.5) * zRange
              if (z1 < z2) { 
                positions[i] = z1
              }
              else {
                positions[i] = z2
              }
            }
            else {
              positions[i] = ((Math.random() - 0.5) * zRange) + zRange
            }
            
          }
          else {
            positions[i] = (Math.random() - 0.5) * zRange // Math.random() - 0.5 to have a random value between -0.5 and +0.5
          }
          
          
        }
        
      }
      let particles

      particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3)) // Create the Three.js BufferAttribute and specify that each information is composed of 3 values

      if (real) {
        particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
        material.vertexColors = true
        material.opacity = 0
        material.size = 7
        particles = new THREE.Points(particlesGeometry, material)
      }
      else {
        material.color = new THREE.Color(color)
        particles = new THREE.Points(particlesGeometry, material)
      }
      
      // particlesMaterial.color = new THREE.Color('#ffffff')
      

      // Points
      particles.name = name
      
      // if (!section) {
      //   particles.position.set(0, 0, (Math.random() *2 )-50 - zRange)
      // }
      // else {
        //set the real people just a tiny bit closer to the camera to fix z fighting
          real ? particles.position.set(sectionPos.x, sectionPos.y, sectionPos.z - 1) : particles.position.set(sectionPos.x, sectionPos.y, sectionPos.z + 1)
        // }

      scene.add(particles)
      
      //for animating
      let particleObj = {}
      particleObj['particles'] = particles
      particleObj['timing'] = particleTiming
      real ? realCrowdParticles.push(particleObj) : crowdParticles.push(particleObj)
      particleTiming += 0.008

      return particles
    }

    function FadeParticles(particles, targetOpacity, duration) {
      forEach(particles, (obj) => {
        gsap.to(obj.particles.material, {
          duration: duration,
          opacity: targetOpacity,
          ease: 'none'
        })
      })
    }

  

    //===========================
    //
    //     External Functions
    // 
    //===========================

    function GetUserCharacter() {
      //get user main character
      fetch(
        'https://' + titleId + '.playfabapi.com/Client/GetUserReadOnlyData',
        {
          method: 'POST',
          headers: {
            Accept: 'text/plain',
            'Content-Type': 'application/json',
            'X-Authorization': user.playFabSessionToken
          },
          body: JSON.stringify({
            Keys: ['mainCharacter']
          })
        }
      )
        .then(function (response) {
          if (response.status >= 400 && response.status < 600) {
            response.json().then(function (object) {
              alert(object.errorMessage);
            });
          } else if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          user.mainCharacter = data.data.Data.mainCharacter.Value;

          //we have to get all the user's characters first
          fetch(
            'https://' +
              titleId +
              '.playfabapi.com/Client/GetAllUsersCharacters',
            {
              method: 'POST',
              headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization': user.playFabSessionToken
              },
              body: JSON.stringify({
                PlayFabId: user.playFabId
              })
            }
          )
            .then(function (response) {
              if (response.status >= 400 && response.status < 600) {
                response.json().then(function (object) {
                  alert(object.errorMessage);
                });
              } else if (response.status === 200) {
                return response.json();
              }
            })
            .then((data) => {
              let userChars = [];
              if (Object.keys(data.data.Characters).length != 0) {
                for (let i = 0; i < data.data.Characters.length; i++) {
                  const char = data.data.Characters[i];

                  if (char.CharacterType === 'audiophile-1') {
                    userChars.push(char);
                  }
                }

                if (userChars.length) {
                  user.characters = userChars;

                  //get the data now that we have the character id
                  fetch(
                    'https://' +
                      titleId +
                      '.playfabapi.com/Client/GetCharacterReadOnlyData',
                    {
                      method: 'POST',
                      headers: {
                        Accept: 'text/plain',
                        'Content-Type': 'application/json',
                        'X-Authorization': user.playFabSessionToken
                      },
                      body: JSON.stringify({
                        PlayFabId: user.playFabId,
                        CharacterId: user.mainCharacter,
                        Keys: ['items', 'customizations', 'animations']
                      })
                    }
                  )
                    .then(function (response) {
                      if (response.status >= 400 && response.status < 600) {
                        response.json().then(function (object) {
                          alert(object.errorMessage);
                        });
                      } else if (response.status === 200) {
                        return response.json();
                      }
                    })
                    .then((data) => {
                      if (Object.keys(data.data.Data).length != 0) {
                        let props = data.data.Data;

                        console.log('successful data fetch');
                        let charProps = {
                          items: JSON.parse(props.items.Value),
                          customizations: JSON.parse(
                            props.customizations.Value
                          ),
                          displayName: user.displayName,
                          animations: JSON.parse(props.animations.Value)
                        };
                        console.log(charProps);
                        let newChar = new Character(charProps);
                        user.character = newChar;
                        characters[user.playFabId] = user.character;
                        //get new spawn pos
                        let spawnPos = new THREE.Vector3(playerRoomPos.x, playerRoomPos.y, playerRoomPos.z)

                        newChar.spawn(
                          user.playFabId,
                          user.mainCharacter,
                          spawnPos.x,
                          spawnPos.z,
                          scene,
                          () => {
                            //once char is visible enable bubble
                            if (initChatBtn.style.display == 'none') {
                              initChatBtn.style.display = 'block';
                            }
                            initChatBtn.style.opacity = 1

                            if (activeVote?.isVoteActive) {
                              activeVote.images.forEach((vote) => {
                                if (vote.userPosted?.playfabId == user.playFabId)
                                {
                                  BuildTotem(user.playFabId, vote.imageUri, vote.id, null, VoteForTotem)
                                }
        
                              })
                            }
                          }
                        );


                        //get existing inventory
                        // GetOwnedItems()

                        // PrepInitialControlPops(charProps)
                      } else {
                        console.log(
                          'no character item data, drop in the defaults'
                        );
                      }
                    })
                    .catch(function (err) {
                      //no existing session with this id
                      return console.log(err);
                    });
                } else {
                  return console.log('User has no character added to account');
                }
              } else {
                console.log('User has no characters somehow...');
              }
            })
            .catch(function (err) {
              //no existing session with this id
              return console.log(err);
            });
        });
    }

    async function LoadUsersInGroup(users) {
      await new Promise(r => setTimeout(r, 3000));
      for (let i = 0; i < users.length; i++) {
        const id = users[i];
        await GetGuestChar(id);
      }
    }

    let userClones = []

    async function GetGuestChar(id, clone) {
      //make sure we're ignoring any join events from the main user or anyone else already spawned in the room
      if (clone || (id != user.playFabId && !Object.keys(characters).includes(id))) {
        await fetch(
          'https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction',
          {
            method: 'POST',
            headers: {
              Accept: 'text/plain',
              'X-EntityToken': user.entityToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              FunctionName: 'CC_LoadCharacter',
              PlayFabId: user.playFabId,
              FunctionParameter: {
                playfabID: id
              }
            })
          }
        )
          .then((response) => response.json())
          .then((data) => {
            //only spawn if we found player data
            if (data.data) {
              const playerData = data.data.FunctionResult;

              
              if (playerData) {
                playerData.animations = ['idle'];

                //weird fix accommodating for user data change
                let displayName = playerData.displayName
                playerData.displayName = {}
                playerData.displayName.displayName = displayName
                
                //get new spawn pos
                let spawnPos = GetSpawnPos();
                let newChar
                if (clone) {
                  let displayName = typeof playerData.displayName === 'object' ? playerData.displayName.displayName : playerData.displayName
                  displayName += "-clone:" +(userClones.length+1)
                  newChar = new Character(playerData);
                  userClones.push(newChar)
                  characters[id+'-clone:'+userClones.length] = newChar
                  //get index of spawn pos for cleanup later
                  characters[id+'-clone:'+userClones.length].spawnVal = spawnPos[0]
                }
                else {
                  newChar = new Character(playerData);
                  characters[id] = newChar;
                  //get index of spawn pos for cleanup later
                  characters[id].spawnVal = spawnPos[0]
                }
                
                newChar.spawn(id, '', spawnPos[1].x, spawnPos[1].z, scene, () => {

                  //make sure we're still in a room, otherwise remove char
                  if (isInRoom) {
                    //check for active votes and spawn totem in front if so
                    if (activeVote?.isVoteActive) {
                      activeVote.images.forEach((vote) => {
                        if (vote.userPosted?.playfabId == id)
                        {
                          BuildTotem(id, vote.imageUri, vote.id, null, VoteForTotem)
                        }

                      })
                    }
                  }
                  else {
                    RemoveGuestChar(id)
                  }
                  
                  
                });
              }
            }
            
            
          })
          .catch(function (err) {
            console.log('no existing character found by that id, just move past it')
            return console.log(err);
          })
      }
    }

    function RemoveGuestChar(id, cloneNum) {
      if (id == 'disconnect') {
        let ids = Object.keys(characters)
        for (let i = 0; i < ids.length; i++) {
          const id = ids[i];
          if (/*id != user.playFabId &&*/ characters[id]) {
            characters[id].body.traverse((child) => {
              // Test if it's a mesh
              if (child instanceof THREE.Mesh) {
                child.geometry.dispose();

                // Loop through the material properties
                for (const key in child.material) {
                  const value = child.material[key];

                  // Test if there is a dispose function
                  if (value && typeof value.dispose === 'function') {
                    value.dispose();
                  }
                }
              }
            });

            scene.remove(characters[id].body);
            scene.remove(characters[id].bubbleTarget)
            //clear out occupied spawn pos          
            delete spawnedCharsPos[characters[id].spawnVal]
            delete characters[id];
          }
        }

        spawnedCharsPos.length = 0

        delete user.character

        //remove room totem
        DestroyTotem('room');
      }
      //if other id is passed, remove just that one
      else if (id) {
        if (id != user.playFabId || cloneNum) {
          let charId = cloneNum ? id+'-clone:'+cloneNum : id 
          if (cloneNum && userClones[cloneNum-1]) {
            delete userClones[cloneNum-1]
            userClones.flat()
          } 


          characters[charId].body.traverse((child) => {
            // Test if it's a mesh
            if (child instanceof THREE.Mesh) {
              child.geometry.dispose();

              // Loop through the material properties
              for (const key in child.material) {
                const value = child.material[key];

                // Test if there is a dispose function
                if (value && typeof value.dispose === 'function') {
                  value.dispose();
                }
              }
            }
          });

          scene.remove(characters[charId].body);
          //clear out occupied spawn pos          
          spawnedCharsPos[characters[charId].spawnVal] = null
          delete characters[charId]
        }
      }
      //if no id then remove everyone except user (like when leaving a room)
      
    }

    const spawnedCharsPos = []
    function GetSpawnPos () {
      if (spawnedCharsPos.length > 0) {
        for (let i = 0; i < spawnedCharsPos.length; i++) {

          if (!spawnedCharsPos[i])
          {
            //alternate for odd/even
            // let xVal = i % 2 == 0 ? -1 : 1
            // let zVal = i % 2 == 0 ? -1.5 : 0.5 //TODO: Fix alternating z pos
            // charPos[i] = new THREE.Vector3(1.25 * (Math.max(1,Math.floor(i/2))) * xVal, 0, zVal)
            let newPos = new Vector3(charPositions[i].pos.x + playerRoomPos.x, charPositions[i].pos.y + playerRoomPos.y, charPositions[i].pos.z + playerRoomPos.z)

            spawnedCharsPos[i] = newPos

            return [i, spawnedCharsPos[i]]
          }
          
        }
        //didn't find an open spot in existing array, add new one
        //alternate for odd/even
        // let xVal = charPos.length % 2 == 0 ? -1 : 1
        // let zVal = zTracker == 2 ? -1.5 : 0.5
        // if (zTracker == 2) {
        //   zTracker = 0
        // }
        // else {
        //   zTracker++
        // }
        // charPos.push(new THREE.Vector3(1.25 * (Math.max(1,Math.floor(charPos.length/2)+1)) * xVal, 0, zVal))
        
        let newPos = new Vector3(charPositions[spawnedCharsPos.length].pos.x + playerRoomPos.x, charPositions[spawnedCharsPos.length].pos.y + playerRoomPos.y, charPositions[spawnedCharsPos.length].pos.z + playerRoomPos.z)

        spawnedCharsPos.push(newPos)
        return [spawnedCharsPos.length - 1, spawnedCharsPos[spawnedCharsPos.length-1]]
      }
      else {
        let newPos = {
          'pos' : new Vector3(charPositions[0].pos.x + playerRoomPos.x, charPositions[0].pos.y + playerRoomPos.y, charPositions[0].pos.z + playerRoomPos.z),
          'occupied' : false
        }
        spawnedCharsPos[0] = newPos
        return [0, spawnedCharsPos[0].pos]
      }
      
    }

    function CleanUpTotems() {
      //cache number of totems
      let numTotems = Object.keys(totems).length
      for (let i = 0; i < numTotems; i++) {
        const id = Object.keys(totems)[i];
        //console.log('cleaning up totem: ', id, "totems before: ", totems)
        
        intervalManager(id, false)
        if (id != 'room') DestroyTotem(id);
        
      }

      for (let key in totems) {
        if (totems.hasOwnProperty(key) && key !== 'room') {
          delete totems[key];
        }
      }
      
      // console.log('totems after: ', totems)

    }

    //not running for all totems in array
    function DestroyTotem(id) {
      // console.log('destroying totem ' + id)
      if (totems[id]) {
        clearInterval(totems[id].interval);

        totems[id].totem.traverse((child) => {
          // Test if it's a mesh
          if (child instanceof THREE.Mesh) {
            child.geometry.dispose();

            // Loop through the material properties
            for (const key in child.material) {
              const value = child.material[key];

              // Test if there is a dispose function
              if (value && typeof value.dispose === 'function') {
                value.dispose();
              }
            }
          }
        });
        scene.remove(totems[id].totem);

        //delete totems[id];
      }
    }

    function GetMainCharacter() {
      return user.characters?.[0].CharacterId;
    }

    function ChangeAnimation(userid, animation, oneshot) {
      characters[userid].startAnimation(animation, oneshot);
    }

    function ChangeExpression(userid, expression) {
      characters[userid].changeExpression(expression);

      if (userid == user.playFabId) {
        CheckTutorialStep('UpdatedExpression');
      }
    }

    function OneShotManager(userid, _listener) {
      // characters[userid].startAnimation();
      // characters[userid].mixer.removeEventListener('finished', _listener, true);
      characters[userid].currentlyAnimating = false
    }

    //===========================
    //
    //     Totems
    //
    //===========================

    //totem obj
    // const totems = {}

    function BuildTotem(id, imgUrl, voteId, position, btnCallback, selector) {
      //character not found
      if (!selector && id != 'room' && !characters[id]) {
        return
      }
      
      // console.log('building totem ' + id)
      let scale = 0
      
      if (id == 'room' || characters[id]) {
        scale = 0.45;

        if (!position) {
          position = new THREE.Vector3(
            characters[id].body.position.x + 0.5,
            characters[id].body.position.y + 2.5,
            characters[id].body.position.z
          );

          scale = 0.3;
        }

        
      }
      //room selector totems
      else {
        scale = 0.4;
      }

      gltfLoader.load(modelPath + 'totem.glb', (model) => {
          let totem = model.scene;
          totem.name = id + ' totem';
          totem.position.set(position.x, position.y - 10.5, position.z + 0.5);
          totem.scale.set(scale, scale, scale);
          scene.add(totem);
          // totem.visible = false

          totems[id] = {
            id: id,
            totem: totem,
            interval: null,
            rotationTimer: 0
          };

          buildTotemPanel(id, voteId, RotateTotem, btnCallback);

          //if we already have the img url, add it on immediately
          if (imgUrl) setTotemImage(id, imgUrl);

          AnimateVoteTotem(id, true);

          //set up tutorial room select totem if it matches
          if (roomArrowTargetId == id) {
            totems[id].totem.add(roomArrowTarget)
            roomArrowTarget.position.set(roomArrowTarget.position.x, 6.85, roomArrowTarget.position.z);
            arrowTargets['roomTarget'] = roomArrowTarget
            arrowTargets['roomTotemTarget'] = roomArrowTarget
          }
      });

      //if it's the tutorial move the totem arrow target
      if (voteId == 'tutorialVote') {
        selfTotemArrowTarget.position.set(position.x, 1, position.z);
      }
    }

    function RotateTotem(id, forward) {
      if (totems[id] && !totems[id].noRotate) {
        
        totems[id].totem.flipped = forward
        
        gsap.to(totems[id].totem.rotation, {
          y: forward ? 3 : 0,
          duration: 0.5,
          ease: 'power1.out'
        });

        intervalManager(id, forward);
      } 
      // else {
      //   intervalManager(id, false);
      // }

      if (isInRoom && forward && (mediaQueryState != 'desktop')) {
        document.getElementById('totemMobileButton').style.display = 'block'
        document.getElementById('totemMobileButton').style.pointerEvents = 'all'
      }
      else {
        document.getElementById('totemMobileButton').style.display = 'none'
        document.getElementById('totemMobileButton').style.pointerEvents = 'none'
      }
    }

    function AnimateVoteTotem(id, forward) {
      gsap.to(totems[id].totem.position, {
        y: forward ? -1 : -10,
        duration: 2,
        ease: CustomEase.create(
          'custom',
          'M0,0 C0,0 0.025,0.193 0.05,0.32 0.072,0.436 0.078,0.474 0.104,0.581 0.124,0.664 0.14,0.706 0.168,0.78 0.191,0.841 0.202,0.862 0.238,0.918 0.262,0.956 0.29,0.985 0.328,1.01 0.362,1.032 0.372,1.047 0.418,1.048 0.506,1.048 0.524,0.964 0.682,0.964 0.758,0.964 0.851,0.97 0.884,0.984 0.92,1 1,1 1,1 '
        )
      });
      if (id == 'room') {
        gsap.to(totems[id].totem.position, {
          delay: 2,
          x: 6,
          duration: 2,
          ease: 'power1.out'
        })
      }
    }

    function intervalManager(id, flag) {
      //console.log(totems)
      if (typeof flag != 'undefined' && totems[id]) {
        //reset timer
        totems[id].rotationTimer = 1;
        if (!totems[id].interval) {
          totems[id].interval = setInterval(function () {
            //make sure id still exists first!!
            if (!totems[id] && totems[id].interval) {
              clearInterval(totems[id].interval);
              return
            }
            
            if (totems[id].rotationTimer > 0) {
              totems[id].rotationTimer -= 1;
            } else {
              clearInterval(totems[id].interval);
              totems[id].interval = null;
              if (totems[id].totem.rotation.y > 0) {
                RotateTotem(id, false);
              }
            }
          }, 1000);
        }
      
      } else {
        if (totems[id]) {
          clearInterval(totems[id].interval);
          totems[id].interval = null;
        }
      }
    }

    getInitialSize(camera, renderer);

    //screen resizer + change callback
    screenSizeListener(mediaQueryChange);

    
    function mediaQueryChange(state) {
      console.log('Changed screen to ' + state);
      // AddMessageToHistory(state + ': ' + window.innerWidth + ' x ' + window.innerHeight, 'admin')

      if (totems['room']) {
        totems['room'].totem.visible = true
      }

      switch (state) {
        case 'desktop':
          //defaultCamPos = new THREE.Vector3(0, 3.95, 19);
          // sectionSelectCamPos = new THREE.Vector3(0, 90, 0);
          
          //totem
          roomTotemPosition = new THREE.Vector3(6.5, 0, 0);
          //totemArrowTarget.position.set(5.5, -1.6, -2.9);
          // if (totems['room']) {
          //   gsap.to(totems['room'].position, {
          //     x: roomTotemPosition.x,
          //     z: roomTotemPosition.z,
          //     duration: 0.5
          //   });
          // }
          break;

        case 'tabletLandscape':
          defaultCamPos = new THREE.Vector3(0, 4.5, 19);
          //sectionSelectCamPos = new THREE.Vector3(0, 115, 0);
          //totem
          roomTotemPosition = new THREE.Vector3(5.5, -1, 0);
          //totemArrowTarget.position.set(1.23, 0.4, -7.35);
          // if (totems['room']) {
          //   gsap.to(totems['room'].position, {
          //     x: roomTotemPosition.x,
          //     y: roomTotemPosition.y,
          //     z: roomTotemPosition.z,
          //     duration: 0.5
          //   });
          // }

          // camera.rotation.set(-0.15, 0, 0)

          break;

        case 'mobileLandscape':
          defaultCamPos = new THREE.Vector3(0, 9.1, 39);
          // sectionSelectCamPos = new THREE.Vector3(0, 90, 0);
          // camera.rotation.set(-0.15, 0, 0)

          //totem
          roomTotemPosition = new THREE.Vector3(1.23, -1, 0);
          //totemArrowTarget.position.set(1.23, -1, -7.35);
          if (totems['room']) {
            // gsap.to(totems['room'].position, {
            //   x: 1.23,
            //   y: -1,
            //   z: -7.75,
            //   duration: 0.5
            // });
            console.log(totems['room'])
            // totems['room'].totem.visible = false
          }

          break;

        case 'tabletPortrait':
          defaultCamPos = new THREE.Vector3(0, 5.8, 25.5);
          sectionSelectCamPos = new THREE.Vector3(0, 19.5, 73);
          roomCamOffset = new THREE.Vector3(0, 5.5, 10);
          defaultCamRot = new THREE.Vector3(-0.09, 0, 0);
          //totem
          roomTotemPosition = new THREE.Vector3(2.5, 1.5, -2);
          //totemArrowTarget.position.set(1, 0.4, -9.6);
          // if (totems['room']) {
          //   gsap.to(totems['room'].position, {
          //     x: roomTotemPosition.x,
          //     y: roomTotemPosition.y,
          //     z: roomTotemPosition.z,
          //     duration: 0.5
          //   });
          // }
          // camera.rotation.set(-0.15, 0, 0)

          //remove the char position 2 to the right of the char for the totem to take its place
          charPositions.splice(3, 1)

          break;
        case 'mobilePortrait':
          setCanDrag(true)

          //remove the char position to the right of the char for the totem to take its place
          charPositions.splice(1, 1)

          defaultCamPos = new THREE.Vector3(0, 5.8, 25.5);
          sectionSelectCamPos = new THREE.Vector3(0, 19.5, 63);
          roomCamOffset = new THREE.Vector3(0, 5.9, 11);
          defaultCamRot = new THREE.Vector3(-0.08, 0, 0);

          // //set room height css var
          // //get width of screen
          // let width = document.getElementById('ccParent').offsetWidth
          // let height = document.getElementById('ccParent').offsetHeight
          // let vidHeight = width * 0.5625
          // let roomHeight = height - vidHeight
          // document.getElementById('canvasContainer2').style.setProperty('--mobile-room-height', roomHeight + 'px')
          
          //set default pos of history
          // if (!document.getElementById('messagesList').classList.contains('expanded')) {
          //   messageHistoryPOS = Math.round(vidHeight)
          //   document.getElementById('messagesList').style.top = messageHistoryPOS + 'px' 
          // }

          //totem
          roomTotemPosition = new THREE.Vector3(1.4, -1, -3.5);
          // totemArrowTarget.position.set(1.5, 1.3, -9.6);
          // selfTotemArrowTarget.position.set(0.5, 0.7, 0.5)
          // if (totems['room']) {
          //   gsap.to(totems['room'].position, {
          //     x: roomTotemPosition.x,
          //     y: roomTotemPosition.y,
          //     z: roomTotemPosition.z,
          //     duration: 0.5
          //   });
          // }
          // gsap.to(camera.position, {x: 1.8, y: 1.7, z: 3.2, duration: 0.5})

          break;
        default:
          break;
      }
    }

    let totemMobileButton
    let keyDown

    //proto html declaration
    setTimeout(() => {
      //move message container inside canvas container at the end. This isn't a great way to do this
      canvasContainer
        .appendChild(document.getElementById('messageContainer'));
      canvasContainer
        .appendChild(document.getElementById('sectionSelectButton'));
        canvasContainer
        .appendChild(document.getElementById('dsktpExpandButton'));
      
      document
        .getElementById('joinCrowdButton')
        .addEventListener('click', function (event) {
          document
        .getElementById('joinCrowdButton').classList.add('disabled')

          CheckForUser();       
          
          
          //need to account for twitch at some point
          if (player) {
            //just try to play it again in case it hasn't started playing (like on mobile)
            
            
            if (streamProvider == 'yt') {
              player.playVideo()
              player.setVolume(0)
              player.unMute();
              player.setVolume(0)
              var timer = setInterval(function () {
                if (player.getVolume() >= 100){
                    clearInterval(timer);
                }
                //console.log(player.getVolume())
                player.setVolume(player.getVolume() + 1)
              }, 100);
            }
            else {
              player.play()
              player.setVolume(0)
              player.setMuted(false);
              player.setVolume(0)
              var timer = setInterval(function () {
                if (player.getVolume() >= 1){
                    clearInterval(timer);
                }
                //console.log(player.getVolume())
                player.setVolume(player.getVolume() + 0.1)
              }, 100);
            }
          }
        });
      //moved this to after video is loaded
      // document.getElementById('joinCrowdButton').style.opacity = 1;

      document
        .getElementById('tutorialButton')
        .addEventListener('click', function (event) {
          ActivateTut();
          TutDisplay(true);
        });

      document
        .getElementById('sectionSelectButton')
        .addEventListener('click', function (event) {
          sectionSelection();
        });
      document
        .getElementById('totemMobileButton')
        .addEventListener('click', function (event) {
          console.log('totem mobile button clicked')
          if (isInRoom) ProposeNewTotem()
        });
        totemMobileButton = document
        .getElementById('totemMobileButton')
      document
        .getElementById('messageContainer')
        .insertAdjacentHTML(
          'beforeend',
          '<div id="announcementContainer"><div class="parent"><span class="icon">📢</span><span class="announcement"><span class="text"></span></span></div></div><div id="vibesHeader" class="point hidden"></div><div id="vibesLow" class="point hidden"></div><div class="point" id="message-init"><div id="shareBubble" class="point">Show link copied to clipboard!</div><div class="speak"><div id="inputContainer"><input id="messageInput" type="text" autocomplete="off"></input></div><div id="msgInput"><span id="msgInputPlaceholder" class="visible">...</span><span id="send-icons"><span id="showShare" class="icon">🛸</span><span id="emojiToggle" class="icon">🙂</span><span id="vibesToggle" class="icon">💓</span><span id="announcementToggle" class="icon hidden">📢</span><i id="messageSend" class="icon fa fa-send"></i></span></div></div><div id="vibeContainer" class="hidden"><span>Sending<span><p id="vibeType"></p></div></div>'
        );
      document
        .getElementById('message-init')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          ChatInput(event.target.id, RecenterCamera);
          
        });
        initChatBtn = document.getElementById('message-init')
      document
        .getElementById('msgInputPlaceholder')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          ChatInput(event.target.id, RecenterCamera);
        });
      document
        .getElementById('messageSend')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          ChatInput(event.target.id, () => {/*don't move the cam on send, only open*/});
        });
      document
        .getElementById('vibesToggle')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          InitVibes(event.target.id);
        });
      document
        .getElementById('vibesLow')
        .addEventListener('click', function (event) {
          event.stopPropagation();
          DisplayPackages(true);
        });
        document
        .getElementById('showShare')
        .addEventListener('click', function (event) {
          event.stopPropagation();

          if (mediaQueryState == 'desktop' || !navigator.share) {
            // Copy the text inside the text field
            navigator.clipboard.writeText(window.location.href);

            // Alert the copied text
            document.getElementById('shareBubble').innerText = 'Link copied to clipboard!'
            document.getElementById('shareBubble').style.display = 'block'
            
            setTimeout(() => {
              document.getElementById('shareBubble').style.top = '-30px'
            }, 100);

            
            setTimeout(() => {
              if (document.getElementById('announcementToggle').classList.contains('selected')) {
                document.getElementById('shareBubble').innerText = '📢 This will be sent to ALL users!'
              }
              else  {
                document.getElementById('shareBubble').style.top = '30px'
              }
              
            }, 2500)
            if (!document.getElementById('announcementToggle').classList.contains('selected')) {
              setTimeout(() => {
                document.getElementById('shareBubble').style.display = 'none'
              }, 3000)
            }
             
          }
          else {
            const shareData = {
              title: `${targetArtist} - LIVE on frisson.live. Join the crowd!`,
              text: `Join me in the crowd at this LIVE virtual music festival!`,
              url: window.location.href,
            };
            navigator.share(shareData)
          }
           
        });
      document.getElementById('announcementToggle').addEventListener('click', function (event) {
        event.stopPropagation();

        document.getElementById('announcementToggle').classList.toggle('selected')

        if (document.getElementById('announcementToggle').classList.contains('selected')) {
          document.getElementById('shareBubble').innerText = '📢 This will be sent to ALL users!'
          document.getElementById('shareBubble').style.display = 'block'
          setTimeout(() => {
            document.getElementById('shareBubble').style.top = '-30px'
          }, 100);
        }
        else {
          setTimeout(() => {
            document.getElementById('shareBubble').style.top = '30px'
          }, 100)
           setTimeout(() => {
            document.getElementById('shareBubble').style.display = 'none'
          }, 1000)
        }

      });
        // document.getElementById('historyExpand').addEventListener('click', function (event) {
        //   event.stopPropagation();
        //   console.log('detecting close tap')
        //   CollapseHistory();
        // });
        document.getElementById('expandButton').addEventListener('click', function (event) {
          event.stopPropagation()
          if (document.getElementById('messagesList').classList.contains('dsktpExpanded'))
          {
            document.getElementById('messagesList').classList.remove('dsktpExpanded')
          }
          else {
            if (document.getElementById('expandButton').className != 'dis') {
              if (document.getElementById('messagesList').classList.contains('expanded')) {
                HistoryContract()
              }
              else {
                HistoryExpand()
              }
            }
          }
          
          

        })
        document.getElementById('dsktpExpandButton').addEventListener('click', function (event) {
          event.stopPropagation()
          // if (document.getElementById('expandButton').className != 'dis') {
          //   if (document.getElementById('messagesList').className == 'expanded') {
          //     document.getElementById('expandButton').style.opacity = 0
          //     HistoryContract()
          //   }
          //   else {
          //     document.getElementById('expandButton').style.opacity = 1
          //     HistoryExpand()
          //   }
          // }

          if (document.getElementById('messagesList').classList.contains('dsktpExpanded')) {
            document.getElementById('messagesList').classList.remove('dsktpExpanded')
          }
          else {
            document.getElementById('messagesList').classList.add('dsktpExpanded')
            //scroll window to bottom
            document.getElementById('messagesList').scrollTo({
              top: document.getElementById('messagesList').scrollHeight,
              behavior: "smooth"
          });
          }
          

        })

      // document.getElementById('ccModalButton').addEventListener('click', function (event) {
      //   //clicked join on modal, start tutorial
      //   ActivateTut();
      //   TutDisplay(true);
      //   //hide modal
      //   document.getElementById('ccModal').classList.add('hidden')
      // })

      // document.querySelector('#ccModal .close').addEventListener('click', function (event) {
      //   //clicked close on modal, don't start tutorial
      //   document.getElementById('ccModal').classList.add('hidden')
      //   initChat(
      //       user,
      //       CharacterInit,
      //       GetGuestChar,
      //       LoadUsersInGroup,
      //       RemoveGuestChar,
      //       SectionSelectCameraMove,
      //       RoomSelectCameraMove,
      //       InRoomCameraMove,
      //       BuildTotem,
      //       CleanUpTotems
      //     );
      //     //document.getElementById('loginModal').style.display = 'none';

      //     InitTut('crowd-chat', GetUser(), [DisconnectUser]);
      //     CheckTutorialStep('JoinedCrowdChat');
      // })
      // Execute a function when the user presses a key on the keyboard
      document.addEventListener('keydown', function (event) {
        KeyDown(event)
      });

      let scrollPos = 0
      let scrollUpAmt = 0

      let scrollStart
      let historyScroll = false
      let scrollEnd
      let lastPos = 0
      let scrollDir = 0
      let scrollDiff = 0
      let topExpand
      let heightExpand
      let topContract
      let heightContract

      let scrollingUp = false
      let scrollUpInfluence = 0
      let scrollDownInfluence = 0
      
      document.getElementById('messagesList').addEventListener('scroll', (event) => {
          // MessageListScrollChecker(event)
      });
      document.getElementById('messagesList').addEventListener('touchstart', (event) => { 
        lastPos = event.touches[0].clientY
        scrollStart = event.touches[0].clientY
      })
      document.getElementById('messagesList').addEventListener('touchmove', (event) => {
        if (userConnection.room && !document.getElementById('messagesList').classList.contains('expanded')) {
          historyTouchMove(event)
        }
      });
      // document.getElementById('historyDragBar').addEventListener('touchmove', (event) => {
      //     historyTouchMove(event)
      // });
      
      function historyTouchMove (event) {
        // if (false && !historyScroll && !document.getElementById('messagesList').classList.contains('expanded')) {
        //   historyScroll = true
        //   scrollStart = event.touches[0].clientY
        //   lastPos = scrollStart
        // }
        // else {
        //   scrollEnd = event.touches[0].clientY

          if (scrollEnd - scrollStart > 20 )
          {
            console.log('scrolling up')
            scrollUpInfluence ++

            if (scrollUpInfluence > 15)
            {
              scrollingUp = true
              scrollUpInfluence = 0
              document.getElementById('expandButton').style.opacity = 1
              document.getElementById('expandButton').style.pointerEvents = 'all !important'
            }
            
            
            // document.getElementById('messagesList').className = 'expanded'
            // document.getElementById('historyClose').style.display = 'block'
            // canExpand = false
          }
          else {
            console.log('scrolling down')
            scrollDownInfluence ++ 
            if (scrollDownInfluence > 15)
            {
              scrollingUp = false
              scrollDownInfluence = 0
              document.getElementById('expandButton').style.opacity = 0
              document.getElementById('expandButton').style.pointerEvents = 'none'
            }
          }

        //   scrollDiff = (scrollEnd - lastPos)
        //   let mlTop = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))
        //   let mlHeight = document.getElementById('messagesList').offsetHeight 

        //   if (mlTop >= 0 && mlTop <= messageHistoryPOS) {
            
        //     let offset = mlTop - scrollDiff > 0 ? mlTop - scrollDiff : 0
        //     if (offset == 0) {
        //       // historyScroll = false
        //       document.getElementById('messagesList').classList.remove('expanded')
        //     }
        //     if (offset > messageHistoryPOS) {
        //       offset = messageHistoryPOS
        //       // historyScroll = false
        //       document.getElementById('messagesList').classList.remove('expanded')
        //     }
        //     document.getElementById('messagesList').style.top = offset + 'px'
        //   }
        //   if (mlHeight <= window.innerHeight && mlHeight >= 150 ) {
        //     let newHeight = mlHeight + (scrollDiff * 2) 
        //     if (newHeight > window.innerHeight) {
        //       newHeight = window.innerHeight
        //       // historyScroll = false
        //       document.getElementById('messagesList').classList.remove('expanded')
        //     }
        //     if (newHeight < 150) {
        //       newHeight = 150
        //       // historyScroll = false
        //       document.getElementById('messagesList').classList.remove('expanded')
        //       document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0)'
        //     }
        //     document.getElementById('messagesList').style.height = newHeight + 'px'
        //     let alpha = newHeight / window.innerHeight
        //     document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
        //   }

        //   lastPos = scrollEnd
        //   console.log(scrollDiff)
        // }

        scrollEnd = event.touches[0].clientY
        // MessageListScrollChecker(event)
      }

      function HistoryExpand() {
        //temp disable while expanding
        document.getElementById('expandButton').className = 'dis'

        document.querySelector('#expandButton i').style.transform = 'rotate(180deg)'
        topExpand = setInterval(function () {
          clearInterval(topContract)
          let top = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))

          
          if(top <= 0) {
            console.log(`Clearing interval because top is ${top}`)
            clearInterval(topExpand)
            document.getElementById('messagesList').classList.add('expanded')
            // historyScroll = false
          }
          else {
            let newVal = top - 10 > 0 ? top - 10 : 0
            console.log(`New top is ${top}`)
            document.getElementById('messagesList').style.top = newVal + 'px'
          }
        }, 1)
        heightExpand = setInterval(function () {
          clearInterval(heightContract)
          let height = document.getElementById('messagesList').offsetHeight

          if(height >= window.innerHeight - 50) {
            clearInterval(heightExpand)
            document.getElementById('messagesList').classList.add('expanded')
            // historyScroll = false
            document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0.9)'
            //reenable
            document.getElementById('expandButton').className = ''
          }
          else {
            document.getElementById('messagesList').style.height = height + 10 + 'px'
            let alpha = height / window.innerHeight
            document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
          }
        }, 1)
      }
      function HistoryContract() {
        document.querySelector('#expandButton i').style.transform = 'rotate(0deg)'
        document.getElementById('expandButton').style.opacity = 0
        //temp disable while expanding
        document.getElementById('expandButton').className = 'dis'

        let topContract = setInterval(function () {
          clearInterval(topExpand)
          let top = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))

          if(top >= messageHistoryPOS) {
            clearInterval(topContract)
            document.getElementById('messagesList').classList.remove('expanded')
          }
          else {
            let offset = top + 10 > messageHistoryPOS ? messageHistoryPOS : top + 10
            document.getElementById('messagesList').style.top = offset + 'px'
          }
        }, 1)
        let heightContract = setInterval(function () {
          clearInterval(heightExpand)
          let height = document.getElementById('messagesList').offsetHeight

          if(height <= 150) {
            clearInterval(heightContract)
            document.getElementById('messagesList').classList.remove('expanded')
            document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0)'
            document.getElementById('messagesList').scrollTo({
              top: document.getElementById('messagesList').scrollHeight,
              behavior: "smooth"
            })
            //reenable
            document.getElementById('expandButton').className = ''
          }
          else {
            let offset = height - 10 < 150 ? 150 : height - 10
            document.getElementById('messagesList').style.height = offset + 'px'
            let alpha = offset / window.innerHeight
            document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
          }
      }, 1)
      }
      //send over to tracked messages controller so truncated msgs can be expanded
      expandInit(HistoryExpand)

      document.addEventListener('touchend', (event) => {
        // if (false && historyScroll || document.getElementById('messagesList').classList.contains('expanded')) {
        //   if (!document.getElementById('messagesList').classList.contains('expanded')) {
        //     // historyScroll = false
  
        //     //scrolling down
        //     if (scrollDiff > 0) {
        //       topExpand = setInterval(function () {
        //         clearInterval(topContract)
        //         let top = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))
    
        //         if(top <= 0) {
        //           clearInterval(topExpand)
        //           document.getElementById('messagesList').className = 'expanded'
        //           // historyScroll = false
        //         }
        //         else {
        //           let newVal = top - 10 > 0 ? top - 10 : 0
        //           document.getElementById('messagesList').style.top = newVal + 'px'
        //         }
        //       }, 1)
        //       heightExpand = setInterval(function () {
        //         clearInterval(heightContract)
        //         let height = document.getElementById('messagesList').offsetHeight
    
        //         if(height >= window.innerHeight - 50) {
        //           clearInterval(heightExpand)
        //           document.getElementById('messagesList').className = 'expanded'
        //           // historyScroll = false
        //           document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0.9)'
        //         }
        //         else {
        //           document.getElementById('messagesList').style.height = height + 10 + 'px'
        //           let alpha = height / window.innerHeight
        //           document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
        //         }
        //       }, 1)
        //     }
        //     //scrolling up
        //     else if (scrollDiff < 0) {
  
        //     }
        //     //even
        //     else {
  
        //     }
            
        //   }
        //   else if (document.getElementById('messagesList').classList.contains('expanded')) {
  
        //     //scrolling down
        //     if (scrollDiff > 0) {
              
        //     }
        //     //scrolling up
        //     else if (scrollDiff < 0) {
        //       let topContract = setInterval(function () {
        //         clearInterval(topExpand)
        //         let top = parseInt(document.getElementById('messagesList').style.top.replace(/px/,""))
    
        //         if(top >= messageHistoryPOS) {
        //           clearInterval(topContract)
        //           document.getElementById('messagesList').classList.remove('expanded')
        //         }
        //         else {
        //           let offset = top + 10 > messageHistoryPOS ? messageHistoryPOS : top + 10
        //           document.getElementById('messagesList').style.top = offset + 'px'
        //         }
        //       }, 1)
        //       let heightContract = setInterval(function () {
        //         clearInterval(heightExpand)
        //         let height = document.getElementById('messagesList').offsetHeight
    
        //         if(height <= 150) {
        //           clearInterval(heightContract)
        //           document.getElementById('messagesList').classList.remove('expanded')
        //           document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,0)'
        //           document.getElementById('messagesList').scrollTo({
        //             top: document.getElementById('messagesList').scrollHeight,
        //             behavior: "smooth"
        //           })
        //         }
        //         else {
        //           let offset = height - 10 < 150 ? 150 : height - 10
        //           document.getElementById('messagesList').style.height = offset + 'px'
        //           let alpha = offset / window.innerHeight
        //           document.getElementById('messagesList').style.backgroundColor = 'rgba(0,0,0,' + alpha + ')'
        //         }
        //       }, 1)
              
        //     }
        //     //even
        //     else {
  
        //     }
        //   }
        //   historyScroll = false
        // }
        // lastPos = 0
      })

      function MessageListScrollChecker (event) {
        let prevScroll = scrollPos
          
        if (event.target.scrollTopMax != 0)
        {
          scrollPos = event.target.scrollTopMax - event.target.scrollTop
        }
        
        console.log('scroll prev: ' + prevScroll)
        console.log('scroll pos: ' + scrollPos)
        if (prevScroll < scrollPos) {
          console.log("scrolling up")
          scrollUpAmt++
        } 
        else if (prevScroll > scrollPos){
          console.log("scrolling down")
          scrollUpAmt = 0
        }
        console.log('scrolled ' + scrollUpAmt)
        if (scrollUpAmt > 2)
        {
          // if (!document.getElementById('messagesList').classList.contains('expanded') && canExpand)
          // {
          //   console.log('trying to expand')
          //   document.getElementById('messagesList').className = 'expanded'
          //   document.getElementById('historyClose').style.display = 'block'
          // }
        }
          
          
      }

      function KeyDown (event) {
        // If the user presses the "Enter" key on the keyboard
        if (event.key === 'Enter') {
          // Cancel the default action, if needed
          event.preventDefault();
          if (
            document
              .getElementById('message-init')
              .classList.contains('active') &&
            document.getElementById('messageInput').value != ''
          ) {
            // Trigger the button element with a click
            SendMessage();
          } else if (
            !document
              .getElementById('message-init')
              .classList.contains('active')
          ) {
            event.stopPropagation();
            ChatInput(event.target.id, RecenterCamera);
            // ChatInput(event.target.id, () => {/*don't move the cam on send, only open*/});
          }
        } else if (
          event.key === 'Escape' &&
          initChatBtn.classList.contains('active')
        ) {
          ChatInput();
        }
      }

      keyDown = KeyDown

      const trigger = document.querySelector('#emojiToggle');

      //init emoji keyboard
      const picker = new createPopup({
        theme: 'picmo__dark',
        showVariants: false,
        position: 'bottom',
        showPreview: false,
        visibleRows: 5,
        emojiSize: '1.8rem'
      },
      {
        referenceElement: trigger,
        onPositionLost: 'close'
      }
      );

      

      picker.addEventListener('emoji:select', (selection) => {
        document.querySelector('#messageInput').value += selection.emoji;
      });

      trigger.addEventListener('click', () => {
        picker.toggle();
        trigger.classList.toggle('selected');
      });

      picker.addEventListener('picker:close', selection => {
        trigger.classList.remove('selected');
    }); 

      // window.addEventListener('click', (event)=> {
      //   CheckOutsideTap(event)
      // }) 

      // window.addEventListener('touchstart', (event)=> {
      //   CheckOutsideTap(event)
      // })

      function CheckOutsideTap (event) {
        if(initChatBtn.style.display != 'none' && document.getElementById('messageInput').classList.contains('visible') && event.target != document.getElementById('messageInput') && !event.target.classList.contains('emoji-picker__wrapper') && !event.target.classList.contains('emoji-picker__emoji')) {
          ChatInput()
        }
      }

      document.getElementById('message-init').style.display = 'none';
      // this.initChatBtn = initChatBtn;

      setupUI(scene, camera, renderer, canvas);

      // InitTut('crowd-chat', GetUser(), [DisconnectUser]);

      if (mediaQueryState == 'mobilePortrait' || mediaQueryState == 'tabletPortrait') {
        //set room height css var
        //get width of screen
        let width = document.getElementById('ccParent').offsetWidth
        let height = document.getElementById('ccParent').offsetHeight
        let vidHeight = width * 0.5625
        let roomHeight = height - vidHeight
        document.getElementById('canvasContainer2').style.setProperty('--mobile-room-height', roomHeight + 'px')

        //set default pos of history
        if (!document.getElementById('messagesList').classList.contains('expanded')) {
          messageHistoryPOS = Math.round(vidHeight)
          document.getElementById('messagesList').style.top = messageHistoryPOS + 'px' 
        }
      }
      
    }, 2000);

    //Drag Controls
    dragControls(canvas, camera, null);

    const clock = new THREE.Clock()
      let previousTime = 0

    //Utility function for tweening colors
    function colorTo (target, value){
      //console.log(`target color: ${target.material.color}`)
      var target = scene.getObjectByName(target.name);
      var initial = new THREE.Color(target.material.color.getHex());
  
      gsap.to(initial, {
          r: value.r,
          g: value.g,
          b: value.b,
          ease: CustomEase.create("custom", "M0,0 C0.292,0 0.904,1 1,1 "),
          onUpdate: function() { target.material.color = initial; }
      });
    }

    var animate = function (clock, previousTime) {
      const elapsedTime = clock.getElapsedTime();
      const deltaTime = elapsedTime - previousTime;
      previousTime = elapsedTime;
      //update all character animation mixers
      for (const [id, char] of Object.entries(characters)) {
        if (char.currentAnim) char.mixer.update(deltaTime);
      }
      // this.annie.update(deltaTime * 1000);

      //update positions for all visible bubbles

      let size = new THREE.Vector2(0, 0);
      renderer.getSize(size);
      let elementOffset = (videoContainer.offsetHeight - (window.innerHeight / 2) + (canvasContainer.offsetHeight / 2))

      for (const bubble of Object.entries(bubbles)) {
        //returns array, first is id, second is bubble obj
        if (bubble[1].element && characters[bubble[0]]?.body && characters[bubble[0]].bubbleTarget) {
          //get head pos of char
          // const headPos = new Vector3(this.characters[bubble[0]].body.position.x, this.characters[bubble[0]].body.position.y - this.characters[bubble[0]].headOffset, this.characters[bubble[0]].body.position.z)

          //offset will be different for self to avoid input bubble

          let headOffset =  bubble[0] == user?.playFabId ? 70 : 30

          let headPos = new THREE.Vector3();
          Object.values(
            characters[bubble[0]].bubbleTarget.getWorldPosition(headPos)
          );
          const screenPosition = headPos.clone();
          screenPosition.project(camera);
          const translateX = (screenPosition.x * size.x * 0.5) - (bubble[1].element.offsetWidth / 2);
          //55px is the overall size of the bubble element + the chat element
          const translateY = elementOffset  - initChatBtn.offsetHeight - screenPosition.y * size.y * 0.5 - headOffset;

          bubble[1].element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
        }
      }

      //update position of init chat button
      if (initChatBtn && characters[user?.playFabId]?.bubbleTarget) {
        //get head pos of char

        // const headPos = new Vector3(Object.values(this.characters)[0].body.position.x, Object.values(this.characters)[0].body.position.y - Object.values(this.characters)[0].headOffset, Object.values(this.characters)[0].body.position.z)

        let headPos = new THREE.Vector3();
        characters[user?.playFabId].bubbleTarget.getWorldPosition(headPos);
        // headPos.y -= Object.values(this.characters)[0].headOffset - Object.values(this.characters)[0]

        const screenPosition = headPos.clone();
        // const screenPosition = this.spinner.position.clone()
        screenPosition.project(camera);

        const translateX = (screenPosition.x * size.x * 0.5) - (initChatBtn.offsetWidth / 2);
        //53px is the overall size of the bubble element
        // const translateY = -screenPosition.y * size.y * 0.5 - 33;
        // const translateY = -screenPosition.y * size.y * 0.5 + canvasContainer.clientHeight - 60;
        const translateY = elementOffset - initChatBtn.offsetHeight - screenPosition.y * size.y * 0.5

        if (translateX > -window.innerWidth/2 && translateX < window.innerWidth/2 - 50)
        {
          initChatBtn.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
        }
        // else {
        //   console.log('x', translateX)
        //   console.log('width', window.innerWidth/2)
        // }
        
      }

      //update position 
      // if (this.roomTotem) {
      //   let totemPos = new THREE.Vector3()
      //   console.log(this.roomTotem)
      //   this.roomTotem.getWorldPosition(totemPos)

      //   const screenPosition = totemPos.clone();
      //   // const screenPosition = this.spinner.position.clone()
      //   screenPosition.project(this.camera);

      //   const translateX = screenPosition.x * size.x * 0.5;
      //   //53px is the overall size of the bubble element
      //   const translateY = -screenPosition.y * size.y * 0.5 - 33;

      //   this.totemMobileButton.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
      // }


      let objPos = new THREE.Vector3();
      if (
        arrowData.arrow1.obj &&
        arrowTargets[arrowData.arrow1.obj]
      ) {
        let translateX;
        let translateY;
        let arrow = document.getElementById('tutArrow-1')

        if (
          arrowData.arrow1.obj != 'bubble' &&
          arrowData.arrow1.obj != 'vibe' &&
          arrowData.arrow1.obj != 'join'
        ) {
          Object.values(arrowTargets[arrowData.arrow1.obj].getWorldPosition(objPos))
            const screenPosition = objPos.clone()
            screenPosition.project(camera)
            translateX = screenPosition.x * size.x * 0.5
            //55px is the overall size of the bubble element + the chat element
            // translateY = - screenPosition.y * size.y * 0.5
            translateY = elementOffset - (arrow.offsetHeight / 2) - screenPosition.y * size.y * 0.5 - 100
        } else {
          if (arrowData.arrow1.obj == 'bubble' && initChatBtn) {
            translateX = initChatBtn
              .getBoundingClientRect().x;
            translateY =
              initChatBtn.getBoundingClientRect().y -
              45 -
              initChatBtn.getBoundingClientRect()
                .height /
                2;
          } else if (
            arrowData.arrow1.obj == 'vibe' &&
            document.getElementsByClassName('vibesMenu ')[0]
          ) {
            translateX =
              document
                .getElementsByClassName('vibesMenu ')[0]
                .getBoundingClientRect().x +
              document
                .getElementsByClassName('vibesMenu ')[0]
                .getBoundingClientRect().width - 60;
            translateY =
              document
                .getElementsByClassName('vibesUI--header ')[0]
                .getBoundingClientRect().y +
              document
                .getElementsByClassName('vibesUI--header ')[0]
                .getBoundingClientRect().height /
                2;
          } else if (arrowData.arrow1.obj == 'join') {
            translateX = document
              .getElementById('joinCrowdButton')
              .getBoundingClientRect().x;
            translateY =
              document.getElementById('joinCrowdButton').getBoundingClientRect()
                .y -
              45 -
              document.getElementById('joinCrowdButton').getBoundingClientRect()
                .height /
                2;
          }
          // translateX = -30
          // translateY = 185
        }

        
        // arrow.className = "bounce " + arrowData.arrow1.arrow1Pos

        if (arrow) {
            arrow.style.transform = `translateX(${translateX}px) translateY(${translateY}px)` 
        }
      }
      if (
        arrowData.arrow2.obj &&
        arrowTargets[arrowData.arrow2.obj]
      ) {
        let arrow = document.getElementById('tutArrow-2');
        
        Object.values(
          arrowTargets[arrowData.arrow2.obj].getWorldPosition(objPos)
        );
        const screenPosition = objPos.clone();
        screenPosition.project(camera);
        const translateX = screenPosition.x * size.x * 0.5;
        //55px is the overall size of the bubble element + the chat element
        const translateY = elementOffset - (arrow.offsetHeight / 2) - screenPosition.y * size.y * 0.5 - 100

        
        // arrow.className = "bounce " + arrowData.arrow1.arrow1Pos

        if (arrow) {
          arrow.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
        }
      }

      //message history messages positioning
      //get all visible messages
      var visMessages = document.querySelectorAll('.historyMessage.visible p'), i

      //define min max movement of message
      //min = 0 | max = window total width - width of el
      //min -15 | max 15
      

      for (let i = 0; i < visMessages.length; i++) {
        const mes = visMessages[i];
        //find id of user that said it
        let id = mes.dataset.id

        if (characters[id]) {
          
          
          //find user char in char array and get x pos
          let xPos = characters[id].body.position.x.toFixed(2) - relativeCenter.toFixed(2)
          let distDif = xPos - (camera.position.x.toFixed(2) - relativeCenter.toFixed(2))

          let rat = getRatio(distDif, -15, 15)
          let mesPos = getVal(rat, 0, document.getElementById('messagesList').offsetWidth - mes.offsetWidth)
          mes.style.transform = 'translateX('+mesPos+'px)'
        }
      }

      //crowd particles
      if (canAnimateCrowd) {
        forEach(crowdParticles, (obj) => {
        
          obj.particles.position.y = (Math.sin(elapsedTime * obj.timing * 50) / 50) - 1.5

        })
        forEach(realCrowdParticles, (obj) => {
        
          obj.particles.position.y = (Math.sin(elapsedTime * obj.timing * 50) / 50) - 1.5

        })
        forEach(roomCrowdParticles, (obj) => {
        
          obj.particles.position.y = (Math.sin(elapsedTime * obj.timing * 50) / 50) - 1.5

        })
      }
      

      stats.update()

      ThreeMeshUI.update();

      renderer.render( scene, camera );
      const frameId = window.requestAnimationFrame(() => {animate(clock, previousTime)})
    };

    let onWindowResize = function () {
      camera.aspect = canvasContainer.clientWidth / (canvasContainer.clientHeight + 100);
      camera.updateProjectionMatrix();
      renderer.setSize( canvasContainer.clientWidth, (canvasContainer.clientHeight + 100) );
      updateBounds(renderer)
    };
    
    var ro = new ResizeObserver(entries => {
      // console.log('resized')
      if (canObserveForCam) {
        camera.aspect = canvasContainer.clientWidth / (canvasContainer.clientHeight + 100);
        camera.updateProjectionMatrix();
        renderer.setSize( canvasContainer.clientWidth, (canvasContainer.clientHeight + 100) );
        renderer.render( scene, camera );
        updateBounds(renderer)
        //console.log('resized')
      }
      
    });
    
    // Observe one or multiple elements
    ro.observe(canvasContainer)

    const handleUnload = () => {
      console.log('decided to close, disconnect');
      DisconnectUser()
    }

    const promptUnload = (e) => {
      console.log('deciding to close, disconnect');
  
      if (user.character) {
        sectionSelection()
  
        var message = 'o/';
  
        (e || window.event).returnValue = message; //Gecko + IE
        return message;
      }
  
      
    }

    window.addEventListener("resize", onWindowResize, false);

    window.addEventListener('unload', handleUnload);
    window.addEventListener('beforeunload', promptUnload);
    window.addEventListener('pagehide', promptUnload);
    window.addEventListener('pagehide', promptUnload);

    animate(clock, previousTime);

    return () => {
      
      console.log(exposedData)
      if (mountRef.current) mountRef.current.removeChild( renderer.domElement);

      window.removeEventListener('beforeunload', promptUnload);
      window.removeEventListener('unload', handleUnload);
      window.removeEventListener('pagehide', promptUnload);
      window.removeEventListener('pagehide', handleUnload);
      
      clearInterval(updateLocationInterval)

      // window.removeEventListener('storage', this.checkStorage);

      document.removeEventListener('keydown', function (event) {
        keyDown(event)
      });

      // Traverse the whole scene
      scene.traverse((child) => {
        // Test if it's a mesh
        if (child instanceof THREE.Mesh) {
          child.geometry.dispose();

          // Loop through the material properties
          for (const key in child.material) {
            const value = child.material[key];

            // Test if there is a dispose function
            if (value && typeof value.dispose === 'function') {
              value.dispose();
            }
          }
        }
      });
      // this.renderer.instance.dispose()
      DisposeEvents()
      MQDIsposeEvents()

      DisconnectUser();
      
    }
  }, []);

  return (
    <div id='ccParent'>
      <Loading />
      <CCModal />
      <Loader />

      <div id='ccContainer2'>
          <div id="joinCrowdOverlay">
              <input
              id="joinCrowdButton"
              class="ui"
              type="button" 
              // onClick={play}
              value="Join the Crowd! 🔊"
            ></input>
          </div>
          <div id="videoContainer2">
            <div class="streamBanner2">
              <img class="bannerLogo2" src={logo}></img>
            </div>
            <div id="streamContainer">
              <div id="stream"></div>
            </div>
            <div class="streamBanner2">
              <img class="bannerLogo2" src={logo}></img>
            </div>
          </div>
          <div id='canvasContainer2' className='closed' ref={mountRef}/>

          
          <div id="dsktpExpandButton"><i class="fa fa-history" aria-hidden="true"></i></div>
          <img id="sectionSelectButton" src={exitSign}></img>
          <div id="totemMobileButton" class="point"></div>
          
          <div id="messagesList">
            <div id="expandButton"><i class="fa fa-external-link-square" aria-hidden="true"></i></div>
          </div>
          <div id="messageContainer">
          </div>
          
          
          <input class="hidden" id="tutorialButton" type="button" value="?"></input>

          <input id="totemInput" type="file" style={{ display: 'none' }}></input>
          {/* <div id="loginModal">
            <LoginForgotPasswordRegister />
          </div> */}
          <DynamicMenuModernized position={3} direction="top" />
          <VibesMenu />
      </div>
    </div>
      
  );
}

export default JoinTheCrowd;