import React, { Fragment } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// layout
import { LayoutDefault } from '../../layouts';
import { Header } from '../../components/header';
import { Footer } from '../../container/footer';
// data
import frisson from '../../data/frisson.json';
import './styles.scss';

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Helmet>
        <title>{`${frisson.title}`}</title>
      </Helmet>
      <LayoutDefault className='bg-light template-font-1 bg_color--3'>
        <Header />
        <Container className='pl_md--60 pl_sm--0'>
          <Row>
            <div className='privacy-policy'>
              <h2>Frisson Privacy Policy</h2>
              <blockquote>
                <p>We appreciate your interest in the Frisson platform, products, features, and services which we make
                  available for your use as part of the platform (collectively the, “Service”).</p>
                <i>Last updated: April 25, 2021</i>
              </blockquote>
              <br />
              <p>
                This Frisson Privacy Notice applies to your use of <a rel='noopener noreferrer' target='__blank'
                                                                      href='http://www.frisson.live'>http://www.frisson.live</a>,
                software and websites
                developed by Frisson Studios, Inc. (with its affiliates, “Frisson”), or other products or services
                provided by Frisson and any other websites, applications, or services provided, owned, or operated by
                Frisson that link to this Privacy Notice (collectively, the “Frisson Services”). Frisson values the
                privacy of users, producers, and others who visit and use the Frisson Services (collectively or
                individually, “you” or “users”) and wants you to be familiar with how we collect, use, and disclose
                personal information from and about you. This notice describes our privacy policy. By visiting
                <a rel='noopener noreferrer' target='__blank'
                   href='http://www.frisson.live'> http://www.frisson.live</a>, setting up your Frisson account, or
                using the Frisson Services, you are
                accepting the practices described in this Privacy Notice, to the extent permitted by law.
              </p>
              <p>
                For purposes of data protection laws, Frisson Studios, Inc. located at 340 S Lemon Ave #4257 Walnut, CA
                91789, is the “data controller” of your information collected in connection with the Frisson Services.
              </p>
              <p>You may share personal information when using the Frisson Services. One example is when you provide
                information about yourself as part of the Frisson account creation process. Another is when you take
                certain actions on the Frisson Services that are public or intended to be public in nature, such as when
                you broadcast User Content, participate in a Crowd Chat, or post profile information. Given the social
                nature of some of the Frisson Services, that information may be collected, used, or disclosed by others
                who are part of that social interaction. In addition, some features of the Frisson Services are designed
                to provide others with information about user activity, such as identifying the user who created a
                Stream. We advise and encourage you to be mindful of this when considering your activity on the Frisson
                Services.</p>
              <p>Storage and access to cookies that are set in connection with the Frisson Services are governed by the
                Frisson Cookie Policy (“Cookie Policy”).</p>
              <br />
              <h4>1. Children’s Privacy.</h4>
              <p>
                IF YOU ARE UNDER 13 YEARS OF AGE, THEN PLEASE DO NOT USE OR ACCESS THE FRISSON SERVICES AT ANY TIME OR
                IN ANY MANNER.
              </p>
              <p>Protecting the privacy of young children is especially important. For that reason, Frisson does not
                knowingly collect or maintain personal information (as defined by the United States Children’s Online
                Privacy Protection Act) from persons under 13 years-of-age. If Frisson learns that personal information
                of persons under 13 has been collected on or through the Frisson Services, Frisson will take appropriate
                steps to delete this information.</p>
              <p>If you are the parent or legal guardian of a child under 13 who has become a Frisson Services member,
                then please contact Frisson at <a target='_blank' rel='noopener noreferrer'
                                                  href='mailto:support@frisson.live?Subject='> support@frisson.live</a> to
                have that child’s account terminated and personal
                information deleted.</p>
              <p>[For residents of the EEA and residents of Brazil where the processing of personal information is based
                on consent, Frisson will not knowingly engage in that processing for users under the age of consent
                established by applicable data protection law. If we learn that we are engaged in that processing with
                such users, we will halt such processing and will take reasonable measures to promptly remove applicable
                information from our records.]</p>
              <br />
              <h4>2. Personal Information Frisson Collects About You</h4>
              <p>
                We obtain information about you through the means discussed below when we provide the Frisson Services.
                Please note that we need certain types of information so that we can provide the Frisson Services to
                you. If you do not provide us with such information, or ask us to delete it, you may no longer be able
                to access or use the Frisson Services.
              </p>
              <p>User-provided Information: You may provide a variety of information about yourself to us, such as your
                name, voice and image (in any content you upload), Frisson username, email address, postal mailing
                address, telephone number, credit card number, and billing information when you register for Frisson
                Services; upload, purchase, view, or download certain content or products from the Frisson Services;
                enter contests or sweepstakes; or otherwise use the features and functionality of the Frisson
                Services.</p>
              <p>Automatically Collected Information: When you access the Frisson Services or open one of our emails, we
                automatically record and store certain information about your system by using cookies and other types of
                technologies. Cookies are small text files containing a string of alphanumeric characters that are sent
                to your browser. For information about what cookies are, how they work, how Frisson uses them, and how
                to remove them, please see our Cookie Policy. Examples of such information we automatically collect
                include Internet Protocol address (“IP Address”), a unique user ID, device and browser types and
                identifiers, referring and exit page addresses, software and system type, and information about your
                usage of Frisson Services. Examples of how Frisson uses automatically collected information include to:
                (a) automatically update the Frisson application on your system; (b) remember your information so that
                you will not have to re-enter it during your visit or the next time you access the Frisson Services; (c)
                provide customized advertisements, content, and information; (d) monitor the effectiveness of marketing
                campaigns; (e) monitor and store aggregate site usage metrics such as total number of visitors and pages
                accessed; and (f) track your entries, submissions, and status in any promotions or other activities.</p>
              <p>Information from Other Sources: We may obtain additional information from third parties and sources
                other than the Frisson Services. For example, we may obtain additional information from advertisers,
                games or services you use, or social media networks (such as Facebook) for which you have approved our
                access. When you access the Frisson Services through social media networks or when you connect the
                Frisson Services to social media networks, you are authorizing Frisson to collect, store, and use such
                additional information and content in accordance with this Privacy Notice. We may also obtain
                information from third-party services regarding your use of such services, including about your use of
                the User Content you choose to broadcast through the Frisson Services. We use this information to
                supplement the information we collect about you in order to provide more relevant, safer experiences for
                you with the Frisson Services and improve the Frisson Services, analytics, and advertising. If we
                combine or associate information from other sources with information that we collect through the Frisson
                Services, we will treat the combined information in accordance with this Privacy Notice.</p>
              <br />
              <h4>3. How Frisson Uses Personal Information</h4>
              <p>
                Frisson uses such information to operate, maintain, enhance, provide, create, and develop all of the
                features, functionality, and services (new or existing) found on the Frisson Services; provide security
                for our websites, products, software, or applications; manage relationships with Frisson account holders
                (e.g., Partners, Affiliates), including making or receiving payment; improve users’ experience with
                Frisson by providing content recommendations and by delivering content that we hope users will find
                relevant and interesting, including advertising and marketing messages; allow you to comment on content,
                and participate in online games, contests, or rewards programs; prevent fraud and abuse; and understand
                the usage trends of our users.
              </p>
              <p>We use your email address and/or telephone number to communicate with you, including to notify you of
                major Frisson Services updates, for customer service purposes, or to contact you regarding any content
                that you have posted to or downloaded from the Frisson Services. If you want to stop receiving
                notifications by telephone, reply STOP to any text message from us. Note that we may still send you
                additional informational or transactional notifications.</p>
              <p>Frisson may periodically send promotional materials or notifications to you related to the Frisson
                Services. If you want to stop receiving promotional materials, you can go to your account settings once
                you have logged in to the Frisson Services or follow the unsubscribe instructions at the bottom of any
                email from us. </p>
              <p>In certain cases, we have a legal obligation to collect and process your personal information (such as
                our obligation to share data with tax authorities).</p>
              <p>We may also ask for your consent to process your personal information for a specific purpose that we
                communicate to you. When you consent to our processing your personal information for a specified
                purpose, you may withdraw your consent at any time and we will stop the processing of your data for that
                purpose.</p>
              <p>Frisson will rely on legal grounds to process your personal information to the extent permitted by
                applicable law, which may include, without limitation: to honor contractual commitments, to take steps
                in anticipation of entering into contract, to fulfill legal obligations, your consent, and Frisson’s
                legitimate interests.</p>
              <br />
              <h4>4. When Frisson Discloses Personal Information</h4>
              <p>
                We share such information as described below:
              </p>
              <p>
                We disclose such information to service providers working on our behalf, such as, to: provide website
                hosting, maintenance, and security services; fulfill orders; conduct data analysis and create reports;
                offer certain functionality; or assist Frisson in improving the Frisson Services and creating new
                services and features. We require that these parties process such information in compliance with this
                Privacy Notice, we authorize them to use the information only for the purposes for which it is provided
                to them, and we require them to use reasonable confidentiality measures.
              </p>
              <p>
                Frisson may disclose user information if we believe in good faith that such disclosure is necessary to
                comply with U.S. state and federal laws or other applicable laws around the world (for example, in the
                country of your residence), or respond to a court order, judicial or other government request, subpoena,
                or warrant in the manner legally required.
              </p>
              <p>
                Frisson also reserves the right to disclose information that we believe, in good faith, is appropriate
                or necessary to protect Frisson from potential liability or from fraudulent, abusive, or unlawful uses;
                investigate and defend ourselves against third-party claims, or allegations; protect the security or
                integrity of the Frisson Services; or protect the rights, property, or safety of Frisson, our users, or
                others.
              </p>
              <br />
              <h4>5. Data Subject Rights and Your Choices </h4>
              <p>
                Frisson’s Privacy Choices page describes your privacy options with respect to the Frisson services,
                including information about how you may be able to review, update, or delete information that Frisson
                may have about you. You may decline to share certain information with Frisson, in which case Frisson may
                not be able to provide to you some of the features and functionality found on the Frisson Services. If
                you have created a Frisson Services account, depending on the service, you may be able to update your
                profile information and preferences or disable your account by logging in and going to the “settings”
                section.
              </p>
              <p>
                For other requests to review, update, delete, or otherwise limit Frisson’s use of information that you
                have provided directly to Frisson, you may contact <a target='_blank' rel='noopener noreferrer'
                                                                      href='mailto:support@frisson.live?Subject='> support@frisson.live</a>.
                In your request, please include
                your email address, name, address, and telephone number and specify all relevant background. To protect
                your privacy and security, we may take steps to verify your identity before granting you access or
                making corrections to your information. You are responsible for maintaining the confidentiality of your
                unique password and account information at all times.
              </p>
              <p>[Residents of the European Economic Area (“EEA”) and residents of Brazil can exercise certain data
                subject rights available to them under applicable data protection laws. We will comply with requests to
                exercise these rights in accordance with applicable law. Please note that in some circumstances, we may
                need to keep processing your information for certain legitimate interests or to comply with a legal
                obligation. If these rights apply to you, they may permit you to request that we:</p>
              <ul>
                <li>Obtain access to or a copy of certain personal information we hold about you.</li>
                <li>Prevent the processing of your personal information for direct marketing purposes (including any
                  direct marketing processing based on profiling).
                </li>
                <li>Update personal information that is out of date or incorrect.</li>
                <li>Delete certain personal information we hold about you.</li>
                <li>Restrict the way that we process and disclose specific personal information about you.</li>
                <li>Transfer your personal information to a third-party provider of services.</li>
                <li>Revoke consent that you previously provided for the processing of your personal information.</li>
              </ul>
              <p>For more information on how to exercise these rights, click here. If applicable, you may make a
                complaint to the data protection supervisory authority in the country where you are based.
                Alternatively, you may seek a remedy through local courts if you believe your rights have been
                breached.]</p>
              <br />
              <h4>6. Account Closure & Deletion</h4>
              <p>
                If you would like to close your account and delete your profile information on www.frisson.live go to
                https://www.frisson.live/profile. If you close your account, Frisson may retain certain information
                about you for the activities described in this Privacy Notice, or as permitted or required by applicable
                law.
              </p>
              <br />
              <h4>6. Third-Party Services and Websites</h4>
              <p>
                The Frisson Services may link to third-party websites or services. The privacy practices of those third
                parties are not governed by this Privacy Notice. We encourage you to review the privacy policies of
                these third-party websites and services to understand their practices.
              </p>
              <p>If you connect to a third-party service (such as Facebook, Twitter, or another third-party application)
                through the Frisson Services or otherwise link your Frisson account with a third-party service, you are
                requesting and authorizing us to share or grant access to information on your behalf (such as your
                username, the fact that your connection originated from the Frisson Services, and other relevant usage
                and diagnostic information) with such third party. We may also send information about the content you
                watch or your activities on the Frisson Services to such third parties. For example, we may make
                information available to app developers, game developers, and game publishers to facilitate purchases
                and awards of digital goods, such as games and in-game items.</p>
              <br />
              <h4>7. Advertisers and Analytics Providers</h4>
              <p>
                Frisson may use third-party Web analytics services in connection with the Frisson Services. These
                service providers use a variety of tracking technologies such as cookies to analyze how users use the
                Frisson Services. The information collected (described above in “Automatically Collected Information”)
                may be shared with or collected directly by these services and processed to evaluate your use of the
                Frisson Services.
              </p>
              <p>Frisson may also work with third-party ad networks, advertisers, and advertising analytics providers to
                target (and measure the performance of) ads to you both on and off the Frisson Services. We may share
                with these third parties (or they may directly collect) data such as cookie and mobile ad identifiers in
                order to engage in advertising activity (including to understand how you respond to advertisements and
                to serve relevant ads). Frisson also works with third parties to serve ads to you off Frisson’s
                Services. When we do so, we may share information such as your email address or resettable device
                identifier for that advertising partner to “match” to other information that may have about you.</p>
              <p>Advertisers on Frisson, or representatives or service providers working on their behalf (“Frisson
                Advertisers”), sometimes use technology to serve ads that appear on our services directly to your
                device. They automatically receive certain data, such as your IP address, when this happens. They may
                also use cookies (or similar technologies) to measure the effectiveness of their ads and to personalize
                ad content. This Privacy Notice does not apply to, and we cannot control the tracking technologies and
                activities of Frisson Advertisers and you should consult their respective privacy policies. </p>
              <br />
              <h4>8. Data Security</h4>
              <p>
                Frisson uses a variety of procedural, administrative, technical, and physical measures to protect your
                information. These measures vary based on the sensitivity of your information, but you acknowledge that
                no security precautions or systems can be completely secure. We cannot and do not ensure or warrant the
                security of any information you transmit to Frisson, and you do so entirely at your own risk. We cannot
                and do not guarantee that such information may not be accessed, disclosed, altered, or destroyed by
                breach of any of our physical, technical, or other safeguards and security measures.
              </p>
              <br />
              <h4>9. Retention of Your Information </h4>
              <p>
                Frisson maintains data related to your usage of Frisson for as long as it is required in order to
                fulfill the relevant purposes described in this Privacy Notice, as may be required (or permitted) by law
                such as for tax and accounting purposes, or as otherwise communicated to you. Once you have asked us to
                close your account and delete your profile information, we will delete all information that we are not
                required or permitted to retain by law.
              </p>
              <br />
              <h4>10. Merger or Sale of Frisson </h4>
              <p>
                In the event that Frisson, or some or all assets related to the Frisson Services are acquired by or
                merged with a third-party entity or in connection with a contemplated change of ownership transaction,
                we reserve all rights, in any of these circumstances, to share, transfer or otherwise assign or convey
                the information that we have collected from users as part of that merger, acquisition, sale, or other
                change of control event, including in the course of diligence prior to such transaction.
              </p>
              <br />
              <h4>11. International Data Transfers </h4>
              <p>Information collected by Frisson may be stored and processed in your region, in the United States (for
                instance in our major data centers), or in any other country where Frisson or its affiliates,
                subsidiaries, partners, or service providers are located or maintain facilities. If we provide any
                information about you to any such entities, we will take appropriate measures to ensure such companies
                protect your information adequately in accordance with this Privacy Notice and applicable law.</p>
              <br />
              <h4>12. Changes and Updates to This Privacy Notice </h4>
              <p>
                Frisson reserves the right to change, modify, add, or remove portions of this Privacy Notice at any time
                (for example to reflect updates to the Frisson Services or to reflect changes in the law). Please check
                this Privacy Notice periodically for those changes. Your continued use of the Frisson Services after the
                posting of changes constitutes your binding acceptance of such changes.
              </p>
              <p>[We will not materially change our policies and practices to make them less protective of personal
                information collected in the past under a prior version of the Privacy Notice without the consent of
                affected individuals.]</p>
              <br />
              <h4>13. Frisson Contact Information</h4>
              <p>
                Please contact Frisson with any questions or comments about this Privacy Notice at 340 S Lemon Ave #4257
                Walnut, CA 91789 or by email to <a target='_blank' rel='noopener noreferrer'
                                                   href='mailto:support@frisson.live?Subject='> support@frisson.live</a>.
                We will respond to your inquiry within 30 days of
                its receipt. If you are a California resident, you may have this same information emailed to you by
                sending a letter to the foregoing address with your email address and a request for this information.
              </p>
            </div>
          </Row>
        </Container>
        <Footer />
      </LayoutDefault>
    </Fragment>
  );
};
export default PrivacyPolicy;
