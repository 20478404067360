import React from "react";
import { Spinner } from "../spinner";
import "./styles.scss";
const Button = ({
  type,
  btnText,
  btnLink,
  size,
  btnStyle,
  customClass = "",
  color,
  onClick,
  disabled,
  loading
}) => {
  return type === "link" ? (
    <a
      className={`frisson-btn fr-btn-${color} btn-${size}-size btn-${btnStyle} ${customClass} space-between`}
      href={btnLink}
      onClick={onClick}
    >
      {btnText}
    </a>
  ) : (
    <button
      title='Sign Up Now'
      className={`frisson-btn fr-btn-${color} btn-${size}-size btn-${btnStyle} ${customClass} space-between`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      <div className={`content-button ${loading ? "button-spinner" : ""}`}>
        <span>{btnText}</span>{" "}
        {loading && (
          <span style={{ marginLeft: 20 }}>
            <Spinner />
          </span>
        )}
      </div>
    </button>
  );
};

export default Button;
