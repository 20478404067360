import React from "react";

// layout
import { Modal } from "react-bootstrap";
import Button from "../../components/button";

import "./styles.scss";

const DeleteWatchListArtistModal = ({
                                    onCloseModal,
                                    openModal,
                                    onRemoveItem,
                                    nameUser
                                  }) => {
  const handleDelete = () => {
    onRemoveItem();
    onCloseModal();
  };

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      size="md"
      show={Boolean(openModal)}
      onHide={onCloseModal}
      className="delete-watch-list-item-modal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Remove artist from watchlist</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to remove <b>{nameUser.DispalyName}</b> from your watchlist?</Modal.Body>
      <Modal.Footer>
        <div className="form-footer-action">
          <Button
            btnText={"Remove"}
            type="button"
            btnStyle={"rounded"}
            size={"sd"}
            color={"theme"}
            onClick={handleDelete}
          />
          <Button
            customClass="margin-left-10px"
            btnText={"Cancel"}
            type="button"
            btnStyle={"rounded"}
            size={"sd"}
            color={"theme-4"}
            onClick={onCloseModal}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteWatchListArtistModal;
