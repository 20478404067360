// data
import emojiData from 'shared/emojiTable.json';
import GraphemeSplitter from "grapheme-splitter"

const splitter = new GraphemeSplitter()

let reactionCallback
let expressionCallback
let oneShotManager

export function messageEventInit (reaction, expression) {
    reactionCallback = reaction
    expressionCallback = expression
}

export function messageEvent (userid, message) {
    let msgEmojis = emojiParse(message)
    
    //maybe check for certain words in addition to emojis like 'hey'
    if (msgEmojis.length) {
        console.log(msgEmojis)
        let action = checkTable(msgEmojis)
        console.log(action)

        if(action.reaction) reactionCallback(userid, action.reaction, true)
        //for now just take last expression
        if(action.expression) expressionCallback(userid, action.expression[action.expression.length-1])
        
    }
}

function emojiParse (message) {
    const withEmojis = /\p{Extended_Pictographic}/u

    let split = splitter.splitGraphemes(message)
    let arr = [];
    for (var i=0; i<split.length; i++) {
        let char = split[i]
        //commented section originally used to avoid duplicates
        if (withEmojis.test(char) /*&& !arr.includes(char)*/) {
            arr.push(char);
        }
    }
    return arr;
}

function checkTable (emojis) {

    let action = {}

    for (let i = 0; i < emojis.length; i++) {
        const em = emojis[i].codePointAt(0).toString(16)
            
        for (let j = 0; j < Object.values(emojiData).length; j++) {
                const type = Object.entries(emojiData)[j]
                
                for (let k = 0; k < Object.values(type[1]).length; k++) {
                    const val = Object.values(type[1])[k]
                    
                    if (val.triggers.includes(em)) {
                        if (!action[type[0]]) action[type[0]] = []

                        action[type[0]].push(val.result[Math.floor(Math.random()*val.result.length)])
                        
                    }

                }

            }
    }
    
    return action
    
}