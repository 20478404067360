import React from 'react';

import './styles.scss';

export const Spinner = ({ borderRadius }) => {
  return (
    <div className='content-loading' style={borderRadius && { borderRadius: borderRadius }}>
      <span className='loader' />
    </div>
  );
};
