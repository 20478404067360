import React, { useRef, useEffect, useMemo, useState } from 'react';
import Slick from 'react-slick';
import TitleCommon from '../../components/title-common';
import { BlockLoading } from 'react-loadingg';
import { useDispatch, useSelector } from 'react-redux';
import {
  isLoading,
  getScheduledPerformances
} from '../../selectors/searchGame.selector';
import {
  recentlyLiveRequest,
  scheduledPerformancesRequest
} from '../../slices/searchGameSession';
import { isEmpty } from 'lodash';
import RecentlyItem from '../../components/carousel-slider-top/recently-item';
import {
  FORMAT_DATE_TIME,
  PAGINATION,
  FILTER_STATUS
} from '../../shared/utils/constants';
import moment from 'moment';
import SheduledItem from '../../components/carousel-slider-bottom/sheduled-item';

const ScheduledPerformances = () => {
  const [countSlider, setCountSlider] = useState(1);
  const ref = useRef({});
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const { data, totalCount } = useSelector(getScheduledPerformances);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: '',
    prevArrow: '',
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const countPage = Math.ceil(totalCount / 5);
  const SlickSlider = ({ children, settings, classes }) => {
    return (
      <Slick {...settings} ref={ref} className={classes}>
        {children}
      </Slick>
    );
  };

  const newData = useMemo(() => {
    if (!data) return [];
    const newList = [];
    const cloneArray = data.records;
    const totalPage = Math.ceil(totalCount / 5);
    for (let i = 0; i < totalPage; i++) {
      if (i === 0) {
        newList.push({ [i]: cloneArray.slice(0, 5 * 1) });
      } else {
        newList.push({ [i]: cloneArray.slice(i * 5, (i + 1) * 5) });
      }
    }
    return newList;
  }, [totalCount, data]);

  useEffect(() => {
    if (!isEmpty(data)) return;
    dispatch(
      scheduledPerformancesRequest({
        pageSize: 5,
        pageIndex: 1,
        filter: [
          {
            key: 'startDatetime',
            operator: 'gte',
            value: new Date()
          }
        ],
        orderBy: {
          startDatetime: 'asc'
        }
      })
    );
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(
      scheduledPerformancesRequest({
        pageSize: 5,
        pageIndex: countSlider,
        filter: [
          {
            key: 'startDatetime',
            operator: 'gte',
            value: new Date()
          }
        ],
        orderBy: {
          startDatetime: 'asc'
        }
      })
    );
  }, [countSlider, dispatch]);
  const next = () => {
    // ref.current.slickNext();
    if (newData.length) {
      setCountSlider((prev) => ++prev);
    }
  };
  const previous = () => {
    if (countSlider > 1) {
      setCountSlider((prev) => --prev);
    }

    // ref.current.slickPrev();
  };

  return (
    <div className="wrapper-recently-slider bg-galleryGradient wow move-up">
      <TitleCommon title="Scheduled Performances" />
      {loading ? (
        <BlockLoading color="#df1a46" />
      ) : (
        <>
          <SlickSlider settings={settings}>
            {!isEmpty(newData) &&
              newData?.map((carousel, index) => {
                const cloneCarousel = carousel[index];
                return (
                  <main key={index}>
                    {cloneCarousel?.map((item, i) => {
                      return (
                        <SheduledItem
                          key={i}
                          item={item}
                          featureShow={carousel.featureShow}
                          title={carousel.title}
                          classItem={i === 3 ? 'item-b' : ''}
                        />
                      );
                    })}
                  </main>
                );
              })}
          </SlickSlider>
          <div className="live-now-pagination modify-pagination">
            <div className="box-pagination">
              <div className={`show-arrow ${countSlider === 1 ? 'disabled' : ''}`} onClick={previous}>
                <i className="fa fa-angle-left" aria-hidden="true" />
              </div>
              <div className={`show-arrow ${countSlider === countPage ? 'disabled' : ''}`} onClick={next}>
                <i className="fa fa-angle-right" aria-hidden="true" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ScheduledPerformances;