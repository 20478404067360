import React, { forwardRef } from "react";
import Slick from "react-slick";

import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

const SlickSlider = forwardRef(({ children, settings, classes, sliderRef }) => {
  return (
    <Slick {...settings} className={classes} ref={sliderRef}>
      {children}
    </Slick>
  );
});

export default SlickSlider;
