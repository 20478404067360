import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import moment from 'moment';

const CalendarInput = ({
                         showTimeSelect,
                         timeFormat,
                         timeIntervals,
                         timeCaption,
                         dateFormat,
                         showTimeSelectOnly,
                         formik,
                         name,
                         handleChange,
                         minDate,
                         isDisabled,
                         disablePast,
                         styley
                       }) => {
  const [val, setVal] = useState('');
  const data = new Date();
  const now = moment().format('DD/MM/YYYY HH:mm A');
  const dataTime = () => {
    return (Date.parse(val) + 3600000) < Date.parse(data);
  };
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => {
      setVal(value);
      const dataTime = () => {
        return (Date.parse(val) + 3600000) < Date.parse(data);
      };


      return (<div
        className={`input-content-control `}
        onClick={!isDisabled ? onClick : null}
      >
        <i className='fa fa-calendar' aria-hidden='true' />
        <input
          value={value || ''}
          type='text'
          disabled={dataTime() ? true : isDisabled}
          className='input-custom-control '
        />
      </div>);
    }
  );
  return (
    <>
      <DatePicker
        selected={formik.values[name]}
        onChange={handleChange}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        timeCaption={timeCaption}
        dateFormat={dateFormat}
        minDate={minDate}
        disablePast={disablePast}
        showTimeSelectOnly={showTimeSelectOnly}
        filterDate={(date) => {
          return moment() < date;
        }}
        customInput={<ExampleCustomInput />}
        disabled={dataTime()}
      />
      {formik.errors[name] ? (
        <div className='error'>{formik.errors[name]}</div>
      ) : null}
    </>
  );
};
export default CalendarInput;
