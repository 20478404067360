import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Image, Dropdown } from 'react-bootstrap';
import DefaultAvatar from './DefaultAvatar';
import friendStatus from '../../data/friends-list/friend-status.json';
import { redirectPage } from '../../shared/utils/helpers';
import './styles.scss';
import {
  LOCATION_ATM,
  LOCATION_DRESSING_ROOM,
  LOCATION_HOMEPAGE,
  LOCATION_ONLINE,
  LOCATION_STORE
} from '../../constants/location';

const FriendsListItem = ({ data }) => {
  const dropdownToggleRef = useRef();
  const isInShow = ![friendStatus.offline, friendStatus.online].includes(
    data?.activeShowId
  );

  const handleJoinShow = (e) =>
    isInShow && redirectPage(e, `jointhecrowd?id=${data?.gameSessionId}`);
  const [location, setLocation] = useState();
  const [actionUser, setActionUser] = useState();

  const [serverDate, setServerDate] = useState(new Date(data?.lastActiveTime));
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = now.getTime() - serverDate.getTime();
      setElapsedTime(difference);
    }, 1000);

    return () => clearInterval(interval);
  }, [serverDate]);

  const formatElapsedTime = (ms) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days === 0 && hours === 0) {
      return `${minutes % 60}m`;
    } else if (days === 0) {
      return `${hours % 24}h`;
    } else {
      return `${days}d`;
    }
  };

  const getLocationFriend = () => {
    switch (data?.activeShowId) {
      case -1:
        return (
          setLocation(LOCATION_HOMEPAGE),
          setActionUser(LOCATION_ONLINE)
        )
      case -2:
        return (
          setLocation(LOCATION_DRESSING_ROOM),
          setActionUser(LOCATION_ONLINE)
        )
      case -3:
        return (
          setLocation(LOCATION_STORE),
          setActionUser(LOCATION_ONLINE)
        )
      case -4:
        return (
          setLocation(LOCATION_ATM),
          setActionUser(LOCATION_ONLINE)
        )
      case -5:
        return (
          setLocation(LOCATION_ONLINE),
          setActionUser(LOCATION_ONLINE)
        )
      default:
        return (
          setLocation('offline'),
          setActionUser('offline')
        )
    }
  }

  useEffect(()=>{
    if (data?.activeShowId > 0) {
        setActionUser(LOCATION_ONLINE)
    } else {
      getLocationFriend()
    }

  }, [data?.activeShowId])

  return (
    <Container>
      <Row
        className={`wrapper-friends-list-item ${data?.activeShowId > 0 ? 'cursor-pointer' : ''}`}
      >
        <Col xs={3} sm={2}>

          <div style={{ width: '60px', height: '60px', position: 'relative' }}>
            {data?.activeShowId === '0' ? <div style={{
              width: '16px',
              height: '16px',
              backgroundColor: '#2EDF57',
              borderRadius: '50px',
              position: 'absolute',
              top: '40px',
              right: '0'
            }}></div> : ''}

            {data?.avatarUrl ? (
              <Image src={data.avatarUrl} roundedCircle />
            ) : (
              <DefaultAvatar name={data?.displayName} />
            )}
          </div>

        </Col>
        <Col xs={9} sm={6} className="about-friend">
          <p className="title">{data?.displayName}</p>
          {
            data?.activeShowId > 0 ?
              (
                <button  className={`location show ${data?.activeShowId === 0 ? 'offline' : ''}`}
                         onClick={handleJoinShow}>{data?.activeShowName !== null ? data?.activeShowName : 'Show name'}</button >
              ) : (
                <p  className={`location ${data?.activeShowId === 0 ? 'offline' : ''}`}>{location}</p >
              )
          }

        </Col>


        <Col xs={4} sm={4} className='actions'>
          <label className={`information ${data?.activeShowId === 0 ? 'offline' : ''}`}>{actionUser}</label>
          <span className={`lastActiveTime ${data?.activeShowId === 0 && data?.lastActiveTime !== null? 'show' : ''}`}>{data?.lastActiveTime !== null ? `${formatElapsedTime(elapsedTime)} ago` : ''}</span>
        </Col>
      </Row>
    </Container>
  );
};
export default FriendsListItem;
