import { call, put, all, takeLatest } from "redux-saga/effects";
import {
  homeSliderRequest,
  homeSliderSuccess,
  homeSliderError,
  recentlyLiveRequest,
  recentlyLiveSuccess,
  recentlyLiveError,
  popularShowRequest,
  popularShowSuccess,
  popularShowError,
  scheduledPerformancesSuccess,
  scheduledPerformancesError,
  scheduledPerformancesRequest
} from '../slices/searchGameSession';
import dataRecently from "../data/carousel-slider/recently-live-first-section.json";
import { dataSlider } from "../shared/defaultValue";
import dataPopular from "../data/popular-show/popular-show.json";

import { toastConfig } from "../shared/defaultValue";
import searchGameService from "../services/SearchGame";
import searchScheduleService from "../services/SearchSchedule";

import toast from "react-hot-toast";
function* homeSlider({ payload }) {
  try {
    const result = yield call(searchGameService.searchGameRandom, payload);
    if (result.success) {
      if (result.data.length === 0) {
        yield put(
          homeSliderSuccess({
            data: dataSlider,
            isFake: true
          })
        );
      } else {
        yield put(homeSliderSuccess(result));
      }
    } else {
      toast.error(result.message, toastConfig);
      yield put(homeSliderError(result.message));
    }
  } catch (error) {
    yield put(homeSliderError(error.message));
  }
}
function* recentlyLive({ payload }) {
  try {
    const result = yield call(searchGameService.searchGameSession, payload);
    if (result.success) {
      if (result.data.length === 0) {
        yield put(
          recentlyLiveSuccess({
            data: dataRecently,
            pageIndex: 1,
            pageSize: 15,
            success: true,
            totalCount: 0,
            isFake: true
          })
        );
      } else {
        yield put(recentlyLiveSuccess(result));
      }
    } else {
      toast.error(result.message, toastConfig);
      yield put(recentlyLiveError(result.message));
    }
  } catch (error) {
    yield put(recentlyLiveError(error.message));
  }
}

function* scheduledPerformances({ payload }) {
  try {
    const result = yield call(searchScheduleService.getScheduledPerformances, payload);
    if (result.success) {
      if (result.data.length === 0) {
        yield put(
          scheduledPerformancesSuccess({
            data: dataRecently,
            pageIndex: 1,
            pageSize: 15,
            success: true,
            totalCount: 0,
            isFake: true
          })
        );
      } else {
        yield put(scheduledPerformancesSuccess(result));
      }
    } else {
      toast.error(result.message, toastConfig);
      yield put(scheduledPerformancesError(result.message));
    }
  } catch (error) {
    yield put(scheduledPerformancesError(error.message));
  }
}



function* popularShow({ payload }) {
  try {
    const result = yield call(searchGameService.searchGameSession, payload);
    if (result.success) {
      if (result.data.length === 0) {
        yield put(
          recentlyLiveSuccess({
            data: dataPopular,
            pageIndex: 1,
            pageSize: 15,
            success: true,
            totalCount: 0,
            isFake: true
          })
        );
      } else {
        yield put(popularShowSuccess(result));
      }
    } else {
      toast.error(result.message, toastConfig);
      yield put(popularShowError(result.message));
    }
  } catch (error) {
    yield put(popularShowError(error.message));
  }
}

export function* searchGameSaga() {
  yield all([
    takeLatest(homeSliderRequest.type, homeSlider),
    takeLatest(recentlyLiveRequest.type, recentlyLive),
    takeLatest(popularShowRequest.type, popularShow),
    takeLatest(scheduledPerformancesRequest.type, scheduledPerformances)
  ]);
}
