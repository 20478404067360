import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Input from '../input';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { submissionContactRequest } from '../../slices/user';
import Button from '../../components/button';

const ContactFormOne = () => {
  const dispatch = useDispatch();
  const contactShema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    message: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required')
  });
  const formik = useFormik({
    initialValues: { name: '', message: '', email: '' },
    validationSchema: contactShema,
    onSubmit: (values) => {
      const newForm = {
        type: 'contact',
        // name: values?.name,
        // email: values?.email,
        // message: values?.message,
        formData: {
          name: values?.name,
          email: values?.email,
          message: values?.message
        }
      };
      dispatch(submissionContactRequest(newForm));
    }
  });
  return (
    <div className="contact-form">
      <form>
        <Row>
          <Col xs={12}>
            <Input
              id={'name'}
              name={'name'}
              type={'text'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'Name*'}
            />
            {formik.touched['name'] && formik.errors['name'] ? (
              <div className="error">{formik.errors['name']}</div>
            ) : null}
          </Col>

          <Col xs={12} className="mt--30">
            <Input
              id={'email'}
              name={'email'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type={'text'}
              placeholder={'Email*'}
            />
            {formik.touched['email'] && formik.errors['email'] ? (
              <div className="error">{formik.errors['email']}</div>
            ) : null}
          </Col>

          <Col xs={12} className="mt--30">
            <Input
              id="message"
              name="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type={'textarea'}
              placeholder={'Your Message*'}
            />
            {formik.touched['message'] && formik.errors['message'] ? (
              <div className="error">{formik.errors['message']}</div>
            ) : null}
          </Col>

          <Col xs={12} className="mt--30 text-center">
            <Button
              btnText={'Send Message'}
              type="button"
              btnStyle={'rounded'}
              onClick={formik.handleSubmit}
              size={'sd'}
              color={'theme'}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ContactFormOne;
