import React from "react";

const Input = ({
  type,
  name,
  id,
  placeholder,
  required,
  value,
  className,
  onChange,
  onBlur
}) => {
  return type === "textarea" ? (
    <textarea
      id={id}
      name={name}
      onBlur={onBlur}
      value={value && value}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      className={className}
    />
  ) : (
    <input
      type={type}
      id={id}
      name={name}
      placeholder={placeholder}
      value={value && value}
      required={required}
      className={className}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default Input;
