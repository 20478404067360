import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  homeSlider: {},
  recentlyLive: {},
  popularShow: {},
  scheduledPerformances: {},
  error: "",
  loading: false,
  success: false
};

const searchGameSession = createSlice({
  name: "searchGame",
  initialState,
  reducers: {
    homeSliderRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },

    homeSliderSuccess: (state, { payload }) => {
      return {
        ...state,
        homeSlider: payload,
        loading: false,
        success: true
      };
    },
    homeSliderError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    recentlyLiveRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    recentlyLiveSuccess: (state, { payload }) => {
      return {
        ...state,
        recentlyLive: payload,
        loading: false,
        success: true
      };
    },
    recentlyLiveError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    scheduledPerformancesRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    scheduledPerformancesSuccess: (state, { payload }) => {
      return {
        ...state,
        scheduledPerformances: payload,
        loading: false,
        success: true
      };
    },
    scheduledPerformancesError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },


    popularShowRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    popularShowSuccess: (state, { payload }) => {
      return {
        ...state,
        popularShow: payload,
        loading: false,
        success: true
      };
    },
    popularShowError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },




    resetState: () => {
      return { ...initialState };
    }
  }
});

export const {
  homeSliderRequest,
  homeSliderSuccess,
  homeSliderError,
  recentlyLiveSuccess,
  recentlyLiveError,
  recentlyLiveRequest,
  popularShowRequest,
  popularShowSuccess,
  popularShowError,
  scheduledPerformancesRequest,
  scheduledPerformancesSuccess,
  scheduledPerformancesError,
  resetState
} = searchGameSession.actions;

export default searchGameSession.reducer;
