import React from 'react';
import defaultuser from '../../assets/img/edit-profile/defaultuser.png';
import './styles.scss';

const AvatarSimple = ({ getUser }) => {
  return (
    <div aria-label='Change Profile Picture' className=''>
      <div className='photo'>
        <img className='personPhoto' src={getUser?.avatarUrl || defaultuser} />
      </div>
      {/*<div*/}
      {/*  className='profile-img'*/}
      {/*  style={{*/}
      {/*    backgroundImage: `url(${getUser?.avatarUrl || defaultuser})`*/}
      {/*  }}*/}
      {/*>*/}
      {/*</div>*/}
    </div>
  );
};
export default AvatarSimple;
