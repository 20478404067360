import React, { useEffect, useMemo, useState } from 'react';
import './styles.scss';
import sprite from '../../assets/svg/sprite.svg';
import prop from '../../assets/img/prop.png';
import { useSelector, useDispatch } from 'react-redux';
import SelectInput from '../../container/select-input';
import RangeInput from '../../container/range-input';
import { getProfileUser } from '../../selectors/user.selector';
import AvatarUploadAccountSettings from '../avatar-upload-account-settings';
import userAPI from '../../services/UserAPI';
import { dataRemoteShedule } from '../../selectors/user.selector';
import getRemotePerScheduleRequest from '../../slices/user';
import {
  getProfileRequest,
  getRemotePerConfRequest,
  getRemoteRequestConfig
} from '../../slices/user';
import ImageUploadStreamContent from '../image-upload-stream-content';
import { Spinner } from 'react-bootstrap';
import UserAPI from '../../services/UserAPI';

const CustomizationStreamContent = () => {
  const restApiCustom = useSelector((state) => state?.user?.dataRemotePerApi);

  const isSuccess = useSelector((state) => state?.user?.success);

  const dispatch = useDispatch();
  const [changeTitle, setChangeTitle] = useState('');
  const [isEnvironment, setIsEnvironment] = useState('');
  const [isGenre, setIsGenre] = useState('');
  const [isStage, setIsStage] = useState('');
  const [isVideoLoops, setIsVideoLoops] = useState(0);
  const [isVideoLoopsArray, setIsVideoLoopsArray] = useState(undefined);
  const [isMinMaxRange, setIsMinMaxRange] = useState({});
  const [valueDescription, setValueDescription] = useState('');
  const [valueImage, setValueImage] = useState('');
  const [imageStream, setImageStream] = useState('');
  const [imageStreamBanner, setImageStreamBanner] = useState('');
  const [valueImageCustStream, setValueImageCustStream] = useState('');
  const [loadImage, setLoadImage] = useState(false);
  const profileUser = useSelector(getProfileUser);
  const [stageNames, setStageName] = useState([]);
  const getImage = (value) => {
    setValueImage(value);
  };
  const getImageImage = (value) => {
    setValueImageCustStream(value);
  };

  const clickSaveData = () => {
    try {
      let y = 0;
      while (isVideoLoopsArray.indexOf('') !== -1) {
        let k = isVideoLoopsArray.indexOf('');
        isVideoLoopsArray.splice(k, 1);
        y++;
      }

      setIsVideoLoops(isVideoLoops - y);

      let u = new Promise(async (resolve, reject) => {
        if (valueImageCustStream !== '' || valueImage !== '') {
          setLoadImage(true);
          // setLoading(true);
          const formDataValueImage = new FormData();
          const formDataBanner = new FormData();
          formDataValueImage.append('img_stream', valueImageCustStream);

          formDataBanner.append('img_stream_banner', valueImage);
          const res =
            valueImageCustStream !== ''
              ? await userAPI.uploadThumbnail(formDataValueImage)
              : '';
          const resBanner =
            valueImage !== ''
              ? await userAPI.uploadThumbnailBanner(formDataBanner)
              : '';

          res !== '' && setImageStreamBanner(res.data.fileName);
          resBanner !== '' && setImageStreamBanner(resBanner.data.fileName);
          if (res || resBanner) setLoadImage(false);
          dispatch(getRemotePerConfRequest());
          resolve({
            streamInfoLogo: `${process.env.REACT_APP_IMAGES_URL_FRISON_IMAGE}${res.data.fileName}`,
            stageBanner: `${process.env.REACT_APP_IMAGES_URL_FRISON_IMAGE}${resBanner.data.fileName}`
          });
        }
        resolve({
          stageBanner: customStatus?.streamBannerURL,
          streamInfoLogo: customStatus?.streamThumbnailURL
        });
      });
      u.then((data) => {
        dispatch(
          getRemoteRequestConfig({
            environment: isEnvironment
              ? isEnvironment
              : customStatus?.environment,
            stageSaveName: isStage,
            stagePX: customStatus?.stagePX === '' ? 1 : customStatus?.stagePX,
            stagePY: customStatus?.stagePY === '' ? 1 : customStatus?.stagePY,
            stagePZ: customStatus?.stagePZ === '' ? 1 : customStatus?.stagePZ,
            inboundStreamLink: '',
            maxEnergyLevel: isMinMaxRange?.maxValue
              ? isMinMaxRange?.maxValue
              : 1,
            minEnergyLevel: isMinMaxRange?.minValue
              ? isMinMaxRange?.minValue
              : 8,
            videoScreenConfig: {
              useOverlays: true,
              useDefaultOverlays: true,
              videoOverlayUrls: [...isVideoLoopsArray]

              // videoOverlayNames: ['ddfd', 'hghjhhj']
            },

            vdj: true,
            isLive: true,
            genre: isGenre,
            announcement: '',
            superAdminsIds: [...customStatus?.superAdminsIds],
            streamTitle: changeTitle,
            streamDescription: valueDescription,
            streamThumbnailURL: data?.streamInfoLogo,
            streamBannerURL: data?.stageBanner,
            //stageBannerURL: data?.stageBanner,
            schedulePerformanceId: '',
            targetArtist: '',
            reconnectToActivePerformance: true,
            targetStreamingPlatform: '',
            streamPlatformAccessToken: '',
            twitchProfileName: '',
            youtubeProfileName: '',
            twitchUserID: '',
            youtubeUserID: '',
            twitchRefreshToken: '',
            youtubeRefreshToken: ''
          })
        );
      });
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const getSelectInput = (val, env) => {
    switch (env) {
      case 'environment':
        setIsEnvironment(val);
        break;
      case 'stage':
        setIsStage(val);
        break;
      case 'genre':
        setIsGenre(val);
        break;
      default:
        return false;
    }
  };
  // const setImageButton = async () => {
  //   setLoadImage(true);
  //   // setLoading(true);
  //   const formData = new FormData();
  //   formData.append('img_stream', valueImageCustStream);
  //   const res = await userAPI.uploadAvatar(formData);
  //
  //   setImageStream(res.data.fileName)
  //
  //
  //   if (res) setLoadImage(false);
  //   dispatch(getProfileRequest());
  // };
  const minMaxValueRange = (min, max) => {
    setIsMinMaxRange({ minValue: min, maxValue: max });
  };

  const getUser = useMemo(() => {
    return profileUser || JSON.parse(localStorage.getItem('profile_user'));
  }, [profileUser]);
  const [customStatus, setCustomStatus] = useState(undefined);
  const loading = useSelector((state) => state.user.loading);
  const arrayEnvironment = () => {
    let i = [];
    if (customStatus) {
      i.push(customStatus?.environment);
    }
    return i;
  };

  useEffect(() => {
    setStageName(restApiCustom?.stageNames);
    setCustomStatus(restApiCustom?.config);

    // UserAPI.getRemotePerformanceDataApi().then((res) => {
    //   setStageName(res?.data?.stageNames);
    //   setCustomStatus(res?.data?.config);
    // }).catch((err) => {
    //   throw err;
    // });
  }, []);

  const arrayGenre = [
    'Country',
    'Rap',
    'Hip Hop',
    'Electronic / Dance',
    'Classical',
    'Rock',
    'Indie',
    'Pop'
  ];

  useEffect(() => {
    customStatus !== undefined &&
      customStatus !== '' &&
      setValueDescription(customStatus?.streamDescription);
    setChangeTitle(customStatus?.streamTitle);
    setIsMinMaxRange({
      minValue: customStatus?.minEnergyLevel,
      maxValue: customStatus?.maxEnergyLevel
    });
    setIsVideoLoops(customStatus?.videoScreenConfig?.videoOverlayUrls?.length);
    setIsVideoLoopsArray(customStatus?.videoScreenConfig?.videoOverlayUrls);
  }, [customStatus]);
  const getVideoLoops = () => {
    let content = [];
    for (let i = 0; i < isVideoLoops; i++) {
      content.push(
        <input
          type="text"
          value={isVideoLoopsArray[i]}
          key={i}
          id={i}
          placeholder="Enter Youtube URL"
          onChange={changeValue}
        />
      );
    }
    return content;
  };
  const changeValue = (e) => {
    let g = Number(e.target.id);
    setIsVideoLoopsArray([
      ...isVideoLoopsArray.slice(0, g),
      e.target.value,
      ...isVideoLoopsArray.slice(g + 1)
    ]);
  };
  const changeTitleStreamContent = (val) => {
    setChangeTitle(val);
  };
  return (
    <>
      {customStatus === undefined ? (
        <Spinner
          animation="border"
          variant="primary"
          style={{ margin: '10% 50%' }}
        />
      ) : (
        <div className="customization-stream-grid">
          <div className="customization-stream-grid-left">
            <h4>Stage Content</h4>
            <h5 className="customization-stream-warning1">
              Producer/Stage Logo
              <img
                style={{ width: '20px', height: '20px', marginLeft: '10px' }}
                src={
                  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEQSURBVHgBvZThDYJADIWLcQBGuA1kBDbQDWQDR4ANHEGdQEeACWAD2AA3qD2uJJfzWu8H8SUvRFq/tEd7ABsrkwKImNPjTC7Ihl8P7C7LsglSRbCGPKMsG2tSQDm5x3T13IkI1GBP8oU8hlCtTU0F59WRWB3CDOpntlZYRSpE/u/S+o6ZJTlXjvdFbsm2EhOJrxMBe35xkFnQkB92TLiKRsgr/AoLIaklV97vEmQZHyipJE9cnQEdCD5wUnLuHlzT4AMHJbHj5xESgIvQbUhsbPogfsW4Zj4S8KF1JPHmAU8oz2odrRm/V29Et27aZdGLh4BbXw5B+7+ur2ib2gVrwI2K3aJ18CdwX9Nuzhv+oQ9dPz9qoMM+GQAAAABJRU5ErkJggg=='
                }
              />
            </h5>
            <AvatarUploadAccountSettings
              loadImage={loadImage}
              getUser={customStatus?.streamBannerURL}
              getImage={getImage}
              buttonName={'Change Logo'}
            />
            <div className="video-loops">
              <div className="customization-stream-warning">Video Loops</div>
              <button
                className="button-pick"
                onClick={() => {
                  setIsVideoLoops(isVideoLoops + 1);
                }}
              >
                <svg fill="#fff" style={{ width: '20px', height: '20px' }}>
                  <use href={sprite + '#plus'}></use>
                </svg>
              </button>
            </div>
            {getVideoLoops()}
          </div>
          <div className="customization-stream-grid-center">
            <h4>Performance Content</h4>
            <div className="customization-stream-grid-div">
              <div className="customization-stream-warning">Environment</div>
              <SelectInput
                element={['STAGING', 'WAREHOUSE']}
                isOrigin={customStatus?.environment}
                getSelectInput={getSelectInput}
                meaning={'environment'}
              />
            </div>
            <div>
              <div className="customization-stream-warning">Stage</div>
              <SelectInput
                element={stageNames}
                getSelectInput={getSelectInput}
                isOrigin={customStatus?.stageSaveName}
                meaning={'stage'}
              />
            </div>
            <div>
              <div className="customization-stream-warning">Genre</div>
              <SelectInput
                element={arrayGenre}
                getSelectInput={getSelectInput}
                isOrigin={customStatus?.genre}
                meaning={'genre'}
              />
            </div>
            <div>
              <div
                className="customization-stream-warning"
                style={{ marginBottom: '30px' }}
              >
                Energy
              </div>
              <RangeInput
                max={customStatus?.maxEnergyLevel}
                min={customStatus?.minEnergyLevel}
                minMaxValueRange={minMaxValueRange}
              />
            </div>
          </div>
          <div className="customization-stream-grid-right">
            <h4>Stream Info</h4>
            <ImageUploadStreamContent
              isImage={
                !customStatus?.streamThumbnailURL
                  ? customStatus?.streamThumbnailUrl
                  : customStatus?.streamThumbnailURL
              }
              getImageImage={getImageImage}
              title={changeTitle}
              changeTitleStreamContent={changeTitleStreamContent}
            />
            <div className="stream-text">
              <textarea
                value={valueDescription}
                onChange={(e) => {
                  setValueDescription(e.target.value);
                }}
              />
            </div>
            <button className="button-save" onClick={clickSaveData}>
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default CustomizationStreamContent;
