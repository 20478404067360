import React, { useEffect, useState } from 'react';
import './styles.scss';
import MultiRangeSlider from 'multi-range-slider-react';

const RangeInput = ({ max, min, minMaxValueRange }) => {
  const [objValue, set_objValue] = useState({});
  useEffect(() => {
    set_objValue({ max: max, min: min });
  }, []);
  const handleInput = (e) => {
    minMaxValueRange(e.minValue, e.maxValue);

    set_objValue({ max: e.maxValue, min: e.minValue });
  };
  return (
    <>
      <MultiRangeSlider
        min={1}
        max={8}
        step={1}
        ruler={true}
        label={true}
        preventWheel={false}
        minValue={objValue.min}
        maxValue={objValue.max}
        onInput={(e) => {
          handleInput(e);
        }}
      />
    </>
  );
};
export default RangeInput;
