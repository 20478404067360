// import React, { Fragment } from "react";
// import { Helmet } from "react-helmet";

// // layout
// import { LayoutDefault } from "../../layouts";
// import { Header } from "../../components/header";
// import { Footer } from "../../container/footer";

// // container
// import GenresList from "../../container/genres-list";
// import DropDownMenu from "../../container/dropdown-menu";
// import ListCardLive from "../../container/list-live-now";
// import ListRecommendedShows from "../../container/list-recommended-shows";
// import SuggestedShows from "../../container/suggested-shows";

// // data
// import frisson from "../../data/frisson.json";
// import { typeGenre } from "../../selectors/genres.selector";
// import { useSelector } from "react-redux";

// import "./styles.scss";

// const DressingRoom = () => {
//   const { genre } = useSelector(typeGenre);

//   return (
//     <Fragment>
//       <Helmet>
//         <title>{`${frisson.title}`}</title>
//       </Helmet>
//       <p>Hello World</p>
//     </Fragment>
//   );
// };

// export default DressingRoom;



import React from "react";
// import ReactDOM from "react-dom";
// import { Redirect, Route } from "react-router-dom";
// import './style.css'
import * as THREE from "three"
import gsap from 'gsap'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import jwt_decode from 'jwt-decode' 
import { dragControls,dragAction,mouse, interactables, prepControlObjs, setInitialVal, getVal, getRatio, draggableShelves, removeShelves, dispose, pointersDisabled, togglePointer, tick, toggleCanTick } from './drag'
import User from 'shared/audiophile/models/user'
import { screenSizeListener, screenSizeStates, mediaQueryState, sizes, MQDIsposeEvents } from 'shared/audiophile/mediaQueries'
import Catalog from '../../shared/audiophile/models/catalog'
import {Character, sharedAnims, CharacterClassInit, headSizeProp, neckLengthProp, bodySizeProp, handSizeProp, legLengthProp, feetSizeProp, bodyColorProp} from 'shared/audiophile/character'
import DynamicMenu from "components/dynamic-menu";
import Loading from "components/loading";
import {InitTut, CheckTutorialStep, arrowData} from "shared/audiophile/onboarding"
import { setupUI } from "shared/ui-builder";
import hslToHex from "shared/audiophile/hslToHex";
import { Object3D, Vector3 } from "three";
import DynamicMenuModernized from "components/dynamic-menu-modernized";



class DressingRoom extends React.Component {
    constructor(props) {
        super(props)
    
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.animate = this.animate.bind(this)
      }
    
      componentDidMount() {

        //add styles to body
        document.body.style.height = '100%'
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.documentElement.style.overflow = 'hidden'
        document.documentElement.style.width = '100%'
        //display loading
        document.getElementsByClassName('loading')[0].style.display = 'block'

        const width = this.mount.clientWidth
        const height = this.mount.clientHeight
    
        const scene = new THREE.Scene()
        const camera = new THREE.PerspectiveCamera(
          75,
          width / height,
          0.1,
          1000
        )
        const renderer = new THREE.WebGLRenderer({ antialias: true})
        const canvas = renderer.domElement
        
        renderer.setClearColor('#000000')
        renderer.setSize(width, height)
    
        this.scene = scene
        this.camera = camera
        this.renderer = renderer

        

        function Dispose () {
            MQDIsposeEvents()
            dispose()
        }
        this.eventsDispose = Dispose

        /**
         * Constants
         */

        const titleId = '10B75'

        /**
         * Lights
         */
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
        scene.add(ambientLight)

        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
        directionalLight.castShadow = true
        directionalLight.shadow.mapSize.set(1024, 1024)
        directionalLight.shadow.camera.far = 15
        directionalLight.shadow.camera.left = - 7
        directionalLight.shadow.camera.top = 7
        directionalLight.shadow.camera.right = 7
        directionalLight.shadow.camera.bottom = - 7
        directionalLight.position.set(5, 5, 5)
        scene.add(directionalLight)

        const rimLight = new THREE.PointLight(0xC73AFC, 0.5)
        rimLight.position.set(-1.5, 0, 0.75)
        rimLight.name = "RimLight"
        scene.add(rimLight)

        const spotLight = new THREE.SpotLight( 0xffffff );
        spotLight.position.set( 0, 5.3, 3.8 );

        spotLight.castShadow = true;

        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;

        spotLight.shadow.camera.near = 500;
        spotLight.shadow.camera.far = 4000;
        spotLight.shadow.camera.fov = 20;
        spotLight.penumbra = 1
        spotLight.angle = 0.1
        spotLight.target.position.set(3,10,0.5)
        spotLight.intensity = 0.8
        // scene.add( spotLight );
        // scene.add( spotLight.target )

        //container for camera and character
        const camContainer = new THREE.Object3D
        camContainer.name = "CamContainer"
        camContainer.position.set(1.8, 1.7, 3.2)

        const cylGeo = new THREE.BoxGeometry( 1,3,1 );
        const cylMat = new THREE.MeshBasicMaterial( {color: 0xffff00} );
        // const characterSpinner = new THREE.Mesh( cylGeo, cylMat );
        // characterSpinner.position.set(0.09,1.5,0)
        // characterSpinner.name = "Char Spinner Target"
        // characterSpinner.tag = "spinner"
        // characterSpinner.callbacks = [spinChar, resetCharSpin]
        // characterSpinner.visible = false
        // interactables.push(characterSpinner)
        // scene.add( characterSpinner );

        // const mirrorGeo = new THREE.BoxGeometry( 1,3,1 );
        // const mirrorColor = (0).toFixed(1)
        // var mirrorMaterial = new THREE.ShaderMaterial({
        //     transparent: true,
        //     depthWrite: false,
        //     side: THREE.DoubleSide,
        //     uniforms: {c: {type: "3f"}, o: {type: "3f"}},
        //     vertexShader:   `
        //       varying vec3 p;
        //       void main() {
        //         // transfer vertex position to fragment shader, 
        //         // this value is interpolated by gpu hardware between pixels of triangle, 
        //         // containing this vertex
        //         p = position; 
        //         gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        //       }`,
        //     fragmentShader: `
        //       varying vec3 p;  // position of current pixel relative to sphere center
        //       uniform vec3 c;  // center of current sphere
        //       uniform vec3 o;  // center of opposite sphere
        //       void main() {
        //          vec3 a = abs(p)*50.0;  
        //          float opacity = a.x<1. || a.y<1. || a.z<1. ? 0.8 : 0.3;               
        //          // here is test of shpere overlapping   
        //          opacity = distance(o, p + c) < 3.0 ? 0.0 : opacity; 
        //          gl_FragColor = vec4(vec3(1.0, 0.0, 1.0 - 1.0), opacity);
        //       }`
        // });
        // const characterMirror = new THREE.Mesh( mirrorGeo, mirrorMaterial );
        // characterMirror.position.set(0.09,1.5,0)
        // characterMirror.name = "Char Mirror"
        // scene.add( characterMirror );

        /**
         * Models
         */
        let anims = {}
        const modelPath = 'https://audiophileitems.blob.core.windows.net/items/'
        const dracoLoader = new DRACOLoader()
        const gltfLoader = new GLTFLoader()
        dracoLoader.setDecoderPath('/draco/')

        gltfLoader.setDRACOLoader(dracoLoader)

        const textureLoader = new THREE.TextureLoader( );
        let texture1 = textureLoader.load(modelPath + 'empty.png')
        let texture2 = textureLoader.load(modelPath + 'hoody_base.png')
        let texture3 = textureLoader.load(modelPath + 'blush.png')
        let texture4 = textureLoader.load(modelPath + 'face.png')

        texture1.flipY = false
        texture2.flipY = false
        texture3.flipY = false
        texture4.flipY = false

        //container for onboarding arrow targets
        this.arrowTargets = {}
        this.arrowData = arrowData

        //Load Enviro scene
        let mixerControls

        gltfLoader.load(modelPath + 'customizerMixer.glb', (object) => {
            let obj = object.scene
            obj.name = "Customize Mixer"
            obj.position.set(1.8,1.24,2.47)
            obj.rotation.set(0.67,0,0)
            obj.scale.set(1.075,1,1)

            //find all interactables
            obj.children.forEach(el => {
                //this is kind of janky
                //this also needs to change to switch from catalog to inventory
                // prepControlObjs(el, CategoryStartSpawn)

                //find correct select callback
                if (el.name.includes('select')) {
                    switch (el.name.split('|')[0]) {
                        case "headMorphPlus":
                            prepControlObjs(el, () => {MorphMainChar(true, true)})
                            break;
                        case "headMorphMinus":
                            prepControlObjs(el, () => {MorphMainChar(false, true)})
                            break;
                        case "bodyMorphPlus":
                            prepControlObjs(el, () => {MorphMainChar(true, false)})
                            break;
                        case "bodyMorphMinus":
                            prepControlObjs(el, () => {MorphMainChar(false, false)})
                            break;
                        case "save":
                            prepControlObjs(el, SaveCharacter)
                            break;
                        case "customize":
                            this.arrowTargets['bodyMixer'] = el
                            prepControlObjs(el, () => {customizeRotate(true)})
                            break;
                        case "head_topper":
                            this.arrowTargets['headCategory'] = el
                            prepControlObjs(el, CategoryStartSpawn)
                            break;
                        default:
                            prepControlObjs(el, CategoryStartSpawn)
                            break;
                    }
                    
                }
                else{
                    prepControlObjs(el)
                }
            });

            scene.add(obj)
            mixerControls = obj

            // PrepInitialControlProps()
            gltfLoader.load(modelPath + 'bodyMixer-new.glb', (object) => {
                let obj = object.scene
                obj.name = "Body Mixer"
                obj.position.set(1.11,1.24,3.4)
                obj.rotation.set(0,0,-0.67)
                obj.scale.set(1,1,1)

                //find all interactables
                obj.children.forEach(el => {
                    //this is kind of janky
                    //this also needs to change to switch from catalog to inventory
                    // prepControlObjs(el, CategoryStartSpawn)

                    //find correct select callback
                    if (el.name.includes('select')) {
                        switch (el.name.split('|')[0]) {
                            case "headMorphPlus":
                                prepControlObjs(el, () => {MorphMainChar(true, true)})
                                break;
                            case "headMorphMinus":
                                prepControlObjs(el, () => {MorphMainChar(false, true)})
                                break;
                            case "bodyMorphPlus":
                                prepControlObjs(el, () => {MorphMainChar(true, false)})
                                break;
                            case "bodyMorphMinus":
                                prepControlObjs(el, () => {MorphMainChar(false, false)})
                                break;
                            case "save":
                                prepControlObjs(el, SaveCharacter)
                                this.arrowTargets['bodySave'] = el
                                break;
                            case "customizeReturn":
                                prepControlObjs(el, () => {customizeRotate(false)})
                                this.arrowTargets['customizeReturn'] = el
                                break;
                            default:
                                prepControlObjs(el, CategoryStartSpawn)
                                break;
                        }
                        
                    }
                    else if (el.name == "headSize|rot"){
                        this.arrowTargets['knob'] = el
                        prepControlObjs(el)
                    }
                    else{
                        prepControlObjs(el)
                    }
                });

                scene.add(obj)
                bodyMixerControls = obj

                // PrepInitialControlProps()

                GetUserCharacter(user)

            })

        })

        //body mixer
        let bodyMixerControls
        

        
        let backdrop = gltfLoader.load(modelPath + 'backdrop.glb', (object) => {
            let obj = object.scene
            obj.name = "Backdrop"
            obj.position.set(0,-1.7,0)
            obj.rotation.set(0,1.5,0)
            obj.scale.set(2,4,15)

            // obj.material.color = '#444444'
            // obj.material.metalness = 0
            // obj.material.roughness = 0.5

            obj.children[0].material = new THREE.MeshStandardMaterial({
                color: '#c8c8c8',
                metalness: 0,
                roughness: 0.5
            })
            obj.children[0].receiveShadow = true

            camContainer.add(obj)
        })

        // let head_placeholder
        
        // gltfLoader.load(modelPath + 'head_placeholder.glb', (object) => {
        //     let obj = object.scene
        //     obj.name = "HeadPlaceholder"

        //     // obj.children[0].receiveShadow = true

        //     head_placeholder = obj
        //     // scene.add(obj)
        // })

        // let hands_placeholder = gltfLoader.load(modelPath + 'hands_placeholder.glb', (object) => {
        //     let obj = object.scene
        //     obj.name = "HandsPlaceholder"

        //     // obj.children[0].receiveShadow = true

        //     // scene.add(obj)
        // })

        let shelfContainer = new THREE.Group()
            shelfContainer.name = "ShelfContainer"
            shelfContainer.position.set(1.77,0,0.54)
            shelfContainer.rotation.set(0,-0.39,0)
            shelfContainer.scale.set(1.5,1.5,1.5)

        // spotLight.target = shelfContainer

        // const modelPath = '/models/Character/mixamo/new/'
        

        // let charProps = {
        //     "items" : {
        //         "head" : "head_only",
        //         "head_acc" : "head_acc",
        //         "face" : "",
        //         "face_acc" : "face_acc",
        //         "body" : "body_jazz",
        //         "body_acc" : "body_acc",
        //         "hands" : "hands_only",
        //         "feet" : "feet_only"
        //     },
        //     "customizations" : {
        //         "headSize":1,
        //         "bodySize":1,
        //         "handSize":1,
        //         "feetSize":1,
        //         "neckLength":0.9,
        //         "armLength":1,
        //         "legLength":-0.2
        //     },
        //     "animations" : [
        //         "twist"
        //     ]
        // }

        let defaultCustomizations = {
            "customizations" : {
                "headSize":1,
                "bodySize":1,
                "handSize":1,
                "feetSize":1,
                "neckLength":0.9,
                "armLength":1,
                "legLength":-0.2
            }
        }

        //===========================
        //
        //     User Properties
        // 
        //===========================



        function GetUser () {
            let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
            if (appState?.auth.isAuthenticated) {
                return new User(jwt_decode(appState.auth.currentUser))
            }
            else {
                console.log("Cannot get user, we should return to login")
                window.location.href = '/'
                return null
            }
        }

        const user = GetUser()
        // GetUserCharacter(user)


        let charProps2 = {
            "items" : {
                "head" : "head_only",
                "head_acc" : "head_acc",
                "face_acc" : "face_acc",
                "body" : "body_only",
                "body_acc" : "body_jazz",
                "hands" : "hands_only",
                "feet" : "feet_only"
            },
            "customizations" : {
                "headSize":0.8,"bodySize":1,"handSize":1,"feetSize":1,"neckLength":1,"armLength":1,"legLength":0.1
            },
            "animations" : [
                "twist",
                // "slide",
                // "robot"
            ],
            "loadedParts" : 0
        }

        //===========================
        //
        //     Character Functions
        // 
        //===========================

        let catalogVersion = "Audiophile Items"

        //for saving current character configuration
        let currentCharProps

        //Character property ranges
        // const headSizeProp = {
        //     "min" : 0.9,
        //     "max" : 1.1
        // }
        // const neckLengthProp = {
        //     "min" : 0.8,
        //     "max" : 1
        // }
        // const bodySizeProp = {
        //     "min" : 0.9,
        //     "max" : 1.1
        // }
        // const handSizeProp = {
        //     "min" : 0.9,
        //     "max" : 1.2
        // }
        // const legLengthProp = {
        //     "min" : -0.2,
        //     "max" : 0.5
        // }

        // const feetSizeProp = {
        //     "min" : 0.6,
        //     "max" : 1
        // }

        // const bodyColorProp = {
        //     "hMin" : 0,
        //     "hMax" : 360,
        //     "sMin" : 0,
        //     "sMax" : 100,
        //     "vMin" : 0,
        //     "vMax" : 100,
        // }

        const characters = {};
        this.characters = characters

        CharacterClassInit(OneShotManager)
        function OneShotManager (userid, _listener) {
            characters[user.mainCharacter].startAnimation()
            characters[user.mainCharacter].mixer.removeEventListener('finished', _listener, true);
        }

        

        function MorphMainChar (increase, head) {
            characters[user.mainCharacter]?.changeMorph(increase, head)
            // if (increase) {
            //     if (head) {
            //         characters[user.mainCharacter]?.changeMorph(true, true)
            //     }
            //     else {
            //         characters[user.mainCharacter]?.changeMorph(true, true)
            //     }
                
            // }
            // else {
            //     characters[user.mainCharacter]?.changeMorph(false)
            // }
        }

        //find and return all objects of type in a scene
        // function traverseScene (scene, type) {
        //     let objs

        //     for (let i = 0; i < array.length; i++) {
        //         const element = array[i];
                
        //         if (element.type === type) {
        //             obj.push(element)
        //         }

        //     }
        // }

        function getChildren (obj) {
            
        }

        //===========================
        //
        //     External Functions
        // 
        //===========================

        /*    Default Assigns    */
        // const catalog = {
        //     "head_acc": [],
        //     "face_acc": [],
        //     "clothes": [],
        //     "body_acc": [],
        //     "hands_acc": [],
        //     "feet": []
        // }

        const catalog = new Catalog()

        // function FireCloudscript(cs, args) {
        //     fetch('https://' + titleId + '.playfabapi.com/Client/ExecuteCloudScript', {
        //         method: "POST",
        //         headers: {
        //             "Accept" : "text/plain",
        //             "X-Authorization" : sessionTicket,
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({

        //             "FunctionName" : cs,
        //             "FunctionParameter" : args


        //         })
        //     })
        //     .then(response => response.json())
        //     .then(data => {

        //         console.log('updated user info');

        //     });
        // }

        function GetUserCharacter () {
            //get user main character
            fetch('https://' + titleId + '.playfabapi.com/Client/GetUserReadOnlyData', {
                method: 'POST',
                headers: {
                    'Accept' : 'text/plain',
                    'Content-Type': 'application/json',
                    'X-Authorization' : user.playFabSessionToken
                },
                body:  JSON.stringify({
                    "Keys" : ["mainCharacter"]
                })
            })
            .then(function(response){
                if (response.status >= 400 && response.status < 600) {
                    response.json().then(function(object) {
                        console.log("Cannot get user, we should return to login")
                        window.location.href = '/'
                    })
                } else if (response.status === 200) {
                    return response.json()
                }
                
            }).then(data => {
                user.mainCharacter = data.data.Data.mainCharacter.Value

                //we have to get all the user's characters first
                fetch('https://' + titleId + '.playfabapi.com/Client/GetAllUsersCharacters', {
                    method: 'POST',
                    headers: {
                        'Accept' : 'text/plain',
                        'Content-Type': 'application/json',
                        'X-Authorization' : user.playFabSessionToken
                    },
                    body:  JSON.stringify({
                        "PlayFabId" : user.playFabId
                    })
                })
                .then(function(response){
                    if (response.status >= 400 && response.status < 600) {
                        response.json().then(function(object) {
                            console.log("Cannot get user, we should return to login")
                            window.location.href = '/'
                        })
                    } else if (response.status === 200) {
                        return response.json()
                    }
                    
                })
                .then(data => {
                    let userChars = []
                    if (Object.keys(data.data.Characters).length != 0) {

                        for (let i = 0; i < data.data.Characters.length; i++) {
                            const char = data.data.Characters[i];

                            if (char.CharacterType === 'audiophile-1') {
                                userChars.push(char)
                            }
                            
                        }

                        if (userChars.length) {
                            user.characters = userChars

                            //get the data now that we have the character id
                            fetch('https://' + titleId + '.playfabapi.com/Client/GetCharacterReadOnlyData', {
                                method: 'POST',
                                headers: {
                                    'Accept' : 'text/plain',
                                    'Content-Type': 'application/json',
                                    'X-Authorization' : user.playFabSessionToken
                                },
                                body:  JSON.stringify({
                                    "PlayFabId" : user.playFabId,
                                    "CharacterId": user.mainCharacter,
                                    "Keys" : ["items", "customizations", "animations"]
                                })
                            })
                            .then(function(response){
                                if (response.status >= 400 && response.status < 600) {
                                    response.json().then(function(object) {
                                        console.log("Cannot get user, we should return to login")
                            window.location.href = '/'
                                    })
                                } else if (response.status === 200) {
                                    return response.json()
                                }
                                
                            })
                            .then(data => {

                                if (Object.keys(data.data.Data).length != 0) {

                                    let props = data.data.Data;
                                    
                                    console.log("successful data fetch")
                                    
                                    //Init Drag Controls
                                    dragControls(canvas,camera,readControls)
                                    PrepInitialControlProps()
                                    
                                    let charProps = {
                                        "items" : JSON.parse(props.items.Value),
                                        "customizations" : JSON.parse(props.customizations.Value),
                                        "animations" : ["idle"],
                                        // "animations" : JSON.parse(props.animations.Value)
                                    }
                                    let newChar = new Character(charProps)
                                    characters[user.mainCharacter] = newChar
                                    newChar.spawn(user.playFabId, user.mainCharacter, 0, 0, scene, CharacterLoaded)

                                    console.log(interactables)
                                    
                                    

                                    //get existing inventory
                                    GetOwnedItems()
                                    setupUI(scene, camera, renderer, canvas)
                                    InitTut('dressing-room', user, [customizeRotate],mixerControls, bodyMixerControls)

                                    
                                }
                                else {
                                    console.log("no character item data, drop in the defaults")
                                }

                                //gui for changes
                                // gui.add(characters[user.mainCharacter].customizations.body.bodyColor, "h", 0, 360, 1).onChange(() => {
                                //     characters[user.mainCharacter].applyAdjustments()
                                // })
                                // gui.add(characters[user.mainCharacter].customizations.body.bodyColor, "s", 0, 100, 1).onChange(() => {
                                //     characters[user.mainCharacter].applyAdjustments()
                                // })
                                // gui.add(characters[user.mainCharacter].customizations.body.bodyColor, "v", 0, 100, 1).onChange(() => {
                                //     characters[user.mainCharacter].applyAdjustments()
                                // })
                                // gui.add(characters[user.mainCharacter].customizations.head, "neckLength", neckLengthProp.min, neckLengthProp.max, 0.001).onChange(() => {
                                //     characters[user.mainCharacter].applyAdjustments()
                                // })
                                // gui.add(characters[user.mainCharacter].customizations.body, "bodySize", bodySizeProp.min, bodySizeProp.max, 0.001).onChange(() => {
                                //     characters[user.mainCharacter].applyAdjustments()
                                // })
                                // // gui.add(charProps.customizations, "armLength", 0.9, 1.1, 0.001)
                                // gui.add(characters[user.mainCharacter].customizations.hands, "handSize", handSizeProp.min, handSizeProp.max, 0.001).onChange(() => {
                                //     characters[user.mainCharacter].applyAdjustments()
                                // })
                                // gui.add(characters[user.mainCharacter].customizations.feet, "legLength", legLengthProp.min, legLengthProp.max, 0.001).onChange(() => {
                                //     characters[user.mainCharacter].applyAdjustments()
                                // })
                                // gui.add(characters[user.mainCharacter].customizations.feet, "feetSize", feetSizeProp.min, feetSizeProp.max, 0.001).onChange(() => {
                                //     characters[user.mainCharacter].applyAdjustments()
                                // })
                                // gui.addColor(characters[user.mainCharacter].customizations.body, "bodyColor").name("Body Color").onChange(() => {
                                //     characters[user.mainCharacter].applyAdjustments()
                                // })

                                //screen resizer + change callback
                                screenSizeListener(camera, renderer, mediaQueryChange)
                                
                            }).catch(function (err) {

                            //no existing session with this id
                                return console.log(err);
                            });
                        }
                        else {
                            return console.log("User has no character added to account")
                        }

                        

                    }
                    else {
                        console.log("User has no characters somehow...")
                    }
                    
                }).catch(function (err) {

                //no existing session with this id
                    return console.log(err);
                });
            })
        }

        function GetMainCharacter () {
            return user.characters?.[0].CharacterId
        }

        function CharacterLoaded () {
            for (let i = 0; i < characters[user.mainCharacter].skinnedMeshes.length; i++) {
                const element = characters[user.mainCharacter].skinnedMeshes[i];
                console.log(element)
                interactables.push(element)
            }

            togglePointer(true)
            //hide loading
            document.getElementsByClassName('loading')[0].style.display = 'none'
        }

        function PrepInitialControlProps (charProps) {

            if (characters[user.mainCharacter]) {
                console.log("Char exists")
                charProps = characters[user.mainCharacter].customizations
            }
            else {
                console.log("No Char exists, wait and try again")
                setTimeout(() => {
                    PrepInitialControlProps()
                }, 500);

                return null
            }

            let props = {}

            Object.values(charProps).forEach(part => {

                Object.keys(part).forEach(val => {
                    console.log(val)
                    switch (val) {
                        case "headSize":
                            props[val] = getRatio(part[val], headSizeProp.min, headSizeProp.max)
                            break;
                        case "neckLength":
                            props[val] = getRatio(part[val], neckLengthProp.min, neckLengthProp.max)
                            break;
                        case "bodySize":
                            props[val] = getRatio(part[val], bodySizeProp.min, bodySizeProp.max)
                            break;
                        case "bodyColor":
                            props["hue"] = getRatio(part[val].h, bodyColorProp.hMin, bodyColorProp.hMax)
                            props["sat"] = getRatio(part[val].s, bodyColorProp.sMin, bodyColorProp.sMax)
                            props["value"] = getRatio(part[val].v, bodyColorProp.vMin, bodyColorProp.vMax)
                            break;
                        case "handSize":
                            props[val] = getRatio(part[val], handSizeProp.min, handSizeProp.max)
                            break;
                        case "legLength":
                            props[val] = getRatio(part[val], legLengthProp.min, legLengthProp.max)
                            break;
                        case "feetSize":
                            props[val] = getRatio(part[val], feetSizeProp.min, feetSizeProp.max)
                            break;
                        default:
                            break;
                    }
                    
                })
                
            })

            setInitialVal(props)
        }

        function GetOwnedItems () {
            fetch('https://' + titleId + '.playfabapi.com/Client/GetUserInventory', {
                method: 'POST',
                headers: {
                    'Accept' : 'text/plain',
                    'Content-Type': 'application/json',
                    'X-Authorization' : user.playFabSessionToken
                },
                // body:  JSON.stringify({
                //     "CharacterId": user.mainCharacter
                // })
            })
            .then(function(response){
                if (response.status >= 400 && response.status < 600) {
                    response.json().then(function(object) {
                        alert(object.errorMessage);
                    })
                } else if (response.status === 200) {
                    return response.json()
                }
                
            })
            .then(data => {

                if (Object.keys(data.data.Inventory).length != 0) {

                    let inventory = [];
                    let items = data.data.Inventory

                    for (let i = 0; i < items.length; i++) {

                        //check if it's an item, it will have it's id delineated with a bar
                        if (items[i].ItemId.includes('|')) {
                            const item = parseCatalogItem(items[i])
                        
                            if (item.type !== 'head') {
                                inventory.push(item)
                            }
                        }
                        
                    }

                    user.inventory = new Catalog()

                    inventory.forEach(item => {
                        // let name = {}
                        // name[item.name] = () => {characters[user.mainCharacter].replacePart(item.type, item.id)}
                        // gui.add(name, item.name)

                        user.inventory[item.type].push(item)

                    });

                    // Object.keys(user.inventory).forEach(element => {
                    //     let el = document.createElement('a')
                    //     // let price = element.price == 0 ? "Free" : element.price + 'DB';
                    //     let textnode = document.createTextNode(element + ' - ' + user.inventory[element].length)
                        
                    //     el.appendChild(textnode)
                    //     // el.appendChild(document.createTextNode('\u2718'));
                    //     el.onclick = function() { 
                    //         CategoryStartSpawn(element, user.inventory) 
                    //     }

                    //     document.getElementById('inventoryContainer').appendChild(el)
                    //     let linebreak = document.createElement("br");
                    //     document.getElementById('inventoryContainer').appendChild(linebreak)
                    // });

                    let save = {}
                    save["Save Character!"] = () => { SaveCharacter() }
                    // gui.add(save, "Save Character!")

                    itemPool = user.inventory

                    //now that we have the user's items, get catalog and filter out owned items
                    GetCatalogItems()

                }
                else {
                    console.log("no character item data, drop in the defaults")
                }
                
            }).catch(function (err) {

            //no existing session with this id
                return console.log(err);
            });
        }

        function GetCatalogItems () {
            fetch('https://' + titleId + '.playfabapi.com/Client/GetCatalogItems', {
                method: 'POST',
                headers: {
                    'Accept' : 'text/plain',
                    'Content-Type': 'application/json',
                    'X-Authorization' : user.playFabSessionToken
                },
                body:  JSON.stringify({
                    "CatalogVersion": catalogVersion
                })
            })
            .then(function(response){
                if (response.status >= 400 && response.status < 600) {
                    response.json().then(function(object) {
                        alert(object.errorMessage);
                    })
                } else if (response.status === 200) {
                    return response.json()
                }
                
            })
            .then(data => {

                if (Object.keys(data.data.Catalog).length != 0) {

                    // let catalog = [];
                    let items = data.data.Catalog

                    for (let i = 0; i < items.length; i++) {

                        


                        //check if it's an item, it will have it's id delineated with a bar
                        if (items[i].ItemId.includes('|')) {
                            const item = parseCatalogItem(items[i])

                            if (item.type !== 'head' && item.type !== 'body' && item.type !== 'hands') {
                                
                                //make sure item isn't already in user's inventory
                                let owned = false
                                for (let j = 0; j < user.inventory[item.type].length; j++) {
                                    const element = user.inventory[item.type][j];
                                    
                                    if (element.id === item.id) {
                                        owned = true
                                    }
                                }
                                
                                if (!owned) {
                                catalog[item.type].push(item)  
                                }
                                
                            }
        
                        }
                        
                    }

                    // Object.keys(catalog).forEach(element => {
                    //     let el = document.createElement('a')
                    //     // let price = element.price == 0 ? "Free" : element.price + 'DB';
                    //     let textnode = document.createTextNode(element + ' - ' + catalog[element].length)
                        
                    //     el.appendChild(textnode)
                    //     // el.appendChild(document.createTextNode('\u2718'));
                    //     el.onclick = function() { 
                    //         CategoryStartSpawn(element, catalog) 
                    //     }

                    //     document.getElementById('catalogContainer').appendChild(el)
                    //     let linebreak = document.createElement("br");
                    //     document.getElementById('catalogContainer').appendChild(linebreak)
                    // });

                    //hook up temp cart buy button
                    // document.getElementById('buyButton').onclick = function () { PurchaseItems() }
                    
                    //itemPool = catalog

                }
                else {
                    console.log("no character item data, drop in the defaults")
                }
                
            }).catch(function (err) {

            //no existing session with this id
                return console.log(err);
            });
        }

        //when spawning categories:
            //determine shelf type by category type
            //spawn in the appropriate number of shelves based on type and number of items in category
            //keep track of shelf bones based on number of bones in particular shelf


        let loadedCategoryParts = 0
        let currentCategory = null
        let currentCatalog = null
        let itemPool = null
        //shelf
        let currentShelf = null
        let currentBone = 0
        let shelves = []
        let shelfType
        let shelfOffset = 2.2
        let boneOffset = 0
        let heightOffset = 0
        let scaleOffset = 0.02
        const categoryContainer = []

        function CategoryStartSpawn (category) {
            //disable pointer while loading
            togglePointer(false)
            //display loading
            document.getElementsByClassName('loading')[0].style.display = 'block'
            
            currentCategory = category
            currentCatalog = itemPool
            loadedCategoryParts = 0

            //shelf
            resetShelves()

            switch (category) {
                case "head_topper":
                case "head_acc":
                case "eyes":
                case "face_acc":
                    shelfType = "shelf-4x3-wBones"
                    boneOffset = 12
                    heightOffset = -2.22
                    scaleOffset = 0.014
                    break
                case "hands_acc":
                    shelfType = "shelf-4x3-wBones"
                    boneOffset = 12
                    heightOffset = -1.7
                    scaleOffset = 0.02
                    break
                case "feet":
                    shelfType = "shelf-4x3-wBones"
                    boneOffset = 12
                    heightOffset = -0.55
                    scaleOffset = 0.011
                    break
                case "clothes":
                    shelfType = "shelf-4x2-wBones"
                    boneOffset = 8
                    heightOffset = -2
                    scaleOffset = 0.02
                    break
                case "body_acc":
                    shelfType = "shelf-4x3-wBones"
                    boneOffset = 8
                    heightOffset = -2
                    scaleOffset = 0.02
                    break
                case "animations":
                    shelfType = "shelf-4x3-wBones"
                    boneOffset = 8
                    heightOffset = -1.5
                    scaleOffset = 0.4
                    break
                default:
                    shelfType = "shelf-4x3-wBones"
                    boneOffset = 8
                    heightOffset = -2
                    scaleOffset = 0.02
                    break;
            }

            categoryContainer.forEach(element => {
                scene.remove(element)
            });

            categoryContainer.children = []

            CategorySpawnLoop()
        }

        function CategorySpawnLoop () {    
            if (currentCatalog[currentCategory].length > 0) {
                LoadCategoryPart(Object.values(currentCatalog[currentCategory])[loadedCategoryParts].id, Object.values(currentCatalog[currentCategory])[loadedCategoryParts].type, CategorySpawnIterate); 
            }
            else {
                loadEmptyShelf()
                //enable pointer when done loading
                togglePointer(true)
                //hide loading
                document.getElementsByClassName('loading')[0].style.display = 'none'
            }
        }

        var CategorySpawnIterate = () => {
            // set x to next item
            loadedCategoryParts++;

            //find current shelf bone
            // currentBone = loadedCategoryParts - ((shelves.length - 1) * boneOffset)

            // any more items in array? continue loop
            if(loadedCategoryParts < Object.keys(currentCatalog[currentCategory]).length) {
                CategorySpawnLoop()
            }
            else {
                //we're done
                loadedCategoryParts = 0
                currentCategory = null
                currentBone = 0

                // scene.add(categoryContainer)
                scene.add(shelfContainer)

                shelves.forEach(shelf => {
                    interactables.push(shelf.children[0])
                });
                // interactables.push(shelf.children[0])

                CheckTutorialStep('LoadCategory')

                //enable pointer when done loading
                togglePointer(true)

                //hide loading
                document.getElementsByClassName('loading')[0].style.display = 'none'
            }
        }

        let shelfArrowTarget = new Object3D()
        shelfArrowTarget.position.set(1.39,3,0)
        scene.add(shelfArrowTarget)
        this.arrowTargets['shelfTarget'] = shelfArrowTarget

        async function LoadCategoryPart(id, type, callback) {

                if (id) {

                    //check if we need another shelf
                    if (shelves.length <= loadedCategoryParts / boneOffset) {

                        const object = await modelLoader(modelPath + shelfType + '.glb')

                        // gltfLoader.load('/models/' + shelfType + '.glb', (object) => {
                            let obj = object.scene
                            obj.name = "Shelf_" + (shelves.length + 1)
                            obj.tag = "drag"
                            obj.position.set(shelves.length * shelfOffset,0,-0.7)
                            // obj.rotation.set(0,-0.75,0)
                            // obj.scale.set(1.5,1.5,1.5)
                    
                            //set up shadow cast, this might have to change if the hierarchy of the shelf model changes
                            obj.children[0].children.forEach(mesh => {
                                mesh.castShadow = true
                            });
                            
                            currentShelf = obj
                            //save slots and bones onto shelf object
                            currentShelf.slots = []
                            currentShelf.bones = []
                    
                            obj.children.forEach(element => {
                                if (element.name.includes('slot')) {
                                    element.tag = 'select'
                                    element.visible = false
                    
                                    currentShelf.slots.push(element)
                    
                                    interactables.push(element)
                                }
                                else if (element.name.includes('Armature')) {
                                    element.children.forEach(chdrn => {
                                        if (chdrn.name.includes('Bone')){
                                            currentShelf.bones.push(chdrn)
                                        }
                                    });
                                }
                            });
                            
                            //add whole shelf to interactables too in case the drag outside the slots
                            interactables.push(obj)
                    
                            shelfContainer.add(obj)
                            draggableShelves.push(obj)
                            shelves.push(obj)

                            currentBone = 0
                        // })
                    }

                    let equippedSign = await modelLoader(modelPath + 'equippedSign.glb');
                    equippedSign = equippedSign.scene;
                    equippedSign.name = 'equippedSign';
                    equippedSign.visible = false
                    equippedSign.tag = 'equippedSign'
          
                    equippedSign.position.set(0,-0.58,0.63)
                    equippedSign.rotation.set(0,-1.45,0)
                    equippedSign.scale.set(0.055,0.055,0.055)
          
                    currentShelf
                        .getObjectByName('Armature')
                        .children[currentBone].add(equippedSign);

                    let tex
                    if(type == 'eyes') tex = id

                    console.log('spawning the piece ' + id)
                    
                    // gltfLoader.load(modelPath + id + '.glb', (model) => {

                        // let obj = model.scene.getObjectByProperty("type", "SkinnedMesh")
                        let obj
                        
                        //we don't load anything automatically for the animations
                        if  (type != 'animations') {
                            if (type != 'eyes')
                            {
                                await modelLoader(modelPath + id + '.glb')
                                .then((data) => {
                                    obj = data.scene
                                    console.log('loaded')
                                }).catch(() => {
                                    console.log('failed loading')
                                    //enable pointer since it failed so they can try again
                                    togglePointer(true)
                                    //return null
                                })
                            }
                            else {
                                await modelLoader(modelPath + 'eyes.glb')
                                .then((data) => {
                                    obj = data.scene
                                    console.log('loaded')
                                }).catch(() => {
                                    console.log('failed loading')
                                    //enable pointer since it failed so they can try again
                                    togglePointer(true)
                                    //return null
                                })
                            }

                            
                            //check if loaded successfully
                            if(obj) {
                                if (obj.type == "Group") {
                                    for (let i = 0; i < obj.children.length; i++) {
                                        const element = obj.children[i];
                                        
                                        // element.bind(this.bodyparts.skeleton, categoryContainer.matrixWorld)
                                        // element.bindMode = 'detached'

                                        element.scale.set(scaleOffset, scaleOffset, scaleOffset)

                                        // categoryContainer.push(element)

                                    }
                                }
                                else if (obj.type == "SkinnedMesh") {
                                    // obj.bind(this.bodyparts.skeleton, categoryContainer.matrixWorld)
                                    // obj.bindMode = 'detached'

                                    obj.scale.set(scaleOffset, scaleOffset, scaleOffset)

                                    // categoryContainer.push(obj)
                                }

                                //this needs to be changed for a particular item offset
                                obj.translateY(heightOffset)
                            }

                        }
                        
                        //check if loaded successfully
                        if (obj) {
                            let newPlaceholder

                            switch (type) {
                                case "head_topper":
                                case "head_acc":
                                case "face_acc":
                                
                                    newPlaceholder = await modelLoader(modelPath + 'head_placeholder.glb')
                                    newPlaceholder = newPlaceholder.scene
                                    newPlaceholder.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
                                    newPlaceholder.translateY(heightOffset)
                                    
                                    // scene.add(newPlaceholder)
                                    break
                                case "eyes":
                                    newPlaceholder = await modelLoader(modelPath + 'head_placeholder.glb')
                                    newPlaceholder = newPlaceholder.scene
                                    newPlaceholder.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
                                    newPlaceholder.translateY(heightOffset)

                                    // //tint the manequin head so it's not so bone chilling
                                    // newPlaceholder.children[0].children[1].material.color.set(hslToHex(characters[user.mainCharacter].customizations.body.bodyColor.h, characters[user.mainCharacter].customizations.body.bodyColor.s, characters[user.mainCharacter].customizations.body.bodyColor.v))

                                    // let eyeMesh = await modelLoader(modelPath + 'head_placeholder.glb')
                                    // eyeMesh = eyeMesh.scene
                                    // eyeMesh.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
                                    // eyeMesh.translateY(heightOffset)
                                    
                                    let eyes_tex = textureLoader.load(modelPath + tex +'.png')
                                    eyes_tex.flipY = false
                                    // eyes_tex.magFilter = THREE.NearestFilter;
                                    // eyes_tex.minFilter = THREE.NearestFilter;
                                    let eyes_mat = new THREE.MeshBasicMaterial({
                                            map: eyes_tex,
                                            // alphaTest: 0.5
                                        });
                                    eyes_mat.needsUpdate = true
                                    eyes_mat.transparent = true
                                    obj.getObjectByName('obj').material = eyes_mat
                                    break
                                case "face":

                                    // we need to spawn in the face placeholder but tint it as the current face and spawn face texture around it
                                    break
                                case "hands_acc":
                                    newPlaceholder = await modelLoader(modelPath + 'hands_placeholder.glb')
                                    newPlaceholder = newPlaceholder.scene
                                    newPlaceholder.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
                                    newPlaceholder.translateY(heightOffset)
                                    obj.translateZ(-0.27)
                                    break
                                case "feet":
                                    
                                    break
                                case "clothes":
                                    equippedSign.position.set(0,-1.42,0.09)
                                    break
                                case "body_acc":
                                    
                                    break
                                case "animations":

                                    //we need to spawn in some kind of placeholder with a symbol for the current animation
                                    newPlaceholder = await modelLoader(modelPath + 'animFrame.glb')
                                    newPlaceholder = newPlaceholder.scene
                                    newPlaceholder.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
                                    newPlaceholder.translateY(heightOffset)
                                    break
                                default:
                                    
                                    break;
                            }
                            
                            //tint the manequin head so it's not so bone chilling
                            newPlaceholder?.children[0].children[1].material.color.set(hslToHex(characters[user.mainCharacter].customizations.body.bodyColor.h, characters[user.mainCharacter].customizations.body.bodyColor.s, characters[user.mainCharacter].customizations.body.bodyColor.v))
                            
                            //add item id to appropriate slot on shelf
                            let tempCurBone = currentBone
                            let tempCurShelf = currentShelf
                            //TODO: ADD FIX FOR IF ITEM CANNOT BE REMOVED
                            currentShelf.slots[currentBone].callback = [() => {characters[user.mainCharacter].replacePart(type, id)},() => {toggleShelfItem(tempCurShelf, tempCurBone, type)}]
                            currentShelf.getObjectByName("Armature").children[tempCurBone].add(obj)
                            currentShelf.getObjectByName("Armature").children[tempCurBone].add(newPlaceholder)
                            
                            currentBone ++

                            // categoryContainer.position.set(-this.startingPosition.x, -this.startingPosition.y, -this.startingPosition.z)

                            // console.log('done creating the ' + type)

                            //check if the object is already equipped
                            Object.values(characters[user.mainCharacter].items).forEach(element => {
                                if (element === id) {
                                    toggleShelfItem(tempCurShelf, tempCurBone)
                                }
                            });

                            
                        }

                        if (callback) callback()
                    // },
                    // // null,
                    // // this.loadError(type, callback? callback : null)
                    // )
                }
            else {
                // console.log('Character not using ' + type)
                if (callback) callback()
            }
        }

        async function loadEmptyShelf () {
            const object = await modelLoader(modelPath + shelfType + '.glb')

            // gltfLoader.load('/models/' + shelfType + '.glb', (object) => {
                let obj = object.scene
                obj.name = "Shelf_" + (shelves.length + 1)
                obj.tag = "drag"
                obj.position.set(shelves.length * shelfOffset,0,-0.7)
                // obj.rotation.set(0,-0.75,0)
                // obj.scale.set(1.5,1.5,1.5)
        
                //set up shadow cast, this might have to change if the hierarchy of the shelf model changes
                obj.children[0].children.forEach(mesh => {
                    mesh.castShadow = true
                });
                
                currentShelf = obj
                //save slots and bones onto shelf object
                currentShelf.slots = []
                currentShelf.bones = []
        
                obj.children.forEach(element => {
                    if (element.name.includes('slot')) {
                        element.tag = 'select'
                        element.visible = false
        
                        currentShelf.slots.push(element)
        
                        interactables.push(element)
                    }
                    else if (element.name.includes('Armature')) {
                        element.children.forEach(chdrn => {
                            if (chdrn.name.includes('Bone')){
                                currentShelf.bones.push(chdrn)
                            }
                        });
                    } 
                });

                interactables.push(obj)
                    
                shelfContainer.add(obj)
                draggableShelves.push(obj)
                shelves.push(obj)

                scene.add(shelfContainer)

                CheckTutorialStep('LoadCategory')
        }

        //TODO: ADD FIX FOR IF ITEM CANNOT BE REMOVED
        let prevSelection
        function toggleShelfItem (shelf, index, type) {
            if  (type != 'animations') {
                if (prevSelection && prevSelection != shelf.bones[index].children[1]) {
                    prevSelection[1].visible = true
            prevSelection[0].visible = false
                }
                
                shelf.bones[index].children[0].visible = !shelf.bones[index].children[0].visible
                shelf.bones[index].children[1].visible = !shelf.bones[index].children[1].visible
                prevSelection = shelf.bones[index].children
            }
            
        }

        function resetShelves () {

            //manual child disposing for now
            // shelfContainer.children.forEach(shelf => {
            //     //for each of the shelves
            //     shelf.children.forEach(el => {
            //         if (el.name.includes('Armature')) {
            //             //this is where the bones and each of the clothing items are
            //             el.children.forEach(item => {
            //                 //this is the actual shelf mesh
            //                 if (item.type.includes('Mesh')) {
            //                     item.geometry.dispose();
            //                     item.material.dispose(); 
            //                 }
            //                 //these are the bones holding the clothes
            //                 else {
            //                     //this is the contents of the clothing item scen
            //                     //   scene       Armature    SkinnedMesh
            //                     item.children[0]?.children[0].children[1].geometry.dispose()
            //                     item.children[0]?.children[0].children[1].material.dispose()
            //                 }
            //             });
            //         }
            //         else if (el.name.includes('slot')) {
            //             el.geometry.dispose()
            //             el.material.dispose()
            //         }
            //     });
            // });


            while(shelfContainer.children.length > 0) {
                // shelfContainer.children[0].children.forEach(element => {
                //     element.dispose()
                // });
                // shelfContainer.children[0].geometry.dispose();
                // shelfContainer.children[0].material.dispose();
                shelfContainer.remove(shelfContainer.children[0])
                scene.remove(shelfContainer.children[0])
                
                
            }
            scene.remove(shelfContainer)
            shelfContainer = new THREE.Group()
            shelfContainer.name = "ShelfContainer"
            shelfContainer.position.set(1.77,0,0.54)
            shelfContainer.rotation.set(0,-0.39,0)
            shelfContainer.scale.set(1.5,1.5,1.5)

            shelves.length = 0
            currentShelf = null

            console.log("Interactables before: ")
            console.log(interactables.length)

            
            removeShelves([mixerControls, bodyMixerControls], [() => {MorphMainChar(true, true)},() => {MorphMainChar(false, true)}, () => {MorphMainChar(true, false)},() => {MorphMainChar(false, false)}, SaveCharacter, () => {customizeRotate(true)},() => {customizeRotate(false)}, CategoryStartSpawn])
            // for (var i = interactables.length - 1; i >= 0; i--) {
                
            //     if (interactables[i].name.includes('slot') || interactables[i].name.includes('Armature')) { 
            //         console.log(interactables[i].name)
            //         interactables.splice(i, 1);
            //         console.log(interactables.length)
            //     }
            // }
            // interactables.push(characterSpinner)

            console.log("Interactables after: ")
            console.log(interactables.length)
        }

        function modelLoader(url) {
            return new Promise((resolve, reject) => {
                gltfLoader.load(url, data=> resolve(data), null, reject);
            });
        }   

        function customizeRotate(body) {

            let bodyX
            let bodyZ

            switch (mediaQueryState) {
                case 'desktop':
                    bodyX = -0.72
                    bodyZ = 1.27
                    break;
                case 'tabletLandscape':
                    bodyX = -0.250
                    bodyZ = 1.610
                    break;
                case 'tabletProtrait':
                    bodyX = -1.75
                    bodyZ = 2.1
                    break;
                case 'mobileLandscape':
                    bodyX = -1.75
                    bodyZ = 2.1
                    break;
                case 'mobilePortrait':
                    bodyX = -2.43
                    bodyZ = 3.02
                    break;
                default:
                    break;
            }

            

            if (body) {
                if (mediaQueryState == 'mobileLandscape') {
                    //camera
                    gsap.to(camContainer.position, {z: 4.32, duration: 0.5})
                }
                gsap.to(camContainer.rotation, {duration: 1, y: 1.57})
                gsap.to(characters[user.mainCharacter].body.position, {duration: 1, x: bodyX, z: bodyZ})
                gsap.to(characters[user.mainCharacter].body.rotation, {duration: 1, y: 0.44})
                
                gsap.to(characters[user.mainCharacter].body.children[0].position, {duration: 1, x:-bodyX, z: -bodyZ})

                // gsap.to(characterSpinner.rotation, {duration: 1, y: 0.44})
                // gsap.to(characterSpinner.position, {duration: 1, x: bodyX, z: bodyZ})
                
                CheckTutorialStep("MoveToBody")
                
            }
            else {
                if (mediaQueryState == 'mobileLandscape') {
                    //camera
                    gsap.to(camContainer.position, {z: 2.65, duration: 0.5})
                }
                gsap.to(camContainer.rotation, {duration: 1, y: 0})
                gsap.to(characters[user.mainCharacter].body.position, {duration: 1, x: 0, z: 0})
                gsap.to(characters[user.mainCharacter].body.children[0].position, {duration: 1, x: 0, z: 0})
                gsap.to(characters[user.mainCharacter].body.rotation, {duration: 1, y: 0})

                // gsap.to(characterSpinner.rotation, {duration: 1, y: 0})
                // gsap.to(characterSpinner.position, {duration: 1, x: 0.09, z: 0})

                CheckTutorialStep("MoveFromBody")
            } 
        }

        //===========================
        //
        //     Purchase Functions
        // 
        //===========================

        // const cart = new Cart()

        function SaveCharacter() {
            //display loading
            document.getElementsByClassName('loading')[0].style.display = 'block'

            fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
                method: "POST",
                headers: {
                    "Accept" : "text/plain",
                    "X-EntityToken" : user.entityToken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    "FunctionName": "Save_Character_Configuration",
                    "FunctionParameter": {                
                        "characterID" : user.mainCharacter,
                        "customizations" : JSON.stringify(characters[user.mainCharacter].customizations),
                        "items" : JSON.stringify(characters[user.mainCharacter].items)
                    }


                })
            })
            .then(response => response.json())
            .then(data => {

                //hide loading
                document.getElementsByClassName('loading')[0].style.display = 'none'
                
                console.log('updated character items');

                CheckTutorialStep("SaveCharacter")

            });
        }

        // function PurchaseItems () {
        //     fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
        //         method: "POST",
        //         headers: {
        //             "Accept" : "text/plain",
        //             "X-EntityToken" : user.entityToken,
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({

        //             "FunctionName": 'PurchaseAudiophileItem',
        //             "FunctionParameter": {
        //                 "items" : cart.formattedItems,
        //                 "catalogVersion": catalogVersion,
        //                 "currency": "DB",
        //                 "clientTotalCost": cart.price.toString()
        //             }
        //         })
        //     })
        //     .then(response => response.json())
        //     .then(data => {

        //         GetOwnedItems()
        //         console.log('function successful')

        //     });
        // }

        function parseCatalogItem(item) {

            //couldn't do this in a single line :(
            let price = 0
            if (item.VirtualCurrencyPrices && item.VirtualCurrencyPrices['DB']) {
                price = item.VirtualCurrencyPrices['DB']
            }
            
            let processedItem = {
                id : item.ItemId.split('|')[0],
                type: item.ItemId.split('|')[1],
                name : item.DisplayName, 
                price : price
            }

            return processedItem
        }


            
        // function loadCatalogItem(item) {
            
        //     if (!cart.contains(item)) {
        //         cart.add(item)
        //         characters[user.mainCharacter].replacePart(item.type, item.id)
        //     }
        //     else{
        //         cart.remove(item)
        //         characters[user.mainCharacter].replacePart(item.type, characters[user.mainCharacter].defaultItems[item.type])
        //     }
            
        //     console.log("Cart", cart)
            
            
        // }

        // var sizeMin = -0.3, sizeMax = 0.2, headMin = 0.4, headMax = -0.25
        // const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;
        // Number.prototype.map = function (in_min, in_max, out_min, out_max) {
        //     return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
        //   }


        /**
         * Sizes
         */
        // const sizes = {
        //     width: window.innerWidth,
        //     height: window.innerHeight
        // }

        // window.addEventListener('resize', () =>
        // {
        //     // Update sizes
        //     sizes.width = window.innerWidth
        //     sizes.height = window.innerHeight

        //     // Update camera
        //     camera.aspect = sizes.width / sizes.height
        //     camera.updateProjectionMatrix()

        //     // Update renderer
        //     renderer.setSize(sizes.width, sizes.height)
        //     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        // })

        let testX = -4;
        // document.addEventListener("keydown", onSpawnKey, false);
        // function onSpawnKey(event) {
        //     var keyCode = event.which;
        //     if (keyCode == 83) {
        //         // GetUserCharacter(user)
        //         // let testChar = new Character(charProps)
        //         // characters.push(testChar)
        //         // console.log(characters)
        //         // testChar.spawn("testID",testX,0,0)

        //         // testX++

        //         replaceColor()
        //     }
        //     if (keyCode == 68) {
        //         // GetUserCharacter(user)
        //         // let testChar = new Character(charProps)
        //         // characters.push(testChar)
        //         // console.log(characters)
        //         // testChar.spawn("testID",testX,0,0)

        //         // testX++

        //         replaceColor2()
        //     }
        //     // if (keyCode == 68) {
        //     //     // let testChar2 = new Character(charProps2)
        //     //     // characters.push(testChar2)
        //     //     // // console.log(characters)
        //     //     // testChar2.spawn(testX,0,0)

        //     //     // testX++

        //     //     characters[user.mainCharacter].replacePart("body_acc", "body_jazz")
        //     // }
        //     if (keyCode == 49) {
        //         // console.log(JSON.stringify(characters[0].customizations))
        //         characters[user.mainCharacter].startAnimation('robot')
        //     }
        // };
        /**
         * Camera
         */
        // Base camera
        // const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
        camera.position.set(0, 0, 0)
        camera.rotation.set(-0.15, 0, 0)
        camContainer.add(camera)
        scene.add(camContainer)

        // Controls
        // const controls = new OrbitControls(camera, canvas)
        // controls.target.set(0, 0.75, 0)
        // controls.enableDamping = true





        function readControls (obj) {

            switch (obj.object.charProp) {
                case "headSize":
                    // characters[user.mainCharacter].customizations.head.headSize = ((headSizeProp.max - headSizeProp.min) * obj.object.val) + headSizeProp.min
                    characters[user.mainCharacter].customizations.head.headSize = getVal(obj.object.val, headSizeProp.min, headSizeProp.max)
                    break;
                case "neckLength":
                    characters[user.mainCharacter].customizations.head.neckLength = getVal(obj.object.val, neckLengthProp.min, neckLengthProp.max)
                        
                    break;
                case "bodySize":
                characters[user.mainCharacter].customizations.body.bodySize = getVal(obj.object.val, bodySizeProp.min, bodySizeProp.max)
                    
                break;
                case "legLength":
                    characters[user.mainCharacter].customizations.feet.legLength = getVal(obj.object.val, legLengthProp.min, legLengthProp.max)
                        
                    break;
                case "handSize":
                    characters[user.mainCharacter].customizations.hands.handSize = getVal(obj.object.val, handSizeProp.min, handSizeProp.max)
                        
                    break;
                case "feetSize":
                    characters[user.mainCharacter].customizations.feet.feetSize = getVal(obj.object.val, feetSizeProp.min, feetSizeProp.max)
                        
                    break;
                case "hue":
                    characters[user.mainCharacter].customizations.body.bodyColor.h = getVal(obj.object.val, bodyColorProp.hMin, bodyColorProp.hMax)
                    break
                case "sat":
                    characters[user.mainCharacter].customizations.body.bodyColor.s = getVal(obj.object.val, bodyColorProp.sMin, bodyColorProp.sMax)
                    break
                case "value":
                    characters[user.mainCharacter].customizations.body.bodyColor.v = getVal(obj.object.val, bodyColorProp.vMin, bodyColorProp.vMax)
                    default:
                    break;
            }

            characters[user.mainCharacter].applyAdjustments()
        }

        function spinChar (delta) {
            // console.log("spinning char this much: " + delta)
            let spinRate = mediaQueryState == 'mobilePortrait' ? 0.022 : 0.006

            characters[user.mainCharacter].body.children[0].rotation.y += (delta * spinRate)

        }

        function resetCharSpin () {
            //characters[user.mainCharacter].body.rotation.y = 0

            gsap.to(characters[user.mainCharacter].body.children[0].rotation, {duration: 0.5, y: 0})
        }

        /**
         * Renderer
         */
        // const renderer = new THREE.WebGLRenderer({
        //     antialias: true,
        //     canvas: canvas
        // })
        renderer.shadowMap.enabled = true
        renderer.shadowMap.type = THREE.PCFSoftShadowMap
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


        

        // //screen resizer + change callback
        // screenSizeListener(camera, renderer, mediaQueryChange)

        function mediaQueryChange (state) {
            console.log("Changed screen to " + state)

            switch (state) {
                case 'desktop':
                    //camera
                    gsap.to(camContainer.position, {x: 1.8, y: 1.7, z: 3.2, duration: 0.5})
                    //mixers
                    gsap.to(mixerControls.position, {x: 1.8, y: 1.24, z: 2.47, duration: 0.5})
                    gsap.to(mixerControls.rotation, {x: 0.67, z: 0, duration: 0.5})
                    gsap.to(bodyMixerControls.position, {x: 1.1, y: 1.24, z: 3.4, duration: 0.5})
                    gsap.to(bodyMixerControls.rotation, {z: -0.67, duration: 0.5})
                    
                    break;
                case 'tabletProtrait':
                    //camera
                    gsap.to(camContainer.position, {x: 1.21, y: 1.77, z: 3.74, duration: 0.5})
                    //mixers
                    gsap.to(mixerControls.position, {x: 1.09, y: 1.1, z: 2.82, duration: 0.5})
                    gsap.to(mixerControls.rotation, {x: 1.15, z: 0, duration: 0.5})
                    gsap.to(bodyMixerControls.position, {x: 0.46, y: 1.24, z: 3.82, duration: 0.5})
                    gsap.to(bodyMixerControls.rotation, {z: -0.97, duration: 0.5})
                    break;
                case 'mobileLandscape':
                    //camera
                    gsap.to(camContainer.position, {x: -0.09, y: 1.77, z: 2.65, duration: 0.5})
                    //mixers
                    gsap.to(mixerControls.position, {x: -0.53, y: 1.46, z: 2.08, duration: 0.5})
                    gsap.to(mixerControls.rotation, {x: 1.24, z: -0.07, duration: 0.5})
                    gsap.to(bodyMixerControls.position, {x: -0.56, y: 1.6, z: 4.47, duration: 0.5})
                    gsap.to(bodyMixerControls.rotation, {z: -1.37, duration: 0.5})
                    break;
                case 'mobilePortrait':
                    //camera
                    gsap.to(camContainer.position, {x: 0.7, y: 1.77, z: 4.4, duration: 0.5})
                    //mixers
                    gsap.to(mixerControls.position, {x: 0.64, y: 1.28, z: 3.66, duration: 0.5})
                    gsap.to(mixerControls.rotation, {x: 1.38, z: 0.0, duration: 0.5})
                    gsap.to(bodyMixerControls.position, {x: 0.01, y: 1.28, z: 4.405, duration: 0.5})
                    gsap.to(bodyMixerControls.rotation, {z: -1.38, duration: 0.5})
                    break;
                default:
                    break;
            }

            mixerControls.updateMatrixWorld()
            mixerControls.children.forEach(chld => {
                chld.updateMatrixWorld()
            });
            bodyMixerControls.updateMatrixWorld()
            bodyMixerControls.children.forEach(chld => {
                chld.updateMatrixWorld()
            });

        }

        //start dragging tick
        toggleCanTick(true)
        tick()
    
        this.mount.appendChild(this.renderer.domElement)
        this.start()
      }
    
      componentWillUnmount() {
        console.log("unmounting")
        this.stop();
        this.mount.removeChild(this.renderer.domElement);
        
        // Traverse the whole scene
        this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
            else if (child instanceof THREE.Group) {
                child.traverse((subChild) =>
                {
                    if(subChild instanceof THREE.Mesh)
                    {
                        subChild.geometry.dispose()

                        // Loop through the material properties
                        for(const key in subChild.material)
                        {
                            const value = subChild.material[key]

                            // Test if there is a dispose function
                            if(value && typeof value.dispose === 'function')
                            {
                                value.dispose()
                            }
                        }
                    }
                })
            }
        })
        for (let i = 0; i < this.scene.children.length; i++) {
            const element = this.scene.children[i];
            
            this.scene.remove(element)
        }
        // this.renderer.instance.dispose()
        this.scene = null
        this.camera = null
        this.renderer && this.renderer.renderLists.dispose()
        this.renderer = null

        this.eventsDispose()
    }
    
      start() {
        const clock = new THREE.Clock()
        let previousTime = 0

        if (!this.frameId) {
          this.frameId = requestAnimationFrame(() => {this.animate(clock, previousTime)})
        }
      }
    
      stop() {
        cancelAnimationFrame(this.frameId)
      }
    
      animate(clock, previousTime) {
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = elapsedTime - previousTime
        previousTime = elapsedTime
        //update all character animation mixers
        for (const [id, char] of Object.entries(this.characters)) {
            if (char.currentAnim)
                char.mixer.update(deltaTime)
        }

        let size = new THREE.Vector2(0,0)
        this.renderer.getSize(size)

        let objPos = new THREE.Vector3()
        if (this.arrowData.arrow1.obj && this.arrowTargets[this.arrowData.arrow1.obj]) {
            Object.values(this.arrowTargets[this.arrowData.arrow1.obj].getWorldPosition(objPos))
            const screenPosition = objPos.clone()
            screenPosition.project(this.camera)
            const translateX = screenPosition.x * size.x * 0.5
            //55px is the overall size of the bubble element + the chat element
            const translateY = - screenPosition.y * size.y * 0.5 - 100

            let arrow = document.getElementById('tutArrow-1')
            // arrow.className = "bounce " + arrowData.arrow1.arrow1Pos

            if (arrow) {
               arrow.style.transform = `translateX(${translateX}px) translateY(${translateY}px)` 
            }
            
        }
        if (this.arrowData.arrow2.obj && this.arrowTargets[this.arrowData.arrow2.obj]) {
            Object.values(this.arrowTargets[this.arrowData.arrow2.obj].getWorldPosition(objPos))
            const screenPosition = objPos.clone()
            screenPosition.project(this.camera)
            const translateX = screenPosition.x * size.x * 0.5
            //55px is the overall size of the bubble element + the chat element
            const translateY = - screenPosition.y * size.y * 0.5 - 100

            let arrow = document.getElementById('tutArrow-2')
            // arrow.className = "bounce " + arrowData.arrow1.arrow1Pos

            if (arrow) {
               arrow.style.transform = `translateX(${translateX}px) translateY(${translateY}px)` 
            }
            
        }
        
    
        this.renderScene()
        this.frameId = window.requestAnimationFrame(() => {this.animate(clock, previousTime)})
      }
    
      renderScene() {
        this.renderer.render(this.scene, this.camera)
      }
    
      render() {
        return (
            <div>
                <Loading />
                <DynamicMenuModernized position={1} direction="down" />
                <div
                    style={{ width: '100vw', height: '100vh' }}
                    ref={(mount) => { this.mount = mount }}
                />
            </div>
        )
      }
}

// const rootElement = document.getElementById("root")
// ReactDOM.render(<DressingRoom />, rootElement);
export default DressingRoom;