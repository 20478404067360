import { call, put, all, takeLatest } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import {
  loginSuccess,
  loginError,
  logoutSuccess,
  registerSuccess,
  registerError,
  login,
  logout,
  registerRequest,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordError,
  loadGuestSession,
  loadGuestSessionSuccess,
  loadGuestSessionError
} from '../slices/auth';
import {
  recentlyLiveRequest,
  popularShowRequest,
  homeSliderRequest,
  scheduledPerformancesRequest
} from '../slices/searchGameSession';
import { getProfileRequest } from '../slices/user';
import {
  FORMAT_DATE_TIME,
  PAGINATION,
  FILTER_STATUS,
  PLAYFAB_TITLE_ID
} from '../shared/utils/constants';
import { toastConfig } from '../shared/defaultValue';
import accountService from '../services/Account';
import userService from '../services/UserAPI';
import moment from 'moment';
import toast from 'react-hot-toast';
import { resetState } from 'slices/searchGameSession';

window.PlayFab.settings.titleId = PLAYFAB_TITLE_ID.titleId;

function loginWithEmailAndPassword(loginRequest) {
  return new Promise((resolve, reject) => {
    window.PlayFab.ClientApi.LoginWithEmailAddress(loginRequest, (response, error) => {
      if (error) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
}

function* loginUser({ payload }) {
  try {
    const result = yield call(accountService.login, payload);
    if (result.success) {
      yield put(resetState());
      yield put(loginSuccess(result.data.token));
      yield put(getProfileRequest());
      localStorage.setItem('refreshToken', result.data.refreshToken);
    } else {
      toast.error(result.message, toastConfig);
      yield put(loginError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(loginError(error.message));
  }
}

function* signUpUser({ payload }) {
  try {
    const result = yield call(accountService.register, payload);
    if (result.success) {
      yield put(registerSuccess(true));
      const loginRequest = {
        Email: payload?.email,
        Password: payload?.password,
      };

      try {
        const response = yield call(loginWithEmailAndPassword, loginRequest);
        const loginValue = {
          email: payload?.email,
          sessionTicket: response?.data.SessionTicket,
        };
        yield put(login(loginValue));
      } catch (error) {
        toast.error(error?.errorMessage, toastConfig);
      }

      toast.success('Account register successful', toastConfig);
      setTimeout(() => {
        let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
        const decode = jwt_decode(appState.auth.currentUser);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'newAccountSignUpFan',
          'playfabId': decode?.playFabId
        });
      }, 3000);
      
      //add new object for tutorial state
      let tutorialState = {
        "dressing-room" : true,
        "store" : true,
        "atm" : true,
        "crowd-chat" : true
      }
      localStorage.setItem("frisson_tutorial", JSON.stringify(tutorialState))
    } else {
      toast.error(result.message, toastConfig);
      yield put(registerError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(registerError(error.message));
  }
}

function* forgotPasswordUser({ payload }) {
  try {
    const result = yield call(accountService.forgotPassword, payload);
    if (result?.success) {
      yield put(forgotPasswordSuccess());
      toast.success('Please check email to change password', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(registerError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(forgotPasswordError(error.message));
  }
}

// Remove the access token cookie from Express
function* logoutUser() {
  yield call(userService.updateUserShowConnection, {
    showId: '-1',
    disconnect: true
  });
  console.log('[Auth] Logout user and reset store');
  yield put(resetState());
  yield put(logoutSuccess());
  localStorage.removeItem('frisson_state');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('profile_user');

  const cookies = document.cookie.split(';');
  const currentDomain = window.location.hostname;

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    const domain = '.' + currentDomain;

    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' + domain;
  }

  localStorage.clear();
}

function* loadGuestSessionUser() {
  try {
    const result = yield call(userService.getGuestToken);
    if (result.success) {
      yield put(resetState());
      yield put(loadGuestSessionSuccess(result.data.token));
      yield put(
        recentlyLiveRequest({
          pageSize: PAGINATION.PAGE_SIZE,
          pageIndex: PAGINATION.PAGE_INDEX,
          filterOptions: {
            status: FILTER_STATUS.LIVE,
            timeStartedFrom: moment()
              .subtract(90, 'days')
              .format(FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM),
            timeStartedTo: moment(new Date()).format(
              FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM
            )
          }
        })
      );
      yield put(
        scheduledPerformancesRequest({
          pageSize: PAGINATION.PAGE_SIZE,
          pageIndex: PAGINATION.PAGE_INDEX,
          filter: [
            {
              key: 'startDatetime',
              operator: 'gte',
              value: new Date()
            }
          ],
          orderBy: {
            startDatetime: 'asc'
          }
        })
      );
      yield put(
        popularShowRequest({
          pageSize: PAGINATION.PAGE_SIZE,
          pageIndex: PAGINATION.PAGE_INDEX,
          filterOptions: {
            status: FILTER_STATUS.PAST,
            timeStartedFrom: moment()
              .subtract(90, 'days')
              .format(FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM),
            timeStartedTo: moment(new Date()).format(
              FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM
            )
          }
        })
      );
      yield put(homeSliderRequest());
    } else {
      toast.error(result.message, toastConfig);
      yield put(loadGuestSessionError(result.message));
    }
  } catch (error) {
    toast.error(error.message, toastConfig);
    yield put(loadGuestSessionError(error.message));
  }
}

export function* authSaga() {
  yield all([
    takeLatest(login.type, loginUser),
    takeLatest(logout.type, logoutUser),
    takeLatest(registerRequest.type, signUpUser),
    takeLatest(forgotPasswordRequest.type, forgotPasswordUser),
    takeLatest(loadGuestSession.type, loadGuestSessionUser)
  ]);
}
