import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from "formik";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Button } from "react-bootstrap";

// selectors
import { forgotPasswordRequest } from "../../slices/auth";
import { isLoading } from "../../selectors/auth.selector";

// component
import Register from '../../container/register-user';
import Login from '../../components/login';
import InputFormik from '../../components/input-formik';

//helpers
import * as yup from "yup";

import "./styles.scss";
import { isSuccessAuth } from '../../selectors/user.selector';
import { SpinnerOverlay } from '../../components/spinner-overlay';

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};


const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};

const DesktopComponent = () => (
  <Fragment>
    <div className="ui embed">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/RA_qz4KNYeA?rel=0&amp;autoplay=1&mute=1&controls=0&showinfo=0&modestbranding=1&hd=1&loop=1"
        title="Frisson Live Stream"
        frameBorder="0"
        allow="accelerometer; autoplay=1; encrypted-media; gyroscope; picture-in-picture "
        allowFullScreen
      />
    </div>
    <section className="seperator-wrapper">
      <div className="seperator gradient"/>
    </section>
  </Fragment>
);

const VideoFrisson = () => {
  const { width } = useViewport();
  const breakpoint = 1028;

  return width > breakpoint ? <DesktopComponent /> : "";
};


const SignUp = () => {

  const [activeTab, setActiveTab] = useState(true);
  const dispatch = useDispatch();

  const loading = useSelector(isLoading);

  const switchTab = (isActive) => {
    setActiveTab(isActive);
  };
  const getIsSuccessAuth = useSelector(isSuccessAuth);
  const switchForgotLogin = (active) => {
    const login = document.querySelector(".login-section");
    const loginTitle = document.querySelector(".--login");
    const signUpTitle = document.querySelector(".sign-up");
    const forgotPassword = document.querySelector(".forgot-password-section");
    const forgotPasswordTitle = document.querySelector(".--forgot-password");
      // eslint-disable-next-line react-hooks/rules-of-hooks


    if (active === true) {
      login.style.display = "none";
      loginTitle.style.display = "none";
      signUpTitle.style.display = "none";
      forgotPassword.setAttribute("style", "display:contents !important");
      forgotPasswordTitle.setAttribute("style", "display:flex !important");
    } else if (active === false) {
      login.style.display = "contents";
      loginTitle.style.display = "flex";
      signUpTitle.style.display = "flex";
      forgotPassword.style.display = "none";
      forgotPasswordTitle.style.display = "none";
    }
  };

  let history = useHistory();
  useEffect(() => {
    getIsSuccessAuth && history.push('/profile');
  }, [getIsSuccessAuth]);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: yup.object().shape({
      email: yup.string().email("Invalid email").required("Required")
    }),
    onSubmit: (values) => {
      dispatch(forgotPasswordRequest(values));
    }
  });

  return(
    <>
      <SpinnerOverlay loading={loading} />
      <div className="wrapper-form-sign-up wow move-up">
        <ViewportProvider>
          <VideoFrisson />
        </ViewportProvider>
        <div className="main-wrapper">
          <div className="wrapper-form">
            <div className="form-login-body">
              <div className="head-tab">
                <div />
                <div
                  className={`choose-tab forgot ${
                    activeTab ? "--selected --forgot-password" : ""
                  }`}
                  onClick={() => {
                    switchTab(true);
                    switchForgotLogin(true);
                  }}
                >
                  Forgot Password
                </div>
                <div
                  className={`choose-tab ${activeTab ? "--selected --login" : ""}`}
                  onClick={() => switchTab(true)}
                >
                  Login
                </div>
                <div
                  className={`choose-tab sign-up ${
                    !activeTab ? "--selected --sign-up" : ""
                  }`}
                  onClick={() => switchTab(false)}
                >
                  Sign Up
                </div>
                <div />
              </div>
              <div className="tabs">
                {activeTab && (
                  <Fragment>
                    <div className="form-active-login">
                      <Login />

                      <form
                        onSubmit={formik.handleSubmit}
                        className="forgot-password-section"
                      >
                        <InputFormik
                          icon="fa-envelope-o"
                          name="email"
                          formik={formik}
                          placeholder="Email address"
                        />
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn-submit"
                          style={{ width: "100%" }}
                          disabled={loading}
                        >
                          Get New Password
                          {loading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </Button>

                        <div
                          className="forgot-password"
                          onClick={() => switchForgotLogin(false)}
                        >
                          Cancel
                        </div>
                      </form>
                    </div>
                  </Fragment>
                )}
                {!activeTab && <Register />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;