export const FORMAT_DATE_TIME = {
  YYYY_MM_DD: "YYYY/MM/DD",
  MM_DD_YYYY: "MM/DD/YYYY",
  YYYY_MM_DDTHH_MM: "YYYY-MM-DDTHH:mm Z"
};

export const DATE = {
  TODATE: "today",
  YES_TER_DATE: "yesterday",
  LAST_WEEK: "lastWeek",
  LAST_MONTH: "lastMonth"
};

export const ROLE_GUEST = ["Guest"];

export const ROLE_SCHEDULE = ["Producer", "Artist", ""];

export const ROLE_FRIENDS_LIST = [""];

export const PAGINATION = {
  PAGE_SIZE: 100,
  PAGE_INDEX: 1
};
export const FILTER_STATUS = {
  PENDING: "Pending",
  LIVE: "Live",
  PAST: "Past"
};

export const PLAYFAB_TITLE_ID = {
  titleId: '10B75'
}