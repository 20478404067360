import React from "react";
import "./styles.scss";
const FormGroupInput = ({
  label,
  placeholder,
  type = "text",
  icon,
  classNameColor = "active",
  formik,
  name,
  readOnly = false,
  disabled
}) => {
  return (
    <div className="form-group">
      <div className={`form-label ${classNameColor}`}>{label}</div>
      <div className="input-content-control">
        <i className={`fa ${icon}`} aria-hidden="true" />
        <input
          readOnly={readOnly}
          type={type}
          value={formik.values[name] || ""}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`input-custom-control ${
            formik.touched[name] && formik.errors[name] ? "is-invalid" : null
          }`}
          placeholder={placeholder}
          name={name}
          autoComplete="off"
          disabled={disabled}
        />
      </div>
      {formik.touched[name] && formik.errors[name] ? (
        <div className="error">{formik.errors[name]}</div>
      ) : null}
    </div>
  );
};

export default FormGroupInput;
