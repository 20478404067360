import React, {Fragment} from "react";
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
const $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('bootstrap/dist/js/bootstrap.min');

class DownloadPage extends React.Component {

    state = {
        page: null
    };

    componentDidMount() {

        fetch("CrowdChatV1/dl.html")
            .then(result => {
                return result.text();
            })
            .then(page => {
                this.setState(
                    {
                        page: { __html: page }
                    }
                );
            });
    }

    render() {
        const { page } = this.state;
        return (
            <Fragment>
                <Helmet>
                    <title>Frisson - Producer Setup</title>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"/>
                    <script src="https://www.frisson.live/CrowdChatV1/js/vendor/bootstrap.min.js"/>
                    <script src="https://www.frisson.live/CrowdChatV1/js/vendor/modernizr-2.8.3-respond-1.4.2.min.js"/>
                    <script src="https://www.frisson.live/CrowdChatV1/js/dl.js"/>
                </Helmet>
                <div
                    dangerouslySetInnerHTML={page && page}
                />
            </Fragment>
        );
    }

}

export default DownloadPage;
