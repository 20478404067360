import React, { useMemo } from "react";
import { isEmpty } from "lodash";
import { redirectPage } from "../../shared/utils/helpers";

import ButtonVideo from "../../components/button-video";
import "./styles.scss";
const RecommendedShow = ({ item }) => {
  const typeVideo = useMemo(() => {
    if (isEmpty(item)) {
      return <div />;
    }

    return (
      <div
        className="cart__recommended-show wow move-up"
        onClick={(e) => redirectPage(e, `jointhecrowd?id=${item?.id}`)}
      >
        <div className="box-recommended__show">
          <img
            src={item.youtubeThumbnail?.replace("mqdefault", "maxresdefault")}
            alt=""
          />
          <div className="content___recommended-show">
            {item.status ? (
              <div className="content-show-top">
                <div className="title-show-horizontal" />
                <div className="title-recommended-show"> {item.status}</div>
              </div>
            ) : (
              ""
            )}
            <div className="content-show-body">
              <div className="show-body-left">{item?.genre}</div>
              <div className="show-body-right">
                <div className="number-watch">{item?.totalConnections}</div>
                <div className="number-watch people">In the crowd</div>
              </div>
            </div>
            <div className="content-show-bottom">
              <div className="show-bottom-left">
                <div className="left__title">
                  {item?.targetArtistDisplayName}
                </div>
                <div className="left__description">{item?.streamTitle}</div>
              </div>
              <div className="show-bottom-right">
                <ButtonVideo videoId={`jointhecrowd?id=${item?.id}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [item]);

  return <>{typeVideo}</>;
};

export default RecommendedShow;
