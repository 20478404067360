import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// layout
import { LayoutDefault } from '../../layouts';
import { Header } from '../../components/header';
import { Footer } from '../../container/footer';
// data
import frisson from '../../data/frisson.json';
import './styles.scss';

const TermsOfSale = () => {
  return (<Fragment>
      <Helmet>
        <title>{`${frisson.title}`}</title>
      </Helmet>
      <LayoutDefault className='bg-light template-font-1 bg_color--3'>
        <Header />
        <Container className='pl_md--60 pl_sm--0'>
          <Row>
            <div className='terms-conditions'>
              <h2>Frisson Terms & Conditions</h2>
              <blockquote>
                <h4>Introduction</h4>
                <p>From time to time, Frisson Studios, Inc. or its affiliates (referred to as “Frisson”, “we”, “us”, or
                  “our”) may make available certain content, services, and products in relation to the website hosted at
                  <a href='http://www.frisson.live'> http://www.frisson.live</a>, software and websites developed by
                  Frisson, or other products or services
                  provided by Frisson (the “Frisson Services”). You signify that you have read, understand, and agree to
                  be bound by the Terms of Sale at the time when you purchase content, services, or products – including
                  purchases that are gifted to another user – through the Frisson Services that link to these Terms of
                  Sale (the “Subsidiary Products and Services”). These Terms of Sale are included in Frisson’s Terms of
                  Service (the “Terms of Service”) and upon purchasing Subsidiary Products and Services, your purchases
                  and use of the Frisson Services are also governed by the Terms of Service. </p>
                <p>At Frisson’s sole discretion, we may change these Terms of Sale without prior written notice. Upon
                  changing these Terms of Sale, we may provide notice via email or a post on the Frisson Services or may
                  update the “Last Updated” date above. You should periodically check these Terms of Sale to stay up to
                  date with any changes. When such changes are made to the Frisson Services, the updated terms will
                  apply to any purchase made after the date of the update. Prior to each purchase you make, you should
                  review these Terms of Sale so that you understand the latest terms that apply to your transaction.
                  Please do not make any purchases on the Frisson Services if you do not agree to these Terms of
                  Sale.</p>
                <p>The Terms of Sale take precedence over the Terms of Service in the event that they conflict, though
                  both agreements together (including other agreements and policies referenced therein) make up the
                  entirety of your agreement with Frisson in connection with your purchases of the Subsidiary Products
                  and Services.</p>
                <p><b>IMPORTANT INFORMATION IN CONNECTION TO YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS ARE CONTAINED IN
                  THESE TERMS OF SALE. THEREFORE, IT IS CRITICAL THAT YOU READ THESE TERMS OF SALE AND THE TERMS OF
                  SERVICE CAREFULLY.</b></p>
              </blockquote>
              <br />
              <h4>1. Purchase Requirements & Account Security</h4>
              <p>Purchases may only be made by registered Frisson Services users who comply with the Terms of Service
                and these Terms of Sale. By agreeing to these Terms of Sale, you accept the sole responsibility for all
                activities under your account, including purchases. Furthermore, you understand that it is your sole
                responsibility to maintain the security of your account, including limiting access to your password and
                account. Subsidiary Products and Services are sold only to users who can legally make purchases with a
                credit or debit card, including (1) users over the age of 18 and (2) users between the ages of 13 and 18
                (or your resident jurisdiction’s legal age of majority) who have the explicit permission of a parent or
                guardian to make a purchase. At Frisson’s sole discretion, we reserve the right to terminate accounts or
                cancel purchases at any time for any or no reason.</p>
              <br />
              <h4>2. Decibels, Hertz, and Rewards</h4>
              <p>Decibels (“Decibels”) may be offered for purchase or on a promotional basis as part of the Subsidiary
                Products and Services and are considered digital content which can enable Rewards (“Rewards”), or
                Decibels can enable a user to show their support for streams, producers, and producer content in the
                form of Vibes (“Vibes” or “Vibbing”). Rewards are digital content that may include virtual characters,
                character items, character animations, exclusive stream features, or exclusive access to streams or
                stream content.</p>
              <p>The Subsidiary Products and Services also include Hertz which are digital content that may enable the
                same or different Rewards than those Rewards enabled for Users of Decibels. Hertz may be offered on a
                promotional basis or unlocked for engaging with Game Content (“Game Content”) which may include engaging
                with Frisson’s chat system Crowd Chat (“Crowd Chat”), engaging with other Crowd Chat Users, uploading
                certain qualified content, or engaging with certain parts of the Frisson Services.</p>
              <p>Decibels, Hertz, Rewards, and Game Content are henceforth called “Digital Content.” Your use of Digital
                Content is subject to Frisson’s Terms of Service, including the Allowed Decibel Usage Policy and the
                Allowed Hertz Usage Policy, all of which Frisson may, with or without notice, update or amend from time
                to time.</p>
              <p>Digital Content may not be used as any type of currency or property and has no monetary value as cash
                or a cash equivalent. Frisson’s Digital Content is always subject to the Terms of Service and therefore
                provides no monetary interest, property, ownership, proprietary, or intellectual property to the user.
                Digital content cannot be exchanged for anything outside the Frisson Services including cash or other
                goods and services. Users cannot sell or transfer Digital Content to other users or third parties, nor
                may they by action or an offer exchange, sell, purchase, barter, or trade Digital Content for any
                consideration included, not included, or outside the Frisson Services. Any transfers or attempts at such
                a transfer are not recognized by Frisson and Users as valid and are null and void.</p>
              <p>Refunds for Digital Content are not provided or allowed unless required by your jurisdiction’s
                applicable law. To be explicit, non-refundable Digital Content includes all Decibels, Hertz, Rewards,
                and all Decibels or Hertz used or redeemed for Vibbing.</p>
              <p>Digital Content is not meant to be a financial instrument, financial support, or compensation for any
                streams or producers. Should Frisson share revenue from the sale of Digital Content with streams or
                producers, said revenue is subject solely to any separate written agreement or agreements between
                Frisson and the applicable stream or producer, though Frisson does not in any way guarantee to any User
                that streams or producers will receive any financial benefit from the sale of Digital Content.</p>
              <p>Frisson reserves the right to change the number of Decibels or Hertz required to earn or enable Rewards
                as well as the number of Decibels or Hertz rewarded in connection to any Game Content. Changes,
                restrictions, and withdraws of Rewards and their qualifying amounts for redemption may happen at any
                time, even if they impact the availability or usage of Digital Content. The availability and inventory
                of Frisson’s Digital content are in no way guaranteed to be available always, at a specific time, or for
                any particular length of time. Therefore, Users should not rely upon the availability or continued
                availability of any Reward or unlocks from Game Content.</p>
              <p>All rights, interest, and title of Digital Content are reserved by Frisson, including any and all
                associated trademarks, copyrights, and other intellectual property rights therein. Upon termination of
                your user account, any Digital Content that has gone unused will be forfeited to Frisson, except as
                required by applicable law. Frisson reserves the right to terminate any User from any of the Frisson
                Services at any time, with or without reason. Digital Content collected by any User prior to termination
                will not be reinstated and will be forfeited.</p>
              <br />
              <h4>3. Delivery Of Products And Services</h4>
              <p>You understand and agree that when you purchase Frisson Subsidiary Products and Services, Frisson has
                completed its responsibility to deliver such product, service, or content once it is made available to
                you or your designated and authorized recipients, despite the usage or lack thereof of the delivered
                product, service, or content.</p>
              <br />
              <h4>4. Refund Policy</h4>
              <p>All purchases made or gifts received as part of the Frisson Services will be made immediately available
                for you to use and access at the time of purchase or gift transfer. Cancellations or returns of
                Subsidiary Products and Services are not permitted after their purchase, except as otherwise stated in
                these Terms of Sale or to the extent permitted by applicable law. We reserve the right to update or
                amend this refund policy for any reason and at any time.</p>
              <br />
              <h4>5. Terms and Methods of Payment</h4>
              <p>You understand that your Frisson User account cannot hold any cash, monetary funds, or currency, but is
                instead used as a way to track, list, and access your payment methods. Payment methods may change from
                time to time and may include various credit cards, debit cards, PayPal, and/or other methods (“Payment
                Methods”). By making a purchase with a certain payment method, you are bound by all the terms and
                conditions of that payment method. When you make a purchase via the Frisson Services, you are agreeing
                that you are responsible for and authorized to use the Payment Method that you designate in the purchase
                and furthermore authorize Frisson and any of its designated payment processors to charge the designated
                Payment Method for the amount specified – including all applicable taxes and fees – in the purchase.
                Unless otherwise offered by a Frisson-provided Payment Method, all purchases are to be made in United
                States Dollars.
              </p>
              <p>
                Frisson payment processing partners may ask for certain personal data in order to complete the payment
                in their system and/or to comply with applicable laws and regulations. Personal data may include, but is
                not limited to, your legal name, address, date of birth, or a valid ID. Our payment processing partners
                may use this personal data to contact you directly regarding any unsuccessful or pending payment.
              </p>
              <p>
                Frisson may automatically terminate or decline your purchase if your Payment Method is invalid or cause
                issues when attempting to complete a purchase. If payment is not accepted and the purchase is declined
                or suspended, you are responsible for resolving the issue or issues to resume your transaction. If you
                need help with a declined transaction, please email <a target='_blank' rel='noopener noreferrer'
                                                                       href='mailto:support@frisson.live?Subject='> support@frisson.live</a>.
              </p>
              <br />
            </div>
          </Row>
        </Container>
        <Footer />
      </LayoutDefault>
    </Fragment>);
};
export default TermsOfSale;
