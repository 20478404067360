import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userAPI from '../../services/UserAPI';
import { Spinner } from '../spinner';
import { isLoading } from '../../selectors/user.selector';
import defaultuser from '../../assets/img/edit-profile/defaultuser.png';
import { getProfileRequest } from '../../slices/user';
import './styles.scss';

const AvatarUploadAccountSettings = ({
  getUser,
  buttonColor,
  getImage,
  loadImage,
  buttonName
}) => {
  const [uploadAvatar, setUploadAvatar] = useState('');
  const [uploadAvatarImage, setUploadAvatarImage] = useState('');
  const dispatch = useDispatch();
  const isloading = useSelector(isLoading);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(loadImage);
  }, [loadImage]);
  // const uploadPicture = async (e) => {
  //   console.log('3333',e.target.files[0])
  //   setLoading(true);
  //   const formData = new FormData();
  //   formData.append('avatar', e.target.files[0]);
  //   const res = await userAPI.uploadAvatar(formData);
  //   if (res) setLoading(false);
  //   // dispatch(getProfileRequest());
  // };
  const encodeImageFileAsURL = (element) => {
    let file = element.target.files[0];
    setUploadAvatarImage(element.target.files[0]);
    let reader = new FileReader();
    reader.onloadend = function () {
      // console.log('RESULT', reader.result)
      setUploadAvatar(reader.result);
    };
    reader.readAsDataURL(file);
    getImage(file);
  };
  return (
    <div aria-label="Change Profile Picture" className="block-avatar-upload">
      {/*<div*/}
      {/*  className='avatar-img'*/}
      {/*  style={{*/}
      {/*    backgroundImage: `url(${uploadAvatar !== '' ? uploadAvatar : getUser?.avatarUrl || defaultuser})`*/}
      {/*  }}*/}
      {/*>*/}
      {/*</div>*/}
      <div className="avatar-img">
        <div className="avatar-img-photo">
          <img
            className="avatar-img-personPhoto"
            src={uploadAvatar !== '' ? uploadAvatar : getUser || defaultuser}
          />
        </div>
      </div>
      {loading || isloading ? (
        <Spinner />
      ) : (
        <>
          <div
            className="avatar-button-upload"
            style={{ backgroundColor: buttonColor ? '#FF6E09' : '' }}
          >
            <input
              className="input-ava"
              type="file"
              accept=".png, .jpg, .jpeg"
              // onChange={uploadPicture}
              onChange={encodeImageFileAsURL}
            />
            {buttonName ? buttonName : 'Change avatar'}
          </div>
        </>
      )}
    </div>
  );
};
export default AvatarUploadAccountSettings;
