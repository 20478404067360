import * as THREE from "three"
import gsap from 'gsap'
import { mediaQueryState } from "./mediaQueries"

export let bubbles = {}
const maxBubbles = 3
let expandFn
export function expandInit (historyExpand) {
    expandFn = historyExpand
}

export function displayMessage (id, message) {

    //TODO: add in a negative margin-top of half the overall height of the element based on the length of the message if greater than 25px (two lines)
    let isAVibe = false
    let vibesMesage = ''

    if (/&vibe&/g.test(message)) {
        //apply vibe styling
        isAVibe = true
        //pull code out of message
        message = message.split('&vibe&')[1]
        //pull out vibe name message
        vibesMesage = message.split('|||')[0]
        message = message.split('|||')[1]
    }
    let newBubble = document.createElement('span')
    newBubble.classList.add('message')
    newBubble.style.opacity = 0
    newBubble.style.transform = 'translateY(0)'
    newBubble.addEventListener('mouseenter', (event) => {
        let target = event.target.closest('.message')
        bringBubbleToTop(target)
    })
    newBubble.addEventListener('click', (event) => {
        let target = event.target.closest('.message')
        bringBubbleToTop(target)
    })

    if (mediaQueryState === 'desktop') {
        if (message.length > 100) {
            message = message.slice(0, 100) + '...'
            newBubble.innerHTML = `<p style="margin: 0">${message}<span class="trunc"><i class="fa fa-external-link-square" aria-hidden="true"></i>
            </span></p>`
            newBubble.querySelector('.trunc').addEventListener('click', () => {
                document.getElementById('messagesList').classList.add('dsktpExpanded')
            })
        }
        else {
            newBubble.innerHTML = `<p style="margin: 0">${message}</p>`
        }
    }
    else {
        if (message.length > 75) {
            message = message.slice(0, 75) + '...'
            newBubble.innerHTML = `<p style="margin: 0">${message}<span class="trunc"><i class="fa fa-external-link-square" aria-hidden="true"></i>
            </span></p>`
            newBubble.querySelector('.trunc').addEventListener('click', () => {
                expandFn()
                document.getElementById('expandButton').style.opacity = 1
            })
        }
        else {
            newBubble.innerHTML = `<p style="margin: 0">${message}</p>`
        }
    }

    
    
        
    if (!bubbles[id] || !document.getElementById('message-'+id)) {
        createNewBubble(id)
        // bubbles[id].html += `<span style="opacity:0, transform: translateY(0)">${message}</span>`
        // if (vibesMesage) { bubbles[id].html += `<p>${vibesMesage}</p>`}
        document.getElementById('messageContainer').insertAdjacentHTML('beforeend', bubbles[id].html)
        bubbles[id].element = document.getElementById('message-'+id)
    }
    else {
        
        
        
        //check for how many span children and if 1, add a new one, if 2, replace child 0
        if (bubbles[id].element.childElementCount == maxBubbles)
        {
            //max bubbles created, so delete the highest one and add a new one at the bottom, then move the others up
            bubbles[id].element.children[bubbles[id].element.childElementCount - 1].remove()
        }
        
        
        
        
        
    }

    //if (vibesMesage) { newBubble.innerHTML = `<p>${vibesMesage}</p>`}
    // document.getElementById('message-'+id).innerHTML = `<span>${message}</span>`  
    if (bubbles[id].element.childElementCount > 0) {
        // let tl = gsap.timeline()
        bubbles[id].element.insertBefore(newBubble, bubbles[id].element.firstChild)
        
        

        

        
    }
    else {
        bubbles[id].element.appendChild(newBubble)
    }
    console.log("inserting message")
    
    //let's get a height offset for the new bubble
    let offset
    let height = newBubble.offsetHeight
    if (height < 70) {
        offset = 0
    }
    else if (height > 70 && height < 90) {
        offset = -25
    }
    else if (height > 90 && height < 120) {
        offset = -50
    }
    else if (height > 120 && height < 140) {
        offset = -73
    }
    else {
        offset = -99
    }


    if (isAVibe) {
        offset = -30
        height += 19
    }

    for (let i = 1; i < bubbles[id].element.children.length; i++) {
        const el = bubbles[id].element.children[i];
        const curOffset = new window.WebKitCSSMatrix(window.getComputedStyle(el).transform).m42 
        console.log(`curOffset: ${curOffset}`, `height: ${height}`, `offset: ${offset}`, `destination: ${curOffset - height + offset - 6}`)
        gsap.to(el, {y: (curOffset - height - 6), duration: 0.4} )
    }

    // bubbles[id].element = document.querySelector('#message-'+id)
    // bubbles[id].element = newBubble


    //vibe styling?
    if (isAVibe) {
        newBubble.classList.add('sentVibe')
    }
    else {
        newBubble.classList.remove('sentVibe')
    }

    //handle height adjustment
    gsap.to(newBubble, {y: offset, duration: 0.4} )

    let tl = gsap.timeline()
    tl.to(newBubble, {autoAlpha: 1, duration: 0, onComplete: () => {
        newBubble.classList.add('visible')
    }} )
    tl.delay(fadeBubble(message.length))
    tl.to(newBubble, {autoAlpha: 0, duration: 1, onComplete: () => {
        newBubble.classList.remove('visible')
    }} )
    
}

function createNewBubble (id) {

    bubbles[id] = {
        id : id,
        html : '<div class="point bubble messageBubble" id="message-'+id+'">',
        element : '',
        message : '',
        position : new THREE.Vector3(0, 0, 0)
    } 

}

function fadeBubble (length) {
    let fadeDelay = 0

    // if (length <= 20) {
    //     fadeDelay = 10
    // }
    // else if (length <= 40) {
    //     fadeDelay = 15
    // }
    // else { 
    //     fadeDelay = 20
    // }
    fadeDelay = Math.min((length / 2), 17)
    // console.log(`fade delay: ${fadeDelay}`)
    return fadeDelay
}

function bringBubbleToTop (newTop) {
    let msgs = document.getElementsByClassName('bubble')
    Array.prototype.forEach.call(msgs,function(el){
        el.classList.remove('top')
    });

    newTop.closest('.bubble').classList.add('top')
    // console.log('bringing to top')
}

export function clearAllBubbles () {
    for (let i = 0; i < Object.keys(bubbles).length; i++) {
        const el = Object.values(bubbles)[i].element;
        gsap.to(el, {autoAlpha: 0, duration: 0.2, onComplete: () => {
            el.classList.remove('visible')
        }} )
        
    }
}