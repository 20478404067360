import React, { useRef, useEffect, useMemo, useState } from 'react';
import Slick from "react-slick";
import TitleCommon from "../../components/title-common";
import { BlockLoading } from "react-loadingg";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoading,
  getRecentlyLive
} from "../../selectors/searchGame.selector";
import { recentlyLiveRequest } from "../../slices/searchGameSession";
import { isEmpty } from "lodash";
import RecentlyItem from "../../components/carousel-slider-top/recently-item";
import {
  FORMAT_DATE_TIME,
  PAGINATION,
  FILTER_STATUS
} from "../../shared/utils/constants";
import moment from "moment";

const RecentlyLive = () => {
  const ref = useRef({});
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const { data, totalCount } = useSelector(getRecentlyLive);
  const countPage = Math.ceil(totalCount / 5);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: "",
    prevArrow: "",
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const next = () => {
    ref.current.slickNext();
  };
  const previous = () => {
    ref.current.slickPrev();
  };
  const SlickSlider = ({ children, settings, classes }) => {
    return (
      <Slick {...settings} ref={ref} className={classes}>
        {children}
      </Slick>
    );
  };

  const newData = useMemo(() => {
    const newList = [];
    const cloneArray = data;

    const totalPage = Math.ceil(totalCount / 5);
    for (let i = 0; i < totalPage; i++) {
      if (i === 0) {
        newList.push({ [i]: cloneArray.slice(0, 5 * 1) });
      } else {
        newList.push({ [i]: cloneArray.slice(i * 5, (i + 1) * 5) });
      }
    }
    return newList;
  }, [totalCount, data]);

  useEffect(() => {
    if (!isEmpty(data)) return;
    dispatch(
      recentlyLiveRequest({
        pageSize: PAGINATION.PAGE_SIZE,
        pageIndex: PAGINATION.PAGE_INDEX,
        filterOptions: {
          status: FILTER_STATUS.LIVE,
          timeStartedFrom: moment()
            .subtract(320, "days")
            .format(FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM),
          timeStartedTo: moment(new Date()).format(
            FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM
          )
        }
      })
    );
  }, [data, dispatch]);

  return (
    <div className="wrapper-recently-slider wow move-up">
      <TitleCommon title="Recently Live" />
      {loading ? (
        <BlockLoading color="#df1a46" />
      ) : (
        <>
          <SlickSlider settings={settings}>
            {!isEmpty(newData) &&
              newData?.map((carousel, index) => {
                const cloneCarousel = carousel[index];
                return (
                  <main key={index}>
                    {cloneCarousel?.map((item, i) => {
                      return (
                        <RecentlyItem
                          key={i}
                          item={item}
                          featureShow={carousel.featureShow}
                          title={carousel.title}
                          classItem={i === 3 ? "item-b" : ""}
                        />
                      );
                    })}
                  </main>
                );
              })}
          </SlickSlider>
          {totalCount > 1 ? (
            <div className="live-now-pagination modify-pagination">
              <div className="box-pagination">
                <div className={`show-arrow ${PAGINATION.PAGE_INDEX === 1 ? 'disabled' : ''}`} onClick={previous}>
                  <i className="fa fa-angle-left" aria-hidden="true" />
                </div>
                <div className={`show-arrow ${PAGINATION.PAGE_INDEX === countPage ? 'disabled' : ''}`} onClick={next}>
                  <i className="fa fa-angle-right" aria-hidden="true" />
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default RecentlyLive;