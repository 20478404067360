import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import hslToHex from "./hslToHex"
import { buildNameplate } from "shared/ui-builder";

const modelPath = 'https://audiophileitems.blob.core.windows.net/items/'

const dracoLoader = new DRACOLoader()
const gltfLoader = new GLTFLoader()
dracoLoader.setDecoderPath('/draco/')

gltfLoader.setDRACOLoader(dracoLoader)

const textureLoader = new THREE.TextureLoader( );


//TEMP
// let texture1 = textureLoader.load(modelPath + 'empty.png')
// let texture2 = textureLoader.load(modelPath + 'hoody_base.png')
// let texture3 = textureLoader.load(modelPath + 'blush.png')
// let texture4 = textureLoader.load(modelPath + 'face.png')

// texture1.flipY = false
// texture2.flipY = false
// texture3.flipY = false
// texture4.flipY = false

let OneShotManager

//Character property ranges
export const headSizeProp = {
    "min" : 0.9,
    "max" : 1.1
}
export const neckLengthProp = {
    "min" : 0.7,
    "max" : 0.9
}
export const bodySizeProp = {
    "min" : 0.9,
    "max" : 1.1
}
export const handSizeProp = {
    "min" : 0.9,
    "max" : 1.2
}
export const legLengthProp = {
    "min" : -0.2,
    "max" : 0.45
}

export const feetSizeProp = {
    "min" : 0.8,
    "max" : 1.1
}

export const bodyColorProp = {
    "hMin" : 0,
    "hMax" : 360,
    "sMin" : 0,
    "sMax" : 100,
    "vMin" : 0,
    "vMax" : 100,
}

export let sharedAnims = []

export function CharacterClassInit (oneshot) {
    OneShotManager = oneshot
}

export class Character {

    constructor(characterProps) {
        this.playerId = null
        this.characterId = null
        this.displayName = typeof characterProps.displayName === 'object' ? characterProps.displayName.displayName : characterProps.displayName
        this.items = characterProps.items
        this.defaultItems = {...characterProps.items}
        this.defaultCustomizations = {...characterProps.customizations}
        this.customizations = characterProps.customizations
        this.animations = characterProps.animations
        this.currentAnim = null
        this.prevAnim = null
        this.idleAnim = null
        this.animQueue = []
        this.currentlyAnimating = false
        this.loadedParts = 0
        this.startingPosition = new THREE.Vector3(0,0,0)
        this.mixer = null
        this.body = new THREE.Group();
        this.body.name = "body"
        this.bodyparts = {
            //assigned after instatiation
            //group all major pieces together for adjustment later
            "skeleton" : null,
            "head_topper" : null,
            "head" : null,
            "head_acc" : null,
            // "face" : null,
            // "face_obj" : null,
            "eyes" : null,
            "mouth" : null,
            "face_acc" : null,
            "head_group" : new THREE.Group(),
            "body" : null,
            "clothes" : null,
            "body_acc" : null,
            "body_group" : new THREE.Group(),
            "hands" : null,
            "hands_acc" : null,
            "hands_group" : new THREE.Group(),
            "feet" : null
        }
        this.skinnedMeshes = []
        this.headOffset = 1.2 - this.customizations.head.neckLength - this.customizations.feet.legLength
        this.scene = null
        this.loadedCallback = null
    }

    spawn (playerId, characterId, xPos,zPos, scene, callback) {
        this.playerId = playerId
        this.CharacterId = characterId
        this.scene = scene
        this.loadedCallback = callback

        this.startingPosition = new THREE.Vector3(xPos,0,zPos);
        gltfLoader.load(modelPath + 'rig_only.glb', (object) => {

            let loadedObject = object.scene
            let loadedSkeleton = loadedObject.getObjectByName('body').skeleton

            this.mixer = new THREE.AnimationMixer(loadedObject)

            this.bodyparts.skeleton = loadedSkeleton

            loadedObject.name = "skeleton"
            loadedObject.visible = false

            // //find head bone for tracking bubble pos
            // this.bodyparts.skeleton.bones.forEach(bone => {
            //     if (bone.name == "mixamorigHeadTop_End") {
            //         //     this.headBone = bone
            //         const cylGeo = new THREE.BoxGeometry(1, 1, 1);
            //         const cylMat = new THREE.MeshBasicMaterial({ color: 0xffff00 });
            //         const bubbleTarget = new THREE.Mesh(cylGeo, cylMat);
            //         let worldPos = new THREE.Vector3()
            //         bone.getWorldPosition(worldPos)
            //         worldPos.y += this.customizations.head.neckLength
            //         bubbleTarget.position.set(xPos, worldPos.y, zPos);
            //         bubbleTarget.scale.set(0.25, 0.25, 0.25);
            //         bubbleTarget.name = 'Bubble Target';
            //         bubbleTarget.visible = false;
            //         this.bodyparts.head_group.add(bubbleTarget);
            //         this.bubbleTarget = bubbleTarget
            //     }


            // });

            this.body.add(loadedObject)

            let helper = new THREE.SkeletonHelper( loadedSkeleton.bones[0] );
            // this.body.add( helper );
            // this.scene.add(this.body)

            //load all animations
            for (let i = 0; i < this.animations.length; i++) {
                const anim = this.animations[i]

                //only load the animation if we haven't already
                if(!sharedAnims[anim]) {
                    gltfLoader.load(
                      modelPath + 'anim_'+ anim +'.glb',
                      (model) =>
                      {
                          //add this anim to the library
                          sharedAnims[anim] = model.animations[0]
                      }
                    )
                }
            }

            //load in all default items
            this.items.head = "head_only"
            this.items.mouth = "mouth"
            this.items.eyes = "eyes"
            this.items.mouth_tex = "mouth_slight_smile"
            this.items.body = "body_only"
            this.items.hands = "hands_only"

            this.#spawnLoop()

        })

    }

    #spawnLoop () {
        this.#loadPart(Object.keys(this.items)[this.loadedParts], Object.values(this.items)[this.loadedParts], this.#spawnIterate);
    }

    #spawnIterate = () => {
        // set x to next item
        this.loadedParts++;

        // any more items in array? continue loop
        if(this.loadedParts < Object.keys(this.items).length) {
            this.#spawnLoop();
        }
        else {

            // this.bodyparts.head_group.add(this.bodyparts.head)
            // if (this.bodyparts.head_acc) this.bodyparts.head_group.add(this.bodyparts.head_acc)
            // if (this.bodyparts.face_acc) this.bodyparts.head_group.add(this.bodyparts.face_acc)
            // if (this.bodyparts.eyes) this.bodyparts.head_group.add(this.bodyparts.face_acc)
            // if (this.bodyparts.mouth) this.bodyparts.head_group.add(this.bodyparts.face_acc)
            this.bodyparts.head_group.name = "head group"

            // this.bodyparts.body_group.add(this.bodyparts.body)
            // if (this.bodyparts.body_acc) this.bodyparts.body_group.add(this.bodyparts.body_acc)
            // if (this.bodyparts.clothes) this.bodyparts.body_group.add(this.bodyparts.clothes)
            this.bodyparts.body_group.name = "body group"

            // this.bodyparts.hands_group.add(this.bodyparts.hands)
            // if (this.bodyparts.hands_acc) this.bodyparts.hands_group.add(this.bodyparts.hands_acc)
            this.bodyparts.hands_group.name = "hands group"



            this.body.add(this.bodyparts.head_group)
            this.body.add(this.bodyparts.body_group)
            this.body.add(this.bodyparts.hands_group)
            this.body.add(this.bodyparts.feet)

            this.applyAdjustments()

            //find head bone for tracking bubble pos
            this.bodyparts.skeleton.bones.forEach(bone => {
                if (bone.name == "mixamorigHeadTop_End") {
                    this.headBone = bone
                    const cylGeo = new THREE.BoxGeometry(1, 1, 1);
                    const cylMat = new THREE.MeshBasicMaterial({ color: 0xffff00 });
                    const bubbleTarget = new THREE.Mesh(cylGeo, cylMat);
                    let worldPos = new THREE.Vector3()
                    this.headBone.getWorldPosition(worldPos)
                    // worldPos.y += 0.9 - this.customizations.head.neckLength 
                    // worldPos.y += 0.45 - this.customizations.feet.legLength
                    bubbleTarget.position.set(worldPos.x, worldPos.y + 0.5, worldPos.z);
                    bubbleTarget.scale.set(0.25, 0.25, 0.25);
                    bubbleTarget.name = 'Bubble Target';
                    bubbleTarget.visible = false;
                    this.bodyparts.head.add(bubbleTarget);
                    this.bubbleTarget = bubbleTarget
                }


            });

            this.body.position.set(this.startingPosition.x, this.startingPosition.y, this.startingPosition.z)
            //try offsetting the skeleton obj to reduce weird positional stretching
            this.body.children[0].position.set(-this.startingPosition.x, -this.startingPosition.y, -this.startingPosition.z)

            //start idle animation
            this.idleAnim = Object.values(this.animations)[0]
            this.startAnimation(this.idleAnim)

            let namePlate = buildNameplate(this.displayName)
            // console.log(this.displayName)
            namePlate.name = "NamePlate"
            namePlate.position.set(0.1,0.08,1.95)
            namePlate.rotation.x = -0.65

            namePlate.children.forEach(chld => {
                chld.castShadow = false
                chld.children.forEach(subChild => {
                    subChild.castShadow = false
                })
            });

            this.body.add(namePlate)

            this.scene.add(this.body)

            if (this.loadedCallback) this.loadedCallback()
        }
    }

    #loadPart(type, id, callback) {
        // const part = Object.keys(this.items)[this.loadedParts]
        // const value = Object.values(this.items)[this.loadedParts]

        if (id && type != "eyes_tex" && type != "mouth_tex") {
            // console.log('trying to create the ' + type)
            // console.log('spawning the piece ' + id)
            const meshGroup = new THREE.Group();
            gltfLoader.load(
              modelPath + id + '.glb',
              (model) =>
              {

                  // let obj = model.scene.getObjectByProperty("type", "SkinnedMesh")
                  let obj = model.scene.getObjectByName('obj');
                  obj.castShadow = true;
                  obj.receiveShadow = false;

                  //assign body parts
                  this.bodyparts[type] = meshGroup
                  this.bodyparts[type].name = type
                  obj.bodyparttype = type

                  switch (type) {
                      case "face_obj":

                    // let baseFace = textureLoader.load(modelPath + this.items.face +'.png')

                    // texture1.flipY = false
                    // texture2.flipY = false
                    // // texture3.flipY = false
                    // baseFace.flipY = false
                    // obj.material.onBeforeCompile = function ( shader ) {
                    //     // console.log(shader.vertexShader)
                    //     shader.uniforms.percent = {value: 1.0 }
                    //     shader.uniforms.tOne = {value: texture1}
                    //     shader.uniforms.tSec = {value: baseFace}
                    //     shader.vertexShader = 'varying vec2 vUv;\n' + shader.vertexShader
                    //     shader.vertexShader = shader.vertexShader.replace(
                    //     '#include <worldpos_vertex>',
                    //     [
                    //         '#include <worldpos_vertex>',
                    //         'vUv = uv;'
                    //     ].join( '\n' )
                    //     );
                    //     shader.fragmentShader = 'uniform sampler2D tOne;\nuniform sampler2D tSec;\nvarying vec2 vUv;\nuniform float percent;\n' + shader.fragmentShader;
                    //     shader.fragmentShader = shader.fragmentShader.replace(
                    //       '#include <dithering_fragment>',
                    //       [
                    //         '#include <dithering_fragment>',
                    //         'vec3 c;',
                    //         'vec4 Ca = texture2D(tOne, vUv);',
                    //         'vec4 Cb = texture2D(tSec, vUv);',
                    //         'c = Ca.rgb * Ca.a + Cb.rgb * Cb.a * (1.0 - Ca.a);  // blending equation',
                    //         'float a = (Ca.a) + (Cb.a);',
                    //         'gl_FragColor= vec4(c, a);',
                    //         // 'if (gl_FragColor.a < 0.5 ) discard;'
                    //       ].join( '\n' )
                    //     );
                    //       obj.material.transparent = true
                    //     obj.material.userData.shader = shader
                    // };
                    // obj.scale.set(1.02,1.02,1.02)
                    // obj.position.set(0,-0.04, 0)
                    // this.bodyparts.head_group.add(this.bodyparts[type])
                    // break
                      case "eyes":
                          let eyes_tex = textureLoader.load(modelPath + this.items.eyes_tex +'.png')
                          eyes_tex.flipY = false
                          // eyes_tex.magFilter = THREE.NearestFilter;
                          // eyes_tex.minFilter = THREE.NearestFilter;
                          let eyes_mat = new THREE.MeshBasicMaterial({
                              map: eyes_tex,
                              depthTest: true
                              // alphaTest: 0.5
                          });
                          eyes_mat.needsUpdate = true
                          eyes_mat.transparent = true
                          obj.material = eyes_mat
                          obj.renderOrder = -1
                          this.bodyparts.head_group.add(this.bodyparts[type])
                          break

                      case "mouth":
                          let mouth_tex = textureLoader.load(modelPath + this.items.mouth_tex + '.png')
                          mouth_tex.flipY = false
                          // mouth_tex.magFilter = THREE.NearestFilter;
                          // mouth_tex.minFilter = THREE.NearestFilter;
                          let mouth_mat = new THREE.MeshBasicMaterial({
                              map: mouth_tex,
                              depthTest: true
                              // alphaTest: 0.5
                          });
                          mouth_mat.needsUpdate = true
                          mouth_mat.transparent = true
                          obj.material = mouth_mat
                          obj.renderOrder = -1
                          this.bodyparts.head_group.add(this.bodyparts[type])
                          break
                      case "head":
                      case "head_topper":
                      case "head_acc":
                      case "hair":
                      case "face_acc":
                          this.bodyparts.head_group.add(this.bodyparts[type])
                          break

                      case "body":
                          this.bodyparts.body_group.add(this.bodyparts[type])
                          break
                      case "clothes":
                          this.bodyparts.body_group.add(this.bodyparts[type])
                          break
                      case "body_acc":
                          this.bodyparts.body_group.add(this.bodyparts[type])
                          break


                      case "hands":
                      case "hands_acc":
                          this.bodyparts.hands_group.add(this.bodyparts[type])
                          break

                      default:
                          this.body.add(this.bodyparts[type])

                          break;
                  }

                  if (obj.type == "Group") {
                      for (let i = 0; i < obj.children.length; i++) {
                          const element = obj.children[i];

                          element.bind(this.bodyparts.skeleton, meshGroup.matrixWorld)
                          element.bindMode = 'detached'

                          meshGroup.add(element)

                      }
                  }
                  else if (obj.type == "SkinnedMesh") {
                      obj.bind(this.bodyparts.skeleton, meshGroup.matrixWorld)
                      obj.bindMode = 'detached'

                      meshGroup.add(obj)

                      this.skinnedMeshes.push(obj)
                  }


                  // meshGroup.position.set(-this.startingPosition.x, -this.startingPosition.y, -this.startingPosition.z)

                  // console.log('done creating the ' + type)
                  if (callback) callback()
              },
              // null,
              // this.loadError(type, callback? callback : null)
            )
        }
        else {
            console.log('Character not using ' + type)
            if (callback) callback()
        }
    }

    replaceTexture (type, id) {

        let obj = this.bodyparts[type].getObjectByName('obj')

        let tex = textureLoader.load(modelPath + id +'.png')
        tex.flipY = false
        obj.material.map = tex
        obj.material.needsUpdate = true

        //set global current properties for saving later
        this.items['eyes_tex'] = id
    }

    replacePart (type, id) {

        switch (type) {
            case "eyes":
                this.replaceTexture(type, id)
                return
            case "animations":
                this.startAnimation(id, true)
                break
            default:
                //delete part of that type if the user has it equipped
                if (this.bodyparts[type]) {

                    let canRemove = false
                    if (id == this.items[type]) {
                        switch (type) {
                            case "head_topper":
                            case "head_acc":
                            case "face_acc":
                            case "body_acc":
                            case "hands_acc":
                                canRemove = true
                                break
                            default:
                                canRemove = false
                                break
                        }
                    }
                    else {
                        canRemove = true
                    }

                    if (canRemove) {
                        this.bodyparts[type].children.forEach(child => this.bodyparts[type].remove(child))
                        this.scene.remove(this.bodyparts[type])

                        //remove from sub groups if necessary
                        switch (type) {
                            case "head":
                            case "head_topper":
                            case "head_acc":
                            case "face_acc":
                                this.bodyparts.head_group.remove(this.bodyparts[type])
                                break

                            case "body":
                            case "clothes":
                                this.bodyparts.body_group.remove(this.bodyparts[type])
                                break
                            case "body_acc":
                                this.bodyparts.body_group.remove(this.bodyparts[type])
                                break
                            case "hands":
                            case "hands_acc":
                                this.bodyparts.hands_group.remove(this.bodyparts[type])
                                break
                            default:
                                this.body.remove(this.bodyparts[type])

                                break;
                        }
                    }


                }

                //make sure this is a different part than we already have, otherwise, just remove it
                if (id != this.items[type]) {
                    this.#loadPart(type, id, this.applyAdjustments)

                    //set global current prooperties for saving later
                    this.items[type] = id
                }
                else {
                    //set global current prooperties for saving later
                    this.items[type] = ''
                }

                break;
        }


    }

    resetChar () {
        Object.keys(this.items).forEach(item => {

            switch (item) {
                case "eyes":
                    if (this.items['eyes_tex'] != this.defaultItems['eyes_tex']) {
                        this.replacePart(item, this.defaultItems['eyes_tex'])
                    }
                    break
                case "head_topper":
                case "head_acc":
                case "face_acc":
                case "clothes":
                case "body_acc":
                case "hands_acc":
                case "feet":
                    //if the item has been changed, set it back to default
                    if (this.items[item] != this.defaultItems[item]) {
                        this.replacePart(item, this.defaultItems[item])
                    }

                    break
                default:
                    break;
            }
        });
    }

    changeExpression (expression) {
        let mouthMat = this.bodyparts['mouth'].children[0].material
        let mouth_tex = textureLoader.load(modelPath + 'mouth_' + expression + '.png')
        mouth_tex.flipY = false
        mouthMat.map = mouth_tex
        mouthMat.needsUpdate = true
    }


    async startAnimation (newAction, oneshot, overlay) {

        //if action is an array, replace the queue
        if(Array.isArray(newAction)) {
            this.animQueue = newAction
        }
        //if not, just put just the one in the queue
        else {
            // this.animQueue = []

            //if nothing passed in, revert to previous
            if(!newAction) this.animQueue.push(this.idleAnim)
            else  this.animQueue.push(newAction)

        }

        //only load the animation if we haven't already
        if(!sharedAnims[this.animQueue[0]]) {
            const model = await Promise.resolve(gltfLoader.loadAsync( modelPath + 'anim_'+ this.animQueue[0] +'.glb' ))
            //add this anim to the library
            sharedAnims[this.animQueue[0]] = model.animations[0]
            // await gltfLoader.loadAsync(
            //     modelPath + 'anim_'+ action +'.glb',
            //     (model) =>
            //     {

            //     }
            // )
        }
        console.log('starting animation ' + this.animQueue[0], this.animQueue)
        const clipAction = this.mixer.clipAction(sharedAnims[this.animQueue[0]])


        if (this.currentAnim) {
            let currentAction = this.mixer.clipAction(sharedAnims[this.currentAnim])
            if (oneshot) {
                // if (!this.currentlyAnimating || overlay) {
                //     this.currentlyAnimating = true
                clipAction.setLoop(THREE.LoopOnce);
                clipAction.reset();
                clipAction.play();
                currentAction.crossFadeTo(clipAction, 0.5, true);

                //if there are more in the queue, time the next calls
                if (this.animQueue.length > 1) {
                    setTimeout(() => {
                        this.startAnimation(this.animQueue, true)
                    }, clipAction._clip.duration * 1000 - (600));
                }
                else {
                    setTimeout(() => {
                        this.startAnimation(this.idleAnim)
                    }, clipAction._clip.duration * 1000 - (600));
                }


                // }

            }
            else {
                clipAction.reset();
                clipAction.play();
            }

        }
        else {
            this.currentAnim = this.animQueue[0]
            clipAction.play()
        }

        //now that we've set it as the active clip, shift the queue over
        this.animQueue.shift()

        // if (this.currentAnim) {

        //     this.prevAnim = this.currentAnim

        //     if ( this.currentAnim !== action ) {
        //         this.mixer.clipAction(sharedAnims[this.currentAnim]);
        //     }

        //     // this.mixer.stopAllAction()
        //     // const action = this.actions[index];
        //     clipAction.weight = 1;
        //     // clipAction.fadeIn(1.5);
        //     // clipAction.play();

        //     // clipAction
        //     // .reset()
        //     // .setEffectiveTimeScale(1)
        //     // .setEffectiveWeight(1)
        //     // .fadeIn(1);


        // }

        // if (oneshot) {
        //     clipAction.setLoop(THREE.LoopOnce)
        //     // clipAction.clampWhenFinished = true

        //     let id = this.playerId

        //     this.mixer.addEventListener( 'finished', function _listener () {
        //             OneShotManager(id, _listener)
        //         }
        //     )

        //     clipAction.crossFadeTo()

        // }
        // else {
        //     //don't set one-shots as current
        //     this.currentAnim = action
        //     clipAction.play()
        // }





    }

    oneShotEnd = (currentAction, clipAction) => {
        currentAction.enabled = true;
        clipAction.crossFadeTo(currentAction, 0.5, true);
        this.currentlyAnimating = false
    }

    applyAdjustments = () => {

        // "headSize" : 1.5,
        // "bodySize" : 1,
        // "handSize" : 1,
        // "feetSize" : 1,
        // "neckLength" : 1,
        // "headSizeLengthOffset" : 0,
        // "armLength" : 1,
        // "legLength" : 0.5

        //leg length
        this.bodyparts.head_group.position.y = this.customizations.feet.legLength
        this.bodyparts.body_group.position.y = this.customizations.feet.legLength
        this.bodyparts.hands_group.position.y = this.customizations.feet.legLength

        //head size
        this.bodyparts.head_group.children.forEach(element => {
            element.children[0].scale.set(this.customizations.head.headSize, this.customizations.head.headSize, this.customizations.head.headSize)
        })
        // this.bodyparts.head_group.scale.set(this.customizations.head.headSize, this.customizations.head.headSize, this.customizations.head.headSize)

        //neck length
        this.bodyparts.head_group.position.y += this.customizations.head.neckLength

        //body size
        this.bodyparts.body_group.children.forEach(element => {
            element.children[0].scale.set(this.customizations.body.bodySize, this.customizations.body.bodySize, this.customizations.body.bodySize)
        });
        // this.bodyparts.body_group.scale.set(this.customizations.body.bodySize, this.customizations.body.bodySize, this.customizations.body.bodySize)
        let bodySizeHeightOffset = -this.customizations.body.bodySize * 1.5 + 1.55
        this.bodyparts.body_group.position.y += bodySizeHeightOffset
        this.morph()


        //arm length

        //hand size
        this.bodyparts.hands_group.children.forEach(element => {
            element.children[0].scale.set(this.customizations.hands.handSize, this.customizations.hands.handSize, this.customizations.hands.handSize)
        })
        // this.bodyparts.hands_group.scale.set(this.customizations.hands.handSize, this.customizations.hands.handSize, this.customizations.hands.handSize)
        let handSizeHeightOffset = -this.customizations.hands.handSize + 1.1
        this.bodyparts.hands_group.position.y += handSizeHeightOffset
        // this.bodyparts.hands_group.position.z -= handSizeHeightOffset

        //feet size
        this.bodyparts.feet.children[0].scale.set(this.customizations.feet.feetSize, this.customizations.feet.feetSize, this.customizations.feet.feetSize)


        //offset for neck length and for head offset
        let headSizeLengthOffset = -this.customizations.head.headSize * 1.5 + 0.75
        this.bodyparts.head_group.position.y += headSizeLengthOffset

        //add skintone
        this.bodyparts.head.getObjectByName('obj').material.color.set(hslToHex(this.customizations.body.bodyColor.h, this.customizations.body.bodyColor.s, this.customizations.body.bodyColor.v))
        this.bodyparts.body.getObjectByName('obj').material.color.set(hslToHex(this.customizations.body.bodyColor.h, this.customizations.body.bodyColor.s, this.customizations.body.bodyColor.v))
        this.bodyparts.hands.getObjectByName('obj').material.color.set(hslToHex(this.customizations.body.bodyColor.h, this.customizations.body.bodyColor.s, this.customizations.body.bodyColor.v))

        // this.bodyparts.head.getObjectByName('obj').material.color.set( this.customizations.body.bodyColor )
        // this.bodyparts.body.getObjectByName('obj').material.color.set( this.customizations.body.bodyColor )
        // this.bodyparts.hands.getObjectByName('obj').material.color.set( this.customizations.body.bodyColor )

        // let body = this.body

        // this.bodyparts.hands_acc.traverse(function(obj){

        //     if (obj.type == "SkinnedMesh") {
        //         let helper = new THREE.SkeletonHelper(obj.skeleton.bones[0])
        //         body.add(helper)
        //     }

        // })


        this.scene.add(this.body)
    }

    changeMorph(increase, head) {
        if (increase){
            if (head && this.customizations.head.morph < 4) {
                this.customizations.head.morph ++
                this.morph(head)
            }
            else if (this.customizations.body.morph < 4) {
                this.customizations.body.morph ++
                this.morph(head)
            }
        }
        else if (!increase) {
            if (head && this.customizations.head.morph > 0) {
                this.customizations.head.morph --
                this.morph(head)
            }
            else if (this.customizations.body.morph > 0) {
                this.customizations.body.morph --
                this.morph(head)
            }
        }

    }

    morph(head) {
        let newMorph = [0,0,0,0,0]

        if (head) {
            newMorph[this.customizations.head.morph] = 1

            //set morph target for body & clothes mesh
            this.bodyparts.head_group.children.forEach(element => {
                element.children[0].morphTargetInfluences = newMorph
            });
        }
        else {

            newMorph[this.customizations.body.morph] = 1

            //set morph target for body & clothes mesh
            this.bodyparts.body_group.children.forEach(element => {
                element.children[0].morphTargetInfluences = newMorph
            });
        }

    }

    loadError (part, callback) {
        console.log("Load error, character " + this.characterId + " failed to load their " + part)
        callback()
    }

}