import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Col, Container, Row, Modal } from 'react-bootstrap';
import classNames from 'classnames/bind';
// redux-action
import { logout, registerSuccess } from '../../slices/auth';
import { getProfileUser } from '../../selectors/user.selector';
import { getAuth } from '../../selectors/auth.selector';
// data
import frisson from '../../data/frisson.json';
import { TABS } from '../../data/profile-edit/ProfileTab';
import logo from '../../assets/img/logo/logo.png';
// component
import Logo from '../../components/header/elements/logo';
import AvatarUpload from '../../components/avatar-upload';
// container
import EarningContainer from '../../container/earning-container';
import AccountSetting from '../../container/account-setting';
import ProfileUser from '../../container/profile-user';
import Footer from '../../container/footer/Footer';
import HeaderContainer from '../../container/header/HeaderContainerOne';
import LayoutDefault from '../../layouts/LayoutDefault';
import {
  getUserByToken,
  loadAppState,
  roleUser
} from '../../shared/utils/helpers';
import SchedulePerformance from '../../container/schedule-performance';
import FriendsList from '../../container/friends-list';
import WatchList from '../../container/watch-list';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { genres } from '../../shared/defaultValue';
import { getURLSearchParam } from '../../helper/getURLSearchParams';
import './styles.scss';
import AddConnection from '../../container/add-connection';
import AvatarUserTab from '../../container/avatar-user-tab';
import userService from '../../services/UserAPI';
import DropDownButton from '../../components/dropdown-button';
import sprite from '../../assets/svg/sprite.svg';
import { selectTab } from '../../slices/tabs';
import DropDownSearchMenu from '../../components/dropdown-search-menu';
import {
  getProfileRequest,
  getRemotePerConfRequest,
  getRemotePerStatusRequest,
  payPalGetCodeHistoryRequest
} from '../../slices/user';

const EditProfile = () => {
  const buttonColor = true;
  const [activeBackground, setActiveBackground] = useState(false);
  const history = useHistory();
  const user = loadAppState();
  // const history = useHistory();
  const tabik = useSelector((state) => state.tabs);
  let location = useLocation();
  const dispatch = useDispatch();
  const { isSignUp } = useSelector(getAuth);
  const profileUser = useSelector(getProfileUser);
  const role = roleUser();
  let tab = getURLSearchParam(location?.search, 'tab');
  const [currentToken, setCurrentToken] = useState(0);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenMenuSearch, setIsOpenMenuSearch] = useState(false);
  const [isOpenMenuSubSearch, setIsOpenMenuSubSearch] = useState(true);
  const test = useSelector((state) => state);

  const [selectedTabId, setSelectedTabId] = useState(
    localStorage.getItem('selectedTabId') || 'tab1'
  );
  const openAndCloseMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  useEffect(() => {
    dispatch(selectTab(-1));
    dispatch(
      payPalGetCodeHistoryRequest({
        startDayUtc: '01-01-1901',
        endDayUtc: '01-01-2100'
      })
    );
    dispatch(getRemotePerConfRequest());
    // dispatch(getRemotePerScheduleRequest());
    // dispatch(getRemotePerStatusRequest());
    dispatch(getRemotePerStatusRequest());
    dispatch(getProfileRequest());
  }, [dispatch]);
  const openAndCloseMenuSearch = () => {
    setIsOpenMenuSearch(!isOpenMenuSearch);
    setIsOpenMenuSubSearch(!isOpenMenuSubSearch);
  };
  const [tabIndex, setTabIndex] = useState(
    tab
      ? TABS[tab]
      : role === 'Producer'
      ? TABS.schedulePerformance
      : TABS.accountSettings
  );
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };
  // const breakpoint = 500;
  const smallMoileBreakpoint = 479;
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  useEffect(() => {
    if (window.location.search !== '') {
      dispatch(selectTab(5));
      setTimeout(() => {
        history.push('/profile');
      }, 2000);
    }
  });
  useEffect(() => {
    if (tab) {
      setTabIndex(TABS[tab] || 6);
    }
  }, [tab]);

  useEffect(() => {
    dispatch(
      selectTab(isNaN(parseInt(selectedTabId)) ? -1 : parseInt(selectedTabId))
    );
  }, []);
  const getUser = useMemo(() => {
    return profileUser || JSON.parse(localStorage.getItem('profile_user'));
  }, [profileUser]);
  // Token dependency
  useEffect(() => {
    if (
      user?.auth?.currentUser !== undefined &&
      getUserByToken(user?.auth?.currentUser)?.exp <
        Number(String(Date.now() / 1000).slice(0, 10))
    ) {
      userService.updateUserShowConnection({
        showId: '-1',
        disconnect: true
      });
    }
  }, [dispatch]);
  useEffect(() => {
    document.body.scrollIntoView({
      behavior: 'smooth'
    });
  });
  const initGenres = useMemo(() => {
    return genres.filter((element) =>
      getUser?.genre
        .split(',')
        .map((item) => item.toLowerCase())
        .includes(element.value)
    );
  }, [getUser]);
  const handleToggleModal = (value) => {
    dispatch(registerSuccess(value));
  };
  const handleChangeTab = useCallback((event, tabIdx) => {
    event.preventDefault();
    setTabIndex(tabIdx);
  }, []);
  const openModal = () => {
    return (
      <Modal show={isSignUp} onHide={() => handleToggleModal(false)}>
        <Modal.Header closeButton>
          <div className="logoContainer">
            <Logo logo={logo} />
            <Modal.Title>Welcome To Frisson!</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          {/* <p style={{ textAlign: 'center' }}>
            Please upload a profile picture showing your best festival self.
            Then feel free to browse live virtual shows and join the crowd!
          </p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <AvatarUpload getUser={profileUser} buttonColor={buttonColor} />
          </div> */}
          <p
            style={{
              textAlign: 'center',
              marginTop: '-25px',
              fontStyle: 'italic'
            }}
          >
            Live, interactive virtual music festivals for everyone
          </p>
          <p style={{ textAlign: 'center', paddingTop: '20px' }}>
            Party with like-minded people across the globe. Show your support
            for your favorite artists. Make the moment unforgettable by
            launching fireworks during everyone's favorite song. Our mission is
            to deliver those thrilling experiences that give you chills of
            excitement.
          </p>
          <p style={{ textAlign: 'center', paddingTop: '20px' }}>
            Let's jump right into your first show with Crowd Chat! You can end
            the tutorial at any time by clicking the exit button in the
            top-right corner.
          </p>
          {/* <Link to={'/dressing-room?tutorial=1'} class='block-avatar-upload avatar-button-upload'
                style={{ backgroundColor: 'rgb(255, 110, 9)', maxWidth: '36%', margin: '0 auto', color: 'white' }}>Begin
            tutorial
          </Link> */}
          <Link
            onClick={() => handleToggleModal(false)}
            to={process.env.PUBLIC_URL + '/jointhecrowd?id=tutorialShow&tutorial=1'}
            className="buttonContainer"
            style={{ width: '270px', margin: '30px auto 0', cursor: 'pointer' }}
          >
            <div className="button">
              <div className="top-menu-item-modern a">
                <svg style={{ width: '30px', height: '30px' }}>
                  <use href={sprite + '#evenodd'}></use>
                </svg>
              </div>
            </div>
            <div className="nameContainer">
              <span style={{ color: 'white', fontSize: '15px' }}>
                Join Crowd Chat
              </span>
            </div>
          </Link>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <div className="body-background-profile">
      {role === 'Producer' && tabik.tabs === TABS.youtubeTwitch && (
        <div className="body-plug">
          <div className="body-plug-text">
            <p>This page cannot be viewed on mobile.</p>
          </div>
        </div>
      )}
      <Fragment>
        {isSignUp && openModal()}
        <Helmet>
          <title>{`${frisson.title}`}</title>
        </Helmet>
        <LayoutDefault className="template-color-1 template-font-2 bg_color--3">
          <HeaderContainer logoVersion={'light'} className="mb--130">
            <div className="header-left flex-20">
              <Logo logo={logo} />
            </div>
            <div
              className="header-flex-left flex-80 header-menu-burger"
              style={{ paddingRight: '10px' }}
            >
              {isOpenMenu === false ? (
                isOpenMenuSearch ? (
                  <svg
                    fill="#fff"
                    style={{ width: '18px', height: '18px' }}
                    onClick={openAndCloseMenuSearch}
                  >
                    <use href={sprite + '#close_x'}></use>
                  </svg>
                ) : (
                  <svg
                    fill="#fff"
                    style={{ width: '18px', height: '18px' }}
                    onClick={() => {
                      setIsOpenMenuSearch(!isOpenMenuSearch);
                    }}
                  >
                    <use href={sprite + '#icon_search'}></use>
                  </svg>
                )
              ) : (
                ''
              )}
              {isOpenMenuSearch === false ? (
                isOpenMenu ? (
                  <svg
                    fill="#fff"
                    style={{ width: '18px', height: '18px' }}
                    onClick={openAndCloseMenu}
                  >
                    <use href={sprite + '#close_x'}></use>
                  </svg>
                ) : (
                  <svg
                    fill="#fff"
                    style={{ width: '18px', height: '18px' }}
                    onClick={openAndCloseMenu}
                  >
                    <use href={sprite + '#menu_gam'}></use>
                  </svg>
                )
              ) : (
                ''
              )}
            </div>
          </HeaderContainer>
          <div>
            <DropDownButton
              isShow={isOpenMenu}
              openAndCloseMenu={openAndCloseMenu}
            />
            <DropDownSearchMenu
              isShowSearchMenu={isOpenMenuSearch}
              isOpenMenuSubSearch={isOpenMenuSubSearch}
            />
          </div>
          <Container
            style={{ position: 'relative', zIndex: '2' }}
            className={classNames('mt--50', 'container-class', {
              'max-width-inherit': width <= smallMoileBreakpoint
            })}
            fluid="xll"
          >
            <Row>
              <Col xs={12} md={12} lg={4} xl={3}>
                <ProfileUser
                  getUser={getUser}
                  tabIndex={tabIndex}
                  onChangeTab={handleChangeTab}
                />
              </Col>
              <Col xs={12} md={12} lg={8} xl={9}>
                <div
                  className={`content-edit-profile-right w-100 ${
                    tabik.tabs === TABS.youtubeTwitch ? 'youtubeTwitch' : ''
                  }`}
                  // style={{
                  //   backgroundColor: tabik.tabs === 0 && 'transparent',
                  //   backdropFilter: tabik.tabs === 0 && 'unset'
                  // }}
                >
                  {tabik.tabs === TABS.userName || tabik.tabs === -1 ? (
                    <div style={{ overflowX: 'hidden' }}>
                      <AvatarUserTab user={getUser} />
                    </div>
                  ) : (
                    ''
                  )}
                  {tabik.tabs === TABS.watchList && (
                    <div style={{ overflowX: 'hidden' }}>
                      <WatchList getUser={getUser} />
                    </div>
                  )}
                  {role === 'Producer' &&
                    tabik.tabs === TABS.schedulePerformance && (
                      <div className="w-100">
                        <SchedulePerformance />
                      </div>
                    )}
                  {tabik.tabs === TABS.friends && (
                    <div className="w-100">
                      <FriendsList />
                    </div>
                  )}
                  {tabik.tabs === TABS.accountSettings && (
                    <div>
                      <AccountSetting
                        getUser={getUser}
                        initGenres={initGenres}
                      />
                    </div>
                  )}
                  {role === 'Producer' && tabik.tabs === TABS.earningTab && (
                    <div>
                      <EarningContainer />
                    </div>
                  )}
                  {role === 'Producer' && tabik.tabs === TABS.youtubeTwitch && (
                    <div className="w-100">
                      <AddConnection profileUser={getUser} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
          <Footer />
        </LayoutDefault>
      </Fragment>
    </div>
  );
};
export default EditProfile;
