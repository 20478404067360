import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SlickSlider from '../../components/slick';
import BoxEffect from '../../components/box-effect';
import dataFake from '../../data/genres';
import { isEmpty } from 'lodash';
import { typeGenre } from '../../selectors/genres.selector';
import { genres } from '../../shared/defaultValue';
import { getGenres } from '../../selectors/genres.selector';
import { groupByGenres } from '../../shared/utils/helpers';
import './styles.scss';

const SuggestedShows = () => {
  const { genre } = useSelector(typeGenre);
  const [hover, setHover] = useState({});
  const sliderRef = useRef();
  const { data } = useSelector(getGenres);
  const indexSlider = useMemo(() => {
    return genres.findIndex((x) => x.value === genre.value) + 1;
  }, [genre.value]);
  const groupData = useMemo(() => {
    if (isEmpty(data)) return [];
    return groupByGenres(data);
  }, [data]);
  const modifyData = useMemo(() => {
    if (isEmpty(dataFake)) return [];
    const keys = Object.keys(groupData);
    return dataFake?.map((item) => {
      if (keys.includes(item.value)) {
        return {
          ...item,
          total: groupData[item.value]
        };
      }
      return { ...item };
    });
  }, [groupData]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: true,
    customPaging: function(i) {
      return (
        <>{!isEmpty(hover) ? <div className='custom-dots-slick' /> : null}</>
      );
    },
    dotsClass: 'slick-dots slick-background'
  };
  useEffect(() => {
    if (indexSlider === 5) {
      sliderRef.current.slickGoTo(indexSlider - 4);
    } else if (indexSlider === 1) {
      sliderRef.current.slickGoTo(indexSlider + 2);
    } else if (indexSlider === 3) {
      sliderRef.current.slickGoTo(indexSlider - 2);
    } else {
      sliderRef.current.slickGoTo(indexSlider - 1);
    }
  }, [genre, indexSlider]);
  const renderSlides = useMemo(
    () => (
      <div className='wrapper-suggested-shows'>
        <SlickSlider settings={settings} sliderRef={sliderRef}>
          {modifyData?.map((item, index) => {
            return (
              <BoxEffect
                key={index}
                item={item}
                isHover={item ? item?.value === hover.value : false}
                isActive={item ? item?.value === genre.value : false}
                setHover={setHover}
              />
            );
          })}
        </SlickSlider>
      </div>
    ),
    [genre.value, hover.value, modifyData, settings]
  );
  return <>{renderSlides}</>;
};
export default SuggestedShows;
