import * as yup from "yup";

export const ValidationAddFriendsSchema = yup.object().shape({
  friendIds: yup
    .array()
    .required("Required")
    .test(
      "friendIds",
      'Email or username is required',
      (data) => data.length > 0
    )
});
