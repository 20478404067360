import React from "react";

import { Container, Row, Col, Image } from "react-bootstrap";
import DefaultAvatar from "./DefaultAvatar";

import "./styles.scss";

const RequestedListItem = ({ data }) => {
  return (
    <Container>
      <Row className="wrapper-friends-list-item">
        <Col xs={4} sm={2}>
          {data?.avatarUrl ? (
            <Image src={data.avatarUrl} roundedCircle />
          ) : (
            <DefaultAvatar name={data?.displayName} />
          )}
        </Col>
        <Col xs={6} sm={10}>
          {data?.displayName}
        </Col>
      </Row>
    </Container>
  );
};

export default RequestedListItem;
