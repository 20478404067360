import { AppConfig } from 'configs';
import API from './api/api';
import ScheduledPerformancesAPI from '../services/SearchSchedule';

class SchedulePerformanceAPI {
  constructor() {
    this.requestUrl = `${AppConfig.apiURL}/schedules`;
  }

  createFormSheduledPerformance = async (payload) => {
    const { updateThumbnail, ...data } = payload;
    const res = await API.post(`${this.requestUrl}/create`, data);
    const formData = new FormData();
    formData.append('file', updateThumbnail);
    await ScheduledPerformancesAPI.updateThumbnail(formData, res?.data?.scheduledPerformanceId)
    console.log('res?.data---->', res?.data);
    return res;
  };

  updateSheduledPerformance = async (payload) => {
    const {
      genre,
      performanceName,
      runtime,
      startDatetime,
      superAdmins,
      targetArtistAccountId,
      status,
      description,
      updateThumbnail
    } = payload;
    const newPayload = {
      genre,
      performanceName,
      runtime,
      startDatetime,
      superAdmins,
      targetArtistAccountId,
      status,
      description,
    };

    const res = API.put(`${this.requestUrl}/update/${payload?.scheduledPerformanceId}`, newPayload);

    const formData = new FormData();
    formData.append('file', updateThumbnail);
    await ScheduledPerformancesAPI.updateThumbnail(formData, payload?.scheduledPerformanceId);
    console.log('res?.data---->', res);
    return res;
  };
  deleteSheduledPerformance = async (payload) => {
    return API.delete(`${this.requestUrl}/delete/${payload}`);
  };
  searchSheduledPerformance = async (payload) => {
    return API.post(`${this.requestUrl}/search`, payload, {
      withoutAuth: true
    });
  };
  getSheduledById = async (id) => {
    return API.get(`${this.requestUrl}/getById/${id}`);
  };
  getRemoveTargetArtist = async (id) => {
    return API.post(`${this.requestUrl}/${id}/remove-target-artist`);
  }
}

export default new SchedulePerformanceAPI();
