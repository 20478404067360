import React, { useEffect } from "react";

import "./styles.scss";

export const SpinnerOverlay = ({ loading }) => {
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
      return;
    }
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
  }, [loading]);

  return (
    <>
      {loading ? (
        <div className="sk-overlay">
          <div className="sk-circle">
            <div className="sk-circle1 sk-child" />
            <div className="sk-circle2 sk-child" />
            <div className="sk-circle3 sk-child" />
            <div className="sk-circle4 sk-child" />
            <div className="sk-circle5 sk-child" />
            <div className="sk-circle6 sk-child" />
            <div className="sk-circle7 sk-child" />
            <div className="sk-circle8 sk-child" />
            <div className="sk-circle9 sk-child" />
            <div className="sk-circle10 sk-child" />
            <div className="sk-circle11 sk-child" />
            <div className="sk-circle12 sk-child" />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
