import React, {Fragment} from "react";
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.min.css';
const $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('bootstrap/dist/js/bootstrap.min');

class FinishProducerSetup extends React.Component {

    state = {
        page: null
    };

    componentDidMount() {

        fetch("CrowdChatV1/finishproducersetup.html")
            .then(result => {
                return result.text();
            })
            .then(page => {
                this.setState(
                    {
                        page: { __html: page }
                    }
                );
            });
    }

    render() {
        const { page } = this.state;
        return (
            <Fragment>
                <Helmet>
                    <title>Frisson - Producer Setup</title>
                    <script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-beta.1/dist/js/select2.min.js"/>
                    <script src="https://www.frisson.live/CrowdChatV1/js/finishSetup.js"/>
                </Helmet>
                <div
                    dangerouslySetInnerHTML={page && page}
                />
            </Fragment>
        );
    }

}

export default FinishProducerSetup;
