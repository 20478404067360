import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  friendsList: undefined,
  error: "",
  loading: false,
  success: false
};

const friendsListSlice = createSlice({
  name: "friends",
  initialState,
  reducers: {
    getFriendsListRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getFriendsListSuccess: (state, { payload }) => {
      return {
        ...state,
        friendsList: payload,
        loading: false,
        success: true
      };
    },
    getFriendsListError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    requestFriendRequest: (state) => {
      return {
        ...state,
        loading: true,
        success: false
      };
    },
    requestFriendSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true
      };
    },
    requestFriendError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    acceptFriendRequestRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    acceptFriendRequestSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true
      };
    },
    acceptFriendRequestError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    declineFriendRequestRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    declineFriendRequestSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true
      };
    },
    declineFriendRequestError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    resetState: () => {
      return { ...initialState };
    }
  }
});

export const {
  getFriendsListRequest,
  getFriendsListSuccess,
  getFriendsListError,
  requestFriendRequest,
  requestFriendSuccess,
  requestFriendError,
  acceptFriendRequestRequest,
  acceptFriendRequestSuccess,
  acceptFriendRequestError,
  declineFriendRequestRequest,
  declineFriendRequestSuccess,
  declineFriendRequestError,
  resetState
} = friendsListSlice.actions;

export default friendsListSlice.reducer;
