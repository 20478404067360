import React from 'react';
import './styles.scss';
import search from '../../assets/svg/sprite.svg';

const SearchInput = ({ customClass = '', icon, onChange, value }) => {
  return (
    <div className={`search-input ${customClass}`}>
      {/*<div className="icon">{icon}</div>*/}
      <svg className='icon' style={{ width: '24px', height: '24px' }}>
        <use href={search + '#search'} />
      </svg>
      <input type='text' value={value} placeholder='Search watch list' onChange={onChange} />
    </div>
  );
};
export default SearchInput;
