import { call, put, all, takeLatest } from 'redux-saga/effects';
import {
  createFormPerformacneRequest,
  createFormPerformacneSuccess,
  createFormPerformacneError,
  getSchedulePerformanceRequest,
  getSchedulePerformanceSuccess,
  getSchedulePerformanceError,
  updateFormPerformanceRequest,
  updateFormPerformanceSuccess,
  updateFormPerformanceError,
  getScheduleByIdRequest,
  getScheduleByIdSuccess,
  getScheduleByIdError,
  deleteFormPerformanceSuccess,
  deleteFormPerformanceRequest,
  addToPlayListSchedulePerfomancesSuccess,
  addToPlayListSchedulePerfomancesError,
  addToPlayListSchedulePerfomancesRequest,
  getRemoveTargetArtistRequest,
  getRemoveTargetArtistSuccess,
  getRemoveTargetArtistError
} from '../slices/schedulePerformance';
import { toastConfig } from '../shared/defaultValue';
import schedulePerformanceService from '../services/SchedulePerformanceAPI';
import accountApi from '../services/Account';
import moment from 'moment';
import toast from 'react-hot-toast';

function* formSchedulePerformance({ payload }) {
  try {
    const result = yield call(
      schedulePerformanceService.createFormSheduledPerformance,
      payload
    );
    if (result.success) {
      yield put(createFormPerformacneSuccess(true));
      toast.success('Create schedule performance succesfull', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(createFormPerformacneError(result.message));
    }
  } catch (error) {
    yield put(createFormPerformacneError(error.message));
  }
}

function* searchSchedulePerformance({ payload }) {
  try {
    const result = yield call(
      schedulePerformanceService.searchSheduledPerformance,
      payload
    );
    if (result.success) {
      const modifyData = result?.data?.records.map((obj) => {
        return {
          ...obj,
          groupId: obj?.scheduledPerformanceId,
          start: moment.utc(obj?.startDatetime.slice(0, 19)).local().format(),
          className: ['event', 'courseEvent']
        };
      });
      yield put(getSchedulePerformanceSuccess(modifyData));
    } else {
      toast.error(result.message, toastConfig);
      yield put(getSchedulePerformanceError(result.message));
    }
  } catch (error) {
    yield put(getSchedulePerformanceError(error.message));
  }
}

function* formUpdateSchedulePerformance({ payload }) {
  try {
    const result = yield call(
      schedulePerformanceService.updateSheduledPerformance,
      payload
    );
    if (result.success) {
      yield put(updateFormPerformanceSuccess(true));
      toast.success('update schedule performance succesfull', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(updateFormPerformanceError(result.message));
    }
  } catch (error) {
    yield put(updateFormPerformanceError(error.message));
  }
}

function* formDeleteSchedulePerformance({ payload }) {
  try {
    const result = yield call(
      schedulePerformanceService.deleteSheduledPerformance,
      payload
    );
    if (result.success) {
      yield put(deleteFormPerformanceSuccess(true));
      toast.success('delete schedule performance succesfull', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      // yield put(updateFormPerformanceError(result.message));
    }
  } catch (error) {
    // yield put(updateFormPerformanceError(error.message));
  }
}

function* formScheduleByID({ payload }) {
  try {
    const result = yield call(
      schedulePerformanceService.getSheduledById,
      payload
    );
    if (result.success) {
      yield put(getScheduleByIdSuccess(result?.data));
    } else {
      toast.error(result.message, toastConfig);
      yield put(getScheduleByIdError(result.message));
    }
  } catch (error) {
    yield put(getScheduleByIdError(error.message));
  }
}

function* removeTargetArtist({ payload }) {
  try {
    const result = yield call(
      schedulePerformanceService.getRemoveTargetArtist,
      payload
    );
    if (result.success) {
      yield put(getRemoveTargetArtistSuccess(result?.data));
      toast.success('Target artist should be removed from the SP', toastConfig);
    } else {
      toast.error(result.message, toastConfig);
      yield put(getRemoveTargetArtistError(result.message));
    }
  } catch (error) {
    yield put(getRemoveTargetArtistError(error.message));
  }
}

function* addToPlayListSchedulePerformance({ payload }) {
  try {
    const result = yield call(
      accountApi.addToPlayListSheduledPerformance,
      payload
    );
    if (result.success) {
      yield put(addToPlayListSchedulePerfomancesSuccess(result?.data));
    } else {
      toast.error(result.message, toastConfig);
      yield put(addToPlayListSchedulePerfomancesError(result.message));
    }
  } catch (error) {
    yield put(addToPlayListSchedulePerfomancesError(error.message));
  }
}

export function* schedulePerformance() {
  yield all([
    takeLatest(createFormPerformacneRequest.type, formSchedulePerformance),
    takeLatest(getSchedulePerformanceRequest.type, searchSchedulePerformance),
    takeLatest(
      updateFormPerformanceRequest.type,
      formUpdateSchedulePerformance
    ),
    takeLatest(
      deleteFormPerformanceRequest.type,
      formDeleteSchedulePerformance
    ),
    takeLatest(getScheduleByIdRequest.type, formScheduleByID),
    takeLatest(
      addToPlayListSchedulePerfomancesRequest.type,
      addToPlayListSchedulePerformance
    ),
    takeLatest(getRemoveTargetArtistRequest.type, removeTargetArtist),
  ]);
}
