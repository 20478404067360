import React from "react"

const VibesUI_Header = (props) => {
    const [showSupportInfo, setShowSupportInfo] = React.useState(false)

    return (
        <div className="vibesUI--header">
            <div className="close fa fa-times-circle" onClick={() => {
                props.setCcEvent("")
                document.getElementById('vibesToggle').classList.remove('selected')
                document.getElementById('vibesMenu').classList.add("hidden")
                }}></div>
            <h1 className="vibesUI--title animate-feedback">Send Vibes</h1>
            <p className="vibesUI--subhead">Show your support <span onMouseEnter={() => {setShowSupportInfo(true)}} onMouseLeave={() => {setShowSupportInfo(false)}}>i</span></p>
            {showSupportInfo && <div className="vibesUI--support_info">
                <p className="vibesUI--info_tooltip">Interact with the performer and show your support by sending them good vibes! 90%+ of vibes sent go directly to the performance contributors.</p>
            </div>}
        </div>
    )
}
export default VibesUI_Header