import React from "react";
import { redirectPage } from "../../shared/utils/helpers";
import "./styles.scss";
const ButtonVideo = ({ size, videoId }) => {
  return (
    <div className={`button-video__custom ${videoId}`}>
      <button className="play__btn" onClick={(e) => redirectPage(e, videoId)}>
        <div className={`icon-gradient ${videoId}`}>
          <div className={`video-icon`} />
        </div>
      </button>
    </div>
  );
};

export default ButtonVideo;
