import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import Parallax, { Layer } from "react-parallax-scroll";
import CallContentVertical from "../../../components/call-to-action/CallContentVertical";
import ContactForm from "../../../components/forms/ContactFormOne";
import { isSuccess } from "../../../selectors/user.selector";
import { useSelector, useDispatch } from "react-redux";
import { submissionContactSuccess } from "../../../slices/user";
function SignUpModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.content}</Modal.Body>
    </Modal>
  );
}

const CallToActionVerticalThree = ({
  bgColor,
  bgImage,
  overlayColor,
  overlayOpacity
}) => {
  const [modalContactShow, setContactModalShow] = useState(false);
  const success = useSelector(isSuccess);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!success) return;
    setContactModalShow(false);
    dispatch(submissionContactSuccess(false));
  }, [dispatch, modalContactShow, success]);

  return (
    <Fragment>
      <Parallax>
        <Layer
          settings={{ speed: 0.4, type: "backgroundY" }}
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
          }}
        >
          <div
            className="frisson-call-to-action ptb--20 ptb-md--80 ptb-sm--60 position-relative rounded"
            style={{ backgroundColor: bgColor }}
          >
            <div
              className="overlay"
              style={{ backgroundColor: overlayColor, opacity: overlayOpacity }}
            />
            <Container>
              <Row className="text-left">
                <Col xs={12}>
                  <CallContentVertical
                    heading={"Get In Touch"}
                    headingColor="#fff"
                    description="Frisson is made for fans and artists, not special interest groups or big-money investors. That means your feedback and ideas are critical to help us improve your experience. Drop us a line with your thoughts!"
                    btnText="Contact Us"
                    btnClasses="fr-btn-purple text-theme btn-xs-size btn-bullet"
                    onClick={() => setContactModalShow(true)}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Layer>
      </Parallax>
      <SignUpModal
        show={modalContactShow}
        onHide={() => setContactModalShow(false)}
        title={
          <h4 className="heading heading-h4 text-center theme-color">
            Contact Us
          </h4>
        }
        content={<ContactForm />}
      />
    </Fragment>
  );
};

export default CallToActionVerticalThree;
