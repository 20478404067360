import React, { useState, Fragment, useCallback, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
// component
import SearchInput from '../../components/search-input';
import WatchListItem from '../../components/watch-list/WatchListItem';
import DeleteWatchListItemModal from '../../components/watch-list/DeleteWatchListItemModal';
import { SpinnerOverlay } from '../../components/spinner-overlay';
// import { setTimeOut, clearTimeOut } from '../../helper/setTimeOut';
import './styles.scss';
import WatchListAuthor from '../../components/watch-list-author/WatchListAuthor';
import {
  getRemotePerConfRequest,
  getRemoveWatchListRequest,
  getTargetArtistsRequest,
  getWatchListRequest,
  getWatchListSuccess,
  setRemoveUserWatchListRequest,
  setSearchWatchListRequest
} from '../../slices/user';
import { useDispatch, useSelector } from 'react-redux';
import { getTargetArtist, getWatchList } from '../../selectors/user.selector';
import icons from '../../constants/icon';
import { roleUser } from '../../shared/utils/helpers';
import DeleteWatchListArtistModal from '../../components/watch-list/DeleteWatchListArtistModal';
import close from '../../assets/svg/sprite.svg';


const newItem = {
  "PlayFabId": "all",
  "DispalyName": "All",
  "AvatarUrl": icons?.allUsersImage
};

const WatchList = () => {
  const watchlistNew = useSelector(getWatchList);
  const targetArtist = useSelector(getTargetArtist);
  const [openModal, setOpenModal] = useState('');
  const [openModalRemoveUser, setOpenModalRemoveUser] = useState('');
  const artistArr = targetArtist?.length > 0 ? [newItem, ...targetArtist] : [];
  const dispatch = useDispatch();
  const [active, setActive] = useState(null);
  const [valueInput, setValueInput] = useState('')

  useEffect(() => {
    dispatch(getWatchListRequest(''));
    dispatch(getTargetArtistsRequest());

  }, [dispatch]);

  useEffect(() => {
    artistArr?.length > 0 ? setActive(artistArr[0]) : setActive(null);
  }, [targetArtist]);

  useEffect(() => {
    if (!valueInput) {
      return false;
    }
    console.log('valueInput--->', valueInput.lenght );
    const getData = setTimeout(() => {
      dispatch(setSearchWatchListRequest(valueInput));
    }, 2000)
    return () => {
      clearTimeout(getData)
    }
  }, [valueInput])




  const handleRemoveItem = () => {
    dispatch(getRemoveWatchListRequest(openModal));
  };

  const handleRemoveUserItem = () => {
    dispatch(setRemoveUserWatchListRequest(openModalRemoveUser));
  };

  const onChangeFilter = (e) => {
    let value = e;
    if (value === 'all'){
      dispatch(getWatchListRequest(''));
      return false;
    }
    dispatch(getWatchListRequest(value));
  }

  const clearInput = () => {
    console.log('1');
    setValueInput('');
    dispatch(setSearchWatchListRequest(''));
  }
  const handleCloseDeleteModal = useCallback(() => setOpenModal(''), []);
  const handleOpenDeleteModal = useCallback((id) => setOpenModal(id), []);
  const handleRemoveUserCloseDeleteModal = useCallback(() => setOpenModalRemoveUser(''), []);
  const handleRemoveUserOpenDeleteModal = useCallback((id) => setOpenModalRemoveUser(id), []);

  return (
    <Fragment>
      {openModal && (
        <DeleteWatchListItemModal
          openModal={openModal}
          onRemoveItem={handleRemoveItem}
          onCloseModal={handleCloseDeleteModal}
        />
      )}
      {openModalRemoveUser && (
        <DeleteWatchListArtistModal
          openModal={openModalRemoveUser}
          nameUser={artistArr?.find((e) => e?.PlayFabId === openModalRemoveUser)}
          onRemoveItem={handleRemoveUserItem}
          onCloseModal={handleRemoveUserCloseDeleteModal}
        />
      )}
      <SpinnerOverlay loading={false} />
      <div className="wrapper-watch-list">
        <Row>
          <Col className="wrapper-title" xs={12} md={6}>
            <div className="title">Watch List</div>
          </Col>
          <Col className="search-wrap" xs={12} md={6} style={{ margin: '20px 0 40px 0' }}>
            <SearchInput
              value={valueInput}
              onChange={event => setValueInput(event.target.value)}
              icon={<i className="fa fa-search" />}
            />
            <Col className='action'>
              `<svg onClick={() => clearInput()}>
                <use href={close + '#close'} />
              </svg>`
            </Col>
          </Col>
          <div className="wrapper-list-author">
            {artistArr?.length > 0
              ? artistArr?.map((item) => (
                <WatchListAuthor
                  key={item?.PlayFabId}
                  data={item}
                  onChange={(value) => {
                      onChangeFilter(value);
                      setActive(item)
                  }}
                  onRemoveItem={handleRemoveUserOpenDeleteModal}
                  activeClass={active === item}
                />
              ))
              : ''}
          </div>
          <Col xs={12}>
            {watchlistNew?.length > 0
              ? watchlistNew?.map((item) => (
                  <WatchListItem
                    key={item?.scheduledPerformanceId}
                    data={item}
                    onRemoveItem={handleOpenDeleteModal}
                  />
                ))
              : 'No watch list'}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};
export default WatchList;
