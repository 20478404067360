import { AppConfig } from 'configs';
import API from './api/api';

class UserAPI {
  constructor() {
    this.requestUrl = `${AppConfig.apiURL}/users`;
  }

  uploadAvatar(payload) {
    return API.post(`${this.requestUrl}/uploadAvatar`, payload, {
      isMultipart: true
    });
  }

  uploadThumbnail(payload) {
    return API.post(`${this.requestUrl}/updateStreamThumbnail`, payload, {
      isMultipart: true
    });
  }

  uploadThumbnailBanner(payload) {
    return API.post(`${this.requestUrl}/updateStreamBanner`, payload, {
      isMultipart: true
    });
  }

  updateProfile(payload) {
    return API.put(`${AppConfig.apiURL}/users/updateProfile`, payload, {
      isMultipart: false
    });
  }

  getProfileUser() {
    return API.get(`${AppConfig.apiURL}/users/profile`);
  }

  updateAccountPreferences(payload) {
    return API.post(`${AppConfig.apiURL}/users/approve`, payload, {
      isMultipart: false
    });
  }

  deAuthorizeAccount(payload) {
    return API.post(`${AppConfig.apiURL}/users/deauthorize`, payload, {
      isMultipart: false
    });
  }

  loginUser(payload) {
    return API.post(`${AppConfig.apiURL}/users/login`, payload);
  }

  submitGoogleSheet(payload) {
    return API.post(`${AppConfig.apiURL}/leads/add-lead`, payload, {
      isMultipart: false
    });
  }

  acceptBuildAgreement(payload) {
    return API.post(`${AppConfig.apiURL}/users/acceptAgreement`, payload, {
      isMultipart: false
    });
  }

  getGuestToken() {
    return API.get(`${AppConfig.apiURL}/users/guest`);
  }

  getRemotePerformanceDataApi() {
    return API.get(`${AppConfig.apiURL}/users/getRemotePerformanceData`);
  }

  getRemotePerScheduleApi() {
    return API.get(`${AppConfig.apiURL}/users/getRemotePerformanceSchedule`);
  }

  getRemotePerStatusApi() {
    return API.get(`${AppConfig.apiURL}/users/getPerformanceStatus`);
  }

  getRemotePerConfigApi() {
    return API.get(`${AppConfig.apiURL}/users/getRemotePerformanceConfig`);
  }

  streamShutdown(payload) {
    return API.post(`${AppConfig.apiURL}/stream/shutdown`, payload, {
      isMultipart: false
    });
  }

  streamRestart(payload) {
    return API.post(`${AppConfig.apiURL}/stream/restart`, payload, {
      isMultipart: false
    });
  }

  submissionEmail(payload) {
    return API.post(`${AppConfig.apiURL}/contacts/submission`, payload, {
      isMultipart: false
    });
  }

  submissionContact(payload) {
    return API.post(`${AppConfig.apiURL}/contacts/submission`, payload, {
      isMultipart: false
    });
  }

  submissionUserUpdRemPerSchedule(payload) {
    return API.post(
      `${AppConfig.apiURL}/users/updateRemotePerformanceSchedule`,
      payload,
      {
        isMultipart: false
      }
    );
  }

  submissionUserUpdRemPerStatus(payload) {
    return API.post(
      `${AppConfig.apiURL}/users/updatePerformanceStatus`,
      payload,
      {
        isMultipart: false
      }
    );
  }

  submissionUserUpdRemPerConfig(payload) {
    return API.post(
      `${AppConfig.apiURL}/users/updateRemotePerformanceConfig`,
      payload,
      {
        isMultipart: false
      }
    );
  }

  updateUserShowConnection(payload) {
    if (payload.currentUser === undefined) {
      return false;
    }
    return API.put(`${AppConfig.apiURL}/users/update-connection`, payload, {
      isMultipart: false
    });
  }

  setPayPalCode(payload) {
    return API.post(`${AppConfig.apiURL}/users/setPayPalCode`, { code: payload }, {
      isMultipart: false
    });
  }

  getPayoutsHistory(payload) {
    return API.post(`${AppConfig.apiURL}/users/getPayouts`, payload, {
      isMultipart: false
    });
  }

  sendDbAmount(payload) {
    return API.post(`${AppConfig.apiURL}/users/request-payout`, { dbAmount: payload }, {
      isMultipart: false
    });
  }



  getTargetArtists(payload) {
    return API.get(`${AppConfig.apiURL}/users/get-target-artists`, payload, {
      isMultipart: false
    });
  }

  setRemoveWatchList(payload) {
    return API.post(`${AppConfig.apiURL}/users/remove-watch-list`, {
      scheduledPerformanceId:  payload,
    },{
      isMultipart: false
    });
  }

  setRemoveUserWatchList(payload) {
    return API.post(`${AppConfig.apiURL}/users/remove-producer-from-watch-list`, {
      producerPlayfabId:  payload,
    },{
      isMultipart: false
    });
  }

  setAddToWatchList(payload) {
    return API.post(`${AppConfig.apiURL}/users/add-to-watch-list`, {
      scheduledPerformanceId:  payload,
    },{
      isMultipart: false
    });
  }

  setAddArtist(payload) {
    return API.post(`${AppConfig.apiURL}/users/add-producer-to-watch-list`, {
      scheduledPerformanceId:  payload,
    },{
      isMultipart: false
    });
  }

  getUserWatchList(id, payload) {
    return API.get(`${AppConfig.apiURL}/users/get-watch-list?target-artist-id=${id}`, payload, {
      isMultipart: false
    });
  }

  setSearchWatchList(value, payload) {
    return API.get(`${AppConfig.apiURL}/users/get-watch-list?search=${value}`, payload, {
      isMultipart: false
    });
  }

  setUpdatePlayerLocation(payload) {
    return API.post(`${AppConfig.apiURL}/users/update-player-location`, {
      showId:  payload.pageId,
      url:  payload.url,
    },{
      isMultipart: false
    });
  }

  setRefreshToken(payload) {
    return API.post(`${AppConfig.apiURL}/users/refresh-token`, {
      refreshToken: payload,
    })
  }

  setFeedback(payload) {
    return API.post(`${AppConfig.apiURL}/users/feedback`, payload,{
      isMultipart: false
    });
  }

}

export default new UserAPI();
