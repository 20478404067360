import React from "react"
import VibesUI_CustomCategories from "./VibesUI_CustomCategories"
import VibesUI_Order from "./VibesUI_Order"
import VibesUI_Quantity from "./VibesUI_Quantity"
import VibesUI_Timing from "./VibesUI_Timing";
import VibesUI_TypeSelector from "./VibesUI_TypeSelector";
import VibesUI_Color from "./VibesUI_Color";
// import { ccEventData, emojiTypes } from "./vibes_datas";
import { emojiTypes } from "./vibes_datas";
import VibesUI_EmojiSelector from "./VibesUI_EmojiSelector";

export default function VibesUI_Body(props) {
    // const ccEventStates = ccEventData
    const [selectedCategory, setSelectedCategory] = React.useState("")
    const [refresher, setRefresher] = React.useState(0)

    function selectCategory(category) {
        setSelectedCategory(prevCategory => {
            let cat = document.querySelector(`.vibesUI--category_is_${prevCategory}`)
            cat?.classList.remove("selected")
            cat = document.querySelector(`.vibesUI--category_is_${category}`)
            cat.classList.add("selected")
            return category
        })
    }

    function updateQuantity(qty) {
        const tempData = Object.assign({}, props.ccData)
        props.ccData[props.selectedEvent].quantity = qty.toString()
        // let curRef = refresher < 2 ? refresher + 1 : refresher - 1
        // setRefresher(curRef)

        if(qty == 1) {
            updateTiming(0)
        }

        props.setCCData(tempData)
    }

    function updateType(itemType) {
        const tempData = Object.assign({}, props.ccData)

        //find index of type for emoji instead of size name
        props.selectedEvent === 'event_crowdemoji' ? tempData[props.selectedEvent].type = emojiTypes.findIndex(item => item === itemType) + 1  : tempData[props.selectedEvent].type = itemType

        // console.log(ccEventData[props.selectedEvent].type)

        //set text to either type for firework to separate text for emoji
        tempData[props.selectedEvent].typeText = itemType

        props.setCCData(tempData)

        // let curRef = refresher < 2 ? refresher + 1 : refresher - 1
        // setRefresher(curRef)
    }

    function updateEmoji(emoji) {
        const tempData = Object.assign({}, props.ccData)

        if (emoji.hexcode) {
            tempData[props.selectedEvent].text = emoji.hexcode.toLowerCase()
            tempData[props.selectedEvent].emoji = emoji.emoji
            // let curRef = refresher < 2 ? refresher + 1 : refresher - 1
            // setRefresher(curRef)

            props.setCCData(tempData)
        }
        
        // console.log(ccEventData)
    }

    function updateTiming(beatsBetweenLaunches) {
        const tempData = Object.assign({}, props.ccData)
        // console.log("updating up here")
        tempData[props.selectedEvent].timing = beatsBetweenLaunches.toString()
        // let curRef = refresher < 2 ? refresher + 1 : refresher - 1
        // setRefresher(curRef)

        props.setCCData(tempData)
    }

    function updateColor(color) {
        const tempData = Object.assign({}, props.ccData)

        tempData[props.selectedEvent].primaryColor = color
        // let curRef = refresher < 2 ? refresher + 1 : refresher - 1
        // setRefresher(curRef)

        props.setCCData(tempData)
    }

    return (
        <div className="vibesUI--body">
            <button className="vibesUI--back_btn" onClick={() => {props.setCcEvent("")}}>Back</button>
            <h2 className="vibesUI--event_title"></h2>
            <VibesUI_CustomCategories
                eventData={props.ccData}
                selectedEvent={props.selectedEvent}
                selectCategory={selectCategory}
                selectedCategory={selectedCategory}
            />
            <div className="vibesUI--customize_area">
            {selectedCategory === "emoji" && <VibesUI_EmojiSelector selectedEvent={props.selectedEvent} selectedEmoji={updateEmoji} emojiNative={props.ccData[props.selectedEvent].emoji} emojiText={props.ccData[props.selectedEvent].text}/>}
                {selectedCategory === "type" && <VibesUI_TypeSelector selectedEvent={props.selectedEvent} selectedType={updateType} emoji={props.selectedEvent === 'event_crowdemoji'? props.ccData[props.selectedEvent].emoji : ''} itemType={props.ccData[props.selectedEvent].type} color={props.ccData[props.selectedEvent].primaryColor}/>}
                {selectedCategory === "timing" && <VibesUI_Timing updateTiming={updateTiming} timing={props.ccData[props.selectedEvent].timing} qty={props.ccData[props.selectedEvent].quantity}/>}
                {selectedCategory === "quantity" && <VibesUI_Quantity qty={props.ccData[props.selectedEvent].quantity} updateQuantity={updateQuantity}/>}
                {selectedCategory === "color" && <VibesUI_Color displayName={props.displayName} selectedEvent={props.selectedEvent} emoji={props.selectedEvent === 'event_crowdemoji'? props.ccData[props.selectedEvent].emoji : ''} itemType={props.ccData[props.selectedEvent].type} color={props.ccData[props.selectedEvent].primaryColor} updateColor={updateColor}/>}
            </div>
        </div>  
    )
}