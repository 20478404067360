import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './styles.scss';
import { pronouns } from '../../shared/defaultValue';
import Select from 'react-select';
import SelectData from '../select-data';

const CalendarComponent = ({ handleOnChange, closedCalendar }) => {
  const [dateState, setDateState] = useState(new Date());
  const [month, setMonth] = useState([
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    {
      id: 3,
      name: 'March'
    },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    {
      id: 8,
      name: 'August'
    },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    {
      id: 12,
      name: 'December'
    }
  ]);
  const [years, setYears] = useState([
    { id: 1, name: '1917' },
    { id: 2, name: '1918' },
    { id: 3, name: '1919' },
    { id: 4, name: '1920' },
    { id: 5, name: '1921' },
    { id: 6, name: '1922' },
    { id: 7, name: '1923' },
    { id: 8, name: '1924' },
    { id: 9, name: '1925' },
    { id: 10, name: '1926' },
    { id: 11, name: '1927' },
    { id: 12, name: '1928' },
    { id: 13, name: '1929' },
    { id: 14, name: '1930' },
    { id: 15, name: '1931' },
    { id: 16, name: '1932' },
    { id: 17, name: '1933' },
    { id: 18, name: '1934' },
    { id: 19, name: '1935' },
    { id: 20, name: '1936' },
    { id: 21, name: '1937' },
    { id: 22, name: '1938' },
    { id: 23, name: '1939' },
    { id: 24, name: '1940' },
    { id: 25, name: '1941' },
    { id: 26, name: '1942' },
    { id: 27, name: '1943' },
    { id: 28, name: '1944' },
    { id: 29, name: '1945' },
    { id: 30, name: '1946' },
    { id: 31, name: '1947' },
    { id: 32, name: '1948' },
    { id: 33, name: '1949' },
    { id: 34, name: '1950' },
    { id: 35, name: '1951' },
    { id: 36, name: '1952' },
    { id: 37, name: '1953' },
    { id: 38, name: '1954' },
    { id: 39, name: '1955' },
    { id: 40, name: '1956' },
    { id: 41, name: '1957' },
    { id: 42, name: '1958' },
    { id: 43, name: '1959' },
    { id: 44, name: '1960' },
    { id: 45, name: '1961' },
    { id: 46, name: '1962' },
    { id: 47, name: '1963' },
    { id: 48, name: '1964' },
    { id: 49, name: '1965' },
    { id: 50, name: '1966' },
    { id: 51, name: '1967' },
    { id: 52, name: '1968' },
    { id: 53, name: '1969' },
    { id: 54, name: '1970' },
    { id: 55, name: '1971' },
    { id: 56, name: '1972' },
    { id: 57, name: '1973' },
    { id: 58, name: '1974' },
    { id: 59, name: '1975' },
    { id: 60, name: '1976' },
    { id: 61, name: '1977' },
    { id: 62, name: '1978' },
    { id: 63, name: '1979' },
    { id: 64, name: '1980' },
    { id: 65, name: '1981' },
    { id: 66, name: '1982' },
    { id: 67, name: '1983' },
    { id: 68, name: '1984' },
    { id: 69, name: '1985' },
    { id: 70, name: '1986' },
    { id: 71, name: '1987' },
    { id: 72, name: '1988' },
    { id: 73, name: '1989' },
    { id: 74, name: '1990' },
    { id: 75, name: '1991' },
    { id: 76, name: '1992' },
    { id: 77, name: '1993' },
    { id: 78, name: '1994' },
    { id: 79, name: '1995' },
    { id: 80, name: '1996' },
    { id: 81, name: '1997' },
    { id: 82, name: '1998' },
    { id: 83, name: '1999' },
    { id: 84, name: '2000' },
    { id: 85, name: '2001' },
    { id: 86, name: '2002' },
    { id: 87, name: '2003' },
    { id: 88, name: '2004' },
    { id: 89, name: '2005' },
    { id: 90, name: '2006' },
    { id: 91, name: '2007' },
    { id: 92, name: '2008' },
    { id: 93, name: '2009' },
    { id: 94, name: '2010' },
    { id: 95, name: '2011' },
    { id: 96, name: '2012' },
    { id: 97, name: '2013' },
    { id: 98, name: '2014' },
    { id: 99, name: '2015' },
    { id: 100, name: '2016' },
    { id: 101, name: '2017' },
    { id: 102, name: '2018' },
    { id: 103, name: '2019' },
    { id: 104, name: '2020' },
    { id: 105, name: '2021' },
    { id: 106, name: '2022' },
    { id: 107, name: '2023' }
  ]);
  //Change Date Method
  useEffect(() => {
    handleOnChange(dateState);
  }, [dateState]);
  const changeDate = (e) => {
    handleOnChange(
      new Date(
        dateState.getMonth() +
          1 +
          '/' +
          e.getDate() +
          '/' +
          dateState.getFullYear()
      )
    );
    setDateState(
      new Date(
        dateState.getMonth() +
          1 +
          '/' +
          e.getDate() +
          '/' +
          dateState.getFullYear()
      )
    );
  };
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'relative', zIndex: '2' }}>
        <SelectData
          type="Year"
          itemsData={years}
          dateState={dateState}
          setDateState={setDateState}
        />
      </div>
      <div style={{ position: 'relative', zIndex: '1', marginTop: '10px' }}>
        <SelectData
          type="Month"
          itemsData={month}
          dateState={dateState}
          setDateState={setDateState}
        />
      </div>

      <Calendar
        value={dateState}
        onChange={changeDate}
        onClickDay={closedCalendar}
      />
    </div>
  );
};
export default CalendarComponent;
