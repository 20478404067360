import React from 'react';

const Thumbnail = ({className, thumb}) => {
    return (
        <div className={`thumb text-left wow move-up ${className && className}`}>
                <img src={thumb} alt="ctaText"/>
        </div>
    );
};

export default Thumbnail;
