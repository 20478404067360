import React, { useEffect } from "react";
import { useFormik, FormikProvider } from "formik";
import { ValidationAddFriendsSchema } from "../../shared/validationSchema/friendsValidate";
import { useDispatch, useSelector } from "react-redux";

// redux-action
import { requestFriendRequest } from "../../slices/friendsList";
import { isLoading, isSuccess } from "../../selectors/friends.selector";

// layout
import { Col, Container, Row, Modal } from "react-bootstrap";
import Button from "../../components/button";
import TagInput from "../tag-input";

import "./styles.scss";

const AddNewFriendModal = ({ onCloseModal, openModal }) => {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const success = useSelector(isSuccess);

  useEffect(() => {
    if (success) onCloseModal();
  }, [onCloseModal, success]);

  const formik = useFormik({
    validationSchema: ValidationAddFriendsSchema,
    enableReinitialize: true,
    initialValues: {
      friendIds: []
    },
    onSubmit: (values) => {
      dispatch(requestFriendRequest(values));
    }
  });

  const handleCloseModal = () => {
    formik.resetForm();
    onCloseModal();
  };

  return (
    <Modal
      dialogClassName="modal-dialog-friend"
      backdrop="static"
      keyboard={false}
      size="md"
      show={openModal}
      onHide={handleCloseModal}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Friend</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Container>
            <Row>
              <Col xs={12}>
                <div className="form-group">
                  <label className="tag-input-label">Your Friends</label>
                  <TagInput
                    placeholder="Email or username"
                    inline={true}
                    formik={formik}
                    name="friendIds"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </FormikProvider>
      </Modal.Body>
      <Modal.Footer style={{border:'none'}}>
        <Container>
          <Row>
            <Col xs={12}>
              <div className="form-footer-action">

                <button className="add-friend-save" type="button" disabled={loading} onClick={handleCloseModal}>Close</button>
                <button className="add-friend-send" type="button" disabled={loading} onClick={formik.handleSubmit}>Send</button>
                {/*<Button*/}
                {/*  btnText={"Save"}*/}
                {/*  type="button"*/}
                {/*  btnStyle={"rounded"}*/}
                {/*  size={"sd"}*/}
                {/*  color={"tarkis"}*/}
                {/*  disabled={loading}*/}
                {/*  onClick={formik.handleSubmit}*/}
                {/*/>*/}
                {/*<Button*/}
                {/*  customClass="margin-left-10px"*/}
                {/*  btnText={"Close"}*/}
                {/*  type="button"*/}
                {/*  btnStyle={"rounded"}*/}
                {/*  size={"sd"}*/}
                {/*  color={"theme"}*/}
                {/*  disabled={loading}*/}
                {/*  onClick={handleCloseModal}*/}
                {/*/>*/}
              </div>

            </Col></Row></Container>

      </Modal.Footer>
    </Modal>
  );
};

export default AddNewFriendModal;
