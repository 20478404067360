import React, { Fragment } from "react";
import HeaderContainer from "../../container/header/HeaderContainerOne";
import Logo from "./elements/logo";
import logo from "../../assets/img/logo/logo.png";

const Header = () => {
  return (
    <Fragment>
      <HeaderContainer transparent={true} sticky={true} logoVersion={"light"} className="mb--130">
        <div className="header-left flex-20">
          <Logo logo={logo} />
        </div>
        <div className="header-flex-right flex-80"/>
      </HeaderContainer>
    </Fragment>
  );
};

export default Header;
