export const genres = [
  { label: 'Hip hop', value: 'hip hop', content: '56 Shows', img: 'jazz.png' },
  { label: 'Rock', value: 'rock', content: '56 Shows', img: 'rock.png' },
  { label: 'Pop', value: 'pop', content: '56 Shows', img: 'pop.png' },
  { label: 'Rap', value: 'rap', content: '56 Shows', img: 'hiphop.png' },
  { label: 'Indie', value: 'indie', content: '56 Shows', img: 'r&b.png' },
  {
    label: 'Country',
    value: 'country',
    content: '56 Shows',
    img: 'country.png'
  },
  {
    label: 'Electronic / Dance',
    value: 'electronic / dance',
    content: '56 Shows',
    img: 'edm.png'
  },
  {
    label: 'Classical',
    value: 'classical',
    content: '56 Shows',
    img: 'classic.png'
  }
];
export const pronouns = [
  { value: 'he/him', label: 'He/Him' },
  { value: 'she/her', label: 'She/Her' },
  { value: 'they/them', label: 'They/Them' }
];

export const pronounsProducer = [
  { value: 'he/him', label: 'He/Him' },
  { value: 'she/her', label: 'She/Her' },
  { value: 'they/them', label: 'They/Them' },
  { value: 'Ze/Zem', label: 'Ze/Zem' },
  { value: 'No Pronouns', label: 'No Pronouns' }
]

export const timeZone = [
  { value: 'Dateline Standard Time', label: 'Dateline Standard Time' },
  { value: 'UTC-11', label: 'UTC-11' },
  { value: 'Samoa Standard Time', label: 'Samoa Standard Time' },
  { value: 'Hawaiian Standard Time', label: 'Hawaiian Standard Time' },
  { value: 'Alaskan Standard Time', label: 'Alaskan Standard Time' },
  { value: 'Pacific Standard Time (Mexico)', label: 'Pacific Standard Time (Mexico)' },
  { value: 'Pacific Standard Time', label: 'Pacific Standard Time' },
  { value: 'US Mountain Standard Time', label: 'US Mountain Standard Time' },
  { value: 'Mountain Standard Time (Mexico)', label: 'Mountain Standard Time (Mexico)' },
  { value: 'Mountain Standard Time', label: 'Mountain Standard Time' },
  { value: 'Central America Standard Time', label: 'Central America Standard Time' },
  { value: 'Central Standard Time', label: 'Central Standard Time' },
  { value: 'Central Standard Time (Mexico)', label: 'Central Standard Time (Mexico)' },
  { value: 'Canada Central Standard Time', label: 'Canada Central Standard Time' },
  { value: 'SA Pacific Standard Time', label: 'SA Pacific Standard Time' },
  { value: 'Eastern Standard Time', label: 'Eastern Standard Time' },
  { value: 'US Eastern Standard Time', label: 'US Eastern Standard Time' },
  { value: 'Venezuela Standard Time', label: 'Venezuela Standard Time' },
  { value: 'Paraguay Standard Time', label: 'Paraguay Standard Time' },
  { value: 'Atlantic Standard Time', label: 'Atlantic Standard Time' },
  { value: 'Central Brazilian Standard Time', label: 'Central Brazilian Standard Time' },
  { value: 'SA Western Standard Time', label: 'SA Western Standard Time' },
  { value: 'Pacific SA Standard Time', label: 'Pacific SA Standard Time' },
  { value: 'Newfoundland Standard Time', label: 'Newfoundland Standard Time' },
  { value: 'E. South America Standard Time', label: 'E. South America Standard Time' },
  { value: 'Argentina Standard Time', label: 'Argentina Standard Time' },
  { value: 'SA Eastern Standard Time', label: 'SA Eastern Standard Time' },
  { value: 'Greenland Standard Time', label: 'Greenland Standard Time' },
  { value: 'Montevideo Standard Time', label: 'Montevideo Standard Time' },
  { value: 'UTC-02', label: 'UTC-02' },
  { value: 'Mid-Atlantic Standard Time', label: 'Mid-Atlantic Standard Time' },
  { value: 'Azores Standard Time', label: 'Azores Standard Time' },
  { value: 'Cape Verde Standard Time', label: 'Cape Verde Standard Time' },
  { value: 'Morocco Standard Time', label: 'Morocco Standard Time' },
  { value: 'UTC', label: 'UTC' },
  { value: 'GMT Standard Time', label: 'GMT Standard Time' },
  { value: 'Greenwich Standard Time', label: 'Greenwich Standard Time' },
  { value: 'W. Europe Standard Time', label: 'W. Europe Standard Time' },
  { value: 'Central Europe Standard Time', label: 'Central Europe Standard Time' },
  { value: 'Romance Standard Time', label: 'Romance Standard Time' },
  { value: 'Central European Standard Time', label: 'Central European Standard Time' },
  { value: 'W. Central Africa Standard Time', label: 'W. Central Africa Standard Time' },
  { value: 'Namibia Standard Time', label: 'Namibia Standard Time' },
  { value: 'Jordan Standard Time', label: 'Jordan Standard Time' },
  { value: 'GTB Standard Time', label: 'GTB Standard Time' },
  { value: 'Middle East Standard Time', label: 'Middle East Standard Time' },
  { value: 'Egypt Standard Time', label: 'Egypt Standard Time' },
  { value: 'Syria Standard Time', label: 'Syria Standard Time' },
  { value: 'South Africa Standard Time', label: 'South Africa Standard Time' },
  { value: 'FLE Standard Time', label: 'FLE Standard Time' },
  { value: 'Israel Standard Time', label: 'Israel Standard Time' },
  { value: 'E. Europe Standard Time', label: 'E. Europe Standard Time' },
  { value: 'Arabic Standard Time', label: 'Arabic Standard Time' },
  { value: 'Arab Standard Time', label: 'Arab Standard Time' },
  { value: 'Russian Standard Time', label: 'Russian Standard Time' },
  { value: 'E. Africa Standard Time', label: 'E. Africa Standard Time' },
  { value: 'Iran Standard Time', label: 'Iran Standard Time' },
  { value: 'Arabian Standard Time', label: 'Arabian Standard Time' },
  { value: 'Azerbaijan Standard Time', label: 'Azerbaijan Standard Time' },
  { value: 'Mauritius Standard Time', label: 'Mauritius Standard Time' },
  { value: 'Georgian Standard Time', label: 'Georgian Standard Time' },
  { value: 'Caucasus Standard Time', label: 'Caucasus Standard Time' },
  { value: 'Afghanistan Standard Time', label: 'Afghanistan Standard Time' },
  { value: 'Ekaterinburg Standard Time', label: 'Ekaterinburg Standard Time' },
  { value: 'Pakistan Standard Time', label: 'Pakistan Standard Time' },
  { value: 'West Asia Standard Time', label: 'West Asia Standard Time' },
  { value: 'India Standard Time', label: 'India Standard Time' },
  { value: 'Sri Lanka Standard Time', label: 'Sri Lanka Standard Time' },
  { value: 'Nepal Standard Time', label: 'Nepal Standard Time' },
  { value: 'Central Asia Standard Time', label: 'Central Asia Standard Time' },
  { value: 'Bangladesh Standard Time', label: 'Bangladesh Standard Time' },
  { value: 'N. Central Asia Standard Time', label: 'N. Central Asia Standard Time' },
  { value: 'Myanmar Standard Time', label: 'Myanmar Standard Time' },
  { value: 'SE Asia Standard Time', label: 'SE Asia Standard Time' },
  { value: 'North Asia Standard Time', label: 'North Asia Standard Time' },
  { value: 'China Standard Time', label: 'China Standard Time' },
  { value: 'North Asia East Standard Time', label: 'North Asia East Standard Time' },
  { value: 'Singapore Standard Time', label: 'Singapore Standard Time' },
  { value: 'W. Australia Standard Time', label: 'W. Australia Standard Time' },
  { value: 'Taipei Standard Time', label: 'Taipei Standard Time' },
  { value: 'Ulaanbaatar Standard Time', label: 'Ulaanbaatar Standard Time' },
  { value: 'Tokyo Standard Time', label: 'Tokyo Standard Time' },
  { value: 'Korea Standard Time', label: 'Korea Standard Time' },
  { value: 'Yakutsk Standard Time', label: 'Yakutsk Standard Time' },
  { value: 'Cen. Australia Standard Time', label: 'Cen. Australia Standard Time' },
  { value: 'AUS Central Standard Time', label: 'AUS Central Standard Time' },
  { value: 'E. Australia Standard Time', label: 'E. Australia Standard Time' },
  { value: 'AUS Eastern Standard Time', label: 'AUS Eastern Standard Time' },
  { value: 'West Pacific Standard Time', label: 'West Pacific Standard Time' },
  { value: 'Tasmania Standard Time', label: 'Tasmania Standard Time' },
  { value: 'Vladivostok Standard Time', label: 'Vladivostok Standard Time' },
  { value: 'Central Pacific Standard Time', label: 'Central Pacific Standard Time' },
  { value: 'New Zealand Standard Time', label: 'New Zealand Standard Time' },
  { value: 'UTC+12', label: 'UTC+12' },
  { value: 'Fiji Standard Time', label: 'Fiji Standard Time' },
  { value: 'Kamchatka Standard Time', label: 'Kamchatka Standard Time' },
  { value: 'Tonga Standard Time', label: 'Tonga Standard Time' }
];
export const toastConfig = {
  duration: 4000,
  position: 'top-right'
};
export const dataSlider = [
  {
    genre: 'Pop, Rock, Funk, EDM',
    id: '1',
    schedulePerformanceId: null,
    status: 'Live',
    timeStarted: '2021-03-01T11:54:59.0023209',
    totalConnections: '4.200',
    userStartedUsername: 'DILLON FRANCIS',
    youtubeThumbnail: 'slide1.png'
  },
  {
    genre: 'EDM, Techno, Soul',
    id: '2',
    schedulePerformanceId: null,
    status: 'Live',
    timeStarted: '2021-03-01T11:54:59.0023209',
    totalConnections: '1.090',
    userStartedUsername: 'Surkin',
    youtubeThumbnail: 'slide2.png'
  },
  {
    genre: 'Drum & Bass, Punk, Pop, EDM',
    id: 3,
    schedulePerformanceId: null,
    status: 'Live',
    timeStarted: '2021-03-01T11:54:59.0023209',
    totalConnections: '200',
    userStartedUsername: 'Wilkinson',
    youtubeThumbnail: 'slide3.png'
  }
];
