import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// redux - action
import { useSelector, useDispatch } from 'react-redux';
import { getAuth } from '../../selectors/auth.selector';
// component
import LayoutDefault from '../../layouts/LayoutDefault';
import Header from '../../components/header/Header';
import Footer from '../../container/footer/Footer';
import SliderLiveShows from '../../container/slider/SliderLiveShows';
import RecentlyLive from '../../container/recently-live';
import LoginForgotPasswordRegister from '../../container/login-register';
import AdsBanner from '../../components/ads-banner';
import PopularShow from '../../container/popular-show';
import { logout, logoutSuccess, navigateSchedule } from '../../slices/auth';
import { isLoading } from '../../selectors/auth.selector';
import { SpinnerOverlay } from '../../components/spinner-overlay';
import moment from 'moment';
import {
  generateGreetings,
  getUserByToken,
  loadAppState
} from '../../shared/utils/helpers';
import { getPopularShow } from '../../selectors/searchGame.selector';
// data
import frisson from '../../data/frisson.json';
// helper
import { roleUser } from '../../shared/utils/helpers';
import { ROLE_SCHEDULE } from '../../shared/utils/constants';
import userService from '../../services/UserAPI';
import ScheduledPerformances from '../../container/scheduled-performances';
import { InitTut } from "shared/audiophile/onboarding";
import GetUser from 'utilities/getUserLocalStorage';
import { scroller } from 'react-scroll';


const Home = () => {
  const user = loadAppState();
  const [currentToken, setCurrentToken] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const { isAuthenticated, isSignUp, isRole } = useSelector(getAuth);
  const { totalCount } = useSelector(getPopularShow);
  const role = roleUser();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const scrollTo = urlParams.get('scrollTo');

    if (scrollTo) {
      scroller.scrollTo(scrollTo, {
        smooth: true,
        duration: 500
      });
    }
  }, []);
  // Token dependency
  // if (user?.auth?.currentUser !== undefined  && getUserByToken(user?.auth?.currentUser)?.exp < Number(String(Date.now() / 1000).slice(0, 10))) {
  // localStorage.removeItem('frisson_state');
  //   // dispatch(logout());
  // //
  //
  //
  //
  // }
  useEffect(() => {
    //check onboarding step for home page one time
    const localUser = GetUser();

    InitTut('home', localUser, ['EmptyStep']);
  }, []);

  const visionScheduledPerformances = () => {
    const auth = JSON.parse(localStorage.getItem('frisson_state'));
    return auth.auth.isAuthenticated;
  };
  useEffect(() => {
    if (
      user?.auth?.currentUser !== undefined &&
      getUserByToken(user?.auth?.currentUser)?.exp <
        Number(String(Date.now() / 1000).slice(0, 10))
    ) {
      userService.updateUserShowConnection({
        showId: '-1',
        disconnect: true
      });
    }
  }, [dispatch]);
  useEffect(() => {
    if (!isAuthenticated || !isSignUp) return;
    history.push('/profile');
  }, [history, isAuthenticated, isSignUp]);
  useEffect(() => {
    if (ROLE_SCHEDULE.includes(role) && isRole) {
      // history.push("/profile");
      setTimeout(() => {
        history.push('/profile');
      }, 2000);
      dispatch(navigateSchedule());
    }
  }, [dispatch, history, isRole, role]);
  return (
    <Fragment>
      <Helmet>
        <title>{`${frisson.title}`}</title>
      </Helmet>
      <LayoutDefault className="template-color-1 template-font-2 bg_color--3">
        <Header />
        <SpinnerOverlay loading={loading} />
        <SliderLiveShows />
        <div className="bg_color--3 pt--100 pl--30 pr--30">
          {!isAuthenticated ? (
            <Row>
              <Col xs={12} sm={12} lg={12} xl={9}>
                <ScheduledPerformances />
              </Col>
              <Col xs={12} sm={12} lg={12} xl={3} className="content-login-air">
                <LoginForgotPasswordRegister />
                <AdsBanner
                  className="yellow-color"
                  heading="/// What gives you frisson?"
                  adsLink="/discover"
                  ctaText="Shows Coming Up"
                />
                {totalCount !== 0 ? null : (
                  <AdsBanner
                    className="red-color-4"
                    heading={`/// ${moment().format(
                      'dddd'
                    )} ${generateGreetings()} //// SHOWS`}
                    adsLink="/discover"
                    ctaText="Show Me More"
                  />
                )}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <ScheduledPerformances />
              </Col>
            </Row>
          )}
        </div>
        <div className="bg_color--3 pl--30 pr--30">
          <PopularShow />
        </div>
        <div className="bg_color--3 pl--30 pr--30">
          <RecentlyLive />
        </div>
        <Footer />
      </LayoutDefault>
    </Fragment>
  );
};
export default Home;
