import React, { Fragment } from "react";
import {Container, Row} from "react-bootstrap";
import { Helmet } from "react-helmet";

// layout
import { LayoutDefault } from "../../layouts";
import { Header } from "../../components/header";
import { Footer } from "../../container/footer";

// data
import frisson from "../../data/frisson.json";
import "./styles.scss";

const NonDisclosureAgreement = () => {
    return (
        <Fragment>
            <Helmet>
                <title>{`${frisson.title}`}</title>
            </Helmet>
            <LayoutDefault className="bg-light template-font-1 bg_color--3">
                <Header />
                <Container className="pl_md--60 pl_sm--0">
                    <Row>
                        <div className="privacy-policy">
                            <h2>NON-DISCLOSURE AGREEMENT</h2>
                            <blockquote>
                                <i>Last updated: July 22, 2021</i>
                            </blockquote>


                            <p>
                                This Non-Disclosure Agreement (this “Agreement”) is entered into between Frisson Studios, Inc., a Delaware corporation (the “Company”) and you the recipient user of the Company’s
                                software and products hereto (the “Recipient”) as of the Recipient’s first use of the Company’s software and products (the “Effective Date”), to protect the confidentiality of certain
                                confidential information of the Company to be disclosed to the Recipient solely for use in evaluating or using software and products provided by the Company (the “Permitted Use”).
                            </p>


                            <h4>1.</h4>
                            <p>
                                As used herein, “Confidential Information” will mean any and all technical and non-technical information provided by the Company to the Recipient, which may include without limitation
                                information regarding: (a) patent and patent applications; (b) trade secrets; (c) proprietary and confidential information, ideas, techniques, sketches, drawings,
                                works of authorship, models, inventions, know-how, processes, apparatuses, equipment, algorithms, software programs, software source documents, and formulae related to the current,
                                future, and proposed products and services of the Company, including without limitation the Company’s information concerning research, experimental work, development, design details
                                and specifications, engineering, financial information, procurement requirements, purchasing, manufacturing, customer lists, investors, employees, business and contractual relationships,
                                business forecasts, sales and merchandising, marketing plans and information the Company provides regarding third parties; and (d) all other information that the Recipient knew,
                                or reasonably should have known, was the Confidential Information of the Company.
                            </p>


                            <h4>2.</h4>
                            <p>
                                Subject to Section 3, the Recipient agrees that at all times and notwithstanding any termination or expiration of this Agreement it will hold in strict confidence and not disclose to any
                                third party any Confidential Information, except as approved in writing by the Company, and will use the Confidential Information for no purpose other than the Permitted Use. The Recipient
                                will also protect such Confidential Information with at least the same degree of care that the Recipient uses to protect its own Confidential Information, but in no case, less than reasonable care.
                                The Recipient will limit access to the Confidential Information to only those of its employees or authorized representatives having a need to know and who have signed confidentiality agreements
                                containing, or are otherwise bound by, confidentiality obligations at least as restrictive as those contained herein.
                            </p>


                            <h4>3.</h4>
                            <p>
                                The Recipient will not have any obligations under this Agreement with respect to a specific portion of the Confidential Information if the Recipient can demonstrate with competent evidence
                                that such Confidential Information:
                                <ul>`
                                    <li>was in the public domain at the time it was disclosed to the Recipient;</li>
                                    <li>entered the public domain subsequent to the time it was disclosed to the Recipient, through no fault of the Recipient;</li>
                                    <li>was in the Recipient’s possession free of any obligation of confidence at the time it was disclosed to the Recipient;</li>
                                    <li>was rightfully communicated to the Recipient free of any obligation of confidence subsequent to the time it was disclosed to the Recipient; or</li>
                                    <li>was developed by employees or agents of the Recipient who had no access to any Confidential Information.</li>
                                </ul>
                            </p>

                            <h4>4.</h4>
                            <p>
                                Notwithstanding the above, the Recipient may disclose certain Confidential Information, without violating the obligations of this Agreement, to the extent such disclosure is required by a valid order
                                of a court or other governmental body having jurisdiction, provided that the Recipient provides the Company with reasonable prior written notice of such disclosure and makes a reasonable effort to obtain,
                                or to assist the Company in obtaining, a protective order preventing or limiting the disclosure and/or requiring that the Confidential Information so disclosed be used only for the purposes for which the
                                law or regulation required, or for which the order was issued.
                            </p>

                            <h4>5.</h4>
                            <p>
                                The Recipient will immediately notify the Company in the event of any loss or unauthorized disclosure of any Confidential Information.
                            </p>

                            <h4>6.</h4>
                            <p>
                                Upon termination or expiration of this Agreement, or upon written request of the Company, the Recipient will promptly return to the Company all documents and other tangible materials representing any
                                Confidential Information and all copies thereof.
                            </p>

                            <h4>7.</h4>
                            <p>
                                Confidential Information is and shall remain the sole property of the Company. The Recipient recognizes and agrees that nothing contained in this Agreement will be construed as granting any property rights,
                                by license or otherwise, to any Confidential Information disclosed under this Agreement, or to any invention or any patent, copyright, trademark, or other intellectual property right that has issued or that
                                may issue, based on such Confidential Information. The Recipient will not make, have made, use or sell for any purpose any product or other item using, incorporating or derived from any Confidential Information.
                                Neither this Agreement nor the disclosure of any Confidential Information hereunder shall result in any obligation on the part of either party to enter into any further agreement with the other, license any
                                products or services to the other, or to require the Company to disclose any particular Confidential Information. Nothing in this Agreement creates or shall be deemed to create any employment, joint venture,
                                or agency between the parties.
                            </p>

                            <h4>8.</h4>
                            <p>
                                Confidential Information will not be reproduced in any form except as required to accomplish the intent of this Agreement. Any reproduction of any Confidential Information will remain the property of the Company
                                and will contain any and all confidential or proprietary notices or legends that appear on the original, unless otherwise authorized in writing by the Company.
                            </p>

                            <h4>9.</h4>
                            <p>
                                This Agreement will terminate five (5) year(s) after the Effective Date, or may be terminated by either party at any time upon thirty (30) days written notice to the other party. The Recipient’s obligations under
                                this Agreement will survive termination of this Agreement and will be binding upon the Recipient’s heirs, successors, and assigns. The Recipient’s obligations with respect to all Confidential Information will
                                terminate only pursuant to Section 3.
                            </p>
                            <br/>

                            <h4>10.</h4>
                            <p>
                                The Company is providing Confidential Information on an “AS IS” basis for use by the recipient at its own risk. The Company disclaims all WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT
                                LIMITATION ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE.
                            </p>

                            <h4>11.</h4>
                            <p>
                                This Agreement and any action related thereto will be governed, controlled, interpreted, and defined by and under the laws of the State of Delaware, without giving effect to any conflicts of laws principles that
                                require the application of the law of a different state. Any disputes under this Agreement may be brought in the state courts and the Federal courts for the county in which Company’s principal place of business
                                is located, and the parties hereby consent to the personal jurisdiction and exclusive venue of these courts. This Agreement may not be amended except by a writing signed by both parties.
                            </p>

                            <h4>12.</h4>
                            <p>
                                The Recipient hereby agrees that its breach of this Agreement will cause irreparable damage to the Company for which recovery of damages would be inadequate, and that the Company will be entitled to obtain timely
                                injunctive relief under this Agreement, as well as such further relief as may be granted by a court of competent jurisdiction.
                            </p>

                            <h4>13.</h4>
                            <p>
                                If any provision of this Agreement is found by a proper authority to be unenforceable or invalid, such unenforceability or invalidity will not render this Agreement unenforceable or invalid as a whole and, in such
                                event, such provision will be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law or applicable court decisions. Any waiver
                                or failure to enforce any provision of this Agreement on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.
                            </p>

                            <h4>14.</h4>
                            <p>
                                The Recipient will not assign or transfer any rights or obligations under this Agreement without the prior written consent of the Company and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.
                            </p>

                            <h4>15.</h4>
                            <p>
                                The Recipient will not export, directly or indirectly, any U.S. technical data acquired pursuant to this Agreement, or any products utilizing such data, in violation of the United States export laws or regulations.
                            </p>

                            <h4>16.</h4>
                            <p>
                                All notices or reports permitted or required under this Agreement will be in writing and will be delivered by personal delivery, electronic mail, facsimile transmission or by certified or registered mail, return receipt requested, and will be deemed given upon
                                personal delivery, five (5) days after deposit in the mail, or upon acknowledgment of receipt of electronic transmission. Notices will be sent to the addresses set forth at the end of this Agreement or such other address as either party may specify in writing.
                            </p>

                            <h4>17.</h4>
                            <p>
                                The Recipient agrees that the software programs of the Company contain valuable confidential information and agrees that it will not modify, reverse engineer, decompile, create other works from, or disassemble any software programs contained in the Confidential
                                Information without the prior written consent of the Company.
                            </p>

                            <h4>18.</h4>
                            <p>
                                This Agreement is the final, complete and exclusive agreement of the parties with respect to the subject matters hereof and supersedes and merges all prior discussions between the parties with respect to such matters. No modification of or amendment to this
                                Agreement will be effective unless in writing and signed by the party to be charged.
                            </p>

                            <p>
                                By using Company provided software or products, you the Recipient and the Company have executed this Non-Disclosure Agreement as of the Effective Date.
                            </p>

                        </div>
                    </Row>
                </Container>
                <Footer />
            </LayoutDefault>
        </Fragment>
    );
};

export default NonDisclosureAgreement;
