import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
// import ButtonSearch from '../../components/button-search';
// import icon from '../../assets/img/edit-profile/icon.svg';
// import whiteIcon from '../../assets/img/edit-profile/whiteIcon.svg';
// import AvatarUpload from '../../components/avatar-upload';
import { TABS } from '../../data/profile-edit/ProfileTab';
import './styles.scss';
import { selectTab } from '../../slices/tabs';
import { roleUser } from '../../shared/utils/helpers';
import AvatarSimple from '../../components/avatar-simple';
import { logout, resetState } from '../../slices/auth';

const ProfileUser = ({ getUser, tabIndex, onChangeTab }) => {
  const role = roleUser();
  const tab = useSelector((state) => state.tabs);
  const [tabIndexEm, setTabIndexEm] = useState(6);
  const [openSlideUp, setOpenSlideUp] = useState(false);
  const [selectedTabId, setSelectedTabId] = useState(localStorage.getItem('selectedTabId') || 'tab1');
  // async function Logout() {
  //   window.localStorage.removeItem('frisson_state');
  //   document.location.reload();
  // }
  const handleClosePopUp = useCallback(() => {
    setOpenSlideUp(false);
  }, []);
  const onLogout = () => {
    dispatch(resetState());
    dispatch(logout());
    handleClosePopUp();
    // window.localStorage.removeItem('profile_user')
  };
  const handlClick = (e, tabIdx) => {
    setTabIndexEm(tabIdx);
    dispatch(selectTab(e.target.tabIndex));
    setSelectedTabId(tabIdx);
    localStorage.setItem('selectedTabId', tabIdx);
  };


  // const handlClick = useCallback((e, tabIdx) => {
  //
  //   setTabIndexEm(tabIdx);
  //   dispatch(selectTab(e.target.tabIndex));
  //   console.log(e.target.tabIndex);
  // }, []);
  const dispatch = useDispatch();
  const genres = getUser?.genre?.split(',');

  useEffect(() => {
    dispatch(selectTab(parseInt(selectedTabId)));
    setTabIndexEm(parseInt(selectedTabId));
  }, [])

  return (
    <div className='content-edit-profile-left'>
      <div className='profile-side-for-user'>
        <div
          className={classNames('user-avatar-block', {
            inactive: tabIndexEm !== TABS.userName || tabIndexEm !== -1,
            active: tabIndexEm === TABS.userName || tabIndexEm === -1
          })}
          tabIndex={TABS.userName}
          onClick={(e) => {
            handlClick(e, e.target.tabIndex);
          }}
        >
          <AvatarSimple getUser={getUser} />
          <div className='user-title'>
            <div className='user-name'>
              {getUser?.displayName
                ? getUser?.displayName?.length > 13
                  ? getUser?.displayName?.substr(0, 13) + '...'
                  : getUser?.displayName
                : ''}
            </div>
            {/*<div  className='user-email'>{getUser?.email || ''}</div>*/}
            <div className='user-email'>Hz: 0; dB: {getUser?.virtualCurrency?.DB}.</div>
          </div>
        </div>
        <div className='hr-user'></div>
        <ul className='block-tab'>
          {role === 'Producer' && (
            <li
              onClick={(e) => {
                handlClick(e, e.target.tabIndex);
              }}
              tabIndex={TABS.schedulePerformance}
              className={classNames('', {
                inactive: tabIndexEm !== TABS.schedulePerformance,
                active: tabIndexEm === TABS.schedulePerformance
              })}
            >
              <i className='fa fa-archive tab-account-icon'></i> Schedule
              Performance
            </li>
          )}
          <li
            onClick={(e) => {
              handlClick(e, e.target.tabIndex);
            }}
            tabIndex={TABS.watchList}
            className={classNames('', {
              inactive: tabIndexEm !== TABS.watchList,
              active: tabIndexEm === TABS.watchList
            })}
          >
            <i className='fa fa-list tab-account-icon'></i> Watch List
          </li>
          <li
            onClick={(e) => {
              handlClick(e, e.target.tabIndex);
            }}
            tabIndex={TABS.friends}
            className={classNames('', {
              inactive: tabIndexEm !== TABS.friends,
              active: tabIndexEm === TABS.friends
            })}
          >
            <i className='fa fa-users tab-account-icon'></i> Friends
          </li>
          <li
            onClick={(e) => {
              handlClick(e, e.target.tabIndex);
            }}
            tabIndex={TABS.accountSettings}
            className={classNames('', {
              inactive: tabIndexEm !== TABS.accountSettings,
              active: tabIndexEm === TABS.accountSettings
            })}
          >
            <i className='fa fa-cog tab-account-icon'></i> Account Settings
          </li>
          {role === 'Producer' && (<li
            onClick={(e) => {
              handlClick(e, e.target.tabIndex);
            }}
            tabIndex={TABS.earningTab}
            className={classNames('', {
              inactive: tabIndexEm !== TABS.earningTab,
              active: tabIndexEm === TABS.earningTab
            })}
          >
            <i className='fa fa-briefcase tab-account-icon'></i> Earnings
          </li>)}
          {role === 'Producer' && (
            <li
              onClick={(e) => {
                handlClick(e, e.target.tabIndex);
              }}
              tabIndex={TABS.youtubeTwitch}
              className={classNames('', {
                inactive: tabIndexEm !== TABS.youtubeTwitch,
                active: tabIndexEm === TABS.youtubeTwitch
              })}
            >
              <i className='fa fa-plug tab-account-icon'></i> Remote Performance
            </li>
          )}
        </ul>
        <div className='hr-user'></div>
        <div
          className='logout-link'
          onClick={() => {
            onLogout();
          }}
        >
          Logout
        </div>
      </div>
      {/*<div className="profile-side-bar">*/}
      {/*  <div className="profile-nav-info">*/}
      {/*    <AvatarUpload getUser={getUser} />*/}
      {/*    <div className="user-name">{getUser?.displayName || ""}</div>*/}
      {/*    <div className="user-email">{getUser?.email || ""}</div>*/}
      {/*  </div>*/}
      {/*  <div className="user-history">*/}
      {/*    <div className="win-image bounce">*/}
      {/*      <i className="fa fa-trophy" aria-hidden="true" />*/}
      {/*    </div>*/}
      {/*    <div className="user-point">{getUser?.hertz || 0} HERTZ</div>*/}
      {/*  </div>*/}
      {/*  <div className="horizontal" />*/}
      {/*  <div className="user-store">*/}
      {/*    <div*/}
      {/*      className={classNames("watch-coming", {*/}
      {/*        active: tabIndex === TABS.favorites*/}
      {/*      })}*/}
      {/*    >*/}
      {/*      <div className="content-image">*/}
      {/*        <i className="fa fa-bookmark" aria-hidden="true" />*/}
      {/*      </div>*/}
      {/*      <div className="content-description">*/}
      {/*        <div className="description-top">*/}
      {/*          <i className="fa fa-heart-o lock" aria-hidden="true" />*/}
      {/*          <span className="text-description">Favorites</span>*/}
      {/*        </div>*/}
      {/*        <div className="description-body">*/}
      {/*          /!*${getUser?.shows}*!/*/}
      {/*          {"Coming Soon"}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div*/}
      {/*      className={classNames("watch-coming", {*/}
      {/*        active: tabIndex === TABS.watchList*/}
      {/*      })}*/}
      {/*      onClick={(e) => onChangeTab(e, TABS.watchList)}*/}
      {/*    >*/}
      {/*      <div className="content-image">*/}
      {/*        <img*/}
      {/*          src={tabIndex === TABS.watchList ? whiteIcon : icon}*/}
      {/*          alt=""*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className="content-description">*/}
      {/*        <div className="description-top">*/}
      {/*          <i className="fa fa-th-list lock" aria-hidden="true" />*/}
      {/*          <span className="text-description">Watch List</span>*/}
      {/*        </div>*/}
      {/*        <div className="description-body"></div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="horizontal" />*/}
      {/*  <div className="genres-top">*/}
      {/*    <div className="genres-title">Favorite Genres</div>*/}
      {/*    <div className="genres-block">*/}
      {/*      {genres?.map((item, index) => (*/}
      {/*        <ButtonSearch*/}
      {/*          key={index}*/}
      {/*          styleColor={"#DF0346"}*/}
      {/*          handlClickeClick={handlClickeClick}*/}
      {/*          value={item}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
export default ProfileUser;
