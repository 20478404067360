import React, { useRef, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import AdsBanner from "../../components/ads-banner";
import TitleCommon from "../../components/title-common";
import Slick from "react-slick";
import { getAuth } from "../../selectors/auth.selector";
import { useDispatch, useSelector } from "react-redux";
import { isLoading, getPopularShow } from "../../selectors/searchGame.selector";
import { popularShowRequest } from "../../slices/searchGameSession";
import { BlockLoading } from "react-loadingg";
import GalleryItem from "../../components/gallery-item";
import { generateGreetings } from "../../shared/utils/helpers";
import {
  FORMAT_DATE_TIME,
  PAGINATION,
  FILTER_STATUS
} from "../../shared/utils/constants";
import moment from "moment";

import "./styles.scss";
import { isEmpty } from "lodash";

const PopularShow = () => {
  const ref = useRef({});
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const { data, totalCount } = useSelector(getPopularShow);
  const { isAuthenticated } = useSelector(getAuth);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: "",
    prevArrow: "",
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  const newData = useMemo(() => {
    const newList = [];
    const cloneArray = data;
    const totalPage = Math.ceil(totalCount / 5);
    for (let i = 0; i < totalPage; i++) {
      if (i === 0) {
        newList.push({ [i]: cloneArray.slice(0, 5 * 1) });
      } else {
        newList.push({ [i]: cloneArray.slice(i * 5, (i + 1) * 5) });
      }
    }
    return newList;
  }, [totalCount, data]);

  const next = () => {
    ref.current.slickNext();
  };
  const previous = () => {
    ref.current.slickPrev();
  };

  const SlickSlider = ({ children, settings, classes }) => {
    return (
      <Slick {...settings} ref={ref} className={classes}>
        {children}
      </Slick>
    );
  };
  useEffect(() => {
    if (!isEmpty(data)) return;
    dispatch(
      popularShowRequest({
        pageSize: PAGINATION.PAGE_SIZE,
        pageIndex: PAGINATION.PAGE_INDEX,
        filterOptions: {
          status: FILTER_STATUS.PAST,
          timeStartedFrom: moment()
            .subtract(90, "days")
            .format(FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM),
          timeStartedTo: moment(new Date()).format(
            FORMAT_DATE_TIME.YYYY_MM_DDTHH_MM
          )
        }
      })
    );
  }, [data, dispatch]);
  return (
    <>
      {totalCount !== 0 ? (
        <Row className="wrapper-popular-show wow move-up">
          <Col xs={12} sm={12} lg={12} xl={3}>
            {isAuthenticated ? (
              <AdsBanner
                className="yellow-color"
                heading="/// What gives you frisson?"
                adsLink="/discover"
                ctaText="Shows Coming Up"
              />
            ) : null}

            <AdsBanner
              className="red-color-4"
              heading={`/// ${moment().format(
                "dddd"
              )} ${generateGreetings()} //// SHOWS`}
              adsLink="/discover"
              ctaText="Show Me More"
            />
          </Col>
          <Col xs={12} sm={12} lg={12} xl={9}>
            <div className="content-slider">
              <TitleCommon title={"Popular Shows"} className="modify-title" />
              {loading ? (
                <BlockLoading color="#df1a46" />
              ) : (
                <>
                  <SlickSlider settings={settings}>
                    {!isEmpty(newData) &&
                      newData?.map((carousel, index) => {
                        const cloneCarousel = carousel[index];
                        return (
                          <main key={index}>
                            {cloneCarousel?.map((item, i) => {
                              return (
                                <GalleryItem
                                  key={i}
                                  item={item}
                                  className={i === 0 ? "item-b" : "normal"}
                                  modifyClass={true}
                                />
                              );
                            })}
                          </main>
                        );
                      })}
                  </SlickSlider>
                  {totalCount > 5 ? (
                      <div className="live-now-pagination modify-pagination">
                        <div className="box-pagination">
                          <div className="show-arrow" onClick={previous}>
                            <i className="fa fa-angle-left" aria-hidden="true" />
                          </div>
                          <div className="show-arrow active" onClick={next}>
                            <i className="fa fa-angle-right" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                  ) : null}
                </>
              )}
            </div>
          </Col>
        </Row>
      ) : null}
    </>
  );
};
export default PopularShow;
