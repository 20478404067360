import { Grid } from '@mui/material';
import Logo from 'components/header/elements/logo';
import logo from "../../assets/img/logo/logo.png";
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { fireworkTypes, emojiTypes } from "./vibes_datas";

const VibesUI_TypeSelector = (props) => {

    const [itemType, setItemType] = useState(props.itemType)
    const types = props.selectedEvent ==  "event_crowdemoji" ? emojiTypes : fireworkTypes
    const imgURL = '/'

    function selectedItemType(itemType) {
        setItemType(itemType)
        props.selectedType(itemType)
    }

    console.log(props)

    return (
        <Grid className='vibesUI--type_grid' container spacing={0.5}>
            {types.map(typeName => (
            <Grid item xs={3} key={typeName}>
                {props.selectedEvent ==  "event_crowdemoji" ? 
                    <Button style={{backgroundColor: '#ccc'}} className={itemType == typeName ? `selected ${props.selectedEvent}` : ` ${props.selectedEvent}`} onClick={ () => {selectedItemType(typeName)}}>
                        <div className='emojiContainer'>
                            <span className={typeName}>{props.emoji}</span>
                        </div>
                        <p>{typeName}</p>
                        
                        
                    </Button> : 
                    <Button style={{backgroundColor: props.color}} className={itemType == typeName ? `selected ${props.selectedEvent}` : ` ${props.selectedEvent}`} onClick={ () => {selectedItemType(typeName)}}>
                        <p>{typeName}</p>
                        <div style={{ background: 'url(' + require('../../assets/img/fireworks/' + typeName + '.png')}} />
                        
                    </Button>
                }
                
                
            </Grid>
            ))}
      </Grid>
    );
};

export default VibesUI_TypeSelector;