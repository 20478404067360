import React, { useEffect, useState } from 'react';
import sprite from '../../assets/svg/sprite.svg';
import './styles.scss';
import UserAPI from '../../services/UserAPI';
import { useSelector } from 'react-redux';
import { Spinner } from '../spinner';
import * as Yup from 'yup';
import { dataRemoteShedule } from '../../selectors/user.selector';
const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,50})+$/;

const SuperAdmins = () => {
  const restApiAdmins = useSelector(dataRemoteShedule);
  const [adminUrls, setAdminUrls] = useState(['']);
  const [performanceData, setPerformanceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const updateData = () => {
    setLoading(true);

    setPerformanceData(restApiAdmins?.config);

    if (restApiAdmins?.config?.superAdmins?.length)
      setAdminUrls(restApiAdmins?.config?.superAdmins);
    else setAdminUrls(['']);

    setLoading(false);

    // UserAPI.getRemotePerformanceDataApi()
    //   .then((res) => {
    //     setLoading(false);
    //     setPerformanceData(restApiAdmins?.config);
    //     if (res?.data?.config?.superAdmins?.length)
    //       setAdminUrls(res?.data?.config?.superAdmins);
    //     else setAdminUrls(['']);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     throw err;
    //   })
    //   .finally(() => setLoading(false));
  };

  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {
    if (
      adminUrls?.every((item) => {
        if (item?.length) return validate(item);
        else return true;
      })
    )
      setError(false);
    else setError(true);
  }, [adminUrls]);

  const addNewField = () => {
    setAdminUrls([...adminUrls, '']);
  };

  const validate = (value) => {
    return value.match(validRegex);
  };

  const onSave = () => {
    setLoading(true);
    UserAPI.submissionUserUpdRemPerConfig({
      ...performanceData,
      superAdmins: adminUrls?.filter((item) => !!item.length)
    })
      .then(() => updateData())
      .catch((err) => {
        throw err;
      });
  };

  return (
    <div className="super-admins">
      <div className="super-admins-grid">
        <div className="super-admins-info">
          <h4>Superadmins</h4>
          <div>
            <svg fill="#fff" style={{ width: '20px', height: '20px' }}>
              <use href={sprite + '#info'}></use>
            </svg>
          </div>
        </div>
        <button onClick={addNewField}>
          <svg fill="#fff" style={{ width: '20px', height: '20px' }}>
            <use href={sprite + '#plus'}></use>
          </svg>
        </button>
      </div>

      {loading && (
        <div className={'super-admins-loader'}>
          <Spinner borderRadius={'0%'} />
        </div>
      )}

      {adminUrls?.map((item, key) => (
        <input
          key={key}
          type="email"
          placeholder="Enter email"
          // defaultValue={!item ? 'Nothing@yet' : item}
          value={!item ? 'Nothing@yet' : item}
          onChange={(e) => {
            const newLinks = [...adminUrls];
            newLinks[key] = e?.target?.value;
            setAdminUrls(newLinks);
          }}
        />
      ))}

      {error && (
        <p className={'error'} style={{ textAlign: 'center' }}>
          Emails should be valid
        </p>
      )}

      <button onClick={onSave} disabled={error}>
        Save superadmins
      </button>
    </div>
  );
};
export default SuperAdmins;
