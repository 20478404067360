import React, { useEffect, useState } from 'react';
import './styles.scss';

const SelectData = ({
                      itemsData, setDateState, dateState, type
                    }) => {
  const [openUl, setOpenUl] = useState(false);
  const [getValueOption, setGetValueOption] = useState('');
  useEffect(() => {
    itemsData.forEach(i => {
      if (type === 'Month') {
        if (new Date().getMonth() + 1 === i.id) {
          setGetValueOption(i.name);
        }
      }
      if (type === 'Year') {
        if (new Date().getFullYear() === Number(i.name)) {
          setGetValueOption(i.name);
        }
      }
    });
  }, []);

  const stylesOpen = openUl ? 'calendar-mi_open' : 'calendar-mi_close';
  const stylesActiveI = openUl ? 'fa fa-angle-up active' : 'fa fa-angle-up';
  // const onChangeMonth = (e) => {
  //   setDateState(new Date(type === 'Month' ? e.target.value : (dateState.getMonth() + 1) + '/' + (dateState.getDate()) + '/' + type === 'Year' ? e.target.value : dateState.getFullYear()));
  // };
  const clickValueSelect = ({ id, name }) => {
    setGetValueOption(name);
    setDateState(new Date((type === 'Month' ? id : (dateState.getMonth() + 1)) + '/' + (dateState.getDate()) + '/' + (type === 'Year' ? name : dateState.getFullYear())));
  };
  return (
    <div className='calendar-mi' onClick={() => {
      setOpenUl(!openUl);
    }}>
      {getValueOption}
      <div className='type-name'>{type}</div>
      <i className={stylesActiveI}></i>
      <ul className={stylesOpen}>
        {
          itemsData.map((u) => <li key={u.id} onClick={() => {
            clickValueSelect(u);
          }} value={u.id}>{u.name}</li>)
        }
      </ul>
    </div>
  );
};
export default SelectData;
