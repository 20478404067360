import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AdvanceFilter from '../../container/advance-filter';
import './styles.scss';

const menuItems = [
  { path: '', name: 'Home' },
  { path: 'discover', name: 'Discover' },
  { path: 'recommendations', name: 'Recommendations' }
];
const PageContainer = ({ children, className, revealFooter }) => {
  const [footerHeight, setFooterHeight] = useState(0);
  const [opening, setOpen] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const location = useLocation();
  const openToggle = () => {
    setOpen(!opening);
    setSearch(false);
  };
  const openSearch = () => {
    setSearch(!isSearch);
    setOpen(false);
  };
  useEffect(() => {
    if (revealFooter) {
      const pageFooter = document.querySelector('footer');
      const footerHeight = pageFooter.offsetHeight;
      setFooterHeight(footerHeight);
    }
  }, [revealFooter]);
  return (
    <>
      <div className='wrapper-menu'>
        {/*<div className="menu-search" onClick={() => openSearch()}>*/}
        {/*  <i className="fa fa-search" />*/}
        {/*</div>*/}
        {/*<div className="a-header" onClick={() => openToggle()}>*/}
        {/*  {!opening ? (*/}
        {/*    <i className="fa fa-navicon" />*/}
        {/*  ) : (*/}
        {/*    <i className="fa fa-close" />*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
      <div className='content-menu'></div>
      <ul className={`test-acb ${isSearch ? 'open-menu' : 'close-menu'}`}>
        <AdvanceFilter isOpen={true} />
      </ul>
      <ul className={`test-acb ${opening ? 'open-menu' : 'close-menu'}`}>
        {menuItems.length &&
          menuItems?.map((menuItem) => {
            return (
              <li
                className={`test ${
                  menuItem.path === location.pathname.split('/')[1]
                    ? 'active'
                    : ''
                }`}
                key={menuItem.name}
              >
                <Link to={`/${menuItem.path}`}>{menuItem?.name}</Link>
              </li>
            );
          })}
      </ul>
      <div
        className={`wrapper ${revealFooter ? 'all-wrapper' : ''} ${className}`}
        id='wrapper'
        style={{ marginBottom: revealFooter && footerHeight }}
      >
        {children}
      </div>
    </>
  );
};
export default PageContainer;
