import * as yup from "yup";
export const ValidationSchedulePerformanceSchema = yup.object().shape({
  performanceName: yup
    .string()
    .min(4, "Must be 6 characters")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  description: yup
    .string()
    .min(10, "Must be 10 characters")
    .max(100, "Must be 100 characters or less"),
  genre: yup.array().required("Required"),
  startDatetime: yup.date().required("Required"),
  runtime: yup
    .number("Invalid runtime")
    .required("Required")
    .test("Runtime must be greater than 0!", (value) => value > 0),

  targetArtistAccountId: yup.array().nullable(),
  superAdminsIds: yup.array()
});

export const ValidationRemoveArtistSchedulePerformanceSchema = yup.object().shape({
  performanceName: yup
    .string()
    .min(4, "Must be 6 characters")
    .max(50, "Must be 50 characters or less")
    .required("Required"),
  description: yup
    .string()
    .min(10, "Must be 10 characters")
    .max(100, "Must be 100 characters or less"),
  genre: yup.array().required("Required"),
  startDatetime: yup.date().required("Required"),
  runtime: yup
    .number("Invalid runtime")
    .required("Required")
    .test("Runtime must be greater than 0!", (value) => value > 0),

  targetArtistAccountId: yup.array().nullable(),
  superAdminsIds: yup.array()
});

export const ValidationUpdateSchedulePerformanceSchema = yup.object().shape({
  startDatetime: yup
    .date()
    .min(new Date(), "Start date & time cannot be in the past"),
  runtime: yup
    .number()
    .required("Required")
    .test("Runtime must be greater than 0!", (value) => value > 0)
});
