import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: "",
  loading: false,
  success: false,
  genres: {},
  lives: {},
  recommendedShows: {},
  genre: { label: "Genres", value: "genres" },
  show: { value: "show", label: "Shows" },
  time: { value: "bytime", label: "By Time" }
};

const genresSlice = createSlice({
  name: "genres",
  initialState,
  reducers: {
    selectedGenre: (state, { payload }) => {
      return {
        ...state,
        genre: payload
      };
    },
    selectedShow: (state, { payload }) => {
      return {
        ...state,
        show: payload
      };
    },
    selectedTime: (state, { payload }) => {
      return {
        ...state,
        time: payload
      };
    },

    getGenresRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getGenresSuccess: (state, { payload }) => {
      return {
        ...state,
        genres: payload,
        loading: false,
        success: true
      };
    },
    getGenresError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },
    getListLiveRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getListLiveSuccess: (state, { payload }) => {
      return {
        ...state,
        lives: payload,
        loading: false,
        success: true
      };
    },
    getListLiveError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },
    getRecommendedShowsRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getRecommendedShowsSuccess: (state, { payload }) => {
      return {
        ...state,
        recommendedShows: payload,
        loading: false,
        success: true
      };
    },
    getRecommendedShowsError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    }
  }
});

export const {
  selectedGenre,
  getGenresRequest,
  getGenresSuccess,
  getGenresError,
  selectedShow,
  getListLiveRequest,
  getListLiveSuccess,
  getListLiveError,
  selectedTime,
  getRecommendedShowsRequest,
  getRecommendedShowsSuccess,
  getRecommendedShowsError
} = genresSlice.actions;

export default genresSlice.reducer;
