import React from "react";
import { selectedGenre } from "../../slices/genres";
import { useDispatch } from "react-redux";

import "./styles.scss";
const BoxEffect = ({ item, isActive, setHover, isHover }) => {
  const dispatch = useDispatch();
  const handleMouseLeave = () => {
    if (typeof setHover === "function") {
      setHover({});
    }
  };
  const handleMouseEnter = (item) => {
    if (typeof setHover === "function") {
      setHover({ item });
    }
  };

  const selectedItem = (item) => {
    dispatch(selectedGenre(item));
  };

  return (
    <div
      className={`custom-hover-image ${isActive ? "active" : ""} ${
        isHover ? "hover" : ""
      }`}
      onClick={() => selectedItem(item)}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={() => handleMouseEnter(item)}
    >
      <img src={require("../../assets/img/genres/" + item.img)} alt="" />
      <div className="content-description">
        <h4>{item.label}</h4>
        <p> {`${item.total} ${item.total > 0 ? "Shows" : "Show"}`}</p>
      </div>
    </div>
  );
};
export default BoxEffect;
