import React from 'react';
  import { Modal } from 'react-bootstrap';
  import { useDispatch, useSelector } from 'react-redux';
  import { getAuth } from '../../selectors/auth.selector';
  import {  registerSuccess } from '../../slices/auth';
  import logo from '../../assets/img/logo/logo.png';
  // component
  import Logo from '../../components/header/elements/logo';
  
  import { Link} from 'react-router-dom';
  
  import '../edit-profile/styles.scss';
  
  import sprite from '../../assets/svg/sprite.svg';
import { StartChat, joinTest } from './chatManager';
import { ActivateTut, TutDisplay } from 'shared/audiophile/onboarding';

  const CCModal = () => {
    const { isSignUp } = useSelector(getAuth);
    const dispatch = useDispatch();
    const handleToggleModal = (value) => {
        dispatch(registerSuccess(value));
        document.getElementById('joinCrowdButton').style.display = 'none'
        StartChat()
    };
    const handleModalCTA = () => {
        dispatch(registerSuccess(false))
        ActivateTut()
        TutDisplay(true)
    }
    return (
        <Modal show={isSignUp} id='ccModal' onHide={() => handleToggleModal(false)}>
        
            <Modal.Header closeButton>
            <div className='logoContainer'>
                <Logo logo={logo} />
            <Modal.Title>Welcome To Frisson!</Modal.Title>
            </div>
            
            </Modal.Header>
            <Modal.Body>
            {/* <p style={{ textAlign: 'center' }}>
                Please upload a profile picture showing your best festival self.
                Then feel free to browse live virtual shows and join the crowd!
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <AvatarUpload getUser={profileUser} buttonColor={buttonColor} />
            </div> */}
            <p style={{ textAlign: 'center', marginTop: '-25px', fontStyle: 'italic' }}>Live, interactive virtual music festivals for everyone</p>
            <p style={{ textAlign: 'center', paddingTop: '20px' }}>
            Party with like-minded people across the globe. Show your support for your favorite artists. Make the moment unforgettable by launching fireworks during everyone's favorite song. Our mission is to deliver those thrilling experiences that give you chills of excitement.
            </p>
            <p style={{ textAlign: 'center', paddingTop: '20px' }}>
            Let's jump right into your first show with Crowd Chat! You can end the tutorial at any time by clicking the exit button in the top-right corner.
            </p>
            {/* <Link to={'/dressing-room?tutorial=1'} class='block-avatar-upload avatar-button-upload'
                    style={{ backgroundColor: 'rgb(255, 110, 9)', maxWidth: '36%', margin: '0 auto', color: 'white' }}>Begin
                tutorial
            </Link> */}
            <div onClick={() => handleModalCTA()} id='ccModalButton' className='buttonContainer' style={{width : '285px', margin: '30px auto 0', cursor: 'pointer'}}>
                <div className='button'>
                <div className="top-menu-item-modern a">
                    <svg style={{ width: '30px', height: '30px' }}>
                    <use href={sprite + '#evenodd'}></use>
                    </svg>
                </div>
                
                </div>
                <div className='nameContainer'>
                <span style={{color: 'white', fontSize: '15px'}}>Learn More About CrowdChat</span>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
    
  };
  export default CCModal;
  