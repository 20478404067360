import React from "react";

import "./styles.scss";
const ButtonIcon = ({
  type,
  btnText,
  icon,
  size,
  btnStyle,
  color,
  onClick
}) => {
  return (
    <button
      className={`frisson-btn btn-original fr-btn-${color} btn-${size}-size btn-${btnStyle} space-between`}
      onClick={onClick}
      type={type}
    >
      <i
        className="fa fa-plus"
        aria-hidden="true"
        style={{ marginRight: "5px" }}
      ></i>
      {btnText}
    </button>
  );
};

export default ButtonIcon;
