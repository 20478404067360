import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  error: '',
  loading: false,
  success: false,
  isCreated: false,
  isUpdated: false,
  shedulesDetails: [],
  shedules: []
};
const schedulePerformance = createSlice({
  name: 'schedulePerformance',
  initialState,
  reducers: {
    createFormPerformacneRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    createFormPerformacneSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        isCreated: payload
      };
    },
    createFormPerformacneError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },
    deleteFormPerformanceRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    deleteFormPerformanceSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true,
        isUpdated: payload
      };
    },
    updateFormPerformanceRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    updateFormPerformanceSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true,
        isUpdated: payload
      };
    },
    updateFormPerformanceError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },
    getSchedulePerformanceRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getSchedulePerformanceSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true,
        shedules: payload
      };
    },
    getSchedulePerformanceError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    addToPlayListSchedulePerfomancesRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    addToPlayListSchedulePerfomancesSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true,
        shedulesDetails: payload
      };
    },
    addToPlayListSchedulePerfomancesError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    getScheduleByIdRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getScheduleByIdSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true,
        shedulesDetails: payload
      };
    },
    getScheduleByIdError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    getRemoveTargetArtistRequest: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    getRemoveTargetArtistSuccess: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        success: true,
        isUpdated: payload
      };
    },
    getRemoveTargetArtistError: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    },

    resetState: () => {
      return { ...initialState };
    }
  }
});
export const {
  createFormPerformacneRequest,
  createFormPerformacneSuccess,
  createFormPerformacneError,
  getSchedulePerformanceRequest,
  getSchedulePerformanceSuccess,
  getSchedulePerformanceError,
  deleteFormPerformanceRequest,
  deleteFormPerformanceSuccess,
  updateFormPerformanceRequest,
  updateFormPerformanceSuccess,
  updateFormPerformanceError,
  getScheduleByIdRequest,
  getScheduleByIdSuccess,
  getScheduleByIdError,
  resetState,
  addToPlayListSchedulePerfomancesRequest,
  addToPlayListSchedulePerfomancesSuccess,
  addToPlayListSchedulePerfomancesError,
  getRemoveTargetArtistRequest,
  getRemoveTargetArtistSuccess,
  getRemoveTargetArtistError
} = schedulePerformance.actions;
export default schedulePerformance.reducer;
