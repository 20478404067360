import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScheduledPerformancesAPI from '../../services/SearchSchedule';
import { Spinner } from '../spinner';
import { isLoading } from '../../selectors/user.selector';
import defaultuser from '../../assets/img/edit-profile/defaultuser.png';
import {
  getSchedulePerformanceRequest,
  updateFormPerformanceSuccess
} from '../../slices/schedulePerformance';
import './styles.scss';

const ImageUploadScheduledPerfomance = ({
  getUser,
  buttonColor,
  setReRendingSchedules,
  onPictyreUpload,
  getImage,
  classLabel
}) => {
  const dispatch = useDispatch();
  const isloading = useSelector(isLoading);
  const [loading, setLoading] = useState(false);
  const [loadImg, setLoadImg] = useState();
  const [imageURLs, seImageURLs] = useState();
  const uploadPicture = async (e) => {
    setLoading(true);
    setLoadImg(URL.createObjectURL(e.target.files[0]))

    onPictyreUpload(e.target.files[0]);
  };

  useEffect(() => {
    if(loadImg) {
      setLoading(false);
    }

  },[loadImg])

  return (
    <div
      aria-label="Change Profile Picture"
      className="block-img-upload-upload"
    >
      {/*<div*/}
      {/*  className='avatar-img'*/}
      {/*  style={{*/}
      {/*    backgroundImage: `url(${getUser?.avatarUrl || defaultuser})`*/}
      {/*  }}*/}
      {/*>*/}
      {/*</div>*/}
      <div className="img-upload-img-sheduled">
        <div className="img-upload-img__photo">
          {getUser?.values.thumbnailUrl ? (
            <>
              { loadImg ? (
                ''
              ):(
                <img src={getUser?.values.thumbnailUrl} alt="img" />
              )
              }
            </>

          ) : (
            <>
              { loadImg ? (
                  ''
                ):(
                  <div className="form-group">
                    <div className={classLabel}>IMAGE</div>
                  </div>
                )
              }

            </>
          )}
          { loadImg && !getUser?.values.thumbnailUrl? (
              <img src={loadImg}  alt="img" />
            ):(
              ''
            )
          }
        </div>
      </div>
      {loading || isloading ? (
        <Spinner />
      ) : (
        <>
          <div
            className="img-upload-button-upload"
            style={{ backgroundColor: buttonColor ? '#FF6E09' : '' }}
          >
            <input
              className="input-ava"
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={uploadPicture}
            />
            Upload Image
          </div>
        </>
      )}
    </div>
  );
};
export default ImageUploadScheduledPerfomance;