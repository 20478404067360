import React, { useRef, useEffect, useState, useCallback } from 'react';
import styles from './PopupFeedback.module.scss';
import sprite from '../../assets/svg/sprite.svg';
import { useFormik } from 'formik';
import { Button } from 'react-bootstrap';
import * as yup from 'yup';
import { setFeedbackRequest } from '../../slices/user';
import { useDispatch } from 'react-redux';

const PopupFeedback = ({onClose, email}) => {
  const wrapperRef = useRef(null);
  const [deviceInfo, setDeviceInfo] = useState(getDeviceInfo());
  const [pageUrl, setPageUrl] = useState(window.location.href);
  const profileUser = JSON.parse(window.localStorage.getItem('profile_user'));
  const userEmail = email ? email : profileUser.email;
  const [disabled, setDisabled] = useState(true)
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema: yup.object().shape({
      message: yup.string()
        .min(15, "Must be at least 15 characters")
        .required("Required"),
    }),
    onSubmit: (values,{ resetForm }) => {
      const feedbackForm = {
        email: userEmail,
        pageUrl: pageUrl,
        deviceInfo: deviceInfo,
        message: values.message
      }
      dispatch(setFeedbackRequest(feedbackForm));
      resetForm();
      onClose();
    }
  });


  function getDeviceInfo() {
    const userAgent = navigator.userAgent;

    let os = '';
    if (userAgent.match(/Windows/i)) {
      os = 'Windows';
    } else if (userAgent.match(/Macintosh|Mac OS/i)) {
      os = 'Mac OS';
    } else if (userAgent.match(/Linux/i)) {
      os = 'Linux';
    } else if (userAgent.match(/Android/i)) {
      os = 'Android';
    } else if (userAgent.match(/iOS/i)) {
      os = 'iOS';
    } else {
      os = 'Unknown';
    }

    let browser = '';
    if (userAgent.match(/Chrome/i)) {
      browser = 'Chrome';
    } else if (userAgent.match(/Safari/i)) {
      browser = 'Safari';
    } else if (userAgent.match(/Firefox/i)) {
      browser = 'Firefox';
    } else if (userAgent.match(/Edge/i)) {
      browser = 'Edge';
    } else if (userAgent.match(/Opera|OPR/i)) {
      browser = 'Opera';
    } else if (userAgent.match(/IE|MSIE|Trident/i)) {
      browser = 'Internet Explorer';
    } else {
      browser = 'Unknown';
    }

    return `OS: ${os}, Browser: ${browser}`;
  }

  return (
    <div className={styles.popupFeedback} ref={wrapperRef}>
      <div className={styles.head}>
        <h3>Share feedback with the frisson team </h3>
        <button onClick={onClose}>
          <svg style={{ width: '16px', height: '16px' }}>
            <use href={sprite + '#icon_close_grey'}></use>
          </svg>
        </button>
      </div>
      <form className={styles.content} onSubmit={formik.handleSubmit} noValidate>
        <p className={styles.description}>Send us a message to let us know what think of frisson, to report a bug, or to get some help.</p>
        <textarea
          name='message'
          formik={formik}
          rows='5'
          placeholder="Write your message here"
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        >
        </textarea>
        {formik.errors.message && formik.touched.message && (
          <div className={styles.errorMessage}>{formik.errors.message}</div>
        )}
        <Button
          variant='primary'
          type='submit'
          className='btn-submit'
          style={{ width: '100%' }}
          disabled={!formik.dirty}
        >
          Send Message
        </Button>
      </form>
    </div>
  )
}

export default PopupFeedback;