import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import React, { useState } from 'react';
import emojiData from 'shared/emojiTable.json';
import GraphemeSplitter from "grapheme-splitter"
// import  {EmojiButton} from '@joeattardi/emoji-button';
import { createPopup } from '@picmo/popup-picker';
import { useRef } from 'react';

const VibesUI_EmojiSelector = (props) => {

    const [emojiNative, setEmojiState] = useState(props.emojiNative)
    const [visibleClass, setVisibleClass] = useState(false);

    const splitter = new GraphemeSplitter()
    const picker = React.useMemo(() => {
        
        let picker =  new createPopup({
            theme: 'picmo__dark',
            showVariants: false,
            position: 'bottom',
            showPreview: false,
            visibleRows: 5,
            emojiSize: '1.8rem'
        }, {
            onPositionLost: 'close'
        });

        picker.addEventListener('emoji:select', selection => {
            // document.querySelector('.emojiButton').innerHTML = selection.emoji;
            console.log("selection made")
            selectedEmoji(selection)
        });
        picker.addEventListener('picker:close', selection => {
            setVisibleClass(false)
        }); 

        return picker
    }, [])
    
    function openPicker () {
        const trigger = document.querySelector('.emojiButton')
        picker.referenceElement = trigger
        picker.triggerElement = trigger
        picker.open()
    }

    function selectedEmoji(emoji) {
        if (emoji.emoji != props.emojiNative) {
            console.log(emoji.emoji)
            setEmojiState(emoji.emoji)
            props.selectedEmoji(emoji)
        }
        

        setVisibleClass(prevState => false)
    }

    function onInput (event) {
        
        let msgEmojis = emojiParse(event.target.value)

        let emoji = {
            hexcode : msgEmojis.length ? msgEmojis[0].codePointAt(0).toString(16) : '',
            emoji: msgEmojis.length ? msgEmojis[0] : ''
        }

        setEmojiState(emoji.emoji)
        props.selectedEmoji(emoji)
        
    }


    function emojiParse (input) {
        const withEmojis = /\p{Extended_Pictographic}/u
    
        let split = splitter.splitGraphemes(input)
        let arr = [];
        for (var i=0; i<split.length; i++) {
            let char = split[i]
            if (withEmojis.test(char) && !arr.includes(char)) {
                arr.push(char);
            }
        }
        return arr;
    }

    return (
        <div className={`vibesUI--emoji_selector`}>
            <div className='emojiInput'>
                <input value={emojiNative} onChange={onInput}></input>
            </div>
            {/* <div className='emojiButton'>
                <button className={ visibleClass ? 'selected' : ''} onClick={() => {setVisibleClass(prevState => !prevState)}}>🙂</button>
                
                
            </div> */}

            <div className='emojiButton'>
                <button className={ visibleClass ? 'selected' : ''} onClick={() => {
                        // picker.togglePicker(document.querySelector('.emojiButton'))
                        if (!visibleClass) {
                            openPicker()
                        } 
                        else {
                            picker.close()
                            
                        }
                        setVisibleClass(prevState => !prevState)
                    }}>🙂</button>
                
                
            </div>
            
            {/* <div className={ visibleClass ? 'emojiPicker' : 'hidden'}>
                    <Picker  data={data} onEmojiSelect={selectedEmoji} />
                </div> */}
        </div>
    );
};

export default VibesUI_EmojiSelector;