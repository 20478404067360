import React from "react";
// import ReactDOM from "react-dom";
// import { Redirect, Route } from "react-router-dom";
// import './style.css'
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import jwt_decode from 'jwt-decode' 
import {Character, sharedAnims, CharacterClassInit} from 'shared/audiophile/character'
import gsap from 'gsap'
// import DynamicMenu from "components/dynamic-menu";

import { dragControls,dragAction,mouse, interactables, prepControlObjs, setInitialVal, getVal, getRatio, draggableShelves, removeShelves, dispose, tick, toggleCanTick } from 'shared/audiophile/drag'
import User from 'shared/audiophile/models/user'
// import { screenSizeListener, screenSizeStates, mediaQueryState, sizes } from './mediaQueries'
// import Catalog from './models/catalog'
import { object } from "prop-types";
import { InitTut } from "shared/audiophile/onboarding";
import Loading from "components/loading";
// import { Cart } from './models/cart'

class CharacterViewer extends React.Component {
    constructor(props) {
        super(props)
    
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.animate = this.animate.bind(this)
      }
    
      componentDidMount() {

        const width = this.mount.clientWidth
        const height = this.mount.clientHeight
    
        const scene = new THREE.Scene()
        const camera = new THREE.PerspectiveCamera(
          25,
          width / height,
          0.1,
          1000
        )
        
        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true})
        const canvas = renderer.domElement
        
        renderer.setClearColor( 0x000000, 0 )
        renderer.setSize(width, height)
    
        this.scene = scene
        this.camera = camera
        this.renderer = renderer

        //DISPOSING
        this.dragDispose = dispose

        /**
         * Constants
         */

        const titleId = '10B75'

        /**
         * Lights
         */
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
        scene.add(ambientLight)

        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
        directionalLight.castShadow = true
        directionalLight.shadow.mapSize.set(1024, 1024)
        directionalLight.shadow.camera.far = 15
        directionalLight.shadow.camera.left = - 7
        directionalLight.shadow.camera.top = 7
        directionalLight.shadow.camera.right = 7
        directionalLight.shadow.camera.bottom = - 7
        directionalLight.position.set(5, 5, 5)
        scene.add(directionalLight)

        const rimLight = new THREE.PointLight(0xC73AFC, 0.5)
        rimLight.position.set(-1.5, 0, 0.75)
        rimLight.name = "RimLight"
        // scene.add(rimLight)

        const spotLight = new THREE.SpotLight( 0xffffff );
        spotLight.position.set( -2, 3, 0 );

        spotLight.castShadow = true;

        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;

        spotLight.shadow.camera.near = 500;
        spotLight.shadow.camera.far = 4000;
        spotLight.shadow.camera.fov = 30;
        spotLight.penumbra = 1
        spotLight.angle = 1
        spotLight.target.position.set(3,3,0.5)
        spotLight.intensity = 1
        scene.add( spotLight );
        scene.add( spotLight.target )

        const cylGeo = new THREE.BoxGeometry( 1,3,1 );
        const cylMat = new THREE.MeshBasicMaterial( {color: 0xffff00} );
        const characterSpinner = new THREE.Mesh( cylGeo, cylMat );
        characterSpinner.position.set(0.09,1.5,0)
        characterSpinner.name = "Char Spinner Target"
        characterSpinner.tag = "spinner"
        characterSpinner.callbacks = [spinChar, resetCharSpin]
        characterSpinner.visible = false
        interactables.push(characterSpinner)
        scene.add( characterSpinner );


        /**
         * Models
         */
        let anims = {}
        const modelPath = 'https://audiophileitems.blob.core.windows.net/items/'
        const dracoLoader = new DRACOLoader()
        const gltfLoader = new GLTFLoader()
        dracoLoader.setDecoderPath('/draco/')

        gltfLoader.setDRACOLoader(dracoLoader)

        const textureLoader = new THREE.TextureLoader( );

        //display loading
        document.getElementsByClassName('loading')[0].style.display = 'block'
        
        // let backdrop = gltfLoader.load(modelPath + 'backdrop.glb', (object) => {
        //     let obj = object.scene
        //     obj.name = "Backdrop"
        //     obj.position.set(0,0,0)
        //     obj.rotation.set(0,1.5,0)
        //     obj.scale.set(2,4,15)

        //     // obj.material.color = '#444444'
        //     // obj.material.metalness = 0
        //     // obj.material.roughness = 0.5

        //     obj.children[0].material = new THREE.MeshStandardMaterial({
        //         color: '#c8c8c8',
        //         metalness: 0,
        //         roughness: 0.5
        //     })
        //     obj.children[0].receiveShadow = true

        //     scene.add(obj)
        // })

        // let atm = gltfLoader.load(modelPath + 'atm.glb', (object) => {
        //     let obj = object.scene
        //     obj.name = "ATM"
        //     obj.position.set(1.04, 0.23, -0.2)
        //     obj.rotation.set(0,0,0)
        //     obj.scale.set(0.15,0.15,0.15)

        //     obj.material = new THREE.MeshLambertMaterial({
        //         color: '#ffffff'
        //     })

        //     scene.add(obj)
        // })



//===========================
//
//     User Properties
// 
//===========================



function GetUser () {
    let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
    if (appState?.auth.isAuthenticated) {
        return new User(jwt_decode(appState.auth.currentUser))
    }
    else {
        console.log("Cannot get user, we should return to login")
        window.location.href = '/'
        return null
    }
}





//===========================
//
//     Init
// 
//===========================

const characters = {};
this.characters = characters

const user = GetUser()

InitTut('profile', user, ["EmptyStep"])

GetUserCharacter(user)

function GetUserCharacter () {
    //get user main character
    fetch('https://' + titleId + '.playfabapi.com/Client/GetUserReadOnlyData', {
        method: 'POST',
        headers: {
            'Accept' : 'text/plain',
            'Content-Type': 'application/json',
            'X-Authorization' : user.playFabSessionToken
        },
        body:  JSON.stringify({
            "Keys" : ["mainCharacter"]
        })
    })
    .then(function(response){
        if (response.status >= 400 && response.status < 600) {
            response.json().then(function(object) {
                alert(object.errorMessage);
            })
        } else if (response.status === 200) {
            return response.json()
        }
        
    }).then(data => {
        user.mainCharacter = data.data.Data.mainCharacter.Value

        //we have to get all the user's characters first
        fetch('https://' + titleId + '.playfabapi.com/Client/GetAllUsersCharacters', {
            method: 'POST',
            headers: {
                'Accept' : 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization' : user.playFabSessionToken
            },
            body:  JSON.stringify({
                "PlayFabId" : user.playFabId
            })
        })
        .then(function(response){
            if (response.status >= 400 && response.status < 600) {
                response.json().then(function(object) {
                    alert(object.errorMessage);
                })
            } else if (response.status === 200) {
                return response.json()
            }
            
        })
        .then(data => {
            let userChars = []
            if (Object.keys(data.data.Characters).length != 0) {

                for (let i = 0; i < data.data.Characters.length; i++) {
                    const char = data.data.Characters[i];

                    if (char.CharacterType === 'audiophile-1') {
                        userChars.push(char)
                    }
                    
                }

                if (userChars.length) {
                    user.characters = userChars

                    //get the data now that we have the character id
                    fetch('https://' + titleId + '.playfabapi.com/Client/GetCharacterReadOnlyData', {
                        method: 'POST',
                        headers: {
                            'Accept' : 'text/plain',
                            'Content-Type': 'application/json',
                            'X-Authorization' : user.playFabSessionToken
                        },
                        body:  JSON.stringify({
                            "PlayFabId" : user.playFabId,
                            "CharacterId": user.mainCharacter,
                            "Keys" : ["items", "customizations", "animations"]
                        })
                    })
                    .then(function(response){
                        if (response.status >= 400 && response.status < 600) {
                            response.json().then(function(object) {
                                alert(object.errorMessage);
                            })
                        } else if (response.status === 200) {
                            return response.json()
                        }
                        
                    })
                    .then(data => {

                        if (Object.keys(data.data.Data).length != 0) {

                            let props = data.data.Data;
                            
                            console.log("successful data fetch")
                            let charProps = {
                                "items" : JSON.parse(props.items.Value),
                                "customizations" : JSON.parse(props.customizations.Value),
                                "animations" : JSON.parse(props.animations.Value)
                            }
                            let newChar = new Character(charProps)
                            characters[user.mainCharacter] = newChar
                            newChar.spawn(user.playFabId,user.mainCharacter,0,0,scene, ()=> {
                                    //hide loading
                                    if (document.getElementsByClassName('loading')[0]) {
                                        document.getElementsByClassName('loading')[0].style.display = 'none'
                                    }
                                })
                            newChar.body.rotation.set(0,0,0)

                            //get existing inventory
                            // GetOwnedItems()

                            // PrepInitialControlPops(charProps)

                        }
                        else {
                            console.log("no character item data, drop in the defaults")
                        }

                        
                        
                    }).catch(function (err) {

                    //no existing session with this id
                        return console.log(err);
                    });
                }
                else {
                    return console.log("User has no character added to account")
                }

                

            }
            else {
                console.log("User has no characters somehow...")
            }
            
        }).catch(function (err) {

        //no existing session with this id
            return console.log(err);
        });
    })
}

function GetMainCharacter () {
    return user.characters?.[0].CharacterId
}
function spinChar (delta) {
    // console.log("spinning char this much: " + delta)
    characters[user.mainCharacter].body.rotation.y += (delta * 0.006)

}

function resetCharSpin () {
    gsap.to(characters[user.mainCharacter].body.rotation, {duration: 0.5, y: 0})
}

/**
 * Camera
 */
// Base camera
// const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0,2.55,7)
camera.rotation.set(-0.15, 0, 0)
scene.add(camera)


/**
 * Renderer
 */
// const renderer = new THREE.WebGLRenderer({
//     antialias: true,
//     canvas: canvas
// })
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap


renderer.setSize( width, height, false)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
camera.aspect = width / height;
camera.updateProjectionMatrix();

//Drag Controls
dragControls(canvas,camera)

//start dragging tick
toggleCanTick(true)
tick()

//screen resizer + change callback
// screenSizeListener(camera, renderer, mediaQueryChange)

function mediaQueryChange (state) {
    console.log("Changed screen to " + state)

    switch (state) {
        case 'desktop':
            
            break;
        case 'tabletLandscape':

            break;
        case 'tabletPortrait':

            break;
        case 'mobileLandscape':

            break;
        case 'mobilePortrait':

            break;
        default:
            break;
    }

}

    
        this.mount.appendChild(this.renderer.domElement)
        this.start()
      }
    
      componentWillUnmount() {
        this.stop()
        this.mount.removeChild(this.renderer.domElement)
        // Traverse the whole scene
        this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })
        this.dragDispose()
      }
    
      start() {
        const clock = new THREE.Clock()
        let previousTime = 0

        if (!this.frameId) {
          this.frameId = requestAnimationFrame(() => {this.animate(clock, previousTime)})
        }
      }
    
      stop() {
        cancelAnimationFrame(this.frameId)
      }
    
      animate(clock, previousTime) {
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = elapsedTime - previousTime
        previousTime = elapsedTime
        //update all character animation mixers
        for (const [id, char] of Object.entries(this.characters)) {
            if (char.currentAnim)
                char.mixer.update(deltaTime)
        }


    
        this.renderScene()
        this.frameId = window.requestAnimationFrame(() => {this.animate(clock, previousTime)})
      }
    
      renderScene() {
        this.renderer.render(this.scene, this.camera)
      }
    
      render() {
        return (
            <div style={{ width: '100%', height: '100%', position: "relative"}}>

                <div
                style={{ width: '100%', height: '100%'}}
                ref={(mount) => { this.mount = mount }}
                >
                    <Loading></Loading>
                </div>
            </div>
            
            
            
        )
      }
}

// const rootElement = document.getElementById("root")
// ReactDOM.render(<DressingRoom />, rootElement);
export default CharacterViewer;