// import React, { Fragment } from "react";
// import { Helmet } from "react-helmet";

// // layout
// import { LayoutDefault } from "../../layouts";
// import { Header } from "../../components/header";
// import { Footer } from "../../container/footer";

// // container
// import GenresList from "../../container/genres-list";
// import DropDownMenu from "../../container/dropdown-menu";
// import ListCardLive from "../../container/list-live-now";
// import ListRecommendedShows from "../../container/list-recommended-shows";
// import SuggestedShows from "../../container/suggested-shows";

// // data
// import frisson from "../../data/frisson.json";
// import { typeGenre } from "../../selectors/genres.selector";
// import { useSelector } from "react-redux";

// import "./styles.scss";

// const DressingRoom = () => {
//   const { genre } = useSelector(typeGenre);

//   return (
//     <Fragment>
//       <Helmet>
//         <title>{`${frisson.title}`}</title>
//       </Helmet>
//       <p>Hello World</p>
//     </Fragment>
//   );
// };

// export default DressingRoom;

import React from 'react';
// import ReactDOM from "react-dom";
// import { Redirect, Route } from "react-router-dom";
import './style.css'
import * as THREE from "three";
import gsap from 'gsap'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import jwt_decode from 'jwt-decode' 
import { dragControls,dragAction,mouse, interactables, prepControlObjs, setInitialVal, getVal, getRatio, draggableShelves, removeShelves, currentCubes, dispose, togglePointer, SetCubePosition, tick, toggleCanTick } from './drag'
import User from 'shared/audiophile/models/user'
import {Character} from 'shared/audiophile/character'
import { screenSizeListener, screenSizeStates, mediaQueryState, sizes, MQDIsposeEvents } from 'shared/audiophile/mediaQueries'
import Catalog from '../../shared/audiophile/models/catalog'
import { Cart } from './models/cart'
import DynamicMenu from "components/dynamic-menu";
import Loading from 'components/loading';
import {InitTut, CheckTutorialStep, arrowData} from "shared/audiophile/onboarding"
import { setupUI } from 'shared/ui-builder';
import hslToHex from 'shared/audiophile/hslToHex';
import { SendGTMEvent } from 'shared/utils/gtmEvents';
import ThreeMeshUI from 'three-mesh-ui'
import DB from './assets/dB-white.png'
import FontJSON from "./assets/nunito-bold-msdf.json"
import FontImage from "./assets/nunitobold.png"
import DynamicMenuModernized from 'components/dynamic-menu-modernized';

class Store extends React.Component {
  constructor(props) {
    super(props);

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.animate = this.animate.bind(this);
  }

  componentDidMount() {

    //display loading
    document.getElementsByClassName('loading')[0].style.display = 'block'

    //insert temp html
    // const container = document.getElementById('root')
    // let cartContainer = document.createElement('div')
    // cartContainer.setAttribute('id', 'cartContainer')
    // cartContainer.innerHTML = '<p>Cart</p><p id="cartTotal">Total: 0DB</p></div>'
    // container.appendChild(cartContainer)

    // let buyButton = document.createElement('p')
    // buyButton.setAttribute('id', 'buyButton')
    // buyButton.innerHTML = 'BUY'

    // container.appendChild(buyButton)

    //add styles to body
    document.body.style.height = '100%'
    document.body.style.position = 'fixed'
    document.body.style.overflowY = 'scroll'
    document.documentElement.style.overflow = 'hidden'
    document.documentElement.style.width = '100%'

    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;

    
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    const canvas = renderer.domElement;

    renderer.setClearColor('#000000');
    renderer.setSize(width, height);
    console.log('mounting: should be creating a brand new scene: '+ scene.uuid)

    this.scene = scene;
    console.log('mounting: new scene: '+ this.scene.uuid)
    this.camera = camera;
    this.renderer = renderer;

    //DISPOSING
    function Dispose () {
        // DisposeEvents()
        MQDIsposeEvents()
    }
    this.eventsDispose = Dispose

    /**
     * Constants
     */

    const titleId = '10B75';

    /**
     * Lights
     */
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.set(1024, 1024);
    directionalLight.shadow.camera.far = 15;
    directionalLight.shadow.camera.left = -7;
    directionalLight.shadow.camera.top = 7;
    directionalLight.shadow.camera.right = 7;
    directionalLight.shadow.camera.bottom = -7;
    directionalLight.position.set(5, 5, 5);
    scene.add(directionalLight);

    const rimLight = new THREE.PointLight(0xccc, 0.3);
    rimLight.position.set(-200, 0, 0.75);
    rimLight.name = 'RimLight';
    scene.add(rimLight);

    const spotLight = new THREE.SpotLight(0xfde06d);
    spotLight.position.set(-0.5, 10, 9);

    spotLight.castShadow = true;

    spotLight.shadow.mapSize.width = 1024;
    spotLight.shadow.mapSize.height = 1024;

    spotLight.shadow.camera.near = 500;
    spotLight.shadow.camera.far = 4000;
    spotLight.shadow.camera.fov = 30;
    spotLight.penumbra = 1;
    spotLight.angle = 0.3;
    spotLight.target.position.set(3, 3, 0.5);
    spotLight.intensity = 0.6;
    scene.add(spotLight);
    scene.add(spotLight.target);

    const cylGeo = new THREE.BoxGeometry(1, 3, 1);
    const cylMat = new THREE.MeshBasicMaterial({ color: 0xffff00 });
    const characterSpinner = new THREE.Mesh(cylGeo, cylMat);
    characterSpinner.position.set(0.09, 1.5, 0);
    characterSpinner.name = 'Char Spinner Target';
    characterSpinner.tag = 'spinner';
    characterSpinner.callbacks = [spinChar, resetCharSpin];
    characterSpinner.visible = false;
    interactables.push(characterSpinner);
    scene.add(characterSpinner);

    /**
     * Models
     */
    let anims = {};
    const modelPath = 'https://audiophileitems.blob.core.windows.net/items/';
    const dracoLoader = new DRACOLoader();
    const gltfLoader = new GLTFLoader();
    dracoLoader.setDecoderPath('/draco/');

    gltfLoader.setDRACOLoader(dracoLoader);

    const textureLoader = new THREE.TextureLoader();
    let texture1 = textureLoader.load(modelPath + 'empty.png');
    let texture2 = textureLoader.load(modelPath + 'hoody_base.png');
    let texture3 = textureLoader.load(modelPath + 'blush.png');
    let texture4 = textureLoader.load(modelPath + 'face.png');

    texture1.flipY = false;
    texture2.flipY = false;
    texture3.flipY = false;
    texture4.flipY = false;

    //container for onboarding arrow targets
    this.arrowTargets = {}
    this.arrowData = arrowData

    const geometry = new THREE.BoxGeometry( 1, 1, 1 );
    const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
    // const cube = new THREE.Mesh( geometry, material );
    // cube.name = 'inventory test'
    // cube.scale.set(0.1,0.1,0.1)
    // scene.add( cube );

    //Load Enviro scene
    let mixerControls;

    gltfLoader.load(modelPath + 'storeMixer.glb', (object) => {
      let obj = object.scene;
      obj.name = 'Mixer';
      obj.position.set(1.82, 1.24, 2.52);
      obj.rotation.set(0.67, 0, 0);
      obj.scale.set(1.075, 1, 1);

      //find all interactables
      obj.children.forEach((el) => {
        //this is kind of janky
        //this also needs to change to switch from catalog to inventory
        // prepControlObjs(el, CategoryStartSpawn)

        //find correct select callback
        if (el.name.includes('select')) {
          switch (el.name.split('|')[0]) {
            // case 'headMorphPlus':
            //   prepControlObjs(el, () => {
            //     MorphMainChar(true, true);
            //   });
            //   break;
            // case 'headMorphMinus':
            //   prepControlObjs(el, () => {
            //     MorphMainChar(false, true);
            //   });
            //   break;
            // case 'bodyMorphPlus':
            //   prepControlObjs(el, () => {
            //     MorphMainChar(true, false);
            //   });
            //   break;
            // case 'bodyMorphMinus':
            //   prepControlObjs(el, () => {
            //     MorphMainChar(false, false);
            //   });
            //   break;
            // case 'save':
            //   prepControlObjs(el, SaveCharacter);
            //   break;
            case "head_topper":
              this.arrowTargets['headCategory'] = el
              prepControlObjs(el, CategoryStartSpawn)
              break;
            case 'save':
              el.disabled = true
              this.arrowTargets['purchase'] = el
              prepControlObjs(el, PurchaseItems);
              break;
            default:
              prepControlObjs(el, CategoryStartSpawn);
              break;
          }
        } else {
          prepControlObjs(el);
        }
      });
      
      scene.add(obj);
      mixerControls = obj;

      //init controls
      InitMixer(obj)

      GetUserCharacter(user);

    });
    let inventoryArrowTarget = new THREE.Object3D()
    inventoryArrowTarget.position.set(2.15,1.27,2.53)
        scene.add(inventoryArrowTarget)
        this.arrowTargets['inventoryTarget'] = inventoryArrowTarget


    let backdrop = gltfLoader.load(modelPath + 'backdrop.glb', (object) => {
      let obj = object.scene;
      obj.name = 'Backdrop';
      obj.position.set(0, 0, 0);
      obj.rotation.set(0, 1.5, 0);
      obj.scale.set(2, 4, 15);

      // obj.material.color = '#444444'
      // obj.material.metalness = 0
      // obj.material.roughness = 0.5

      obj.children[0].material = new THREE.MeshStandardMaterial({
        color: '#c8c8c8',
        metalness: 0,
        roughness: 0.5
      });
      obj.children[0].receiveShadow = true;

      scene.add(obj);
    });

    // let tunnel = gltfLoader.load(modelPath + 'dressingRoomTunnel.glb', (object) => {
    //   let obj = object.scene;
    //   obj.name = 'Tunnel';
    //   obj.position.set(-0.36, -0.04, 1.5);
    //   obj.rotation.set(0, -0.36, 0);
    //   obj.scale.set(0.35, 0.35, 0.35);

    //   // obj.material.color = '#444444'
    //   // obj.material.metalness = 0
    //   // obj.material.roughness = 0.5

    //   obj.children[0].material = new THREE.MeshStandardMaterial({
    //     color: '#c8c8c8',
    //     metalness: 0,
    //     roughness: 0.5
    //   });
    //   obj.children[0].receiveShadow = true;

    //   scene.add(obj);
    // });

    let shelfContainer = new THREE.Group();
    shelfContainer.name = 'ShelfContainer';
    shelfContainer.position.set(2.7, 0, 0.54);
    shelfContainer.rotation.set(0, -0.39, 0);
    shelfContainer.scale.set(1.5, 1.5, 1.5);

    // const modelPath = '/models/Character/mixamo/new/'

    // let charProps = {
    //     "items" : {
    //         "head" : "head_only",
    //         "head_acc" : "head_acc",
    //         "face" : "",
    //         "face_acc" : "face_acc",
    //         "body" : "body_jazz",
    //         "body_acc" : "body_acc",
    //         "hands" : "hands_only",
    //         "feet" : "feet_only"
    //     },
    //     "customizations" : {
    //         "headSize":1,
    //         "bodySize":1,
    //         "handSize":1,
    //         "feetSize":1,
    //         "neckLength":0.9,
    //         "armLength":1,
    //         "legLength":-0.2
    //     },
    //     "animations" : [
    //         "twist"
    //     ]
    // }

    let defaultCustomizations = {
      customizations: {
        headSize: 1,
        bodySize: 1,
        handSize: 1,
        feetSize: 1,
        neckLength: 0.9,
        armLength: 1,
        legLength: -0.2
      }
    };

    let divString =
      '<div id="cartContainer">' +
      '<p>Cart</p><p id="cartTotal">Total: 0DB</p>' +
      '<p id="buyButton">BUY</p>' +
      '</div>';

      //post-purchase overlay
    document.getElementById('root').insertAdjacentHTML('beforeend', '<div id="confirmModal" class="hidden"><p>Do you want to equip your new purchases?</p><div class="buttonContainer"><span class="" id="equip">equip</span><span id="equipCancel" class="">revert</span></div></div>')

    //open close modal
    document.getElementById("equip").addEventListener("click", () => {
      SaveCharacter()
    }, false) 

    //open close modal
    document.getElementById("equipCancel").addEventListener("click", () => {
      RevertItems()
    }, false)   

    //hide modal
    document.getElementById('confirmModal').classList.add('hidden')

    // function createCSS3DObject(s) {
    //     // convert the string to dome elements
    //     var wrapper = document.createElement('div');
    //     wrapper.innerHTML = s;
    //     var div = wrapper.firstChild;

    //     // set some values on the div to style it, standard CSS
    //     div.style.width = '375px';
    //     div.style.height = '375px';
    //     div.style.opacity = 1;
    //     div.style['will-change'] = 'all'
    //     div.style.transition = 'top 0.2s linear'
    //     div.style.background = new THREE.Color(0x000000).getStyle();
    //     div.style.color = new THREE.Color(Math.random() * 0xff0000).getStyle();

    //     // create a CSS3Dobject and return it.
    //     var object = new CSS3DObject(div);
    //     return object;
    // }

    // const cssElement = createCSS3DObject(divString);
    // cssElement.name = "3dCSS"
    // cssElement.position.set(0, 0, 0);
    // scene.add(cssElement);

    //===========================
    //
    //     User Properties
    //
    //===========================

    function GetUser() {
      let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
      if (appState?.auth.isAuthenticated) {
        return new User(jwt_decode(appState.auth.currentUser));
      } else {
        console.log('Cannot get user, we should return to login');
        window.location.href = '/';
        return null;
      }
    }

    const user = GetUser();
    // GetUserCharacter(user);

    let charProps2 = {
      items: {
        head: 'head_only',
        head_acc: 'head_acc',
        face_acc: 'face_acc',
        body: 'body_only',
        body_acc: 'body_jazz',
        hands: 'hands_only',
        feet: 'feet_only'
      },
      customizations: {
        headSize: 0.8,
        bodySize: 1,
        handSize: 1,
        feetSize: 1,
        neckLength: 1,
        armLength: 1,
        legLength: 0.1
      },
      animations: [
        'twist'
        // "slide",
        // "robot"
      ],
      loadedParts: 0
    };

    async function GetCurrency() {
      const currency = await fetch(
          'https://'+ titleId + '.playfabapi.com/Client/GetUserInventory',
          {
              method: 'POST',
              headers: {
                  Accept: 'text/plain',
                  'Content-Type': 'application/json',
                  'X-Authorization': user.playFabSessionToken
              }
          }
      )
      .then(function (response) {
          if (response.status >= 400 && response.status < 600) {
              // failure in getting totals
          } else if (response.status === 200) {
              return response.json()
          } else {
              // failure in getting totals
              return null
          }
      })
      .then((data) => {
          if (data) {
              // display the new total somewhere
              return data.data.VirtualCurrency
          }
      })
      .catch(function (err) {
          //no existing session with this id
          console.error(err.toString())
          return null
      })
      
      return currency
  }
    
    let currencyUI 
    async function InitMixer (mixerModel) {
      user.currency = await GetCurrency()


      
      cart.createTextContainer(mixerModel, user.currency)

      //populate mixer ui
      // currencyUI = buildStoreCurrency()
      
      // //update currency values
      // updateStoreCurrency(currencyUI, user.currency)
    }

    //===========================
    //
    //     Character Functions
    //
    //===========================

    let catalogVersion = 'Audiophile Items';

    const characters = {};
    this.characters = characters;

    //===========================
    //
    //     External Functions
    //
    //===========================

    /*    Default Assigns    */
    // const catalog = {
    //     "head_acc": [],
    //     "face_acc": [],
    //     "clothes": [],
    //     "body_acc": [],
    //     "hands_acc": [],
    //     "feet": []
    // }

    let catalog = new Catalog();

    // function FireCloudscript(cs, args) {
    //     fetch('https://' + titleId + '.playfabapi.com/Client/ExecuteCloudScript', {
    //         method: "POST",
    //         headers: {
    //             "Accept" : "text/plain",
    //             "X-Authorization" : sessionTicket,
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({

    //             "FunctionName" : cs,
    //             "FunctionParameter" : args

    //         })
    //     })
    //     .then(response => response.json())
    //     .then(data => {

    //         console.log('updated user info');

    //     });
    // }

    function GetUserCharacter() {
      //get user main character
      fetch(
        'https://' + titleId + '.playfabapi.com/Client/GetUserReadOnlyData',
        {
          method: 'POST',
          headers: {
            Accept: 'text/plain',
            'Content-Type': 'application/json',
            'X-Authorization': user.playFabSessionToken
          },
          body: JSON.stringify({
            Keys: ['mainCharacter']
          })
        }
      )
        .then(function (response) {
          if (response.status >= 400 && response.status < 600) {
            response.json().then(function (object) {
              alert(object.errorMessage);
            });
          } else if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          user.mainCharacter = data.data.Data.mainCharacter.Value;

          //we have to get all the user's characters first
          fetch(
            'https://' +
              titleId +
              '.playfabapi.com/Client/GetAllUsersCharacters',
            {
              method: 'POST',
              headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization': user.playFabSessionToken
              },
              body: JSON.stringify({
                PlayFabId: user.playFabId
              })
            }
          )
            .then(function (response) {
              if (response.status >= 400 && response.status < 600) {
                response.json().then(function (object) {
                  alert(object.errorMessage);
                });
              } else if (response.status === 200) {
                return response.json();
              }
            })
            .then((data) => {
              let userChars = [];
              if (Object.keys(data.data.Characters).length != 0) {
                for (let i = 0; i < data.data.Characters.length; i++) {
                  const char = data.data.Characters[i];

                  if (char.CharacterType === 'audiophile-1') {
                    userChars.push(char);
                  }
                }

                if (userChars.length) {
                  user.characters = userChars;

                  //get the data now that we have the character id
                  fetch(
                    'https://' +
                      titleId +
                      '.playfabapi.com/Client/GetCharacterReadOnlyData',
                    {
                      method: 'POST',
                      headers: {
                        Accept: 'text/plain',
                        'Content-Type': 'application/json',
                        'X-Authorization': user.playFabSessionToken
                      },
                      body: JSON.stringify({
                        PlayFabId: user.playFabId,
                        CharacterId: user.mainCharacter,
                        Keys: ['items', 'customizations', 'animations']
                      })
                    }
                  )
                    .then(function (response) {
                      if (response.status >= 400 && response.status < 600) {
                        response.json().then(function (object) {
                          alert(object.errorMessage);
                        });
                      } else if (response.status === 200) {
                        return response.json();
                      }
                    })
                    .then(async function (data) {
                      if (Object.keys(data.data.Data).length != 0) {
                        let props = data.data.Data;

                        console.log('successful data fetch');
                        let charProps = {
                          items: JSON.parse(props.items.Value),
                          customizations: JSON.parse(
                            props.customizations.Value
                          ),
                          animations: JSON.parse(props.animations.Value)
                        };
                        let newChar = new Character(charProps);
                        characters[user.mainCharacter] = newChar;
                        newChar.spawn(
                          user.playFabId,
                          user.mainCharacter,
                          0,
                          0,
                          scene, 
                          CharacterLoaded
                        );

                        //get existing inventory
                        GetOwnedItems();
                        setupUI(scene, camera, renderer, canvas)
                        // PrepInitialControlPops(charProps);
                        
                        console.log(user.currency)
                      } else {
                        console.log(
                          'no character item data, drop in the defaults'
                        );
                      }

                      InitTut('store', user, null, mixerControls)

                      //screen resizer + change callback
                      screenSizeListener(camera, renderer, mediaQueryChange);

                      //gui for changes
                      // gui.add(characters[user.characters[0].CharacterId].customizations.body.bodyColor, "h", 0, 360, 1).onChange(() => {
                      //     characters[user.characters[0].CharacterId].applyAdjustments()
                      // })
                      // gui.add(characters[user.characters[0].CharacterId].customizations.body.bodyColor, "s", 0, 100, 1).onChange(() => {
                      //     characters[user.characters[0].CharacterId].applyAdjustments()
                      // })
                      // gui.add(characters[user.characters[0].CharacterId].customizations.body.bodyColor, "v", 0, 100, 1).onChange(() => {
                      //     characters[user.characters[0].CharacterId].applyAdjustments()
                      // })
                      // gui.add(characters[user.characters[0].CharacterId].customizations.head, "neckLength", neckLengthProp.min, neckLengthProp.max, 0.001).onChange(() => {
                      //     characters[user.characters[0].CharacterId].applyAdjustments()
                      // })
                      // gui.add(characters[user.characters[0].CharacterId].customizations.body, "bodySize", bodySizeProp.min, bodySizeProp.max, 0.001).onChange(() => {
                      //     characters[user.characters[0].CharacterId].applyAdjustments()
                      // })
                      // // gui.add(charProps.customizations, "armLength", 0.9, 1.1, 0.001)
                      // gui.add(characters[user.characters[0].CharacterId].customizations.hands, "handSize", handSizeProp.min, handSizeProp.max, 0.001).onChange(() => {
                      //     characters[user.characters[0].CharacterId].applyAdjustments()
                      // })
                      // gui.add(characters[user.characters[0].CharacterId].customizations.feet, "legLength", legLengthProp.min, legLengthProp.max, 0.001).onChange(() => {
                      //     characters[user.characters[0].CharacterId].applyAdjustments()
                      // })
                      // gui.add(characters[user.characters[0].CharacterId].customizations.feet, "feetSize", feetSizeProp.min, feetSizeProp.max, 0.001).onChange(() => {
                      //     characters[user.characters[0].CharacterId].applyAdjustments()
                      // })
                      // gui.addColor(characters[user.characters[0].CharacterId].customizations.body, "bodyColor").name("Body Color").onChange(() => {
                      //     characters[user.characters[0].CharacterId].applyAdjustments()
                      // })
                    })
                    .catch(function (err) {
                      //no existing session with this id
                      return console.log(err);
                    });
                } else {
                  return console.log('User has no character added to account');
                }
              } else {
                console.log('User has no characters somehow...');
              }
            })
            .catch(function (err) {
              //no existing session with this id
              return console.log(err);
            });
        });
    }

    function GetMainCharacter() {
      return user.characters?.[0].CharacterId;
    }

    function CharacterLoaded () {
      togglePointer(true)
      //hide loading
      document.getElementsByClassName('loading')[0].style.display = 'none'
  }

    // function PrepInitialControlPops(charProps) {
    //   let props = {};

    //   Object.values(charProps.customizations).forEach((part) => {
    //     Object.keys(part).forEach((val) => {
    //       console.log(val);
    //       switch (val) {
    //         case 'headSize':
    //           props[val] = getRatio(
    //             part[val],
    //             headSizeProp.min,
    //             headSizeProp.max
    //           );
    //           break;
    //         case 'neckLength':
    //           props[val] = getRatio(
    //             part[val],
    //             neckLengthProp.min,
    //             neckLengthProp.max
    //           );
    //           break;
    //         case 'bodySize':
    //           props[val] = getRatio(
    //             part[val],
    //             bodySizeProp.min,
    //             bodySizeProp.max
    //           );
    //           break;
    //         case 'bodyColor':
    //           props['hue'] = getRatio(
    //             part[val].h,
    //             bodyColorProp.hMin,
    //             bodyColorProp.hMax
    //           );
    //           props['sat'] = getRatio(
    //             part[val].s,
    //             bodyColorProp.sMin,
    //             bodyColorProp.sMax
    //           );
    //           props['value'] = getRatio(
    //             part[val].v,
    //             bodyColorProp.vMin,
    //             bodyColorProp.vMax
    //           );
    //           break;
    //         case 'handSize':
    //           props[val] = getRatio(
    //             part[val],
    //             handSizeProp.min,
    //             handSizeProp.max
    //           );
    //           break;
    //         case 'legLength':
    //           props[val] = getRatio(
    //             part[val],
    //             legLengthProp.min,
    //             legLengthProp.max
    //           );
    //           break;
    //         case 'feetSize':
    //           props[val] = getRatio(
    //             part[val],
    //             feetSizeProp.min,
    //             feetSizeProp.max
    //           );
    //           break;
    //         default:
    //           break;
    //       }
    //     });
    //   });

    //   setInitialVal(props);
    // }

    function GetOwnedItems() {
      fetch('https://' + titleId + '.playfabapi.com/Client/GetUserInventory', {
        method: 'POST',
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
          'X-Authorization': user.playFabSessionToken
        }
        // body:  JSON.stringify({
        //     "CharacterId": user.characters[0].CharacterId
        // })
      })
        .then(function (response) {
          if (response.status >= 400 && response.status < 600) {
            response.json().then(function (object) {
              alert(object.errorMessage);
            });
          } else if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          if (Object.keys(data.data.Inventory).length != 0) {
            let inventory = [];
            let items = data.data.Inventory;

            for (let i = 0; i < items.length; i++) {
              //check if it's an item, it will have it's id delineated with a bar
              if (items[i].ItemId.includes('|')) {
                const item = parseCatalogItem(items[i]);

                if (item.type !== 'head') {
                  inventory.push(item);
                }
              }
            }

            user.inventory = new Catalog();

            inventory.forEach((item) => {
              // let name = {}
              // name[item.name] = () => {characters[user.characters[0].CharacterId].replacePart(item.type, item.id)}
              // gui.add(name, item.name)

              user.inventory[item.type].push(item);
            });

            // Object.keys(user.inventory).forEach(element => {
            //     let el = document.createElement('a')
            //     // let price = element.price == 0 ? "Free" : element.price + 'DB';
            //     let textnode = document.createTextNode(element + ' - ' + user.inventory[element].length)

            //     el.appendChild(textnode)
            //     // el.appendChild(document.createTextNode('\u2718'));
            //     el.onclick = function() {
            //         CategoryStartSpawn(element, user.inventory)
            //     }

            //     document.getElementById('inventoryContainer').appendChild(el)
            //     let linebreak = document.createElement("br");
            //     document.getElementById('inventoryContainer').appendChild(linebreak)
            // });

            // let save = {}
            // save["Save Character!"] = () => { SaveCharacter() }
            // gui.add(save, "Save Character!")

            // itemPool = user.inventory

            //now that we have the user's items, get catalog and filter out owned items
            GetCatalogItems();
          } else {
            console.log('no character item data, drop in the defaults');
          }
        })
        .catch(function (err) {
          //no existing session with this id
          return console.log(err);
        });
    }

    function GetCatalogItems() {
      //reset catalog
      catalog = new Catalog();

      fetch('https://' + titleId + '.playfabapi.com/Client/GetCatalogItems', {
        method: 'POST',
        headers: {
          Accept: 'text/plain',
          'Content-Type': 'application/json',
          'X-Authorization': user.playFabSessionToken
        },
        body: JSON.stringify({
          CatalogVersion: catalogVersion
        })
      })
        .then(function (response) {
          if (response.status >= 400 && response.status < 600) {
            response.json().then(function (object) {
              alert(object.errorMessage);
            });
          } else if (response.status === 200) {
            return response.json();
          }
        })
        .then((data) => {
          if (Object.keys(data.data.Catalog).length != 0) {
            // let catalog = [];
            let items = data.data.Catalog;

            for (let i = 0; i < items.length; i++) {
              //check if it's an item, it will have it's id delineated with a bar
              if (items[i].ItemId.includes('|')) {
                const item = parseCatalogItem(items[i]);

                if (
                  item.type !== 'head' &&
                  item.type !== 'body' &&
                  item.type !== 'hands'
                ) {
                  //make sure item isn't already in user's inventory
                  // let owned = false
                  // for (let j = 0; j < user.inventory[item.type].length; j++) {
                  //     const element = user.inventory[item.type][j];

                  //     if (element.id === item.id) {
                  //         owned = true
                  //     }
                  // }

                  // if (!owned) {
                  catalog[item.type]?.push(item);
                  // }
                }
              }
            }

            // Object.keys(catalog).forEach(element => {
            //     let el = document.createElement('a')
            //     // let price = element.price == 0 ? "Free" : element.price + 'DB';
            //     let textnode = document.createTextNode(element + ' - ' + catalog[element].length)

            //     el.appendChild(textnode)
            //     // el.appendChild(document.createTextNode('\u2718'));
            //     el.onclick = function() {
            //         CategoryStartSpawn(element, catalog)
            //     }

            //     document.getElementById('catalogContainer').appendChild(el)
            //     let linebreak = document.createElement("br");
            //     document.getElementById('catalogContainer').appendChild(linebreak)
            // });

            //hook up temp cart buy button
            // document.getElementById('buyButton').onclick = function () { PurchaseItems() }

            itemPool = catalog;

            //if there's already a category being displayed, display it again once
            if (currentCategory) CategoryStartSpawn();
          } else {
            console.log('no character item data, drop in the defaults');
          }
        })
        .catch(function (err) {
          //no existing session with this id
          return console.log(err);
        });
    }

    //when spawning categories:
    //determine shelf type by category type
    //spawn in the appropriate number of shelves based on type and number of items in category
    //keep track of shelf bones based on number of bones in particular shelf

    let loadedCategoryParts = 0;
    let currentCategory = null;
    let currentCatalog = null;
    let ownedItems = [];
    let itemPool = null;
    //shelf
    let currentShelf = null;
    let currentBone = 0;
    let shelves = [];
    let shelfType;
    let shelfOffset = 2.235;
    let boneOffset = 0;
    let heightOffset = 0;
    let scaleOffset = 0.02;
    const categoryContainer = [];

    function CategoryStartSpawn(category) {
      //disable pointer while loading
      togglePointer(false)
      //display loading
      document.getElementsByClassName('loading')[0].style.display = 'block'

      if (category) currentCategory = category;
      currentCatalog = itemPool;
      loadedCategoryParts = 0;
      ownedItems = [];

      //make sure item isn't already in user's inventory
      for (let i = 0; i < currentCatalog[currentCategory].length; i++) {
        const item = currentCatalog[currentCategory][i];

        for (let j = 0; j < user.inventory[currentCategory].length; j++) {
          const element = user.inventory[currentCategory][j];

          if (element.id === item.id) {
            ownedItems.push(i);
          }
        }
      }

      //shelf
      resetShelves();

      switch (currentCategory) {
        case "head_topper":
                case "head_acc":
                case "eyes":
                case "face_acc":
                    shelfType = 'cubesDisplay-4x3'
                    boneOffset = 12
                    heightOffset = -2.22
                    scaleOffset = 0.014
                    break
                case "hands_acc":
                    shelfType = 'cubesDisplay-4x3'
                    boneOffset = 12
                    heightOffset = -1.7
                    scaleOffset = 0.02
                    break
                case "feet":
                    shelfType = 'cubesDisplay-4x3'
                    boneOffset = 12
                    heightOffset = -0.55
                    scaleOffset = 0.011
                    break
                case "clothes":
                    shelfType = 'cubesDisplay-4x3'
                    boneOffset = 8
                    heightOffset = -1.85
                    scaleOffset = 0.02
                    break
                case "body_acc":
                    shelfType = 'cubesDisplay-4x3'
                    boneOffset = 8
                    heightOffset = -2
                    scaleOffset = 0.02
                    break
                case "animations":
                    shelfType = 'cubesDisplay-4x3'
                    boneOffset = 8
                    heightOffset = -1.5
                    scaleOffset = 0.4
                    break
                default:
                    shelfType = 'cubesDisplay-4x3'
                    boneOffset = 8
                    heightOffset = -2
                    scaleOffset = 0.02
                    break;
      }

      categoryContainer.forEach((element) => {
        scene.remove(element);
      });

      categoryContainer.children = [];

      CategorySpawnLoop();
    }

    function CategorySpawnLoop() {
      if (currentCatalog[currentCategory].length > 0) {
        LoadCategoryPart(
          Object.values(currentCatalog[currentCategory])[loadedCategoryParts],
          CategorySpawnIterate
        );
      }
    }

    var CategorySpawnIterate = () => {
      // set x to next item
      loadedCategoryParts++;

      //find current shelf bone
      // currentBone = loadedCategoryParts - ((shelves.length - 1) * boneOffset)

      // any more items in array? continue loop
      if (
        loadedCategoryParts <
        Object.keys(currentCatalog[currentCategory]).length
      ) {
        CategorySpawnLoop();
      } else {
        //we're done
        loadedCategoryParts = 0;
        // currentCategory = null
        currentBone = 0;

        // scene.add(categoryContainer)
        scene.add(shelfContainer);
        console.log("mounting: adding shelf to scene " + scene.uuid)

        shelves.forEach((shelf) => {
          interactables.push(shelf.getObjectByName('CubeContainer'));

          shelf.getObjectByName('CubeContainer').children.forEach((cube) => {
            cube.oYPos = cube.position.y;
            currentCubes.push(cube);
          });
          shelf.getObjectByName('Armature').children.forEach((bone) => {
            bone.oYPos = bone.position.y;
          });
        });

        //set heights on spawn so they don't jump when moved initially
        SetCubePosition()

        // interactables.push(shelf.children[0])

        CheckTutorialStep('LoadCategory')

        //hide loading
        document.getElementsByClassName('loading')[0].style.display = 'none'

        //enable pointer when done loading
        togglePointer(true)
      }
    };

    let shelfArrowTarget = new THREE.Object3D()
        shelfArrowTarget.position.set(1.4,0.8,-0.84)
        scene.add(shelfArrowTarget)
        this.arrowTargets['shelfTarget'] = shelfArrowTarget

    async function LoadCategoryPart(part, callback) {
      let id = part.id
      let type = part.type
      let price = part.price

      if (id) {
        //check if we need another shelf
        if (shelves.length <= loadedCategoryParts / boneOffset) {
          const object = await modelLoader(modelPath + shelfType + '-grouped-noHoles.glb');

          // gltfLoader.load('/models/' + shelfType + '.glb', (object) => {
          let obj = object.scene;
          obj.name = 'Cubes_' + (shelves.length + 1);
          obj.tag = 'drag';
          obj.position.set(shelves.length * shelfOffset, -0.15, -0.7);
          // obj.rotation.set(0,-0.75,0)
          // obj.scale.set(1.5,1.5,1.5)

          //set up shadow cast, this might have to change if the hierarchy of the shelf model changes
          obj.children[1].children.forEach((mesh) => {
            mesh.visible = false
            mesh.castShadow = true;
          });

          currentShelf = obj;
          //save slots and bones onto shelf object
          currentShelf.slots = [];
          currentShelf.bones = [];

          for (let i = 0; i < obj.children.length; i++) {
            const element = obj.children[i];

            // if (element.name.includes('slot')) {
            //   element.tag = 'select';
            //   element.visible = false;

            //   //only make slot interactable if it's not owned
            //   // if (!ownedItems.includes(shelves.length + currentShelf.slots.length)) {
            //   interactables.push(element);
            //   // }

            //   currentShelf.slots.push(element);
            if (element.name.includes('Armature')) {
              element.children.forEach((chdrn) => {
                if (chdrn.name.includes('Bone')) {
                  currentShelf.bones.push(chdrn);
                }
              });
            }
            else if (element.name.includes('CubeContainer')) {
              //re-order the children so they match the armature
              element.children.sort((a, b) => a.name.localeCompare(b.name))

              element.children.forEach(cube => {
                let child = cube.children[0]
                if (child.name.includes('slot')) {
                  child.tag = 'select';
                  child.visible = false;
    
                  //only make slot interactable if it's not owned
                  // if (!ownedItems.includes(shelves.length + currentShelf.slots.length)) {
                  interactables.push(child);
                  // }
    
                  currentShelf.slots.push(child);
                }
              });
            }
          }

          //add whole shelf to interactables too in case the drag outside the slots
          // interactables.push(obj)

          shelfContainer.add(obj);
          draggableShelves.push(obj);
          shelves.push(obj);

          currentBone = 0;
          // })
        }

        

        let owned = ownedItems.includes(shelves.length - 1 + currentBone)
        let equippedSign

        if (owned) {

          let soldSign = await modelLoader(modelPath + 'soldSign.glb');
          soldSign = soldSign.scene;
          soldSign.name = 'sold-' + id;

          soldSign.position.set(0,-0.6,0)
          soldSign.rotation.set(0,-1.5,0)
          soldSign.scale.set(0.065,0.065,0.065)

          currentShelf
            .getObjectByName('Armature')
            .children[currentBone].add(soldSign);
        }
        else {

          equippedSign = await modelLoader(modelPath + 'equippedSign.glb');
          equippedSign = equippedSign.scene;
          equippedSign.name = 'equippedSign';
          equippedSign.visible = false
          equippedSign.tag = 'equippedSign'

          equippedSign.position.set(0,-0.6,0.3)
          equippedSign.rotation.set(0,-1.45,0)
          equippedSign.scale.set(0.055,0.055,0.055)

          currentShelf
              .getObjectByName('Armature')
              .children[currentBone].add(equippedSign);
              
          console.log('spawning the piece ' + id);

          // gltfLoader.load(
          //     modelPath + id + '.glb',
          // (model) =>
          //     {
          let tex
          if(type == 'eyes') tex = id

          let obj
          // let obj = model.scene.getObjectByProperty("type", "SkinnedMesh")
          //we don't load anything automatically for the animations
          if  (type != 'animations') {
            if (type != 'eyes')
            {
              await modelLoader(modelPath + id + '.glb')
              .then((data) => {
                  obj = data.scene
                  console.log('loaded')
              }).catch(() => {
                  console.log('failed loading')
                  //enable pointer since it failed so they can try again
                  togglePointer(true)
                  //return null
              })
            }
            else {
              await modelLoader(modelPath + 'eyes.glb')
              .then((data) => {
                  obj = data.scene
                  console.log('loaded')
              }).catch(() => {
                  console.log('failed loading')
                  //enable pointer since it failed so they can try again
                  togglePointer(true)
                  //return null
              })
            }
            
            //check if loaded successfully
            if(obj) {
              obj.name = id;
              obj.tag = 'item'

              if (obj.type == 'Group') {
                for (let i = 0; i < obj.children.length; i++) {
                  const element = obj.children[i];

                  // element.bind(this.bodyparts.skeleton, categoryContainer.matrixWorld)
                  // element.bindMode = 'detached'

                  element.scale.set(scaleOffset, scaleOffset, scaleOffset);

                  // categoryContainer.push(element)
                }
              } else if (obj.type == 'SkinnedMesh') {
                // obj.bind(this.bodyparts.skeleton, categoryContainer.matrixWorld)
                // obj.bindMode = 'detached'

                obj.scale.set(scaleOffset, scaleOffset, scaleOffset);

                // categoryContainer.push(obj)
              }
              //this needs to be changed for a particular item offset
              // obj.translateY(heightOffset)
            }
            
          }

          // currentShelf.slots[currentBone].callback = [
          //   () => {
          //     characters[user.characters[0].CharacterId].replacePart(type, id);
          //   },
          //   () => {
          //     toggleShelfItem(tempCurShelf, tempCurBone);
          //   }
          // ];
          // currentShelf
          //   .getObjectByName('Armature')
          //   .children[currentBone].add(obj);
          //check if loaded successfully
          if (obj) {
            let newPlaceholder;
            let newPricetag
            newPricetag = await modelLoader(
              modelPath + 'priceTag.glb'
            );
            newPricetag = newPricetag.scene;
            newPricetag.scale.set( 0.07, 0.07, 0.07)
            newPricetag.rotation.set(0.28, -1.28, 0)

            //add price text
            let priceContainer = new ThreeMeshUI.Block({
              height: 3,
              width: 9,
              padding: 0.001,
              offset: 0.0001,
              textAlign: 'right', 
              backgroundOpacity: 0,
              fontFamily: FontJSON,
              fontTexture: FontImage,
              backgroundColor: new THREE.Color( 0x00ff00 ),
            })
            priceContainer.add(
                new ThreeMeshUI.Text({
                  content: price.toString(),
                  fontSize: 2.5,
                  fontColor: new THREE.Color(0x0000)
                    // bestFit: 'shrink'
                })
            );
            
            let dbIcon = new ThreeMeshUI.InlineBlock( {
                justifyContent: 'center',
                contentDirection: 'column',
                // fontFamily: FontJSON,
                // fontTexture: FontImage,
                fontSize: 5,
                // padding: 0.0001,
                height: 2.5,
                width: 2.5,
                padding: 0.1,
                borderRadius: 0.0001,
                backgroundOpacity: 1,
                backgroundSize: 'contain',
                backgroundColor: new THREE.Color( 0x000000 ),
            } )
    
            new THREE.TextureLoader().load(DB, (texture) => {
                dbIcon.set({
                  backgroundTexture: texture,
                });
              });
            // dbIcon.position.set(0,-2.5,0)
            // dbIcon.rotation.set(0,0,-0.75)

            
            priceContainer.add(dbIcon)
            newPricetag.add(priceContainer)

            priceContainer.position.set(-0.63,-0.48,-0.34)
            priceContainer.rotation.set(3.12,1.28,2.25)
            

            switch (type) {
              case 'head_topper':
              case 'head_acc':
              case 'face_acc':
                newPlaceholder = await modelLoader(
                  modelPath + 'head_placeholder.glb'
                );
                newPlaceholder = newPlaceholder.scene;
                newPlaceholder.children[0].scale.set(
                  scaleOffset,
                  scaleOffset,
                  scaleOffset
                );
                newPlaceholder.translateY(heightOffset);

                
                newPricetag.position.set(0.47, -0.08, 0)

                // scene.add(newPlaceholder)
                break;
              case "eyes":
                newPlaceholder = await modelLoader(modelPath + 'head_placeholder.glb')
                newPlaceholder = newPlaceholder.scene
                newPlaceholder.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
                newPlaceholder.translateY(heightOffset)

                newPricetag.position.set(0.47, -0.08, 0)

                // //tint the manequin head so it's not so bone chilling
                // newPlaceholder.children[0].children[1].material.color.set(hslToHex(characters[user.mainCharacter].customizations.body.bodyColor.h, characters[user.mainCharacter].customizations.body.bodyColor.s, characters[user.mainCharacter].customizations.body.bodyColor.v))

                // let eyeMesh = await modelLoader(modelPath + 'head_placeholder.glb')
                // eyeMesh = eyeMesh.scene
                // eyeMesh.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
                // eyeMesh.translateY(heightOffset)
                
                let eyes_tex = textureLoader.load(modelPath + tex +'.png')
                eyes_tex.flipY = false
                // eyes_tex.magFilter = THREE.NearestFilter;
                // eyes_tex.minFilter = THREE.NearestFilter;
                let eyes_mat = new THREE.MeshBasicMaterial({
                        map: eyes_tex,
                        // alphaTest: 0.5
                    });
                eyes_mat.needsUpdate = true
                eyes_mat.transparent = true
                obj.getObjectByName('obj').material = eyes_mat
                break
              case 'hands_acc':
                newPlaceholder = await modelLoader(
                  modelPath + 'hands_placeholder.glb'
                );
                newPlaceholder = newPlaceholder.scene;
                newPlaceholder.children[0].scale.set(
                  scaleOffset,
                  scaleOffset,
                  scaleOffset
                );
                newPlaceholder.translateY(heightOffset);
                newPricetag.position.set(0.65, -0.19, 0.61)
                newPricetag.rotation.set(-0.11, -2.06, 0)
                break;
              case 'feet':
                newPricetag.position.set(-0.07, -0.36, 0.3)
                newPricetag.rotation.set(1.68, -0.66, 2.19)
                newPricetag.scale.set(0.05, 0.05, 0.05)
                break;
              case 'body_acc':
                newPricetag.position.set(0.7, -0.36, 0.3)
                break
              case 'clothes':
                newPlaceholder = await modelLoader(
                  modelPath + 'body_placeholder.glb'
                );
                newPlaceholder = newPlaceholder.scene;
                newPlaceholder.children[0].scale.set(
                  scaleOffset,
                  scaleOffset,
                  scaleOffset
                );
                newPricetag.position.set(0.59, -0.11, 0.46)
                newPricetag.rotation.set(-0.55, -1.19, 0)
                break;
                case 'animations':
                  //we need to spawn in some kind of placeholder with a symbol for the current animation
                  newPlaceholder = await modelLoader(modelPath + 'animFrame.glb')
                  newPlaceholder = newPlaceholder.scene
                  newPlaceholder.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
                  newPlaceholder.translateY(heightOffset)
              default:
                break;
            }

            //this needs to be changed for a particular item offset
            obj.translateY(heightOffset);

            //tint the manequin head so it's not so bone chilling
            newPlaceholder?.children[0].children[1].material.color.set(hslToHex(characters[user.mainCharacter].customizations.body.bodyColor.h, characters[user.mainCharacter].customizations.body.bodyColor.s, characters[user.mainCharacter].customizations.body.bodyColor.v))

            //add item id to appropriate slot on shelf
            let tempCurBone = currentBone;
            let tempCurShelf = currentShelf;
            let catItem = catalog[type].find(el => el.id == id)
            //cache these for removal in cart
            catItem.shelf = tempCurShelf
            catItem.bone = tempCurBone

            currentShelf.slots[currentBone].callback = [
              () => {
                loadCatalogItem(catItem)
              },
              () => {
                toggleShelfItem(tempCurShelf, tempCurBone);
              }
            ];
            currentShelf.getObjectByName('Armature').children[tempCurBone].add(obj);
            currentShelf
              .getObjectByName('Armature')
              .children[tempCurBone].add(newPlaceholder);
            currentShelf
              .getObjectByName('Armature')
              .children[tempCurBone].add(newPricetag);

            if (cart.contains(catItem)) {
              obj.visible = false
              equippedSign.visible = true
            }
          }    

          // //this needs to be changed for a particular item offset
          // obj.translateY(heightOffset)

          // //add item id to appropriate slot on shelf
          // let tempCurBone = currentBone
          // let tempCurShelf = currentShelf

          //if we own the item, make it invisible and unclickable
          // if (owned) {
            

          // } else {
            
          // }

          // //temp hide the item if it's currently equipped
          // if (characters[user.characters[0].CharacterId].items[type] === id) {
          //   obj.visible = false;
          // }

          

          

          // categoryContainer.position.set(-this.startingPosition.x, -this.startingPosition.y, -this.startingPosition.z)

          // console.log('done creating the ' + type)

          //check if the object is already equipped
          // Object.values(characters[user.mainCharacter].items).forEach(element => {
          //     if (element === id) {
          //         toggleShelfItem(tempCurShelf, tempCurBone)
          //     }
          // });
        }
        //enable the current pedestal
        currentShelf.getObjectByName('CubeContainer').children[currentBone].visible = true
        
        currentBone++;
        if (callback) callback();
        // },
        // null,
        // this.loadError(type, callback? callback : null)
        // )
      } else {
        // console.log('Character not using ' + type)
        if (callback) callback();
      }
    }

    let prevSelection
    function toggleShelfItem (shelf, index, type) {
        if  (type != 'animations') {
        if (prevSelection && prevSelection[1] != shelf.bones[index].children[1])
        {
            prevSelection[1].visible = true
            prevSelection[0].visible = false

        }
            
        shelf.bones[index].children[0].visible = !shelf.bones[index].children[0].visible
        shelf.bones[index].children[1].visible = !shelf.bones[index].children[1].visible
        prevSelection = shelf.bones[index].children
        }
        
    }

    function resetShelves() {
      //manual child disposing for now
      // shelfContainer.children.forEach(shelf => {
      //     //for each of the shelves
      //     shelf.children.forEach(el => {
      //         if (el.name.includes('Armature')) {
      //             //this is where the bones and each of the clothing items are
      //             el.children.forEach(item => {
      //                 //this is the actual shelf mesh
      //                 if (item.type.includes('Mesh')) {
      //                     item.geometry.dispose();
      //                     item.material.dispose();
      //                 }
      //                 //these are the bones holding the clothes
      //                 else {
      //                     //this is the contents of the clothing item scen
      //                     //   scene       Armature    SkinnedMesh
      //                     item.children[0]?.children[0].children[1].geometry.dispose()
      //                     item.children[0]?.children[0].children[1].material.dispose()
      //                 }
      //             });
      //         }
      //         else if (el.name.includes('slot')) {
      //             el.geometry.dispose()
      //             el.material.dispose()
      //         }
      //     });
      // });

      while (shelfContainer.children.length > 0) {
        // shelfContainer.children[0].children.forEach(element => {
        //     element.dispose()
        // });
        // shelfContainer.children[0].geometry.dispose();
        // shelfContainer.children[0].material.dispose();
        shelfContainer.remove(shelfContainer.children[0]);
        scene.remove(shelfContainer.children[0]);
      }
      scene.remove(shelfContainer);
      shelfContainer = new THREE.Group();
      shelfContainer.name = 'ShelfContainer';
      shelfContainer.position.set(2.7, 0, 0.54);
      shelfContainer.rotation.set(0, -0.39, 0);
      shelfContainer.scale.set(1.5, 1.5, 1.5);

      shelves.length = 0;
      currentShelf = null;

      console.log('Interactables before: ');
      console.log(interactables.length);

      removeShelves(mixerControls, [PurchaseItems, CategoryStartSpawn]);
      // for (var i = interactables.length - 1; i >= 0; i--) {

      //     if (interactables[i].name.includes('slot') || interactables[i].name.includes('Armature')) {
      //         console.log(interactables[i].name)
      //         interactables.splice(i, 1);
      //         console.log(interactables.length)
      //     }
      // }
      interactables.push(characterSpinner);

      console.log('Interactables after: ');
      console.log(interactables.length);
    }

    function modelLoader(url) {
      return new Promise((resolve, reject) => {
        gltfLoader.load(url, (data) => resolve(data), null, reject);
      });
    }

    //===========================
    //
    //     Purchase Functions
    //
    //===========================

    const cart = new Cart(mixerUIToggle);

    function SaveCharacter() {

      //hide modal
      document.getElementById('confirmModal').classList.add('hidden')

      //display loading
      document.getElementsByClassName('loading')[0].style.display = 'block'

      fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
          method: "POST",
          headers: {
              "Accept" : "text/plain",
              "X-EntityToken" : user.entityToken,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({

              "FunctionName": "Save_Character_Configuration",
              "FunctionParameter": {                
                  "characterID" : user.mainCharacter,
                  "customizations" : JSON.stringify(characters[user.mainCharacter].customizations),
                  "items" : JSON.stringify(characters[user.mainCharacter].items)
              }


          })
      })
      .then(response => response.json())
      .then(data => {

          //hide loading
          document.getElementsByClassName('loading')[0].style.display = 'none'

          CheckTutorialStep('PurchasedItems')
          
          console.log('updated character items')
          
          //enable pointer when done
          togglePointer(true)

      });
  }

    function PurchaseItems() {

      if (cart.disabled) {
        cart.cantAfford(true)
        return
      }
      
      //display loading
      document.getElementsByClassName('loading')[0].style.display = 'block' 

      //disable pointer while loading
      togglePointer(false)

      fetch(
        'https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction',
        {
          method: 'POST',
          headers: {
            Accept: 'text/plain',
            'X-EntityToken': user.entityToken,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            FunctionName: 'PurchaseAudiophileItem',
            FunctionParameter: {
              items: cart.formattedItems,
              catalogVersion: catalogVersion,
              currency: 'DB',
              clientTotalCost: cart.price.toString()
            }
          })
        }
      )
        .then((response) => response.json())
        .then(async (data) => {

          if (data.data.FunctionResult.statusCode == 200)
          {
            //purchase successful
            GetOwnedItems();

            console.log('function successful');

            // characters[user.mainCharacter].resetChar();            
            //build GA product array
            
            let gaProducts = []
            for (let i = 0; i < cart.items.length; i++) {
              const item = cart.items[i];
              
              gaProducts.push({
                'item_name': item.name,     // Name or ID is required.
                'item_id': item.id,
                'price': item.price,
                'item_brand': 'frisson',
                'item_category': 'Audiophile Items',
                'item_category2': item.type.charAt(0).toUpperCase() + item.type.slice(1), // We can use this as a sub-category now!
                'quantity': 1, // Optional fields may be omitted or set to empty string.
              })
            }

            //send ga event
            SendGTMEvent({
              'event': 'purchase_store_item',
              'transaction_id': data.data.FunctionResult.annotatedOrderId,
              'currency': 'USD',
              'value': cart.price, // I DID NOT modify this value to change it to cents - not sure if it already is or not...?
              'items': gaProducts
            })

            cart.clear();

            user.currency = await GetCurrency()
        
            cart.updateStoreCurrency(user.currency)

            //show equip modal
            document.getElementById('confirmModal').classList.remove('hidden')

          }

          

          //hide loading
          document.getElementsByClassName('loading')[0].style.display = 'none'

          
        });
    }


    function RevertItems() {
      //hide modal
      document.getElementById('confirmModal').classList.add('hidden')

      characters[user.mainCharacter].resetChar();
      CheckTutorialStep('PurchasedItems')
      //enable pointer when done
      togglePointer(true)
    }

    function parseCatalogItem(item) {
      //couldn't do this in a single line :(
      let price = 0;
      if (item.VirtualCurrencyPrices && item.VirtualCurrencyPrices['DB']) {
        price = item.VirtualCurrencyPrices['DB'];
      }

      let processedItem = {
        id: item.ItemId.split('|')[0],
        type: item.ItemId.split('|')[1],
        name: item.DisplayName,
        price: price
      };

      return processedItem;
    }

    function returnCatalogItem(id, type) {
      let item = null;
      catalog[type].forEach((element) => {
        if (element.id === id) {
          item = element;
        }
      });

      return item;
    }

    function mixerUIToggle (item) {
      toggleShelfItem(item.shelf, item.bone)
      loadCatalogItem(item)
    }

    function loadCatalogItem(item) {
      let existingCat = cart.containsCategory(item.type)
      
      
      if (!cart.contains(item)) {
        if (existingCat) {
          cart.remove(existingCat);
        }
        
        cart.add(item);
        characters[user.mainCharacter].replacePart(
          item.type,
          item.id
        );
        CheckTutorialStep('AddedItem')
      } else {
        cart.remove(item);
        let defaultItem = characters[user.mainCharacter].defaultItems[item.type]

        if (item.type == 'eyes') defaultItem = characters[user.mainCharacter].defaultItems['eyes_tex']
        characters[user.mainCharacter].replacePart(
          item.type,
          defaultItem
        );
        CheckTutorialStep('RemovedItem')

        console.log(characters[user.mainCharacter].items)
      }

      

      console.log('Cart', cart);
    }

    // var sizeMin = -0.3, sizeMax = 0.2, headMin = 0.4, headMax = -0.25
    // const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;
    // Number.prototype.map = function (in_min, in_max, out_min, out_max) {
    //     return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    //   }

    /**
     * Sizes
     */
    // const sizes = {
    //     width: window.innerWidth,
    //     height: window.innerHeight
    // }

    // window.addEventListener('resize', () =>
    // {
    //     // Update sizes
    //     sizes.width = window.innerWidth
    //     sizes.height = window.innerHeight

    //     // Update camera
    //     camera.aspect = sizes.width / sizes.height
    //     camera.updateProjectionMatrix()

    //     // Update renderer
    //     renderer.setSize(sizes.width, sizes.height)
    //     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    // })

    let testX = -4;
    // document.addEventListener('keydown', onSpawnKey, false);
    // function onSpawnKey(event) {
    //   var keyCode = event.which;
    //   if (keyCode == 83) {
    //     // GetUserCharacter(user)
    //     // let testChar = new Character(charProps)
    //     // characters.push(testChar)
    //     // console.log(characters)
    //     // testChar.spawn("testID",testX,0,0)
    //     // testX++
    //   }
    //   if (keyCode == 68) {
    //     // let testChar2 = new Character(charProps2)
    //     // characters.push(testChar2)
    //     // // console.log(characters)
    //     // testChar2.spawn(testX,0,0)

    //     // testX++

    //     characters[user.characters[0].CharacterId].replacePart(
    //       'body_acc',
    //       'body_jazz'
    //     );
    //   }
    //   if (keyCode == 49) {
    //     // console.log(JSON.stringify(characters[0].customizations))
    //     characters[user.characters[0].CharacterId].startAnimation('robot');
    //   }
    // }
    /**
     * Camera
     */
    // Base camera
    // const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    camera.position.set(1.8, 1.7, 3.2);
    camera.rotation.set(-0.15, 0, 0);
    scene.add(camera);

    // Controls
    // const controls = new OrbitControls(camera, canvas)
    // controls.target.set(0, 0.75, 0)
    // controls.enableDamping = true

    // function readControls(obj) {
    //   switch (obj.object.charProp) {
    //     case 'headSize':
    //       // characters[user.characters[0].CharacterId].customizations.head.headSize = ((headSizeProp.max - headSizeProp.min) * obj.object.val) + headSizeProp.min
    //       characters[
    //         user.characters[0].CharacterId
    //       ].customizations.head.headSize = getVal(
    //         obj.object.val,
    //         headSizeProp.min,
    //         headSizeProp.max
    //       );
    //       break;
    //     case 'neckLength':
    //       characters[
    //         user.characters[0].CharacterId
    //       ].customizations.head.neckLength = getVal(
    //         obj.object.val,
    //         neckLengthProp.min,
    //         neckLengthProp.max
    //       );

    //       break;
    //     case 'bodySize':
    //       characters[
    //         user.characters[0].CharacterId
    //       ].customizations.body.bodySize = getVal(
    //         obj.object.val,
    //         bodySizeProp.min,
    //         bodySizeProp.max
    //       );

    //       break;
    //     case 'legLength':
    //       characters[
    //         user.characters[0].CharacterId
    //       ].customizations.feet.legLength = getVal(
    //         obj.object.val,
    //         legLengthProp.min,
    //         legLengthProp.max
    //       );

    //       break;
    //     case 'handSize':
    //       characters[
    //         user.characters[0].CharacterId
    //       ].customizations.hands.handSize = getVal(
    //         obj.object.val,
    //         handSizeProp.min,
    //         handSizeProp.max
    //       );

    //       break;
    //     case 'feetSize':
    //       characters[
    //         user.characters[0].CharacterId
    //       ].customizations.feet.feetSize = getVal(
    //         obj.object.val,
    //         feetSizeProp.min,
    //         feetSizeProp.max
    //       );

    //       break;
    //     case 'hue':
    //       characters[
    //         user.characters[0].CharacterId
    //       ].customizations.body.bodyColor.h = getVal(
    //         obj.object.val,
    //         bodyColorProp.hMin,
    //         bodyColorProp.hMax
    //       );
    //       break;
    //     case 'sat':
    //       characters[
    //         user.characters[0].CharacterId
    //       ].customizations.body.bodyColor.s = getVal(
    //         obj.object.val,
    //         bodyColorProp.sMin,
    //         bodyColorProp.sMax
    //       );
    //       break;
    //     case 'value':
    //       characters[
    //         user.characters[0].CharacterId
    //       ].customizations.body.bodyColor.v = getVal(
    //         obj.object.val,
    //         bodyColorProp.vMin,
    //         bodyColorProp.vMax
    //       );
    //     default:
    //       break;
    //   }

    //   characters[user.characters[0].CharacterId].applyAdjustments();
    // }

    function spinChar(delta) {
      // console.log("spinning char this much: " + delta)
      let spinRate = mediaQueryState == 'mobilePortrait' ? 0.022 : 0.006

      characters[user.mainCharacter].body.children[0].rotation.y += (delta * spinRate)
    }

    function resetCharSpin() {
      console.log('reset')
      gsap.to(characters[user.mainCharacter].body.children[0].rotation, {duration: 0.5, y: 0})
    }

    /**
     * Renderer
     */
    // const renderer = new THREE.WebGLRenderer({
    //     antialias: true,
    //     canvas: canvas
    // })
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    //Drag Controls
    dragControls(canvas, camera, null);

    function mediaQueryChange(state) {
      // console.log('Changed screen to ' + state);

      switch (state) {
        case 'desktop':
          //camera
          gsap.to(camera.position, { x: 1.8, y: 1.7, z: 3.2, duration: 0.5 });
          //body
          gsap.to(characters[user.mainCharacter].body.position, {
            z: 0,
            duration: 0.5
          });
          //mixers
          gsap.to(mixerControls.position, {
            x: 1.82,
            y: 1.24,
            z: 2.52,
            duration: 0.5
          });
          gsap.to(mixerControls.rotation, { x: 0.67, z: 0, duration: 0.5 });
          break;
        case 'tabletProtrait':
          //camera
          gsap.to(camera.position, {
            x: 1.21,
            y: 1.77,
            z: 3.74,
            duration: 0.5
          });
          //body
          gsap.to(characters[user.mainCharacter].body.position, {
            z: 0,
            duration: 0.5
          });
          //mixers
          gsap.to(mixerControls.position, {
            x: 1.09,
            y: 1.1,
            z: 2.82,
            duration: 0.5
          });
          gsap.to(mixerControls.rotation, { x: 1.15, z: 0, duration: 0.5 });
          break;
        case 'mobileLandscape':
          //camera
          gsap.to(camera.position, {
            x: 0.06,
            y: 1.77,
            z: 2.65,
            duration: 0.5
          });
          //body
          gsap.to(characters[user.mainCharacter].body.position, {
            z: 0,
            duration: 0.5
          });
          //mixers
          gsap.to(mixerControls.position, {
            x: -0.53,
            y: 1.46,
            z: 2.08,
            duration: 0.5
          });
          gsap.to(mixerControls.rotation, { x: 1.24, z: -0.07, duration: 0.5 });
          break;
        case 'mobilePortrait':
          //camera
          gsap.to(camera.position, { x: 0.7, y: 1.77, z: 3.71, duration: 0.5 });
          //body
          gsap.to(characters[user.mainCharacter].body.position, {
            z: -0.18,
            duration: 0.5
          });

          //mixers
          gsap.to(mixerControls.position, {
            x: 0.64,
            y: 1.22,
            z: 2.95,
            duration: 0.5
          });
          gsap.to(mixerControls.rotation, { x: 1.32, z: 0, duration: 0.5 });
          break;
        default:
          break;
      }
    }

    //start dragging tick
    toggleCanTick(true)
    tick()

    this.mount.appendChild(this.renderer.domElement);
    this.start();
  }

  componentWillUnmount() {

    console.log('mounting: should be unmounting')

    this.stop();
    this.mount.removeChild(this.renderer.domElement);
    
    // Traverse the whole scene
    this.scene.traverse((child) =>
    {
        // Test if it's a mesh
        if(child instanceof THREE.Mesh)
        {
            child.geometry.dispose()

            // Loop through the material properties
            for(const key in child.material)
            {
                const value = child.material[key]

                // Test if there is a dispose function
                if(value && typeof value.dispose === 'function')
                {
                    value.dispose()
                }
            }
        }
        else if (child instanceof THREE.Group) {
            child.traverse((subChild) =>
            {
                if(subChild instanceof THREE.Mesh)
                {
                    subChild.geometry.dispose()

                    // Loop through the material properties
                    for(const key in subChild.material)
                    {
                        const value = subChild.material[key]

                        // Test if there is a dispose function
                        if(value && typeof value.dispose === 'function')
                        {
                            value.dispose()
                        }
                    }
                }
            })
        }
    })
    for (let i = 0; i < this.scene.children.length; i++) {
        const element = this.scene.children[i];
        
        this.scene.remove(element)
    }
    // this.renderer.instance.dispose()
    this.scene = null
    this.camera = null
    this.renderer && this.renderer.renderLists.dispose()
    this.renderer = null

    this.eventsDispose()
}

  start() {
    const clock = new THREE.Clock();
    let previousTime = 0;

    if (!this.frameId) {
      this.frameId = requestAnimationFrame(() => {
        this.animate(clock, previousTime);
      });
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  animate(clock, previousTime) {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;
    //update all character animation mixers
    for (const [id, char] of Object.entries(this.characters)) {
      if (char.currentAnim) char.mixer.update(deltaTime);
    }

    let size = new THREE.Vector2(0,0)
    this.renderer.getSize(size)

    let objPos = new THREE.Vector3()
        if (this.arrowData.arrow1.obj && this.arrowTargets[this.arrowData.arrow1.obj]) {
            Object.values(this.arrowTargets[this.arrowData.arrow1.obj].getWorldPosition(objPos))
            const screenPosition = objPos.clone()
            screenPosition.project(this.camera)
            const translateX = screenPosition.x * size.x * 0.5
            //55px is the overall size of the bubble element + the chat element
            const translateY = - screenPosition.y * size.y * 0.5 - 100

            let arrow = document.getElementById('tutArrow-1')
            // arrow.className = "bounce " + arrowData.arrow1.arrow1Pos

            if (arrow) {
               arrow.style.transform = `translateX(${translateX}px) translateY(${translateY}px)` 
            }
            
        }
        if (this.arrowData.arrow2.obj && this.arrowTargets[this.arrowData.arrow2.obj]) {
            Object.values(this.arrowTargets[this.arrowData.arrow2.obj].getWorldPosition(objPos))
            const screenPosition = objPos.clone()
            screenPosition.project(this.camera)
            const translateX = screenPosition.x * size.x * 0.5
            //55px is the overall size of the bubble element + the chat element
            const translateY = - screenPosition.y * size.y * 0.5 - 100

            let arrow = document.getElementById('tutArrow-2')
            // arrow.className = "bounce " + arrowData.arrow1.arrow1Pos

            if (arrow) {
               arrow.style.transform = `translateX(${translateX}px) translateY(${translateY}px)` 
            }
            
        }

    this.renderScene();
    this.frameId = window.requestAnimationFrame(() => {
      this.animate(clock, previousTime);
    });
  }

  renderScene() {
    this.renderer.render(this.scene, this.camera);
  }

  render() {
    return (
      <div>
        <Loading />
        <DynamicMenuModernized position={1} direction="down" />
        <div
          style={{ width: '100vw', height: '100vh' }}
          ref={(mount) => {
            this.mount = mount;
          }}
        />
      </div>
    );
  }
}

// const rootElement = document.getElementById("root")
// ReactDOM.render(<DressingRoom />, rootElement);
export default Store;
