import React, { useRef, useEffect, useState, useCallback } from 'react';
import ButtonFriends from '../../components/button-friends';
import FriendsListItem from '../../components/friends-list/FriendsListItem';
import AddNewFriendModal from '../../components/friends-list/AddNewFriendModal';
import './styles.scss';

function useOutsideAlerter(ref, onClose) {
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       onClose();
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [ref, onClose]);
}

const PopUpFriends = ({ data, onClose }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, onClose);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = useCallback(() => setOpenModal(false), []);

  return (
    <div centered="true" className="popup-friends" ref={wrapperRef}>
      <AddNewFriendModal
        openModal={openModal}
        onCloseModal={handleCloseModal}
      />
      <div className="header">
        <div className="title">Friends</div>
        <button className="button_friends" onClick={handleOpenModal}>+ ADD FRIEND</button>
        <button onClick={onClose}>
          <i className="fa fa-minus"></i>
        </button>
      </div>
      <div className="body">
        {data?.map((item) => (
          <FriendsListItem key={item?.friendId} data={item} />
        ))}
      </div>
    </div>
  );
};

export default PopUpFriends;
