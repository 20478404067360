import React from "react"
import VibesUI_CustomTab from "./VibesUI_CustomTab"
import { useEffect } from "react";

export default function VibesUI_CustomCategories(props) {
    const description = updateDescription(props.selectedCategory)

    function updateDescription(category) {
        switch (category) {
            case "type":
                switch (props.selectedEvent) {
                    case "event_firework":
                        return "Choose the firework type to determine it's shape, size, and behavior.";
                    case "event_crowdemoji":
                        return "Choose how big you want your emoji to be in the crowd.";
                    case "event_dance_vibes":
                        return "Choose the special animation that you want to triggger.";
                    default:
                        return "No description"
                }

            case "emoji":
                return "Select the emoji you want to display in the crowd."

            case "color":
                switch (props.selectedEvent) {
                    case "event_firework":
                        return "Pick the primary and secondary colors for your fireworks.";
                    case "event_crowdemoji":
                        return "Pick the color of your name that shows up next to your emoji.";
                    default:
                        return "No description"
                }

            case "timing":
                return "Designate how many beats of time you want in-between your firework launches."

            case "quantity":
                return "How many of this vibe do you want to send?"

            default:
                return "Select a category to customize"
        }
    }

    React.useEffect(() => {
        if (!props.selectedCategory) {
            if (props.selectedEvent === "event_crowdemoji"){
                props.selectCategory("emoji")
            }
            else if (props.selectedEvent === "event_firework") {
                props.selectCategory("type")
            }
        }
        
    }, [props.selectedCategory])

    // TODO NEXT FOR JEREMY: Pull all these elements out into an array so that we can auto-select the first category
    return (
        <div className="vibesUI--customization_categories">
            <div className="vibesUI--customization_categories_container">
                {props.selectedEvent === "event_crowdemoji" && <VibesUI_CustomTab
                    eventData={props.eventData[props.selectedEvent]}
                    name="emoji"
                    selectCategory={() => {props.selectCategory("emoji")}}
                />}
                {(props.selectedEvent === "event_firework" || props.selectedEvent === "event_crowdemoji") && <VibesUI_CustomTab
                    eventData={props.eventData[props.selectedEvent]}
                    name="type"
                    selectCategory={() => {props.selectCategory("type")}}
                />}
                {(props.selectedEvent === "event_firework" || props.selectedEvent === "event_crowdemoji") && <VibesUI_CustomTab
                    eventData={props.eventData[props.selectedEvent]}
                    name="color"
                    colorCount={props.selectedEvent === "event_firework" ? 2 : 1}
                    selectCategory={() => {props.selectCategory("color")}}
                />}
                {(props.selectedEvent === "event_firework" || props.selectedEvent === "event_crowdemoji") && <VibesUI_CustomTab
                    eventData={props.eventData[props.selectedEvent]}
                    name="quantity"
                    selectCategory={() => {props.selectCategory("quantity")}}
                />}
                {props.selectedEvent === "event_firework" && <VibesUI_CustomTab
                    eventData={props.eventData[props.selectedEvent]}
                    name="timing"
                    selectCategory={() => {props.selectCategory("timing")}}
                />}
            </div>
            <div className="vibesUI--category_description">
                <p>{description}</p></div>
        </div>
    )
}