import React, { useState, Fragment, useEffect } from 'react';
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Button } from "react-bootstrap";

// selectors
import { forgotPasswordRequest } from "../../slices/auth";
import { isLoading } from "../../selectors/auth.selector";

// component
import Login from "../../components/login";
import Register from "../register-user";
import InputFormik from "../../components/input-formik";

// services

//helpers
import * as yup from "yup";

import "./styles.scss";
import close from '../../assets/svg/sprite.svg';

const LoginForgotPasswordRegister = (props) => {
  const [activeTab, setActiveTab] = useState(props.activeTabe ? props.activeTabe : true);
  const dispatch = useDispatch();

  const loading = useSelector(isLoading);

  const switchTab = (isActive) => {
    setActiveTab(isActive);
  };


  const switchForgotLogin = (active) => {
    const login = document.querySelector(".login-section");
    const loginTitle = document.querySelector(".--login");
    const signUpTitle = document.querySelector(".sign-up");
    const forgotPassword = document.querySelector(".forgot-password-section");
    const forgotPasswordTitle = document.querySelector(".--forgot-password");

    if (active === true) {
      login.style.display = "none";
      loginTitle.style.display = "none";
      signUpTitle.style.display = "none";
      forgotPassword.setAttribute("style", "display:contents !important");
      forgotPasswordTitle.setAttribute("style", "display:flex !important");
    } else if (active === false) {
      login.style.display = "contents";
      loginTitle.style.display = "flex";
      signUpTitle.style.display = "flex";
      forgotPassword.style.display = "none";
      forgotPasswordTitle.style.display = "none";
    }
  };

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: yup.object().shape({
      email: yup.string().email("Invalid email").required("Required")
    }),
    onSubmit: (values) => {
      dispatch(forgotPasswordRequest(values));
    }
  });

  useEffect(() => {
    if (props.activeTabe === true && props.activeTabe !== false) {
      setActiveTab(true);
    } else if (props.activeTabe === false && props.activeTabe !== true) {
      setActiveTab(false);
    }
  }, [props]);


  return (
    <div className="wrapper-form-register-login wow move-up" id="login_section">
      <div className="wrapper-form">
        <div className="form-login-top">
          {props.close && (
            <button className="close_btn" onClick={props.close}>
              <svg>
                <use href={close + '#close'} />
              </svg>
            </button>
          )}
          <div className="login__join">Join The Crowd</div>
          <div className="login__welcome">Welcome To frisson</div>
        </div>
        <div className="form-login-body">
          <div className="head-tab">
            <div />
            <div
              className={`choose-tab forgot ${
                activeTab ? "--selected --forgot-password" : ""
              }`}
              onClick={() => {
                switchTab(true);
                switchForgotLogin(true);
              }}
            >
              Forgot Password
            </div>
            <div
              className={`choose-tab ${activeTab ? "--selected --login" : ""}`}
              onClick={() => switchTab(true)}
            >
              Login
            </div>
            <div
              className={`choose-tab sign-up ${
                !activeTab ? "--selected --sign-up" : ""
              }`}
              onClick={() => switchTab(false)}
            >
              Sign Up
            </div>
            <div />
          </div>
          <div className="tabs">
            {activeTab && (
              <Fragment>
                <div className="form-active-login">
                  <Login />

                  <form
                    onSubmit={formik.handleSubmit}
                    className="forgot-password-section"
                  >
                    <InputFormik
                      icon="fa-envelope-o"
                      name="email"
                      formik={formik}
                      placeholder="Email address"
                    />
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-submit"
                      style={{ width: "100%" }}
                      disabled={loading}
                    >
                      Get New Password
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>

                    <div
                      className="forgot-password"
                      onClick={() => switchForgotLogin(false)}
                    >
                      Cancel
                    </div>
                  </form>
                </div>
              </Fragment>
            )}
            {!activeTab && <Register />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForgotPasswordRegister;
