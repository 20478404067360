// import React, { Fragment } from "react";
// import { Helmet } from "react-helmet";

// // layout
// import { LayoutDefault } from "../../layouts";
// import { Header } from "../../components/header";
// import { Footer } from "../../container/footer";

// // container
// import GenresList from "../../container/genres-list";
// import DropDownMenu from "../../container/dropdown-menu";
// import ListCardLive from "../../container/list-live-now";
// import ListRecommendedShows from "../../container/list-recommended-shows";
// import SuggestedShows from "../../container/suggested-shows";

// // data
// import frisson from "../../data/frisson.json";
// import { typeGenre } from "../../selectors/genres.selector";
// import { useSelector } from "react-redux";

// import "./styles.scss";

// const DressingRoom = () => {
//   const { genre } = useSelector(typeGenre);

//   return (
//     <Fragment>
//       <Helmet>
//         <title>{`${frisson.title}`}</title>
//       </Helmet>
//       <p>Hello World</p>
//     </Fragment>
//   );
// };

// export default DressingRoom;




import React from "react";
// import ReactDOM from "react-dom";
// import { Redirect, Route } from "react-router-dom";
import './style.css'
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import jwt_decode from 'jwt-decode' 
import {Character, sharedAnims, CharacterClassInit} from 'shared/audiophile/character'
import { setupUI, BuildATMUI, DisposeEvents  } from "../../shared/ui-builder";
import gsap from 'gsap'
import DynamicMenu from "components/dynamic-menu";

// import { dragControls,dragAction,mouse, interactables, prepControlObjs, setInitialVal, getVal, getRatio, draggableShelves, removeShelves } from './drag'
import User from 'shared/audiophile/models/user'
import { screenSizeListener, screenSizeStates, mediaQueryState, sizes, MQDIsposeEvents } from 'shared/audiophile/mediaQueries'
import Catalog from './models/catalog'
import { object } from "prop-types";
import Loading from "components/loading";
import { InitTut, ActivateTut, TutDisplay, tutState } from "shared/audiophile/onboarding";
import { SendGTMEvent } from "shared/utils/gtmEvents";
import DynamicMenuModernized from "components/dynamic-menu-modernized";
// import { Cart } from './models/cart'

class ATM extends React.Component {
    constructor(props) {
        super(props)
    
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.animate = this.animate.bind(this)
      }
    
      componentDidMount() {

        /**
         * Constants
         */

         const titleId = '10B75'


         //add styles to body
        document.body.style.height = '100%'
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.documentElement.style.overflow = 'hidden'
        document.documentElement.style.width = '100%'

        
        //atm page states
        const url = window.location.origin;
        const urlParams = new URLSearchParams(window.location.search);
        let orderId = urlParams.get('orderId');
        // const playfabId = urlParams.get('playfabId');
        let pageStages = ["startPurchase", "finishPurchase"]
        let pageState = orderId ? pageStages[1] : pageStages[0]

        console.log("Page state: " + pageState)

        let userCurrency
        let listenForPurchaseFocus = false
        let leftForPurchase = false
        let device = ''
        window.addEventListener("focus", function(event) 
        { 
            if (listenForPurchaseFocus && leftForPurchase && tutState != 1) {
                //presumably returning from paypal, check user account
                CompareCurrency()
            }
        }, false);

        window.addEventListener("mousedown", function(event) 
        { 
            if (listenForPurchaseFocus && leftForPurchase && tutState != 1) {
                //presumably returning from paypal, check user account
                CompareCurrency()
            }
        }, false);

        // Example of the blur event as opposed to focus
        // window.addEventListener("blur", function(event) { 
        //     if (listenForPurchaseFocus) {
        //         leftForPurchase = true
        //     }
        // }, false);

        const width = this.mount.clientWidth
        const height = this.mount.clientHeight
    
        const scene = new THREE.Scene()
        const camera = new THREE.PerspectiveCamera(
          75,
          width / height,
          0.1,
          1000
        )
        
        const renderer = new THREE.WebGLRenderer({ antialias: true})
        const canvas = renderer.domElement
        
        renderer.setClearColor('#000000')
        renderer.setSize(width, height)
    
        this.scene = scene
        this.camera = camera
        this.renderer = renderer

        function Dispose () {
            DisposeEvents()
            MQDIsposeEvents()
        }
        this.eventsDispose = Dispose


        

        /**
         * Lights
         */
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
        scene.add(ambientLight)

        const directionalLight = new THREE.DirectionalLight(0xffffff, 0.6)
        directionalLight.castShadow = true
        directionalLight.shadow.mapSize.set(1024, 1024)
        directionalLight.shadow.camera.far = 15
        directionalLight.shadow.camera.left = - 7
        directionalLight.shadow.camera.top = 7
        directionalLight.shadow.camera.right = 7
        directionalLight.shadow.camera.bottom = - 7
        directionalLight.position.set(5, 5, 5)
        scene.add(directionalLight)

        const rimLight = new THREE.PointLight(0xC73AFC, 0.5)
        rimLight.position.set(-1.5, 0, 0.75)
        rimLight.name = "RimLight"
        // scene.add(rimLight)

        const spotLight = new THREE.SpotLight( 0xffffff );
        spotLight.position.set( -2, 3, 0 );

        spotLight.castShadow = true;

        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;

        spotLight.shadow.camera.near = 500;
        spotLight.shadow.camera.far = 4000;
        spotLight.shadow.camera.fov = 30;
        spotLight.penumbra = 1
        spotLight.angle = 1
        spotLight.target.position.set(3,3,0.5)
        spotLight.intensity = 1
        scene.add( spotLight );
        scene.add( spotLight.target )

        // const atmGeo = new THREE.BoxGeometry( 1,2.5,1 );
        // const atmMat = new THREE.MeshBasicMaterial( {color: 0xdddcc5} );
        // const atmMesh = new THREE.Mesh( atmGeo, atmMat );
        // atmMesh.position.set(0.88,1.2,-1.8)
        // scene.add( atmMesh );


        /**
         * Models
         */
        let anims = {}
        const modelPath = 'https://audiophileitems.blob.core.windows.net/items/'
        const dracoLoader = new DRACOLoader()
        const gltfLoader = new GLTFLoader()
        dracoLoader.setDecoderPath('/draco/')

        gltfLoader.setDRACOLoader(dracoLoader)

        const textureLoader = new THREE.TextureLoader( );
        // let texture1 = textureLoader.load(modelPath + 'empty.png')
        // let texture2 = textureLoader.load(modelPath + 'hoody_base.png')
        // let texture3 = textureLoader.load(modelPath + 'blush.png')
        // let texture4 = textureLoader.load(modelPath + 'face.png')

        // texture1.flipY = false
        // texture2.flipY = false
        // texture3.flipY = false
        // texture4.flipY = false

        //Load Enviro scene
        // let mixerControls

        // gltfLoader.load(modelPath + 'mixerStore.glb', (object) => {
        //     let obj = object.scene
        //     obj.name = "Mixer"
        //     obj.position.set(1.8,0.66,1.96)
        //     obj.rotation.set(0.54,-1.57,0)
        //     obj.scale.set(0.3,0.3,0.3)

        //     //find all interactables
        //     obj.children.forEach(el => {
        //         //this is kind of janky
        //         //this also needs to change to switch from catalog to inventory
        //         // prepControlObjs(el, CategoryStartSpawn)

        //         //find correct select callback
        //         if (el.name.includes('select')) {
        //             switch (el.name.split('|')[0]) {
        //                 case "headMorphPlus":
        //                     prepControlObjs(el, () => {MorphMainChar(true, true)})
        //                     break;
        //                 case "headMorphMinus":
        //                     prepControlObjs(el, () => {MorphMainChar(false, true)})
        //                     break;
        //                 case "bodyMorphPlus":
        //                     prepControlObjs(el, () => {MorphMainChar(true, false)})
        //                     break;
        //                 case "bodyMorphMinus":
        //                     prepControlObjs(el, () => {MorphMainChar(false, false)})
        //                     break;
        //                 case "save":
        //                     prepControlObjs(el, SaveCharacter)
        //                     break;
        //                 case "purchase":
        //                     prepControlObjs(el, PurchaseItems)
        //                     break;
        //                 default:
        //                     prepControlObjs(el, CategoryStartSpawn)
        //                     break;
        //             }
                    
        //         }
        //         else{
        //             prepControlObjs(el)
        //         }
        //     });

        //     scene.add(obj)
        //     mixerControls = obj
        // })
        let backdrop = gltfLoader.load(modelPath + 'backdrop.glb', (object) => {
            let obj = object.scene
            obj.name = "Backdrop"
            obj.position.set(0,0,0)
            obj.rotation.set(0,1.5,0)
            obj.scale.set(2,4,15)

            // obj.material.color = '#444444'
            // obj.material.metalness = 0
            // obj.material.roughness = 0.5

            obj.children[0].material = new THREE.MeshStandardMaterial({
                color: '#c8c8c8',
                metalness: 0,
                roughness: 0.5
            })
            obj.children[0].receiveShadow = true

            scene.add(obj)
        })

        let atm = gltfLoader.load(modelPath + 'atm.glb', (object) => {
            let obj = object.scene
            obj.name = "ATM"
            obj.position.set(1.04, 0.23, -0.2)
            obj.rotation.set(0,0,0)
            obj.scale.set(0.15,0.15,0.15)

            obj.material = new THREE.MeshLambertMaterial({
                color: '#ffffff'
            })

            scene.add(obj)
        })

        // let shelfContainer = new THREE.Group()
        //     shelfContainer.name = "ShelfContainer"
        //     shelfContainer.position.set(2.7,0,0.54)
        //     shelfContainer.rotation.set(0,-0.39,0)
        //     shelfContainer.scale.set(1.5,1.5,1.5)

        // const modelPath = '/models/Character/mixamo/new/'
        

        // let charProps = {
        //     "items" : {
        //         "head" : "head_only",
        //         "head_acc" : "head_acc",
        //         "face" : "",
        //         "face_acc" : "face_acc",
        //         "body" : "body_jazz",
        //         "body_acc" : "body_acc",
        //         "hands" : "hands_only",
        //         "feet" : "feet_only"
        //     },
        //     "customizations" : {
        //         "headSize":1,
        //         "bodySize":1,
        //         "handSize":1,
        //         "feetSize":1,
        //         "neckLength":0.9,
        //         "armLength":1,
        //         "legLength":-0.2
        //     },
        //     "animations" : [
        //         "twist"
        //     ]
        // }

        // let defaultCustomizations = {
        //     "customizations" : {
        //         "headSize":1,
        //         "bodySize":1,
        //         "handSize":1,
        //         "feetSize":1,
        //         "neckLength":0.9,
        //         "armLength":1,
        //         "legLength":-0.2
        //     }
        // }

//===========================
//
//     UI
// 
//===========================




//===========================
//
//     User Properties
// 
//===========================



function GetUser () {
    let appState = JSON.parse(window.localStorage.getItem('frisson_state'));
    if (appState?.auth.isAuthenticated) {
        return new User(jwt_decode(appState.auth.currentUser))
    }
    else {
        console.log("Cannot get user, we should return to login")
        window.location.href = '/'
        return null
    }
}

const user = GetUser()
InitCurrency()
GetUserCharacter(user)

async function InitCurrency () {
    userCurrency = await GetCurrency()
}

//===========================
//
//     Init
// 
//===========================
let selectedBundle

if (pageState === "startPurchase") {
    //let's get our bundles and display them here for purchase
    fetchBundles()
}
else {
    //returning from Paypal finalize the purchase
    confirmPurchase()
}

function fetchBundles () {
    fetch('https://' + titleId + '.playfabapi.com/Client/GetCatalogItems', {
        method: "POST",
        headers: {
            "Accept" : "text/plain",
            "X-Authorization" : user.playFabSessionToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "CatalogVersion": "RM Items"
        })
    })
    .then(response => response.json())
    .then(data => {
        const bundles = data.data.Catalog

        //insert temp html for bundles
        // const container = document.getElementById('root')
        // let cartContainer = document.createElement('div')
        // cartContainer.setAttribute('id', 'bundleContainer')
        // cartContainer.innerHTML = '<p>Bundles</p></div>'
        // container.appendChild(cartContainer)

        bundles.forEach(element => {
            //add to html
            // let el = document.createElement('p')
            // el.setAttribute('id', element.id)
            // el.setAttribute('class', 'bundleItem')
            // let textnode = document.createTextNode(element.DisplayName + ' - ' + rmtodollar(element.VirtualCurrencyPrices.RM))
            
            // el.appendChild(textnode)
            // // el.appendChild(document.createTextNode('\u2718'));
            // el.onclick = function() { selectBundle(element) }

            // document.getElementById('bundleContainer').appendChild(el)
        });

        setupUI(scene, camera, renderer, canvas)
        BuildATMUI(bundles, initiatePurchase, () => {
            // document.getElementById("tutContainer").classList.remove('hidden')
            ActivateTut()
            TutDisplay(true)
        })
        
        //screen resizer + change callback
        screenSizeListener(camera, renderer, mediaQueryChange)

        switch (device) {
            // case 'desktop':
            // //move camera in
            // gsap.to(camera.position, {duration: 2.5, x: 0.94, y: 2.1, z: 0.6, ease: "power1.out", delay: 0.75})
            
            // break;
            // case 'tabletProtrait':
            // //move camera in
            // gsap.to(camera.position, {duration: 2.5, x: 0.94, y: 2.1, z: 0.6, ease: "power1.out", delay: 0.75})
            
            // break;
            case 'mobileLandscape':
            //move camera in
            gsap.to(camera.position, {duration: 2.5, x: 0.94, y: 1.98, z: 0.3, ease: "power1.out", delay: 0.75})
            
            break;
            // case 'mobilePortait':
            // //move camera in
            // gsap.to(camera.position, {duration: 2.5, x: 0.94, y: 2.1, z: 0.6, ease: "power1.out", delay: 0.75})
            
            // break;
            default:
                //move camera in
                gsap.to(camera.position, {duration: 2.5, x: 0.94, y: 2.1, z: 0.7, ease: "power1.out", delay: 0.75})
            break;
        }

        InitTut('atm', user, [])

        

    })

    // return [
    //     {'name' : 'One Decible', 'price' : '$0.01', 'id' : 'db-bundle-1'},
    //     {'name' : 'Two Decibles', 'price' : '$0.02', 'id' : 'db-bundle-2'},
    //     {'name' : 'Three Decibles', 'price' : '$0.03', 'id' : 'db-bundle-3'}
    // ]
}


function selectBundle (bundle) {
    selectedBundle = bundle

    initiatePurchase()
}
let curOrder
let curBundle
function initiatePurchase(bundle) {

    if (tutState == 1) {
        return
    }

    //open blank window and save it to have url set once we get it back
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=0,height=0,left=-1000,top=-1000`;

    var theWindow = window.open('','purchase', params)

    fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
        method: "POST",
        headers: {
            "Accept" : "text/plain",
            "X-EntityToken" : user.entityToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({

            "FunctionName" : "PlayPal_StartPurchase",
            "PlayFabId" : user.playFabId,
            "FunctionParameter" : {
                "itemId" : bundle.ItemId,
                "ticket" : user.playFabSessionToken
            }


        })
    })
    .then(response => response.json())
    .then(data => {

        const order = data.data.FunctionResult

        fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
            method: "POST",
            headers: {
                "Accept" : "text/plain",
                "X-EntityToken" : user.entityToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                "FunctionName" : "PlayPal_PayForPurchase",
                "PlayFabId" : user.playFabId,
                "FunctionParameter" : {
                    "orderId" : order.orderId,
                    "ticket" : user.playFabSessionToken
                }


            })
        })
        .then(response => response.json())
        .then(data => {

            curOrder = order
            curBundle = bundle

            redirectToPurchase(theWindow, data.data.FunctionResult.confirmationURL)

        });

    });
}

function redirectToPurchase (thewindow, url) {
    leftForPurchase = true
    listenForPurchaseFocus = true
    thewindow.location = url

    
}

async function CompareCurrency() {
    //display loading
    document.getElementsByClassName('loading')[0].style.display = 'block'

    let curCurrency = await GetCurrency()
    if (curCurrency["DB"] > userCurrency["DB"]) {
        //we now have more money than before
        listenForPurchaseFocus = false
        leftForPurchase = false
        //hide loading
        document.getElementsByClassName('loading')[0].style.display = 'none'

        //send GTM event
        SendGTMEvent({
            'event': 'purchase_vc',
            'transaction_id': curOrder.orderId,
            //'affiliation': streamId, // Can comment this out on atm.js
            'currency': 'USD',
            'value': curBundle.VirtualCurrencyPrices.RM, // GA4 requires the value in cents, which I think this is
            'items': [{
                'item_name': curBundle.DisplayName,
                'item_id': curBundle.ItemId,
                'price': curBundle.VirtualCurrencyPrices.RM,
                'item_brand': 'frisson',
                'item_category': 'Virtual Currency', // I think in the current implementation, we have this falsely labeled as 'Vibes'?
                'quantity': 1
            }]
        })

        alert('Purchase Successful!')
    }
    else {
        //hide loading
        document.getElementsByClassName('loading')[0].style.display = 'none'
    }
}

async function GetCurrency() {
    const currency = await fetch(
        'https://'+ titleId + '.playfabapi.com/Client/GetUserInventory',
        {
            method: 'POST',
            headers: {
                Accept: 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization': user.playFabSessionToken
            }
        }
    )
    .then(function (response) {
        if (response.status >= 400 && response.status < 600) {
            // failure in getting totals
        } else if (response.status === 200) {
            return response.json()
        } else {
            // failure in getting totals
            return null
        }
    })
    .then((data) => {
        if (data) {
            // display the new total somewhere
            return data.data.VirtualCurrency
        }
    })
    .catch(function (err) {
        //no existing session with this id
        console.error(err.toString())
        return null
    })
    
    return currency
}

// function initiatePurchase(id) {
//     fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
//         method: "POST",
//         headers: {
//             "Accept" : "text/plain",
//             "X-EntityToken" : user.entityToken,
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({

//             "FunctionName" : "PlayPal_StartPurchase",
//             "PlayFabId" : user.playFabId,
//             "FunctionParameter" : {
//                 "itemId" : id,
//                 "ticket" : user.playFabSessionToken
//             }


//         })
//     })
//     .then(response => response.json())
//     .then(data => {

//         const order = data.data.FunctionResult

//         fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
//             method: "POST",
//             headers: {
//                 "Accept" : "text/plain",
//                 "X-EntityToken" : user.entityToken,
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({

//                 "FunctionName" : "PlayPal_PayForPurchase",
//                 "PlayFabId" : user.playFabId,
//                 "FunctionParameter" : {
//                     "orderId" : order.orderId,
//                     "ticket" : user.playFabSessionToken
//                 }


//             })
//         })
//         .then(response => response.json())
//         .then(data => {

//             redirectToPurchase(data.data.FunctionResult.confirmationURL)

//         });

//     });
// }

// function redirectToPurchase (url) {
//     window.location.href = url
// }

function confirmPurchase() {
    fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
        method: "POST",
        headers: {
            "Accept" : "text/plain",
            "X-EntityToken" : user.entityToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({

            "FunctionName" : "PlayPal_ConfirmPurchase",
            "PlayFabId" : user.playFabId,
            "FunctionParameter" : {
                "orderId" : BigInt(orderId).toString('16').toString().toUpperCase(),// eslint-disable-line
                "ticket" : user.playFabSessionToken
            }


        })
    })
    .then(response => response.json())
    .then(data => {

        if (data.status === 200) {
            alert("Successful purchase!")
        }

    });
}


// let charProps2 = {
//     "items" : {
//         "head" : "head_only",
//         "head_acc" : "head_acc",
//         "face_acc" : "face_acc",
//         "body" : "body_only",
//         "body_acc" : "body_jazz",
//         "hands" : "hands_only",
//         "feet" : "feet_only"
//     },
//     "customizations" : {
//         "headSize":0.8,"bodySize":1,"handSize":1,"feetSize":1,"neckLength":1,"armLength":1,"legLength":0.1
//     },
//     "animations" : [
//         "twist",
//         // "slide",
//         // "robot"
//     ],
//     "loadedParts" : 0
// }

//===========================
//
//     Character Functions
// 
//===========================

// let catalogVersion = "Audiophile Items"

//for saving current character configuration
let currentCharProps

//Character property ranges
// const headSizeProp = {
//     "min" : 0.9,
//     "max" : 1.1
// }
// const neckLengthProp = {
//     "min" : 0.8,
//     "max" : 1
// }
// const bodySizeProp = {
//     "min" : 0.9,
//     "max" : 1.1
// }
// const handSizeProp = {
//     "min" : 0.9,
//     "max" : 1.2
// }
// const legLengthProp = {
//     "min" : -0.2,
//     "max" : 0.5
// }

// const feetSizeProp = {
//     "min" : 0.6,
//     "max" : 1
// }

// const bodyColorProp = {
//     "hMin" : 0,
//     "hMax" : 360,
//     "sMin" : 0,
//     "sMax" : 100,
//     "vMin" : 0,
//     "vMax" : 100,
// }

const characters = {};
this.characters = characters

// class Character {

//     constructor(characterProps) {
//         this.playerId = null
//         this.characterId = null
//         this.items = characterProps.items
//         this.defaultItems = {...characterProps.items}
//         this.defaultCustomizations = {...characterProps.customizations}
//         this.customizations = characterProps.customizations
//         this.animations = characterProps.animations
//         this.currentAnim = null
//         this.loadedParts = 0
//         this.startingPosition = new THREE.Vector3(0,0,0)
//         this.mixer = null
//         this.body = new THREE.Group();
//         this.body.name = "body"
//         this.bodyparts = {
//             //assigned after instatiation
//             //group all major pieces together for adjustment later
//             "skeleton" : null,
//             "head" : null,
//             "head_acc" : null,
//             "face_acc" : null,
//             "head_group" : new THREE.Group(),
//             "body" : null,
//             "clothes" : null,
//             "body_acc" : null,
//             "body_group" : new THREE.Group(),
//             "hands" : null,
//             "hands_acc" : null,
//             "hands_group" : new THREE.Group(),
//             "feet" : null
//         }
        
//         //apply the props passed in to the current holder for properties
//         currentCharProps = characterProps
//     }

    
    

//     spawn (playerId, characterId, xPos,yPos,zPos) {
//         this.playerId = playerId
//         this.CharacterId = characterId

//         this.startingPosition = new THREE.Vector3(xPos,yPos,zPos);
//         gltfLoader.load(modelPath + 'rig_only.glb', (object) => {

//             let loadedObject = object.scene
//             let loadedSkeleton = loadedObject.getObjectByName('body').skeleton
            
//             this.mixer = new THREE.AnimationMixer(loadedObject)
            
//             this.bodyparts.skeleton = loadedSkeleton

//             loadedObject.name = "skeleton"
//             loadedObject.visible = false
//             this.body.add(loadedObject)

//             let helper = new THREE.SkeletonHelper( loadedSkeleton.bones[0] );
//             // scene.add( helper );
//             scene.add(this.body)

//             //load all animations
//             for (let i = 0; i < this.animations.length; i++) {
//                 const anim = this.animations[i]

//                 //only load the animation if we haven't already
//                 if(!anims.anim) {
//                     gltfLoader.load(
//                         modelPath + 'anim_'+ anim +'.glb',
//                         (model) =>
//                         {
//                             //add this anim to the library
//                             anims[anim] = model.animations[0]
//                         }
//                     )
//                 }                
//             }

//             this.#spawnLoop()

//         })

        
        
//     }

//     #spawnLoop () {
//         this.#loadPart(Object.keys(this.items)[this.loadedParts], Object.values(this.items)[this.loadedParts], this.#spawnIterate); 
//     }

//     #spawnIterate = () => {
//         // set x to next item
//         this.loadedParts++;
    
//         // any more items in array? continue loop
//         if(this.loadedParts < Object.keys(this.items).length) {
//             this.#spawnLoop();   
//         }
//         else {

//             this.bodyparts.head_group.add(this.bodyparts.head)
//             if (this.bodyparts.head_acc) this.bodyparts.head_group.add(this.bodyparts.head_acc)
//             if (this.bodyparts.face_acc) this.bodyparts.head_group.add(this.bodyparts.face_acc)
//             this.bodyparts.head_group.name = "head group"

//             this.bodyparts.body_group.add(this.bodyparts.body)
//             if (this.bodyparts.body_acc) this.bodyparts.body_group.add(this.bodyparts.body_acc)
//             if (this.bodyparts.clothes) this.bodyparts.body_group.add(this.bodyparts.clothes)
//             this.bodyparts.body_group.name = "body group"

//             this.bodyparts.hands_group.add(this.bodyparts.hands)
//             if (this.bodyparts.hands_acc) this.bodyparts.hands_group.add(this.bodyparts.hands_acc)
//             this.bodyparts.hands_group.name = "hands group"

            

//             this.body.add(this.bodyparts.head_group)
//             this.body.add(this.bodyparts.body_group)
//             this.body.add(this.bodyparts.hands_group)
//             this.body.add(this.bodyparts.feet)

//             this.applyAdjustments()

//             this.body.position.set(this.startingPosition.x, this.startingPosition.y, this.startingPosition.z)

//             //start idle animation
//             this.startAnimation(Object.values(this.animations)[0])

//             console.log(currentCharProps)

//         }
//     }
    
//     #loadPart(type, id, callback) {
//         // const part = Object.keys(this.items)[this.loadedParts]
//         // const value = Object.values(this.items)[this.loadedParts]
    
//             if (id) {
//                 console.log('trying to create the ' + type)
//                 console.log('spawning the piece ' + id)
//                 const meshGroup = new THREE.Group();
//                 gltfLoader.load(
//                     modelPath + id + '.glb',
//                 (model) =>
//                     {

//                         // let obj = model.scene.getObjectByProperty("type", "SkinnedMesh")
//                         let obj = model.scene.getObjectByName('obj')
//                         obj.castShadow = true
                        
//                         //assign body parts
//                         this.bodyparts[type] = meshGroup
//                         this.bodyparts[type].name = type

//                         switch (type) {
//                             case "face":
//                                 texture1.flipY = false
//                                 texture2.flipY = false
//                                 // texture3.flipY = false
//                                 texture4.flipY = false
//                                 obj.material.onBeforeCompile = function ( shader ) {
//                                     // console.log(shader.vertexShader)
//                                     shader.uniforms.percent = {value: 1.0 }
//                                     shader.uniforms.tOne = {value: texture1}
//                                     shader.uniforms.tSec = {value: texture4}
//                                     shader.vertexShader = 'varying vec2 vUv;\n' + shader.vertexShader
//                                     shader.vertexShader = shader.vertexShader.replace(
//                                     '#include <worldpos_vertex>',
//                                     [
//                                         '#include <worldpos_vertex>',
//                                         'vUv = uv;'
//                                     ].join( '\n' )
//                                     );
//                                     shader.fragmentShader = 'uniform sampler2D tOne;\nuniform sampler2D tSec;\nvarying vec2 vUv;\nuniform float percent;\n' + shader.fragmentShader;
//                                     shader.fragmentShader = shader.fragmentShader.replace(
//                                       '#include <dithering_fragment>',
//                                       [
//                                         '#include <dithering_fragment>',
//                                         'vec3 c;',
//                                         'vec4 Ca = texture2D(tOne, vUv);',
//                                         'vec4 Cb = texture2D(tSec, vUv);',
//                                         'c = Ca.rgb * Ca.a + Cb.rgb * Cb.a * (1.0 - Ca.a);  // blending equation',
//                                         'float a = (Ca.a) + (Cb.a);',
//                                         'gl_FragColor= vec4(c, a);',
//                                         // 'if (gl_FragColor.a < 0.5 ) discard;'
//                                       ].join( '\n' )
//                                     );
//                                       obj.material.transparent = true
//                                     obj.material.userData.shader = shader
//                                 };
//                                 obj.scale.set(1.02,1.02,1.02)
//                                 obj.position.set(0,-0.04, 0)
//                                 this.bodyparts.head_group.add(this.bodyparts[type])
//                                 break
//                             case "head":
//                             case "head_acc":
//                             case "face_acc":
//                                 this.bodyparts.head_group.add(this.bodyparts[type])
//                                 break
                            
//                             case "body":
//                                 this.bodyparts.body_group.add(this.bodyparts[type])
//                                 break
//                             case "clothes":
//                                 this.bodyparts.body_group.add(this.bodyparts[type])
                                
//                                 // obj.material.onBeforeCompile = function ( shader ) {
//                                 //     // console.log(shader.vertexShader)
//                                 //     shader.uniforms.tOne = {value: texture3};
//                                 //     shader.uniforms.tSec = {value: texture1};
//                                 //     shader.vertexShader = 'varying vec2 vUv;\n' + shader.vertexShader;
//                                 //     shader.vertexShader = shader.vertexShader.replace(
//                                 //     '#include <worldpos_vertex>',
//                                 //     [
//                                 //         '#include <worldpos_vertex>',
//                                 //         'vUv = uv;'
//                                 //     ].join( '\n' )
//                                 //     );
//                                 //     shader.fragmentShader = 'uniform sampler2D tOne;\nuniform sampler2D tSec;\nvarying vec2 vUv;\n' + shader.fragmentShader;
//                                 //     shader.fragmentShader = shader.fragmentShader.replace(
//                                 //       '#include <dithering_fragment>',
//                                 //       [
//                                 //         '#include <dithering_fragment>',
//                                 //         'vec3 c;',
//                                 //         'vec4 Ca = texture2D(tOne, vUv);',
//                                 //         'vec4 Cb = texture2D(tSec, vUv);',
//                                 //         'c = Ca.rgb * Ca.a + Cb.rgb * Cb.a * (1.0 - Ca.a);  // blending equation',
//                                 //         'gl_FragColor= vec4(c, 1.0);'
//                                 //       ].join( '\n' )
//                                 //     );

//                                 //     obj.material.userData.shader = shader
//                                 // };

//                                 break
//                             case "body_acc":
//                                 this.bodyparts.body_group.add(this.bodyparts[type])
//                                 break
                            

//                             case "hands":
//                             case "hands_acc":
//                                 this.bodyparts.hands_group.add(this.bodyparts[type])
//                                 break

//                             default:
//                                 this.body.add(this.bodyparts[type])

//                                 break;
//                         }

//                         if (obj.type == "Group") {
//                             for (let i = 0; i < obj.children.length; i++) {
//                                 const element = obj.children[i];
                                
//                                 element.bind(this.bodyparts.skeleton, meshGroup.matrixWorld)
//                                 element.bindMode = 'detached'

//                                 meshGroup.add(element)

//                             }
//                         }
//                         else if (obj.type == "SkinnedMesh") {
//                             obj.bind(this.bodyparts.skeleton, meshGroup.matrixWorld)
//                             obj.bindMode = 'detached'

//                             meshGroup.add(obj)
//                         }
                        

//                         meshGroup.position.set(-this.startingPosition.x, -this.startingPosition.y, -this.startingPosition.z)

//                         console.log('done creating the ' + type)
//                         if (callback) callback()
//                     },
//                     // null,
//                     // this.loadError(type, callback? callback : null)
//                 )
//             }
//             else {
//                 console.log('Character not using ' + type)
//                 if (callback) callback()
//             }
//     }

//     replacePart (type, id) {
//         //delete part of that type if the user has it equipped
//         if (this.bodyparts[type]) {

//             let canRemove = false
//             if (id == characters[user.characters[0].CharacterId].items[type]) {
//                 switch (type) {
//                     case "head_acc":
//                     case "face_acc":
//                     case "body_acc":
//                     case "hands_acc":
//                         canRemove = true
//                         break
//                     default:
//                         canRemove = false
//                         break
//                 }
//             }
//             else {
//                 canRemove = true
//             }

//             if (canRemove) {
//                 this.bodyparts[type].children.forEach(child => this.bodyparts[type].remove(child))
//                 scene.remove(this.bodyparts[type])

//                 //remove from sub groups if necessary
//                 switch (type) {
//                     case "head":
//                     case "head_acc":
//                     case "face_acc":
//                         this.bodyparts.head_group.remove(this.bodyparts[type])
//                         break
                    
//                     case "body":
//                     case "clothes":
//                         this.bodyparts.body_group.remove(this.bodyparts[type])
//                         break
//                     case "body_acc":
//                         this.bodyparts.body_group.remove(this.bodyparts[type])
//                         break
//                     case "hands":
//                     case "hands_acc":
//                         this.bodyparts.hands_group.remove(this.bodyparts[type])
//                         break
//                     default:
//                         this.body.remove(this.bodyparts[type])

//                         break;
//                 }
//             }

            
//         }
        
//             //make sure this is a different part than we already have, otherwise, just remove it
//             if (id != characters[user.characters[0].CharacterId].items[type]) {
//                 this.#loadPart(type, id, this.applyAdjustments)
                
//                 //set global current prooperties for saving later
//                 characters[user.characters[0].CharacterId].items[type] = id
//             }
//             else {
//                 //set global current prooperties for saving later
//                 characters[user.characters[0].CharacterId].items[type] = ''
//             }
        

        
        

//         console.log(currentCharProps)
//     }


//     startAnimation (action) {

//         const clipAction = this.mixer.clipAction(anims[action])

//         if (this.currentAnim) {
//             if ( this.currentAnim !== action ) {
//                 this.mixer.clipAction(anims[this.currentAnim]).fadeOut(1);
//             }
            
//             clipAction
//             .reset()
//             .setEffectiveTimeScale(1)
//             .setEffectiveWeight(1)
//             .fadeIn(1)
//             .play();
//         }
//         else {
//             clipAction.play()
//         }    

//         this.currentAnim = action

//     }

//     applyAdjustments = () => {
        
//         // "headSize" : 1.5,
//         // "bodySize" : 1,
//         // "handSize" : 1,
//         // "feetSize" : 1,
//         // "neckLength" : 1,
//         // "headSizeLengthOffset" : 0,
//         // "armLength" : 1,
//         // "legLength" : 0.5

//         //leg length
//         this.bodyparts.head_group.position.y = this.customizations.feet.legLength
//         this.bodyparts.body_group.position.y = this.customizations.feet.legLength
//         this.bodyparts.hands_group.position.y = this.customizations.feet.legLength
    
//         //head size
//         this.bodyparts.head_group.scale.set(this.customizations.head.headSize, this.customizations.head.headSize, this.customizations.head.headSize)

//         //neck length
//         this.bodyparts.head_group.position.y += this.customizations.head.neckLength

//         //body size
//         this.bodyparts.body_group.scale.set(this.customizations.body.bodySize, this.customizations.body.bodySize, this.customizations.body.bodySize)
//         let bodySizeHeightOffset = -this.customizations.body.bodySize * 1.5 + 1.55
//         this.bodyparts.body_group.position.y += bodySizeHeightOffset
//         this.morph()
        
        
//         //arm length

//         //hand size
//         this.bodyparts.hands_group.scale.set(this.customizations.hands.handSize, this.customizations.hands.handSize, this.customizations.hands.handSize)
//         let handSizeHeightOffset = -this.customizations.hands.handSize + 1.1
//         this.bodyparts.hands_group.position.y += handSizeHeightOffset
//         // this.bodyparts.hands_group.position.z -= handSizeHeightOffset

//         //feet size
//         this.bodyparts.feet.scale.set(this.customizations.feet.feetSize, this.customizations.feet.feetSize, this.customizations.feet.feetSize)
        
        
//         //offset for neck length and for head offset
//         let headSizeLengthOffset = -this.customizations.head.headSize * 1.5 + 0.75
//         this.bodyparts.head_group.position.y += headSizeLengthOffset

//         //add skintone
//         this.bodyparts.head.getObjectByName('obj').material.color.set(hslToHex(this.customizations.body.bodyColor.h, this.customizations.body.bodyColor.s, this.customizations.body.bodyColor.v))
//         this.bodyparts.body.getObjectByName('obj').material.color.set(hslToHex(this.customizations.body.bodyColor.h, this.customizations.body.bodyColor.s, this.customizations.body.bodyColor.v))
//         this.bodyparts.hands.getObjectByName('obj').material.color.set(hslToHex(this.customizations.body.bodyColor.h, this.customizations.body.bodyColor.s, this.customizations.body.bodyColor.v))

//         // this.bodyparts.head.getObjectByName('obj').material.color.set( this.customizations.body.bodyColor )
//         // this.bodyparts.body.getObjectByName('obj').material.color.set( this.customizations.body.bodyColor )
//         // this.bodyparts.hands.getObjectByName('obj').material.color.set( this.customizations.body.bodyColor )
        
        
//         scene.add(this.body)
//     }

//     changeMorph(increase, head) {
//         if (increase && this.customizations.body.morph < 4) {
//             this.customizations.body.morph ++
//         }
//         else if (!increase && this.customizations.body.morph > 0) {
//             this.customizations.body.morph --
//         }
//         this.morph(head)
//     }

//     morph(head) {
//         if (head) {
            
//         }
//         else {
//             let newMorph = [0,0,0,0,0]
//             newMorph[this.customizations.body.morph] = 1

//             //set morph target for body & clothes mesh
//             this.bodyparts.body_group.children.forEach(element => {
//                 element.children[0].morphTargetInfluences = newMorph
//             });
//         }
        
//     }

//     loadError (part, callback) {
//         console.log("Load error, character " + this.characterId + " failed to load their " + part)
//         callback()
//     }

// }

//0-360, 0-100, 0-100
function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

// function MorphMainChar (increase, head) {
//     characters[GetMainCharacter()]?.changeMorph(increase, head)
//     // if (increase) {
//     //     if (head) {
//     //         characters[GetMainCharacter()]?.changeMorph(true, true)
//     //     }
//     //     else {
//     //         characters[GetMainCharacter()]?.changeMorph(true, true)
//     //     }
        
//     // }
//     // else {
//     //     characters[GetMainCharacter()]?.changeMorph(false)
//     // }
// }

//find and return all objects of type in a scene
// function traverseScene (scene, type) {
//     let objs

//     for (let i = 0; i < array.length; i++) {
//         const element = array[i];
        
//         if (element.type === type) {
//             obj.push(element)
//         }

//     }
// }

// function getChildren (obj) {
    
// }

//===========================
//
//     External Functions
// 
//===========================

/*    Default Assigns    */
// const catalog = {
//     "head_acc": [],
//     "face_acc": [],
//     "clothes": [],
//     "body_acc": [],
//     "hands_acc": [],
//     "feet": []
// }

// let catalog = new Catalog()

// function FireCloudscript(cs, args) {
//     fetch('https://' + titleId + '.playfabapi.com/Client/ExecuteCloudScript', {
//         method: "POST",
//         headers: {
//             "Accept" : "text/plain",
//             "X-Authorization" : sessionTicket,
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({

//             "FunctionName" : cs,
//             "FunctionParameter" : args


//         })
//     })
//     .then(response => response.json())
//     .then(data => {

//         console.log('updated user info');

//     });
// }

function GetUserCharacter () {
    //get user main character
    fetch('https://' + titleId + '.playfabapi.com/Client/GetUserReadOnlyData', {
        method: 'POST',
        headers: {
            'Accept' : 'text/plain',
            'Content-Type': 'application/json',
            'X-Authorization' : user.playFabSessionToken
        },
        body:  JSON.stringify({
            "Keys" : ["mainCharacter"]
        })
    })
    .then(function(response){
        if (response.status >= 400 && response.status < 600) {
            response.json().then(function(object) {
                alert(object.errorMessage);
            })
        } else if (response.status === 200) {
            return response.json()
        }
        
    }).then(data => {
        user.mainCharacter = data.data.Data.mainCharacter.Value

        //we have to get all the user's characters first
        fetch('https://' + titleId + '.playfabapi.com/Client/GetAllUsersCharacters', {
            method: 'POST',
            headers: {
                'Accept' : 'text/plain',
                'Content-Type': 'application/json',
                'X-Authorization' : user.playFabSessionToken
            },
            body:  JSON.stringify({
                "PlayFabId" : user.playFabId
            })
        })
        .then(function(response){
            if (response.status >= 400 && response.status < 600) {
                response.json().then(function(object) {
                    alert(object.errorMessage);
                })
            } else if (response.status === 200) {
                return response.json()
            }
            
        })
        .then(data => {
            let userChars = []
            if (Object.keys(data.data.Characters).length != 0) {

                for (let i = 0; i < data.data.Characters.length; i++) {
                    const char = data.data.Characters[i];

                    if (char.CharacterType === 'audiophile-1') {
                        userChars.push(char)
                    }
                    
                }

                if (userChars.length) {
                    user.characters = userChars

                    //get the data now that we have the character id
                    fetch('https://' + titleId + '.playfabapi.com/Client/GetCharacterReadOnlyData', {
                        method: 'POST',
                        headers: {
                            'Accept' : 'text/plain',
                            'Content-Type': 'application/json',
                            'X-Authorization' : user.playFabSessionToken
                        },
                        body:  JSON.stringify({
                            "PlayFabId" : user.playFabId,
                            "CharacterId": user.mainCharacter,
                            "Keys" : ["items", "customizations", "animations"]
                        })
                    })
                    .then(function(response){
                        if (response.status >= 400 && response.status < 600) {
                            response.json().then(function(object) {
                                alert(object.errorMessage);
                            })
                        } else if (response.status === 200) {
                            return response.json()
                        }
                        
                    })
                    .then(data => {

                        if (Object.keys(data.data.Data).length != 0) {

                            let props = data.data.Data;
                            
                            console.log("successful data fetch")
                            let charProps = {
                                "items" : JSON.parse(props.items.Value),
                                "customizations" : JSON.parse(props.customizations.Value),
                                "animations" : JSON.parse(props.animations.Value)
                            }
                            let newChar = new Character(charProps)
                            characters[user.mainCharacter] = newChar
                            newChar.spawn(user.playFabId,user.mainCharacter,0.18,0.175,scene)
                            newChar.body.rotation.set(0,1.5,0)

                            //get existing inventory
                            // GetOwnedItems()

                            // PrepInitialControlPops(charProps)

                        }
                        else {
                            console.log("no character item data, drop in the defaults")
                        }

                        //gui for changes
                        // gui.add(characters[user.characters[0].CharacterId].customizations.body.bodyColor, "h", 0, 360, 1).onChange(() => {
                        //     characters[user.characters[0].CharacterId].applyAdjustments()
                        // })
                        // gui.add(characters[user.characters[0].CharacterId].customizations.body.bodyColor, "s", 0, 100, 1).onChange(() => {
                        //     characters[user.characters[0].CharacterId].applyAdjustments()
                        // })
                        // gui.add(characters[user.characters[0].CharacterId].customizations.body.bodyColor, "v", 0, 100, 1).onChange(() => {
                        //     characters[user.characters[0].CharacterId].applyAdjustments()
                        // })
                        // gui.add(characters[user.characters[0].CharacterId].customizations.head, "neckLength", neckLengthProp.min, neckLengthProp.max, 0.001).onChange(() => {
                        //     characters[user.characters[0].CharacterId].applyAdjustments()
                        // })
                        // gui.add(characters[user.characters[0].CharacterId].customizations.body, "bodySize", bodySizeProp.min, bodySizeProp.max, 0.001).onChange(() => {
                        //     characters[user.characters[0].CharacterId].applyAdjustments()
                        // })
                        // // gui.add(charProps.customizations, "armLength", 0.9, 1.1, 0.001)
                        // gui.add(characters[user.characters[0].CharacterId].customizations.hands, "handSize", handSizeProp.min, handSizeProp.max, 0.001).onChange(() => {
                        //     characters[user.characters[0].CharacterId].applyAdjustments()
                        // })
                        // gui.add(characters[user.characters[0].CharacterId].customizations.feet, "legLength", legLengthProp.min, legLengthProp.max, 0.001).onChange(() => {
                        //     characters[user.characters[0].CharacterId].applyAdjustments()
                        // })
                        // gui.add(characters[user.characters[0].CharacterId].customizations.feet, "feetSize", feetSizeProp.min, feetSizeProp.max, 0.001).onChange(() => {
                        //     characters[user.characters[0].CharacterId].applyAdjustments()
                        // })
                        // gui.addColor(characters[user.characters[0].CharacterId].customizations.body, "bodyColor").name("Body Color").onChange(() => {
                        //     characters[user.characters[0].CharacterId].applyAdjustments()
                        // })
                        
                    }).catch(function (err) {

                    //no existing session with this id
                        return console.log(err);
                    });
                }
                else {
                    return console.log("User has no character added to account")
                }

                

            }
            else {
                console.log("User has no characters somehow...")
            }
            
        }).catch(function (err) {

        //no existing session with this id
            return console.log(err);
        });
    })
}

function GetMainCharacter () {
    return user.characters?.[0].CharacterId
}

// function PrepInitialControlPops (charProps) {

//     let props = {}

//     Object.values(charProps.customizations).forEach(part => {

//         Object.keys(part).forEach(val => {
//             console.log(val)
//             switch (val) {
//                 case "headSize":
//                     props[val] = getRatio(part[val], headSizeProp.min, headSizeProp.max)
//                     break;
//                 case "neckLength":
//                     props[val] = getRatio(part[val], neckLengthProp.min, neckLengthProp.max)
//                     break;
//                 case "bodySize":
//                     props[val] = getRatio(part[val], bodySizeProp.min, bodySizeProp.max)
//                     break;
//                 case "bodyColor":
//                     props["hue"] = getRatio(part[val].h, bodyColorProp.hMin, bodyColorProp.hMax)
//                     props["sat"] = getRatio(part[val].s, bodyColorProp.sMin, bodyColorProp.sMax)
//                     props["value"] = getRatio(part[val].v, bodyColorProp.vMin, bodyColorProp.vMax)
//                     break;
//                 case "handSize":
//                     props[val] = getRatio(part[val], handSizeProp.min, handSizeProp.max)
//                     break;
//                 case "legLength":
//                     props[val] = getRatio(part[val], legLengthProp.min, legLengthProp.max)
//                     break;
//                 case "feetSize":
//                     props[val] = getRatio(part[val], feetSizeProp.min, feetSizeProp.max)
//                     break;
//                 default:
//                     break;
//             }
            
//         })
        
//     })

//     setInitialVal(props)
// }

function GetOwnedItems () {
    fetch('https://' + titleId + '.playfabapi.com/Client/GetUserInventory', {
        method: 'POST',
        headers: {
            'Accept' : 'text/plain',
            'Content-Type': 'application/json',
            'X-Authorization' : user.playFabSessionToken
        },
        // body:  JSON.stringify({
        //     "CharacterId": user.characters[0].CharacterId
        // })
    })
    .then(function(response){
        if (response.status >= 400 && response.status < 600) {
            response.json().then(function(object) {
                alert(object.errorMessage);
            })
        } else if (response.status === 200) {
            return response.json()
        }
        
    })
    .then(data => {

        if (Object.keys(data.data.Inventory).length != 0) {

            let inventory = [];
            let items = data.data.Inventory

            for (let i = 0; i < items.length; i++) {

                //check if it's an item, it will have it's id delineated with a bar
                if (items[i].ItemId.includes('|')) {
                    const item = parseCatalogItem(items[i])
                
                    if (item.type !== 'head') {
                        inventory.push(item)
                    }
                }
                
            }

            user.inventory = new Catalog()

            inventory.forEach(item => {
                // let name = {}
                // name[item.name] = () => {characters[user.characters[0].CharacterId].replacePart(item.type, item.id)}
                // gui.add(name, item.name)

                user.inventory[item.type].push(item)

            });

            // Object.keys(user.inventory).forEach(element => {
            //     let el = document.createElement('a')
            //     // let price = element.price == 0 ? "Free" : element.price + 'DB';
            //     let textnode = document.createTextNode(element + ' - ' + user.inventory[element].length)
                
            //     el.appendChild(textnode)
            //     // el.appendChild(document.createTextNode('\u2718'));
            //     el.onclick = function() { 
            //         CategoryStartSpawn(element, user.inventory) 
            //     }

            //     document.getElementById('inventoryContainer').appendChild(el)
            //     let linebreak = document.createElement("br");
            //     document.getElementById('inventoryContainer').appendChild(linebreak)
            // });

            // let save = {}
            // save["Save Character!"] = () => { SaveCharacter() }
            // gui.add(save, "Save Character!")

            // itemPool = user.inventory

            //now that we have the user's items, get catalog and filter out owned items
            // GetCatalogItems()

        }
        else {
            console.log("no character item data, drop in the defaults")
        }
        
    }).catch(function (err) {

    //no existing session with this id
        return console.log(err);
    });
}

// function GetCatalogItems () {
//     //reset catalog
//     catalog = new Catalog()

//     fetch('https://' + titleId + '.playfabapi.com/Client/GetCatalogItems', {
//         method: 'POST',
//         headers: {
//             'Accept' : 'text/plain',
//             'Content-Type': 'application/json',
//             'X-Authorization' : user.playFabSessionToken
//         },
//         body:  JSON.stringify({
//             "CatalogVersion": catalogVersion
//         })
//     })
//     .then(function(response){
//         if (response.status >= 400 && response.status < 600) {
//             response.json().then(function(object) {
//                 alert(object.errorMessage);
//             })
//         } else if (response.status === 200) {
//             return response.json()
//         }
        
//     })
//     .then(data => {

//         if (Object.keys(data.data.Catalog).length != 0) {

//             // let catalog = [];
//             let items = data.data.Catalog

//             for (let i = 0; i < items.length; i++) {

                


//                 //check if it's an item, it will have it's id delineated with a bar
//                 if (items[i].ItemId.includes('|')) {
//                     const item = parseCatalogItem(items[i])

//                     if (item.type !== 'head' && item.type !== 'body' && item.type !== 'hands') {
                        
//                         //make sure item isn't already in user's inventory
//                         // let owned = false
//                         // for (let j = 0; j < user.inventory[item.type].length; j++) {
//                         //     const element = user.inventory[item.type][j];
                            
//                         //     if (element.id === item.id) {
//                         //         owned = true
//                         //     }
//                         // }
                        
//                         // if (!owned) {
//                           catalog[item.type].push(item)  
//                         // }
                        
//                     }
   
//                 }
                
//             }

//             // Object.keys(catalog).forEach(element => {
//             //     let el = document.createElement('a')
//             //     // let price = element.price == 0 ? "Free" : element.price + 'DB';
//             //     let textnode = document.createTextNode(element + ' - ' + catalog[element].length)
                
//             //     el.appendChild(textnode)
//             //     // el.appendChild(document.createTextNode('\u2718'));
//             //     el.onclick = function() { 
//             //         CategoryStartSpawn(element, catalog) 
//             //     }

//             //     document.getElementById('catalogContainer').appendChild(el)
//             //     let linebreak = document.createElement("br");
//             //     document.getElementById('catalogContainer').appendChild(linebreak)
//             // });

//             //hook up temp cart buy button
//             // document.getElementById('buyButton').onclick = function () { PurchaseItems() }
            
//             itemPool = catalog

//             //if there's already a category being displayed, display it again once
//             if (currentCategory) CategoryStartSpawn()

//         }
//         else {
//             console.log("no character item data, drop in the defaults")
//         }
        
//     }).catch(function (err) {

//     //no existing session with this id
//         return console.log(err);
//     });
// }

// //when spawning categories:
//     //determine shelf type by category type
//     //spawn in the appropriate number of shelves based on type and number of items in category
//     //keep track of shelf bones based on number of bones in particular shelf


// let loadedCategoryParts = 0
// let currentCategory = null
// let currentCatalog = null
// let ownedItems = []
// let itemPool = null
// //shelf
// let currentShelf = null
// let currentBone = 0
// let shelves = []
// let shelfType
// let shelfOffset = 2.2
// let boneOffset = 0
// let heightOffset = 0
// let scaleOffset = 0.02
// const categoryContainer = []

// function CategoryStartSpawn (category) {
//     if (category) currentCategory = category
//     currentCatalog = itemPool
//     loadedCategoryParts = 0
//     ownedItems = []

//     //make sure item isn't already in user's inventory
//     for (let i = 0; i < currentCatalog[currentCategory].length; i++) {
//         const item = currentCatalog[currentCategory][i];

//         for (let j = 0; j < user.inventory[currentCategory].length; j++) {
//             const element = user.inventory[currentCategory][j];
            
//             if (element.id === item.id) {
//                 ownedItems.push(i)
//             }
//         }
//     }
    

//     //shelf
//     resetShelves()

//     switch (currentCategory) {
//         case "head_acc":
//         case "face_acc":
//             shelfType = "shelf-4x3-wBones"
//             boneOffset = 12
//             heightOffset = -2.22
//             scaleOffset = 0.014
//             break
//         case "hands_acc":
//             shelfType = "shelf-4x3-wBones"
//             boneOffset = 12
//             heightOffset = -1.7
//             scaleOffset = 0.02
//             break
//         case "feet":
//             shelfType = "shelf-4x3-wBones"
//             boneOffset = 12
//             heightOffset = -0.55
//             scaleOffset = 0.011
//             break
//         case "clothes":
//             shelfType = "shelf-4x2-wBones"
//             boneOffset = 8
//             heightOffset = -2
//             scaleOffset = 0.02
//             break
//         case "body_acc":
//             shelfType = "shelf-4x3-wBones"
//             boneOffset = 8
//             heightOffset = -2
//             scaleOffset = 0.02
//             break
//         default:
//             shelfType = "shelf-4x3-wBones"
//             boneOffset = 8
//             heightOffset = -2
//             scaleOffset = 0.02
//             break;
//     }

//     categoryContainer.forEach(element => {
//         scene.remove(element)
//     });

//     categoryContainer.children = []

//     CategorySpawnLoop()
// }

// function CategorySpawnLoop () {    
//     if (currentCatalog[currentCategory].length > 0) {
//         LoadCategoryPart(Object.values(currentCatalog[currentCategory])[loadedCategoryParts].id, Object.values(currentCatalog[currentCategory])[loadedCategoryParts].type, CategorySpawnIterate); 
//     }
// }

// var CategorySpawnIterate = () => {
//     // set x to next item
//     loadedCategoryParts++;

//     //find current shelf bone
//     // currentBone = loadedCategoryParts - ((shelves.length - 1) * boneOffset)

//     // any more items in array? continue loop
//     if(loadedCategoryParts < Object.keys(currentCatalog[currentCategory]).length) {
//         CategorySpawnLoop()
//     }
//     else {
//         //we're done
//         loadedCategoryParts = 0
//         // currentCategory = null
//         currentBone = 0

//         // scene.add(categoryContainer)
//         scene.add(shelfContainer)

//         shelves.forEach(shelf => {
//             interactables.push(shelf.children[0])
//         });
//         // interactables.push(shelf.children[0])
//     }
// }

// async function LoadCategoryPart(id, type, callback) {

//         if (id) {

//             //check if we need another shelf
//             if (shelves.length <= loadedCategoryParts / boneOffset) {

//                 const object = await modelLoader(modelPath + shelfType + '.glb')

//                 // gltfLoader.load('/models/' + shelfType + '.glb', (object) => {
//                     let obj = object.scene
//                     obj.name = "Shelf_" + (shelves.length + 1)
//                     obj.tag = "drag"
//                     obj.position.set(shelves.length * shelfOffset,0,-0.7)
//                     // obj.rotation.set(0,-0.75,0)
//                     // obj.scale.set(1.5,1.5,1.5)
            
//                     //set up shadow cast, this might have to change if the hierarchy of the shelf model changes
//                     obj.children[0].children.forEach(mesh => {
//                         mesh.castShadow = true
//                     });
                    
//                     currentShelf = obj
//                     //save slots and bones onto shelf object
//                     currentShelf.slots = []
//                     currentShelf.bones = []

//                     for (let i = 0; i < obj.children.length; i++) {
//                         const element = obj.children[i];
                        
//                         if (element.name.includes('slot')) {
//                             element.tag = 'select'
//                             element.visible = false
                            
//                             //only make slot interactable if it's not owned
//                             // if (!ownedItems.includes(shelves.length + currentShelf.slots.length)) {
//                                 interactables.push(element)
//                             // }
                            
                            
//                             currentShelf.slots.push(element)
            
                            
                            
//                         }
//                         else if (element.name.includes('Armature')) {
//                             element.children.forEach(chdrn => {
//                                 if (chdrn.name.includes('Bone')){
//                                     currentShelf.bones.push(chdrn)
//                                 }
//                             });
//                         } 
//                     }
                    
//                     //add whole shelf to interactables too in case the drag outside the slots
//                     // interactables.push(obj)
            
//                     shelfContainer.add(obj)
//                     draggableShelves.push(obj)
//                     shelves.push(obj)

//                     currentBone = 0
//                 // })
//             }

//             console.log('spawning the piece ' + id)
            
//             // gltfLoader.load(
//             //     modelPath + id + '.glb',
//             // (model) =>
//             //     {

//                     // let obj = model.scene.getObjectByProperty("type", "SkinnedMesh")
//                     let obj = await modelLoader(modelPath + id + '.glb')
//                         obj = obj.scene
//                     obj.name = id

//                     if (obj.type == "Group") {
//                         for (let i = 0; i < obj.children.length; i++) {
//                             const element = obj.children[i];
                            
//                             // element.bind(this.bodyparts.skeleton, categoryContainer.matrixWorld)
//                             // element.bindMode = 'detached'

//                             element.scale.set(scaleOffset, scaleOffset, scaleOffset)

//                             // categoryContainer.push(element)

//                         }
//                     }
//                     else if (obj.type == "SkinnedMesh") {
//                         // obj.bind(this.bodyparts.skeleton, categoryContainer.matrixWorld)
//                         // obj.bindMode = 'detached'

//                         obj.scale.set(scaleOffset, scaleOffset, scaleOffset)

//                         // categoryContainer.push(obj)
//                     }
                    
//                     // //this needs to be changed for a particular item offset
//                     // obj.translateY(heightOffset)
                    
//                     // //add item id to appropriate slot on shelf
//                     // let tempCurBone = currentBone
//                     // let tempCurShelf = currentShelf
                    
                    
//                     //if we own the item, make it invisible and unclickable
//                     if (ownedItems.includes((shelves.length - 1) + currentBone)) {
//                         obj.visible = false
//                     }
//                     else {
//                         currentShelf.slots[currentBone].callback = [() => {characters[user.characters[0].CharacterId].replacePart(type, id)},() => {toggleShelfItem(tempCurShelf, tempCurBone)}]
//                         currentShelf.getObjectByName("Armature").children[currentBone].add(obj)
//                     }

//                     //temp hide the item if it's currently equipped
//                     if (characters[user.characters[0].CharacterId].items[type] === id) {
//                         obj.visible = false
//                     }

//                     let newPlaceholder

//                         switch (type) {
//                             case "head_acc":
//                             case "face_acc":
//                                 newPlaceholder = await modelLoader(modelPath + 'head_placeholder.glb')
//                                 newPlaceholder = newPlaceholder.scene
//                                 newPlaceholder.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
//                                 newPlaceholder.translateY(heightOffset)
                                
//                                 // scene.add(newPlaceholder)
//                                 break
//                             case "hands_acc":
//                                 newPlaceholder = await modelLoader(modelPath + 'hands_placeholder.glb')
//                                 newPlaceholder = newPlaceholder.scene
//                                 newPlaceholder.children[0].scale.set(scaleOffset, scaleOffset, scaleOffset)
//                                 newPlaceholder.translateY(heightOffset)
//                                 break
//                             case "feet":
                                
//                                 break
//                             case "clothes":
                                
//                                 break
//                             case "body_acc":
                                
//                                 break
//                             default:
                                
//                                 break;
//                         }
                        
//                         //this needs to be changed for a particular item offset
//                         obj.translateY(heightOffset)
                        
//                         //add item id to appropriate slot on shelf
//                         let tempCurBone = currentBone
//                         let tempCurShelf = currentShelf
//                         currentShelf.slots[currentBone].callback = [() => {characters[user.characters[0].CharacterId].replacePart(type, id)},() => {toggleShelfItem(tempCurShelf, tempCurBone)}]
//                         currentShelf.getObjectByName("Armature").children[tempCurBone].add(obj)
//                         currentShelf.getObjectByName("Armature").children[tempCurBone].add(newPlaceholder) 


//                     currentBone ++

//                     // categoryContainer.position.set(-this.startingPosition.x, -this.startingPosition.y, -this.startingPosition.z)

//                     // console.log('done creating the ' + type)

//                     //check if the object is already equipped
//                     // Object.values(characters[GetMainCharacter()].items).forEach(element => {
//                     //     if (element === id) {
//                     //         toggleShelfItem(tempCurShelf, tempCurBone)
//                     //     }
//                     // });

//                     if (callback) callback()
//                 // },
//                 // null,
//                 // this.loadError(type, callback? callback : null)
//             // )
//         }
//         else {
//             // console.log('Character not using ' + type)
//             if (callback) callback()
//         }
// }

// let prevSelection
// function toggleShelfItem (shelf, index) {

//     let vis = shelf.bones[index].children[0].visible

//     //make all visible
//     shelfContainer.children.forEach(shelf => {
//         shelf.bones.forEach(bone => {
//             if (bone.children[0]) bone.children[0].visible = true
//         });
//     });

//     shelf.bones[index].children[0].visible = !vis


//     // if (prevSelection != shelf.bones[index].children[0]) {
//     //     if (prevSelection) prevSelection.visible = !prevSelection.visible
//     // }
    
//     // shelf.bones[index].children[0].visible = !shelf.bones[index].children[0].visible
//     // prevSelection = shelf.bones[index].children[0]
// }

// function resetShelves () {

//     //manual child disposing for now
//     // shelfContainer.children.forEach(shelf => {
//     //     //for each of the shelves
//     //     shelf.children.forEach(el => {
//     //         if (el.name.includes('Armature')) {
//     //             //this is where the bones and each of the clothing items are
//     //             el.children.forEach(item => {
//     //                 //this is the actual shelf mesh
//     //                 if (item.type.includes('Mesh')) {
//     //                     item.geometry.dispose();
//     //                     item.material.dispose(); 
//     //                 }
//     //                 //these are the bones holding the clothes
//     //                 else {
//     //                     //this is the contents of the clothing item scen
//     //                     //   scene       Armature    SkinnedMesh
//     //                     item.children[0]?.children[0].children[1].geometry.dispose()
//     //                     item.children[0]?.children[0].children[1].material.dispose()
//     //                 }
//     //             });
//     //         }
//     //         else if (el.name.includes('slot')) {
//     //             el.geometry.dispose()
//     //             el.material.dispose()
//     //         }
//     //     });
//     // });


//     while(shelfContainer.children.length > 0) {
//         // shelfContainer.children[0].children.forEach(element => {
//         //     element.dispose()
//         // });
//         // shelfContainer.children[0].geometry.dispose();
//         // shelfContainer.children[0].material.dispose();
//         shelfContainer.remove(shelfContainer.children[0])
//         scene.remove(shelfContainer.children[0])
        
        
//     }
//     scene.remove(shelfContainer)
//     shelfContainer = new THREE.Group()
//     shelfContainer.name = "ShelfContainer"
//     shelfContainer.position.set(2.7,0,0.54)
//     shelfContainer.rotation.set(0,-0.39,0)
//     shelfContainer.scale.set(1.5,1.5,1.5)

//     shelves.length = 0
//     currentShelf = null

//     console.log("Interactables before: ")
//     console.log(interactables.length)

    
//     removeShelves(mixerControls, [PurchaseItems, CategoryStartSpawn])
//     // for (var i = interactables.length - 1; i >= 0; i--) {
        
//     //     if (interactables[i].name.includes('slot') || interactables[i].name.includes('Armature')) { 
//     //         console.log(interactables[i].name)
//     //         interactables.splice(i, 1);
//     //         console.log(interactables.length)
//     //     }
//     // }
//     interactables.push(characterSpinner)

//     console.log("Interactables after: ")
//     console.log(interactables.length)
// }

function modelLoader(url) {
    return new Promise((resolve, reject) => {
        gltfLoader.load(url, data=> resolve(data), null, reject);
    });
}   

//===========================
//
//     Purchase Functions
// 
//===========================

// const cart = new Cart()

// function SaveCharacter() {
//     fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
//         method: "POST",
//         headers: {
//             "Accept" : "text/plain",
//             "X-EntityToken" : user.entityToken,
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({

//             "FunctionName": "Save_Character_Configuration",
//             "FunctionParameter": {                
//                 "characterID" : user.characters[0].CharacterId,
//                 "customizations" : JSON.stringify(characters[user.characters[0].CharacterId].customizations),
//                 "items" : JSON.stringify(characters[user.characters[0].CharacterId].items)
//             }


//         })
//     })
//     .then(response => response.json())
//     .then(data => {

//         console.log('updated character items');

//     });
// }

// function PurchaseItems () {
//     fetch('https://' + titleId + '.playfabapi.com/CloudScript/ExecuteFunction', {
//         method: "POST",
//         headers: {
//             "Accept" : "text/plain",
//             "X-EntityToken" : user.entityToken,
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({

//             "FunctionName": 'PurchaseAudiophileItem',
//             "FunctionParameter": {
//                 "items" : cart.formattedItems,
//                 "catalogVersion": catalogVersion,
//                 "currency": "DB",
//                 "clientTotalCost": cart.price.toString()
//             }
//         })
//     })
//     .then(response => response.json())
//     .then(data => {

//         GetOwnedItems()

//         console.log('function successful')

//         characters[user.characters[0].CharacterId].resetChar()
        
//         cart.clear()

//     });
// }

function parseCatalogItem(item) {

    //couldn't do this in a single line :(
    let price = 0
    if (item.VirtualCurrencyPrices && item.VirtualCurrencyPrices['DB']) {
        price = item.VirtualCurrencyPrices['DB']
    }
    
    let processedItem = {
        id : item.ItemId.split('|')[0],
        type: item.ItemId.split('|')[1],
        name : item.DisplayName, 
        price : price
    }

    return processedItem
}

// function returnCatalogItem (id, type) {
//     let item = null
//     catalog[type].forEach(element => {
//         if (element.id === id) {
//             item = element
//         }
//     });

//     return item
// }
    
// function loadCatalogItem(item) {
    
//     if (!cart.contains(item)) {
//         cart.add(item)
//         characters[user.characters[0].CharacterId].replacePart(item.type, item.id)
//     }
//     else{
//         cart.remove(item)
//         characters[user.characters[0].CharacterId].replacePart(item.type, characters[user.characters[0].CharacterId].defaultItems[item.type])
//     }
    
//     console.log("Cart", cart)
    
    
// }

// var sizeMin = -0.3, sizeMax = 0.2, headMin = 0.4, headMax = -0.25
// const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;
// Number.prototype.map = function (in_min, in_max, out_min, out_max) {
//     return (this - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
//   }


/**
 * Sizes
 */
// const sizes = {
//     width: window.innerWidth,
//     height: window.innerHeight
// }

// window.addEventListener('resize', () =>
// {
//     // Update sizes
//     sizes.width = window.innerWidth
//     sizes.height = window.innerHeight

//     // Update camera
//     camera.aspect = sizes.width / sizes.height
//     camera.updateProjectionMatrix()

//     // Update renderer
//     renderer.setSize(sizes.width, sizes.height)
//     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
// })

// let testX = -4;
// document.addEventListener("keydown", onSpawnKey, false);
// function onSpawnKey(event) {
//     var keyCode = event.which;
//     if (keyCode == 83) {
//         // GetUserCharacter(user)
//         // let testChar = new Character(charProps)
//         // characters.push(testChar)
//         // console.log(characters)
//         // testChar.spawn("testID",testX,0,0)

//         // testX++
//     }
//     if (keyCode == 68) {
//         // let testChar2 = new Character(charProps2)
//         // characters.push(testChar2)
//         // // console.log(characters)
//         // testChar2.spawn(testX,0,0)

//         // testX++

//         characters[user.characters[0].CharacterId].replacePart("body_acc", "body_jazz")
//     }
//     if (keyCode == 49) {
//         // console.log(JSON.stringify(characters[0].customizations))
//         characters[user.characters[0].CharacterId].startAnimation('robot')
//     }
// };
/**
 * Camera
 */
// Base camera
// const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(0.94,2.34,1.23)
camera.rotation.set(-0.15, 0, 0)
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.target.set(0, 0.75, 0)
// controls.enableDamping = true





// function readControls (obj) {

//     switch (obj.object.charProp) {
//         case "headSize":
//             // characters[user.characters[0].CharacterId].customizations.head.headSize = ((headSizeProp.max - headSizeProp.min) * obj.object.val) + headSizeProp.min
//             characters[user.characters[0].CharacterId].customizations.head.headSize = getVal(obj.object.val, headSizeProp.min, headSizeProp.max)
//             break;
//         case "neckLength":
//             characters[user.characters[0].CharacterId].customizations.head.neckLength = getVal(obj.object.val, neckLengthProp.min, neckLengthProp.max)
                
//             break;
//         case "bodySize":
//         characters[user.characters[0].CharacterId].customizations.body.bodySize = getVal(obj.object.val, bodySizeProp.min, bodySizeProp.max)
            
//         break;
//         case "legLength":
//             characters[user.characters[0].CharacterId].customizations.feet.legLength = getVal(obj.object.val, legLengthProp.min, legLengthProp.max)
                
//             break;
//         case "handSize":
//             characters[user.characters[0].CharacterId].customizations.hands.handSize = getVal(obj.object.val, handSizeProp.min, handSizeProp.max)
                
//             break;
//         case "feetSize":
//             characters[user.characters[0].CharacterId].customizations.feet.feetSize = getVal(obj.object.val, feetSizeProp.min, feetSizeProp.max)
                
//             break;
//         case "hue":
//             characters[user.characters[0].CharacterId].customizations.body.bodyColor.h = getVal(obj.object.val, bodyColorProp.hMin, bodyColorProp.hMax)
//             break
//         case "sat":
//             characters[user.characters[0].CharacterId].customizations.body.bodyColor.s = getVal(obj.object.val, bodyColorProp.sMin, bodyColorProp.sMax)
//             break
//         case "value":
//             characters[user.characters[0].CharacterId].customizations.body.bodyColor.v = getVal(obj.object.val, bodyColorProp.vMin, bodyColorProp.vMax)
//             default:
//             break;
//     }

//     characters[user.characters[0].CharacterId].applyAdjustments()
// }

// function spinChar (delta) {
//     // console.log("spinning char this much: " + delta)
//     characters[GetMainCharacter()].body.rotation.y += (delta * 0.003)

// }

// function resetCharSpin () {
//     characters[GetMainCharacter()].body.rotation.y = 0
// }

/**
 * Renderer
 */
// const renderer = new THREE.WebGLRenderer({
//     antialias: true,
//     canvas: canvas
// })
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


//Drag Controls
// dragControls(canvas,camera,readControls)



function mediaQueryChange (state) {
    console.log("Changed screen to " + state)

    device = state
    // switch (state) {
    //     case 'desktop':
    //       //camera
    //       gsap.to(camera.position, {x: 1.8, y: 1.7, z: 3.2, duration: 0.5})
          
    //       break;
    //   case 'tabletProtrait':
    //       //camera
    //       gsap.to(camera.position, {x: 1.21, y: 1.77, z: 3.74, duration: 0.5})
          
    //       break;
    //   case 'mobileLandscape':
    //       //camera
    //       gsap.to(camera.position, {x: 0.06, y: 1.77, z: 2.65, duration: 0.5})
          
    //     break;
    // case 'mobilePortait':
    //     //camera
    //     gsap.to(camera.position, {x: 0.7, y: 1.77, z: 3.71, duration: 0.5})
        
    //     break;
    // default:
    //     break;
    // }

}

    
        this.mount.appendChild(this.renderer.domElement)
        this.start()
      }
    
      componentWillUnmount() {

        this.stop();
        this.mount.removeChild(this.renderer.domElement);
        
        // Traverse the whole scene
        this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })
        // this.renderer.instance.dispose()
        this.eventsDispose()
    }

    
      start() {
        const clock = new THREE.Clock()
        let previousTime = 0

        if (!this.frameId) {
          this.frameId = requestAnimationFrame(() => {this.animate(clock, previousTime)})
        }
      }
    
      stop() {
        cancelAnimationFrame(this.frameId)
      }
    
      animate(clock, previousTime) {
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = elapsedTime - previousTime
        previousTime = elapsedTime
        //update all character animation mixers
        for (const [id, char] of Object.entries(this.characters)) {
            if (char.currentAnim)
                char.mixer.update(deltaTime)
        }


    
        this.renderScene()
        this.frameId = window.requestAnimationFrame(() => {this.animate(clock, previousTime)})
      }
    
      renderScene() {
        this.renderer.render(this.scene, this.camera)
      }
    
      render() {
        return (
            <div>
                <Loading />
                <DynamicMenuModernized position={3} direction="top" />
                <div
                style={{ width: '100vw', height: '100vh' }}
                ref={(mount) => { this.mount = mount }}
            />
            </div>
            
        )
      }
}

// const rootElement = document.getElementById("root")
// ReactDOM.render(<DressingRoom />, rootElement);
export default ATM;