import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
// layout
import { LayoutDefault } from '../../layouts';
import { Header } from '../../components/header';
import { Footer } from '../../container/footer';
// data
import frisson from '../../data/frisson.json';
import './styles.scss';

const CommunityGuidelines = () => {
  return (<Fragment>
    <Helmet>
      <title>{`${frisson.title}`}</title>
    </Helmet>
    <LayoutDefault className='bg-light template-font-1 bg_color--3'>
      <Header />
      <Container className='pl_md--60 pl_sm--0'>
        <Row>
          <div className='terms-conditions'>
            <h2>Community Guidelines</h2>
            <p>FAt Frisson, our mission is to provide the best shared social video experience created by our growing
              community where creators and communities can interact safely. To achieve this goal, we require that all
              users participate in such a way that promotes a civil, positive experience for our user community.</p>
            <p>In addition to our Terms of Service, we provide the following guidelines for our users. These
              guidelines apply to all user generated content and user activity on the Frisson Services. This is
              considered an evolving document that we update based on the evolution of the Frisson community, the
              Frisson user experience and the Frisson Services. Additional guidelines or specific exceptions may be
              applicable for certain services or properties accessible through the Frisson Services.</p>
            <p>The Terms of Sale take precedence over the Terms of Service in the event that they conflict, though
              both agreements together (including other agreements and policies referenced therein) make up the
              entirety of your agreement with Frisson in connection with your purchases of the Subsidiary Products
              and Services.</p>
            <p>To protect the integrity of our community, as the provider of the service, we at Frisson reserve all
              rights to suspend or terminate any account at any time for any conduct that we, in our sole discretion,
              determine to be inappropriate or harmful. </p>
            <br />
            <h4>1. Breaking the Law</h4>
            <p>You must respect and comply with all applicable local, national, and international laws and regulations
              while using the Frisson Services. Any user content or user activity on the Frisson Services featuring,
              encouraging, offering, or soliciting illegal activity is prohibited.</p>
            <br />
            <h4>2. Suspension Evasion</h4>
            <p>All suspensions are binding until expiration or removal upon successful appeal. Any attempt to circumvent
              an account suspension or Crowd Chat or Stream ban by using other accounts, identities, or by appearing on
              another user’s account will also result in an additional enforcement against your accounts, up to an
              indefinite suspension or termination. </p>
            <p>In addition, it is prohibited to use your access to the Frisson Services to knowingly feature or promote
              a suspended or terminated user. </p>
            <br />
            <h4>3. Violence and Threats</h4>
            <p>Acts and threats of violence will be taken seriously and are considered zero-tolerance violations and all
              accounts associated with such activities will be indefinitely suspended or terminated. This includes, but
              is not limited to:</p>
            <p>Attempts or threats to physically harm or kill others
              Attempts or threats to hack, DDOS, or SWAT others
              Use of weapons to physically threaten, intimidate, harm, or kill others
              Frisson does not allow content that depicts, glorifies, encourages, or supports terrorism, or violent
              extremist actors or acts. This includes threatening to or encouraging others to commit acts that would
              result in serious physical harm to groups of people or significant property destruction. You may not
              display or link terrorist or extremist propaganda, including graphic pictures or footage of terrorist or
              extremist violence, even for the purposes of denouncing such content.</p>
            <p>In situations where a user has lost control of their broadcast due to severe injury, medical emergency,
              police action, or being targeted with serious violence, we will temporarily remove the channel and
              associated content.</p>
            <p>In exceptional circumstances, we may preemptively suspend accounts when we believe an individual’s use of
              Frisson poses a high likelihood of inciting violence. In weighing the risk of harm, we consider an
              individual’s influence, the level of recklessness in their past behaviors (regardless of whether any past
              behavior occurred on Frisson), whether or not there continues to be a risk of harm, and the scale of
              ongoing threats.</p>
            <br />
            <h4>4. Hateful Conduct and Harassment</h4>
            <p>Hateful conduct and harassment are not allowed on Frisson. Hateful conduct is any content or activity
              that promotes or encourages discrimination, denigration, harassment, or violence based on the following
              protected characteristics: race, ethnicity, color, caste, national origin, immigration status, religion,
              sex, gender, gender identity, sexual orientation, disability, serious medical condition, and veteran
              status. We also provide certain protections for age. Frisson has zero tolerance for hateful conduct,
              meaning we act on every valid reported instance of hateful conduct. We afford every user equal protections
              under this policy, regardless of their particular characteristics.</p>
            <p>Harassment has many manifestations, including stalking, personal attacks, promotion of physical harm,
              hostile raids, and malicious false report brigading. Sexual harassment, specifically, can take the form of
              unwelcome sexual advances and solicitations, sexual objectification, or degrading attacks relating to a
              person’s perceived sexual practices.</p>
            <p>We will take action on all instances of hateful conduct and harassment, with an increasing severity of
              enforcement when the behavior is targeted, personal, graphic, or repeated/prolonged, incites further
              abuse, or involves threats of violence or coercion. The most egregious violations may result in an
              indefinite suspension on the first offense. </p>
            <p>Learn more about our hateful conduct and harassment policies and enforcement.</p>
            <br />
            <h4>5. Unauthorized Sharing of Private Information</h4>
            <p>Do not invade the privacy of others. It is prohibited to share content that may reveal private personal
              information about individuals, or their private property, without permission. This includes but is not
              limited to:
            </p>
            <p>
              Sharing personally identifiable information (such as real name, location, or ID)
              Sharing restricted or protected social profiles or any information from those profiles
              Sharing content that violates another’s reasonable expectation of privacy, for example streaming from a
              private space, without permission
            </p>
            <br />
            <h4>6. Impersonation</h4>
            <p>Content or activity meant to impersonate an individual or organization is prohibited. Any attempts to
              misrepresent yourself as a member of Frisson representatives is a zero-tolerance violation and will result
              in indefinite suspension.</p>
            <br />
            <h4>7. Account Usernames and Display Names</h4>
            <p>In order to ensure that our community is safe and inclusive, inappropriate account names that violate our
              Community Guidelines are prohibited. We also recognize that an account’s username has more impact across
              our services than many other forms of content because they are persistent, cross-functional, and, in most
              cases, much more visible. Because of this, we have additional, higher standards for usernames based on
              reducing harm across our services. Usernames and display names created on Frisson may not include:</p>
            <ul>
              <li> Breaking the Law, including Terrorism and Child Exploitation</li>
              <li> Violence and Threats</li>
              <li> Hateful Conduct</li>
              <li> Harassment and Sexual Harassment</li>
              <li> Unauthorized Sharing of Private Information</li>
              <li> Impersonation</li>
              <li> Glorification of natural or violent tragedies</li>
              <li> Self-Destructive Behavior</li>
              <li> References to recreational drugs, hard drugs, and drug abuse, with exceptions for alcohol, tobacco,
                and marijuana
              </li>
              <li> References to sexual acts, genital, or sexual fluids</li>
            </ul>
            <p>Learn more about our username policies and enforcement</p>
            <br />
            <h4>8. Spam, Scams, and Other Malicious Conduct</h4>
            <p>Any content or activity that disrupts, interrupts, harms, or otherwise violates the integrity of Frisson
              services or another user’s experience or devices is prohibited. Such activity includes:</p>
            <p>Posting large amounts of repetitive, unwanted messages or user reports
              Distributing unauthorized advertisements
              Phishing
              Defrauding others
              Spreading malware or viruses
              Misinformation (such as feigning distress, posting misleading metadata, or intentional channel
              miscategorization)</p>
            <br />
            <h4>9. Harmful misinformation actors</h4>
            <p>Tampering (such as artificially inflating follow or live viewer stats)
              Selling or sharing user accounts
              Reselling Frisson services or features (such as channel Moderator status)
              Defacing, or attempting to deface, website pages or other Frisson services (such as uploading
              inappropriate or malicious content)
              Cheating a Frisson rewards system (such as the Drops or channel points systems)
              Harmful Misinformation Actors
              In order to reduce harm to our community and the public without undermining our streamers’ open dialogue
              with their communities, we also prohibit harmful misinformation superspreaders who persistently share
              misinformation on or off of Frisson. We seek to remove users whose online presence is dedicated to (1)
              persistently sharing (2) widely disproven and broadly shared (3) harmful misinformation topics. </p>
            <p>This policy is focused on Frisson users who persistently share harmful misinformation. It will not be
              applied to users based upon individual statements or discussions that occur on the channel. All three of
              the criteria listed above must be satisfied in order for us to take action on an account. We will evaluate
              whether a user violates the policy by assessing both their on-platform behavior as well as their
              off-platform behavior. You can report these actors by sending an email to OSIT@twitch.tv with the account
              name and any available supporting evidence.</p>
            <p>Below we provide a short list of non-comprehensive examples of the types of content that harmful
              misinformation actors persistently share. However, misinformation evolves rapidly, and we will continue to
              update these guidelines and topic categories as new trends and behaviors emerge. </p>
            <ul>
              <li> Misinformation that targets protected groups, which is already prohibited under our Hateful Conduct &
                Harassment Policy
              </li>
              <li> Harmful health misinformation and wide-spread conspiracy theories related to dangerous treatments,
                COVID-19, and COVID-19 vaccine misinformation
              </li>
              <li> Discussions of treatments that are known to be harmful without noting the dangers of such
                treatments
              </li>
              <li> For COVID-19—and any other WHO-declared Public Health Emergency of International Concern
                (PHEIC)—misinformation that causes imminent physical harm or is part of a broad conspiracy
              </li>
              <li> Misinformation promoted by conspiracy networks tied to violence and/or promoting violence</li>
              <li> Civic misinformation that undermines the integrity of a civic or political process</li>
              <li> Promotion of verifiably false claims related to the outcome of a fully vetted political process,
                including election rigging, ballot tampering, vote tallying, or election fraud*
              </li>
              <li> In instances of public emergencies (e.g., wildfires, earthquakes, active shootings), we may also act
                on misinformation that may impact public safety
              </li>
            </ul>
            <p>*Note: In order to evaluate civic misinformation claims, we work with independent misinformation experts
              such as the Global Disinformation Index, as well as information from election boards and congressional
              certification.</p>
            <br />
            <h4>10. Nudity, Pornography, and Other Sexual Content</h4>
            <p>Nudity and sexually explicit content or activities, such as pornography, sexual acts or intercourse, and
              sexual services, are prohibited</p>
            <p>Content or activities that threaten or promote sexual violence or exploitation are strictly prohibited
              and may be reported to law enforcement.</p>
            <br />
            <h4>11. Extreme Violence, Gore, and Other Obscene Conduct</h4>
            <p>Content that exclusively focuses on extreme or gratuitous gore and violence is prohibited.</p>
            <br />
            <h4>12. Self-Destructive Behavior</h4>
            <p>Activity that may endanger your life, lead to your physical harm, or encourage others to engage in
              physically harmful behavior is prohibited. This includes, but is not limited to: suicide threats,
              glorification or encouragement of self-harm, intentional physical trauma, illegal use of drugs, and
              illegal or dangerous consumption of alcohol.</p>
            <br />
            <h4>13. Intellectual Property Rights</h4>
            <p>You should only share content on your Frisson channel that you own, or that you otherwise have rights to
              or are authorized to share on Frisson. If you share content on your Frisson channel that you do not own or
              otherwise do not have the rights to share on Frisson, you may be infringing another person’s intellectual
              property rights. This includes any third party content included in your content, derivative creations, or
              performances of others’ copyrighted content. We encourage you to assess your content for adherence to
              applicable intellectual property laws and the proper application of principles such as fair use, and to
              secure all appropriate rights needed, before sharing your content on Frisson.</p>
            <p>Any unauthorized content you share on Frisson violates our Terms of Service and is subject to removal.
              Multiple violations of our policies may lead to a permanent suspension or termination of your account.
              Additionally, rights holders may request that Frisson remove unauthorized content and/or issue
              penalties.</p>
            <p>Examples of content you should not share on Frisson without permission from the rights holders or unless
              otherwise permitted by law include</p>
            <p>Other Frisson creators’ content
              Pirated games or content from unauthorized private servers
              Content from other sites
              Movies, television shows, or sports matches
              Music you do not own or do not have the rights to share
              Goods or services protected by trademark
              Learn more about our policies regarding including music in your Frisson channel.</p>
            <br />
            <h4>14. Content Labeling</h4>
            <p>You are expected to accurately label your content to the best of your ability. When choosing a category
              or tag, please choose whichever best describes your content. Deliberate or extensive misuse of titles,
              tags, games/categories, or other metadata are prohibited.</p>
            <br />
            <h4>15. Off-Service Conduct</h4>
            <p>Frisson is committed to facilitating vibrant and dynamic communities, which can only happen if our users
              feel secure and protected. In order to achieve this goal, Frisson enforces against severe offenses
              committed by members of the Frisson community that occur outside of our services, such as hate group
              membership, terrorist recruitment, sexual assault, and child grooming. We will investigate reports that
              include verifiable evidence of these behaviors and, if we are able to confirm, issue enforcements against
              the relevant users. We will also consider allegations of similarly severe conduct by those wishing to join
              the Frisson community and, if substantiated, we will suspend or terminate the account</p>
            <p>We will do our best to ensure that our enforcement decisions are accurate, which in some cases will
              necessitate us bringing in a third party investigator for thorough and impartial review. If we are able to
              verify reports of off-service statements or behaviors that relate to an incident that took place on
              Frisson, we will use this evidence to support and inform our enforcement decisions.</p>
          </div>
        </Row>
      </Container>
      <Footer />
    </LayoutDefault>
  </Fragment>);
};
export default CommunityGuidelines;
