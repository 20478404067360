import {selectionStep} from './chatManager'
import { updateBounds } from '../../shared/ui-builder'
import { setSectionSelection } from './vidManager'

/**
 * Sizes
 */
export const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

let vidHeight = sizes.width / 1.78
let vidWidth = sizes.width
let canvasHeight = sizes.height - vidHeight
let canvasWidth = sizes.width
let bannersWidth = 0

let vidRatio = (sizes.height / 3)

let camera
let renderer
let prevState
// const vidContainer = document.getElementById('videoContainer')
// const canvasEl = document.getElementById('root').getElementsByTagName('canvas')[0]

let _resizeListener = function(callback) {
    //save current to see if it changes at this size change
    
            
    window.setTimeout(() => {
       update()
        if (prevState != mediaQueryState) {
            if (callback) callback(mediaQueryState)
        }
        
        prevState = mediaQueryState
    }, 50);
    // update()
}

export function screenSizeListener (callback) {
    update()

    if (callback) callback(mediaQueryState)

    window.addEventListener('resize', _resizeListener.bind(this, callback))
}

export function update() {

    //check for section selection and add class to vid
    //setSectionSelection(selectionStep)

    calcQuery()

    //cal initial vid height
    //calcElementsHeight()

    // Update renderer
    // renderer.setSize(sizes.width, sizes.height)
    // renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    //calcElementsHeight()
    
    
    // Update camera
    // camera.aspect = canvasWidth / canvasHeight
    // camera.updateProjectionMatrix()
}

export function getInitialSize (theCamera, theRenderer) {

    camera = theCamera
    renderer = theRenderer
    
    calcQuery ()
    // calcElementsHeight()
    return({"initWidth" : canvasWidth, "initHeight" : canvasHeight})
}

function calcQuery () {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    if (sizes.width < 500) {
        mediaQueryState = screenSizeStates.MobilePortrait
    }
    else if (sizes.width < 1000 && sizes.height < 600) {
        mediaQueryState = screenSizeStates.MobileLandscape
    }
    else if (sizes.width < 1200 && sizes.height > 1000) {
        mediaQueryState = screenSizeStates.TabletPortrait
    }
    else if (sizes.width < 1200 && sizes.height < 1000) {
        mediaQueryState = screenSizeStates.TabletLandscape
    }
    else if (sizes.width > 1200) {
        mediaQueryState = screenSizeStates.Desktop
    }
}

function calcElementsHeight () {
    //calculate and output vid section height

    switch (mediaQueryState) {
        case screenSizeStates.MobilePortrait:
        case screenSizeStates.TabletPortrait:
            //full page width
            document.getElementById('ccContainer').style.display = "block"
            vidHeight = sizes.width / 1.78
            vidWidth = sizes.width
            console.log("mobile tablet portrait")
            canvasHeight = sizes.height - vidHeight
            canvasWidth = sizes.width
            
            break;
        case screenSizeStates.TabletLandscape:
        case screenSizeStates.Desktop:

            //check to see if we are currently a section on desktop and we need to adjust heights
            if (selectionStep == 1) {
                canvasHeight = (sizes.height / 3) *2  + 50
                vidHeight = sizes.height / 3
            }
            else if (selectionStep == 2) {
                canvasHeight = sizes.height / 2 + 100
                vidHeight = sizes.height / 2
            } else {
                canvasHeight = sizes.height / 3
                vidHeight = (sizes.height / 3) * 2
            }

            //full page width
            document.getElementById('ccContainer').style.display = "block"
             
            vidWidth = vidHeight / 0.5625
            // console.log("here")
            
            canvasWidth = sizes.width

            //set up overlap
            // if (document.getElementById('root') && document.getElementById('root').getElementsByTagName('canvas')[0]) {
            //     document.getElementById('canvasContainer').getElementsByTagName('canvas')[0].style.height = (canvasHeight + 140) + 'px'
            // }

            break;
        case screenSizeStates.MobileLandscape:
             document.getElementById('ccContainer').style.display = "block"
             vidHeight = sizes.height
             vidWidth = sizes.width
             console.log("here")
             canvasHeight = sizes.height
             canvasWidth = sizes.width

             break;
        
        
        // case screenSizeStates.Desktop:
        //     //right hand canvas
        //     document.getElementById('ccContainer').style.display = "block"
        //     vidHeight = sizes.height
        //     vidWidth = Math.min(sizes.height * 1.78, sizes.width * 0.75)

        //     canvasHeight = sizes.height
        //     canvasWidth = sizes.width - vidWidth
        //     break;
    
        default:
            break;
    }

    //check for banner width
    if (vidWidth < sizes.width)
    {
        bannersWidth = (sizes.width - vidWidth) / 2
    }
    else {
        bannersWidth = 0
    }
    
    document.getElementById('streamContainer').style.width = vidWidth + 'px'
    document.getElementById('streamContainer').style.height = vidHeight + 'px'

    Array.prototype.forEach.call(document.getElementsByClassName('streamBanner'), function(el) {
        el.style.width = bannersWidth + 'px'
    });
    

    document.getElementById('canvasContainer').style.width = canvasWidth + 'px'
    document.getElementById('canvasContainer').style.height = canvasHeight + 'px'

    if (document.getElementById('root') && document.getElementById('root').getElementsByTagName('canvas')[0] && mediaQueryState != screenSizeStates.Desktop && mediaQueryState != screenSizeStates.TabletLandscape) {
        document.getElementById('root').getElementsByTagName('canvas')[0].style.width = canvasWidth + 'px'
        document.getElementById('root').getElementsByTagName('canvas')[0].style.height = canvasHeight + 'px'
        
    }
    // else if (document.getElementById('root') && document.getElementById('root').getElementsByTagName('canvas')[0] && mediaQueryState == screenSizeStates.Desktop && mediaQueryState == screenSizeStates.TabletLandscape) {
    //     renderer.setSize(canvasWidth, canvasHeight  + 140)
    // }

    renderer.setSize(canvasWidth, canvasHeight)

    updateBounds(renderer)

}

export const screenSizeStates = {
    Desktop : 'desktop',
    TabletLandscape : 'tabletLandscape',
    TabletPortrait : 'tabletPortrait',
    MobileLandscape : 'mobileLandscape',
    MobilePortrait : 'mobilePortrait'
}

export let mediaQueryState = screenSizeStates.Desktop

export function MQDIsposeEvents () {
    window.removeEventListener( 'resize', _resizeListener, false);

}